import React from 'react';
import { Modal, Tooltip } from 'antd';
import { Field } from './Field';
import DataTableComponent from './components/DataTableComponent';
import { MapLabelComponent } from './MapComponent';
import { FontAwesomeIcon } from '..';

const TableDiffWarning = ({ title, ...props }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Tooltip
        title={title}
        onClick={() => {
          setVisible(true);
        }}
      >
        <FontAwesomeIcon icon="engine-warning" type="solid" style={{ color: 'orange', cursor: 'pointer' }} />
      </Tooltip>
      <Modal
        title="Iepriekšējās vērtības"
        width="80%"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        closable
      >
        <MapLabelComponent isJson skipIfNoTitle {...props} />
      </Modal>
    </>
  );
};

@Field.register
class TableComponent extends Field {
  static code = 'table';

  extraItemProps = {
    wrapperCol: { sm: 24, xs: 24, lg: 24, md: 24 },
    style: { width: '100%', marginRight: 0 },
    label: undefined,
  };

  getFormLabel() {
    return undefined;
  }

  renderWarningIcon({ previousValue, value, id }) {
    if (!previousValue && !value) {
      // console.log('previousValue: ', previousValue);
      return '';
    }
    const groupBy = (list, key) => {
      // console.log('list: ', list);
      const listKeys = key.split('.');
      if (!Array.isArray(list)) {
        // TODO: FIX BELOW
        // Bug with object instead of an array
        // console.log('list: ', list);
        return [];
      }
      return list
        .filter((i) => i)
        .reduce((hash, obj) => {
          let xKey = obj[listKeys[0]];
          if (listKeys.length > 1) {
            // console.log('listKeys[0]: ', listKeys[0], obj);
            xKey =
              obj[listKeys[0]] && obj[listKeys[0]][listKeys[1]] ? obj[listKeys[0]][listKeys[1]] : listKeys.join('.');
          }
          return { ...hash, [`${xKey}:${listKeys.join('.')}`]: (hash[xKey] || []).concat(obj) };
        }, {});
    };

    const data = { [this.key]: this.groupDiffDataBy ? groupBy(previousValue, this.groupDiffDataBy) : previousValue };
    return (
      <TableDiffWarning
        title="Skatīt izmaiņas"
        value={JSON.stringify(data)}
        tableName={this.dataSet}
        hasChildren
        childrenKey={id}
        name={this.key}
      />
    );
  }

  renderComponent({ async, optionSource: asyncSource, optionValueKey: asyncKey, ...props }) {
    const isAsync = async || this.async;
    const optionSource = asyncSource || this.optionSource;
    const optionValueKey = asyncKey || this.optionValueKey;

    if (this.label) {
      props.disabled = true;
    }

    return (
      <DataTableComponent
        async={isAsync}
        optionSource={optionSource}
        optionValueKey={optionValueKey}
        warningIconProps={this.warningIconProps}
        title={
          <>
            {this.title}
            {this.showWarningIcon && this.renderWarningIcon(this.warningIconProps)}
          </>
        }
        formInstance={this.formInstance}
        {...props}
      />
    );
  }
}

export default TableComponent;
