import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card, Radio, Tag } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, FormItem, gridRow } from '@ois-lib';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Geocode from 'react-geocode';
// import { toJS } from 'mobx';

@inject('appStore', 'authStore', 'companyStore')
@observer
class EditCompany extends EditFormBase {
  title = 'Uzņēmuma profils';
  storeName = 'companyStore';
  baseUrl = '/';
  colSize = { lg: 24, xl: 18, xxl: 12 };
  googleMapsApiKey = 'AIzaSyCDPjfKGh44tYCn1u1qtCqYx6A38ynvRQE';

  companyCardLabels = {
    Lat: ['Telefons:', 'E-pasts:', 'Reģ.Nr.:', 'Adrese:', 'Mājas lapa:'],
    Rus: ['Телефон:', 'Э-мейл:', 'Рег.номер:', 'Адрес:', 'Веб-страница:'],
    Eng: ['Phone:', 'E-mail:', 'Reg.No.:', 'Address:', 'Homepage:'],
  };
  companyCardFields = [['phone'], ['email'], ['regNr'], ['street', 'city', 'zip'], ['web']];

  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
      history,
      companyStore,
      authStore: { user },
    } = props;

    this.state = {
      language: 'Lat',
      position: {
        lat: 56.95713,
        lng: 24.10403,
      },
    };

    if ([user.id, 'edit'].includes(id)) {
      companyStore.item.id = user.id;
    } else {
      companyStore.item.id = user.id;
      history.push('/company/edit');
    }
    this.checkIncompleteCompanyInfo = this.checkIncompleteCompanyInfo.bind(this);
  }

  async componentDidMount() {
    const { companyStore } = this.props;
    if (companyStore.item && companyStore.item.id) {
      this.loadFresh(companyStore).then(() => {
        const { form } = this.props;
        const lat = form.getFieldValue('lat') && parseFloat(form.getFieldValue('lat'));
        const lng = form.getFieldValue('lng') && parseFloat(form.getFieldValue('lng'));
        if (typeof lat === 'number' && typeof lng === 'number') {
          this.handleLocationChange(lat, lng);
        } else {
          this.checkAddressLocation();
        }
        this.checkIncompleteCompanyInfo();
      });
    }
  }

  componentWillUnmount() {
    this.checkIncompleteCompanyInfo();
  }

  checkIncompleteCompanyInfo = () => {
    if (this.props.authStore.user.isCompanyAdmin) {
      const { item } = this.props.companyStore;
      const requiredFieldList = ['descriptionLat', 'email', 'city', 'street', 'zip', 'phone', 'storedTags'];
      for (let i = 0; i < requiredFieldList.length; i++) {
        const check = Array.isArray(item[requiredFieldList[i]]) && item[requiredFieldList[i]].length === 0;
        if (!item[requiredFieldList[i]] || check) {
          this.props.appStore.setIncompleteCompanyInfo(0);
          return;
        }
      }
    }
    this.props.appStore.setIncompleteCompanyInfo(2);
  };

  handleLocationChange = (lat, lng) => {
    lat = +`${Math.round(`${lat}e+6`)}e-6`;
    lng = +`${Math.round(`${lng}e+6`)}e-6`;
    this.props.form.setFieldsValue({
      lat,
      lng,
    });
    this.setState({
      position: {
        lat,
        lng,
      },
    });
  };

  checkAddressLocation = () => {
    const city = this.props.form.getFieldValue('city');
    const street = this.props.form.getFieldValue('street');
    if (city && street) {
      Geocode.setApiKey(this.googleMapsApiKey);
      // set response language. Defaults to english.
      Geocode.setLanguage('en');
      // set response region. Its optional.
      Geocode.setRegion('lv');
      // Enable or disable logs. Its optional.
      // Geocode.enableDebug();
      // Get latitude & longitude from address.
      Geocode.fromAddress(`${street}, ${city}`).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          this.handleLocationChange(lat, lng);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  setLanguage = (event) => {
    this.setState({ language: event.target.value });
  };

  canSave() {
    const { authStore } = this.props;
    return authStore && authStore.user && authStore.user.isCompanyAdmin;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    const item = this.props.form.getFieldsValue();
    const { language } = this.state;
    const srcUrl = item && item.files && item.files.length ? item.files[0].url : null;
    const logo = srcUrl ? (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
        <img
          src={srcUrl}
          alt="Logo"
          style={{
            objectFit: 'cover',
            maxWidth: '100%',
            maxHeight: '200px',
          }}
        />
      </div>
    ) : (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h1>{item.name}</h1>
      </div>
    );

    const tags = item && item.tags ? item.tags.map((tag) => <Tag key={tag.id}>{tag.name}</Tag>) : '';

    const companyCard = item ? (
      <Card title="Uzņēmuma profila izskats rop.lv mājas lapā">
        <Row>
          <Col>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {logo}
              <div style={{ minWidth: '300px' }}>
                {this.companyCardLabels[language].map((e, i) => (
                  <div key={e} style={{ display: 'flex', alignContent: 'left' }}>
                    <div
                      style={{
                        minWidth: '120px',
                        textAlign: 'right',
                        fontWeight: 'bold',
                      }}
                    >
                      {e}
                    </div>
                    <div style={{ paddingLeft: '1rem' }}>
                      {this.companyCardFields[i]
                        .map((f) => item[f])
                        .filter((f) => !!f)
                        .join(', ')}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>{item['description'.concat(language)]}</Row>
        <Row style={{ marginTop: '1rem' }}>{tags}</Row>
      </Card>
    ) : (
      ''
    );
    const containerStyle = {
      height: '400px',
    };
    return (
      <>
        <Row {...gridRow}>
          <Col {...this.colSize}>
            <h1 className="cabinet_title">{`${this.title}: ${(item && item.shortName) || item.name || ''}`}</h1>
          </Col>
        </Row>
        <Row {...gridRow}>
          <Col {...this.colSize}>
            <Card>
              <Radio.Group defaultValue={language} onChange={this.setLanguage} style={{ marginBottom: '1rem' }}>
                <Radio.Button value="Lat" className={'styledRadioButton'.concat(language === 'Lat' ? 'Checked' : '')}>
                  Latviski
                </Radio.Button>
                <Radio.Button value="Eng" className={'styledRadioButton'.concat(language === 'Eng' ? 'Checked' : '')}>
                  English
                </Radio.Button>
                <Radio.Button value="Rus" className={'styledRadioButton'.concat(language === 'Rus' ? 'Checked' : '')}>
                  Русский
                </Radio.Button>
              </Radio.Group>
              {companyCard}
            </Card>
          </Col>
        </Row>
        <Row {...gridRow}>
          <Col {...this.colSize}>
            <Card title="Pamatinformācija par uzņēmumu" bordered={false}>
              <FormItem name="name" asText />
              {/* <FormItem name="nameEn" /> */}
              {/* <FormItem name="shortName" /> */}
              <FormItem name="regNr" />
              <FormItem name="files" preview={{ width: 'auto', display: 'inline-block', maxHeight: '200px' }} />
              <FormItem
                name="descriptionLat"
                rules={[
                  {
                    message: 'Šis lauks ir obligāts',
                    required: true,
                  },
                ]}
              />
              <FormItem name="descriptionEng" />
              <FormItem name="descriptionRus" />
              <FormItem name="storedTags" />
            </Card>
            <Card title="Kontaktinformācija" bordered={false}>
              <FormItem
                name="email"
                rules={[
                  {
                    message: 'Šis lauks ir obligāts',
                    required: true,
                  },
                ]}
              />
              <FormItem
                name="city"
                onBlur={() => this.checkAddressLocation()}
                rules={[
                  {
                    message: 'Šis lauks ir obligāts',
                    required: true,
                  },
                ]}
              />
              <FormItem
                name="street"
                onBlur={() => this.checkAddressLocation()}
                rules={[
                  {
                    message: 'Šis lauks ir obligāts',
                    required: true,
                  },
                ]}
              />
              <FormItem
                name="zip"
                rules={[
                  {
                    message: 'Šis lauks ir obligāts',
                    required: true,
                  },
                ]}
              />
              <FormItem
                name="phone"
                rules={[
                  {
                    message: 'Šis lauks ir obligāts',
                    required: true,
                  },
                ]}
              />
              <FormItem name="web" />
              <FormItem name="lat" asText />
              <FormItem name="lng" asText />

              <LoadScript googleMapsApiKey={this.googleMapsApiKey}>
                <GoogleMap mapContainerStyle={containerStyle} center={this.state.position} zoom={15}>
                  <Marker
                    position={this.state.position}
                    draggable
                    onDragEnd={(marker) => this.handleLocationChange(marker.latLng.lat(), marker.latLng.lng())}
                  />
                </GoogleMap>
              </LoadScript>
            </Card>
            <Card title="Norēķinu informācija" bordered={false}>
              <FormItem name="vatNr" />
              <FormItem name="accountingCode" />
              <FormItem name="bankId" />
              <FormItem name="bankAccount" />
              <FormItem name="bankDetails" />
            </Card>
            {/* <FormItem disabled name="agents" /> */}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditCompany' })(EditCompany));
