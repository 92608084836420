class RequestQueue {
  _key = null;
  _counter = 0;
  _request = 0;
  _response = null;
  _loading = true;
  _cacheLifetime = null;

  _resolvers = {};

  constructor(entry = {}) {
    const { key, queue, request, cacheLifetime = 1000 } = entry;

    this._key = key;
    this._queue = queue;
    this._cacheLifetime = cacheLifetime;
    // this.resolver = resolve;
    this._request = request.then((response) => {
      this.response = response;
      return true;
    });
    return new Proxy(this, {});
  }

  isResolved() {
    return !this._counter && !this._cacheLifetime;
  }

  runResolvers() {
    for (let key in this._resolvers) {
      this._resolvers[key](this._response.clone());
      delete this._resolvers[key];
      this.counter--;
    }
    clearTimeout(this._cacheTimeout);
    this._cacheTimeout = setTimeout(() => {
      this._cacheLifetime = 0;
    }, this._cacheLifetime);
  }

  set resolver(resolverFunc) {
    this._resolvers[++this._counter] = resolverFunc;
    if (this._response) {
      this.runResolvers();
    }
    return true;
  }

  set counter(count) {
    this._counter = count;
    return true;
  }

  get counter() {
    return this._counter;
  }

  set response(value) {
    this._response = value;
    this.runResolvers();
    return true;
  }
}

export default RequestQueue;
