import { Form } from 'antd';

class XForm extends Form {
  constructor(props, ctx) {
    super(props, ctx);
    // console.log('props, ctx: ', props, ctx);
  }
}

// XForm.create = (stuff) => {
//   // console.log(stuff);
//   const frm = createThing(stuff);
//   console.log('frm: ', frm);
//   return frm;
// };

export default XForm;
