/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Select, Input, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Field, Spinner } from './Field';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';
import StoreFactory from '../stores';
import Addon, { AddonComponent } from '../addons';

const AddonWrapper = ({ children, addons, ...props }) => {
  const addonBefore = [];
  const addonAfter = [];

  if (addons) {
    for (let addon of addons) {
      addonAfter.push(addon);
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  if (props.addAfter) {
    addonAfter.push(props.addAfter);
  }

  if (props.addBefore) {
    addonBefore.push(props.addBefore);
  }
  // console.log('addonAfter: ', addonAfter);

  return !addonBefore || !addonAfter ? (
    children
  ) : (
    <div className="select-with-addonAfter force-display-block">
      {addonBefore && !!addonBefore.length && <div className="addon-action-block left">{addonBefore}</div>}
      {children}
      {addonAfter && !!addonAfter.length && <div className="addon-action-block right">{addonAfter}</div>}
    </div>
  );
};

const SelectLabel = observer(({ value, col, record_store, name, addons, addAfter, addBefore }) => {
  const record = record_store ? record_store.item : null;
  if (record && record[`_t_${name}`] !== undefined && record[`_t_${name}`] !== null) {
    return (
      <AddonWrapper addons={addons} addAfter={addAfter} addBefore={addBefore}>
        {record[`_t_${name}`] || ' - '}
      </AddonWrapper>
    );
  }
  const res = value !== null && value !== undefined ? col.loadRowTransform({ [col.key]: value }) : null;
  return <span>{res || ' - '}</span>;
});

const WarnIconValue = observer(({ value, col }) => {
  const res = value !== null && value !== undefined ? col.loadRowTransform({ [col.key]: value }) : null;
  return <span>{res || '(tukšs lauks)'}</span>;
});

const SelectObserver = observer(({ col, ...props }) => {
  const [isOptionsLoading, setOptionsLoading] = useState(false);
  const [asyncOptions, setAsyncOptions] = useState(null);
  const [optionStore, setOptionStore] = useState(null);
  // const [searchQuery, setSearchQuery] = useState('');
  const _this = {
    waitTimeout: 0,
    loadedPrefixes: {},
    searchQuery: null,

    handleOpetionStoreSearch: (value) => {
      if (value.length > 2) {
        if (!_this.optionStore && col.optionSource) {
          _this.optionStore = StoreFactory.getStore(col.optionSource, 'options');
        }
        if (col.optionFilters) {
          _this.optionStore.systemFilters =
            props.optionFilters || col.optionFilters ? { ...col.optionFilters, ...props.optionFilters } : {};
        }

        setOptionsLoading(true);

        // console.log('vice! ', value);

        clearTimeout(_this.waitTimeout);
        _this.waitTimeout = setTimeout(async () => {
          if (_this.optionStore) {
            let data = [];
            _this.optionStore.systemFilters =
              props.optionFilters || col.optionFilters ? { ...col.optionFilters, ...props.optionFilters } : {};
            // console.log('_this.optionStore.systemFilters: ', _this.optionStore.systemFilters);
            if (props.optionsSort && typeof props.optionsSort === 'object') {
              _this.optionStore.explicitColumnSorters = Object.keys(props.optionsSort).map((k) => {
                return {
                  key: k,
                  direction: props.optionsSort[k],
                };
              });
            } else {
              _this.optionStore.explicitColumnSorters = null;
            }
            _this.optionStore.pageSize = 30;
            // setSearchQuery(`${value}`);
            _this.searchQuery = `${value}`;
            const searchResult = await _this.optionStore.search(`${value}`.toLocaleLowerCase());
            if (searchResult && searchResult.data) {
              data = searchResult.data;
            }

            let _asyncOptions = data.map((item) => ({
              ...item,
              key: col.optionKeyName && item[col.optionKeyName] ? item[col.optionKeyName] : item.id,
              value:
                col.optionValueName && item[col.optionValueName]
                  ? item[col.optionValueName]
                  : item.name || item.description || ' - ',
            }));
            if (col.addIfNotFound && _asyncOptions.length===0){
              _asyncOptions.push({id:'addnew````'+value,key:'addnew````'+value,name:'Pievienot '+value});
            }
            setAsyncOptions(_asyncOptions);
            col.options = [..._asyncOptions];
            setOptionsLoading(false);
          }
        }, 500);
      }
    },
  };

  const record = props.record_store.item;
  const isNew = record && (!Object.keys(record).length || !record.id);
  const isLazy =
    col.optionSource &&
    ['companies', 'companyRepresentatives', 'ports', 'shipVisits', 'ships', 'tugboatVisits'].includes(col.optionSource);

  let options = [];
  let loading = col.optionStore && (col.optionStore.loadingFull || col.optionStore.loadingOne);

  // if (col.key === 'fromBerth') {
  //   console.log('col: ');
  // }

  let additionalFilters = {};
  if (col.dynamicOptionFilters) {
    additionalFilters = { ...col.dynamicOptionFilters(record) };
  }

  if (!asyncOptions) {
    if (col.optionSource && props.record_store) {
      if (!props.record_store.loadingOne) {
        if (
          record &&
          (record[`_t_${props.name}`] !== undefined || (isNew && isLazy) || (!isNew && isLazy && props.name in record)) &&
          !_this.searchQuery
        ) {
          if (record && record[props.name] && isLazy) {
            if (!record[`_t_${props.name}`]) {
              col.s_getSelectedOption(toJS(record[props.name]), item => {
                setAsyncOptions([{ key: item.id, ...item }]);
              });
            } else {
              if (props.value && record[col.key] === props.value) {
                options = [{ key: record[props.name], value: record[`_t_${props.name}`] }];
              } else {
                options = [];
                loading = false;
              }
            }
          } else if (!isLazy) {
            const combinedOptionFilters = { ...col.optionFilters, ...props.optionFilters, ...additionalFilters };
            if (combinedOptionFilters) {
              options = col.getOptionsFromStore(
                { f: JSON.stringify({ ...combinedOptionFilters, fMode: 'strict' }) }
              );
            } else {
              options = [];
            }
          } else {
            loading = false;
          }
        } else {
          if (props.optionFilters !== undefined || (col.optionFilters !== undefined && !col.loadingFull)) {
            // const recordValue = record && toJS(record[props.name]);
            // if (recordValue) {
            // options = col.getOptionsFromStore({ f: JSON.stringify({ id: [recordValue] }), size: 1 });
            // console.log('VICE: ', options);
            // additionalFilters.id = [recordValue];
            // }

            let defaultOptions = [];
            const combinedOptionFilters = { ...col.optionFilters, ...props.optionFilters, ...additionalFilters };

            if (
              combinedOptionFilters &&
              col.optionStore &&
              !col.optionStore.loadingFull &&
              JSON.stringify(col.refrx) !== JSON.stringify(combinedOptionFilters)
            ) {
              col.refreshWithParams({ f: JSON.stringify({ ...combinedOptionFilters, fMode: 'strict' }) });
              col.refrx = combinedOptionFilters;
            }

            options =
              combinedOptionFilters !== null
                ? col.getOptionsFromStore(
                  { f: JSON.stringify({ ...combinedOptionFilters, fMode: 'strict' }) },
                  `_options_${col.editForm.id}`
                )
                : defaultOptions;
          } else {
            options = col.getOptionsFromStore();
          }
        }
      } else {
        loading = true;
      }
    } else {
      options = col.options ?? [];
    }
  }

  // if (
  //   !isLazy &&
  //   options.length &&
  //   col.activeOptionValue &&
  //   col.cahcedVal !== props.value &&
  //   !options.find((o) => o.key === col.activeOptionValue)
  // ) {
  //   col.loadMissingOptions();
  //   col.cahcedVal = props.value;
  // }

  // if (
  //   !isLazy &&
  //   col.optionSource &&
  //   props.value &&
  //   col.singleOptionRequested !== props.value &&
  //   options.length &&
  //   !options.find((o) => o.key === props.value)
  // ) {
  //   const valueOption = col.getOptionsFromStore({ f: JSON.stringify({ id: props.value, fMode: 'strict' }), size: 1 });

  //   col.singleOptionRequested = props.value;
  //   console.log('props.value: ', props.value);
  //   console.log('valueOption: ', valueOption);
  // }

  if (record && isLazy) {
    props.onSearch = _this.handleOpetionStoreSearch;
    props.filterOption = false;
    if (props.noSave) {
      const { optionSource } = props.record_store.colByName[props.name];
      if (optionStore !== optionSource) {
        setAsyncOptions(null);
        setOptionStore(optionSource);
      }
    }
    options =
      asyncOptions && asyncOptions.length
        ? options.reduce((acc, curr) => {
          if (!acc.find((a) => a.key === curr.key)) {
            acc.push(curr);
          }
          return acc;
        }, toJS(asyncOptions, { recurseEverything: true }))
        : options;
    props.notFoundContent = !asyncOptions ? (
      <div className="empty-select-search">
        <FontAwesomeIcon icon="print-search" />
        <p>Ievadiet vismaz 3 simbolus, lai meklētu...</p>
      </div>
    ) : undefined;
  }

  // ! Need to refactor this to col.normalizeOptionsBeforeRender
  if (col.optionsSortFunc && options) {
    options = options.sort(col.optionsSortFunc);
  }

  if (col.localOptionFilterFunc && options) {
    options = options.filter((o) => col.localOptionFilterFunc(o, props.value, record));
  }

  // if (col.key === 'parentWasteTypeId') {
  //   console.log('props: ', props);
  // }

  const overrideProps = {};
  if (props.overridechangeevent) {
    overrideProps.onChange = (val) => {
      const option = options.filter(opt => opt.key === val);
      props.overridechangeevent(val, 0 in option ? option[0] : null, props.extraprops);
    }
  }

  if (props.value && options.length && props.extraprops?.stashBerthData) {
    const berth = options.filter(opt => opt.key === props.value);
    props.extraprops.stashBerthData(0 in berth ? berth[0] : null);
  }


  return loading && !options.length ? (
    <Spinner />
  ) : (
    <AddonWrapper addons={props.addons} addAfter={props.addAfter} addBefore={props.addBefore}>
      <input style={{ display: `none` }} name={col.key} /> {/* DISABLE AUTOCOMPLETE */}
      <Select
        {...props}
        loading={isOptionsLoading && !options.length}
        removeIcon={<FontAwesomeIcon icon="times" />}
        clearIcon={<FontAwesomeIcon type="regular" icon="times-circle" />}
        name={col.key} /* DISABLE AUTOCOMPLETE */
        {...overrideProps}
      >
        {options &&
          options.length &&
          options.map((option) => {
            return (
              <Select.Option
                title={col.renderOptionTitle ? col.renderOptionTitle(option, record) : option.value}
                key={option.key || 0}
                value={option.key}
                disabled={!!option.deleted_at}
                className={!col.showDeleted ? (option.deleted_at && 'hidden') || '' : ''}
                style={props.optionStyle ? props.optionStyle(option, record, props.extraprops) : {}}
              >
                {col.renderOption ? col.renderOption(option, record) : (option.value ?? option.name) }
              </Select.Option>
            );
          })}
      </Select>
    </AddonWrapper>
  );
});

@Field.register
class SelectComponent extends Field {
  static code = 'select';

  addonBefore = [];
  addonAfter = [];

  loadFormTransform(item, value = item[this.key]) {
    if (value === null) return undefined;
    return value;
  }

  saveFormTransform(values, out = {}) {
    out[this.key] = values[this.key] === undefined ? null : values[this.key];
    return super.saveFormTransform(out, out);
  }
  
  renderShipColumn(value, record) {
    if (this.fieldAddons && Array.isArray(this.fieldAddons)) {
      const addons = this.fieldAddons
        .map((addon, key) => {
          if (addon.type) {
            return Addon.make(addon.type, {
              key,
              addonFormStore: StoreFactory.getStore(`${this.optionSource}_${addon.optionSource}`),
              addonStore: StoreFactory.getStore(addon.optionSource, 'addons'),
              addonSource: addon.optionSource,
              fieldSource: this.optionSource,
              fieldProps: { value: record.id, inTable: true },
              options: addon,
            }).renderComponent({});
          }
          return 'Invalid addon type';
        })
        .filter((addon) => !!addon);
      return (
        <div>
          {value}
          &nbsp;
          {addons}
        </div>
      );
    }

    return value;
  }

  renderWarningIcon({ altOptionProps, altOptionSource, asText, ...props }) {
    if (altOptionProps) {
      if (altOptionProps.optionStore) {
        this.optionStore = altOptionProps.optionStore;
      }
      if (altOptionProps.optionKeyName) {
        this.optionKeyName = altOptionProps.optionKeyName;
      }
      if (altOptionProps.optionValueName) {
        this.optionValueName = altOptionProps.optionValueName;
      }
    }

    if (altOptionSource) {
      this.optionSource = altOptionSource;
    }

    return (
      <Tooltip
        title={
          <>
            <strong>Iepriekšējā vērtība: </strong>
            <span style={{ textDecoration: 'line-through' }}>
              <WarnIconValue col={this} {...props} value={props.previousValue} />
            </span>
          </>
        }
      >
        &nbsp;
        <FontAwesomeIcon icon="engine-warning" type="solid" style={{ color: 'orange', cursor: 'pointer' }} />
      </Tooltip>
    );
  }

  renderComponent({ altOptionProps, altOptionSource, asText, ...props }) {
    this.activeOptionValue = props.value;
    if (altOptionProps) {
      if (altOptionProps.optionStore) {
        this.optionStore = altOptionProps.optionStore;
      }
      if (altOptionProps.optionKeyName) {
        this.optionKeyName = altOptionProps.optionKeyName;
      }
      if (altOptionProps.optionValueName) {
        this.optionValueName = altOptionProps.optionValueName;
      }
    }

    if (altOptionSource) {
      this.optionSource = altOptionSource;
    }

    const extra = {
      // addons: [...props.addons, ...this.addons],
      addonAfter: this.addonAfter || [],
      addonBefore: this.addonBefore || [],
    };

    if (props.addons) {
      let Addons = props.addons;
      if (!Array.isArray(Addons)) {
        Addons = [Addons];
      }
      const FieldContext = this.fieldContext;
      Addons = Addons.map((addon, addonIndex) => {
        if (typeof addon === 'function') {
          return (
            <FieldContext.Consumer key={`${this.key}.addon.consumer.${addonIndex}`}>
              {(consumerProps) => {
                return consumerProps && addon(consumerProps);
              }}
            </FieldContext.Consumer>
          );
        }
        return addon;
      });
      // for (let addon of Addons) {
      //   addonAfter.push(addon);
      // }

      extra.addons = Addons;
    }

    // if (this.key === 'fromBerth') {
    //   console.log('col: ');
    // }

    if (this.label || asText) {
      return <SelectLabel col={this} {...props} {...extra} />;
    }
    return <SelectObserver col={this} {...props} {...extra} />;
  }
}

export default SelectComponent;
