import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, FormItem, gridCol2, gridRow } from '@ois-lib';

@inject('lineStore', 'appStore')
@observer
class EditLine extends EditFormBase {
  title = 'Līnijas kartiņa';
  storeName = 'lineStore';
  baseUrl = '/planning/lines';

  async componentDidMount() {
    this.load(this.props.lineStore);
  }

  renderForm() {
    return (
      <>
        <br />
        <Row {...gridRow}>
          <Card title="Parametri" bordered className="mt15">
            <Col {...gridCol2}>
              <FormItem asText name="name" />
              <FormItem asText name="companyId" />
              <FormItem asText name="allowedDeviation" />
              <FormItem asText disabled required={false} name="isOperating" />
              <FormItem asText name="registrationDate" />
              <FormItem asText name="endDate" />
              <FormItem asText disabled name="hasTimetable" />
            </Col>
          </Card>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'lines' })(EditLine));
