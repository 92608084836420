import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import FontAwesomeIcon from '../../assets/icon/FontAwesomeIcon';

const { SubMenu } = Menu;
const MenuLink = ({ to, children, appStore, authStore, ...props }) => {
  return (
    <Menu.Item {...props}>
      <Link
        to={to}
        onClick={() => {
          if (!appStore.windowWidth.desktop) {
            appStore.setCollapse(true);
          }
        }}
      >
        {children}
      </Link>
    </Menu.Item>
  );
}

@inject('appStore', 'authStore')
@observer
class CabinetSidebar extends React.Component {
  constructor(props) {
    super(props);

    const { authStore, stores, appStore } = props;
    this.renderMenu = (routes, level = 0) => {
      return routes
        .map(({ title, path, hidden, icon, children, perms, showIf }) => {
          if (perms) {
            let access = false;
            for (const perm of perms) {
              if (authStore.permissions && authStore.permissions.includes(perm)) {
                access = true;
                break;
              }
            }
            if (!access) {
              return null;
            }
          }

          if (showIf && typeof showIf === 'function' && !showIf(stores)) {
            return null;
          }

          if (children && children.filter((c) => !c.hidden).length) {
            const childNodes = this.renderMenu(
              children.filter((c) => !c.hidden),
              level + 1
            ).filter((route) => !!route);
            if (!childNodes.length) {
              return null;
            }

            return (
              <SubMenu
                key={path}
                title={
                  <span
                    style={!level && appStore.menuSelected.includes(path) ? { color: '#fff' } : undefined}
                  >
                    {icon ? <FontAwesomeIcon icon={icon} /> : ''}
                    <span>{title || ''}</span>
                  </span>
                }
              >
                {childNodes}
              </SubMenu>
            );
          }

          return (
            ((!showIf || (typeof showIf === 'function' && !!showIf(stores))) && !hidden && path && (
              <MenuLink key={path || title} to={path} appStore={appStore}>
                {icon ? (
                  <span>
                    <FontAwesomeIcon icon={icon} />
                    <span>{title}</span>
                  </span>
                ) : (
                  title
                )}
              </MenuLink>
            )) ||
            ''
          );
        })
        .filter((route) => !!route);
    };
  }

  componentDidMount() {
    const { appStore } = this.props;
    this.menu = this.renderMenu(toJS(appStore.routes, { recurseEverything: true }));
  }

  render() {
    const { props } = this;
    const { authStore, appStore, mode } = props;

    if (!authStore.permsTrasformed || this.selectedItems !== toJS(appStore.menuSelected)) {
      this.menu = this.renderMenu(toJS(appStore.routes, { recurseEverything: true }));
      this.selectedItems = toJS(appStore.menuSelected);
    }

    return (
      <Menu
        forceSubMenuRender
        className="cabinet_sidebar"
        selectedKeys={appStore.menuSelected}
        openKeys={appStore.menuOpen}
        onOpenChange={appStore.setMenuOpen}
        theme="dark"
        mode={mode || 'inline'}
      >
        {this.menu}
      </Menu>
    );
  }
}

export default CabinetSidebar;
