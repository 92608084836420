import { observable, action, runInAction, computed, toJS } from 'mobx';
import ErrorForbidden from '../exceptions/ErrorForbidden';
import api from '../services/api';

class AuthStore {
  api = api;
  warnBefore = 180;
  logging = false;
  backUrl = null;
  isUserSet = false;

  @observable permsTrasformed = false;
  @observable sessionRemaining = 0;
  @observable loggedIn = false;
  @observable user = {};
  @observable loading = true;
  @observable perms = {};
  @observable permissions = [];

  @computed get sessionWarningVisible() {
    return this.sessionRemaining && this.sessionRemaining < this.warnBefore && this.sessionRemaining > 0;
  }

  @action.bound setSessionRemaining(t) {
    this.sessionRemaining = t;
  }

  @computed get sessionRemainingTxt() {
    const sec = this.sessionRemaining % 60;
    return `${(this.sessionRemaining / 60) | 0}:${sec < 10 ? '0' : ''}${sec}`;
  }

  @computed get userRoles() {
    return this.user && this.user.roles ? toJS(this.user.roles, { recurseEverything: true }) : [];
  }

  @action.bound async login(params) {
    this.logging = true;
    const res = await api.login({ ...params, email: params.username });

    if (res && res.access_token) {
      api.setToken(res.access_token);
      window.localStorage.setItem('token', res.access_token);
      if (!this.loggedIn && !this.isUserSet) {
        if (res.user) {
          this.loggedIn = true;
          this.user = res.user;
        } else {
          this.loggedIn = true;
          this.user = await api.me();
        }
        if (this.user) {
          this.transformPerms();
          this.isUserSet = true;
        }
      }
      this.logging = false;
      return { ok: true };
    } else {
      console.error('Failed to fetch', res);
    }
    this.logging = false;
    return { ...res, ok: false };
  }

  @action.bound async requestPasswordReset(email) {
    const res = await api.getResetToken({ email });
    return res;
  }

  @action.bound async checkPasswordResetToken(token) {
    const res = await api.checkPasswordResetToken({ token });
    return res;
  }

  @action.bound async resetPassword(params) {
    const res = await api.resetPassword(params);
    return res;
  }

  @action.bound async logout(withApiCall = true) {
    this.backUrl = null;
    this.logging = true;
    this.loggedIn = false;
    // if (this.user && this.user.id && window.echo) {
    //   window.echo.private(`App.User.${this.user.id}`).stopListening('.messages');
    //   window.echo.disconnect();
    // }
    // api.socket.disconnect();
    if (withApiCall) {
      const res = await api.logout({});
      if (res && res.success) {
        window.localStorage.removeItem('token');
        // window.echo = undefined;
      }
    } else {
      window.localStorage.removeItem('token');
      // window.echo = undefined;
    }
    this.isUserSet = false;
    this.user = {};
    this.permsTrasformed = false;
    this.logging = false;
    api.setToken();
  }

  constructor() {
    setInterval(async () => {
      const tokenExp = api.getExp();
      const sessionRemaining = tokenExp ? Math.floor((tokenExp - Date.now()) / 1000) : 0;
      this.setSessionRemaining(sessionRemaining);
      if (tokenExp && sessionRemaining < 1) {
        if (this.loggedIn) {
          if (window.location) {
            this.backUrl = window.location.href.replace(window.location.origin, '');
          }
          this.logout(false);
        }
      } else {
        if (this.loggedIn && !this.isUserSet) {
          const res = api.me().then((res) => res);
          if (res) {
            this.user = res;
            this.transformPerms();
            this.isUserSet = true;
          }
        }
      }
    }, 1000);

    if (!this.loggedIn) {
        if (typeof window !== 'undefined' && window.location) {
          this.backUrl = window.location.href.replace(window.location.origin, '');
      }
    }

    if (typeof window !== 'undefined' && window.localStorage && window.localStorage.getItem('token')) {
      api.setToken(window.localStorage.getItem('token'));
      setTimeout(async () => {
        try {
          const res = await api.me();
          if (res) {
            this.user = res;
            this.transformPerms();
            this.isUserSet = true;
          }
        } catch (e) {
          runInAction(() => {
            this.loggedIn = false;
            this.loading = false;
          });
        }
        runInAction(() => {
          this.loggedIn = true;
          this.loading = false;
        });
      });
    } else {
      this.loggedIn = false;
      this.loading = false;
    }
  }

  can = (perm) => {
    if (this.permsTrasformed) {
      if (Array.isArray(perm)) {
        return perm.reduce((acc, el) => !!this.perms[el] || acc, false);
      }
      return !!this.perms[perm];
    }
    return false;
  };

  authorize = (perms = []) => {
    let returnValue = false;
    if (this.permsTrasformed) {
      for (const perm of perms) {
        if (this.can(perm)) {
          returnValue = true;
        }
      }
      if (!returnValue) {
        throw new ErrorForbidden('Pieeja liegta');
      }
    }
    return returnValue;
  };

  transformPerms() {
    this.permissions = this.user.perms;
    this.perms = {};
    const perms = {};
    if (this.user.perms) {
      for (const perm of this.user.perms) {
        perms[perm] = true;
      }
      this.perms = perms;
      this.permsTrasformed = true;
    }
  }
}

// Singleton
const store = new AuthStore();

export default store;
