import React from 'react';
import { Upload, Button, Icon } from 'antd';
import api from '../services/api';
import { Field } from './Field';
import FilesRenderer, { FilesList } from '../components/FilesRenderer';

const FilesListComponent = ({ value = [], asText, onChange, parent }) => {
  return (
    <>
      {!asText && (
        <Upload
          ref={(_ref) => {
            if (_ref) {
              parent.uploadRef = _ref;
            }
          }}
          action={`${api.base}blobs`}
          headers={{ Authorization: api.headers.Authorization }}
          onChange={({ file, fileList }) => {
            if (file && file.status === 'done' && file.response) {
              fileList
                .filter((f) => file.uid === f.uid)
                .forEach((f) => {
                  if (f.response && f.response.data && f.response.data.id) {
                    f.blobId = f.response.data.id;
                    f.guid = f.response.data.guid;
                    f.url = `/api/v1/download/${f.guid || ''}`;
                  }
                });
            }

            const set = new Set([...(value || []), ...fileList]);
            if (onChange) {
              onChange([...set]);
            }
            return [...set];
          }}
          multiple
          showUploadList={false}
        >
          <Button>
            <Icon type="upload" />
            Augšupielādēt
          </Button>
        </Upload>
      )}
      <FilesList asText={asText} allowDelete deleteCallback={onChange} parent={parent} files={value} />
    </>
  );
};

@Field.register
class FilesComponent extends Field {
  static code = 'files';

  uploadRef = null;

  constructor(col) {
    super(col);
    this.render = FilesRenderer;
  }

  loadRowTransform(row) {
    return <FilesListComponent value={row[this.key]} parent={this} asText />;
  }

  renderComponent(props) {
    if (props.ref) {
      delete props.ref;
    }
    if (this.label || props.asText) {
      props.asText = true;
    }
    return <FilesListComponent {...props} parent={this} />;
  }
}

export default FilesComponent;
