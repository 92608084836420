import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol, gridCol2 } from '@ois-lib';

@inject('appStore', 'authorize', 'can')
@observer
class EditCargo extends EditFormBase {
  title = 'Krava';
  storeName = 'store';

  canSave() {
    return true;
    // return this.props.can('edit ship_visits.cargo');
  }

  canDelete() {
    return true;
    // return this.props.can('delete ship_visits.cargo');
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.cargo', 'edit ship_visits.cargo']);
    const isDangerous = this.props.parentProps ? this.props.parentProps.isCargoDangerous : false;
    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Kravas informācija" bordered={false}>
            <Row {...gridRow}>
              <Col {...gridCol2}>
                <FormItem name="containerNo" />
                <FormItem name="containerTypeId" />
                <FormItem name="mass" />
                <FormItem name="packageContentGrossWeight" />
                <FormItem name="packageContentNetWeight" />
                <FormItem name="packageType" />
                <FormItem name="packageQuantity" />
                <FormItem name="cargoDescription" />
                <FormItem name="technicalTitle" hidden={!isDangerous} />
                <FormItem name="ano" hidden={!isDangerous} />
                <FormItem name="imo" hidden={!isDangerous} />
                <FormItem name="isManifestPresent" hidden={!isDangerous} />
              </Col>
              <Col {...gridCol2}>
                <FormItem name="transitPortId" />
                <FormItem name="destinationPortId" />
                <FormItem name="cargoStatusId" />
                <FormItem name="transitExport" />
                <FormItem name="cargoTypeId" />
                <FormItem name="cargoGroupId" />
                <FormItem name="cargoQuantity" />
                <FormItem name="unitId" />
                <FormItem name="shipTypeId" hidden={!isDangerous} />
                <FormItem name="locationOnBoard" hidden={!isDangerous} />
                <FormItem name="transportUnitId" hidden={!isDangerous} />
                <FormItem name="contacts" hidden={!isDangerous} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditCargo' })(EditCargo);
