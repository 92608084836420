import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Tooltip, Badge, List, Popover, notification, Button } from 'antd';
import { toJS, runInAction } from 'mobx';
import Echo from 'laravel-echo';
import moment from 'moment';
import { FontAwesomeIcon } from '@ois-lib';
import api from '@ois-lib/services/api';

const getVariables = (item) => {
  if (!item) {
    return {};
  }

  const { id, type, date } = item;
  let { body } = item;

  if (body) {
    body = JSON.parse(body);
  }

  if (body && body.type == 'custom') {
    return {
      // id,
      // body,
      type: 'custom',
      action: body.message,
      link: body.link,
      icon: 'engine-warning',
      message: body.title,
    };
  }

  if (!type) {
    return item;
  }

  let action = 'skatītu ierakstu';
  let icon = 'ship';
  let link = '/';
  let message = type ? type.name : ' - ';

  action = 'skatītu kuģa vizīti';
  link = `/live/expected/${body.id || ''}`;

  switch (type.code) {
    case 'visit new':
      break;
    case 'visit updated':
    case 'visit updated agent':
      if (body.custom) {
        link = `/live/expected/${body.id || ''}`;
        message = body.message;
        if (body.action) {
          action = body.action;
        }
        break;
      }

      if (body.updated_by === -1) {
        // SKLOIS
        message = `Aģents labojis ${body.ship || ' - '} vizīti.`;
      } else if (body.updated_by_name) {
        // User
        message = `${body.updated_by_name}${
          body.updated_by_object && body.updated_by_object === 'oks' ? ' (OKS)' : ''
        } laboja ${body.ship || ' - '} vizīti.`;
      }
      break;
    // Status change
    case 'visit arrival rejected':
    case 'visit departure rejected':
    case 'visit arrival accepted':
    case 'visit departure accepted':
      const statuses = {
        rejected: 'aizliedza',
        accepted: 'atļāva',
      };
      const directions = {
        arrival: 'ienākt',
        departure: 'iziet',
      };
      const statusIcons = {
        rejected: <FontAwesomeIcon icon="times-octagon" type="solid" />,
        accepted: <FontAwesomeIcon icon="shield-check" type="solid" />,
      };
      message = (
        <span className={`status-text status-${body.status}`}>
          {statusIcons[body.status]}
          &nbsp;
          {`${body.org || ' - '} ${statuses[body.status]} ${directions[body.direction]} ${body.ship}`}
        </span>
      );
      if (body.directions === 'departure') {
        icon = 'arrow-up';
      }
      if (body.directions === 'arrival') {
        icon = 'arrow-down';
      }
      break;
    case 'visit comment':
      break;
    case 'visit dangerouscargo':
      break;
    case 'ship movement':
      message=body.message;
      if (body.type){
        if (body.type==='stopped'){
          link='/live/berthed/'+body.id;
        }
        else if (body.type==='started'){
          link='/statistics/visits/'+body.id;
        }
        else if (body.type==='incoming'){
          link='/live/expected/'+body.id;
        }
        else if (body.type==='outgoing'){
          link='/statistics/visits/'+body.id;
        }
      }
      break;
    case 'custom':
      break;
    default:
  }

  return {
    id,
    body,
    type,
    date,
    action: `Klikšķniet, lai ${action}`,
    link,
    icon,
    message,
  };
};

const Menu = ({ data, total, markAsRead, history, fetchMoreData }) => (
  <List
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => {
      const { id, date, link, icon, message, type } = getVariables(item.notification);
      let style={};
      if (type.code==='ship movement'){
        style.backgroundColor='#D0F0FF';
      }
      return (
        <div key={'notification-'+type+'-'+id} style={style}>
          <List.Item
            key={id}
            onClick={() => {
              markAsRead(id);
              history.push(link);
            }}
          >
            <List.Item.Meta
              avatar={<FontAwesomeIcon icon={icon} className="icon-check" />}
              title={message}
              description={moment(date).format('L LTS')}
            />
          </List.Item>
        </div>
      );
    }}
    footer={
      !!data.length && (
        <div className="button-footer">
          {total > data.length && (
            <Button type="primary" onClick={() => fetchMoreData()}>
              Ielādēt vēl...
            </Button>
          )}
          <Button onClick={() => markAsRead('all')}>Dzēst visus paziņojumus</Button>
        </div>
      )
    }
  />
);

@inject('appStore', 'authStore', 'notificationStore', 'can')
@observer
class AppToolbar extends React.Component {
  windowFocused = true;

  constructor(props) {
    super(props);
    this.state = {
      notificationsVisible: false,
    };

    // if (!window.echo && window.localStorage.getItem('token')) {
    //   const params = {
    //     broadcaster: 'pusher',
    //     key: 'WpYNL6xPfoXnN3VUfYqjkNrigfszf0Xh', // proccess.env.ECHO_KEY
    //     wsHost: window.location.hostname,
    //     wsPort: window.location.port ? 8000 : 6001,
    //     disableStats: true,
    //     enabledTransports: ['ws', 'wss'],
    //     auth: {
    //       headers: {
    //         Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    //       },
    //     },
    //   };
    //   // if (window.location.port) {
    //   //   params.wsPort = 8000;
    //   // }

    //   window.echo = new Echo(params);
    // }
  }

  componentDidMount() {
    const {
      authStore,
      notificationStore,
      appStore: { history },
    } = this.props;

    if (!notificationStore.firstLoad && !notificationStore.data.length) {
      notificationStore.load();
      notificationStore.firstLoad = true;
    }

    // if (authStore.user && authStore.user.id && window.echo) {
    //   this.lastUserId = authStore.user.id;
    //   window.echo.private(`App.User.${this.lastUserId}`)

    api.socket.listenUser('.messages', (item) => {
      if (notificationStore && !notificationStore.loading) {
        notificationStore.refresh();
      }

      const { id, type, link, icon, action, message } = getVariables(item);
      notification.open({
        key: `message.window.${type}`,
        message,
        description: `${action}`,
        icon: <FontAwesomeIcon icon={icon} />,
        onClick: () => {
          this.markAsRead(id);
          notification.close(`message.window.${type}`);
          history.push(link);
        },
      });
    });
    //   window.echo.channel('ais').listen('ais', (item) => {
    //     console.log('ais', item);
    //   });
    // }

    // runInAction(() => {
    //   if (!authStore.user || (authStore.user && !authStore.user.id)) {
    //     window.echo.private(`App.User.${authStore.user.id}`).stopListening('.messages');
    //   }
    // });
  }

  fetchNotifications = () => {
    const { notificationStore } = this.props;
    notificationStore.pageSize += 10;
    notificationStore.refresh();
  };

  markAsRead = async (id = null) => {
    if (id) {
      const { notificationStore } = this.props;
      const res = await notificationStore.call(
        'read',
        {
          id,
        },
        id
      );
      if (res) {
        notificationStore.refresh();
      }
      this.setState({
        notificationsVisible: false,
      });
    }
  };

  // eslint-disable-next-line camelcase
  componentWillUnmount() {
    api.socket.leaveUser();
    // const { authStore } = this.props;
    // if (authStore.user && authStore.user.id && window.echo) {
    //   window.echo.private(`App.User.${authStore.user.id}`).stopListening('.messages');
    // }
  }

  render() {
    const {
      appStore,
      authStore,
      notificationStore,
      appStore: { history },
      can,
    } = this.props;
    const { notificationsVisible } = this.state;
    const data = toJS(notificationStore.data, { recurseEverything: true });
    const count = data.filter((i) => !i.read).length;
    const total = notificationStore.totalRecords;
    // Vai tiešām ir kāds kurš nevar skatīt help?
    // const viewHelp = can('view help') || can('edit help');
    // console.log(viewHelp);
    return (
      <div className="site_header_right_icons">
        <Popover
          content={
            <Menu
              data={data}
              markAsRead={this.markAsRead}
              history={history}
              total={total}
              fetchMoreData={this.fetchNotifications}
            />
          }
          placement="bottomLeft"
          width="320px"
          title={
            <span>
              Paziņojumi&nbsp;
              {!!count && !!total && `(${count}/${total})`}
            </span>
          }
          trigger="click"
          visible={notificationsVisible}
          onVisibleChange={(visible) => this.setState({ notificationsVisible: visible })}
          overlayClassName="notifications-popover"
        >
          <Link to="/">
            <Tooltip placement="bottom" title="Paziņojumi">
              <Badge count={total}>
                {' '}
                {/* dot */}
                <FontAwesomeIcon icon="bell" className="icon-check" />
              </Badge>
            </Tooltip>
          </Link>
        </Popover>
          <Link
            to="/help"
            onClick={() => {
              if (!appStore.windowWidth.desktop) {
                appStore.setCollapse(true);
              }
            }}
          >
            <Tooltip placement="bottom" title="Palīdzība">
              <FontAwesomeIcon icon="question-circle" className="icon-check" />
            </Tooltip>
          </Link>
        
        <Link
          to="/profile/edit"
          onClick={() => {
            if (!appStore.windowWidth.desktop) {
              appStore.setCollapse(true);
            }
          }}
        >
          <Tooltip placement="bottom" title="Iestatījumi">
            <FontAwesomeIcon icon="cog" className="icon-check" />
          </Tooltip>
        </Link>
        <span className="pointer" aria-hidden onClick={() => authStore.logout()}>
          <Tooltip placement="bottomLeft" title="Beigt darbu">
            <FontAwesomeIcon icon="sign-out" className="icon-check" />
          </Tooltip>
        </span>
      </div>
    );
  }
}

export default AppToolbar;
