import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import EditInvoice from './EditInvoice';

@inject('invoiceStore', 'authorize', 'can')
@observer
class Invoices extends React.Component {
  static Edit = EditInvoice;

  title = 'Rēķini';

  componentDidMount() {
    const { invoiceStore } = this.props;
    if (this.props.invoiceStore.columnSorters && !this.props.invoiceStore.columnSorters.length) {
      this.props.invoiceStore.columnSorters.push({
        key: 'date',
        direction: 'desc',
      });
    }
    invoiceStore.load();
  }

  render() {
    const { isWidget, invoiceStore, history, /*authorize,*/ can } = this.props;
    // authorize(['view invoices', 'edit invoices']);

    const Widget = (
      <DataTable
        store={invoiceStore}
        title={this.title}
        onRowClick={(row) => history.push(`/finance/invoices/${row.id || ''}`)}
        onAdd={can('edit invoices') && (() => history.push('/finance/invoices/new'))}
      />
    );
    return isWidget ? (
      Widget
    ) : (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>{Widget}</Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Invoices);
