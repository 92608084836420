import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Card, Form, Tabs, Radio, Button /*, Alert, message, Collapse, List, Tooltip*/ } from 'antd';
// import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
// import moment from 'moment-timezone';
import { DataTable, EditFormBase, FormItem, gridRow, gridCol, gridCol2, FontAwesomeIcon } from '@ois-lib';
import StoreFactory from '@ois-lib/stores';
// import EditOperationalNote from '@ois-lib/routes/EditOperationalNote';
import {
  EditCrewMember,
  // EditPassenger,
  EditCargoConsignment,
  EditDangerousCargoConsignment,
  EditShipDocument,
  // EditShipsStore,
  EditWasteDisposal,
  EditMovement,
  // EditPilotBill,
  EditBerthVisit,
  // EditShipOperation,
  // EditResourceRequest,
  // EditResourceTask,
} from './Forms';
import { toJS } from 'mobx';

const { TabPane } = Tabs;
// const FormatedColumn = ({ children }) => (
//   <Row>
//     <Col {...{ xs: 24, sm: 8 }} />
//     <Col {...{ xs: 24, sm: 16 }}>{children}</Col>
//   </Row>
// );

// function ShipToolTip({ row, ...props }) {
//   const ship = row._indicators && row._indicators.ships && row._indicators.ships.in;
//   const { children } = props;
//   const { name: v } = ship;
//   const sd = [
//     { t: 'SHIP NAME', v },
//     { t: 'IMO', v: row.imoIn },
//     { t: 'TYPE', v: ship ? ship.shipTypeId : '' },
//     { t: 'CALL SIGN', v: ship ? ship.mmsi : '' },
//     {
//       t: 'GT',
//       // eslint-disable-next-line no-nested-ternary
//       v: row.gt ? row.gt : ship ? ship.grossWeight : ' ',
//     },
//     { t: 'DW (S or W)', v: ship ? ship.deadweight : '' },
//     { t: 'ICE CLASS', v: ship ? ship.iceClass : '' },
//     {
//       t: 'ENGINE POWER (KW)',
//       v: ship ? ship.enginePower : '',
//     },
//     { t: 'BOW THRUSTER (KW)', v: '' },
//     { t: 'STERN THRUSTER (KW)', v: '' },
//     { t: 'RUDDER TYPE', v: '' },
//     { t: 'PROPPELER TYPE', v: '' },
//     { t: 'LOA', v: ship ? ship.length : '' },
//     { t: 'BREATH', v: ship ? ship.width : '' },
//     { t: 'ACTUAL MAX FW DRAFT', v: '' },
//   ];
//   return (
//     <span>
//       <Tooltip
//         key="shipInfo"
//         placement="right"
//         title={
//           <small>
//             <table>
//               <tbody>
//                 {sd.map((d, key) => (
//                   <tr key={key}>
//                     <td className="mb0">{`${d.t}:`}</td>
//                     <td>
//                       {' '}
//                       <b>{d.v}</b>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </small>
//         }
//       >
//         {children}
//       </Tooltip>
//       {/* <br />
//         <small title="IMO">{row.imoIn}</small> */}
//     </span>
//   );
// }

@inject('appStore', 'authStore', 'changeRecordsStore', 'authorize', 'can')
@observer
class EditShipVisit extends EditFormBase {
  title = 'Vizītes kartiņa';
  storeName = 'shipVisits';
  baseUrl = '/live/lines';
  defaultActiveSection = {};
  changeRecordsStore = null;

  constructor(props) {
    super(props);
    /*
    if (props.title) {
      this.title = props.title;
    }
    */

    if (props.match && props.match.path) {
      this.baseUrl = props.match.path.replace(':id', '');
    }
    this.state = {
      cargoDirection: 'IN',
      passengersDirection: 'IN',
      wasteDirection: 'IN'
    };
    this.store = StoreFactory.getStore('shipVisits', props.filter ? props.filter.toUpperCase() : undefined);
    this.store.systemFilters = props.filter ? { status: props.filter.toUpperCase() } : {};

    const history = props.appStore.useBrowserHistory();
    const {
      location: { searchParams },
    } = history;

    if (searchParams.tabs) {
      const sections = searchParams.tabs.split(',');
      for (let section of sections) {
        section = section.split(':');
        const key = section[0] ? `${section[0]}`.trim() : null;
        const value = section[1] ? `${section[1]}`.trim() : undefined;
        if (key) {
          switch (key) {
            case 't':
            case 'b':
              this.defaultActiveSection[key] = value;
              break;
            default:
          }
        }
      }
    }
  }

  setTitle(title) {
    const recordTitle = this.props.form.getFieldValue('skloisVisitCode');
    super.setTitle(`${recordTitle || ''}${` ${title}`}`);
    return `${title}${(recordTitle && `: ${recordTitle}`) || ''}`;
  }

  // if (company && shipAgentId && company.id !== shipAgentId) {
  //   authorize(['view ship_visits']);
  // }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    const { store } = this;
    if (store.item.id) {
      let shipAgentId = store.item && store.item.shipAgentId;
      const { authStore, authorize } = this.props;
      const { user } = authStore;
      const { company } = toJS(user);

      if (company && shipAgentId && company.id !== shipAgentId) {
        authorize(['view ship_visits']);
      }
    }
  }

  async componentDidMount() {
    const {
      authStore: { user },
      changeRecordsStore,
      match,
      can,
    } = this.props;

    const agentCompanyId = user && user.companyId ? user.companyId : null;
    if (agentCompanyId) {

      this.store.item.shipAgentId = agentCompanyId;
      this.props.form.setFieldsValue({ shipAgentId: agentCompanyId });
    }

    if (match.params.id !== 'new' && can('view change_record')) {
      this.changeRecordsStore = changeRecordsStore;
      this.changeRecordsStore.systemFilters = {
        objectType: [this.store.dataSet],
        objectId: [match.params.id],
      };
      this.changeRecordsStore.load();
    }
    this.load(this.store);
  }

  // canSave() {
  //   console.log("Calling this.canSave()");
  //   // if (this.store.item.document_source === 'Aģents') return true;
  //   return false;
  //   // const { filter } = this.props;
  //   // return filter === 'VISIT_REQUEST';
  // }

  canDelete() {
    return false;
  }

  /*
  updatePermit = async (params) => {
    const { store } = this;
    if (params.direction) {
      params.comment = this.props.form.getFieldValue(
        `_shipVisitPermitComment${params.direction === 'in' ? 'In' : 'Out'}`
      );
    }
    const ret = await store.call('permit', params);
    if (ret) {
      if (ret.data) {
        const { incomingPermits, outgoingPermits } = ret.data;
        let action = 'saglabāta';
        switch (params.status) {
          case 'accepted':
            action = 'apstiprināta';
            break;
          case 'rejected':
            action = 'noraidīta';
            break;
          case 'commented':
          default:
        }
        message.success(
          `Vizītes ${params.direction === 'in' ? 'ienākšanas' : 'iziešanas'} atļauja veiksmīgi ${action}!`
        );
        this.props.form.setFieldsValue({
          incomingPermits,
          outgoingPermits,
          _shipVisitPermitCommentIn: null,
          _shipVisitPermitCommentOut: null,
        });
      } else if (ret.error) {
        let action = 'saglabājot';
        switch (params.status) {
          case 'accepted':
            action = 'apstiprinot';
            break;
          case 'rejected':
            action = 'noraidot';
            break;
          case 'commented':
            action = 'komentējot';
            break;
          default:
        }
        message.error(`Kļūda ${action} vizītes ${params.direction === 'in' ? 'ienākšanas' : 'iziešanas'} atļauju!`);
        console.error(ret.error);
      }
    }
  };
    */
  canEditShipVisit() {
    const {
      authStore: { user },
      // changeRecordsStore,
      // match,
      // can,
    } = this.props;

    const agentCompanyId = user && user.companyId ? user.companyId : null;
    if ((this.store.item.document_source === 'Aģents' && this.store.item.shipAgentId === agentCompanyId) || this.props.match.params.id === 'new') {
      this.canEdit = true;
      return true;
    }
    this.canEdit = false;
    return false;
  }

  renderForm() {
    this.forceDisableEdit = !this.canEditShipVisit();
    const { match } = this.props;
    // let skloisStatuses = [];
    if (this.store && this.store.item && (this.store.item.incomingPermits || this.store.item.outgoingPermits)) {
      /*
      const permits = [
        ...toJS(this.store.item.incomingPermits, { recurseEverything: true }),
        ...toJS(this.store.item.outgoingPermits, { recurseEverything: true }),
      ];

      skloisStatuses = permits
        .filter((p) => p.controlOrganization && p.controlOrganization.code === 'OP')
        .map((p) => {
          const time = p.updatedTime ? moment(p.updatedTime).tz('Europe/Riga').format() : '(laiks nav norādīts)';
          let coTitle = p.controlOrganization ? p.controlOrganization.name : '';
          let status = 'info';

          const skloisTime = p.skloisTime ? moment(p.skloisTime).tz('Europe/Riga').format() : '(laiks nav norādīts)';
          switch (p.skloisStatus) {
            case 'SENT':
              status = 'success';
              break;
            case 'FAILED':
              status = 'error';
              break;
            default:
          }
          if (!p.skloisStatus) {
            coTitle += ' - Gaida nosūtīšanu';
          } else {
            coTitle +=
              status === 'success'
                ? ` - Nosūtīts ${skloisTime}`
                : ` - Kļūda nosūtot datus uz SKLOIS, pēdejais neveiksmīgais meginājums ${skloisTime}`;
          }
          return {
            title: (p.direction === 'IN' ? 'Ienākšana: ' : 'Iziešana: ') + coTitle,
            direction: p.direction,
            time,
            status,
          };
        });
        */
    }

    // let historicalAgents = '';
    // if (this.store.item) {
    //   const { shipVisitAgents, ata } = this.store.item;
    //   if (shipVisitAgents && shipVisitAgents.length > 1) {
    //     const cloneAgents = [...shipVisitAgents];
    //     cloneAgents.pop();
    //     const data = cloneAgents.map((agent) => {
    //       const phone = agent.company.phone ? `tālr: ${agent.company.phone}, ` : '';
    //       const direction = ata && moment(ata).isSameOrBefore(moment(agent.created_at)) ? 'IZEJOT' : 'IENĀKOT';
    //       return `${agent.company.name}, ${phone}reģistrēts: ${moment(agent.created_at)
    //         .tz('Europe/Riga')
    //         .format()}, ${direction}`;
    //     });
    //     historicalAgents = (
    //       <Collapse>
    //         <Collapse.Panel header="Iepriekšējie aģenti" key="1">
    //           <List size="small" dataSource={data} renderItem={(item) => <List.Item>{item}</List.Item>} />
    //         </Collapse.Panel>
    //       </Collapse>
    //     );
    //   }
    // }

    return (
      <>
        <Row {...gridRow}>
          <Col {...gridCol}>
            <h1 className="cabinet_title">
              {this.title}
              <FormItem name="_indicators" label={null} />
            </h1>
          </Col>
        </Row>
        <Row {...gridRow}>
          <Col {...gridCol}>
            <Tabs defaultActiveKey={this.defaultActiveSection.t || undefined} type="card" className="content-tabs">
              <TabPane tab="Identifikātori" key="identifiers" forceRender>
                <Row gutter={[24, 24]} type="flex">
                  <Col {...gridCol2}>
                    <Card title="Ienākšana" size="small" className="pad30">
                      <FormItem name="shipInId" layout="horizontal" />
                      <FormItem name="eta" layout="horizontal" />
                      <FormItem name="ata" asText layout="horizontal" />
                      <FormItem name="fromPort" layout="horizontal" />
                      {/*<FormItem name="tugInShipVisitId" layout="horizontal" />*/}
                    </Card>
                  </Col>
                  <Col {...gridCol2}>
                    <Card title="Iziešana" size="small" className="pad30">
                      <FormItem name="shipOutId" layout="horizontal" />
                      <FormItem name="etd" layout="horizontal" />
                      <FormItem name="atd" asText layout="horizontal" />
                      <FormItem name="toPort" layout="horizontal" />
                      {/*<FormItem name="tugOutShipVisitId" layout="horizontal" />*/}
                    </Card>
                  </Col>
                  <Col {...gridCol2}>
                    <Card title="Aģents" size="small" className="pad30">
                      <FormItem name="shipAgentId" asText bold layout="horizontal" />
                      <FormItem name="shipAgentPhone" layout="horizontal" />
                      <FormItem name="shipRepresentativeId" layout="horizontal" />
                      <FormItem name="shipRepresentativePhone" layout="horizontal" />
                      {/*historicalAgents*/}
                    </Card>
                  </Col>
                  <Col {...gridCol2}>
                    <Card title="Mērķis" size="small" className="pad30">
                      <FormItem name="shipVisitPurposes" layout="horizontal" />
                      <FormItem name="operator" layout="horizontal" />
                      <FormItem name="onSchedule" layout="horizontal-wide" />
                      <FormItem name="charterer" layout="horizontal" />
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab="Apkalpe" key="crewMembers" forceRender>
                <FormItem
                  // disabled={!this.props.can('edit ship_visits.crew_members')}
                  formKind={EditCrewMember}
                  name="crewMembers"
                  noCard
                />

                {match.params.id !== 'new' && this.store.item.crewMembers && !!this.store.item.crewMembers.length && (
                  <Button
                    style={{ marginBottom: '25px' }}
                    href={`/api/v1/shipVisits/${match.params.id}/crewxlsx/`}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="file-spreadsheet" />
                    Lejupielādēt apkalpes sarakstu
                  </Button>
                )}
              </TabPane>


              {/*
                <TabPane tab="Pasažieri" key="passengers" forceRender>
                  <DataTable
                    title="Pasažieri"
                    name="passengers"
                    canAdd={match.params.id !== 'new'}
                    canEdit={this.props.can(['edit ship_visits.passengers']) && !this.forceDisableEdit}
                    modalForm={EditPassenger}
                    modalProps={{ width: '860px' }}
                    defaultFilters={{
                      shipVisitId: match.params.id !== 'new' ? match.params.id : -9999,
                      direction: [this.state.passengersDirection],
                    }}
                    actions={[
                      <Radio.Group
                        key="filter"
                        className="table-ffradio-actions"
                        value={this.state.passengersDirection}
                        onChange={(e) => {
                          this.setState({ passengersDirection: e.target.value });
                        }}
                      >
                        <Radio key="IN" value="IN">
                          Ienākot
                        </Radio>
                        <Radio key="OUT" value="OUT">
                          Izejot
                        </Radio>
                      </Radio.Group>,
                    ]}
                    fixedFormValues={{
                      shipVisitId: match.params.id,
                      direction: this.state.passengersDirection,
                    }}
                  />
                </TabPane>
            */}

              <TabPane tab="Kravas" key="cargo" forceRender>
                <DataTable
                  title="Kravas"
                  name="cargo"
                  canAdd={this.canEdit}
                  canEdit={this.canEdit}
                  modalForm={EditCargoConsignment}
                  modalProps={{ width: '860px' }}
                  defaultFilters={{
                    shipVisitId: match.params.id !== 'new' ? match.params.id : -9999,
                    direction: [this.state.cargoDirection],
                    isDangerous: [null],
                  }}
                  actions={[
                    <Radio.Group
                      key="filter"
                      className="table-ffradio-actions"
                      value={this.state.cargoDirection}
                      onChange={(e) => {
                        this.setState({ cargoDirection: e.target.value });
                      }}
                    >
                      <Radio key="IN" value="IN">
                        Ienākot
                      </Radio>
                      <Radio key="OUT" value="OUT">
                        Izejot
                      </Radio>
                    </Radio.Group>,
                  ]}
                  fixedFormValues={{
                    shipVisitId: match.params.id,
                    direction: this.state.cargoDirection,
                  }}
                />
                {/*
                  <Col {...gridCol}>
                    <FormItem name="cargoDescriptionIn" asText={!!this.store.item.skloisVisitCode} />
                    <FormItem name="cargoDescriptionOut" asText={!!this.store.item.skloisVisitCode} />
                    <FormItem name="comparedWithCargoManifesto" />
                  </Col>
                    */}
              </TabPane>

              <TabPane tab="Bīstamās kravas" key="dangerousCargo" forceRender>
                <DataTable
                  title="Bīstamās kravas"
                  name="dangerousCargo"
                  canAdd={this.canEdit}
                  canEdit={this.canEdit}
                  modalForm={EditDangerousCargoConsignment}
                  modalProps={{ width: '860px' }}
                  defaultFilters={{
                    shipVisitId: match.params.id !== 'new' ? match.params.id : -9999,
                    direction: [this.state.cargoDirection],
                    isDangerous: [true],
                  }}
                  actions={[
                    <Radio.Group
                      key="filter"
                      className="table-ffradio-actions"
                      value={this.state.cargoDirection}
                      onChange={(e) => {
                        this.setState({ cargoDirection: e.target.value });
                      }}
                    >
                      <Radio key="IN" value="IN">
                        Ienākot
                      </Radio>
                      <Radio key="OUT" value="OUT">
                        Izejot
                      </Radio>
                    </Radio.Group>,
                  ]}
                  fixedFormValues={{
                    shipVisitId: match.params.id,
                    direction: this.state.cargoDirection,
                    isDangerous: true,
                  }}
                />
                {/* <FormItem
                    disabled={!this.props.can('edit ship_visits.cargo')}
                    formKind={EditDangerousCargoConsignment}
                    name="dangerousCargoConsignments"
                    noCard
                    modalProps={{ width: '860px' }}
                  /> */}
              </TabPane>

              <TabPane tab="Dokumenti" key="documents" forceRender>
                <FormItem
                  // disabled={!this.props.can('edit ship_visits.documents')}
                  formKind={EditShipDocument}
                  name="documents"
                  noCard
                />
              </TabPane>

              {/*
                <TabPane tab="Kuģu krājumi" key="stores" forceRender>
                  <FormItem
                    disabled={!this.props.can('edit ship_visits.stores')}
                    formKind={EditShipsStore}
                    name="stores"
                    noCard
                  />
                </TabPane>
               */}



              <TabPane tab="Kuģu atkritumi" key="waste" forceRender>
                <DataTable
                  title="Kuģu atkritumi"
                  name="waste"
                  canAdd={this.props.can(['edit ship_visits.waste']) && this.canEdit}
                  canEdit={this.props.can(['edit ship_visits.waste']) && this.canEdit}
                  modalForm={EditWasteDisposal}
                  showExcelExport={false}
                  modalProps={{ width: '860px' }}
                  defaultFilters={{
                    shipVisitId: match.params.id !== 'new' ? parseInt(match.params.id, 10) : -9999,
                    direction: [this.state.wasteDirection],
                  }}
                  actions={[
                    <Radio.Group
                      key="filter"
                      className="table-ffradio-actions"
                      value={this.state.wasteDirection}
                      onChange={(e) => {
                        this.setState({ wasteDirection: e.target.value });
                      }}
                    >
                      <Radio key="IN" value="IN">
                        Ienākot
                      </Radio>
                      <Radio key="OUT" value="OUT">
                        Izejot
                      </Radio>
                    </Radio.Group>,
                  ]}
                  fixedFormValues={{
                    shipVisitId: match.params.id,
                    direction: this.state.wasteDirection,
                  }}
                  noCard
                />
                {/* Do we need this */}
                {/* <FormItem
                  // disabled={!this.props.can('edit ship_visits.waste')}
                  formKind={EditWasteDisposal}
                  name="waste"
                  noCard
                />

                <Col {...gridCol2}>
                  <FormItem asText={!this.props.can('edit ship_visits.waste')} name="wDPreviousPort" />
                  <FormItem asText={!this.props.can('edit ship_visits.waste')} name="wDPreviousPortTime" />
                  <FormItem asText={!this.props.can('edit ship_visits.waste')} name="wDNextPort" />
                </Col>
                <Col {...gridCol2}>
                  <FormItem asText={!this.props.can('edit ship_visits.waste')} name="wDDisposalAmount" />
                  <FormItem asText={!this.props.can('edit ship_visits.waste')} name="wDInsufficientPower" />
                  <FormItem asText={!this.props.can('edit ship_visits.waste')} name="wDDataCompared" />
                  <FormItem asText={!this.props.can('edit ship_visits.waste')} name="wDDeclarationDate" />
                  <FormatedColumn>
                    <Button
                      onClick={() => {
                        // console.log("event@Button->onClick: ", event);
                      }}
                    >
                      <FontAwesomeIcon icon="print" />
                      Nosūtīt
                    </Button>
                  </FormatedColumn>
                </Col> */}
              </TabPane>
              {/*

              <TabPane tab="Līnija" key="shipping-line" forceRender>
                <Row {...gridRow} style={{ marginTop: 15 }}>
                  <Col {...gridCol2}>
                    <FormItem name="inShippingLineId" />
                    <FormItem name="outShippingLineId" />
                  </Col>
                </Row>
              </TabPane> */}

              {/* match.params.id !== 'new' && this.props.can('view change_record') && (
                <TabPane tab="Izmaiņu žurnāls" key="changeRecords" forceRender>
                  <Row {...gridRow} style={{ marginTop: 15 }}>
                    <Col {...gridCol}>
                      {this.changeRecordsStore && (
                        <DataTable
                          store={this.changeRecordsStore}
                          title="Izmaiņu žurnāls"
                          hideSearchRow
                          onAdd={false}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
              )*/}
            </Tabs>
          </Col>
          <Col {...gridCol}>
            {/*
            <Card size="small" bordered style={{ width: '100%', borderTop: 0, marginTop: -1 }}>
              <Card bordered className="tabs-card" style={{ margin: 3 }}>
                <Tabs defaultActiveKey={this.defaultActiveSection.b || undefined}>
                  {this.props.can(['view ship_visits.movement', 'edit ship_visits.movement']) && (
                    <TabPane tab={`Kustības (${this.store.item.movement_count || 0})`} key="movement" forceRender>
                      <DataTable
                        title="Kustības"
                        name="movement"
                        canAdd={match.params.id !== 'new'}
                        canEdit={this.props.can(['edit ship_visits.movement']) && !this.forceDisableEdit}
                        modalForm={EditMovement}
                        modalProps={{ width: '860px' }}
                        defaultFilters={{
                          shipVisitId: match.params.id !== 'new' ? match.params.id : -9999,
                          // direction: [this.state.passengersDirection],
                        }}
                        // actions={[
                        //   <Radio.Group
                        //     key="filter"
                        //     className="table-ffradio-actions"
                        //     value={this.state.passengersDirection}
                        //     onChange={(e) => {
                        //       this.setState({ passengersDirection: e.target.value });
                        //     }}
                        //   >
                        //     <Radio key="IN" value="IN">
                        //       Ienākot
                        // </Radio>
                        //     <Radio key="OUT" value="OUT">
                        //       Izejot
                        // </Radio>
                        //   </Radio.Group>,
                        // ]}
                        fixedFormValues={{
                          shipVisitId: match.params.id,
                          // direction: this.state.passengersDirection,
                        }}
                        noCard
                      />
                      <FormItem
                        disabled={!this.props.can('edit ship_visits.movement')}
                        formKind={EditMovement}
                        name="movement"
                        noCard
                        modalProps={{ width: '860px' }}
                      />
                    </TabPane>
                  )}
                  {/* {this.props.can(['view ship_visits.pilotBills', 'edit ship_visits.pilotBills']) && (
                    <TabPane
                      tab={`Loču kvītis (${(this.props.form.getFieldValue('pilotBills') || 0) &&
                        this.props.form.getFieldValue('pilotBills').length
                        })`}
                      key="pilotBills"
                      forceRender
                    >
                      <FormItem
                        disabled={!this.props.can('edit ship_visits.pilotBills')}
                        formKind={EditPilotBill}
                        name="pilotBills"
                        noCard
                        modalProps={{ width: '860px' }}
                      />
                    </TabPane>
                  )} */}

            {/*
                  {this.props.can(['view ship_visits.ship_operations', 'edit ship_visits.ship_operations']) && (
                    <TabPane
                      tab={`Darbības (${
                        (this.props.form.getFieldValue('shipOperations') || 0) &&
                        this.props.form.getFieldValue('shipOperations').length
                      })`}
                      key="shipOperations"
                      forceRender
                    >
                      <FormItem
                        disabled={!this.props.can('edit ship_visits.ship_operations')}
                        formKind={EditShipOperation}
                        name="shipOperations"
                        noCard
                      />
                    </TabPane>
                  )}
                  {this.props.can(['view ship_visits.resource_requests', 'edit ship_visits.resource_requests']) && (
                    <TabPane
                      tab={`Resursu pieteikumi (${
                        (this.props.form.getFieldValue('resourceRequests') || 0) &&
                        this.props.form.getFieldValue('resourceRequests').length
                      })`}
                      key="resourceRequests"
                      forceRender
                    >
                      <FormItem
                        disabled={!this.props.can('edit ship_visits.resource_requests')}
                        formKind={EditResourceRequest}
                        name="resourceRequests"
                        noCard
                        modalProps={{ width: '860px' }}
                      />
                    </TabPane>
                  )}
                  {this.props.can(['view ship_visits.resource_tasks', 'edit ship_visits.resource_tasks']) && (
                    <TabPane
                      tab={`Resursu norīkojumi (${
                        (this.props.form.getFieldValue('resourceTasks') || 0) &&
                        this.props.form.getFieldValue('resourceTasks').length
                      })`}
                      key="resourceTasks"
                      forceRender
                    >
                      <FormItem
                        disabled={!this.props.can('edit ship_visits.resource_tasks')}
                        formKind={EditResourceTask}
                        name="resourceTasks"
                        noCard
                      />
                    </TabPane>
                  )}
                  {this.props.can(['view operational_notes', 'edit operational_notes']) && (
                    <TabPane
                      tab={`Operatīvās piezīmes (${
                        (this.props.form.getFieldValue('operationalNotes') || 0) &&
                        this.props.form.getFieldValue('operationalNotes').length
                      })`}
                      key="operationalNotes"
                      forceRender
                    >
                      <FormItem
                        disabled={!this.props.can('edit operational_notes')}
                        formKind={EditOperationalNote}
                        name="operationalNotes"
                        noCard
                      />
                    </TabPane>
                  )}
                  {match.params.id !== 'new' && (
                    <TabPane tab="Atļaujas" key="permits" forceRender>
                      <Row {...gridRow} className="permits-row mt15" type="flex">
                        <Col {...gridCol2}>
                          <Card title="Ienākšana" size="small" bordered>
                            <FormItem name="incomingPermits" />
                            {this.props.can([
                              'ship_visits.permits.accept_in',
                              'ship_visits.permits.reject_in',
                              'ship_visits.permits.comment_in',
                            ]) && (
                              <FormItem
                                formItemProps={{
                                  labelCol: {
                                    span: 24,
                                    style: { marginTop: 15 },
                                  },
                                  wrapperCol: { span: 24 },
                                }}
                                name="_shipVisitPermitCommentIn"
                                className="mt15 no-resize"
                                rows="3"
                                usePlaceholderAsLabel
                              />
                            )}
                            <div className="controlOrgBtnBlock">
                              {this.props.can('ship_visits.permits.reject_in') && (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.updatePermit({
                                      direction: 'in',
                                      status: 'rejected',
                                    });
                                  }}
                                  type="danger"
                                  size="small"
                                  htmlType="submit"
                                >
                                  Noraidīt
                                </Button>
                              )}
                              {this.props.can('ship_visits.permits.accept_in') && (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.updatePermit({
                                      direction: 'in',
                                      status: 'accepted',
                                    });
                                  }}
                                  type="primary"
                                  className="color-green light"
                                  size="small"
                                  htmlType="submit"
                                >
                                  Apstiprināt
                                </Button>
                              )}
                              {this.props.can('ship_visits.permits.comment_in') && (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.updatePermit({
                                      direction: 'in',
                                      status: 'commented',
                                    });
                                  }}
                                  type="primary"
                                  size="small"
                                  htmlType="submit"
                                  disabled={!this.props.form.getFieldValue('_shipVisitPermitCommentIn')}
                                >
                                  Komentēt
                                </Button>
                              )}
                            </div>
                            {match.params.id !== 'new' && !!skloisStatuses.length && (
                              <>
                                <h4 style={{ marginTop: 15 }}>SKLOIS statuss:</h4>
                                {skloisStatuses
                                  .filter((s) => s.direction === 'IN')
                                  .map((s, k) => (
                                    <Alert
                                      key={k}
                                      className="skloisStatusBox"
                                      message={s.title}
                                      type={s.status}
                                      showIcon
                                    />
                                  ))}
                              </>
                            )}
                          </Card>
                        </Col>
                        <Col {...gridCol2}>
                          <Card title="Iziešana" size="small" bordered>
                            <FormItem name="outgoingPermits" />
                            {this.props.can([
                              'ship_visits.permits.accept_out',
                              'ship_visits.permits.reject_out',
                              'ship_visits.permits.comment_out',
                            ]) && (
                              <FormItem
                                formItemProps={{
                                  labelCol: {
                                    span: 24,
                                    style: { marginTop: 15 },
                                  },
                                  wrapperCol: { span: 24 },
                                }}
                                name="_shipVisitPermitCommentOut"
                                rows="3"
                                className="mt15 no-resize"
                                usePlaceholderAsLabel
                              />
                            )}
                            <div className="controlOrgBtnBlock">
                              {this.props.can('ship_visits.permits.reject_out') && (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.updatePermit({
                                      direction: 'out',
                                      status: 'rejected',
                                    });
                                  }}
                                  type="danger"
                                  size="small"
                                  htmlType="submit"
                                >
                                  Noraidīt
                                </Button>
                              )}
                              {this.props.can('ship_visits.permits.accept_out') && (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.updatePermit({
                                      direction: 'out',
                                      status: 'accepted',
                                    });
                                  }}
                                  type="primary"
                                  className="color-green light"
                                  size="small"
                                  htmlType="submit"
                                >
                                  Apstiprināt
                                </Button>
                              )}
                              {this.props.can('ship_visits.permits.comment_out') && (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.updatePermit({
                                      direction: 'out',
                                      status: 'commented',
                                    });
                                  }}
                                  type="primary"
                                  size="small"
                                  htmlType="submit"
                                  disabled={!this.props.form.getFieldValue('_shipVisitPermitCommentOut')}
                                >
                                  Komentēt
                                </Button>
                              )}
                            </div>
                            {match.params.id !== 'new' && !!skloisStatuses.length && (
                              <>
                                <h4 style={{ marginTop: 15 }}>SKLOIS statuss:</h4>
                                {skloisStatuses
                                  .filter((s) => s.direction === 'OUT')
                                  .map((s, k) => (
                                    <Alert
                                      className="skloisStatusBox"
                                      key={k}
                                      message={s.title}
                                      type={s.status}
                                      showIcon
                                    />
                                  ))}
                              </>
                            )}
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  )}
                </Tabs>
              </Card>
            </Card>
          </Col>
         */}
          </Col>
          <Col {...gridCol}>
            <Card title={null} bordered style={{ width: '100%', borderTop: 0, marginTop: -1 }}>
              <Row gutter={[24, 24]} type="flex">
                <Col {...gridCol2}>
                  <Card title="Piezīmes" size="small">
                    <FormItem
                      name="notes"
                      layout="horizontal-wide"
                      formItemProps={{
                        className: 'no-margin',
                      }}
                      rows="4"
                      usePlaceholderAsLabel
                      style={{ width: '100%', resize: 'none' }}
                    // asText={!!this.store.item.skloisVisitCode}
                    />
                  </Card>
                </Col>
                {match.params.id !== 'new' && (
                  <Col {...gridCol2}>
                    <Card title="Informacija par dokumentu" size="small">
                      <FormItem
                        name="ownable"
                        noCard
                        formItemProps={{
                          className: 'grey-text',
                        }}
                      />
                    </Card>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
          <Col {...gridCol}>
            <Card size="small" bordered style={{ width: '100%', borderTop: 0, marginTop: -1 }}>
              <Card bordered className="tabs-card" style={{ margin: 3 }}>
                <Tabs defaultActiveKey={this.defaultActiveSection.b || undefined}>
                  <TabPane tab={`Kustības (${this.store.item !== undefined ? 0 : this.store.item.movement_count})`} key="movement" forceRender>
                    <DataTable
                      title="Kustības"
                      name="movement"
                      canAdd={false}
                      canEdit={false}
                      modalForm={EditMovement}
                      modalProps={{ width: '860px' }}
                      defaultFilters={{
                        shipVisitId: match.params.id !== 'new' ? parseInt(match.params.id, 10) : -9999,
                      }}
                      fixedFormValues={{
                        shipVisitId: match.params.id,
                      }}
                      additionalFormProps={{
                        ship: this.store.item.shipIn
                      }}
                      noCard
                    />
                  </TabPane>
                  <TabPane
                    tab={`Piestātņu apmeklējumi (${this.store.item !== undefined ? 0 : this.store.item.berth_visits_count})`}
                    key="berthVisits"
                    forceRender
                  >
                    <DataTable
                      title="Piestātņu apmeklējumi"
                      name="berthVisits"
                      canAdd={this.canEdit}
                      canEdit={this.canEdit}
                      modalForm={EditBerthVisit}
                      modalProps={{ width: '860px' }}
                      defaultFilters={{
                        shipVisitId: match.params.id !== 'new' ? parseInt(match.params.id, 10) : -9999,
                      }}
                      fixedFormValues={{
                        shipVisitId: match.params.id,
                      }}
                      noCard
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditShipVisit' })(EditShipVisit));
