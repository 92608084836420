import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import EditDiscount from './EditDiscount';

@withRouter
@inject('discountStore', 'authorize', 'can')
@observer
class Discounts extends React.Component {
  static Edit = EditDiscount;

  title = 'Atlaides';

  componentDidMount() {
    const { discountStore } = this.props;
    discountStore.load();
  }

  render() {
    const { isWidget, discountStore, history, /* authorize,*/ can } = this.props;
    // authorize(['view discounts', 'edit discounts']);

    const Widget = (
      <DataTable
        store={discountStore}
        title={this.title}
        onRowClick={(row) => history.push(`/finance/discounts/${row.id || ''}`)}
        onAdd={can('edit discounts') && (() => history.push('/finance/discounts/new'))}
      />
    );
    return isWidget ? (
      Widget
    ) : (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>{Widget}</Col>
        </Row>
      </>
    );
  }
}

export default Discounts;
