const driver = typeof window !== 'undefined' ? window : {};

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getUri = () => {
  if (driver && driver.location) {
    const {
      location: { origin, hostname, host, protocol },
    } = driver;
    if (origin) {
      return origin;
    }
    if (protocol) {
      if (host) {
        return `${protocol}//${host}`;
      }
      if (hostname) {
        return `${protocol}//${hostname}`;
      }
    }
  }
  return null;
};

export let getFullUri = () => {
    if (driver && driver.location) {
        let {
            location: { origin, pathname, search, hash, hostname, host, protocol },
        } = driver;

        if (origin) {
            return `${origin}${pathname}${search}${hash}`;
        }

        if (protocol) {
            let url = `${protocol}//`;
            url += host || hostname;
            url += pathname;
            url += search;
            url += hash;
            return url;
        }
    }

    return null;
};

export const getIdFromUri = (uri) => {
    if (uri) {
        const segments = new URL(uri).pathname.split('/');
        return segments[segments.length - 1];
    }
    return null;
};

export const downloadOne = (url, forceNewWindow = false) => {
  var link = document.createElement('a');

  link.style.display = 'none';
  if (forceNewWindow) {
    link.setAttribute('target', '_blank');
  } else {
    link.setAttribute('download', null);
  }
  link.setAttribute('id', '_' + makeid(5));
  link.setAttribute('href', url);

  document.body.appendChild(link);
  setTimeout(() => {
    link.click();

    console.log('LINK CLICKED!', url);
    document.body.removeChild(link);
  });
};

export const downloadAll = (urls, forceNewWindow = true) => {
  var link = document.createElement('a');

  link.style.display = 'none';
  if (forceNewWindow) {
    link.setAttribute('target', '_blank');
  } else {
    link.setAttribute('download', '');
  }

  document.body.appendChild(link);

  for (var i = 0; i < urls.length; i++) {
    link.setAttribute('id', '_' + makeid(5));
    link.setAttribute('href', urls[i]);
    link.click();
  }

  document.body.removeChild(link);

  return true;
};

export const downloadAllHack = (urlsItems, forceNewWindow = false) => {
  // const operate = {
  //   download: (urls) => {
  //     console.log(document.hasFocus());
  //     if (document.hasFocus()) {
  //       const url = urls.pop();
  //       const a = document.createElement('a');
  //       a.setAttribute('id', '_' + makeid(5));
  //       a.setAttribute('href', url);
  //       a.setAttribute('download', '');
  //       // a.setAttribute('target', '_blank');
  //       a.click();
  //       console.log(urls);
  //       if (urls.length == 0) {
  //         clearInterval(operate.interval);
  //       }
  //     }
  //   },
  // };
  // operate.interval = setInterval(operate.download, 2000, urlsItems);
};

export default {
  getUri,
  downloadAll,
  downloadOne,
  downloadAllHack,
};
