import React from 'react';
import moment from 'moment-timezone';
import { toJS } from 'mobx';
import { DatePicker, Button, Icon, Tooltip } from 'antd';
import { FontAwesomeIcon } from '..';
import { Field } from './Field';

const DateRangeLabelComponent = ({ value, format }) => {
  if (!value) {
    return <div />;
  }
  const leftValue = moment(value[0]).tz('Europe/Riga').format(format);
  const rightValue = moment(value[1]).tz('Europe/Riga').format(format);
  return <div>{`${leftValue || '(nav norādīts)'} - ${rightValue || '(nav norādīts)'}`}</div>;
};

@Field.register
class DateRangeComponent extends Field {
  static code = 'daterange';

  constructor(col, dataSet) {
    super(col, dataSet);

    this.format = this.time ? 'DD.MM.YYYY. HH:mm' : 'DD.MM.YYYY.';

    //	Filters
    if (this.filter === undefined) {
      this.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, showButtons = true }) => {
        if (Array.isArray(selectedKeys)) {
          selectedKeys = selectedKeys.map((date) => (date ? moment(date) : null));
        }
        return (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={toJS(selectedKeys)}
              onChange={(v) => setSelectedKeys(v)}
              style={{ marginBottom: 8, display: 'block' }}
              format="L"
              showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }}
              allowClear={!showButtons}
            />
            {showButtons && (
              <>
                <Button
                  type="primary"
                  onClick={() => confirm(toJS(selectedKeys))}
                  icon="search"
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  Atlasīt
                </Button>
                <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                  Noņemt
                </Button>
              </>
            )}
          </div>
        );
      };

      this.filterIcon = (filtered) => {
        return <Icon type="calendar" style={{ color: filtered ? '#1890ff' : undefined }} />;
      };
    }
  }

  loadRowTransform(row) {
    const { key } = this;
    if (row && row[key]) {
      const value = row[key];
      const leftValue = value[0];
      const rightValue = value[1];

      return [
        moment(leftValue).tz('Europe/Riga').format(this.format),
        moment(rightValue).tz('Europe/Riga').format(this.format),
      ];
    }
    return null;
  }

  loadFormTransform(item, value = item[this.key]) {
    if (value) {
      const leftValue = item[this.key][0];
      const rightValue = item[this.key][1];

      return [moment(leftValue).tz('Europe/Riga'), moment(rightValue).tz('Europe/Riga')];
    }
    return null;
  }

  saveFormTransform(values, out = {}) {
    if (values[this.key]) {
      const leftValue = values[this.key][0];
      const rightValue = values[this.key][1];

      out[this.key] = [
        moment(leftValue).format('YYYY-MM-DD HH:mm:ss'),
        moment(rightValue).format('YYYY-MM-DD HH:mm:ss'),
      ];
    } else {
      out[this.key] = null;
    }

    return super.saveFormTransform(out, out);
  }

  compareValues(left, right) {
    if (left) {
      let lLeftValue = left[0];
      let lRightValue = left[1];
      left = '';
      if (lLeftValue) {
        if (typeof lLeftValue === 'string') {
          lLeftValue = moment(lLeftValue);
        }
        left += lLeftValue.format(this.format);
      }
      if (lRightValue) {
        if (typeof lRightValue === 'string') {
          lRightValue = moment(lRightValue);
        }
        left += lRightValue.format(this.format);
      }
    }
    if (right) {
      let rLeftValue = right[0];
      let rRightValue = right[1];
      right = '';
      if (rLeftValue) {
        if (typeof rLeftValue === 'string') {
          rLeftValue = moment(rLeftValue);
        }
        right += rLeftValue.format(this.format);
      }
      if (rRightValue) {
        if (typeof rRightValue === 'string') {
          rRightValue = moment(rRightValue);
        }
        right += rRightValue.format(this.format);
      }
    }
    return left !== right;
  }

  renderWarningIcon({ previousValue }) {
    if (Array.isArray(previousValue)) {
      const leftValue = previousValue[0];
      const rightValue = previousValue[1];

      if (typeof leftValue === 'string' && typeof rightValue === 'string') {
        previousValue = `${moment(leftValue).format(this.format) || '(nav norādīts)'} - ${
          moment(rightValue).format(this.format) || '(nav norādīts)'
        }`;
      }
    } else {
      previousValue = null;
    }

    if (typeof previousValue === 'string') {
      previousValue = moment(previousValue).format(this.format);
    }
    return (
      <Tooltip
        title={
          <>
            <strong>Iepriekšējā vērtība: </strong>
            <span style={{ textDecoration: 'line-through' }}>{previousValue || '(tukšs lauks)'}</span>
          </>
        }
      >
        &nbsp;
        <FontAwesomeIcon icon="engine-warning" type="solid" style={{ color: 'orange', cursor: 'pointer' }} />
      </Tooltip>
    );
  }

  renderComponent(_props) {
    // eslint-disable-next-line prefer-const
    let { value, ...props } = _props;
    if (typeof value === 'string') {
      value = null; // graceful fail
      console.error('Invalid string value supplied for DateRangeComponent, please pass a Moment object');
    }

    return this.label || this.asText || props.asText ? (
      <DateRangeLabelComponent {...props} value={value} format={this.format} />
    ) : (
      <DatePicker.RangePicker
        value={value}
        style={{ display: 'block' }}
        format={this.format}
        showTime={this.time ? { format: 'HH:mm' } : false}
        {...props}
      />
    );
  }
}

export default DateRangeComponent;
