import React from 'react';
import moment from 'moment-timezone';
import { TimePicker /* , Button, Icon */ } from 'antd';
import { Field } from './Field';

const TimeLabel = ({ value }) => {
  if (!value) {
    return <div />;
  }
  const format = 'HH [h] mm [m]';
  value = value
    ? moment
        .utc(value * 1000)
        .tz('Europe/Riga')
        .format(format)
    : value;
  return <div>{value}</div>;
};

@Field.register
class TimeComponent extends Field {
  static code = 'time';

  loadRowTransform(row) {
    const { key } = this;
    const format = 'HH:mm';
    row[key] = row[key]
      ? moment
          .utc(row[key] * 1000)
          .tz('Europe/Riga')
          .format(format)
      : row[key];
    return row[key];
  }

  loadFormTransform(item, value = item[this.key]) {
    if (value) {
      return value ? moment.utc(value * 1000).tz('Europe/Riga') : value;
    }
    return null;
  }

  saveFormTransform(values, out = {}) {
    let seconds = 0;
    if (values[this.key]) {
      seconds = values[this.key]
        .format('HH:mm')
        .split(':')
        .reduce((total, timeUnit, key) => {
          if (!parseInt(timeUnit, 10)) {
            return total;
          }
          switch (key) {
            case 0:
              total += parseInt(timeUnit, 10) * 3600;
              break;
            case 1:
              total += parseInt(timeUnit, 10) * 60;
              break;
            case 3:
              total += parseInt(timeUnit, 10);
              break;
            default:
          }
          return total;
        }, 0);
    }

    out[this.key] = seconds || null;
    return super.saveFormTransform(out, out);
  }

  //   renderComponent({ value, asText, props }) {
  //     if (typeof value === 'string') {
  //       value = null;
  //       console.error('Invalid string value supplied for TimeComponent, please pass a Moment object');
  //     }
  //     console.log(value, asText, props);
  //     return this.label || asText ? (
  //       <TimeLabel {...props} value={value} />
  //     ) : (
  //       <TimePicker placeholder="Izvēlieties laiku (h, m)" format="HH:mm" value={value} {...props} />
  //     );
  //   }
  // }
  renderComponent(_props) {
    let { value, asText, ...props } = _props;
    if (value && typeof value === 'string') {
      value = null;
      console.error('Invalid string value supplied for TimeComponent, please pass a Moment object');
    }
    return this.label || asText ? (
      <TimeLabel {...props} value={value} />
    ) : (
      <TimePicker placeholder="Izvēlieties laiku (h, m)" format="HH:mm" value={value} {...props} />
    );
  }
}

export default TimeComponent;
