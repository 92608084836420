import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import moment from 'moment-timezone';
import { inject, observer } from 'mobx-react';
import { gridRow, FormItem, gridCol } from '..';
import EditFormBase from '../EditFormBase';

@inject('appStore', 'authStore', 'authorize', 'can')
@observer
class EditOperationalNote extends EditFormBase {
  title = 'Operatīvā piezīme';
  // storeName = 'store';
  static storeName = 'operationalNotes';

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    const { store } = this;

    if (props.fixedFormValues) {
      store.item = { ...store.item, ...props.fixedFormValues };
    }

    // eslint-disable-next-line eqeqeq
    if (props && props.match && this.props && this.props.match && props.match.params.id != this.props.match.params.id) {
      this.load(store, true, props);
    }
  }

  transformValuesBeforeSubmit(values) {
    if (!values.userId || !values.updated_at) {
      if (!values.userId && this.props.authStore.user.id) {
        values.userId = this.props.authStore.user.id;
      }
      if (!values.updated_at) {
        values.updated_at = moment();
      }
    }
    return values;
  }

  canSave() {
    return this.props.can('edit operational_notes');
  }

  canDelete() {
    return this.props.can('delete operational_notes');
  }

  renderForm() {
    const { authorize } = this.props;
    authorize(['view operational_notes', 'edit operational_notes']);

    let title = 'Operatīvā piezīme';
    switch (this.store.item.resourceType) {
      case 'SHIPVISIT':
        title = 'Vizītes operatīvā piezīme';
        break;
      case 'SHIP':
        title = 'Kuģa operatīvā piezīme';
        break;
      case 'BERTH':
        title = 'Piestātnes operatīvā piezīme';
        break;
      case 'MOVEMENT':
        title = 'Kustības operatīvā piezīme';
        break;
      default:
    }

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title={title} bordered={false}>
            <FormItem name="updated_at" hidden={!this.props.form.getFieldValue('updated_at')} />
            <FormItem name="userId" hidden={!this.props.form.getFieldValue('userId')} />
            {/* <FormItem name="resourceType" hidden={!this.props.form.getFieldValue('resourceType')} />
            <FormItem name="resourceId" hidden={!this.props.form.getFieldValue('resourceId')} /> */}
            <FormItem name="note" />
            <FormItem name="files" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditOperationalNote' })(EditOperationalNote);
