import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

class ErrorForbidden extends Error {
  static component = ({ message = 'Pieeja liegta' }) => (
    <div className="notFound-block">
      <Result
        status="403"
        title="403"
        subTitle={message}
        extra={
          <Link to="/">
            <Button type="primary">Uz sākumu</Button>
          </Link>
        }
      />
    </div>
  );

  constructor(message = null, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);
    this.message = message;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    // if (Error.captureStackTrace) {
    //   Error.captureStackTrace(this, CustomError);
    // }

    this.name = 'ErrorForbidden';
    this.date = new Date();
  }
}
export default ErrorForbidden;
