import React from 'react';
import { Row, Col, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { observable, toJS, runInAction } from 'mobx';
import { gridRow, FontAwesomeIcon } from '@ois-lib';
import OverviewDrawer from './OverviewDrawer';

@inject('appStore', 'authorize', 'can')
@observer
class Overview extends React.Component {
  @observable settings = [];
  @observable grid = [];
  @observable block = null;
  @observable isDrawerOpened = false;

  title = 'Pārskats';
  colProps = {
    1: { xs: 24, sm: 24, md: 24, lg: 24 },
    2: { xs: 24, sm: 24, md: 12, lg: 12 },
    3: { xs: 24, sm: 24, md: 8, lg: 8 },
  };
  lsKey = 'dashboard_settings_oks';
  allowEdit = true;

  constructor(props) {
    super(props);

    const { company } = toJS(props.authStore.user) || {};

    this.settings = [];
    if (localStorage.getItem(this.lsKey)) {
      this.settings = JSON.parse(localStorage.getItem(this.lsKey));
    }
    if (!this.settings || !this.settings.length) {
      if (company.isAgent || company.isCargoLoader) {
        this.settings = [
          { flexSize: 2, path: '/profile/edit' },
          { flexSize: 2, path: '/notifications' },
          { flexSize: 2, path: '/statistics/mystatistics' },
          { flexSize: 2, path: ':widget:shortcuts' },
        ];
      }
    }

    const flatFind = (list) =>
      list.reduce((routeContainer, routeItem) => {
        const { title, path, component, filter, perms, hasWidget, children } = routeItem;

        if (children && children.length) {
          for (const child of flatFind(children)) {
            routeContainer.push(child);
          }
        }

        if (hasWidget && path && component) {
          routeContainer.push({
            title,
            path,
            component,
            filter,
            perms,
            hasWidget,
          });
        }
        return routeContainer || [];
      }, []);

    this.flat = flatFind(toJS(props.appStore.routes, { recurseEverything: true }));

    this.grid = this.settings
      .map((widget) => {
        const w = this.flat.find((f) => f.path === widget.path);
        return w ? { ...w, flexSize: widget.flexSize } : null;
      })
      .filter((i) => i);
  }

  setTitle(title) {
    super.setTitle(null);
    return title;
  }

  toggleDrawer = (block = null) => {
    runInAction(() => {
      this.block = block || null;
      this.isDrawerOpened = !this.isDrawerOpened;
    });
  };

  handleDrawerSubmit = (settings) => {
    this.settings = settings;
    if (localStorage) {
      localStorage.setItem(this.lsKey, JSON.stringify(this.settings));
    }
    this.grid = settings
      .map((widget) => {
        const w = this.flat.find((f) => f.path === widget.path);
        return w ? { ...w, flexSize: widget.flexSize } : null;
      })
      .filter((i) => i);
  };

  render() {
    const gutter = 15;
    // const { can } = this.props;
    // if (!can('view ship_visits') && !can('edit ship_visits')) {
    //   return null;
    // }
    return (
      <>
        <Row {...gridRow} gutter={gutter} style={{ marginTop: 15 }} type="flex">
          {!!this.grid.length &&
            toJS(this.grid, { recurseEverything: true }).map(
              ({ component: Component, flexSize, filter, path, ...rest }, key) => (
                <Col
                  {...this.colProps[flexSize]}
                  key={`${key}:${path}`}
                  style={{
                    marginBottom: gutter,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Card
                    title={null}
                    bordered={false}
                    className="overview-tile"
                    style={{
                      flex: '1 1 auto',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Component
                      {...{ filter, title: rest.title }}
                      settingsOptions={[
                        {
                          title: 'Bloka iestatījumi',
                          onClick: () => this.toggleDrawer(path),
                        },
                      ]}
                      isWidget
                    />
                  </Card>
                </Col>
              )
            )}
          {this.allowEdit && (
            <Col {...this.colProps[1]}>
              <div
                className="dashboard-grid-empty"
                role="button"
                tabIndex="-1"
                aria-hidden="true"
                onClick={() => this.toggleDrawer()}
              >
                <FontAwesomeIcon icon={this.grid.length ? 'pencil' : 'plus-circle'} />
              </div>
            </Col>
          )}
        </Row>
        <OverviewDrawer
          block={this.block}
          settings={toJS(this.settings, { recurseEverything: true })}
          visible={this.isDrawerOpened}
          toggleTrigger={this.toggleDrawer}
          onSubmit={this.handleDrawerSubmit}
          onCancel={() => {
            // console.log("cancel");
          }}
        />
      </>
    );
  }
}

export default Overview;
