import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol } from '@ois-lib';

@inject('appStore', 'authorize', 'can')
@observer
class EditWasteDisposal extends EditFormBase {
  title = 'Kuģa atkritumi';
  static storeName = 'wasteDisposals';
  
  canSave() {
    console.log(3);
    return true;
  }

  canDelete() {
    return true;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.waste', 'edit ship_visits.waste']);
    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Kuģa atkritumi" bordered={false}>
            <FormItem name="nr" />
            <FormItem name="wasteTypeId" />
            <FormItem name="wasteDescription" />
            <FormItem name="wasteUnitId" />
            <FormItem name="disposed" />
            <FormItem name="forDisposal" />
            <FormItem name="maxCapacity" />
            <FormItem name="remainder" />
            <FormItem name="expectedToCreate" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditWasteDisposal' })(EditWasteDisposal);
