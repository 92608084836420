import React from 'react';
import { Card, Layout } from 'antd';
import loginPhoto from '@ois-lib/assets/img/login.png';
import LoginForm from './LoginForm';
import PasswordRequestForm from './PasswordRequestForm';
import PasswordResetForm from './PasswordResetForm';

const styleSheet = {
  login: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  card: {
    borderRadius: '10px',
    width: '42em',
    display: 'flex',
  },
  img: {
    height: '100%',
    borderRadius: '10px 0 0 10px',
  },
  body: {
    width: '115%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Login = ({ allowRegister }) => {
  return (
    <Layout style={styleSheet.login} className="login-page">
      <Card
        bordered={false}
        style={styleSheet.card}
        cover={<img src={loginPhoto} alt="" style={styleSheet.img} />}
        bodyStyle={styleSheet.body}
      >
        <LoginForm allowRegister={allowRegister} />
      </Card>
    </Layout>
  );
};

const ForgotPassword = () => {
  return (
    <Layout style={styleSheet.login} className="login-page reset-password">
      <Card
        bordered={false}
        style={styleSheet.card}
        cover={<img src={loginPhoto} alt="" style={styleSheet.img} />}
        bodyStyle={styleSheet.body}
      >
        <PasswordRequestForm />
      </Card>
    </Layout>
  );
};

const ResetPassword = () => {
  return (
    <Layout style={styleSheet.login} className="login-page reset-password">
      <Card
        bordered={false}
        style={styleSheet.card}
        cover={<img src={loginPhoto} alt="" style={styleSheet.img} />}
        bodyStyle={styleSheet.body}
      >
        <PasswordResetForm />
      </Card>
    </Layout>
  );
};

export { ForgotPassword, ResetPassword };
export default Login;
