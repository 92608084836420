import React from 'react';
import Field from './Field';

const Label = ({ value, errors }) => {
  const hasError = errors && errors.length;
  let err;
  if (hasError) {
    for (const ek of errors) {
      err = <div className="ant-form-explain">{ek.message}</div>;
      break;
    }
  }
  return (
    <div className={(hasError && 'has-error') || undefined}>
      <div>{value || ''}</div>
      {err}
    </div>
  );
};

@Field.register
class LabelComponent extends Field {
  static code = 'label';

  renderComponent(props) {
    const {
      value,
      'data-__field': { errors },
    } = props;
    return <Label value={value} errors={errors} />;
  }
}

export default LabelComponent;
