import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, message, Checkbox } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import StoreFactory from '@ois-lib/stores';
import Overview from './Overview';
import EditShipVisit from './EditShipVisit';
import { toJS } from 'mobx';

@inject('authorize', 'can', 'appStore', 'authStore', 'companyStore')
@observer
class ShipVisits extends React.Component {
  static Edit = EditShipVisit;
  static Overview = Overview;

  title = 'Kuģu vizītes';

  constructor(props) {
    super(props);

    const { title, filter, appStore } = props;
    if (title) {
      this.title = title;
    }

    this.state = {
      showOnlyAgentVisits: localStorage.getItem('showOnlyAgentVisits') === 'false' ? false : true,
    };

    this.store = StoreFactory.getStore('shipVisits', filter ? filter.toUpperCase() : undefined);
    this.store.keepOriginalValues = ['shipAgentId'];
    const additionalFilters = {};
    if (this.state.showOnlyAgentVisits) {
      const { user } = props.authStore;
      const { company } = toJS(user);
      additionalFilters.shipAgentId = company.id;
    }
    this.store.systemFilters = filter ? { status: filter.toUpperCase(), ...additionalFilters } : {};
    this.store.sorter = { eta: 'desc' };
    switch (`${filter}`.toUpperCase()) {
      case 'IN_PORT':
        appStore.shipVisitLink = '/ships';
        this.store.sorter = { ata: 'desc' };
        break;
      case 'VISIT_REQUEST':
        appStore.shipVisitLink = '/planning/visits';
        break;
      case 'HISTORY':
        appStore.shipVisitLink = '/statistics/visits';
        break;
      case 'MOVING':
        appStore.shipVisitLink = '/live/moving';
        break;
      case 'BERTHED':
        appStore.shipVisitLink = '/live/berthed';
        break;
      case 'RAID':
        appStore.shipVisitLink = '/live/raid';
        break;
      case 'EXPECTED':
        appStore.shipVisitLink = '/live/expected';
        break;
      default:
        appStore.shipVisitLink = null;
    }

    this.store.load();
  }

  componentDidMount() {
    if (!this.props.appStore.incompleteCompanyInfo) {
      message.info('Lūdzu, ievadiet datus par uzņēmumu!');
      this.props.history.push('/company/edit');
    }
  }

  render() {
    const { isWidget, history, settingsOptions, settingsButtons, appStore, authStore, can } = this.props;
    const { shipVisitLink } = appStore;
    const { user } = authStore;
    const { company } = toJS(user);

    const DataTableWidget = (props) => (
      <DataTable
        {...props}
        store={this.store}
        title={this.title}
        can={can}
        actions={
          company &&
          company.id && [
            <Checkbox
              key="shoOnlyAgentVisits"
              style={{ marginRight: -15 }}
              checked={this.state.showOnlyAgentVisits}
              onChange={(e) => {
                const { checked } = e.target;
                const filters = {
                  ...this.store.filters,
                  ...this.store.systemFilters,
                  shipAgentId: checked && company ? company.id : undefined,
                };
                this.store.sysFilter(filters, this.store.sorter);
                this.setState({ showOnlyAgentVisits: checked }, () => {
                  localStorage.setItem('showOnlyAgentVisits', checked);
                });
              }}
            >
              Rādīt tikai <strong>{company.name}</strong> kuģus
            </Checkbox>,
          ]
        }
        rowClassName={(row, groq) => {
          let colorClass = 'visit-color ';
          let shipAgentId = row.shipAgentId;
          if (typeof shipAgentId !== 'number' && row['_orig.shipAgentId']) {
            shipAgentId = row['_orig.shipAgentId'];
          }
          if (company && company.id === shipAgentId) {
            colorClass += 'black bolder ';
            if(row._indicators.expiredDocuments){
              colorClass += 'visit-rejected ';
            }
          } else {
            colorClass += 'black bg-white cursor-default ';
          }
          if(row.document_source === "Aģents") colorClass = 'visit-color black bolder createdManually';
          return colorClass;
        }}
        onRowClick={(row) => {
          let shipAgentId = row.shipAgentId;
          if (typeof shipAgentId !== 'number' && row['_orig.shipAgentId']) {
            shipAgentId = row['_orig.shipAgentId'];
          }
          if (company && company.id === shipAgentId && history) {
            history.push(`${shipVisitLink}/${row.id || ''}`);
          }
        }}
        addButtonLabel="Pieteikt vizīti"
        onAdd={
          // undefined
          (() => history && history.push(`/ships/new`))
        }
      />
    );

    return isWidget ? (
      <DataTableWidget additionalSettingsOptions={settingsOptions} additionalSettingsButtons={settingsButtons} />
    ) : (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>
            <DataTableWidget />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(ShipVisits);
