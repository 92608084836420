import React from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Modal, Button, message } from 'antd';

@inject('can')
@observer
class ModalEditorComponent extends React.Component {
  // Refactored TableComponent
  constructor(props) {
    super(props);

    props.store.isOpen = observable.box(false);
    props.store.rowId = observable.box(null);
    props.store.loadedRow = observable.box({});

    props.store.loadingOne = false;
    props.store._data = props.value ? props.value : [];
  }

  handleDelete = () => {
    const { baseStore: store, ...props } = this.props;
    store.item = toJS(props.store.item, { recurseEverything: true });
    return Modal.confirm({
      title: 'Vai tiešām vēlaties dzēst šo ierakstu?',
      content: 'Apstiprinot šo paziņojumu, ieraksts tiks dzēsts.',
      onOk: async () => {
        try {
          const res = await store.deleteOne();
          if (res) {
            message.success('Ieraksts veiksmīgi dzēsts');
            store._data = store._data.filter((row) => row.id !== store.item.id);
            props.store.isOpen.set(false);
          }
        } catch (e) {
          console.error(e);
          message.error('Kļūda dzēšot ierakstu');
        }
      },
      okType: 'danger',
      okText: 'Dzēst ierakstu',
      cancelText: 'Atcelt',
    });
  };

  render() {
    const { store, baseStore, formKind: FormKind, rowId, can } = this.props;

    return (
      <Modal
        centered
        closable={false}
        visible={store.isOpen.get()}
        onOk={() => {
          store.isOpen.set(false);
        }}
        onCancel={() => {
          store.isOpen.set(false);
        }}
        footer={[
          !!store.item && !!store.item.id && can('delete operational_notes') && (
            <Button key="delete" type="danger" onClick={this.handleDelete}>
              Dzēst
            </Button>
          ),
          <Button key="back" onClick={() => store.isOpen.set(false)}>
            Aizvērt
          </Button>,
          can('edit operational_notes') && (
            <Button
              key="submit"
              type="primary"
              loading={false}
              onClick={async (e) => {
                if (this.ref) {
                  try {
                    store.item.resourceType = 'SHIP'; // must be dynamic
                    store.item.resourceId = rowId;
                    const startId = store.item.id;
                    if (!startId) {
                      baseStore.item.id = null;
                    }
                    store.item = await this.ref.handleQuietSubmit(e, baseStore);
                    if (store.item && startId) {
                      baseStore._data = baseStore._data.map((row) =>
                        row.id === startId ? toJS(store.item, { recurseEverything: true }) : row
                      );
                    } else {
                      baseStore._data.unshift(store.item);
                    }
                  } catch (err) {
                    console.error(err);
                    return;
                  }
                  store.isOpen.set(false);
                  baseStore.loadWithOptions();
                }
              }}
            >
              Saglabāt
            </Button>
          ),
        ]}
      >
        {FormKind && (
          <FormKind
            wrappedComponentRef={(ref) => {
              this.ref = ref;
              if (ref && ref.props && ref.props.form && store && store.item) {
                this.formRef = ref.props.form;
                this.formRef.resetFields();
                if (this.ref) {
                  this.ref.setFields(store.item, store);
                }
                // this.formRef.setFieldsValue(store.item);
              } else {
                this.formRef = undefined;
              }
            }}
            store={store}
          />
        )}
      </Modal>
    );
  }
}

export default ModalEditorComponent;
