import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Table, Tabs, Input, Button } from 'antd';
import { inject, observer } from 'mobx-react';
// import { DataTable, gridCol, gridRow } from '@ois-lib';
import { gridCol, gridRow } from '@ois-lib';
import '../dashboard/Dashboard.css';

// @inject('resourceRequestStore', 'authorize', 'can')
@inject('authorize', 'can')
@observer
class ShippingLineReport extends React.Component {
  tabSizes = {
    report: { lg: 24, xl: 21, xxl: 15 },
  };
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'responder',
      colSize: { lg: 18, xl: 14, xxl: 10 },
    };
  }

  changeActiveKey = (activeKey) => {
    this.setState({ activeKey, colSize: this.tabSizes[activeKey] || { lg: 18, xl: 14, xxl: 10 } });
  };

  render() {
    const { TabPane } = Tabs;
    const { activeKey, colSize } = this.state;
    const dataSource = [
      {
        order: 1,
        title: 'Respondenta informācija',
        key: 'responder',
        due: '01.01.2021',
        showHeader: false,
        tables: [
          {
            title: '1. Respondents',
            dataSource: [
              {
                title: 'Pārskata periods:',
                data: '2020.jūnijs',
                key: '0',
              },
              {
                title: 'Kuģošanas līnija',
                data: '-',
                key: '1',
              },
              {
                title: 'Līnijas operators',
                data: '-',
                key: '2',
              },
              {
                title: 'Līnijas aģents Rīgas brīvostā',
                data: '-',
                key: '3',
              },
            ],
            columns: [
              {
                dataIndex: 'title',
                key: 'title',
              },
              {
                render: (e) => <Input value={e.data} />,
                key: 'data',
              },
            ],
          },
          {
            title: '2. Anketas aizpildītājs',
            dataSource: [
              {
                title: 'Vārds, Uzvārds:',
                data: 'Vārds, Uzvārds',
                key: '0',
              },
              {
                title: 'Tālruņa numurs:',
                data: '+371 ........',
                key: '1',
              },
              {
                title: 'E-pasta adrese',
                data: 'vards.uzvards@uznemums.lv',
                key: '2',
              },
            ],
            columns: [
              {
                dataIndex: 'title',
                key: 'title',
              },
              {
                render: (e) => <Input value={e.data} />,
                key: 'data',
              },
            ],
          },
        ],
      },
      {
        order: 2,
        title: 'Līnijas darbības rādītāji',
        key: 'report',
        due: '01.01.2021',
        className: 'largeTable',
        tables: [
          {
            // title: '1. Kuģu skaits',
            showHeader: false,
            style: { maxWidth: '500px' },
            dataSource: [
              {
                key: '110',
                title: '1. Kuģu skaits',
              },
            ],
            columns: [
              {
                // dataIndex: 'title',
                render: (e) => <h3>{e.title}</h3>,
                key: 'title',
              },
              {
                render: (e) => <Input value={e.data} disabled={!!e.disabled} hidden={!!e.hidden} />,
                key: 'data',
              },
            ],
          },
          {
            title: '2. Konteineri ar kravu',
            className: 'largeTable',
            dataSource: [
              {
                title: "20' konteineri",
                key: '20',
              },
              {
                title: ">20' un <40' konteineri",
                key: '30',
              },
              {
                title: "40' konteineri",
                key: '40',
              },
              {
                title: "45' konteineri",
                key: '45',
              },
              {
                title: ">45' konteineri",
                key: '50',
              },
              {
                title: 'Citi konteineri',
                key: 'others',
              },
            ],
            columns: [
              {
                title: '',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Ar kuģiem no Rīgas NOSŪTĪTIE konteineri',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits',
                    render: () => <Input />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas',
                    render: () => <Input />,
                  },
                ],
              },
              {
                title: 'Ar kuģiem Rīgā SAŅEMTIE konteineri',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits2',
                    render: () => <Input />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu2',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas2',
                    render: () => <Input />,
                  },
                ],
              },
              {
                title: 'KOPĀ',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits3',
                    render: () => <Input />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu3',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas3',
                    render: () => <Input />,
                  },
                ],
              },
            ],
          },
          {
            title: '3. Tukšie konteineri',
            className: 'largeTable',
            dataSource: [
              {
                title: "20' konteineri",
                key: '20',
              },
              {
                title: ">20' un <40' konteineri",
                key: '30',
              },
              {
                title: "40' konteineri",
                key: '40',
              },
              {
                title: "45' konteineri",
                key: '45',
              },
              {
                title: ">45' konteineri",
                key: '50',
              },
              {
                title: 'Citi konteineri',
                key: 'others',
              },
            ],
            columns: [
              {
                title: '',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Ar kuģiem no Rīgas NOSŪTĪTIE konteineri',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits',
                    render: () => <Input />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas',
                    render: () => <Input />,
                  },
                ],
              },
              {
                title: 'Ar kuģiem Rīgā SAŅEMTIE konteineri',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits2',
                    render: () => <Input />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu2',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas2',
                    render: () => <Input />,
                  },
                ],
              },
              {
                title: 'KOPĀ',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits3',
                    render: () => <Input />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu3',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas3',
                    render: () => <Input />,
                  },
                ],
              },
            ],
          },
          {
            title: '4. Kopā konteinteri',
            className: 'largeTable',
            dataSource: [
              {
                title: "20' konteineri",
                key: '20',
              },
              {
                title: ">20' un <40' konteineri",
                key: '30',
              },
              {
                title: "40' konteineri",
                key: '40',
              },
              {
                title: "45' konteineri",
                key: '45',
              },
              {
                title: ">45' konteineri",
                key: '50',
              },
              {
                title: 'Citi konteineri',
                key: 'others',
              },
            ],
            columns: [
              {
                title: '',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Ar kuģiem no Rīgas NOSŪTĪTIE konteineri',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits',
                    render: () => <Input />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas',
                    render: () => <Input />,
                  },
                ],
              },
              {
                title: 'Ar kuģiem Rīgā SAŅEMTIE konteineri',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits2',
                    render: () => <Input />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu2',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas2',
                    render: () => <Input />,
                  },
                ],
              },
              {
                title: 'KOPĀ',
                children: [
                  {
                    title: 'Skaits (gab):',
                    key: 'skaits3',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'TEU:',
                    key: 'teu3',
                    render: () => <Input disabled />,
                  },
                  {
                    title: 'Tonnas:',
                    key: 'tonnas3',
                    render: () => <Input disabled />,
                  },
                ],
              },
            ],
            note:
              'Piezīme: Konteineru TEU vienības tiek aprēķinātas, izmantojot Centrālās Statistikas pārvaldes' +
              " izmantotos koeficientus: 20'konteineram - 1 TEU, >20' un <40' konteineram - 1,5 TEU, 40'konteineram " +
              "- 2TEU, 45' konteineram - 2,25TEU, > 45' konteineram - 2,25TEU. Citiem konteineriem tiek piemērots" +
              ' koeficients 1TEU.',
          },
          {
            title: '5. Nekonteinerizēta krava',
            dataSource: [
              {
                title: 'Kravas, kas nav konteinerizētas',
                key: 'cargo',
              },
            ],
            columns: [
              {
                title: '',
                key: 'title',
                dataIndex: 'title',
              },
              {
                title: 'Ar kuģiem no Rīgas NOSŪTĪTS (tonnas):',
                key: 'sent',
                render: () => <Input />,
              },
              {
                title: 'Ar kuģiem no Rīgas SAŅEMTS (tonnas):',
                key: 'recieved',
                render: () => <Input />,
              },
              {
                title: 'KOPĀ (tonnas):',
                key: 'total',
                render: () => <Input disabled />,
              },
            ],
            note:
              'Ja papildus konteinerkravām līnijas kuģos Rīgā uzkrautas vai izkrautas kravas, kas' +
              ' nav konteinerizētas, norādiet to daudzumu pārskata periodā tabulā',
          },
        ],
      },
    ];

    const tabPanes = dataSource.map((e, i) => {
      const tables = e.tables
        ? e.tables.map((el, index) => {
            const { title, subtitle, columns, note, ...props } = el;
            return (
              <span key={index}>
                {title ? <h3>{title}</h3> : ''}
                {subtitle ? <p>{subtitle}</p> : ''}
                <Table dataSource={el.dataSource} columns={columns} pagination={false} {...props} />
                <p>{note || ''}&nbsp;</p>
              </span>
            );
          })
        : '';
      const last = dataSource.length === i + 1;
      return (
        <TabPane tab={e.title} key={e.key} forceRender>
          <h2>{e.title}</h2>
          {tables}
          <Button
            onClick={() => (last ? '' : this.changeActiveKey(dataSource[i + 1].key))}
            className={'styledButton moveRight'.concat(last ? '' : ' nextButton')}
          >
            <span>{last ? 'Iesniegt' : 'Tālāk'}&nbsp;</span>
          </Button>
        </TabPane>
      );
    });

    return (
      <>
        <br />
        <h2>Atskaite par kuģošanas līnijas darbību Rīgas brīvostā</h2>
        <Row {...gridRow}>
          <Col {...gridCol} xs={24} lg={colSize.lg} xl={colSize.xl} xxl={colSize.xxl}>
            <Tabs
              type="card"
              className="content-tabs"
              activeKey={activeKey}
              onChange={(key) => this.changeActiveKey(key)}
            >
              {tabPanes}
            </Tabs>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(ShippingLineReport);
