import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Card, Table, message } from 'antd';
import { inject, observer } from 'mobx-react';
import { gridCol2, gridRow } from '@ois-lib';
import './dashboard/Dashboard.css';
import moment from 'moment';
import EditNotifications from './EditNotifications';

@inject('appStore', 'authStore', 'companyStore', 'oksNotificationStore')
@observer
class Notifications extends React.Component {
  static Edit = EditNotifications;

  componentDidMount() {
    if (!this.props.appStore.incompleteCompanyInfo) {
      message.info('Lūdzu, ievadiet datus par uzņēmumu!');
      this.props.history.push('/company/edit');
    }
    const { oksNotificationStore } = this.props;
    oksNotificationStore.load();
  }
  render() {
    // const { contractStore, history } = this.props;
    const { oksNotificationStore, history } = this.props;
    // console.log(oksNotificationStore);
    const notificationsDataSource =
      oksNotificationStore && oksNotificationStore._data
        ? oksNotificationStore._data
            //.filter((e) => !e.validTill || moment(e.validTill).isAfter(moment()))
            .sort((a, b) => {
              return moment(a.pivot.created_at).isAfter(moment(b.pivot.created_at), 'second') ? -1 : 1;
            })
            .map((e) => {
              console.log(!e.pivot.readAt);
              return {
                title: e.subject,
                date: moment(e.pivot.created_at).format(),
                key: e.id,
                isNew: !e.pivot.readAt,
                name: e.oksNotificationSender.name,
              };
            })
        : [];
    //     {
    //       title: `Sveicam Jūs jaunajā Ostas Informācijas sistēmā! Šobrīd vēl veicam sistēmas pielāgošanu, tādēļ dažas sadaļas vēl nav pieejamas, vai arī var nedarboties korekti. Informēsim Jūs par atjauninājumiem!\n
    //       Lūdzam ar jautājumiem par jaunās sistēmas lietošanu sazināties ar Denisu Bičkovu, e-pasts deniss.bickovs@rop.lv, tel. +371 27896336`,
    //       date: '15.02.2021.',
    //       key: 0,
    //       source: '#',
    // }
    const notificationsColumns = [
      {
        title: 'Datums / Laiks',
        dataIndex: 'date',
        key: 'date',
        className: 'center',
      },
      {
        title: 'Sūtītājs',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Temats',
        // render: (record) => (
        //   // target="_blank"
        //   <a href={record.source} rel="noopener noreferrer" className="notificationLink">
        //     {record.title}
        //   </a>
        // ),
        dataIndex: 'title',
        key: 'title',
      },
    ];
    return (
      <div>
        <br />
        <Row {...gridRow} gutter={[16, 16]} type="flex">
          <Col {...gridCol2}>
            <Card title="Paziņojumi">
              <Table
                dataSource={notificationsDataSource}
                columns={notificationsColumns}
                // showHeader={false}
                // pagination={false}
                onRow={(row) => {
                  return {
                    onClick: (event) => history.push(`/notifications/${row.key || ''}`),
                  };
                }}
                rowClassName={(record, index) => (record.isNew ? 'pointer bold' : 'pointer')}
                className="NotificationsTable"
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Notifications);
