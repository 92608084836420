/* eslint-disable eqeqeq */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card, Button, Modal, message, Alert, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, EditFormBase, FormItem, gridRow, gridCol, gridCol2, FontAwesomeIcon } from '@ois-lib';
import EditCalculationRowForm from './Forms/EditCalculationRow';

@inject('calculationStore', 'changeRecordsStore', 'appStore', 'authorize', 'can')
@observer
class EditCalculation extends EditFormBase {
  title = 'Kalkulācijas kartiņa';

  storeName = 'calculationStore';
  baseUrl = '/finance/calculations';

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    const { calculationStore, filter } = this.props;
    if (props && props.match && this.props && this.props.match && props.match.params.id != this.props.match.params.id) {
      this.load(calculationStore, true, props);
    }
    calculationStore.item.type = filter ? filter.toUpperCase() : 'PORTDUES';
  }

  async componentDidMount() {
    const { changeRecordsStore, calculationStore, match, can } = this.props;
    if (match.params.id !== 'new' && can('view change_record')) {
      const changes = {
        title: 'Veiktās izmaiņas',
        key: 'diff',
        type: 'text',
        asText: true,
        render: (value, row) => {
          if (row && row.changesCount) {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return <a href="#">Skatīt versiju</a>;
          }
          return ' - ';
        },
        // json: true,
      };

      for (const key of Object.keys(changes)) {
        changeRecordsStore.colByName.diff[key] = changes[key];
      }

      this.changeRecordsStore = changeRecordsStore;
      this.changeRecordsStore.systemFilters = {
        objectType: [calculationStore.dataSet],
        objectId: [parseInt(match.params.id, 10)],
      };
      this.changeRecordsStore.load();
    }
    this.load(calculationStore);
  }

  componentWillUnmount() {
    this.setVersion();
  }

  actionButtons(store, defaultActions) {
    const actions = defaultActions || [];
    actions.map((action) => {
      if (action && action.key === 'save') {
        action.resolver = (e) => this.handleSubmit(e);
          // this.props.match.params.id !== 'new' ? this.handleSubmitWithComment(e) : this.handleSubmit(e);
      }
      return action;
    });

    if (
      !this.forceDisableEdit &&
      !store.loadingOne &&
      !store.item.state &&
      this.props.match.params.id !== 'new' &&
      this.canAccept &&
      this.canAccept() &&
      !actions.find((a) => a && a.key === 'accept')
    ) {
      const { form } = this.props;
      const isDisabled = form.isFieldsTouched() || store.savingOne;

      actions.push({
        title: 'Apstiprināt kalkulāciju',
        key: 'accept',
        button: {
          title: 'Apstiprināt kalkulāciju',
          tooltip: isDisabled ? 'Lūdzu, saglabājiet izmaiņas, lai varētu apstiprināt kalkulāciju' : undefined,
          icon: 'check',
          props: {
            type: 'primary',
            className: 'color-green',
            disabled: () => isDisabled,
          },
        },
        resolver: () =>
          Modal.confirm({
            title: 'Vai tiešām vēlaties apstiprināt kalkulāciju?',
            onOk: async () => {
              try {
                const res = await store.call('accept', {});
                if (res.ok) {
                  message.success('Kalkulācija apstiprināta!');
                } else {
                  message.error(`Kļūda: ${res.error}`);
                }
              } catch (e) {
                message.error('Notikusi sistēmas kļūda apstiprinot kalkulāciju.');
              }
              this.load(store, true);
            },
            okType: 'primary',
            okText: 'Apstiprināt kalkulāciju',
            cancelText: 'Aizvērt',
          }),
      });
    }

    return actions;
  }

  setVersion(version = undefined) {
    const { history } = this.props;
    const linkParts = history.location.pathname.split('@');
    if (!version) {
      history.push(linkParts[0]);
    } else {
      linkParts[1] = version;
      history.push(linkParts.join('@'));
    }
  }

  handleSubmitWithComment(e) {
    e.preventDefault();
    return Modal.info({
      title: 'Lūdzu, ievadiet izmaiņu iemeslu!',
      content: (
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label">
            <h5 title="Iemesls">Iemesls</h5>
          </div>
          <div className="ant-col ant-form-item-control-wrapper" style={{ marginBottom: 15 }}>
            <div className="ant-form-item-control">
              <span className="ant-form-item-children">
                <Input.TextArea
                  rows={4}
                  onChange={(v) => {
                    this.comment = v.target.value;
                  }}
                />
              </span>
            </div>
          </div>
          <Alert message="Apstiprinot šo paziņojumu, ieraksts tiks saglabāts." type="info" showIcon />
        </div>
      ),
      onOk: async () => {
        try {
          this._extra_data = { _extra_comment: this.comment };
          e.persist();
          return await this.handleSubmit(e);
        } catch (err) {
          message.error('Notikusi sistēmas kļūda!');
          console.error(err);
          return null;
        }
      },
      okType: 'primary',
      okText: 'Saglabāt izmaiņas',
      cancelText: 'Aizvērt',
    });
  }

  canSave() {
    // Check status  ? 
    // !this.forceDisableEdit && !['ACCEPTED', 'CANCELED'].includes(calculationStore.item.state);
    return !this.forceDisableEdit;
  }

  canAccept() {
    return true;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    const { /* authorize, */ match, history, calculationStore } = this.props;
    // authorize([match.params.id !== 'new' && 'view calculations', 'edit calculations']);

    const linkParts = history.location.pathname.split('@');

    return (
      <>
        <Row {...gridRow} className="calclulation-rows" style={{ marginTop: 20 }}>
          {calculationStore.item &&
            calculationStore.item._messages &&
            calculationStore.item._messages.map((msg, i) => (
              <Col key={`t_${i}`} {...gridCol2} style={{ marginBottom: 10 }}>
                <Alert message={msg} type="warning" showIcon closable />
              </Col>
            ))}
          <Col {...gridCol}>
            <Card title="Pamatinformācija" bordered>
              <Col {...gridCol2}>
                <FormItem asText name="type" />
                <FormItem asText name="nr" />
                <FormItem asText name="date" />
                <FormItem asText name="dueDate" />
                <FormItem asText name="recipientId" />
                <FormItem asText name="charterer" />
                <FormItem asText name="bankAccountId" />
                <FormItem asText name="amount" />
                <FormItem asText name="vat" />
                <FormItem asText name="shipVisitId" />
                <FormItem asText name="status" />
                <FormItem asText name="comparisonDate" />
                <FormItem asText name="comparedId" />
                <FormItem asText name="publishedDate" />
                <FormItem asText name="clientAcquaintanceDate" />
                <FormItem name="partnerComment" canEdit={!this.forceDisableEdit} />
                <FormItem asText name="glossary" />
              </Col>
            </Card>
          </Col>
          <Col {...gridCol}>
            <FormItem
              disabled
              formKind={EditCalculationRowForm}
              name="calculationRows"
              rowOnClick={(row, callback) => {
                if ('details' in row && 'description' in row) {
                  callback(row);
                }
              }}
              modalProps={{ width: '720px' }}
              formItemProps={{ className: 'mt15' }}
              addButtonLabel="Pievienot korekciju"
              extraItemProps={{
                rowClassName: (_record, _key, level) => {
                  if (level === 1) {
                    return 'red-row';
                  }
                  if (level === 2) {
                    return 'blue-row';
                  }
                  return null;
                },
                groupBy: 'calculationTariffId',
                childrenColumnName: 'details',
                expandAll: true,
                /* rowSelection */
              }}
            />
            {match.params.id !== 'new' &&
              calculationStore.item.calculationRows &&
              !!calculationStore.item.calculationRows.length && (
                <Button
                  disabled={this.props.form.isFieldsTouched()}
                  style={{ marginBottom: '25px' }}
                  href={`/api/v1/calculations/${match.params.id}/invoice/`}
                  target="_blank"
                >
                  <FontAwesomeIcon icon="file-pdf" />
                  Kopskats
                </Button>
              )}
          </Col>
        </Row>
        <Row {...gridRow}>
          <Col {...gridCol}>
            {this.changeRecordsStore && (
              <DataTable
                store={this.changeRecordsStore}
                title="Izmaiņu žurnāls"
                addButtonIcon={<FontAwesomeIcon icon="eye" />}
                addButtonLabel="Rādīt aktuālo dokumentu"
                onAdd={linkParts[1] ? () => this.setVersion() : undefined}
                rowClassName={(record) => {
                  if (record.id == linkParts[1]) {
                    return `change-record current`;
                  }
                  return `change-record`;
                }}
                onRowClick={(row) => this.setVersion(row.id)}
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditCalculation' })(EditCalculation));
