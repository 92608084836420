import DataStoreFactory from './DataStoreFactory';
import FieldSpec, { classifiers } from './FieldSpec';

class StoreFactoryClass {
  classifiers = classifiers;
  stores = {};
  storesByDataSet = {};
  cache = {};

  getStore(dataSet, instanceName = '', refresh = false, _columnDataset = null) {
    if (instanceName && !instanceName.startsWith('_')) {
      instanceName = `_${instanceName}`;
    }
    if (!this.stores[dataSet + instanceName + (_columnDataset || '')] || refresh) {
      this.stores[dataSet + instanceName + (_columnDataset || '')] = new DataStoreFactory(
        _columnDataset || dataSet,
        dataSet,
        FieldSpec,
        this.appStore,
        instanceName
      );

      if (!this.storesByDataSet[dataSet]) this.storesByDataSet[dataSet] = [];
      this.storesByDataSet[dataSet].push(this.stores[dataSet + instanceName + (_columnDataset || '')]);
      this.dataSetStores = this.storesByDataSet[dataSet];
    }
    return this.stores[dataSet + instanceName + (_columnDataset || '')];
  }

  getCache(dataSet) {
    if (!this.cache[dataSet]) {
      this.cache[dataSet] = {}; // If does not work try to use it as Singleton instead
    }
    return this.cache[dataSet];
  }
}

const StoreFactory = new StoreFactoryClass();
export default StoreFactory;
