import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { observer } from 'mobx-react';
import { Field, Spinner } from './Field';
import 'rc-color-picker/assets/index.css';

const ListObserverComponent = observer(({ parentObject, value }) => {
  const columns = [
    {
      title: 'Iestāde',
      dataIndex: 'organization',
    },
    {
      title: 'Lēmums',
      dataIndex: 'status',
      render: (_value) => {
        const statusMap = {
          null: 'Nav',
          ACCEPTED: 'Atļauts',
          REJECTED: 'Aizliegts',
          COMMENTED: 'Nav', // 'Pievienots komentārs'
        };
        const style = {};
        switch (_value) {
          case 'ACCEPTED':
            style.color = '#007314';
            break;
          case 'REJECTED':
            style.color = '#f5222d';
            break;
          // case 'COMMENTED':
          //   style.color = '#008cff';
          //   break;
          default:
        }
        return <span style={style}>{statusMap[_value]}</span>;
      },
    },
    {
      title: 'Komentārs',
      dataIndex: 'comment',
      render: (_value) => _value || ' - ',
    },
    {
      title: 'Laiks',
      dataIndex: 'updatedTime',
      render: (_value) => (_value ?
        (moment(_value).isBefore(moment().startOf('day')) ?
          moment(_value).format('DD.MM.YYYY LTS')
          :
          moment(_value).format('LTS'))

        : ' - '),
    },
  ];

  const options = parentObject.optionSource ? parentObject.getOptionsFromStore() : parentObject.options;
  const loading = parentObject.optionStore && parentObject.optionStore.loadingFull;
  const list = value ? [...new Set(value)] : [];

  if (!parentObject.filterFunc) {
    parentObject.filterFunc = (i) => i;
  }

  const organizations = options
    .filter(parentObject.filterFunc)
    .map((option, key) => {
      const val = list.length ? list.find((v) => v.controlOrganizationId === option.key) : null;
      return {
        key,
        organization: val && val.controlOrganization ? val.controlOrganization.code : option.value,
        status: val ? val.status : null,
        comment: val ? val.comment : null,
        updatedTime: val && val.updatedTime ? val.updatedTime : null,
      };
    })
    .sort((a, b) => {
      if (typeof a.organization === 'string') {
        return (
          a.organization.localeCompare(b.organization, 'lv', {
            ignorePunctuation: true,
          }) * 1
        );
      }
      return (a.organization > b.organization ? 1 : -1) * 1;
    });

  return loading ? (
    <Spinner />
  ) : (
    <Table
      style={{ width: '100%' }}
      columns={columns}
      dataSource={organizations}
      size="small"
      className="borderless"
      pagination={false}
    />
  );
});

@Field.register
class ListComponent extends Field {
  static code = 'list';

  getFormLabel() {
    return undefined;
  }

  renderComponent({ value }) {
    return <ListObserverComponent value={value} parentObject={this} />;
  }
}

export default ListComponent;
