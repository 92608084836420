import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { gridCol, gridRow, DataTable } from '@ois-lib';
import StoreFactory from '@ois-lib/stores';
import EditCalculation from './EditCalculation';

@inject('authorize', 'can')
@observer
class Calculations extends React.Component {
  static Edit = EditCalculation;

  title = 'Kalkulācijas';

  constructor(props) {
    super(props);
    if (props.title) {
      this.title = props.title;
    }
    this.store = StoreFactory.getStore('calculations', props.filter ? props.filter.toUpperCase() : undefined);
    this.store.systemFilters = props.filter ? { type: props.filter.toUpperCase() } : {};
  }

  componentDidMount() {
    if (this.store.systemFilters) {
      this.store.keepOriginalValues = ['shipVisitId'];
      this.store.load();
    }
  }

  render() {
    const { isWidget, history, /*filter, authorize,*/ can } = this.props;
    // authorize(['view calculations', 'edit calculations']);
    const link = `/finance/calculations/`;
    const Widget = (
      <DataTable
        store={this.store}
        title={this.title}
        addButtonLabel="Pievienot korekciju"
        onRowClick={(row) => history.push(`${link}${row.id || ''}`)}
        onAdd={false && can('edit calculations') && (() => history.push(`${link}new`))}
      />
    );

    return isWidget ? (
      Widget
    ) : (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>{Widget}</Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Calculations);
