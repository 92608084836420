import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Row, Col, Card, Tooltip } from 'antd';
import { gridRow, gridCol2 } from '..';
import { Field, Spinner } from './Field';

const MultiCheckboxexComponent = observer(({ col, ...props }) => {
  let options = col.optionSource ? col.getOptionsFromStore() : col.options;
  const loading = col.optionStore && col.optionStore.loadingFull;
  const value = props.value ? [...new Set(props.value)] : [];
  const { asText } = props;

  let vdom = '';
  if (asText) {
    delete props.asText;
  }

  if (col.optionsSortFunc && options) {
    options = options.sort(col.optionsSortFunc);
  }

  if (col.grouped) {
    const groups = [...new Set(options.map((o) => o.group))];
    vdom = (
      <Row {...gridRow}>
        <Checkbox.Group className="vertical-checkbox-group" {...props} value={value}>
          {groups.map((group, i) => (
            <Col key={i} {...gridCol2} className="mt15">
              <Card title={group}>
                <Row {...gridRow}>
                  {options &&
                    options
                      .filter((o) => o.group === group)
                      .map((option, key) => {
                        if (!asText) {
                          return (
                            <Col key={key} {...gridCol2}>
                              <Checkbox value={option.key}>{option.value}</Checkbox>
                            </Col>
                          );
                        }
                        if (value.includes(option.key)) {
                          return (
                            <Col key={key} {...gridCol2}>
                              <span>{option.value}</span>
                            </Col>
                          );
                        }
                        return null;
                      })}
                </Row>
              </Card>
            </Col>
          ))}
        </Checkbox.Group>
      </Row>
    );
  } else {
    vdom = (
      <Checkbox.Group
        className="vertical-checkbox-group"
        {...props}
        value={value}
        options={
          options &&
          options
            .filter((option) => {
              if (props.showonlychecked) {
                return props.value && props.value.includes(option.key);
              }
              return true;
            })
            .map((option, i) => ({
              label: (
                <Tooltip
                  title={
                    props.itemtooltip && props.itemtooltip.length
                      ? props.itemtooltip.filter((e) => e.id === option.key).map((e) => e.tooltip)[0]
                      : null
                  }
                >
                  {option.value}
                </Tooltip>
              ),
              value: option.key,
            }))
        }
      />
    );
  }
  return loading ? <Spinner /> : vdom;
});

@Field.register
class MultiCheckboxes extends Field {
  static code = 'multicheckbox';

  renderComponent(props) {
    if (this.label) {
      props.asText = true;
    }
    return <MultiCheckboxexComponent col={this} {...props} />;
  }
}

export default MultiCheckboxes;
