import React from 'react';
import { Empty, Skeleton } from 'antd';
import StoreFactory from '@ois-lib/stores';
import { when } from 'mobx';
import {getUri} from "../helpers/url";

class ShipInfoComponent extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ref: false,
    };

    if (props.keepColumnOrder) {
      this.keepColumnOrder = props.keepColumnOrder;
    }

    if (props.values) {
      this.values = props.values;
    }

    if (props.fieldSpec) {
      this.fieldSpec = props.fieldSpec;
      const col = this.fieldSpec.colByKey.shipVisits.shipInId;
      this.store = col.optionStore || this.getStore();
      // if(this.store){
      //   this.store.keepOriginalValues = [];
      // }
    }

    this.colsLoaded = {};
    this.renderedBox = null;
  }

  // componentDidMount() {
  //   if (this.store) {
  //     this.store.loadIfEmpty();
  //   }
  // }

  getStore() {
    return StoreFactory.getStore('ships', '_options');
  }

  get renderBoxContent() {
    if (this.store) {
      const { columns } = this.props;
      let values = this.values;
      const shipId = this.props.shipId ? parseInt(this.props.shipId, 10) : null;
      if (!values && shipId) {
        if (!this.store) {
          this.store = this.getStore();
        }
        const opts = this.store.getAsOptions;
        if (opts) {
          values = opts.find((o) => o.key === shipId);
        }
      }

      if (values) {
        if (!values.id && shipId) {
          values.id = shipId;
        }
        for (const sKey in values) {
          if (!columns.includes(sKey) && !columns.includes(sKey.replace('_t_', ''))) {
            delete values[sKey];
            delete values[`_t_${sKey}`];
          } else {
            if (values[`_t_${sKey}`]) {
              values[sKey] = values[`_t_${sKey}`];
              delete values[`_t_${sKey}`];
            }
          }
        }
      }

      const items = this.store.normalizeTableRecord && this.store.normalizeTableRecord(values, true);
      let itemKeys = Object.keys(items);
      if (this.keepColumnOrder && columns) {
        itemKeys = columns.filter((c) => itemKeys.includes(c));
      }
      this.renderedBox = (
        <ul className="clean-list">
          {itemKeys.map((k) => {
            const cTitle = this.fieldSpec.getColTitle('ships', k, true);
            if (!cTitle) {
              return null;
            }
            let value = items[k];
            const col = this.fieldSpec.getCol('ships', k);
            if (col.optionStore && !this.colsLoaded[k]) {
              this.colsLoaded[k] = true;
              when(
                () => !col.optionStore.loadingFull && col.optionStore._dataFull.length,
                () => {
                  clearTimeout(this.reloadTimeout);
                  this.reloadTimeout = setTimeout(() => {
                    this.forceUpdate();
                  }, 200);
                }
              );
            }

            return (
              <li key={k}>
                <strong>{cTitle}: </strong> <em>{value || '(nav norādīts)'}</em>
              </li>
            );
          })}
            <li key="shipUrl">
                <a href={`${getUri()}/catalog/ships/${this.props.shipId}`} target="_blank" rel="noopener noreferrer">
                    Kuģa kartiņa
                </a>
            </li>
        </ul>
      );
      return this.renderedBox;
    }
    return <Empty />;
  }

  render() {
    let isVisible = false;
    const element = this.state.ref;
    if (element) {
      isVisible = true;
    }
    return (
      <div
        ref={(r) => {
          if (!this.state.ref) {
            this.setState({ ref: r });
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isVisible ? this.renderBoxContent : <Skeleton />}
      </div>
    );
  }
}

export default ShipInfoComponent;
