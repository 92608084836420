import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol, gridCol2, gridRow, FormItem } from '@ois-lib';

@inject('helpGroupStore', 'can', 'appStore')
@observer
class EditHelpGroup extends EditFormBase {
  title = 'Grupa';
  storeName = 'helpGroupStore';
  baseUrl = '/help';

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const {
      [this.storeName]: store,
      form,
      match,
      history: { location },
    } = this.props;
    store.redirectTo = (item) => `/helpContent/${item.key}`;
    this.load(store);

    this.store = store;

    const { searchParams } = location;
    if (match.params.id) {
      const { sysGroup } = searchParams;
      setTimeout(() => form.setFieldsValue({ sysGroup: sysGroup || window.localStorage.helpSysGroup }));
    }
  }

  async componentDidMount() {
    this.props.helpGroupStore.redirectTo = (item) => `/helpGroup/${item.key}`;
    this.load(this.props.helpGroupStore);
  }

  canSave() {
    return this.props.can('edit help.helpGroup');
  }

  canDelete() {
    return this.props.can('delete help.helpGroup');
  }

  renderForm() {
    return (
      <>
        {' '}
        <Row {...gridRow}>
          <Col {...gridCol}>
            <h1 className="cabinet_title">{this.title}</h1>
          </Col>
        </Row>
        <Row {...gridRow}>
          <Col {...gridCol2}>
            <Card title="Palīga grupa" bordered className="mt15">
              <FormItem name="name" />
              <FormItem name="key" />
              <FormItem
                asText={!!window.localStorage.helpSysGroup || this.store.item.sysGroup}
                hidden={!this.props.can('edit help.helpGroup')}
                name="sysGroup"
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditHelpGroup' })(EditHelpGroup));
