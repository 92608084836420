import React from 'react';
import { Radio } from 'antd';
import { observer } from 'mobx-react';
import { Field, Spinner } from './Field';

const RadioboxLabel = observer(({ value, col }) => {
  const res = col.loadRowTransform({ [col.key]: value });
  return <>{res}</>;
});

const RadioboxObserver = observer(({ col, vertical, mode, ...props }) => {
  const { addonBefore, addonAfter } = props;
  const options = col.optionSource ? col.getOptionsFromStore() : col.options;
  const loading = col.optionStore && col.optionStore.loadingFull;
  let radioStyle = {};
  let RadioComponent = Radio;

  if (mode && mode === 'button') {
    RadioComponent = Radio.Button;
  } else if (vertical) {
    radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
  }

  return loading ? (
    <Spinner />
  ) : (
    <div style={{position: "relative"}}>
        {addonBefore}
        <Radio.Group {...props}>
          {options &&
            options.map((option) => (
              <RadioComponent key={option.key} value={option.key} style={radioStyle} >
                {option.value}
              </RadioComponent>
            ))}
        </Radio.Group>
        {addonAfter}
    </div>
  );
});

@Field.register
class RadioboxComponent extends Field {
  static code = 'radio';

  loadFormTransform(item) {
    return super.loadFormTransform(
      item,
      item[this.key] === null && this.options.find((o) => o.key.toLowerCase() === 'null') ? 'null' : item[this.key]
    );
  }

  renderComponent({ asText, ...props }) {
    if (this.label || asText) {
      return <RadioboxLabel col={this} {...props} />;
    }
    return <RadioboxObserver col={this} {...props} />;
  }
}

export default RadioboxComponent;
