import StoreFactory from '../stores';

export default class Addon {
  static components = {
    unknown: (config) => {
      console.warn('Unknown component: ', config);
      return null;
    },
  };
  static get(code) {
    const component = Addon.components[code];
    return component || Addon.components.unknown;
  }
  static make(type, options) {
    return new (Addon.get(type))(type, options);
  }
  static register(target) {
    Addon.components[target.code] = target;
  }

  storeParams = {};

  rowId = null;

  constructor(type, config) {
    this.config = config;
    if (config.fieldProps && config.fieldProps.value) {
      this.rowId = config.fieldProps.value;
    }
    if (config.addonStore) {
      this.store = config.addonStore;
    }
    if (config.addonFormStore) {
      this.formStore = config.addonFormStore;
    }

    if (config.optionSource && StoreFactory) {
      this.optionSource = StoreFactory.getStore(config.optionSource);
    }

    if (config.fieldSource && StoreFactory && config.fieldProps.value) {
      this.fieldStore = StoreFactory.getStore(config.fieldSource);
    }
  }

  getFromStore() {
    if (!StoreFactory) {
      return [];
    }
    if (!this.optionStore) {
      this.optionStore = StoreFactory.getStore(this.optionSource);
      this.optionStore.loadIfEmpty();
    }
    if (this.optionKeyName) {
      this.optionStore.optionKeyName = this.optionKeyName;
    }
    if (this.optionValueName) {
      this.optionStore.optionValueName = this.optionValueName;
    }
    return this.optionStore.getAsOptions;
  }

  getFromItemStore() {
    if (!StoreFactory) {
      return [];
    }
    if (!this.optionStore) {
      this.optionStore = StoreFactory.getStore(this.optionSource);
      this.optionStore.loadIfEmpty();
    }
    if (this.optionKeyName) {
      this.optionStore.optionKeyName = this.optionKeyName;
    }
    if (this.optionValueName) {
      this.optionStore.optionValueName = this.optionValueName;
    }
    return this.optionStore.getAsOptions;
  }
}
