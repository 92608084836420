import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';

@inject('appStore', 'contractStore')
@observer
class Contracts extends React.Component {
  title = 'Līgumi';
  componentDidMount() {
    const { contractStore } = this.props;
    contractStore.load();
  }
  render() {
    const { contractStore, history } = this.props;

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1 className="cabinet_title">{this.title}</h1>
          <div>
            <span className="ant-dropdown-link" style={{ color: 'gray' }}>
              <Icon type="down" />
            </span>
          </div>
        </div>
        <Row {...gridRow}>
          <Col {...gridCol}>
            <DataTable
              store={contractStore}
              title={this.title}
              onRowClick={(row) => {
                history.push(`/contracts/${row.id || ''}`);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Contracts);
