import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol } from '@ois-lib';

@inject('appStore', 'authorize', 'can')
@observer
class EditShipStore extends EditFormBase {
  title = 'Kuģu krājums';
  storeName = 'store';

  canSave() {
    return false;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.stores', 'edit ship_visits.stores']);

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Kuģu krājums" bordered={false}>
            <FormItem name="shipsStoreTypeId" />
            <FormItem name="unitId" />
            <FormItem name="qty" />
            <FormItem name="location" />
            <FormItem name="purpose" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditShipStore' })(EditShipStore);
