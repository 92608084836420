import React from 'react';
import ReactQuill from 'react-quill';
import { Field } from './Field';
import api from '../services/api';
import 'react-quill/dist/quill.snow.css';

@Field.register
class RichTextComponent extends Field {
  static code = 'richtextarea';

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  updateEditorView() {
    if (this.ref) {
      clearTimeout(this.updator);
      this.updator = setTimeout(() => {
        const editor = this.ref.getEditingArea();
        const images = editor.querySelectorAll('img[src^="[data-image-url]:"]');
        for (const image of images) {
          image.src = image.src.replace('[data-image-url]:', '/api/v1/download/');
        }
      }, 400);
    }
  }

  setRef(ref = null) {
    if (!this.ref && ref) {
      this.ref = ref;
      // console.log('this.ref: ', this.ref);
      // console.warn('REF is set!');
      // setTimeout(() => this.updateEditorView(), 200);
    }
  }

  handleChange = async (value, delta, type, onChange) => {
    if (type === 'user') {
      if (delta.ops) {
        for (const d of delta.ops) {
          if (d.insert && typeof d.insert === 'object') {
            const { image } = d.insert;
            if (image && image.startsWith('data:')) {
              // eslint-disable-next-line no-await-in-loop
              const imageBlobObject = await fetch(image).then((data) => data.blob());
              if (imageBlobObject) {
                // eslint-disable-next-line no-await-in-loop
                const result = await api.fileUpload(imageBlobObject);
                if (result && result.data) {
                  value = value.replace(image, `[data-image-url]:${result.data.guid}`);
                }
              }
            }
          }
        }
        // this.updateEditorView();
      }
    }
    onChange(value);
  };

  renderComponent({ asText, ...props }) {
    if (this.label || props.asText) {
      let { value } = props;
      value = value.replace('[data-image-url]:', '/api/v1/download/');
      /* eslint-disable react/no-danger */
      return <div className="normalize-text" dangerouslySetInnerHTML={{ __html: value }} />;
    }

    const value = props.value ? props.value.replace('[data-image-url]:', '/api/v1/download/') : null;

    return (
      <ReactQuill
        {...props}
        ref={this.setRef.bind(this)}
        modules={this.modules}
        formats={this.formsats}
        name="editor"
        onChange={(v, d, t) => {
          this.handleChange(v, d, t, props.onChange);
        }}
        value={value || ''}
        theme="snow"
      />
    );
  }
}

export default RichTextComponent;
