import React, { useState, cloneElement } from 'react';
import Cookies from 'js-cookie';
import { toJS, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Table, Button, Input, Row, Col, Card, Tag, Pagination, Tooltip, Dropdown, Menu, Icon, Modal } from 'antd';
import api from '@ois-lib/services/api';
import TableSettings from './DataTableSettings';
import DataCalendar from './components/Calendar';
import FontAwesomeIcon from './assets/icon/FontAwesomeIcon';
import StoreFactory from './stores';
import { withRouter } from 'react-router';
import { Spinner } from './addons/components/ButtonWrapComponent';

const { Search } = Input;

const minColWidth = 80;

const resizeColumnFunc = (element, key, store) => {
  const i = {
    isMouseDown: false,
    element: element,
    tr: element.parentElement.parentElement,
    th: element.parentElement,
    initialColumnWidth: element.parentElement.offsetWidth,
    startPosX: 0,
    movedByX: 0,
    calcWidth: 0,
    store,
  };

  element.addEventListener('mousedown', (e) => {
    e.stopPropagation();
    i.startPosX = e.pageX;
    // x = window.Event
    //   ? e.pageX //clientX
    //   : document.documentElement.scrollLeft
    //   ? document.documentElement.scrollLeft
    //   : document.body.scrollLeft;
    i.initialColumnWidth = i.th.offsetWidth;
    i.tr.classList.add('resizing');
    i.th.classList.add('resizing');
    i.calcWidth = minColWidth;
    i.isMouseDown = true;
  });
  window.addEventListener('mousemove', (e) => {
    if (i.isMouseDown === true) {
      i.movedByX = e.pageX - i.startPosX;

      const maxColWidth = parseFloat(i.store.activeColumns.length * minColWidth);
      let newWidth = parseFloat(i.initialColumnWidth + i.movedByX);
      if (i.store.activeColumns.length && i.movedByX > maxColWidth) {
        newWidth = Math.max([parseFloat(i.tr.offsetWidth - maxColWidth), i.initialColumnWidth]);
      }

      i.calcWidth = newWidth > minColWidth ? newWidth : minColWidth;
      if (i.th.hasAttribute('width')) {
        i.th.removeAttribute('width');
      }
      i.th.style.width = i.calcWidth + 'px';
      // i.th.setAttribute('width', i.th.style.width);
    }
  });

  window.addEventListener('mouseup', (e) => {
    e.stopPropagation();
    if (i.isMouseDown === true) {
      i.tr.classList.remove('resizing');
      i.th.classList.remove('resizing');
      i.store.setColumnWidth(key, i.calcWidth);
      i.isMouseDown = false;
    }
  });
};

const TableWrapper = ({ store, setFilters, setSorter, extraprops, className, ...rest }) => {
  const [value, setValue] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState({});

  const firstCol = rest.children && rest.children.length ? rest.children[0].props.columns[0] : null;
  if (firstCol && firstCol.key === 'selection-column') {
    store.controlColumns = [firstCol];
  }

  const _this = {
    resizableRef: {},
  };

  return (
    <thead className={className}>
      <tr>
        {store.activeColumnsForTable.map((col, index) => {
          const FilterIcon = col.filterIcon
            ? cloneElement(col.filterIcon(store.filters[col.key]), {
              className: 'anticon anticon-filter ant-dropdown-trigger',
            })
            : null;
          let sorter = store.columnSorters.find((s) => s.key === col.key);
          const thProps = {};
          if (store.columnWidths[col.key]) {
            thProps.width = store.columnWidths[col.key] + 'px';
          }
          return (
            <th
              key={col.key}
              className="ant-table-column-has-actions ant-table-column-has-filters ant-table-column-has-sorters"
              {...thProps}
            >
              <span
                aria-hidden
                className="ant-table-header-column"
                onClick={() => {
                  if (col.sorter !== false) {
                    if (sorter) {
                      switch (sorter.direction) {
                        case 'asc':
                          sorter = 'desc';
                          break;
                        case 'desc':
                          sorter = null;
                          break;
                        default:
                          sorter = null;
                      }
                    } else {
                      sorter = 'asc';
                    }
                    setSorter(col.key, sorter || null);
                  }
                }}
              >
                <div className="ant-table-column-sorters">
                  <span className="ant-table-column-title">
                    <span>{col.title}</span>
                  </span>
                  {col.sorter && col.sorter !== false && (
                    <span className="ant-table-column-sorter">
                      <div title="Sort" className="ant-table-column-sorter-inner ant-table-column-sorter-inner-full">
                        <i
                          aria-label="icon: caret-up"
                          className={`anticon anticon-caret-up ant-table-column-sorter-up ${sorter && sorter.direction === 'asc' ? 'on' : 'off'
                            }`}
                        >
                          <svg
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            className=""
                            data-icon="caret-up"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            {/* eslint-disable-next-line max-len */}
                            <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
                          </svg>
                        </i>
                        <i
                          aria-label="icon: caret-down"
                          className={`anticon anticon-caret-down ant-table-column-sorter-down ${sorter && sorter.direction === 'desc' ? 'on' : 'off'
                            }`}
                        >
                          <svg
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            className=""
                            data-icon="caret-down"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            {/* eslint-disable-next-line max-len */}
                            <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
                          </svg>
                        </i>
                      </div>
                    </span>
                  )}
                </div>
              </span>
              {!extraprops.disableColumnResize && (
                <span
                  className="resizable"
                  ref={(refElement) => (refElement ? resizeColumnFunc(refElement, col.key, store) : null)}
                >
                  <FontAwesomeIcon icon="arrows-h" type="solid" />
                </span>
              )}
              {col.filterDropdown && (
                <Dropdown
                  placement={!index ? 'bottomCenter' : 'bottomRight'}
                  visible={dropdownVisible[col.key]}
                  overlay={
                    <div className="ant-table-filter-dropdown">
                      {col.filterDropdown({
                        setSelectedKeys: (_value) => setValue(_value),
                        selectedKeys:
                          value ||
                          (Array.isArray(store.filters[col.key]) ? store.filters[col.key] : [store.filters[col.key]]),
                        confirm: (_value) => {
                          // console.log(_value);
                          if ((_value.length === 1 && [undefined, null, ''].includes(_value[0])) || !_value.length) {
                            // console.log(`empty`);
                            delete store.filters[col.key];
                            setFilters({ ...store.filters });
                            setValue(null);
                          } else {
                            // console.log(`setFilters`);
                            setFilters({ ...store.filters, [col.key]: _value });
                          }
                        },
                        clearFilters: () => {
                          delete store.filters[col.key];
                          setFilters({ ...store.filters });
                          setValue(null);
                        },
                      })}
                    </div>
                  }
                  trigger={['click']}
                  onVisibleChange={(e) => {
                    setDropdownVisible({ [col.key]: e });
                  }}
                >
                  {FilterIcon}
                </Dropdown>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const tableHeaderWrapper = (store, setSorter, setFilters, extraprops = undefined) => (props) => (
  <TableWrapper store={store} setSorter={setSorter} setFilters={setFilters} extraprops={extraprops} {...props} />
);

const WrapperFragment = ({ children }) => <React.Fragment key="wrapper">{children}</React.Fragment>;

const PopupXY = ({ record, rowContextMenu, visible, x, y }) =>
  rowContextMenu && visible ? (
    <div className="popup-xy" style={{ left: `${x}px`, top: `${y}px` }}>
      {rowContextMenu(record)}
    </div>
  ) : null;
@observer
class DataTable extends React.Component {
  store = null;

  constructor(props) {
    super(props);
    this.state = {
      view: props.defaultView || 'table',
      modal: {
        resourceId: null,
        visible: false,
        open: (resourceId) => {
          const { modal } = this.state;
          modal.resourceId = resourceId || null;
          this.setState({ modal: { ...modal, visible: true } });
        },
        close: () => {
          const { modal, resourceId } = this.state;
          if (!['new', null].includes(resourceId)) {
            setTimeout(() => this.store.refresh(), 150);
          }
          this.setState({ modal: { ...modal, resourceId: null, visible: false } });
        },
      },
      rowContextPopup: {
        visible: false,
        x: 0,
        y: 0,
      },
      query: props?.urlSettings?.q ?? undefined,
      sorters: props?.urlSettings?.s ?? {},
      filters: props?.urlSettings?.f ?? {},
      page: props?.urlSettings?.p ?? 1,
      pageSize: props?.urlSettings?.ps ?? 10,
    };

    const { modalForm: ModalForm, defaultFilters, name } = props;
    if (ModalForm) {
      if (ModalForm.storeName) {
        this.store = StoreFactory.getStore(ModalForm.storeName, name);
        this._modalDefaultFilters = defaultFilters || { id: [-99999] };
        this.store.systemFilters = this._modalDefaultFilters;
        this.store.load();
      }
      this.ModalForm = ({ id, match, ...formProps }) => {
        const newMatch = { params: { id: id || 'new' } };
        return <ModalForm {...formProps} match={newMatch} modal />;
      };
    } else if (props.store) {
      this.store = props.store;
    }

    this.expanded = false;
    this.expandedRowKeys = [];
    this.expandedRows = {};
  }
  componentDidMount() {
    const { childrenColumnName } = this.props;
    if (this.store) {
      this.store._hasTable = true;
      this.store.setSearchQuery('');
      if (childrenColumnName) {
        this.store.childrenColumnName = childrenColumnName;
      }
    }
  }
  componentWillUnmount() {
    if (this.store) {
      this.store._hasTable = false;
    }
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { modalForm: ModalForm, store, forceUpdate } = this.props;
    const { defaultFilters } = newProps;
    if (ModalForm && JSON.stringify(this._modalDefaultFilters) !== JSON.stringify(defaultFilters)) {
      this.store.systemFilters = defaultFilters || {};
      this._modalDefaultFilters = this.store.systemFilters;
      if (!this.store.loading) {
        this.store.sysFilter({ ...this.store.filters, ...defaultFilters, fMode: 'strict' });
      }
    } else if ((!this.store && store) || (store && this.store && this.store.dataSet !== store.dataSet) || forceUpdate) {
      this.store = store;
    }

    if (newProps.useUrlSettings) {
      this.store.loadColStateFromUrl(new URLSearchParams(newProps.location.search), this.setSettings);
    }
  }

  get isSelection() {
    return window.getSelection().type === 'Range';
  }

  showSettingsModal = () => {
    // const { store } = this.props;
    this.setState({ tableSettingsOpen: true });
    this.store.setSettingsName('');
  };

  setSettings = (settings) => {
    const t = {};

    if (settings.q) {
      t.query = settings.q;
    }

    // if (settings.f) {
    //   t.filters = { ...settings.f };
    // }

    // if (settings.s) {
    //   t.sorters = { ...settings.s };
    // }

    // if (settings.p) {
    //   t.page = settings.p;
    // }

    // if (settings.ps) {
    //   t.pageSize = settings.ps;
    // }

    this.setState({ ...t });
  }

  update = (query) => {
    if (!this.props.useUrlSettings) {
      return;
    }

    const { location, history } = this.props;

    const params = new URLSearchParams(location.search);
    let t = params.get(`t`);
    
    try { t = JSON.parse(t); }
    catch (e) { t = []; }

    const nT = {};

    for (const dataset in t) {
      if (dataset !== this.store.dataSet) {
        nT[dataset] = { ...t[dataset] };
      }
    }

    const settings = {};

    if (query?.length) {
      settings.q = query;
    }

    // if (Object.keys(filters).length) {
    //   settings.f = { ...filters };
    // }

    // if (Object.keys(sorters).length) {
    //   settings.s = { ...sorters };
    // }

    // if (page > 1) {
    //   settings.p = page;
    // }

    // if (pageSize !== this.store.defaultPageSize) {
    //   settings.ps = pageSize;
    // }

    if (Object.keys(settings).length) {
      nT[this.store.dataSet] = { ...settings };
    }

    params.delete(`t`);
    if (Object.keys(nT).length) {
      params.set(`t`, JSON.stringify(nT));
    }

    const search = params.toString();
    history.push(`${location.pathname}${search?.length ? `?${search}` : ``}`);
  };

  toggleView = () => {
    const { store, state } = this;
    const { view, pagination } = state;
    this.setState({
      view: view === 'table' ? 'calendar' : 'table',
      pagination:
        view === 'table'
          ? {
            current: store.pageCurrent,
            size: store.pageSize,
          }
          : {},
    });

    runInAction(() => {
      if (view === 'table') {
        store.setPageSize(1, 2000);
      } else if (pagination) {
        store.setPageSize(pagination.current, pagination.size);
      }
    });
  };

  onExpand = (expanded, record) => {
    this.expanded = true;

    if (expanded) {
      this.expandedRowKeys.push(record._key);
    } else {
      this.expandedRowKeys = this.expandedRowKeys.filter((item) => item !== record._key);
    }
    this.expandedRows[record._key] = expanded;
    clearTimeout(this.expansionTimer);
    this.expansionTimer = setTimeout(() => {
      this.forceUpdate();
    }, 30);
  };

  openRow = (record) => {
    if (this.props.expandAll && !(record._key in this.expandedRows)) {
      this.onExpand(true, record);
    }
  };

  // eslint-disable-next-line camelcase
  USAFE_componentWillMount() {
    const { store, state } = this;
    const { view } = state;
    if (store) {
      switch (view) {
        case 'calendar':
          store.setPageSize(1, 2000);
          break;
        case 'table':
        default:
          store.setPageSize(1, 10);
      }
    }
  }

  setPageSize = (current, size) => {
    // const { query, sorters, filters } = this.state;

    // this.setState({ page: current, pageSize: size });
    this.store.setPageSize(current, size);
    // this.update(query, filters, sorters, current, size);
  }

  setPage = (current) => {
    // const { query, sorters, filters, pageSize } = this.state;

    // this.setState({ page: current });
    this.store.paginate(current);
    // this.update(query, filters, sorters, current, pageSize);
  }

  setQuery = (str) => {
    const { sorters, filters, pageSize } = this.state;

    this.setState({ query: str, page: 1 });
    this.store.pageCurrent = 1;
    this.store.search(str);
    this.update(str, filters, sorters, 1, pageSize);
  }

  setSorter = (key, direction) => {
    // const { query, sorters, filters, page, pageSize } = this.state;

    // const defaultSorters = [];
    // if (this.store.columns) {
    //   this.store.columns.forEach((col) => {
    //     if (col.defaultSortOrder) {
    //       defaultSorters[col.key] = col.defaultSortOrder;
    //     }
    //   });
    // }

    // const s = {};
    // for (const k in sorters) {
    //   if (k !== key) {
    //     s[k] = sorters[k];
    //   }
    // }

    // if ((defaultSorters[key] ?? null) !== direction) {
    //   s[key] = direction;
    // }

    // this.setState({ sorters: { ...s } });
    this.store.sortColumn(key, direction || null);
    // this.update(query, filters, s, page, pageSize);
  };

  setFilters = (f) => {
    // const { query, sorters, pageSize } = this.state;

    // this.setState({ filters: { ...f }, page: 1 });
    this.store.pageCurrent = 1;
    this.store.filter({ ...f }, this.store.sorter);
    // this.update(query, { ...f }, sorters, 1, pageSize);
  };

  get expandedRowKeysBeta() {
    return Object.keys(this.expandedRows).filter((k) => this.expandedRows[k]);
  }

  render() {
    const { store, state } = this;
    const { view, modal, rowContextPopup, tableSettingsOpen } = state;
    const {
      actions,
      actionButtons,
      canAdd,
      groupBy,
      rowClassName,
      printable,
      fixedFormValues,
      searchDisabled,
      hideSearch,
      disableColumnResize,
      additionalFormProps,
      ...props
    } = this.props;
    let { onAdd, canEdit, onRowClick, onSave, onContextMenu, rowContextMenu } = this.props;
    let altOnAdd;

    if (this.ModalForm && modal) {
      if (canAdd !== false && !this.onAddSet) {
        if (!onAdd) {
          onAdd = () => {
            modal.open('new');
          };
        } else {
          altOnAdd = () => onAdd(modal);
        }
      } else if (this.onAddSet) {
        onAdd = this.onAddSet;
      }

      if (!onRowClick && !this.onRowClickSet) {
        onRowClick = (row) => {
          modal.open(row.id);
        };
        this.onRowClickSet = onRowClick;
      } else if (this.onRowClickSet) {
        onRowClick = this.onRowClickSet;
      }
    }

    const { searchQuery, childrenColumnName, activeColumns: columns } = store;
    const numPages = Math.ceil(store.total / store.pageSize);
    const rowKey = store.data && store.data.length && store.data[0].key ? 'key' : props.rowKey || 'id';
    let viewButtonTitle = props.calendarTitle || 'Rādīt kalendāru';
    if (view === 'calendar') {
      viewButtonTitle = 'Rādīt sarakstu';
    }
    if (view && props.calendarProps) {
      runInAction(() => {
        if (view === 'calendar' && store.pageSize < 2000) {
          store.setPageSize(1, 2000);
        } else if (view === 'table' && store.pageSize > 100) {
          store.setPageSize(1, props.defaultPageSize || 10);
        }
      });
    }

    let { data } = store;
    if (groupBy) {
      const collons = Array.isArray(groupBy) ? groupBy : [groupBy];
      const _data = [];
      for (const i in data) {
        const row = data[i];

        let id = null;
        for (let j = 0; j < _data.length; j++) {
          if (_data[j].calculationTariffId === row.calculationTariffId) {
            id = j;
            break;
          }
        }

        if (id === null) {
          _data.push([]);
          id = _data.length - 1;
        }

        for (const j in collons) {
          const col = collons[j];
          _data[id][col] = row[col];
        }

        if (row[childrenColumnName] && Array.isArray(row[childrenColumnName]) && row[childrenColumnName].length) {
          if (_data[id][childrenColumnName] === undefined) {
            _data[id][childrenColumnName] = [];
          }
          _data[id].key = `${row.calculationTariffId}.${row.id}`;
          _data[id][childrenColumnName].push(row);
        }
      }
      data = toJS(_data).slice();
    }

    if (!store.isLocal && store.loading) {
      this.expanded = false;
      this.expandedRowKeys = [];
    }

    if (!this.expanded && !store.loading) {
      const stack = [];
      let level = { i: 0, obj: store.data };

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { i, obj } = level;

        if (obj.length <= i) {
          if (stack.length) {
            level = stack.pop();
            continue;
          } else {
            break;
          }
        }

        if (obj[i][childrenColumnName] && obj[i].expand && obj[i]._key) {
          this.expandedRowKeys.push(obj[i]._key);
          stack.push({ i: i + 1, obj });

          level = { i: 0, obj: obj[i][childrenColumnName] };
        } else {
          ++level.i;
        }
      }
    }

    let additionalSettingsOptions = [];
    if (props.additionalSettingsOptions) {
      additionalSettingsOptions = [
        {
          title: 'Tabulas iestatījumi',
          onClick: this.showSettingsModal,
        },
        ...props.additionalSettingsOptions,
      ];
    }

    const Wrapper = props.noCard ? WrapperFragment : Card;
    const ModalFormComponent = this.ModalForm;
    return (
      <div
        style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}
        className={!store.isLocal ? 'cabinet_table_group' : 'cabinet_table_group local-table'}
      >
        <Wrapper
          style={{
            width: '100%',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          size="small"
          title={
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={{ span: 24 }} className="table_header_title">
                <span className="table_header_title_item">{props.title}</span>
                <Tag>{store.total}</Tag>
              </Col>
            </Row>
          }
        >
          <Col
            className={!onRowClick ? 'no-row-table' : ''}
            style={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {(!props.hideSearchRow && (
              <div className="ant-table-title" style={{ flex: '0 0 auto' }}>
                <Row type="flex" justify="space-between">
                  <Row type="flex" justify="space-between">
                    {!!actionButtons && actionButtons}
                    {!!printable && (
                      <Button
                        type="default"
                        onClick={() => {
                          let sort = store.columnSorters.map((s) => `${s.key}:${s.direction}`).join(`,`) || ``;
                          if (sort.length) {
                            sort = `&sort=${encodeURI(sort)}`;
                          }
                          let q = ``;
                          if (store.searchQuery) {
                            q = `&q=${encodeURI(store.searchQuery)}`;
                          }

                          let f = ``;
                          if (Object.keys(store.filters).length) {
                            f = `&f=${JSON.stringify(store.filters)}`;
                          }

                          let etc = ``;
                          for (const key in store.additionalParams) {
                            etc += `&${key}=${store.additionalParams[key]}`;
                          }

                          const url = `${api.base}${store.dataSet}/pdf?size=1000${q}${f}${sort}${etc}`;

                          Cookies.set(`Authorization`, `${api.headers.Authorization}`);

                          window.open(url, `_blank`);
                        }}
                      >
                        Drukāt
                      </Button>
                    )}
                    {(altOnAdd || onAdd) && (
                      <Button type="primary" size="small" onClick={altOnAdd || onAdd || (() => true)}>
                        {props.addButtonIcon || <FontAwesomeIcon icon="plus" className="btn-icon" />}
                        <span>{props.addButtonLabel || 'Pievienot'}</span>
                      </Button>
                    )}
                    {!!actions && <Col className="table_header_top_buttons">{actions}</Col>}
                  </Row>
                  <Col className="search-col">
                    <span>
                      {!!props.calendar && (
                        <Button type="secondary" onClick={this.toggleView}>
                          <FontAwesomeIcon icon={view === 'table' ? 'calendar' : 'list'} />
                          {viewButtonTitle}
                        </Button>
                      )}
                    </span>
                    { (!!hideSearch ? <></> : (searchDisabled && (
                      <span>Meklēšana pēc frāzes: {(searchQuery && <Tag>{searchQuery}</Tag>) || ' - '}</span>
                    )) || (
                        <Search
                          disabled={searchDisabled}
                          placeholder="Meklēt"
                          onChange={(e) => store.setSearchQuery(e.target.value)}
                          value={searchQuery}
                          size="small"
                          onSearch={this.setQuery}
                          onFocus={(e) => {
                            if (e.target === document.activeElement) {
                              window.cancelSubmittion = true;
                            }
                          }}
                          onBlur={(e) => {
                            if (window.cancelSubmittion && e.target !== document.activeElement) {
                              window.cancelSubmittion = false;
                            }
                          }}
                          onKeyUp={(e) => {
                            switch (e.which) {
                              case 27:
                                store.search();
                                e.target.blur();
                                break;
                              // case 13:
                              //   console.log("ENTER PRESSED!");
                              //   break;
                              default:
                                // store.setSearchQuery(e.target.value);
                                break;
                            }
                          }}
                        />
                      ))}
                    {props.additionalSettingsButtons}
                    {((store && !store.isLocal) || props.showReload) && (
                      <Tooltip placement="topRight" arrowPointAtCenter title="Atkārtoti ielādēt datus">
                        <Button
                          size="small"
                          className="icon-only"
                          onClick={() => {
                            if (props.overloadRefresh) {
                              props.overloadRefresh();
                              return;
                            }

                            if (props.showReload) {
                              window.location.reload();
                            } else {
                              store.refresh();
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="sync" />
                        </Button>
                      </Tooltip>
                    )}
                    {(((store && !store.isLocal) || props.showReload) && props.showExcelExport != false) && (
                      <>
                        <Button
                          title="Eksportēt uz Excel"
                          size="small"
                          className="icon-only"
                          onClick={() => {
                            props.customExcel ? window.open(props.customExcel) :  store.getExcel(props.asyncExcel);
                          }}
                        >
                          <FontAwesomeIcon icon="table" />
                        </Button>
                        <Modal
                          centered
                          visible={store.loadingExcel}
                          closable={false}
                          footer={false}
                        >
                          <Spinner /> Ģenerējam atskaiti, lūdzu uzgaidiet
                        </Modal>
                      </>
                    )}
                    {!additionalSettingsOptions.length ? (
                      <Tooltip placement="topRight" arrowPointAtCenter title="Tabulas iestatījumi">
                        <Button className="icon-only" size="small" onClick={this.showSettingsModal}>
                          <FontAwesomeIcon icon="cog" />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Dropdown
                        placement="bottomRight"
                        overlay={
                          <Menu>
                            {additionalSettingsOptions.map((i, index) => (
                              <Menu.Item key={index}>
                                <span aria-hidden onClick={i.onClick}>
                                  {i.title}
                                </span>
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                      >
                        <Button className="icon-only" size="small">
                          <FontAwesomeIcon icon="cog" />
                        </Button>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
              </div>
            )) || <div className="ant-table-title" style={{ flex: '0 0 auto' }} />}
            {(onAdd || onRowClick) && modal.visible && ModalFormComponent && (
              <ModalFormComponent
                id={modal.resourceId}
                closeModal={modal.close}
                visible={modal.visible}
                modalProps={props.modalProps}
                fixedFormValues={fixedFormValues}
                additionalFormProps={additionalFormProps}
                afterSubmitted={onSave}
              />
            )}
            {view === 'table' ? (
              <Table
                style={{ overflow: 'auto', flex: '1 1 auto' }}
                dataSource={data}
                onChange={(pagination, filters, sorter) => {
                  store.filter(filters, sorter);
                }}
                loading={!store.isLocal && store.loading}
                columns={columns.map((col) => ({
                    ...col,
                    title: <span key={col.key}>{col.title}</span>,
                    filteredValue: store.filters[col.key],
                  })
                )}
                components={{ header: { wrapper: tableHeaderWrapper(store, this.setSorter, this.setFilters, { disableColumnResize }) } }}
                size="small"
                pagination={
                  store.isLocal &&
                  !props.hidePagination && {
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    size: 'large',
                    showTotal: (total) => (
                      <span className="pagination-page-counter">
                        {`${total} ieraksti ${numPages} ${numPages !== 11 && numPages % 10 === 1 ? 'lapā' : 'lapās'}`}
                      </span>
                    ),
                  }
                }
                rowClassName={(record, key, level) => {
                  return `pointer ${record.className || ''}
                    ${(rowClassName && rowClassName(record, key, level)) || ''}`;
                }}
                rowSelection={props.rowSelection}
                rowKey={(record, key) => {
                  if (record._key) {
                    this.openRow(record);
                    return record._key;
                  }
                  record._key = record.key ? `${key}.${record.key}` : `${record.id}.${key}`;
                  this.openRow(record);
                  return record._key;
                }}
                onRow={(record) => ({
                  onClick: (e) => {
                    if (onRowClick && !this.isSelection) {
                      onRowClick(record, e);
                    }
                  },
                  onContextMenu: (event) => {
                    if (onContextMenu) {
                      return onContextMenu(event);
                    }
                    if (rowContextMenu && !this.isSelection) {
                      event.preventDefault();
                      if (!rowContextPopup.visible) {
                        const that = this;
                        document.addEventListener('click', function onClickOutside() {
                          that.setState({ rowContextPopup: { visible: false } });
                          document.removeEventListener('click', onClickOutside);
                        });
                      }

                      this.setState({
                        rowContextPopup: {
                          record,
                          visible: true,
                          x: event.clientX,
                          y: event.clientY,
                        },
                      });
                    }
                  },
                  onMouseEnter: (event) => {
                      if (record.invalid) {
                          event.currentTarget.title = "Atkritumu veids OIS netika atpazīts! Nepieciešams izvēlēties atbilstošu veidu no saraksta.";
                      }
                  },
                })}
                childrenColumnName={childrenColumnName}
                expandedRowKeys={this.expandedRowKeysBeta}
                onExpand={this.onExpand.bind(this)}
                {...props.tableProps}
              />
            ) : (
              <DataCalendar
                {...props.calendarProps}
                hasOnEvent={!!onRowClick}
                onEvent={(record) => (onRowClick && !this.isSelection ? onRowClick(record) : false)}
                onFilter={(filters) => store.filter(filters, toJS(store.sorter))}
                loading={!store.isLocal && store.loading}
                rowKey={rowKey}
                columns={columns}
                filters={toJS(store.filters)}
                dataSource={toJS(store.data)}
              />
            )}
            <TableSettings
              store={store}
              onOk={this.onOk}
              visible={tableSettingsOpen}
              onCancel={() => this.setState({ tableSettingsOpen: false })}
              onReset={() => {
                this.setState({ filters: {}, sorters: {}, page: 1 });
                this.update(state.query, {}, {}, 1, state.pageSize);
              }}
            />
            {!props.hidePagination && view === 'table' && (
              <Row className="table-pagination" type="flex" justify="space-around" align="middle">
                <Col xs={24} sm={6} md={7} lg={5}>
                  {!store.isLocal && store.total > 0 && (
                    <span className="pagination-page-counter">
                      {`${store.total} ieraksti ${numPages} ${numPages !== 11 && numPages % 10 === 1 ? 'lapā' : 'lapās'
                        }`}
                    </span>
                  )}
                </Col>
                <Col xs={24} sm={24} md={17} lg={19}>
                  {store && !store.isLocal && (
                    <Pagination
                      showSizeChanger
                      current={store.pageCurrent}
                      pageSize={store.pageSize}
                      onShowSizeChange={this.setPageSize}
                      onChange={this.setPage}
                      defaultCurrent={1}
                      total={store.total}
                      showLessItems
                      style={{ display: 'flex', flex: '0 0 auto' }}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Wrapper>
        <PopupXY rowContextMenu={rowContextMenu} {...rowContextPopup} />
      </div>
    );
  }
}

export default withRouter(DataTable);
