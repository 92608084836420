import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol2, gridRow, FormItem, gridCol } from '@ois-lib';

@inject('invoiceStore', 'appStore', 'authorize', 'can')
@observer
class EditInvoice extends EditFormBase {
  title = 'Rēķina kartiņa';
  storeName = 'invoiceStore';
  baseUrl = '/finance/invoices';

  async componentDidMount() {
    this.load(this.props.invoiceStore);
  }

  canSave() {
    return this.props.can('edit invoices');
  }

  canDelete() {
    return this.props.can('delete invoices');
  }

  renderForm() {
    const { authorize, match } = this.props;
    authorize([match.params.id !== 'new' && 'view invoices', 'edit invoices']);

    return (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol2}>
            <Card title="Rēķins" bordered className="mt15">
              <FormItem name="nr" />
              <FormItem name="date" />
              <FormItem name="recipientId" />
              <FormItem name="calculationPeriodId" />
              <FormItem name="currencyId" />
              <FormItem name="amount" />
              <FormItem name="vat" />
              <FormItem name="paymentReceived" />
              {/* <FormItem name="paymentReceivedSum" /> */}
              {/* <FormItem name="sentToHorizon" /> */}
            </Card>
          </Col>
        </Row>
        <Row {...gridRow}>
          <Col {...gridCol} className="mt15">
            <FormItem
              name="calculations"
              disabled
              onRowClick={(row) => {
                this.props.appStore.history.push(`/finance/calculations/${row.id || ''}`);
                window.scrollTo(0, 0);
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditInvoice' })(EditInvoice));
