/* eslint-disable camelcase */
import {action, computed, observable, runInAction, toJS} from 'mobx';
import api from '../services/api';
// import ErrorForbidden from '@ois-lib/exceptions/ErrorForbidden';

class DataStoreFactory {
  normalizeAllColumns = false;
  @observable _data = [];
  @observable _dataFull = [];
  @observable item = {};
  @observable columns = [];
  definedColumns = [];
  @observable loading = true;
  @observable loadingFull = true;
  loadingFullLock = false;
  @observable loadingOne = true;

  excelTimeout = null;
  @observable excelId = null;
  @observable loadingExcel = false;
  @observable loadingOneRequested = false;
  @observable savingOne = false;
  @observable isLocal = false;
  @observable isAsyncLocal = false;
  @observable columnState = {};
  @observable columnWidths = {};
  @observable columnOrders = {};
  @observable searchQuery = '';

  @observable savedSettings = [];
  @observable settingsLoading = false;
  @observable settingsName = '';

  @observable defaultPageSize = 10;
  @observable pageCurrent = 1;
  @observable pageTotal = 1;
  @observable totalRecords = 0;
  @observable pageSize = 10;

  @observable loadParams = JSON.stringify(null);
  @observable filters = {};
  @observable additionalParams = {};
  @observable columnSorters = [];
  childrenColumnName = 'children';
  systemFilters = {};

  @observable sorter = {};

  forceOverrideValues = {};
  additionalData = {};
  chachedRecordsById = {};

  fieldSet = [];
  dataSet = 'contracts';
  optionKeyName = 'id';
  optionValueName = 'name';
  // isOptionStore = false;

  static columnDataset = null;

  constructor(columnDataset, dataSet, fieldSpec, appStore, _instanceName) {
    this._instanceName = _instanceName;
    if (_instanceName) {
      this.dataSetKey = columnDataset + _instanceName;
    }

    this._storeId = (Math.random() * 10000) | 0;
    // TODO: Investigate (Edgars)
    if ((dataSet && dataSet.endsWith('_options')) || _instanceName === '_options') {
      this.isOptionStore = true;
      dataSet = dataSet.replace('_options', '');
    } else if (_instanceName && _instanceName.startsWith('_options')) {
      this.isOptionStore = true;
      dataSet = dataSet.replace(_instanceName, '');
    }

    this.dataSet = dataSet;
    this.columnDataset = columnDataset;
    this.colByName = fieldSpec.colByKey[columnDataset];
    this.columns = fieldSpec.colByStore[columnDataset];
    this.fieldSpec = fieldSpec;
    this.appStore = appStore;

    if (this.columns) {
      this.definedColumns = toJS(this.columns);
      this.columns = this.columns.filter((col) => {
        const { noColumn, noTable } = col;
        return !noColumn && !noTable;
      });
    } else {
      console.error(`Columns are not defined for store "${columnDataset}"`);
      this.columns = [];
    }
    this.defaultColumnOrder = this.columns.map((col, index) => ({ key: col.key, order: index })); // Store the initial order
    for (const col in this.colByName) {
      if (this.colByName[col].noColumn) {
        delete this.colByName[col];
      }
    }
    this.loadColState();
    // Initial population of columnOrders
    if (!this.columnState || Object.keys(this.columnState).length === 0) {
        this.columnOrders = {};
        if (this.columns && !this.isOptionStore) {
            this.columns.forEach((column, index) => {
                // Use the column's 'columnOrder' property if available; otherwise, use its index
                const order = column.hasOwnProperty('columnOrder') ? column.columnOrder : index;
                this.setColumnOrder(column.key, order);
            });
        }
        this.writeColState()
    }
    this.sortColumnsByOrder();
    if (!Object.keys(this.columnState).length && this.columns && !this.isOptionStore) {
      this.resetColumnState();
    }
    if (this._instanceName !== '_options') this.setupLiveUpdate();
  }

  setupLiveUpdate() {
    if (this.dataSet == 'shipVisits') {
      // console.log(this.isOptionStore,this._instanceName, 'setupUpdate',this._storeId, this.systemFilters);
      // console.trace();
      api.socket.subscribeVisits((item) => {
        if (!this._hasTable) return;
        if (item && item.data && this.systemFilters.status && item.data.prevStatus == this.systemFilters.status) {
        } else if (item && item.data && this.systemFilters.status && item.data.newStatus == this.systemFilters.status) {
          this.refresh();
        } else {
          // console.log(this._storeId,'item nr', item,this.systemFilters, this._data.length);
        }
      });

      api.socket.listenUser('.messages', (item) => {
        try {
          if (item.type && item.type.code == 'visit updated') {
            if (item.body) {
              const body = JSON.parse(item.body);
              if (body.id) {
                const visit = this._data.find((row) => row.id == body.id);
                if (visit) {
                  for (const key in body.changes) {
                    visit[key] = body.changes[key];
                  }
                } else {
                  // console.log('NO VISIT', this.systemFilters );
                }
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      });
    }
  }

  sortColumnsByOrder() {
    // Sort this.columns directly based on this.columnOrders
    const sorted = toJS(this.columns).sort((a, b) => {
        // Directly use the order values from this.columnOrders
        const orderA = this.columnOrders[a.key];
        const orderB = this.columnOrders[b.key];
        return orderA - orderB;
    });

    // Replace the observable array with the sorted array
    this.columns.replace(sorted);
  }
  @action.bound resetColumnState() {
    this.pageCurrent = 1;
    this.columnSorters = [];
    if (this.columns) {
      Object.keys(this.columnState).forEach((colKey) => {
        if (!this.columns.some((col) => col.dataIndex === colKey)) {
          delete this.columnState[colKey];
        }
      });
      this.columns.forEach((col, index) => {
        const defaultOrder = this.defaultColumnOrder.find(d => d.key === col.key)?.order ?? col.key;
        this.setColState(col.key, !col.hide);
        this.setColumnOrder(col.key, defaultOrder);
        if (col.defaultSortOrder) {
          this.columnSorters.push({
            key: col.key,
            direction: col.defaultSortOrder,
          });
        }
      });
      this.sortColumnsByOrder();
    }
    this.filters = this.systemFilters ? this.systemFilters : {};
    this.columnWidths = {};
    // Pie pirmās inicializācijas, "this.isLocal" nepaspēj ielādēties. ;/
    if (this.onResetColumns) {
      this.onResetColumns();
    }

    this.writeColState();
    if (!this.isLocal) {
      this.loadOnly(1);
    }
  }
  ccounter = 1;
  settingsCache = {};

  @action.bound async loadSettings(forceReloadSettings = false) {
    const suffix = this.systemFilters && this.systemFilters.status ? this.systemFilters.status : '';
    let res;
    if (!forceReloadSettings && this.settingsCache[`ui_settings?object=table_${this.dataSet}${suffix}`]) {
      res = this.settingsCache[`ui_settings?object=table_${this.dataSet}${suffix}`];
    } else {
      res = await api[`ui_settings?object=table_${this.dataSet}${suffix}`]();
      if (res) {
        this.settingsCache[`ui_settings?object=table_${this.dataSet}${suffix}`] = res;
      }
    }
    this.savedSettings =
      res &&
      res.map &&
      res.map((setting) => ({
        ...setting,
        settings: JSON.parse(setting.settings),
      }));
  }

  @action.bound async createSettings(kind = 'public') {
    const suffix = this.systemFilters && this.systemFilters.status ? this.systemFilters.status : '';
    const setting = {
      name: toJS(this.settingsName, { recurseEverything: true }),
      object: `table_${this.dataSet}${suffix}`,
      kind,
      settings: {
        columnWidths: toJS(this.columnWidths, { recurseEverything: true }),
        columnOrders: toJS(this.columnOrders, { recurseEverything: true }),
        columns: toJS(this.columnState, { recurseEverything: true }),
        sorting: toJS(this.columnSorters, { recurseEverything: true }),
        filters: toJS(this.filters, { recurseEverything: true }),
      },
    };
    this.settingsName = '';
    const saveResult = await api.ui_settings(setting);
    if (saveResult) {
      this.loadSettings(true);
    }
    return saveResult;
  }

  @action.bound async activateSettings(settingsRow) {
    if (settingsRow.settings) {
      if (settingsRow.settings.columns) {
        this.columnState = toJS(settingsRow.settings.columns, { recurseEverything: true });
      }
      if (settingsRow.settings.sorting) {
        this.columnSorters = toJS(settingsRow.settings.sorting, { recurseEverything: true });
      }
      if (settingsRow.settings.filters) {
        this.filters = toJS(settingsRow.settings.filters, { recurseEverything: true });
      }
      this.writeColState();
      this.loadOnly(1);
      return true;
    }
    return false;
  }

  @action.bound async deleteSettings(settings) {
    const res = await api[`ui_settings/${settings.id}`](undefined, 'DELETE');
    this.loadSettings();
    return res;
  }

  @action.bound setSettingsName(name) {
    this.settingsName = name;
  }

  @action.bound async refreshOne(options = {}) {
    if (this.item && this.item.id && this.item.id !== 'new') {
      this.loadOne(this.item.id, options);
    }
  }

  @action.bound async reloadOne(options = {}) {
    if (this.item && this.item.id && this.item.id !== 'new') {
      return this.loadOne(this.item.id, options);
    }
    return null;
  }

  @action.bound async loadOne(id, options = {}) {
    const { setFields } = options;
    if (!setFields) {
      this.item = {};
    }
    if (!id || id === 'new') {
      if (this.loadingOne) {
        this.loadingOne = false;
      }
      return this.item;
    }
    if (!this.loadingOne) {
      this.loadingOne = true;
    }
    this.loadingOneRequested = true;
    const res = await api[this.dataSet + (id === 'edit' ? '' : `/${id}`) + this.getAdditionalData]();
    return runInAction(() => {
      if (this.loadingOne) {
        this.loadingOne = false;
      }
      if (res && !res.error) {
        if (res && res.data) {
          if (setFields) {
            for (let key of setFields) {
              this.item[key] = res.data[key];
            }
          } else {
            this.item = res.data;
          }
          return setFields ? this.item : res.data;
        }
        throw new Error('Error loading item');
      }
      // if (res.responseCode === 404) {
      //   throw new ErrorForbidden(res.error);
      // }
      return false;
    });
  }

  get getAdditionalData() {
    const data = this.additionalData
      ? Object.entries(this.additionalData)
          .map(([dataKey, dataValue]) => `${encodeURIComponent(dataKey)}=${encodeURIComponent(dataValue)}`)
          .join('&')
      : null;
    return data ? `?${data}` : '';
  }

  @action.bound async pullAsync(gateway = null, params = undefined, method = 'GET') {
    const isMethodGet = method === 'GET';
    this.additionalData = params;
    try {
      if (!this.loading) {
        this.loading = true;
      }
      const gateWayPoint = `${this.dataSet}${gateway ? `/${gateway}` : ''}${isMethodGet ? this.getAdditionalData : ''}`;
      const res = await api[gateWayPoint](!isMethodGet ? { ...params } : null, method);

      if (res) {
        if (this.loading) {
          this.loading = false;
        }
        return res;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  @action.bound async callGet(method, data, id = this.item.id, silent = false) {
    if (!silent) {
      if (!this.loadingOne) {
        this.loadingOne = true;
      }
      this.loadingOneRequested = true;
    }

    let params = this.getAdditionalData;
    const _data = data ? Object.entries(data)
        .map(([dataKey, dataValue]) => `${encodeURIComponent(dataKey)}=${encodeURIComponent(dataValue)}`)
        .join('&')
    : null;

    if (params.length) {
      params += _data;
    } else {
      params = _data ? `?${_data}` : ``;
    }

    const res = await api[`${this.dataSet}/${id}/${method}${params}`]();
    if (res) {
      if (this.loadingOne && !silent) {
        this.loadingOne = false;
      }
    }
    return res;
  }

  @action.bound async call(method, data, id = this.item.id) {
    if (!this.loadingOne) {
      this.loadingOne = true;
    }
    this.loadingOneRequested = true;
    const res = await api[`${this.dataSet}/${id}/${method}`]({ ...data }, 'PUT');
    // if (res) {
    if (this.loadingOne) {
      this.loadingOne = false;
    }
    // }
    return res;
  }

  @action.bound async saveOne(data, id) {
    try {
      this.savingOne = true;
      let res;
      if (id) {
        // Set null to deleted values
        //        for (let fKey of this.fieldSet) if (!data[fKey]) data[fKey] = null;
        res = await api[`${this.dataSet}/${id}`]({ ...data, id }, 'PUT');
      } else {
        res = await api[this.dataSet](data, 'POST');
      }
      this.savingOne = false;
      this.item = res.data;
      return res;
    } catch (e) {
      this.savingOne = false;
      return false;
    }
  }

  @action.bound async makeApiCall(route, data, method = 'POST') {
    try {
      const res = await api[route](data, method);
      return res;
    } catch (e) {
      return false;
    }
  }

  @action.bound async deleteOne() {
    if (!this.item || !this.item.id) {
      throw new Error('Sistēmas kļūda [nav norādīts ieraksta "id"]');
    }
    try {
      this.savingOne = true;
      const res = await api[`${this.dataSet}/${this.item.id}`](undefined, 'DELETE');
      this.savingOne = false;
      return res;
    } catch (e) {
      this.savingOne = false;
      return false;
    }
  }

  @action.bound async load() {
    this.loadOnly();
  }

  @action.bound loadFull(params = null, noCache = false) {
    const cacheable = ['ships', 'ports', 'companies', 'berths']; // 'shipVisits'
    if (!window.classifier_cache) {
      window.classifier_cache = {};
    }

    // eslint-disable-next-line camelcase
    const { classifier_cache } = window;
    this.loadingFullLock = true;
    setTimeout(async () => {
      this.loadingFull = true;
      try {
        const cacheKey = `cache_${this.dataSet}${new Date().getDate()}`;
        if (!noCache && !params && cacheable.includes(this.dataSet) && classifier_cache[cacheKey]) {
          runInAction(() => {
            const data = JSON.parse(classifier_cache[cacheKey]);
            this._dataFull = data
              .map((item) => {
                if (item.id) {
                  this.chachedRecordsById[item.id] = { ...item };
                }
                return item;
              })
              .map((row, i) => ({ ...row, key: i }));
          });
        } else {
          let res = null;
          if (params) {
            const query = Object.keys(params)
              .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
              .join('&');
            res = await api[`${this.dataSet}?${query}`]();
          } else {
            res = await api[this.dataSet]();
          }

          runInAction(() => {
            if (res && res.data) {
              this._dataFull = res.data
                .map((item) => {
                  if (item.id && !this.chachedRecordsById[item.id]) {
                    this.chachedRecordsById[item.id] = { ...item };
                  }
                  return item;
                })
                .map((row, i) => ({ ...row, key: i }));
            }
            if (res && res.data && cacheable.includes(this.dataSet)) {
              classifier_cache[cacheKey] = JSON.stringify(res.data);
            }
          });
        }
      } catch (e) {
        console.error('Failed loading options: ', e);
        throw e;
      } finally {
        runInAction(() => {
          this.loadingFull = false;
        });
        this.loadingFullLock = false;
      }
    }, 1);
  }

  @action.bound refresh() {
    this.loadOnly(this.pageCurrent);
  }

  @action.bound refreshFull(params, noCache = true) {
    this.loadFull(params, noCache);
  }

  @action.bound async loadOnly(pageNr) {
    if (this.isLocal) {
      return null;
    }

    const { field, order } = toJS(this.sorter || {}, { recurseEverything: true });
    const filters = JSON.stringify({ ...this.filters, ...this.systemFilters });
    const params = {};
    if (!this.loading) {
      this.loading = true;
    }
    const searchQuery = this.searchQuery.toLowerCase();

    if (filters !== '{}') {
      if (Object.keys(this.systemFilters).length > 0) {
        this.systemFilters.fMode = 'strict';
      }

      let filterValues = { ...this.filters, ...this.systemFilters };
      for (const col in this.filters) {
        // normalize filters
        if (this.colByName[col]) {
          if (this.colByName[col].type === 'boolean_multiselect') {
            filterValues = {
              ...filterValues,
              ...filterValues[col].reduce((o, v) => ({ ...o, [v]: [true] }), {}),
            };
            delete filterValues[col];
          } else if (this.colByName[col].type === 'date' && Array.isArray(filterValues[col])) {
            const noTz = [];
            for (const val of filterValues[col]) {
              if (val && val.format) {
                noTz.push(val.format(`YYYY-MM-DDTHH:mm:ss.000`) + `Z`);
              } else {
                noTz.push(val);
              }
            }
            filterValues[col] = noTz;
          }
        }

        // end nf
      }
      params.f = JSON.stringify(filterValues);
    }

    if (searchQuery) {
      const qFilters = {};
      for (const col of this.activeColumns) {
        if (col.options && Array.isArray(col.options)){
          const keys = col.options
            .filter((option) => option.value.toLowerCase().includes(searchQuery))
            .map((option) => option.key);
          if (!keys.length) {
            continue;
          }
          if (qFilters[col.key]) {
            if (Array.isArray(qFilters[col.key])) {
              qFilters[col.key].push(...keys);
            }
          } else {
            qFilters[col.key] = keys;
          }
        }
      }
      params.qf = JSON.stringify(qFilters);
      if (params.qf === '{}') {
        delete params.qf;
      }
    }

    const activeColumnSorters =
      this.explicitColumnSorters || toJS(this.columnSorters).filter((col) => !!this.columnState[col.key]);
    if (activeColumnSorters.length) {
      params.sort = activeColumnSorters.map((s) => `${s.key}:${s.direction}`).join(',') || '';
    }
    if (field) {
      params.o = activeColumnSorters.map((s) => `${s.key}:${s.direction}`).join(',') || field;
    }
    if (order && order === 'descend') {
      params.d = 'desc';
    }

    if (searchQuery) {
      params.q = searchQuery;
    }
    if (pageNr) {
      params.page = pageNr;
    }
    params.size = this.pageSize;

    for (const key in this.additionalParams) {
      params[key] = this.additionalParams[key];
    }

    const query = Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
    const res = await api[`${this.dataSet}?${query}`]();
    runInAction(() => {
      if (res) {
        if (res.data) {
          this._data = res.data;
        }
        if (res.last_page) {
          this.pageTotal = res.last_page;
        } else {
          this.pageTotal = 1;
        }

        if (res.current_page) {
          this.pageCurrent = res.current_page;
        } else {
          this.pageCurrent = 1;
        }

        if (res.total) {
          this.totalRecords = res.total;
        } else {
          this.totalRecords = 0;
        }
      }
      if (this.loading) {
        this.loading = false;
      }
    });
    return res;
  }

  @action.bound async getExcel(async = false) {
    if (this.isLocal) {
      return null;
    }

    if (this.loadingExcel) {
      console.log(`Refreshing export status.`, this.excelId);
      clearTimeout(this.excelTimeout);
      const req = await api.call(`status/${this.excelId}`, null, `GET`);
      if (req.responseCode === 200 && req.status !== `READY`) {
        console.log(`Timeouting export refresh for 3 seconds.`, req.status);
        this.excelTimeout = setTimeout(() => this.getExcel(), 3000);
      } else {
        console.log(`Export is ready.`, req.uid);
        window.open(`/api/v1/excel-export/${req.uid}`);
        this.loadingExcel = false;
      }

      return;
    }

    const { field, order } = toJS(this.sorter || {}, { recurseEverything: true });
    const filters = JSON.stringify({ ...this.filters, ...this.systemFilters });
    const params = {};
    if (!this.loading) {
      this.loading = true;
    }
    const searchQuery = this.searchQuery.toLowerCase();

    if (filters !== '{}') {
      if (Object.keys(this.systemFilters).length > 0) {
        this.systemFilters.fMode = 'strict';
      }

      let filterValues = { ...this.filters, ...this.systemFilters };
      for (const col in this.filters) {
        // normalize filters
        if (this.colByName[col]) {
          if (this.colByName[col].type === 'boolean_multiselect') {
            filterValues = {
              ...filterValues,
              ...filterValues[col].reduce((o, v) => ({ ...o, [v]: [true] }), {}),
            };
            delete filterValues[col];
          } else if (this.colByName[col].type === 'date' && Array.isArray(filterValues[col])) {
            const noTz = [];
            for (const val of filterValues[col]) {
              if (val && val.format) {
                noTz.push(val.format(`YYYY-MM-DDTHH:mm:ss.000`) + `Z`);
              } else {
                noTz.push(val);
              }
            }
            filterValues[col] = noTz;
          }
        }
        // end nf
      }
      params.f = JSON.stringify(filterValues);
    }

    if (searchQuery) {
      const qFilters = {};
      for (const col of this.activeColumns) {
        if (col.options) {
          const keys = col.options
            .filter((option) => option.value.toLowerCase().includes(searchQuery))
            .map((option) => option.key);
          if (!keys.length) {
            continue;
          }
          if (qFilters[col.key]) {
            if (Array.isArray(qFilters[col.key])) {
              qFilters[col.key].push(...keys);
            }
          } else {
            qFilters[col.key] = keys;
          }
        }
      }
      params.qf = JSON.stringify(qFilters);
      if (params.qf === '{}') {
        delete params.qf;
      }
    }

    const activeColumnSorters =
      this.explicitColumnSorters || toJS(this.columnSorters).filter((col) => !!this.columnState[col.key]);
    if (activeColumnSorters.length) {
      params.sort = activeColumnSorters.map((s) => `${s.key}:${s.direction}`).join(',') || '';
    }
    if (field) {
      params.o = activeColumnSorters.map((s) => `${s.key}:${s.direction}`).join(',') || field;
    }
    if (order && order === 'descend') {
      params.d = 'desc';
    }

    if (searchQuery) {
      params.q = searchQuery;
    }
    // params.size = this.pageSize;

    for (const key in this.additionalParams) {
      params[key] = this.additionalParams[key];
    }
    params.excel = 1;
    params.columns = Object.entries(this.columnState)
      .filter(([k, v]) => v)
      .map(([k, _]) => k)
      .join(',');

    if (async) {
      const req = await api.call(`${this.dataSet}/excel`, params, `POST`);
      this.loading = false;
      
      if (req.responseCode === 200 && req.uid) {
        if (req.uid) {
          this.excelTimeout = setTimeout(() => this.getExcel(), 3000);
          this.loadingExcel = true;
          this.excelId = req.uid;
        }
      }
    } else {
      const query = Object.keys(params)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join('&');
      let url = `/api/v1/${this.dataSet}?${query}`;
      this.loading = false;
      window.open(url);
    }
    return;
  }

  @action.bound async loadWithOptions(options = null, storeOptions = false) {
    if (this.isLocal) {
      return;
    }

    if (storeOptions && options) {
      this.storedOptions = options;
    } else if (!options && this.storedOptions) {
      options = this.storedOptions;
    }

    const { field, order } = toJS(this.sorter, { recurseEverything: true });
    const params = {};
    if (!this.loading) {
      this.loading = true;
    }
    if (options.filters) {
      let values = options.filters;
      for (const col in this.filters) {
        // normalize filters
        if (this.colByName[col]) {
          if (this.colByName[col].type === 'boolean_multiselect') {
            filterValues = {
              ...filterValues,
              ...filterValues[col].reduce((o, v) => ({ ...o, [v]: [true] }), {}),
            };
            delete filterValues[col];
          } else if (this.colByName[col].type === 'date' && Array.isArray(filterValues[col])) {
            const noTz = [];
            for (const val of filterValues[col]) {
              if (val && val.format) {
                noTz.push(val.format(`YYYY-MM-DDTHH:mm:ss.000`) + `Z`);
              } else {
                noTz.push(val);
              }
            }
            filterValues[col] = noTz;
          }
        }
      }
      params.f = JSON.stringify(values);
    }
    if (field) {
      params.o = field;
    }
    if (order && order === 'descend') {
      params.d = 'desc';
    }
    const query = Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');

    const res = await api[`${this.dataSet}?${query}`]();

    runInAction(() => {
      if (res) {
        if (res.data) {
          this._data = res.data;
        }
        if (res.last_page) {
          this.pageTotal = res.last_page;
        } else {
          this.pageTotal = 1;
        }

        if (res.current_page) {
          this.pageCurrent = res.current_page;
        } else {
          this.pageCurrent = 1;
        }

        if (res.total) {
          this.totalRecords = res.total;
        } else {
          this.totalRecords = 0;
        }
      }
      if (this.loading) {
        this.loading = false;
      }
    });
  }

  @action.bound async loadRecord(id, dataSet = this.dataSet, field = '_loadedRecord') {
    if (!this.loading) {
      this.loading = true;
    }
    const res = await api[`${dataSet}/${id}`]();
    if (res) {
      this[field] = res.data;
    }
    if (this.loading) {
      this.loading = false;
    }
    return res;
  }

  @action.bound async loadRecords(dataSet = this.dataSet, filter, field = '_loadedRecords') {
    if (!this.loading) {
      this.loading = true;
    }
    const res = await api[`loadrecords/${dataSet}${filter}`]();
    if (res) {
      this[field] = res;
    }
    if (this.loading) {
      this.loading = false;
    }
    return res;
  }

  @computed get total() {
    if (this.totalRecords) {
      return this.totalRecords;
    }
    return this._data.length;
  }

  @action.bound paginate(page) {
    this.loadOnly(page);
  }

  @action.bound filter(filters, sorter) {
    this.filters = filters;
    this.sorter = sorter;
    window.localStorage[`filter_state_${this.dataSet}`] = JSON.stringify(this.filters);
    this.loadOnly(1);
  }

  @action.bound sysFilter(filters, sorter) {
    this.systemFilters = filters;
    this.filter(filters, sorter);
  }

  @action.bound setSorter(column, direction) {
    const colSort = this.columnSorters.find((sorter) => sorter.key === column);
    if (colSort) {
      if (!direction) {
        this.columnSorters = this.columnSorters.filter((sorter) => sorter.key !== column);
      } else {
        this.columnSorters = this.columnSorters.map((sorter) => {
          if (sorter.key === column) {
            sorter.direction = direction;
          }
          return sorter;
        });
      }
    } else {
      this.columnSorters.push({
        key: column,
        direction,
      });
    }
    // window.localStorage[`col_sort_state_${this.dataSet}`] = JSON.stringify(this.columnSorters);
    this.writeColState();
  }

  @action.bound sortColumn(column, direction) {
    this.setSorter(column, direction);
    this.loadOnly(1);
  }

  get reducers() {
    if (!this._reducers) {
      const reducers = {};
      for (const col of this.columns) {
        if (col.makeReducer) {
          reducers[col.key] = col.makeReducer(col.key);
        }
      }
      this._reducers = reducers;
    }
    return this._reducers;
  }

  aggregate(row, childrenColumnName = 'children', defaultAggregationSorter = null) {
    if (row[childrenColumnName] && defaultAggregationSorter) {
      row[childrenColumnName] = Array.isArray(row[childrenColumnName])
        ? row[childrenColumnName].sort(defaultAggregationSorter)
        : row[childrenColumnName];
    }
    for (const i in row[childrenColumnName]) {
      if (childrenColumnName) {
        row[childrenColumnName][i] = this.aggregate(
          row[childrenColumnName][i],
          childrenColumnName,
          defaultAggregationSorter
        );
      }
    }
    if (row[childrenColumnName]) {
      for (const col of this.columns) {
        if (this.reducers[col.key]) {
          row[col.key] = row[childrenColumnName].reduce(this.reducers[col.key], null);
        }
      }
    }
    return row;
  }

  @action.bound setPageSize(current, pageSize) {
    // console.trace();
    // console.log('setPageSize', current, pageSize);
    this.pageSize = pageSize;
    this.paginate(current);
  }

  @action.bound async loadIfEmpty(params = null) {
    if (
      !this.loadingFullLock && (
        this.isEmpty ||
        this.loadParams !== JSON.stringify(params)
      )
    ) {
      this.loadParams = JSON.stringify(params);
      return this.loadFull(params);
    }
    return null;
  }

  @action.bound setSearchQuery(query = '') {
    this.searchQuery = query;
  }

  @action.bound search(query = '') {
    this.setSearchQuery(query);
    return this.loadOnly(1);
  }

  normalizeTableRecord = (arow, normalizeOnlyRowColumns = false) => {
    const row = toJS(arow);
    const rowOut = { ...row };

    // if (this.dataSet === 'ships') {
    //   console.log('this.colByName: ', this.colByName, arow, normalizeOnlyRowColumns);
    // }

    for (const key in this.colByName) {
      // if (this.dataSet === 'ships') {
      //   // console.log('key: ', key);
      //   if (key === 'shipTypeId') {
      //     console.log('this.colByName[key]: ', this.colByName[key].loadRowTransform(row));
      //   }
      // }

      if (normalizeOnlyRowColumns && !(key in arow)) {
        continue;
      }
      if (this.colByName[key].keepOriginalValue || (this.keepOriginalValues && this.keepOriginalValues.includes(key))) {
        rowOut[`_orig.${key}`] = row[key];
      }
      if (normalizeOnlyRowColumns || this.columnState[key] || this.normalizeAllColumns) {
        rowOut[key] = this.colByName[key].loadRowTransform(row);
      }
    }
    return rowOut;
  };

  sortData(filtered, sorters) {
    const data = filtered.sort((a, b) => {
      for (const func of sorters) {
        const result = func(a, b);
        if (result !== 0) {
          return result;
        }
      }
      return false;
    });
    return data.map((row) => {
      const childrenColumnName = this.childrenColumnName || 'children';
      if (row[childrenColumnName]) {
        row[childrenColumnName] = this.sortData(row[childrenColumnName], sorters);
      }
      return row;
    });
  }

  @computed get data() {
    if (!this.isLocal) {
      return this._data && Array.isArray(this._data) ? this._data?.map((i) => this.normalizeTableRecord(i)) : [];
    }

    // local
    const searchQuery = this.searchQuery.toLowerCase() || null;
    let filtered = toJS(
      searchQuery ? this._data.filter((row) => JSON.stringify(row).toLowerCase().includes(searchQuery)) : this._data,
      { recurseEverything: true }
    );

    const sorters = [];
    // const { key, direction, sortFunc = null } of this.columnSorters;
    const activeColumnSorters = this.columnSorters.filter((col) => !!this.columnState[col.key]);
    for (const { key, direction } of activeColumnSorters) {
      const dir = direction === 'desc' ? -1 : 1;
      sorters.push((a, b) => {
        if (typeof a[key] === 'string' || typeof b[key] === 'string') {
          const A = a[key] || '';
          const B = b[key] || '';
          return A.localeCompare(B, 'lv', { ignorePunctuation: true }) * dir;
        }
        if (typeof a[key] === 'boolean') {
          return a[key] === b[key] ? 0 : (a[key] * 1 > b[key] * 1 ? 1 : -1) * dir;
        }
        return a[key] === b[key] ? 0 : (a[key] > b[key] ? 1 : -1) * dir;
      });
    }

    if (sorters && sorters.length) {
      filtered = this.sortData(filtered, sorters);
    }

    if (this.filters && Object.keys(this.filters).length) {
      for (const key in this.filters) {
        const value = this.filters[key];
        if (value && Array.isArray(value)) {
          filtered = filtered.filter((row) => row[key] === value[0]);
        }
      }
    }
    return filtered.map((i) => this.normalizeTableRecord(i));
  }

  get getSelectedOptionRow() {
    if (this.optionFilterCallback) {
      return this._dataFull.find(this.optionFilterCallback);
    }
    return null;
  }

  // @computed
  async pushToOptions(id = null, params = {}) {
    if (id && !this.chachedRecordsById[id]) {
      const intKey = parseInt(id, 10);
      if (!this._dataFull.find((option) => parseInt(option.id, 10) === intKey)) {
        if (!this.chachedRecordsById[id]) {
          const res = await this.pullAsync(id, { ...params });
          if (res && res.data) {
            this.chachedRecordsById[id] = { ...res.data };
          }
        }
        if (this.chachedRecordsById[id]) {
          this._dataFull.push({ ...this.chachedRecordsById[id] });
          // if (intKey === 271) {
          //   //
          //   console.log(this.chachedRecordsById, toJS(this._dataFull));
          // }
        }
      }
    }
  }

  @computed get isEmpty() {
    return !(this._dataFull && Array.isArray(this._dataFull) && this._dataFull.length);
  }

  @computed get getAsOptions() {
    return toJS(this._dataFull)
      .filter((i) => i && !i.blocked)
      .map(
        ({
          [this.optionKeyName]: key,
          [this.optionValueName]: value,
          id,
          guard_name,
          created_at,
          updated_at,
          ...item
        }) => ({
          ...item,
          key,
          value, // : value ? value : item.description,
        })
      );
  }

  @computed get activeColumns() {
    return this.columns.filter((col) => this.columnState[col.key] === undefined ? false : !!this.columnState[col.key]);
  }

  @computed get activeColumnsForTable() {
      // Combine controlColumns and activeColumns
      const combinedColumns = [
          ...(this.controlColumns || []),
          ...this.activeColumns.map((col) => ({
              ...col,
              filteredValue: this.filters[col.key],
          })),
      ];

      // Sort the combined columns based on columnOrders
      return combinedColumns.sort((a, b) => {
          const orderA = this.columnOrders[a.key] || 0;
          const orderB = this.columnOrders[b.key] || 0;
          return orderA - orderB;
      });
  }

  @action.bound setColumnWidth(key, width) {
    this.columnWidths[key] = width;
    this.writeColState();
  }

  @action.bound setColumnOrder(key, order) {
    this.columnOrders[key] = order;
    this.sortColumnsByOrder();
    this.writeColState();
  }


  @action.bound setColState(col, value) {
    if (Object.values(this.columnState).filter((v) => v).length === 1 && value === false) {
      return;
    }
    this.columnState[col] = !!value;
    const isActiveSorter = this.columnSorters.find((c) => c.key === col);
    if (isActiveSorter) {
      // Possibly can change this to store parameter and update table state on modal close if value is set to true
      clearTimeout(this.columnSetterTimer);
      this.columnSetterTimer = setTimeout(() => {
        this.refresh();
      }, 400);
    }
    this.writeColState();
  }

  writeColState() {
    if (this.isOptionStore) {
      return;
    }
    const dataSetKey = this.dataSetKey
      ? this.dataSetKey
      : `${this.dataSet}${this.systemFilters && this.systemFilters.status ? '__' + this.systemFilters.status : ''}`;

    window.localStorage[`col_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`] = JSON.stringify(this.columnState);

    const columnState = toJS(this.columnSorters).filter((col) => !!this.columnState[col.key]);

    window.localStorage[`col_sort_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`] = JSON.stringify(columnState);
    window.localStorage[`colwidth_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`] = JSON.stringify(
      this.columnWidths
    );

    window.localStorage[`colorder_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`] = JSON.stringify(
      this.columnOrders
    );

    // const filterState = columnState // opt out filters from unused columns

    window.localStorage[`filter_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`] = JSON.stringify(this.filters);
  }

  @action.bound loadColStateFromUrl(urlParams, callback = null) {
    try {
      const t = JSON.parse(urlParams.get(`t`));

      // this.columnSorters = [];
      // if (this.columns) {
      //   this.columns.forEach((col) => {
      //     this.setColState(col.key, !col.hide);
      //     if (col.defaultSortOrder) {
      //       this.columnSorters.push({
      //         key: col.key,
      //         direction: col.defaultSortOrder,
      //       });
      //     }
      //   });
      // }      

      if (Object.keys(t).includes(this.dataSet)) {
        const settings = t[this.dataSet];

        if (callback) {
          callback(settings);
        }

        this.search(settings.q ?? ``);
        // this.setSearchQuery(settings.q ?? ``);

        // if (settings.f) {
        //   this.filters = { ...this.systemFilters, ...settings.f };
        // } else {
        //   this.filters = { ...this.systemFilters };
        // }
        
        // if (settings.s) {
        //   for (const key in settings.s) {
        //     this.setSorter(key, settings.s[key]);
        //   }
        // }

        // this.pageCurrent = settings.p ?? 1;
        // if (settings.ps) {
        //   this.setPageSize(settings.p ?? 1, settings.ps);
        // } else {
        //   this.paginate(settings.p ?? 1);
        // }
      } else {
        if (callback) {
          callback({ q: ``, f: {}, s: {}, p: 1, ps: this.defaultPageSize });
        }

        // this.pageCurrent = 1;
        // this.setSearchQuery(``);
        // this.filters = { ...this.systemFilters };
        // this.setPageSize(1, this.defaultPageSize);
        this.search(``);
      }

    } catch (ex) {
      if (callback) {
        callback({ q: ``, f: {}, s: {}, p: 1, ps: this.defaultPageSize });
      }

      // this.setSearchQuery(``);
      // this.filters = { ...this.systemFilters };
      // this.setPageSize(1, this.defaultPageSize);
      this.search(``);
    }
  }

  loadColState() {
    if (this.isOptionStore) {
      return;
    }
    const dataSetKey = this.dataSetKey
      ? this.dataSetKey
      : `${this.dataSet}${this.systemFilters && this.systemFilters.status ? '__' + this.systemFilters.status : ''}`;

    try {
      if (window.localStorage[`col_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]) {
        this.columnState = JSON.parse(window.localStorage[`col_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]);
      }

      if (window.localStorage[`col_sort_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]) {
        this.columnSorters = JSON.parse(
          window.localStorage[`col_sort_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]
        );
      }

      if (window.localStorage[`colwidth_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]) {
        this.columnWidths = JSON.parse(
          window.localStorage[`colwidth_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]
        );
      }

      if (window.localStorage[`colorder_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]) {
        this.columnOrders = JSON.parse(
          window.localStorage[`colorder_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]
        );
      }

      if (window.localStorage[`filter_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]) {
        this.filters = JSON.parse(window.localStorage[`filter_state_${dataSetKey}_${this.isLocal ? 'loc' : ''}`]);
      }
      if (Object.values(this.columnState).filter((v) => v).length === 0) {
        this.columnState = {};
      }
    } catch (e) {
      //
    }
  }
}

export default DataStoreFactory;
