import React from 'react';
import Addon from './Addon';
import ButtonWrapComponent from './components/ButtonWrapComponent';

@Addon.register
class OperationalNotesPopoverComponent extends Addon {
  static code = 'operationalNotesPopover';

  renderComponent(props) {
    const { store, formStore, config } = this;
    const { options } = config;

    if (!props.rowId) {
      return '';
    }

    store
      .loadWithOptions(
        {
          filters: {
            ...options.filters,
            resourceId: [props.rowId],
            fMode: 'strict',
          },
        },
        true
      )
      .then((i) => i);

    return (
      <ButtonWrapComponent
        key={config.key}
        store={store}
        formStore={formStore}
        options={options}
        config={config}
        rowId={props.rowId}
      />
    );
  }
}

export default OperationalNotesPopoverComponent;
