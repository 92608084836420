import React from 'react';
import { Field } from './Field';

@Field.register
class CustomComponent extends Field {
  static code = 'custom';
  getFormLabel() {}

  renderComponent(props) {
    if (!props.component) {
      return 'Please define "component" prop';
    }
    const Component = props.component;
    return <Component getCol={() => this}{...props} />;
  }
}

export default CustomComponent;
