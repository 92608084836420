import Form from './XForm';
import FormItem from './formItem';
import DataTable from './DataTable';
import EditFormBase from './EditFormBase';
import EditRowFormBase from './EditRowFormBase';
import HelpLink from './components/HelpLinkWrapper';
import FontAwesomeIcon from './assets/icon/FontAwesomeIcon';
import validate from './validate';

const gridCol = { xs: 24, sm: 24, md: 24, lg: 24 };
const gridColNarrow = { xs: 24, sm: 24, md: 24, lg: 12 };
const gridCol2 = { xs: 24, sm: 24, md: 24, lg: 12 };
const gridRow = { gutter: 15 };

class ScriptLoader {
  async load(src, id) {
    return new Promise((resolve) => {
      if (typeof document !== 'undefined') {
        const scriptNode = document.createElement('script');
        scriptNode.type = 'text/javascript';
        scriptNode.onload = () => resolve();
        scriptNode.src = src;
        if (id) {
          scriptNode.id = id;
        }
        document.body.appendChild(scriptNode);
      }
    });
  }

  async remove(id) {
    if (id) {
      const deleteFunc = (sId) => {
        const elm = document.querySelector(`#${sId}`);
        return sId && elm && elm.remove() ? sId : null;
      };
      if (Array.isArray(id)) {
        return Promise.all(id).then((c) => c.filter(deleteFunc));
      }
      // eslint-disable-next-line new-cap
      return new Promise.then(() => deleteFunc(id));
    }
    return null;
  }
}

const scriptLoader = new ScriptLoader();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export {
  gridCol,
  gridCol2,
  gridColNarrow,
  gridRow,
  scriptLoader,
  formItemLayout,
  tailFormItemLayout,
  validate,
  Form,
  FormItem,
  DataTable,
  EditFormBase,
  EditRowFormBase,
  FontAwesomeIcon,
  HelpLink,
};
