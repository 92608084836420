import Echo from 'laravel-echo';
import { autorun } from 'mobx';
import Pusher from 'pusher-js';
import authStore from '../stores/AuthStore';

if (typeof window !== `undefined` && !window.Pusher) {
  window.Pusher = Pusher;
}

class OisSocket {
  queue = [];
  vqueue = [];
  listenUser(channel, cb) {
    if (!this.echo) {
      this.startSocket();
    }
    if (authStore && authStore.loggedIn && authStore.user && authStore.user.id) {
      this.echo.private(`App.User.${authStore.user.id}`).listen(channel, cb);
      this.visitSubscription = this.subscribe('shipVisit').listen('.modified', (item) => {
        this.vqueue.forEach(cb => cb && cb(item));
      });
    } else {
      this.queue.push([channel, cb]);
    }
  }

  leaveUser() {
    if (this.echo && authStore.user && authStore.user.id) {
      const lastUserId = authStore.user.id;
      this.echo.leave(`App.User.${lastUserId}`);
    }
  }
  subscribe(channel) {
    if (!this.echo) {
      this.startSocket();
    }
    return this.echo.channel(channel);
  }

  subscribeVisits(cb) {
    this.vqueue.push(cb);
  }
  unsubscribeVisits(cb) {
    //TODO:
  }
  updateToken(token) {
    this.token = token;
    if (this.echo) {
      if (token) {
        this.echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${token}`;
      } else {
        this.echo.disconnect();
        this.echo = null;
      }
    }
  }

  startSocket() {
    autorun(() => {
      if (authStore.loggedIn && authStore.user && authStore.user.id) {
        // console.log('user changed', authStore.user.id);
        this.updateToken(window.localStorage.getItem('token'));
        this.queue.forEach(([channel, cb]) => {
          this.echo.private(`App.User.${authStore.user.id}`).listen(channel, cb);
        });
        this.visitSubscription = this.subscribe('shipVisit').listen('.modified', (item) => {
          this.vqueue.forEach(cb => cb && cb(item));
        });
      }
    });

    if (!this.echo) {
      if (!this.token) {
        this.token = window.localStorage.getItem('token');
      }
      const params = {
        broadcaster: 'pusher',
        key: 'WpYNL6xPfoXnN3VUfYqjkNrigfszf0Xh', // proccess.env.ECHO_KEY
        wsHost: window.location.hostname,
        wsPort: window.location.port ? 8000 : window.location.port,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        auth: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      };
      this.echo = new Echo(params);
      this.echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${this.token}`;
    }
  }
}

const socket = new OisSocket();
export default socket;
