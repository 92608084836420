import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { EditFormBase, FormItem, gridCol, gridCol2, gridRow, FontAwesomeIcon } from '@ois-lib';
// import EditTimeTableEntry from './Forms/EditTimeTableEntry';

@inject('lineScheduleStore', 'appStore')
@observer
class EditLineSchedule extends EditFormBase {
  title = 'Līniju grafika kartiņa';
  storeName = 'lineScheduleStore';
  baseUrl = '/planning/line_schedule';

  async componentDidMount() {
    this.load(this.props.lineScheduleStore);
  }

  renderAdherenceStatus = (value, row) => {
    const status = {};

    const ttEta = row.ttEta ? moment(row.ttEta) : null;
    const svAta = row.svAta ? moment(row.svAta, 'L LTS') : null;

    if (ttEta && svAta) {
      const arrivalDeviation = moment.duration(svAta.diff(ttEta));
      const arrivalDeviated = arrivalDeviation.asHours();
      const arrivalTimeAboveAllowed = Math.abs(arrivalDeviated) - parseFloat(row.allowedDeviation | 0) > 0;

      status.arrival = {
        color: '#f5222d',
        icon: 'alarm-clock',
      };

      if (arrivalTimeAboveAllowed) {
        const time = ['hours', 'minutes']
          .map((unit) => {
            const dUnit = Math.abs(arrivalDeviation.get(unit));
            if (!dUnit) {
              return null;
            }
            const singular = dUnit % 10 === 1 && dUnit !== 11;
            switch (unit) {
              case 'hours':
                unit = `${dUnit} stund${!singular ? 'as' : 'u'}`;
                break;
              case 'minutes':
                unit = `${dUnit} minūt${!singular ? 'es' : 'i'}`;
                break;
              default:
            }
            return unit;
          })
          .filter((i) => !!i)
          .join(' ');

        if (arrivalDeviated > 0) {
          status.arrival.value = `Kavēts ${time}`;
        } else {
          status.arrival.value = `Ieradās ${time} ātrāk`;
        }
      } else {
        status.arrival = {
          value: 'Ievērots',
          color: '#007314',
          icon: 'badge-check',
        };
      }
    }

    const ttEtd = row.ttEtd ? moment(row.ttEtd) : null;
    const svAtd = row.svAtd ? moment(row.svAtd, 'L LTS') : null;

    if (ttEtd && svAtd) {
      const departureDeviation = moment.duration(svAtd.diff(ttEtd));
      const departureDeviated = departureDeviation.asHours();
      const departureTimeAboveAllowed = Math.abs(departureDeviated) - parseFloat(row.allowedDeviation | 0) > 0;

      status.departure = {
        color: '#f5222d',
        icon: 'alarm-clock',
      };

      if (departureTimeAboveAllowed) {
        const time = ['hours', 'minutes']
          .map((unit) => {
            const dUnit = Math.abs(departureDeviation.get(unit));
            if (!dUnit) {
              return null;
            }
            const singular = dUnit % 10 === 1 && dUnit !== 11;
            switch (unit) {
              case 'hours':
                unit = `${dUnit} stund${!singular ? 'as' : 'u'}`;
                break;
              case 'minutes':
                unit = `${dUnit} minūt${!singular ? 'es' : 'i'}`;
                break;
              default:
            }
            return unit;
          })
          .filter((i) => !!i)
          .join(' ');

        if (departureDeviated > 0) {
          status.departure.value = `Kavēts ${time}`;
        } else {
          status.departure.value = `Ieradās ${time} ātrāk`;
        }
      } else {
        status.departure = {
          value: 'Ievērots',
          color: '#007314',
          icon: 'badge-check',
        };
      }
    }

    return (
      <>
        {status.arrival && (
          <div>
            <h4>Ienākšana:&nbsp;</h4>
            <span style={{ color: status.arrival.color, fontWeight: 600 }}>
              <FontAwesomeIcon icon={status.arrival.icon} />
              &nbsp;
              {status.arrival.value}
            </span>
          </div>
        )}
        {status.departure && (
          <div>
            <h4>Iziešana:&nbsp;</h4>
            <span style={{ color: status.departure.color, fontWeight: 600 }}>
              <FontAwesomeIcon icon={status.departure.icon} />
              &nbsp;
              {status.departure.value}
            </span>
          </div>
        )}
      </>
    );
  };

  renderForm() {
    const hasScheduleAdherence = this.props.form.getFieldValue('_scheduleAdherence')
      ? !!this.props.form.getFieldValue('_scheduleAdherence').length
      : null;

    return (
      <>
        <br />
        <Row {...gridRow}>
          <Card title="Parametri" bordered className="mt15">
            <Col {...gridCol2}>
              <FormItem name="shipId" />
              <FormItem name="shippingLineId" />
              <FormItem name="startDate" />
              <FormItem name="endDate" />
            </Col>
          </Card>
        </Row>
        {/* <Row {...gridRow}>
          <Col {...gridCol}>
            <FormItem formKind={EditTimeTableEntry} name="timetableEntries" formItemProps={{ className: 'mt15' }} />
          </Col>
        </Row> */}
        <Row {...gridRow}>
          <Col {...gridCol}>
            {hasScheduleAdherence && (
              <FormItem
                disabled
                name="_scheduleAdherence"
                columnRenderMethods={{
                  allowedDeviation: this.renderAdherenceStatus,
                }}
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditLineSchedule' })(EditLineSchedule));
