import React from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import { Row, Col, Card, Tree, Button, Input, Empty, Select, Skeleton } from 'antd';
import 'react-quill/dist/quill.snow.css';
import FontAwesomeIcon from '@ois-lib/assets/icon/FontAwesomeIcon';
import { gridRow } from '..';

const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;

@inject('can', 'helpGroupStore', 'helpContentStore')
@observer
class Help extends React.Component {
  @observable activeSection = '';
  @observable activeTitle = '';

  @observable loading = false;
  @observable content = '';
  @observable helpTree = [];
  @observable viewSource = false;
  @observable search = '';
  @observable expandedKeys = [];

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { history, helpGroupStore } = this.props;
    const { location } = history;
    const { searchParams } = location;

    if (searchParams && searchParams.sysGroup && window.localStorage.helpSysGroup) {
      if (['ois', 'oks'].includes(searchParams.sysGroup)) {
        if (window.localStorage.helpSysGroup !== searchParams.sysGroup) {
          window.localStorage.setItem('helpSysGroup', searchParams.sysGroup);
        }
      }
    }

    helpGroupStore.systemFilters = { sysGroup: window.localStorage.helpSysGroup };
    helpGroupStore.loadOnly();

    this.check = false;
    this.firstLoad = true;
    helpGroupStore.searchQuery = '';
    this.unlisten = history.listen((loc) => {
      if (
        !loc.pathname.match(/^\/help([A-z]+)/g) &&
        loc.pathname.match(/^\/help\//g) &&
        history.location.pathname !== '/help'
      ) {
        if (!['/help', '/help/'].includes(history.location.pathname)) {
          this.loadPath(loc.pathname);
        }
      }
    });

    if (!['/help', '/help/'].includes(history.location.pathname)) {
      this.loadPath(history.location.pathname);
    }

    if (helpGroupStore) {
      helpGroupStore.setPageSize(null, 9999);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  @action.bound setContent(content) {
    this.content = content.replace('[data-image-url]:', '/api/v1/download/');
  }

  handleSysGroup = (value) => {
    const { helpGroupStore, history } = this.props;
    if (['ois', 'oks'].includes(value)) {
      this.sysGroup = value;
    } else {
      this.sysGroup = 'ois';
    }
    window.localStorage.setItem('helpSysGroup', this.sysGroup);
    helpGroupStore.systemFilters = { sysGroup: this.sysGroup };
    helpGroupStore.refresh();
    history.replace('/help/');
  };

  flatKeys = (items, prefix) =>
    items.reduce((curr, { key, children }, index) => {
      curr.push(key || prefix || `${'0.'}${index}`);
      if (children && children.length) {
        for (const child of this.flatKeys(children, index)) {
          curr.push(child);
        }
      }
      if (key) {
        curr.push(key);
      }
      return curr || [];
    }, []);

  @action.bound async loadPath(path) {
    const { helpContentStore } = this.props;
    if (['Group', 'Content'].includes(path)) {
      return null;
    }
    this.setContent('');
    this.loading = true;
    this.activeSection = path.replace('/help/', '/');
    this.activeTitle = '';
    if (this.activeSection) {
      if (window.localStorage.helpSysGroup) {
        helpContentStore.additionalData = { sysGroup: window.localStorage.helpSysGroup };
      }
      const dataX = await helpContentStore.loadOne(this.activeSection.substring(1));
      if (dataX) {
        this.activeTitle = dataX.name;
        this.activeId = dataX.key;
        this.setContent(dataX.content);
        this.loading = false;
      } else {
        this.setContent('');
      }
    } else {
      this.setContent('');
    }
    return true;
  }

  toggleExpand(parentKey, open = false, keys = []) {
    if (parentKey && !open) {
      const { helpGroupStore } = this.props;
      const activeTree = toJS(helpGroupStore.data)
        .map((i) => ({ key: i.key, children: i.children.map((c) => c.key) }))
        .find((i) => i.key === parentKey);
      const activeSection = this.activeSection.substr(1);
      if (activeTree) {
        if (activeTree && activeTree.children && !activeTree.children.includes(activeSection)) {
          keys = keys.filter((k) => !activeTree.children.includes(k) && k !== parentKey);
        }
      }
    }

    this.expandedKeys = keys;
  }

  renderTree(list, prefix = '') {
    const { can } = this.props;
    return list.map(({ name, key, children, ...other }, i) => {
      const childNodes = children && this.renderTree(children, `${prefix}.${i}`).filter((n) => !!n);
      const isThing = 'content' in other;
      const canEdit = can(['edit help.helpGroup']);
      const canDelete = can(['delete help.helpGroup']);

      return (
        <TreeNode
          className="help-tree"
          title={
            isThing ? (
              <Link
                to={`/help/${key || ''}${
                  window.localStorage.helpSysGroup ? `?sysGroup=${window.localStorage.helpSysGroup}` : ''
                }`}
              >
                {name}
              </Link>
            ) : (
              name
            )
          }
          selectable={isThing}
          key={key || prefix || `${'0.'}${i}`}
          isLeaf={isThing}
        >
          {!isThing && (canEdit || canDelete) && (
            <TreeNode
              isLeaf={!isThing}
              icon={<></>}
              className="help-tree-action"
              title={
                <>
                  {canEdit && (
                    <Link
                      to={`/helpContent/new?group=${other.id || key || ''}${
                        window.localStorage.helpSysGroup ? `&sysGroup=${window.localStorage.helpSysGroup}` : ''
                      }`}
                    >
                      <Button type="primary" size="small">
                        <FontAwesomeIcon icon="plus" />
                        Pievienot jaunu ierakstu
                      </Button>
                    </Link>
                  )}
                  <Link
                    to={`/helpGroup/${other.id || key || ''}${
                      window.localStorage.helpSysGroup ? `?sysGroup=${window.localStorage.helpSysGroup}` : ''
                    }`}
                  >
                    <Button type="secondary" size="small">
                      <FontAwesomeIcon icon="pencil" />
                      Labot grupu
                    </Button>
                  </Link>
                </>
              }
              selectable={isThing}
              key={(`actions-${key}` && prefix) || `${'actions-0.'}${i}`}
            />
          )}
          {childNodes}
        </TreeNode>
      );
    });
  }

  render() {
    const content = { __html: this.content };
    const { history, helpGroupStore, can } = this.props;
    const helpTree = toJS(helpGroupStore.data);

    let { expandedKeys } = this;
    expandedKeys = [...new Set(toJS(expandedKeys))];
    let title = this.activeTitle;
    for (const section of helpTree) {
      const found =
        section.children &&
        section.children.find((item) => {
          return item.key === this.activeSection.substr(1);
        });
      if (found) {
        title = found.name;
        expandedKeys = [...new Set([...expandedKeys, found.key])];
        break;
      }
    }

    let renderedTree = [];
    if (helpTree && helpTree.length) {
      renderedTree = this.renderTree(helpTree);
    }

    if (helpGroupStore.searchQuery) {
      const searchResultParents = renderedTree.map((el) => el.key);
      expandedKeys = [...expandedKeys, ...toJS(searchResultParents, { recurseEverything: true })];
    }

    return (
      <>
        <h1 className="cabinet_title">Palīdzība</h1>
        <Row className="help-grid" type="flex" {...gridRow}>
          <Col xs={24} sm={24} md={24} lg={9}>
            <Card
              actions={
                can(['edit help.helpGroup'])
                  ? [
                      <Select
                        key="sysGroup"
                        defaultValue={window.localStorage.helpSysGroup}
                        style={{ minWidth: 130 }}
                        onChange={this.handleSysGroup}
                      >
                        <Select.Option key="ois" value="ois">
                          OIS palīgs
                        </Select.Option>
                        <Select.Option key="oks" value="oks">
                          OKS palīgs
                        </Select.Option>
                      </Select>,
                      <div key="add-group" style={{ textAlign: 'right' }}>
                        <Button
                          onClick={() =>
                            history.push(
                              `/helpGroup/new${
                                window.localStorage.helpSysGroup ? `?sysGroup=${window.localStorage.helpSysGroup}` : ''
                              }`
                            )
                          }
                          type="primary"
                        >
                          <FontAwesomeIcon icon="plus" key="plus" />
                          Pievienot grupu
                        </Button>
                      </div>,
                    ]
                  : undefined
              }
              title="Satura rādītājs"
              className="help-toc-card"
              extra={
                <Search
                  placeholder="Meklēt..."
                  onKeyUp={(e) => {
                    if (e.which === 27) {
                      helpGroupStore.searchQuery = '';
                      helpGroupStore.search();
                      e.target.blur();
                    }
                  }}
                  className={[helpGroupStore.searchQuery && 'hide-search-icon']}
                  onChange={(e) => {
                    helpGroupStore.setSearchQuery(e.target.value);
                    helpGroupStore.search(e.target.value);
                  }}
                  value={helpGroupStore.searchQuery}
                  onFocus={(e) => {
                    if (e.target === document.activeElement) {
                      window.cancelSubmittion = true;
                    }
                  }}
                  onBlur={(e) => {
                    if (window.cancelSubmittion && e.target !== document.activeElement) {
                      window.cancelSubmittion = false;
                    }
                  }}
                  allowClear
                />
              }
            >
              {(renderedTree.length && (
                <DirectoryTree
                  className="fancy-directory-tree"
                  selectable
                  autoExpandParent
                  selectedKeys={[this.activeSection.substring(1)]}
                  defaultExpandedKeys={[this.activeSection.substring(1)]}
                  expandedKeys={toJS(expandedKeys)}
                  onExpand={(_expandedKeys, { expanded, node }) => {
                    this.toggleExpand(node.props.eventKey, expanded, [...new Set(_expandedKeys)]);
                  }}
                  onSelect={(e) => {
                    history.replace(`/help/${e || ''}`);
                  }}
                >
                  {renderedTree}
                </DirectoryTree>
              )) || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={15}>
            {this.activeSection && (
              <Card
                actions={
                  can(['edit help.helpContent'])
                    ? [
                        <div key="add-content" style={{ textAlign: 'right' }}>
                          <Button
                            onClick={() =>
                              history.push(
                                `/helpContent/${this.activeId || ''}${
                                  window.localStorage.helpSysGroup
                                    ? `?sysGroup=${window.localStorage.helpSysGroup}`
                                    : ''
                                }`
                              )
                            }
                            type="primary"
                          >
                            <FontAwesomeIcon icon="edit" key="edit" />
                            Rediģēt
                          </Button>
                        </div>,
                      ]
                    : undefined
                }
                title={<span title={title}>{title}</span>}
              >
                {helpGroupStore.loading && <Skeleton />}
                {/* eslint-disable-next-line react/no-danger */}
                <div className="normalize-text" dangerouslySetInnerHTML={content} />
              </Card>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default Help;
