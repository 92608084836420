import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Form, Input, Button, message } from 'antd';
import FontAwesomeIcon from '@ois-lib/assets/icon/FontAwesomeIcon';
// import Validate from '@ois-lib/validate';
// eslint-disable-next-line no-unused-vars
import { Spinner } from '@ois-lib/addons/components/ButtonWrapComponent';

const styleSheet = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    width: '85%',
    height: '80%',
  },
  subButton: {
    display: 'flex',
    marginBottom: '16px',
  },
  heading: {
    fontWeight: '600',
    fontSize: '1.1em',
    marginBottom: '1.1em',
  },
  btn: {
    width: '100%',
  },
};

@inject('authStore')
@withRouter
@observer
class PasswordRequestFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { authStore, form, history } = this.props;
    form.validateFields(async (err, { email }) => {
      this.setState({ loading: true });
      if (!err) {
        const result = await authStore.requestPasswordReset(email);
        if (result && result.ok) {
          this.setState({ loading: false });
          message.success(`Paroles atjaunošanas pieprasījums veiksmīgi nosūtīts uz Jūsu e-pastu!`);
          await new Promise((r) => setTimeout(r, 3000));
          history.replace(`/`);
        } else {
          form.setFields({
            email: {
              value: ``,
              errors: [new Error(result?.error ?? `Paroles atjaunošanas pieprasījums neveiksmīgs!`)],
            },
          });
          this.setState({ loading: false });
        }
      }
    });
  };
  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { loading } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} style={styleSheet.form}>
        <h1 style={styleSheet.heading}>Lietotāja paroles pieprasījums</h1>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Lūdzu ievadiet e-pastu' },
              { type: 'email', message: 'Lūdzu, ievadiet derīgu e-pasta adresi' },
            ],
          })(
            <Input
              disabled={loading}
              prefix={<FontAwesomeIcon icon="at" style={{ fill: 'rgba(0,0,0,.25)' }} />}
              placeholder="E-pasta adrese"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            disabled={!!form.getFieldError('email') || loading}
            type="primary"
            htmlType="submit"
            style={styleSheet.btn}
          >
            Apstiprināt
          </Button>
          <span>
            vai&nbsp;
            <Link to="/login">atgriezties uz sākumu</Link>
          </span>
        </Form.Item>
      </Form>
    );
  }
}

const LoginForm = Form.create({ name: 'PasswordRequestForm' })(PasswordRequestFormComponent);
export default LoginForm;
