import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import EditLineSchedule from './EditLineSchedule';

@inject('lineScheduleStore', 'authorize', 'can')
@observer
class LineSchedule extends React.Component {
  static Edit = EditLineSchedule;

  title = 'Līniju kuģi';

  componentDidMount() {
    const { lineScheduleStore } = this.props;
    lineScheduleStore.load();
  }

  render() {
    const { lineScheduleStore, history } = this.props;

    return (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>
            <DataTable
              store={lineScheduleStore}
              title={this.title}
              onRowClick={(row) => history.push(`/planning/line_schedule/${row.id || ''}`)}
              onAdd={() => history.push('/planning/line_schedule/new')}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(LineSchedule);
