import React from 'react';
import { Field } from './Field';
import DataFieldTableComponent from './components/DataFieldTableComponent';

@Field.register
class FieldTableComponent extends Field {
  static code = 'fieldtable';
  extraItemProps = {
    wrapperCol: { sm: 24, xs: 24, lg: 24, md: 24 },
    style: { width: '100%', marginRight: 0 },
    label: undefined,
  };
  getFormLabel() {
    return undefined;
  }
  renderComponent(props) {
    return <DataFieldTableComponent title={this.title} formInstance={this.formInstance} {...props} />;
  }
}

export default FieldTableComponent;
