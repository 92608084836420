import CalculationPeriods from './CalculationPeriods';
import Invoices from './Invoices';
import Discounts from './Discounts';
import Calculations from './Calculations';

export default {
  CalculationPeriods,
  Invoices,
  Discounts,
  Calculations,
};
