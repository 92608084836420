/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Field } from './Field';
import { MapLabelComponent } from './MapComponent';
import fieldSpec from '../stores/FieldSpec';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';

const skloisCodeTitles = {
  VisitPermissionStatus: 'Atļauju deklarācija',
  MedDeclarations: 'Med. deklarācija',
  AtaDeclarations: 'ATA deklarācija',
  AtdDeclarations: 'ATD deklarācija',
  ExpDeclaration: 'Kļūda (exp)',
  HzaDeclaration: 'FAL7',
  NotDeclarationIn: 'Notice of arrival',
  NotDeclarationOut: 'Notice of departure',
  PaxDeclarationIn: 'FAL6 (ienākot)',
  PaxDeclarationOut: 'FAL6 (izejot)',
  ScdDeclarationIn: 'FAL2 (ienākot)',
  ScdDeclarationOut: 'FAL2 (izejot)',
  SecDeclaration: 'Drošības deklarācija',
  WasDeclaration: 'Atkritumu deklarācija',
  WebData: 'Kļūda (web api)',
};

const notesSectionTitles = {
  visit: 'Vizīte',
  berth: 'Piestātne',
  ships: 'Kuģis (ienākot/izejot)',
  shipIn: 'Kuģis (ienākot)',
  shipOut: 'Kuģis (izejot)',
};

const IndicatorColumn = inject('appStore')(
  observer(({ value, row, appStore, asColumn }) => {
    if (value) {
      const { shippingLines, ships, expiredDocuments, hasDangerousCargo, bunkering, skloisError, isFat, calculationResults } = value;
      const history = appStore.useBrowserHistory();
      let shipVisitPath = '/';
      if (appStore.shipVisitLink) {
        shipVisitPath = appStore.shipVisitLink.concat('/').replace('//', '/');
      } else {
        shipVisitPath = appStore.sysKey === 'ois' ? '/live/expected/' : '/planning/visits/';
      }

      const recordPath =
        history.location.pathname.includes('/live') && !/\/(\d+)$/g.test(history.location.pathname)
          ? `${history.location.pathname}/${row.id || ''}?tabs=`
          : `${shipVisitPath + row.id}?tabs=`;
      const indicators = [];

      if (shippingLines.in || shippingLines.out) {
        const slArray = [];
        for (const slk of Object.keys(shippingLines)) {
          if (shippingLines[slk]) {
            slArray.push(shippingLines[slk].name);
          }
        }
        const slength = slArray.length;
        if (slength) {
          indicators.push(
            <Tooltip
              key="shippingLines"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${recordPath}t:shipping-line`);
              }}
              placement="topLeft"
              title={`Līnija${!(slength % 10 === 1 && slength !== 11) ? 's' : ''}: ${slArray.join(' - ')}`}
            >
              <FontAwesomeIcon className="shippingLines" icon="exchange-alt" type="solid" />
            </Tooltip>
          );
        }
      }

      if (ships.in || ships.out) {
        const sArray = [];
        const rArray = [];
        for (const sk of Object.keys(ships)) {
          if (ships[sk]) {
            if (ships[sk].isSmall && !sArray.includes(ships[sk].name)) {
              sArray.push(ships[sk].name);
            }
            if (ships[sk].isRiskShip && !rArray.includes(ships[sk].name)) {
              rArray.push(ships[sk].name);
            }
          }
        }
        if (sArray.length) {
          const label = sArray.length > 1 ? 'Mazie kuģi: ' : 'Mazais kuģis: ';
          indicators.push(
            <Tooltip
              key="smallShips"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${recordPath}t:identifiers`);
              }}
              placement="topLeft"
              title={`${label} ${sArray.join(', ')}`}
            >
              <FontAwesomeIcon className="smallShips" icon="ship" type="solid" />
            </Tooltip>
          );
        }

        if (rArray.length) {
          const label = rArray.length > 1 ? 'Riska kuģi: ' : 'Riska kuģis: ';
          indicators.push(
            <Tooltip
              key="riskShips"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${recordPath}t:identifiers`);
              }}
              placement="topLeft"
              title={`${label} ${rArray.join(', ')}`}
            >
              <FontAwesomeIcon className="riskShips" icon="engine-warning" type="solid" />
            </Tooltip>
          );
        }
      }

      if (hasDangerousCargo) {
        indicators.push(
          <Tooltip
            key="dangerousCargo"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`${recordPath}t:dangerousCargo`);
            }}
            placement="topLeft"
            title="Bīstamā krava"
          >
            <FontAwesomeIcon className="dangerousCargo" icon="radiation" type="solid" />
          </Tooltip>
        );
      }

      if (isFat) {
        let textIndicator = ``;

        if (value?.isFatBerthsParams && Array.isArray(value.isFatBerthsParams) && value.isFatBerthsParams.length > 0) {
          value.isFatBerthsParams.forEach(isFatBerthParams => {
            const fatLength = isFatBerthParams.berthLength !== '' ? `Atļautais kuģa garums ${isFatBerthParams.berthLength} m (${isFatBerthParams.shipLength} m).` : '';
            const fatDraft = isFatBerthParams.berthDraft !== '' ? `Atļautā iegrime ${isFatBerthParams.berthDraft} m (${isFatBerthParams.shipDraft} m). ` : '';

            textIndicator += `Piestātne ${isFatBerthParams.name}.  ${fatLength} ${fatDraft}`;
          });
        } else {
          if (value?.isFatBerths && Array.isArray(value.isFatBerths) && value.isFatBerths.length > 0) {
            textIndicator = `Kuģa tehniskie parametri neatbilst piestātnes (${value.isFatBerths.join(`, `)}) parametriem`;
          }
        }

        indicators.push(
          <Tooltip
            key="fat"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`${recordPath}b:movements`);
            }}
            placement="topLeft"
            title={textIndicator}
          >
            <FontAwesomeIcon className="fat" icon="exclamation-square" type="solid" />
          </Tooltip>
        );
      }

      if (bunkering) {
        const bunkeringTitle = [];
        for (const k of Object.keys(bunkering)) {
          let bLabel;
          let bValue = bunkering[k];
          switch (k) {
            case 'startTime':
              bLabel = 'Sākums';
              bValue = bValue ? moment(bValue).format('L LT') : null;
              break;
            case 'endTime':
              bLabel = 'Beigas';
              bValue = bValue ? moment(bValue).format('L LT') : null;
              break;
            case 'comment':
              bLabel = 'Komentāri';
              break;
            case '_t_shipOperationTypeId':
              bLabel = 'Kuģa darbība';
              break;
            case '_t_shipStateChangeReasonId':
              bLabel = 'Iemesls';
              break;
            default:
          }
          if (bLabel && bValue) {
            bunkeringTitle.push(`${bLabel}: ${bValue};`);
          }
        }

        indicators.push(
          <Tooltip
            key="bunkering"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`${recordPath}b:shipOperations`);
            }}
            placement="topLeft"
            title={
              !!bunkeringTitle.length && (
                <>
                  <h4 style={{ color: '#fff', fontWeight: 600 }}>Notiek bunkurēšana: </h4>
                  <ul className="clean-list" style={{ marginBottom: 0 }}>
                    {bunkeringTitle.map((i, k) => (
                      <li key={k}>{i}</li>
                    ))}
                  </ul>
                </>
              )
            }
          >
            <FontAwesomeIcon className="bunkering" icon="anchor" type="solid" />
          </Tooltip>
        );
      }

      if (expiredDocuments && expiredDocuments.length) {
        const expDocNames = expiredDocuments.map((d) => {
          return `${d.shipDocumentType ? d.shipDocumentType.name : ' - '} (${moment(d.validUntil).format('L')})`;
        });

        indicators.push(
          <Tooltip
            key="expiredDocuments"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`${recordPath}t:documents`);
            }}
            placement="topLeft"
            title={`${expDocNames.length > 1 ? 'Dokumenti' : 'Dokuments'} ar iztecējušu temiņu: ${expDocNames.join(
              ', '
            )}`}
          >
            <FontAwesomeIcon className="expiredDocuments" icon="exclamation-triangle" type="solid" />
          </Tooltip>
        );
      }

      if (skloisError && skloisError.length) {
        indicators.push(
          <Tooltip
            key="skloisError"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`${recordPath}b:permits`);
            }}
            placement="topLeft"
            title="Kļūda nosūtot datus uz SKLOIS"
          >
            <FontAwesomeIcon className="skloisError" icon="file-exclamation" type="solid" />
          </Tooltip>
        );
      }

      if (calculationResults && calculationResults.length) {
        const calcres=JSON.parse(calculationResults);
        if (calcres && calcres.status!==undefined && calcres.status==='failed'){
          indicators.push(
            <Tooltip
              key="calculationResults"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${recordPath}t:port-dues`);
              }}
              placement="topLeft"
              title={'Kļūda kalkulācijās'+'     '+(calcres.error_msg?calcres.error_msg:'')}
            >
              <FontAwesomeIcon className="skloisError" icon="calculator" type="solid" />
            </Tooltip>
          );
        }
      }

      if (row._skloisStatus) {
        const errKeys = Object.keys(row._skloisStatus);
        if (errKeys.length) {
          indicators.push(
            <Tooltip
              key="skloisStatusError"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${recordPath}t:identifiers`);
              }}
              placement="topLeft"
              overlayClassName="wide-tooltip"
              title={
                <>
                  <h3 style={{ color: '#fff', fontWeight: 600 }}>
                    Kļūda{errKeys.length > 1 && 's'} ielādējot datus no SKLOIS:
                  </h3>
                  <ul className="clean-list" style={{ marginBottom: 0 }}>
                    {errKeys.map((key) => (
                      <li key={key} style={{ whiteSpace: 'nowrap' }}>
                        <strong>{skloisCodeTitles[key] || 'Kļūda'}:</strong> {row._skloisStatus[key]}
                      </li>
                    ))}
                  </ul>
                </>
              }
            >
              <FontAwesomeIcon className="skloisError" icon="exclamation-circle" type="solid" />
            </Tooltip>
          );
        }
      }

      if (value && value.notes) {
        // return;
        const noteKeys = Object.keys(value.notes);
        const notesArray = noteKeys.reduce((array, key) => {
          if (value.notes[key] && value.notes[key].length) {
            // console.log('row.notes[key]: ', value.notes[key]);
            const item = {
              title: notesSectionTitles[key],
              key: key,
              notes: value.notes[key] || [], // this might differ (must normalize rows)
            };
            array.push(item);
          }
          return array;
        }, []);
        if (notesArray && notesArray.length) {
          let total = 0;
          for (const item of notesArray) {
            total += item.notes.length;
          }
          const totalStr = `${total > 1 ? total > 9 ? `9+` : total : ``}`;

          // console.log('notesArray: ', notesArray);
          indicators.push(
            <Tooltip
              key="operationalNotes"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${recordPath}t:identifiers`);
              }}
              placement="topLeft"
              overlayClassName="wide-tooltip"
              title={
                <>
                  <h3 style={{ color: '#fff', fontWeight: 600 }}>Operatīvās piezīmes:</h3>
                  <ul className="clean-list" style={{ marginBottom: 0 }}>
                    {notesArray.map((item, key) => (
                      <li key={key}>
                        <h4 style={{ color: '#fff' }}>
                          <Tag>{item.title}:</Tag>
                        </h4>
                        <ol>
                          {item.notes.map((n, nK) => {
                            delete n.id;
                            delete n.resourceId;
                            delete n.resourceType;
                            if (n.files && !n.files.length) {
                              delete n.files;
                            }
                            if (n.created_at==n.updated_at) {
                              delete n.updated_at;
                            }
                            return (
                              <li key={`${key}.${nK}`} style={{
                                // whiteSpace: 'nowrap',
                                maxWidth:'300px',
                                 margin: '5px 0' }}>
                                <MapLabelComponent
                                  tableName="operationalNotes"
                                  value={JSON.stringify(n)}
                                  skipIfNoTitle={true}
                                  // returnAsObject
                                  isJson
                                />
                              </li>
                            );
                          })}
                        </ol>
                      </li>
                    ))}
                  </ul>
                </>
              }
            >
              <div
                className="withCount"
                style={asColumn ? { } : {
                  width: 68 + totalStr.length * 10,
                  textAlign: `center`,
                }}
              >
                <FontAwesomeIcon className="operationalNotes" icon="clipboard" type="solid" />
                <span style={{ color: `black`, padding: 5 }}>{totalStr}</span>
              </div>
            </Tooltip>
          );
        }
      }

      return indicators.length ? <div className="indicator-icons">{indicators}</div> : '';
    }
    return value;
  })
);

const PermitsColumn = inject('appStore')(
  observer(({ row, appStore }) => {
    const history = appStore.useBrowserHistory();
    let shipVisitPath = '/';
    if (appStore.shipVisitLink) {
      shipVisitPath = appStore.shipVisitLink.concat('/').replace('//', '/');
    } else {
      shipVisitPath = appStore.sysKey === 'ois' ? '/live/expected/' : '/planning/visits/';
    }
    const recordPath = history.location.pathname.includes('/live')
      ? `${history.location.pathname}/${row.id || ''}?tabs=`
      : `${shipVisitPath + row.id}?tabs=`;
    const p = [];
    if (row && row._indicators && row._indicators.alertPermits) {
      for (const key of Object.keys(row._indicators.alertPermits)) {
        const permits = row._indicators.alertPermits[key];
        if (permits.length) {
          p.push(
            <div key={`permitOf-${key}`} className={`permitOf-${key}`}>
              <span className="title">
                <FontAwesomeIcon icon={`arrow-${key === 'in' ? 'down' : 'up'}`} type="solid" />
                {/* {key === 'in' ? 'in' : 'out'} */}
              </span>
              <ul>
                {permits.map((pmit, i) => {
                  let icon = 'comment';
                  if (pmit.status === 'REJECTED') {
                    icon = 'times-octagon';
                  } else if (pmit.status === 'ACCEPTED') {
                    icon = 'shield-check';
                  }
                  return (
                    <Tooltip
                      key={`operationalNotes-${i}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`${recordPath}t:permits`);
                      }}
                      placement="topLeft"
                      overlayClassName="wide-tooltip"
                      title={
                        pmit.status === `REJECTED` ? (
                          <>
                            {pmit.comment ? `${pmit.comment} ` : ``}
                            {`(${moment(pmit.updatedTime).format(`DD.MM.YYYY HH:mm:ss`)})`}
                          </>
                        ) : undefined
                      }
                    >
                      <li key={pmit.id}>
                        <span className={`status-text status-${pmit.status && pmit.status.toLowerCase()}`}>
                          <FontAwesomeIcon icon={icon} type="solid" />
                        </span>
                        &nbsp;
                        <span className="code-text">{pmit.controlOrganization && pmit.controlOrganization.code}</span>
                      </li>
                    </Tooltip>
                  );
                })}
              </ul>
            </div>
          );
        }
      }
    }

    return p.length ? (
      <div
        className="alert-permits-block"
        onClick={(e) => {
          e.stopPropagation();
          history.push(`${recordPath}b:permits`);
        }}
      >
        {p}
      </div>
    ) : (
      ' '
    );
  })
);

const InlineRenderer = observer(({ render, value, row }) => render(value, row, null));

@Field.register
class IndicatorComponent extends Field {
  static code = 'indicator';

  getFormLabel() {
    return undefined;
  }

  getCellRenderer() {
    return this.render ? this.render : (v) => v;
  }

  renderPermitColumn(value, row) {
    return <PermitsColumn value={value} row={row} />;
  }

  render(value, row) {
    return <IndicatorColumn value={value} row={row} asColumn={true} />;
  }

  renderComponent(props) {
    // eslint-disable-next-line camelcase
    const { value, record_store } = props;
    const row = toJS(record_store.item, { recurseEverything: true });
    return this.inline ? (
      <InlineRenderer value={value} row={row} render={this.render} />
    ) : (
      <IndicatorColumn value={value} row={row} asColumn={false}  />
    );
  }
}

export default IndicatorComponent;
