import React from 'react';
import { Drawer, Form, Select, Input, Button, Tree } from 'antd';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import { FontAwesomeIcon } from '@ois-lib';
import OverviewSettingsList from './OverviewSettingsList';

const { Option } = Select;
const { TreeNode } = Tree;

@inject('appStore')
@observer
class OverviewDrawer extends React.Component {
  @observable flexSize = 2;
  @observable search = '';
  @observable expandedKeys = [];
  @observable activeSection = [];
  @observable activeDrag = null;
  @observable activeTitle = '';

  items = [];
  block = null;
  settings = [];

  constructor(props) {
    super(props);

    if (props.data && props.data.id) {
      this.data = {};
    }
    if (props.settings) {
      this.settings = props.settings;
    }

    if (props.block && typeof props.block === 'string') {
      this.block = props.block;
    }

    const filterRoutes = (items) =>
      items
        .map((i) => {
          if (i.children) {
            i.children = filterRoutes(i.children);
          }
          if (i.hasWidget || (i.children && i.children.length)) {
            return i;
          }
          return null;
        })
        .filter((i) => i);

    this.items = filterRoutes(toJS(props.appStore.routes, { recurseEverything: true }));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (props.block && typeof props.block === 'string') {
      this.block = props.block;
    }
    this.activeSection = this.settings.map((s) => s.path);

    const activeItem = this.settings.find((s) => s.path === this.block);
    if (this.settings && activeItem) {
      this.flexSize = activeItem.flexSize;
    }
  }

  handleSubmit = () => {
    if (this.block) {
      this.settings = this.settings.map((s) => (s.path === this.block ? { flexSize: this.flexSize, path: s.path } : s));
    } else {
      this.settings = this.activeSection.map((as) => {
        const it = this.settings.find((s) => s.path === as);
        return {
          flexSize: (it && it.flexSize) || this.flexSize,
          path: as,
        };
      });
    }

    const { onSubmit, toggleTrigger } = this.props;

    onSubmit(this.settings);
    toggleTrigger();
  };

  renderTree = (list, prefix = '') =>
    list.map(({ title, path, children, hasWidget }, i) => {
      const filtered =
        children && children.length && !title.toLowerCase().includes(this.search.toLowerCase())
          ? children.filter((c) => c.title.toLowerCase().includes(this.search.toLowerCase()))
          : children || null;

      const childNodes = filtered && !!filtered.length && this.renderTree(filtered, `${prefix}.${i}`);

      if (children && !children.length) {
        children = null;
      }

      let isDisabled = !!this.block;
      if (isDisabled) {
        isDisabled = path !== this.block && !!this.settings.map((s) => s.path).includes(path);
      }
      return (
        <TreeNode
          title={title}
          disabled={isDisabled}
          selectable={hasWidget}
          key={hasWidget ? path : prefix || `0.${i}`}
          isLeaf={!children}
          className={!children ? 'leaf disable-select' : ' disable-select'}
        >
          {childNodes}
        </TreeNode>
      );
    });

  onDragStart = (info) => {
    this.activeDrag = info.node.props.eventKey;
  };

  onDragEnd = () => {
    this.activeDrag = null;
  };

  onDrop = (info) => {
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dragPos = parseInt(info.dragNode.props.pos.split('-').pop(), 10);
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item === key) {
          return callback(item, index, arr);
        }
        return null;
      });
    };
    const data = [...toJS(this.activeSection, { recurseEverything: true })];

    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      if (info.dropToGap) {
        arr.splice(index, 1);
      }
      dragObj = item;
    });

    if (!info.dropToGap) {
      const temp = dragObj;
      data[dragPos] = data[info.dropPosition];
      data[info.dropPosition] = temp;
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    this.activeSection = data;
  };

  renderSelectedTreeItems(routes, settings) {
    return routes.reduce((routeContainer, routeItem) => {
      const { title, path, children } = routeItem;
      if (children && children.length) {
        for (const child of this.renderSelectedTreeItems(children, settings)) {
          routeContainer.push(child);
        }
      }

      if (path && settings.includes(path)) {
        routeContainer.push(
          <TreeNode
            isLeaf
            key={path || title}
            title={title}
            checkStrictly={false}
            switcherIcon={<FontAwesomeIcon icon="angle-right" />}
          />
        );
      }
      return routeContainer || [];
    }, []);
  }

  render() {
    const { block, visible, onSubmit, toggleTrigger, appStore } = this.props;

    this.block = block;
    let { expandedKeys } = this;

    const activeSection = toJS(this.activeSection, { recurseEverything: true }).map((s) => s);
    const posTreeNodes = this.renderSelectedTreeItems(
      toJS(appStore.routes, { recurseEverything: true }),
      activeSection
    );
    const renderedTree = this.renderTree(this.items).filter((n) => !!n.props.children && !!n.props.children.length);

    for (const section of toJS(appStore.routes, { recurseEverything: true })) {
      const found = section && section.children && section.children.find((item) => activeSection.includes(item.path));
      if (found) {
        expandedKeys = [...expandedKeys, found.path];
      }
    }

    if (this.search) {
      const searchResultParents = renderedTree.map((el) => el.key);
      expandedKeys = [...expandedKeys, ...toJS(searchResultParents, { recurseEverything: true })];
    }

    return (
      <Drawer
        placement="right"
        width="400"
        closable={false}
        onClose={toggleTrigger && this.toggleDrawer}
        visible={visible}
        getContainer={false}
      >
        <Form layout="vertical" onSubmit={onSubmit || (() => true)}>
          <h3 style={{ fontWeight: 600 }}>{this.block ? 'Labot bloka iestatījumus' : 'Bloku izvēle'}</h3>
          {this.block && (
            <Form.Item label="Iestatīt bloka izmēru">
              <Select
                name="flexSize"
                value={this.flexSize}
                onChange={(v) => {
                  this.flexSize = v;
                }}
              >
                <Option value={1}>1 bloks rindā</Option>
                <Option value={2}>2 bloki rindā</Option>
                <Option value={3}>3 bloki rindā</Option>
              </Select>
            </Form.Item>
          )}
          {!this.block && (
            <Form.Item label="Izvēlieties blokus">
              <Input.Search
                placeholder="Meklēt sadaļu"
                allowClear
                className={[this.search && 'hide-search-icon']}
                onKeyUp={(e) => {
                  if (e.which === 27) {
                    this.search = '';
                    e.target.blur();
                  }
                }}
                onChange={(e) => {
                  this.search = e.target.value;
                }}
                value={this.search}
                onPressEnter={(event) => event.target.blur()}
              />
              <br />
              <Tree
                multiple={!this.block}
                className="fancy-tree"
                selectable
                autoExpandParent
                selectedKeys={this.block ? [this.block] : this.activeSection.filter((el) => typeof el === 'string')}
                // defaultExpandedKeys={
                //   this.block ? [this.block] : this.activeSection.filter((el) => typeof el === 'string')
                // }
                expandedKeys={toJS(expandedKeys, { recurseEverything: true })}
                defaultExpandAll
                switcherIcon={<FontAwesomeIcon icon="angle-down" />}
                onExpand={(_expandedKeys) => {
                  this.expandedKeys = _expandedKeys;
                }}
                onSelect={(active) => {
                  const notNan = !Number.isNaN(active[active.length - 1]);
                  if (active.length && !notNan) {
                    return false;
                  }
                  this.expandedKeys = toJS(active, { recurseEverything: true });
                  if (this.block) {
                    if (!active.length) {
                      active = this.settings.filter((i) => i.path !== this.block).map((i) => i.path);
                    } else {
                      const xactive = [
                        ...new Set([
                          ...this.settings
                            .map((i) => {
                              if (i.path === this.block) {
                                return null;
                              }
                              return i.path;
                            })
                            .filter((i) => i),
                        ]),
                      ];
                      this.block = active.pop();
                      xactive.push(this.block);
                      active = xactive.filter((i) => i);
                    }
                  }
                  this.activeSection = active;
                  return true;
                }}
              >
                {renderedTree}
              </Tree>
              {!this.block && !!toJS(this.activeSection, { recurseEverything: true }).length && (
                <>
                  <h3 style={{ fontWeight: 600 }}>Bloku secība</h3>
                  <Tree
                    draggable
                    className="overview-block-positioner disable-select"
                    selectable={false}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                    onDrop={this.onDrop}
                  >
                    {toJS(this.activeSection, { recurseEverything: true }).map((s) =>
                      posTreeNodes.find((i) => i.key === s)
                    )}
                  </Tree>
                </>
              )}
            </Form.Item>
          )}
          <Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Button onClick={() => toggleTrigger(null)} style={{ marginRight: 8 }}>
                Atcelt
              </Button>
              <Button onClick={this.handleSubmit} type="primary">
                Saglabāt
              </Button>
            </div>
          </Form.Item>
        </Form>
        {!this.block && <OverviewSettingsList store={this} />}
      </Drawer>
    );
  }
}

export default OverviewDrawer;
