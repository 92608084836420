import lat from './lat.json';
import eng from './eng.json';
import rus from './rus.json';

export const dictionaryList = { lat, rus, eng };

export const languageOptions = {
  lat: 'Latviešu',
  rus: 'Русский',
  eng: 'English',
};
