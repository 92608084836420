import React, { useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Field } from './Field';
import { Spinner } from '../addons/components/ButtonWrapComponent';
import api from '../services/api';

const Label = observer(({ value }) => {
    const [name, setName] = useState(null);

    try {
        const data = JSON.parse(value);

        setTimeout(async () => {
            let userId = data.created_by;
            let userObject = data.created_by_object;

            if (data.updated_by && data.updated_by_object) {
                userId = data.updated_by;
                userObject = data.updated_by_object;
            }

            try {
                if (userId && userObject) {
                    const res = await api[`${userObject === `ois` ? `users` : `oksusers`}/${userId}`](); 
                    setName(res.data.fullName);
                } else {
                    setName(<em>Lietotājs nav norādīts</em>);
                }
            } catch (ex) {
                setName(<em>Lietotājs nav atrasts</em>);
            }

        }, 1);

        if (name) {
            return <>{name}</>;
        }
        return <></>;
    } catch (ex) {
        return <em>Lietotājs nav atrasts</em>;
    }
});   

@Field.register
class OwnableNameComponent extends Field {
    static code = 'own-name';

    loadRowTransform({ session_user_name }) {
        return <Label value={session_user_name} />;
    }

    renderComponent({ value }) {
        return <Label value={value} />;
    }
}

export default OwnableNameComponent;
