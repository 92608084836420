import Visits from './Visits';
import Movements from './Movements';
import ShipAgents from './ShipAgents';
import CruisePassengers from './CruisePassengers';
import ShipWaste from './ShipWaste';
import PortPayments from './PortPayments';
import CargoOperations from './CargoOperations';
import CargoDestinations from './CargoDestinations';
import PortsReport from './PortsReport';
import ShippingLineReport from './ShippingLineReport';
import StividorReport from './StividorReport';
import MyStatistics from './MyStatistics';

export default {
  Visits,
  Movements,
  ShipAgents,
  CruisePassengers,
  ShipWaste,
  PortPayments,
  CargoOperations,
  CargoDestinations,
  PortsReport,
  ShippingLineReport,
  StividorReport,
  MyStatistics,
};
