import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { gridColNarrow, gridRow, FormItem, EditFormBase } from '@ois-lib';

@inject('appStore', 'oksNotificationStore')
@observer
class EditNotifications extends EditFormBase {
  title = 'Ziņojums';
  storeName = 'oksNotificationStore';
  readonly = true;
  baseUrl = '/notifications';

  async componentDidMount() {
    this.load(this.props.oksNotificationStore);
  }
  canSave() {
    return false;
  }

  canDelete() {
    return false;
  }
  handleSubmit() {
    return false;
  }
  renderForm() {
    return (
      <>
        <Row {...gridRow} >
          <Card title={this.title} bordered={false}>
            <Col {...gridColNarrow} style={{ textAlign: 'left' }}>
              <FormItem asText name="oksNotificationSenderId" />
              <FormItem asText name="subject" />
              <FormItem asText name="body" hidden={!this.props.form.getFieldValue('body')}/>
              <FormItem asText name="documents" hidden={!this.props.form.getFieldValue('body')}/>
              <div hidden={this.props.form.getFieldValue('body')} style={{ textAlign: 'center' }}>Ziņas termiņš ir beidzies.</div>
            </Col>
          </Card>
        </Row>
      </>
    );
  }
}

export default Form.create({ name: 'EditNotifications' })(EditNotifications);
