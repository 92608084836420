import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import EditLine from './EditLine';

@inject('lineStore', 'authorize', 'can')
@observer
class Lines extends React.Component {
  static Edit = EditLine;

  title = 'Līnijas';

  componentDidMount() {
    const { lineStore } = this.props;
    lineStore.load();
  }

  render() {
    const { lineStore, history } = this.props;

    return (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>
            <DataTable
              store={lineStore}
              title={this.title}
              onRowClick={(row) => history.push(`/planning/lines/${row.id || ''}`)}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Lines);
