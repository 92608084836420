/* eslint-disable eqeqeq */
import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol } from '@ois-lib';
import EditCargo from './EditCargo';

@inject('appStore', 'authorize', 'can')
@observer
class EditCargoConsignment extends EditFormBase {
  static storeName = 'cargoConsignments';

  title = 'Sūtījums';

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    const { store } = this;
    // eslint-disable-next-line eqeqeq
    if (props && props.match && this.props && this.props.match && props.match.params.id != this.props.match.params.id) {
      this.load(store, true, props);
    }
  }

  canSave() {
    return true;
  }

  canDelete() {
    return true;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.cargo', 'edit ship_visits.cargo']);

    const orderNumProps = {};
    if (this.store && this.store.item && this.store.item.id) {
      orderNumProps.asText = true;
    }

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Sūtījums" bordered={false}>
            <FormItem {...orderNumProps} name="orderNum" />
            <FormItem name="loadingPortId" />
            <FormItem name="unloadingPortId" />
            {/*<Row {...gridRow} type="flex">
              <Col {...gridCol2}>
                <Card title="Sūtītājs" size="small" bordered>
                  <FormItem name="consignorName" />
                  <FormItem name="consignorID" />
                  <FormItem name="consignorAddress" />
                  <FormItem name="consignorCountryId" />
                  <FormItem name="consignorContact" />
                </Card>
                <br />
              </Col>
              <Col {...gridCol2}>
                <Card title="Saņēmējs" size="small" bordered>
                  <FormItem name="consigneeName" />
                  <FormItem name="consigneeID" />
                  <FormItem name="consigneeAddress" />
                  <FormItem name="consigneeCountryId" />
                  <FormItem name="consigneeContact" />
                </Card>
                <br />
              </Col>
            </Row>*/}
            <FormItem
              formKind={EditCargo}
              modalProps={{ width: '80%' }}
              // disabled={true}
              name="cargo"
              formItemProps={{ className: 'mt15' }}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditCargoConsignment' })(EditCargoConsignment);
