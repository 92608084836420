import React from 'react';
import { InputNumber } from 'antd';
import { Field } from './Field';

@Field.register
class NumberComponent extends Field {
  static code = 'number';

  renderComponent(props) {
      return <InputNumber {...props} />;
  }
}

export default NumberComponent;
