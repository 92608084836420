import React from 'react';
import { Input } from 'antd';
import { Field } from './Field';

@Field.register
class TextComponent extends Field {
  static code = 'text';

  extraItemProps = {
    hasFeedback: !this.addonAfter,
  };

  renderComponent({ asText, bold, overridechangeevent, extraprops, ...restProps }) {
    if (this.label || asText) {
      const { value } = restProps;
      return bold ? <strong>{value || ' - '}</strong> : value || ' - ';
    }

    const extra = {
      addonAfter: this.addonAfter,
      addonBefore: this.addonBefore,
    };

    const overrideProps = {};
    if (overridechangeevent) {
      overrideProps.onChange = (e) => {
                overridechangeevent(e.target.value, extraprops);
      }
    }

    return <Input {...restProps} {...extra} {...overrideProps} />;
  }
}

export default TextComponent;