import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Table, Tabs, Input, Button, Form } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, gridCol } from '@ois-lib';
import '../dashboard/Dashboard.css';

@inject('appStore', 'authStore', 'companyStore')
@observer
class StividorReport extends EditFormBase {
  title = 'Ikgadējā ostas komersantu darbības rādītāju aptauja';
  storeName = 'companyStore';
  baseUrl = '/';
  colSize = { lg: 24, xl: 18, xxl: 12 };
  tabSizes = {
    detail: { lg: 24, xl: 24, xxl: 21 },
  };

  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
      history,
      companyStore,
      authStore: { user },
    } = props;

    if ([user.id, 'edit'].includes(id)) {
      companyStore.item.id = user.id;
    } else {
      companyStore.item.id = user.id;
      history.push('/statistics/stividorreport');
    }

    this.state = {
      activeKey: 'responder',
      colSize: { lg: 18, xl: 14, xxl: 10 },
    };
  }

  changeActiveKey = (activeKey) => {
    this.setState({ activeKey, colSize: this.tabSizes[activeKey] || { lg: 18, xl: 14, xxl: 10 } });
  };

  async componentDidMount() {
    const { companyStore } = this.props;
    if (companyStore.item && companyStore.item.id) {
      this.loadFresh(companyStore);
    }
  }

  renderForm() {
    // eslint-disable-next-line global-require
    const cargoList = require('./temp.json');
    const { TabPane } = Tabs;
    const { activeKey, colSize } = this.state;
    const dataSource = [
      {
        order: 2,
        title: 'Pārskata informācija',
        key: 'report',
        due: '01.01.2021',
        tables: [
          {
            title: '1. Iebraukuši un izbraukuši pasažieri',
            dataSource: [
              {
                key: '110',
                title: 'Iebraukuši pasažieri',
                code: '110',
              },
              {
                key: '120',
                title: 'Izbraukuši pasažieri',
                code: '120',
              },
            ],
            columns: [
              {
                title: '',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Rindas kods:',
                dataIndex: 'code',
                key: 'code',
              },
              {
                title: 'Pavisam:',
                render: () => <Input type="number" />,
                key: 'total',
              },
              {
                title: 'tai skaitā kruīza pasažieri',
                render: () => <Input type="number" />,
                key: 'cruise',
              },
            ],
          },
          {
            title: '2. Nosūtītas un saņemtas kravas kuģos',
            subtitle: 'Ar precizitāti līdz 0.1 tūkst. t.',
            dataSource: cargoList.map((e) => {
              return {
                title: e.title,
                key: e.rowKey,
                code: e.rowKey,
              };
            }),
            columns: [
              {
                title: '',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Rindas kods:',
                dataIndex: 'code',
                key: 'code',
              },
              {
                title: 'Nosūtītas kravas kuģos, tūkst. t:',
                render: () => <Input type="number" />,
                key: 'sent',
              },
              {
                title: 'Saņemtas kravas kuģos, tūkst. t:',
                render: () => <Input type="number" />,
                key: 'recieved',
              },
              {
                title: 'Pavisam, tūkst. t:',
                render: () => <Input type="number" disabled />,
                key: 'total',
              },
            ],
          },
          {
            title: '3. Plānotais apgrozījums',
            showHeader: false,
            dataSource: [
              {
                title: 'Plānotais kopējais jūras kravu apgrozījums NĀKAMAJĀ mēnesī pēc šī pārskata mēneša (tūkst. t)',
                key: 'planned',
                Input,
                type: 'number',
              },
              {
                title: 'Komentāri par apgrozījuma prognozēm',
                key: 'comment',
                Input: Input.TextArea,
              },
            ],
            columns: [
              {
                dataIndex: 'title',
                key: 'title',
                width: '50%',
              },
              {
                render: (e) => <e.Input type={e.type || ''} />,
                key: 'total',
              },
            ],
          },
          {
            title: '4. Papildus informācija',
            showHeader: false,
            dataSource: [
              {
                title: 'No ostas uz ārzemēm izejošo kuģu skaits pārskata mēnesī',
                key: 'export',
              },
              {
                title: 'Lūdzu norādiet, cik laika Jūs patērējāt šā pārskata aizpildīšanai (minūtes)',
                key: 'minutes',
              },
            ],
            columns: [
              {
                dataIndex: 'title',
                key: 'title',
              },
              {
                render: () => <Input type="number" />,
                key: 'input',
              },
            ],
          },
        ],
      },
      {
        order: 3,
        title: 'Pārskata detalizācija',
        key: 'detail',
        due: '01.01.2021',
        className: 'largeTable',
        tables: [
          {
            title: '1. Nosūtītās kravas kuģos',
            dataSource: [
              {
                title: 'Naftas produkti',
                code: '4020',
                key: '4020',
              },
            ],
            columns: [
              {
                title: '',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Rindas kods:',
                key: 'code',
                dataIndex: 'code',
              },
              {
                title: 'Kopā nosūtīts ar kuģiem (tūkst.t):',
                key: 'sent',
                render: () => <Input />,
              },
              {
                title: 'Kuģos nosūtīto kravu sadalījums pēc to saņemšanas veida ostā',
                key: 'sentByType',
                children: [
                  {
                    title: 'Saņemts ar kuģiem (% no kopējā nosūtītā apjoma):',
                    key: 'recievedByShip',
                    render: () => <Input />,
                  },
                  {
                    title: 'Saņemts ar autotransportu vai pa dzelzceļu no šādām valstīm (% no kopējā nosūtītā apjoma)',
                    key: 'recievedByCountry',
                    children: [
                      {
                        title: 'LAT:',
                        key: 'LAT',
                        render: () => <Input />,
                      },
                      {
                        title: 'RUS:',
                        key: 'RUS',
                        render: () => <Input />,
                      },
                      {
                        title: 'BLR:',
                        key: 'BLR',
                        render: () => <Input />,
                      },
                      {
                        title: 'LIT:',
                        key: 'LIT',
                        render: () => <Input />,
                      },
                      {
                        title: 'EST:',
                        key: 'EST',
                        render: () => <Input />,
                      },
                      {
                        title: 'UKR:',
                        key: 'UKR',
                        render: () => <Input />,
                      },
                      {
                        title: 'KAZ:',
                        key: 'KAZ',
                        render: () => <Input />,
                      },
                      {
                        title: 'UZB:',
                        key: 'UZB',
                        render: () => <Input />,
                      },
                      {
                        title: 'CHN:',
                        key: 'CHN',
                        render: () => <Input />,
                      },
                      {
                        title: 'Citas:',
                        key: 'other',
                        render: () => <Input />,
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Kopā:',
                key: 'total',
                render: () => <Input />,
              },
              {
                title: 'Pa dzelzceļu saņemtās kravas (% no kopējā nosūtītā apjoma):',
                key: 'railroad',
                render: () => <Input />,
              },
            ],
          },
          {
            title: '2. Saņemtās kravas kuģos',
            dataSource: [
              {
                title: 'Naftas produkti',
                code: '4020',
                key: '4020',
              },
            ],
            columns: [
              {
                title: '',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Rindas kods:',
                key: 'code',
                dataIndex: 'code',
              },
              {
                title: 'Kopā saņemts ar kuģiem (tūkst.t):',
                key: 'sent',
                render: () => <Input />,
              },
              {
                title: 'Kuģos saņemto kravu sadalījums pēc to tālāk nosūtīšanas veida no ostas',
                key: 'sentByType',
                children: [
                  {
                    title: 'Nosūtīts ar kuģiem (% no kopējā nosūtītā apjoma):',
                    key: 'recievedByShip',
                    render: () => <Input />,
                  },
                  {
                    title: 'Nosūtīts ar autotransportu vai pa dzelzceļu no šādām valstīm (% no kopējā nosūtītā apjoma)',
                    key: 'recievedByCountry',
                    children: [
                      {
                        title: 'LAT:',
                        key: 'LAT',
                        render: () => <Input />,
                      },
                      {
                        title: 'RUS:',
                        key: 'RUS',
                        render: () => <Input />,
                      },
                      {
                        title: 'BLR:',
                        key: 'BLR',
                        render: () => <Input />,
                      },
                      {
                        title: 'LIT:',
                        key: 'LIT',
                        render: () => <Input />,
                      },
                      {
                        title: 'EST:',
                        key: 'EST',
                        render: () => <Input />,
                      },
                      {
                        title: 'UKR:',
                        key: 'UKR',
                        render: () => <Input />,
                      },
                      {
                        title: 'KAZ:',
                        key: 'KAZ',
                        render: () => <Input />,
                      },
                      {
                        title: 'UZB:',
                        key: 'UZB',
                        render: () => <Input />,
                      },
                      {
                        title: 'CHN:',
                        key: 'CHN',
                        render: () => <Input />,
                      },
                      {
                        title: 'Citas:',
                        key: 'other',
                        render: () => <Input />,
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Kopā:',
                key: 'total',
                render: () => <Input />,
              },
              {
                title: 'Pa dzelzceļu nosūtītās kravas (% no kopējā nosūtītā apjoma):',
                key: 'railroad',
                render: () => <Input />,
              },
            ],
          },
        ],
      },
    ];

    const tabPanes = dataSource.map((e, i) => {
      const { title, key, ...props } = e;
      const tables = e.tables
        ? e.tables.map((el, index) => {
            const { subtitle, columns, note, ...props2 } = el;
            return (
              <span key={index}>
                {el.title ? <h3>{el.title}</h3> : ''}
                {subtitle ? <p>{subtitle}</p> : ''}
                <Table dataSource={el.dataSource} columns={columns} pagination={false} {...props2} />
                <p>{note || ''}&nbsp;</p>
              </span>
            );
          })
        : '';
      const last = dataSource.length === i + 1;
      return (
        <TabPane tab={e.title} key={e.key} forceRender {...props}>
          <h2>{e.title}</h2>
          {tables}
          <Button
            onClick={() => (last ? '' : this.changeActiveKey(dataSource[i + 1].key))}
            className={'styledButton moveRight'.concat(last ? '' : ' nextButton')}
          >
            <span>{last ? 'Iesniegt' : 'Tālāk'}&nbsp;</span>
          </Button>
        </TabPane>
      );
    });

    const {
      companyStore: { item },
    } = this.props;
    const { user } = this.props.authStore;

    return (
      <>
        <Row {...gridRow}>
          <Col {...gridCol} xs={24} lg={colSize.lg} xl={colSize.xl} xxl={colSize.xxl}>
            <h1 className="cabinet_title">{`${this.title}`}</h1>
          </Col>
        </Row>
        <Row {...gridRow}>
          <Col {...gridCol} xs={24} lg={colSize.lg} xl={colSize.xl} xxl={colSize.xxl}>
            <Tabs
              type="card"
              className="content-tabs"
              activeKey={activeKey}
              onChange={(key) => this.changeActiveKey(key)}
            >
              <TabPane tab="Respondenta informācija" key="responder" forceRender>
                <h2>Respondenta informācija</h2>
                <h3>1. Respondents</h3>
                <Row>
                  <Col span={8} style={{ textAlign: 'right', paddingRight: '1rem', lineHeight: '200%' }}>
                    Nosaukums:
                    <br />
                    PVN numurs:
                    <br />
                    Faktiskā adrese:
                    <br />
                    Pilsēta:
                    <br />
                    Pasta indekss:
                    <br />
                    Tālruņa nr.:
                    <br />
                    Faksa nr.:
                    <br />
                    E-pasts:
                    <br />
                  </Col>
                  <Col span={16} style={{ lineHeight: '200%' }}>
                    {item.name}
                    <br />
                    {item.vatNr}
                    <br />
                    {item.street}
                    <br />
                    {item.city}
                    <br />
                    {item.zip}
                    <br />
                    {item.phone}
                    <br />
                    {item.fax}
                    <br />
                    {item.email}
                    <br />
                  </Col>
                </Row>
                <p>&nbsp;</p>
                <h3>2. Anketas aizpildītājs</h3>
                <Row>
                  <Col span={8} style={{ textAlign: 'right', paddingRight: '1rem', lineHeight: '200%' }}>
                    Vārds, Uzvārds:
                    <br />
                    Tālruņa numurs:
                    <br />
                    E-pasta adrese:
                    <br />
                  </Col>
                  <Col span={16} style={{ lineHeight: '200%' }}>
                    {(user && user.fullName) || ''}
                    <br />
                    {(user && user.phone) || ''}
                    <br />
                    {(user && user.email) || ''}
                    <br />
                  </Col>
                </Row>
                <p>&nbsp;</p>
                <Button onClick={() => this.changeActiveKey('report')} className="styledButton moveRight nextButton">
                  <span>Tālāk&nbsp;</span>
                </Button>
              </TabPane>
              {tabPanes}
            </Tabs>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'StividorReport' })(StividorReport));
