import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Dropdown, Menu, Modal, Select, Tag, message } from 'antd';
import StoreFactory from '@ois-lib/stores';
import { getUri } from '../helpers/url';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';

const { Option, OptGroup } = Select;

@withRouter
@inject('appStore', 'authStore', 'can')
@observer
class HelpLinkWrapper extends React.Component {
  store = null;
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedLink: null,
      isDirty: false,
    };

    this.store = StoreFactory.getStore(`helpGroups`);
  }

  componentDidMount() {
    this.store.loadFull();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    //
  }

  //   handleOnHover() {}

  get selectedLink() {
    const { appStore } = this.props;
    const { path } = appStore.routerMatch || {};
    const { selectedLink } = this.state;

    if (this?.store?._dataFull && Array.isArray(this.store._dataFull)) {
      for (const item of this.store._dataFull) {
        if (item.children) {
          const link = item.children.find((c) => !!c.linkedToPath && c.linkedToPath === path);
          if (link && link.id) {
            const itemLink = `/help/${link.key}?sysGroup=${link.sysGroup}`;
            if (selectedLink !== itemLink) {
              setTimeout(() =>
                this.setState({
                  selectedLink: itemLink,
                })
              );
            }

            return itemLink;
          }
        }
      }
    }

    return null;
  }

  get activeId() {
    const { appStore } = this.props;
    const { selectedLink } = this.state;
    const { path } = appStore.routerMatch || {};

    return (
      this.store._dataFull &&
      this.store._dataFull.reduce((curr, aggr) => {
        if (aggr.children) {
          const item = aggr.children.find((c) => !!c.linkedToPath && c.linkedToPath === path);
          if (item && item.id) {
            const itemLink = `/help/${item.key}?sysGroup=${item.sysGroup}`;
            if (selectedLink !== itemLink) {
              setTimeout(() =>
                this.setState({
                  selectedLink: itemLink,
                })
              );
            }
            return item.id;
          }
        }
        return curr;
      }, null)
    );
  }

  render() {
    const { appStore, authStore, can, defaultLink } = this.props;
    if (!authStore.loggedIn) {
      return null;
    }
    const { path } = appStore.routerMatch || {};
    const { modal, helpContentId, isDirty } = this.state;
    const selectedItem = isDirty ? helpContentId  : helpContentId || this.activeId;
    const viewHelpText = this.selectedLink ? 'Skatīt palīdzību' : 'Saite uz lietotāja palīgu nav iestatīta';

    const menu = (
      <Menu>
        <Menu.Item disabled={!this.selectedLink}>
          <Link disabled={!this.selectedLink} to={this.selectedLink || '/'}>
            {this.store.loadingFull ? 'Notiek datu ielāde...' : viewHelpText}
          </Link>
        </Menu.Item>
        {can(['edit help']) && !this.store.loadingFull && (
          <Menu.Item onClick={() => this.setState({ modal: true })}>Labot saiti uz palīdzību</Menu.Item>
        )}
      </Menu>
    );

    return (
      <>
        {
          !this.store.loadingFull && (can(['edit help']) || !!this.selectedLink) && (
          <Dropdown overlay={menu}>
            <FontAwesomeIcon icon="question-circle" className="icon-check" />
          </Dropdown>
          )
        }
        <Modal
          title="Labot saiti uz palīdzību"
          visible={modal}
          onOk={async () => {
            if (path) {
              const ret = await this.store.call('setLink', { path }, selectedItem || 'reset');
              if (ret && ret.data) {
                if (ret.data === 'ok') {
                  message.success(`Saite veiksmīgi piesaistīta palīga saturam!`);
                  this.setState({ selectedLink: defaultLink || undefined, modal: false }, async () => {
                  await this.store.refreshFull();
                  });
                } else {
                  message.error(ret.data.error);
                }
              }
            }
          }}
          onCancel={() => this.setState({ modal: false, selectedLink: undefined, isDirty: false })}
          okText="Saglabāt"
          cancelText="Atcelt"
          style={{ minHeight: 620 }}
        >
          <label htmlFor="tag">
            Palīgam piesaistītā saite: <Tag color="cyan">{getUri() + path}</Tag>
          </label>
          <br />
          <br />
          <Select
            placeholder="Izvēlieties palīga saturu"
            style={{ width: '100%' }}
            onChange={(id) => this.setState({ helpContentId: id, isDirty: true })}
            allowClear
            showSearch
            optionFilterProp="children"
            value={selectedItem || undefined}
          >
            {this.store._dataFull.map((option) => (
              <OptGroup key={option.key} label={option.name}>
                {option.children.map((child) => (
                  <Option key={child.key} value={child.id} /* disabled={child.linkedToPath} */>
                    {child.name}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Modal>
      </>
    );
  }
}

export default HelpLinkWrapper;
