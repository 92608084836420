/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Spin } from 'antd';
import FilesPopover from './FilesPopover';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';

export const FilesList = ({ files, allowDelete, asText, deleteCallback = () => true, parent }) => {
  const isUploading = files && files.find((f) => f.status === 'uploading');
  const hasFilesWithError = files && files.find((f) => f.status === 'uploading');

  return (
    <>
      <div className="file-list-wrapper">
        <Spin spinning={!!isUploading} tip="Notiek failu augšupielāde...">
          <ul className="files-list">
            {files &&
              files.map((f, i) => (
                <li key={i}>
                  <a
                    href={!['uploading', 'error'].includes(f.status) ? f.url : '#'}
                    title={f.name}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="paperclip" />
                    &nbsp;
                    <span style={f.status === 'error' ? { color: '#f5222d', opacity: 0.85 } : undefined}>{f.name}</span>
                    &nbsp;
                    {((['done'].includes(f.status) || !f.status) && <FontAwesomeIcon icon="download" />) ||
                      (['error'].includes(f.status) && <FontAwesomeIcon icon="exclamation-circle" />)}
                    {allowDelete && !asText && (
                      <FontAwesomeIcon
                        icon="trash"
                        guid={f.guid}
                        onClick={(e) => {
                          e.preventDefault();
                          files = files.filter((fx) => {
                            if (fx.guid && f.guid) {
                              return fx.guid !== f.guid;
                            }
                            return fx.uid !== f.uid;
                          });
                          if (parent.uploadRef) {
                            parent.uploadRef.handleRemove(f);
                          }
                          deleteCallback(files /* , deteledFile */);
                        }}
                      />
                    )}
                  </a>
                </li>
              ))}
          </ul>
        </Spin>
      </div>
      {files && !!files.length && (
        <Button
          className="dl-all-btn"
          style={{ marginBottom: '25px' }}
          href={`/api/v1/download_all/${files.map((m) => m.guid).join(',')}`}
          target="_blank"
          disabled={isUploading || hasFilesWithError}
          // download
        >
          Lejupielādēt visus
        </Button>
      )}
    </>
  );
};

const FilesRenderer = (files) =>
  (files && files.props && files.props.value && files.props.value.length && (
    <FilesPopover
      placement="bottomRight"
      title={`Faili (${files.props.value.length})`}
      content={<FilesList files={files.props.value} />}
    >
      <Button size="small">
        <FontAwesomeIcon icon="paperclip" />
        {files.props.value.length}
        &nbsp;&nbsp;
        <FontAwesomeIcon icon="caret-down" />
      </Button>
    </FilesPopover>
  )) ||
  '';

export default FilesRenderer;
