import ButtonGroup from 'antd/lib/button/button-group';
import currency from 'currency.js';
import React from 'react';
import { Tooltip, Button, Popover } from 'antd';
import moment from 'moment-timezone';
import FontAwesomeIcon from '@ois-lib/assets/icon/FontAwesomeIcon';
import StoreFactory from './index';
import Validate from '../validate';
import { getUri } from '../helpers/url';
import ShipInfoComponent from '../components/ShipInfo';
// import { MapLabelComponent } from '@ois-lib/fields/MapComponent';
import api from "../services/api";

const currencyOpts = {
  separator: ' ',
  decimal: ',',
  formatWithSymbol: false,
  symbol: '',
};

export const isShip2Fat = (ship, berth, movement = {}) => {
  const techData = Array.isArray(berth.berthTechnicalData) && berth.berthTechnicalData.length && berth.berthTechnicalData[0];

  if (!techData) {
    return false;
  }

  try {

    const max = {
      length: null,
      draft: null,
    };

    if (techData.maxShipLength) {
      max.length = parseFloat(techData.maxShipLength.replace(`,`, `.`));
    }

    if (techData.maxShipDraft) {
      max.draft = parseFloat(techData.maxShipDraft.replace(`,`, `.`));
    }

    const contender = {
      length: null,
      draft: null,
    };

    if (ship.length) {
      contender.length = parseFloat(ship.length.replace(`,`, `.`))
    }

    if (movement.paramDraftAtStern || ship.draftAtStern) {
      contender.draft = parseFloat((movement.paramDraftAtStern ?? ship.draftAtStern).replace(`,`, `.`));
    }

    // console.log({ ...ship }, { ...berth }, movement?.paramDraftAtStern, max, contender);
    return (max.length && contender.length && max.length <= contender.length) || (max.draft && contender.draft && max.draft <= contender.draft);

  } catch (e) {
    return false;
  }
}

const shipVisitOptionRenderer = (option, row) => {
  const eta = option.eta || (row.ship_visit && row.ship_visit.eta);
  const fields = [eta ? moment(eta).format() : ' - ', option.value ?? option.shipNameIn];
  // const fields = [option.eta ? moment(option.eta).format() : ' - ', option.value];
  if (row && row.id && option.key === row.id) {
    return null;
  }
  return (
    <div style={{ display: 'table' }}>
      {fields.map((f, i) => (
        <div
          key={i}
          style={{
            display: 'table-cell',
            width: i === fields.length - 1 ? null : '150px',
          }}
        >
          {f}
        </div>
      ))}
    </div>
  );
};

const shipOptionRenderer = (options, row) => {
  let fields = '';
  if (options.imo || options.mmsi)
    fields = [options.name, 'IMO: ' + (options.imo ?? '-'), 'MMSI: ' + (options.mmsi ?? '-')];
  if (row.ship) fields = [row.ship.name, 'IMO: ' + (row.ship.imo ?? '-'), 'MMSI: ' + (row.ship.mmsi ?? '-')];
  if (fields != '') {
    return (
      <div style={{ display: 'table' }}>
        {fields.map((f, i) => (
          <div
            key={i}
            style={{
              display: 'table-cell',
              width: i === fields.length - 1 ? null : '150px',
            }}
          >
            {f}
          </div>
        ))}
      </div>
    );
  }
};

// this.modal = appStore.createModal('smallShipMovement', Terminals.Edit, {
//   canEdit: false, true,//props.can(['edit small.movement']),
//   modalProps: { width: '860px' },
// });

// const findValue = (key, obj, defaultValue = null) => {
//   let currentItem = obj;
//   for (const currentKey of key.split('.')) {
//     if (typeof currentItem === 'object' && currentKey in currentItem) {
//       currentItem = currentItem[currentKey];
//     } else {
//       return defaultValue;
//     }
//   }
//   return currentItem;
// };

async function fetchshipVisitPurposeList(){
  const response = await api['shipVisitPurposesSelect']();
  if (response && response.length ) {
    return response;
  } else {
    return [];
  }
}

async function fetchShippingLinesList(){
    const response = await api['shippingLinesSelect']();
    if (response && response.length ) {
        return response;
    } else {
        return [];
    }
}

async function fetchFuelTypeList(){
    const response = await api['fuelTypesSelect']();
    if (response && response.length ) {
        return response;
    } else {
        return [];
    }
}
async function fetchStoredTypeOfActionsList(){
    const response = await api['storedTypeOfActionsSelect']();
    if (response && response.length ) {
        return response;
    } else {
        return [];
    }
}

async function fetchStoredCompanyTagsList(){
    const response = await api['storedCompanyTagsSelect']();
    if (response && response.length ) {
        return response;
    } else {
        return [];
    }
}

// Possibly need to refactor and move each definition to a route
const columnDefinitions = (fieldSpec = {}) => ({
  shipVisits: [
    {
      title: 'SKLOIS Id',
      key: 'skloisVisitCode',
      type: 'text',
      hide: true,
    },
    {
      title: 'Nr.(ienākot)',
      key: 'nrIn',
      type: 'text',
      hide: true,
    },
    {
      title: 'Nr.(izejot)',
      key: 'nrOut',
      type: 'text',
      hide: true,
      // render(val, row,...args) {
      // return row && row.id ? `2020/${row.nrIn}` : '-';
      // },
    },

    {
      title: 'Indikatori',
      key: '_indicators',
      type: 'indicator',
      sorter: false,
      filter: false,
    },
    {
      title: 'Atļaujas',
      key: '_alert_permit_indicators',
      type: 'indicator',
      columnRenderMethod: 'renderPermitColumn',
      sorter: false,
      filter: false,
    },
    {
        title: 'Locis',
        key: '_t_currentPilotId',
        hide: true,
        sorter: false,
        extraFieldProps: {
            showSearch: true,
            optionFilterProp: 'children',
            allowClear: true,
        },
    },
      {
          title: 'Kustības sākuma laiks',
          key: '_t_currentMovementStart',
          hide: true,
          sorter: false,
          extraFieldProps: {
              showSearch: true,
              optionFilterProp: 'children',
              allowClear: true,
          },
      },
      {
          title: 'Velkonis',
          key: '_t_currentTugboatId',
          hide: true,
          sorter: false,
          extraFieldProps: {
              showSearch: true,
              optionFilterProp: 'children',
              allowClear: true,
          },
      },
    // Ienākšana
    // {
    //   title: 'Kuģa nosaukums (ienākot)',
    //   type: 'text',
    //   key: 'shipNameIn',
    //   hide: true,
    // },
    {
      title: 'Piestātne',
      type: 'select',
      key: 'currentBerthId',
      optionSource: 'berths',
      // hide: true,
      optionKeyName: 'id',
      optionValueName: '_t_currentBerthId',
      render: (v, row) => {
        // _t_currentBerthId more reliable, until currentBerth fixed in ES & backend.
        return <span>{row._t_currentBerthId ? row._t_currentBerthId + '' : row.currentBerth?.name ? row.currentBerth.name : (row.toBerth?.name && row.status === "EXPECTED" ? row.toBerth.name : v)}</span>
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      // sorter: false,
    },
    {
      title: 'Kuģis',
      tooltip: 'Kuģis ienākot ostā',
      key: 'shipInId',
      type: 'select',
      render: (v, row) => {
        const ship =
          row._indicators && row._indicators.ships && (row._indicators.ships.in || row._indicators.ships.out);
        const value = typeof row.shipInId === 'number' ? row.shipInId : row['_orig.shipInId'];
        if (!ship) return value;

        if (!('name' in ship) && ship.value) {
          ship.name = ship.value;
          delete ship.value;
        }

        if (!('id' in ship) && ship.key) {
          ship.id = ship.key;
          delete ship.key;
        }

        const availableShipCols = [
          'id',
          'name',
          'imo',
          'shipTypeId',
          'mmsi',
          'callsign',
          'grossWeight',
          'deadweight',
          'iceClass',
          'enginePower',
          'length',
          'width',
          'draft',
          'countryId',
        ];

        return (
          <Popover
            content={
              <ShipInfoComponent
                columns={availableShipCols}
                values={ship}
                shipId={value}
                fieldSpec={fieldSpec}
                keepColumnOrder
              />
            }
            title="Kuģis"
            trigger="hover"
            placement="rightTop"
            arrowPointAtCenter
          >
            <span style={{ display: 'inline-block' }}>{row._t_shipInId ? row._t_shipInId : row.shipNameIn ? row.shipNameIn : v}</span>
          </Popover>
        );
      },
      keepOriginalValue: true,
      optionSource: 'ships',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionFilters: {
        "[and]": [
           { "utilized": ["false"] },
        ]
      },
      // filter: false,
    },
    // Iziešana
    // {
    //   title: 'Kuģa nosaukums (izejot)',
    //   type: 'text',
    //   key: 'shipNameOut',
    //   hide: true,
    // },
    {
      title: 'Kuģis izejot no ostas',
      key: 'shipOutId',
      type: 'select',
      hide: true,
      // filter: false,
      render: (v, row) => {
        return <span>{row._t_shipInId ? row._t_shipInId : row.shipNameOut ? row.shipNameOut : v}</span>
      },
      optionSource: 'ships',
      optionFilters: {
          "[and]": [
              { "utilized": ["false"] },
          ]
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    //
    {
      title: 'No ostas',
      key: 'fromPort',
      optionSource: 'ports',
      type: 'select',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      render: (v, row) => {
        return <span>{v ? v : row?._fromPort?.name}</span>
      },
    },
    {
      title: 'Uz ostu',
      key: 'toPort',
      type: 'select',
      optionSource: 'ports',
      hide: true,
      render: (v, row) => {
        return <span>{v ? v : row?._toPort?.name}</span>
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'No piestātnes',
      key: 'fromBerthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      render: (v, row) => {
        return <span>{row.fromBerth?.name ? row.fromBerth.name : v}</span>
      },
      // optionsSortFunc: (a, b) => a.value ?  a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null,
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Uz piestātni',
      key: 'toBerthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      render: (v, row) => {
        return <span>{row.toBerth?.name ? row.toBerth.name : v}</span>
      },
      // fieldAddons: [
      //   {
      //     title: 'Operatīvās piezīmes',
      //     type: 'operationalNotesPopover',
      //     optionSource: 'operationalNotes',
      //     formStore: 'EditBerth_operationalNotes',
      //     filters: { resourceType: ['BERTH'] },
      //   },
      // ],
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionFilters: {
        isOperating: [true],
      }
    },
    {
      title: 'ETA', // ETA
      key: 'eta',
      type: 'date',
      time: true,
      defaultSortOrder: 'desc',
    },
    {
      title: 'ETD', // ATD
      key: 'etd',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'ATA', // ETA
      key: 'ata',
      type: 'date',
      hide: true,
      time: true,
    },
    {
      title: 'ATD', // ATD
      key: 'atd',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'Kuģi velk',
      key: 'tugInShipVisitId',
      type: 'select',
      hide: true,
      optionKeyName: 'id',
      optionValueName: '_t_shipInId',
      optionSource: 'shipVisits',
      optionFilters: {
        // '_indicators.ships.out.shipTypeId': [3000005, 42], // 42 for production
        eta: true,
        // atd: null,
      },
      renderOption: shipVisitOptionRenderer,
      // hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
        allowClear: true,
      },
    },
    {
      title: 'Vilcējkuģa vizīte (izejot)',
      key: 'tugOutShipVisitId',
      type: 'select',
      optionKeyName: 'id',
      optionValueName: '_t_shipInId',
      optionSource: 'shipVisits',
      hide: true,
      optionFilters: {
        ata: true,
        atd: null,
      },
      renderOption: shipVisitOptionRenderer,
      // hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
        allowClear: true,
      },
    },
    // {
    //   title: 'Mērķis',
    //   key: 'shipVisitPurpose',
    //   type: 'select',
    //   optionSource: 'shipVisitPurposes',
    //   hide: true,
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: 'children',
    //   },
    // },
    {
      title: 'Mērķi',
      key: 'shipVisitPurposes',
      type: 'multiselect',
      extraFieldProps: {
        optionGroup: true,
        optionGroupParent: 'shipVisitPurposeId',
      },
      optionKeyName: 'id',
      optionValueName: 'name',
      optionSource: 'shipVisitPurposes',
      noTable: true,
    },
    {
      title: 'Mērķi',
      key: '_t_shipVisitPurposes',
      type: 'select',
      optionKeyName: 'id',
      optionValueName: 'name',
      label: true,
      selectSearch: true,
      options: fetchshipVisitPurposeList,
    },
    {
      title: 'Aģents',
      key: 'shipAgentId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      render: (v, row) => {
        const sd = [
          { t: 'E-pasts', v: row.shipAgent ? row.shipAgent.email : '' },
          // { t: 'Kontaktpersona', v: row.shipAgent ? row.shipAgent.contactPerson : ''},
          { t: 'Tālrunis', v: row.shipAgent ? row.shipAgent.phone : '' },
        ];
        return (
          <span>
            <Tooltip
              key="shipInfo"
              placement="right"
              title={
                <small>
                  <table>
                    <tbody>
                      {sd.map((d, key) => (
                        <tr key={key}>
                          <td className="mb0">{`${d.t}:`}</td>
                          <td>
                            {' '}
                            <b>{d.v}</b>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </small>
              }
            >
              {v}
            </Tooltip>
          </span>
        );
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Aģenta telefons',
      key: 'shipAgentPhone',
      hide: true,
    },
    {
      title: 'Pārstāvis',
      key: 'shipRepresentativeId',
      type: 'select',
      optionSource: 'companyRepresentatives',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Pārstāvja telefons',
      key: 'shipRepresentativePhone',
      hide: true,
    },
    // Mērķis
    {
      title: 'Operators',
      key: 'operator',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Saskaņā ar grafiku',
      key: 'onSchedule',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Fraktētājs',
      key: 'charterer',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // Kuģu atkritumi
    {
      title: 'Kuģu atkritumi',
      key: 'waste',
      type: 'table',
      // async: true,
      // optionSource: 'wasteDisposals',
      // optionValueKey: 'shipVisitId',
      extraFieldProps: {
        forcedFiltersOptions: {
          columnKey: 'direction',
          defaultOptionValue: 'IN',
          options: [
            { key: 'IN', value: 'Ienākot' },
            { key: 'OUT', value: 'Izejot' },
          ],
        },
      },
      noTable: true,
    },
    {
      title: 'Pēdējā atkritumu nodošanas osta',
      key: 'wDPreviousPort',
      type: 'select',
      optionSource: 'ports',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Pēdējās atkritumu nodošanas laiks',
      key: 'wDPreviousPortTime',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'Nākamā atkritumu nodošanas osta',
      key: 'wDNextPort',
      type: 'select',
      optionSource: 'ports',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Nododamais atkritumu daudzums',
      key: 'wDDisposalAmount',
      type: 'radio', // radio (check Velkonis)
      hide: true,
      options: [
        { key: 'NONE', value: 'Nemaz' },
        { key: 'PARTIAL', value: 'Daļa' },
        { key: 'ALL', value: 'Visi' },
      ],
    },
    {
      title: 'Nepieteikošas atsūknēšanas jaudas',
      key: 'wDInsufficientPower',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Dati salīdzināti ar deklarāciju',
      key: 'wDDataCompared',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Deklarācijas datums',
      key: 'wDDeclarationDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Līnija ienākot',
      key: 'inShippingLineId',
      type: 'select',
      hide: true,
      sorter: false,
      optionSource: 'shippingLines',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
      optionFilters: {
        isOperating: [true],
      },
      options: fetchShippingLinesList
    },
    {
      title: 'Līnija izejot',
      key: 'outShippingLineId',
      type: 'select',
      hide: true,
      sorter: false,
      optionSource: 'shippingLines',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
      optionFilters: {
        isOperating: [true],
      },
      options: fetchShippingLinesList
    },
    {
      title: 'Kravas apraksts (ienākot)',
      key: 'cargoDescriptionIn',
      hide: true,
      // noTable: true,
    },
    {
      title: 'Manifesta svars ienākot',
      key: 'manifesto_weight_in',
      hide: true,
      // noTable: true,
    },
    {
      title: 'Manifesta svars izejot',
      key: 'manifesto_weight_out',
      hide: true,
      // noTable: true,
    },
    {
      title: 'Kravas apraksts (izejot)',
      key: 'cargoDescriptionOut',
      hide: true,
      // noTable: true,
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Apkalpes locekļi',
      key: 'crewMembers',
      type: 'table',
      // async: true,
      // optionSource: 'crewMembers',
      // optionValueKey: 'shipVisitId',
      extraFieldProps: {
        forcedFiltersOptions: {
          columnKey: 'direction',
          defaultOptionValue: 'IN',
          options: [
            { key: 'IN', value: 'Ienākot' },
            { key: 'OUT', value: 'Izejot' },
          ],
        },
      },
      noTable: true,
    },
    {
      title: 'Pasažieri',
      key: 'passengers',
      type: 'table',
      async: true,
      optionSource: 'passengers',
      optionValueKey: 'shipVisitId',
      extraFieldProps: {
        forcedFiltersOptions: {
          columnKey: 'direction',
          defaultOptionValue: 'IN',
          options: [
            { key: 'IN', value: 'Ienākot' },
            { key: 'OUT', value: 'Izejot' },
          ],
        },
      },
      noTable: true,
    },
    {
      title: 'Kravas',
      key: 'cargoConsignments',
      type: 'table',
      async: true,
      optionSource: 'cargoConsignments',
      optionValueKey: 'shipVisitId',
      extraFieldProps: {
        forcedFilters: { isDangerous: null },
        forcedFiltersOptions: {
          columnKey: 'direction',
          defaultOptionValue: 'IN',
          options: [
            { key: 'IN', value: 'Ienākot' },
            { key: 'OUT', value: 'Izejot' },
          ],
        },
      },
      noTable: true,
    },
    {
      title: 'Bīstamās kravas',
      key: 'dangerousCargoConsignments',
      type: 'table',
      // async: true,
      // optionSource: 'cargoConsignments',
      // optionValueKey: 'shipVisitId',
      noTable: true,
    },
    {
      title: 'Salīdzināts ar kravas manifestu',
      key: 'comparedWithCargoManifesto',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Dokumenti',
      key: 'documents',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Kuģu krājumi',
      key: 'stores',
      type: 'table',
      // async: true,
      // optionSource: 'shipsStores',
      // optionValueKey: 'shipVisitId',
      extraFieldProps: {
        forcedFiltersOptions: {
          columnKey: 'direction',
          defaultOptionValue: 'IN',
          options: [
            { key: 'IN', value: 'Ienākot' },
            { key: 'OUT', value: 'Izejot' },
          ],
        },
      },
      noTable: true,
    },
    {
      title: 'Kustības',
      key: 'movement',
      type: 'table',
      // async: true,
      // optionSource: 'movements',
      // optionValueKey: 'shipVisitId',
      noTable: true,
    },
    // {
    //   title: 'Loču kvītis',
    //   key: 'pilotBills',
    //   type: 'table',
    //   // async: true,
    //   // optionSource: 'pilotBills',
    //   // optionValueKey: 'shipVisitId',
    //   noTable: true,
    // },
    {
      title: 'Piestātņu apmeklējumi',
      key: 'berthVisits',
      type: 'table',
      // async: true,
      // optionSource: 'berthVisits',
      // optionValueKey: 'shipVisitId',
      noTable: true,
    },
    {
      title: 'Darbības',
      key: 'shipOperations',
      type: 'table',
      // async: true,
      // optionSource: 'shipOperations',
      // optionValueKey: 'shipVisitId',
      noTable: true,
    },
    {
      title: 'Resursu pieteikumi',
      key: 'resourceRequests',
      type: 'table',
      // async: true,
      // optionSource: 'resourceRequests',
      // optionValueKey: 'shipVisitId',
      noTable: true,
    },
    {
      title: 'Resursu norīkojumi',
      key: 'resourceTasks',
      type: 'table',
      // async: true,
      // optionSource: 'resourceTasks',
      // optionValueKey: 'shipVisitId',
      noTable: true,
    },
    {
      title: 'Operatīvās piezīmes',
      key: 'operationalNotes',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Komentārs (ienākšana)',
      key: '_shipVisitPermitCommentIn',
      type: 'textarea',
      noTable: true,
    },
    {
      title: 'Komentārs (iziešana)',
      key: '_shipVisitPermitCommentOut',
      type: 'textarea',
      noTable: true,
    },
    // {
    //   title: "Komentāra iesniegšanas statuss (ienākšana)",
    //   key: "shipVisitPermitStatusIn",
    //   // type: "label",
    //   noTable: true,
    //   hide: true
    // },
    // {
    //   title: "Komentāra iesniegšanas statuss (iziešana)",
    //   key: "shipVisitPermitStatusOut",
    //   // type: "label",
    //   noTable: true,
    //   hide: true
    // },
    {
      title: 'Ienākšana',
      key: 'incomingPermits',
      type: 'list',
      optionValueName: 'code',
      optionSource: 'controlOrganizations',
      filterFunc: (item) => !item.scope || item.scope !== 'OUT',
      noTable: true,
    },
    {
      title: 'Iziešana',
      key: 'outgoingPermits',
      type: 'list',
      optionValueName: 'code',
      optionSource: 'controlOrganizations',
      filterFunc: (item) => !item.scope || item.scope !== 'IN',
      noTable: true,
    },
    {
      title: 'Informācija par dokumentu',
      key: 'ownable',
      type: 'ownable',
      hide: true,
    },
    {
      title: 'Borts',
      key: 'board',
      hide: true,
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'LEFT', value: 'Kreisais' },
        { key: 'RIGHT', value: 'Labais' },
        { key: 'ANY', value: 'Jebkurš' },
      ],
    },
    //
    // SHIP FIELDS
    {
      title: 'IMO kods (kuģis)',
      key: '_indicators.ships.in.imo',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.imo', row);
      // },
      // render: (value) => {
      //   return value || ' - ';
      // },
    },
    {
      title: 'Kuģa veids',
      key: '_indicators.ships.in.shipTypeId',
      type: 'select',
      optionSource: 'shipTypes',
      hide: true,
      render: (value, row) => {
        return row._t_shipTypeId ? row._t_shipTypeId : value;
      },
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.shipTypeId', row);
      // },
    },
    {
      title: 'MMSI kods (kuģis)',
      key: '_indicators.ships.in.mmsi',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.mmsi', row);
      // },
    },
    {
      title: 'GT (kuģis)',
      key: '_indicators.ships.in.grossWeight',
      hide: true,
      // sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.grossWeight', row);
      // },
    },
    {
      title: 'DW (kuģis)',
      key: '_indicators.ships.in.deadweight',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.deadweight', row);
      // },
    },
    {
      title: 'ICE klasifikators (kuģis)',
      key: '_indicators.ships.in.iceClass',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.iceClass', row);
      // },
    },
    {
      title: 'Dzinēja jauda [kW] (kuģis)',
      key: '_indicators.ships.in.enginePower',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.enginePower', row);
      // },
    },
    {
      title: 'Iegrime (kuģis)', // { t: 'ACTUAL MAX FW DRAFT', v: '' },
      key: '_indicators.ships.in.draft',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.draft', row);
      // },
    },
    {
      title: 'Iegrime priekšgalā (kuģis)',
      key: '_indicators.ships.in.draftAtBow',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.draftAtBow', row);
      // },
    },
    {
      title: 'Iegrime pakaļgalā (kuģis)',
      key: '_indicators.ships.in.draftAtStern',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.draftAtStern', row);
      // },
    },
    ///     { t: 'RUDDER TYPE', v: '' },
    {
      title: 'Dzenskrūves tips (kuģis)',
      key: '_indicators.ships.in.propellerType',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.propellerType', row);
      // },
    },
    {
      title: 'Kuģa garums',
      key: '_indicators.ships.in.length',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.length', row);
      // },
    },
    {
      title: 'Kuģa platums',
      key: '_indicators.ships.in.width',
      hide: true,
      sorter: false,
      filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.width', row);
      // },
    },
    //{
      //title: 'Karogs (kuģis)',
      //key: '_indicators.ships.in.countryId',
      //key: '_t_shipVisitFlag',
      //type: 'select',
      //optionSource: 'countries',
      //optionValueName: 'id',
      //hide: true,
      //sorter: false,
      //multiple: true,
      //extraFieldProps: {
        //showSearch: true,
        //optionFilterProp: 'children',
        //allowClear: true,
      //},
      //filter: false,
      // computed: (_value, _key, row) => {
      //   return findValue('_indicators.ships.in.countryId', row);
      // },

      //     { t: 'TYPE', v: ship ? ship.shipTypeId : '' },
      //     { t: 'CALL SIGN', v: ship ? ship.mmsi : '' },
      //     {
      //       t: 'GT',
      //       // eslint-disable-next-line no-nested-ternary
      //       v: row.gt ? row.gt : ship ? ship.grossWeight : ' ',
      //     },
      //     { t: 'DW (S or W)', v: ship ? ship.deadweight : '' },
      //     { t: 'ICE CLASS', v: ship ? ship.iceClass : '' },
      //     {
      //       t: 'ENGINE POWER (KW)',
      //       v: ship ? ship.enginePower : '',
      //     },
      //     { t: 'BOW THRUSTER (KW)', v: '' },
      //     { t: 'STERN THRUSTER (KW)', v: '' },
      //     { t: 'RUDDER TYPE', v: '' },
      //     { t: 'PROPPELER TYPE', v: '' },
      //     { t: 'LOA', v: ship ? ship.length : '' },
      //     { t: 'BREATH', v: ship ? ship.width : '' },
      //     { t: 'ACTUAL MAX FW DRAFT', v: '' },
    //},
    {
      title: 'Karogs (kuģis)',
      key: '_t_shipVisitFlag',
      hide: false,
      sorter: false,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
        allowClear: true,
      },
    },
  ],

  EditShipVisit_resourceRequests: [
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'Uzņēmums',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Resurss',
      key: 'resourceId',
      type: 'select',
      optionSource: 'resources',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Piestātne',
      key: 'berthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // rules: [Validate.Required],
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    {
      title: 'Vēlamais laiks',
      key: 'desiredTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Plānotais laiks',
      key: 'actualTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Statuss',
      key: 'status',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'NEW', value: 'Jauns' },
        { key: 'ACCEPTED', value: 'Apstiprināts' },
        { key: 'REJECTED', value: 'Noraidīts' },
        { key: 'PROGRESS', value: 'Izpildē' },
        { key: 'COMPLETED', value: 'Pabeigts' },
      ],
    },
    {
      title: 'Pabeigšanas laiks',
      key: 'completionTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Apmaksāts',
      key: 'paid',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: (
        <span>
          Papildus informācija{' '}
          <Tooltip title="Papildus informācija, kas var būt svarīga drošai pakalpojuma nodrošināšanai">
            <FontAwesomeIcon icon="info-circle" />
          </Tooltip>
        </span>
      ),
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Kuģa ETA (Irbes meridiāns)',
      key: 'etaIrbeMeridian',
      type: 'date',
      time: true,
    },
    {
      title: 'Kuģa stāvoklis',
      key: 'shipCondition',
      type: 'select',
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'BALLAST', value: 'Balastā' },
        { key: 'PARTLYLOADED', value: 'Daļēji piekrauts (Krava<75%)' },
        { key: 'LOADED', value: 'Piekrauts (Krava>75%)' },
      ],
    },
    {
      title: 'Degvielas krājumi (t)',
      key: 'fuelStock',
      number: true,
    },
  ],
  EditShipVisit_resourceTasks: [
    // {
    //   title: "Autors",
    //   key: "userId",
    //   type: "select",
    //   optionSource: "users",
    //
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children",
    //   },
    // },
    // {
    //   title: "Resursa pieteikums",
    //   key: "resourceRequestId",
    //   type: "select",
    //   optionSource: "resourceRequests",
    //   optionValueName: "notes",
    //
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children",
    //   },
    // },
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'Resurss',
      key: 'resourceId',
      type: 'select',
      optionSource: 'resources',
      // optionValueName: '_t_resourceId',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Piestātne',
      key: 'berthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    {
      title: 'Vēlamais laiks',
      key: 'desiredTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Darba uzdevums',
      key: 'workDescription',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Pabeigšanas laiks',
      key: 'completionTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Statuss',
      key: 'status',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'NEW', value: 'Jauns' },
        { key: 'ACCEPTED', value: 'Apstiprināts' },
        { key: 'REJECTED', value: 'Noraidīts' },
        { key: 'PROGRESS', value: 'Izpildē' },
        { key: 'COMPLETED', value: 'Pabeigts' },
      ],
    },
  ],
  shipVisitPermits: [
    {
      title: 'Kuģa vizīte',
      key: 'shipVisitId',
      type: 'select',
      optionSource: 'shipVisits',
      optionValueName: '_t_shipInId',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      renderOption: shipVisitOptionRenderer,
    },
    {
      title: 'Virziens (kuģim ienākot/izejot)',
      key: 'direction',
      type: 'select',
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'IN', value: 'Ienākšana' },
        { key: 'OUT', value: 'Iziešana' },
      ],
    },
    {
      title: 'Institūcija',
      key: 'controlOrganizationId',
      type: 'select',
      optionSource: 'controlOrganizations',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Statuss',
      key: 'status',
      type: 'select',
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'ACCEPTED', value: 'Pieņemts' },
        { key: 'REJECTED', value: 'Noraidīts' },
        { key: 'COMMENTED', value: 'Pievienots komentārs' },
        { key: 'FAILED', value: 'Kļūda nosūtot datus uz SKLOIS' },
        { key: 'SENT', value: 'Nosūtīts' },
      ],
    },
    {
      title: 'Komentārs',
      key: 'comment',
      type: 'textarea',
    },
    {
      title: 'Atjaunošanas laiks',
      key: 'updatedTime',
      type: 'date',
      time: true,
    },
    {
      title: 'SKLOIS laiks',
      key: 'skloisTime',
      type: 'date',
      time: true,
    },
  ],
  EditShipVisit_crewMembers: [
    // {
    //   title: "Virziens (kuģim ienākot/izejot)",
    //   key: "direction",
    //   type: "select",

    //   extraFieldProps: {
    //     allowClear: true
    //   },
    //   options: [
    //     { key: "IN", value: "Ienākot" },
    //     { key: "OUT", value: "Izejot" }
    //   ]
    // },
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'Numurs',
      key: 'nr',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
    {
      title: 'Rangs/amats',
      key: 'crewRankId',
      type: 'select',
      optionSource: 'crewRanks',
      optionValueName: 'description',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Vārds',
      key: 'firstName',
      rules: [Validate.Required],
    },
    {
      title: 'Uzvārds',
      key: 'lastName',
      rules: [Validate.Required],
    },
    {
      title: 'Valstiskā piederība',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Dzimšanas datums',
      key: 'birthDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Dzimšanas vieta',
      key: 'birthPlace',
      // rules: [Validate.Required],
    },
    // {
    //   title: 'Dzimšanas vieta',
    //   key: 'birthPlaceId',
    //   type: 'select',
    //   optionSource: 'countries',
    //   hide: true,
    //   rules: [Validate.Required],
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: 'children',
    //   },
    // },
    {
      title: 'Dzimums',
      key: 'gender',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'M', value: 'Vīrietis' },
        { key: 'F', value: 'Sieviete' },
      ],
    },
  ],
  crewMembers: [
    {
      title: 'Virziens (kuģim ienākot/izejot)',
      key: 'direction',
      type: 'select',

      extraFieldProps: {
        allowClear: true,
      },
      options: [
        { key: 'IN', value: 'Ienākot' },
        { key: 'OUT', value: 'Izejot' },
      ],
      noTable: true,
    },
    {
      title: 'Numurs',
      key: 'nr',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
    {
      title: 'Rangs/amats',
      key: 'crewRankId',
      type: 'select',
      optionSource: 'crewRanks',
      optionValueName: 'description',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Vārds',
      key: 'firstName',
      rules: [Validate.Required],
    },
    {
      title: 'Uzvārds',
      key: 'lastName',
      rules: [Validate.Required],
    },
    {
      title: 'Valstiskā piederība',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Dzimšanas datums',
      key: 'birthDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Dzimšanas vieta',
      key: 'birthPlace',
      // rules: [Validate.Required],
    },
    // {
    //   title: 'Dzimšanas vieta',
    //   key: 'birthPlaceId',
    //   type: 'select',
    //   optionSource: 'countries',
    //   hide: true,
    //   rules: [Validate.Required],
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: 'children',
    //   },
    // },
    {
      title: 'Dzimums',
      key: 'gender',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'M', value: 'Vīrietis' },
        { key: 'F', value: 'Sieviete' },
      ],
    },
  ],
  crewRanks: [
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
  ],
  crewTravelDocuments: [
    {
      title: 'Apkalpes loceklis',
      key: 'crewMemberId',
      type: 'select',
      optionSource: 'crewMembers',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Apkalpes loceklis',
      key: 'travelDocumentTypeId',
      type: 'select',
      optionSource: 'travelDocumentTypes',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Numurs',
      key: 'nr',
      type: 'textarea',
    },
    {
      title: 'Derīguma termiņš',
      key: 'validUntil',
      type: 'date',
      hide: true,
    },
    {
      title: 'Izdošanas vieta',
      key: 'issueContryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  crewEffects: [
    {
      title: 'Apkalpes loceklis',
      key: 'crewMemberId',
      type: 'select',
      optionSource: 'crewMembers',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Numurs',
      key: 'nr',
      number: true,
      integer: true,
      rules: [Validate.Number],
    },
    {
      title: 'Identifikācija',
      key: 'identification',
      type: 'textarea',
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
    {
      title: 'Apjoma mērvienība',
      key: 'unitId',
      type: 'select',
      optionSource: 'units',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Apjoms',
      key: 'amount',
      rules: [Validate.Decimal],
    },
  ],
  EditShipVisit_passengers: [
    // {
    //   title: "Virziens (kuģim ienākot/izejot)",
    //   key: "direction",
    //   type: "select",

    //   extraFieldProps: {
    //     allowClear: true
    //   },
    //   options: [
    //     { key: "IN", value: "Ienākot" },
    //     { key: "OUT", value: "Izejot" }
    //   ]
    // },
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'Numurs',
      key: 'nr',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
    {
      title: 'Vārds',
      key: 'firstName',
      rules: [Validate.Required],
    },
    {
      title: 'Uzvārds',
      key: 'lastName',
      rules: [Validate.Required],
    },
    {
      title: 'Valstiskā piederība',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Dzimšanas datums',
      key: 'birthDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Dzimšanas vieta',
      key: 'birthPlace',
      // rules: [Validate.Required],
    },
    // {
    //   title: 'Dzimšanas vieta',
    //   key: 'birthCountryId',
    //   type: 'select',
    //   optionSource: 'countries',
    //   hide: true,
    //   rules: [Validate.Required],
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: 'children',
    //   },
    // },
    {
      title: 'Dzimums',
      key: 'gender',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'M', value: 'Vīrietis' },
        { key: 'F', value: 'Sieviete' },
      ],
    },
    {
      title: 'Tranzīts',
      key: 'transit',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Iekapšanas osta',
      key: 'originPortId',
      type: 'select',
      optionSource: 'ports',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Izkapšanas osta',
      key: 'destinationPortId',
      type: 'select',
      optionSource: 'ports',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  passengers: [
    {
      title: 'Virziens (kuģim ienākot/izejot)',
      key: 'direction',
      type: 'select',
      options: [
        { key: 'IN', value: 'Ienākot' },
        { key: 'OUT', value: 'Izejot' },
      ],
      noTable: true,
    },
    {
      title: 'Numurs',
      key: 'nr',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
    {
      title: 'Vārds',
      key: 'firstName',
      rules: [Validate.Required],
    },
    {
      title: 'Uzvārds',
      key: 'lastName',
      rules: [Validate.Required],
    },
    {
      title: 'Valstiskā piederība',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Dzimšanas datums',
      key: 'birthDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Dzimšanas vieta',
      key: 'birthPlace',
      // rules: [Validate.Required],
    },
    // {
    //   title: 'Dzimšanas vieta',
    //   key: 'birthCountryId',
    //   type: 'select',
    //   optionSource: 'countries',
    //   hide: true,
    //   rules: [Validate.Required],
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: 'children',
    //   },
    // },
    {
      title: 'Dzimums',
      key: 'gender',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'M', value: 'Vīrietis' },
        { key: 'F', value: 'Sieviete' },
      ],
    },
    {
      title: 'Tranzīts',
      key: 'transit',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Iekapšanas osta',
      key: 'originPortId',
      type: 'select',
      optionSource: 'ports',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Izkapšanas osta',
      key: 'destinationPortId',
      type: 'select',
      optionSource: 'ports',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  passengerTravelDocuments: [
    {
      title: 'Pasažieris',
      key: 'passengerId',
      type: 'select',
      optionSource: 'passengers',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Apkalpes loceklis',
      key: 'travelDocumentTypeId',
      type: 'select',
      optionSource: 'travelDocumentTypes',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Numurs',
      key: 'nr',
      type: 'textarea',
    },
    {
      title: 'Derīguma termiņš',
      key: 'validUntil',
      type: 'date',
      hide: true,
    },
    {
      title: 'Izdošanas vieta',
      key: 'issueContryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  travelDocumentTypes: [
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
  ],
  EditShipVisit_cargoConsignments: [
    {
      title: 'Numurs pēc kārtas',
      key: 'orderNum',
    },
    {
      title: 'Virziens (kuģim ienākot/izejot)',
      key: 'direction',
      type: 'select',
      options: [
        { key: 'IN', value: 'Ienākot' },
        { key: 'OUT', value: 'Izejot' },
      ],
      noTable: true,
    },
    // consignor
    // {
    //   title: 'Sūtītājs',
    //   key: 'consignor',
    //   type: 'textarea',
    // },

    {
      title: 'Nosaukums',
      key: 'consignorName',
    },
    {
      title: 'Reģ. numurs',
      key: 'consignorID',
    },
    {
      title: 'Adrese',
      key: 'consignorAddress',
    },
    {
      title: 'Valsts',
      key: 'consignorCountryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kontakti',
      key: 'consignorContact',
      type: 'textarea',
    },

    // consignee
    // {
    //   title: 'Saņēmējs',
    //   key: 'consignee',
    //   type: 'textarea',
    // },
    {
      title: 'Nosaukums',
      key: 'consigneeName',
    },
    {
      title: 'Reģ. numurs',
      key: 'consigneeID',
    },
    {
      title: 'Adrese',
      key: 'consigneeAddress',
    },
    {
      title: 'Valsts',
      key: 'consigneeCountryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kontakti',
      key: 'consigneeContact',
      type: 'textarea',
    },

    {
      title: 'Iekraušanas osta',
      key: 'loadingPortId',
      type: 'select',
      optionSource: 'ports',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Izkraušanas osta',
      key: 'unloadingPortId',
      type: 'select',
      optionSource: 'ports',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Krava',
      key: 'cargo',
      type: 'table',
      noTable: true,
    },
  ],
  cargoConsignments: [
    {
      title: 'Numurs pēc kārtas',
      key: 'orderNum',
    },
    // consignor
    // {
    //   title: 'Sūtītājs',
    //   key: 'consignor',
    //   type: 'textarea',
    // },

    {
      title: 'Nosaukums',
      key: 'consignorName',
      parentheses: `sutītājs`,
    },
    {
      title: 'Virziens (kuģim ienākot/izejot)',
      key: 'direction',
      type: 'select',
      options: [
        { key: 'IN', value: 'Ienākot' },
        { key: 'OUT', value: 'Izejot' },
      ],
      noTable: true,
    },
    {
      title: 'Reģ. numurs',
      key: 'consignorID',
      parentheses: `sutītājs`,
    },
    {
      title: 'Adrese',
      key: 'consignorAddress',
      parentheses: `sutītājs`,
    },
    {
      title: 'Valsts',
      key: 'consignorCountryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      parentheses: `sutītājs`,
    },
    {
      title: 'Kontakti',
      key: 'consignorContact',
      type: 'textarea',
      parentheses: `sutītājs`,
    },
    {
      title: `Bīstama krava`,
      key: `isDangerous`,
      type: `select`,
      noTable: true,
      options: [
        { key: true, value: 'Jā' },
        { key: false, value: 'Nē' },
        { key: null, value: 'Nē' },
      ],
    },
    // consignee
    // {
    //   title: 'Saņēmējs',
    //   key: 'consignee',
    //   type: 'textarea',
    // },
    {
      title: 'Nosaukums',
      key: 'consigneeName',
      parentheses: `saņēmējs`,
    },
    {
      title: 'Reģ. numurs',
      key: 'consigneeID',
      parentheses: `saņēmējs`,
    },
    {
      title: 'Adrese',
      key: 'consigneeAddress',
      parentheses: `saņēmējs`,
    },
    {
      title: 'Valsts',
      key: 'consigneeCountryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      parentheses: `saņēmējs`,
    },
    {
      title: 'Kontakti',
      key: 'consigneeContact',
      type: 'textarea',
      parentheses: `saņēmējs`,
    },

    {
      title: 'Iekraušanas osta',
      key: 'loadingPortId',
      type: 'select',
      optionSource: 'ports',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      noTable: true,
    },
    {
      title: 'Izkraušanas osta',
      key: 'unloadingPortId',
      type: 'select',
      optionSource: 'ports',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      noTable: true,
    },
    {
      title: 'Iekraušanas osta',
      key: 'loadingPortName',
      sorter: true,
    },
    {
      title: 'Izkraušanas osta',
      key: 'unloadingPortName',
      sorter: true,
    },
    {
      title: 'Krava',
      key: 'cargo',
      type: 'table',
      noTable: true,
    },
  ],
  EditShipVisit_cargo: [
    // {
    //   title: "Vizītes kravas datu virziens (kuģim ienākot/izejot)",
    //   key: "direction",
    //   type: "select",

    //   extraFieldProps: {
    //     allowClear: true
    //   },
    //   // options: [
    //   //   { key: "IN", value: "Ienākot" },
    //   //   { key: "OUT", value: "Izejot" }
    //   // ]
    //   options: [
    //     { key: "IN", value: "Iekrauta" },
    //     { key: "OUT", value: "Izkrauta" }
    //   ]
    // },
    {
      title: 'Sūtītājs',
      key: 'consignor',
      type: 'textarea',
    },
    {
      title: 'Saņēmējs',
      key: 'consignee',
      type: 'textarea',
    },
    {
      title: 'Konteinera numurs',
      key: 'containerNo',
    },
    {
      title: 'Konteinera tips', // .. veids
      key: 'containerTypeId',
      type: 'select',
      optionSource: 'containerTypes',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Masa',
      key: 'mass',
      hide: true,
      rules: [Validate.Required, Validate.Decimal],
    },
    {
      title: 'Kravas apraksts',
      key: 'cargoDescription',
      hide: true,
      rules: [Validate.Required],
    },
    {
      title: 'Tranzīta osta',
      key: 'transitPortId',
      type: 'select',
      optionSource: 'ports',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Gala osta',
      key: 'destinationPortId',
      type: 'select',
      optionSource: 'ports',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Statuss',
      key: 'cargoStatusId',
      type: 'select',
      optionSource: 'cargoStatuses',
      optionValueName: 'description',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Tranzīts/Eksports',
      key: 'transitExport',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'TRANSIT', value: 'Tranzīts' },
        { key: 'EXPORT', value: 'Eksports' },
      ],
    },
    {
      title: 'Preces (kravas) veids',
      key: 'cargoTypeId',
      type: 'select',
      optionSource: 'cargoTypes',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas grupa',
      key: 'cargoGroupId',
      type: 'select',
      optionSource: 'cargoGroups',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas daudzums',
      key: 'cargoQuantity',
      hide: true,
      rules: [Validate.Required, Validate.Decimal],
    },
    {
      title: 'Kravas mērvienība',
      key: 'unitId',
      type: 'select',
      optionSource: 'units',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // {
    //   title: "Krāvējs",
    //   key: "loaderId", // Šis ir tabulā, bet nav īsti definēts, kā DB ieraksts
    //   type: "select",
    //   optionSource: "companies",

    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children"
    //   }
    // }
  ],
  // cargoConsignments: [
  //   {
  //     title: 'Numurs pēc kārtas',
  //     key: 'orderNum',
  //   },
  //   {
  //     title: 'Sūtītājs',
  //     key: 'consigner',
  //     type: 'textarea',
  //     hide: true,
  //   },
  //   {
  //     title: 'Saņēmējs',
  //     key: 'consignee',
  //     type: 'textarea',
  //     hide: true,
  //   },
  //   {
  //     title: 'Iekraušanas osta',
  //     key: 'loadingPortId',
  //     type: 'select',
  //     optionSource: 'ports',
  //     hide: true,
  //     rules: [Validate.Required],
  //     extraFieldProps: {
  //       showSearch: true,
  //       optionFilterProp: 'children',
  //     },
  //   },
  //   {
  //     title: 'Izkraušanas osta',
  //     key: 'unloadingPortId',
  //     type: 'select',
  //     optionSource: 'ports',
  //     hide: true,
  //     rules: [Validate.Required],
  //     extraFieldProps: {
  //       showSearch: true,
  //       optionFilterProp: 'children',
  //     },
  //   },
  //   {
  //     title: 'Krava',
  //     key: 'cargo',
  //     type: 'table',
  //     noTable: true,
  //   },
  // ],
  cargos: [
    // {
    //   title: "Sūtītājs",
    //   key: "consignor",
    //   type: "textarea"
    //
    // },
    // {
    //   title: "Saņēmējs",
    //   key: "consignee",
    //   type: "textarea"
    //
    // },
    {
      title: `Id`,
      key: `id`,
      noTable: true,
    },
    {
      title: 'Konteinera numurs',
      key: 'containerNo',
    },
    {
      title: 'Konteinera tips', // .. veids
      key: 'containerTypeId',
      type: 'select',
      optionSource: 'containerTypes',
      hide: true,
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Masa',
      key: 'mass',
      hide: true,
      rules: [
        // Validate.Required,
        Validate.Decimal,
      ],
    },
    {
      title: 'Iepakojuma svars (bruto)',
      key: 'packageContentGrossWeight',
      // hide: true,
      rules: [Validate.Decimal],
    },
    {
      title: 'Iepakojuma svars (neto)',
      key: 'packageContentNetWeight',
      hide: true,
      rules: [Validate.Decimal],
    },
    {
      title: 'Iepakojuma veids',
      key: 'packageType',
      // hide: true,
    },
    {
      title: 'Iepakojumu skaits',
      key: 'packageQuantity',
      // hide: true,
      rules: [Validate.Decimal],
    },
    {
      title: 'Kravas apraksts',
      key: 'cargoDescription',
      type: 'textarea',
      // hide: true,
      rules: [Validate.Required],
    },
    {
      title: 'Tranzīta osta',
      key: 'transitPortId',
      type: 'select',
      optionSource: 'ports',
      hide: true,
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Gala osta',
      key: 'destinationPortId',
      type: 'select',
      optionSource: 'ports',
      hide: true,
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Statuss',
      key: 'cargoStatusId',
      type: 'select',
      hide: true,
      optionSource: 'cargoStatuses',
      optionValueName: 'description',
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Tranzīts/Eksports',
      key: 'transitExport',
      type: 'select',
      hide: true,
      // rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'TRANSIT', value: 'Tranzīts' },
        { key: 'EXPORT', value: 'Eksports' },
      ],
    },
    {
      title: 'Preces (kravas) veids',
      key: 'cargoTypeId',
      type: 'select',
      hide: true,
      optionSource: 'cargoTypes',
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas grupa',
      key: 'cargoGroupId',
      type: 'select',
      hide: true,
      optionSource: 'cargoGroups',
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas daudzums',
      key: 'cargoQuantity',
      hide: true,
      rules: [
        // Validate.Required,
        Validate.Decimal,
      ],
    },
    {
      title: 'Kravas mērvienība',
      key: 'unitId',
      type: 'select',
      hide: true,
      optionSource: 'units',
      rules: [
        // Validate.Required
      ],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas tehniskais nosaukums',
      tooltip: 'Precīzs kravas tehniskais nosaukums',
      key: 'technicalTitle',
      hide: true,
    },
    {
      title: 'Kravas ANO numurs',
      key: 'ano',
      hide: true,
    },
    {
      title: 'Kravas IMO bīstamības klase',
      tooltip: 'kravas IMO bīstamības klase saskaņā ar IMDG, IBC, IGC vai IMSBC kodeksu',
      key: 'imo',
      hide: true,
    },
    {
      title: 'Kuģa tips',
      tooltip:
        'ja kuģis pārvadā kravu, kas minēta Starptautiskajā kodeksā par iepakotas radioaktīvas kodoldegvielas, ' +
        'plutonija un radioaktīvo atkritumu ar augstu radioaktivitātes līmeni drošu pārvadāšanu ar kuģiem un atbilst ' +
        'SOLAS konvencijas pielikuma VII nodaļas 14.noteikuma 2.punkta definīcijai (INF krava)',
      key: 'shipTypeId',
      type: 'select',
      optionSource: 'shipTypes',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas izvietojums uz kuģa',
      key: 'locationOnBoard',
      hide: true,
    },
    {
      title: 'Transporta identifikācijas numurs',
      tooltip:
        'Transporta vienības (izņemot cisternas) identifikācijas numurs, ja kravu pārvadā kravas transporta vienībā',
      key: 'transportUnitId',
      hide: true,
    },
    {
      title: 'Ir bīstamo kravu manifests',
      tooltip:
        'apstiprinājums, ka uz kuģa atrodas kravas saraksts vai manifests, vai atbilstīgs iekraušanas plāns, kurā ' +
        'norādīta detalizēta informācija par bīstamajām un piesārņojošām kravām un to izvietojumu uz kuģa',
      key: 'isManifestPresent',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Kontaktinformācija',
      tooltip: 'tās personas kontaktinformācija, no kuras var iegūt detalizētu informāciju par kravu',
      key: 'contacts',
      hide: true,
    },
    // {
    //   title: "Krāvējs",
    //   key: "loaderId", // Šis ir tabulā, bet nav īsti definēts, kā DB ieraksts
    //   type: "select",
    //   optionSource: "companies",

    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children"
    //   }
    // }
  ],
  EditShipVisit_documents: [
    {
      title: `Id`,
      key: `id`,
      noTable: true,
    },
    {
      title: 'Dokuments',
      key: 'shipDocumentTypeId',
      type: 'select',
      optionSource: 'shipDocumentTypes',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Izdošanas datums',
      key: 'issueDate',
      type: 'date',
    },
    {
      title: 'Izdošanas vieta',
      key: 'issueLocation',
    },
    {
      title: 'Derīgs līdz',
      key: 'validUntil',
      type: 'date',
    },
    {
      title: 'Pārbaudīšanas datums', // Pārbaudīts
      key: 'verifiedDate',
      type: 'date',
    },
    {
      title: 'Pārbaudīts',
      key: 'verified',
      type: 'checkbox',
    },
    {
      title: 'Pārbaudīja',
      key: 'verifiedByName',
      label: true,
    },
    {
      title: 'Komentārs',
      key: 'comment',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Faili', // Pielikumi
      key: 'files',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
  ],
  shipDocuments: [
    {
      title: 'Dokuments',
      key: 'shipDocumentTypeId',
      type: 'select',
      optionSource: 'shipDocumentTypes',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Izdošanas datums',
      key: 'issueDate',
      type: 'date',
    },
    {
      title: 'Izdošanas vieta',
      key: 'issueLocation',
    },
    {
      title: 'Derīgs līdz',
      key: 'validUntil',
      type: 'date',
    },
    {
      title: 'Pārbaudīšanas datums', // Pārbaudīts
      key: 'verifiedDate',
      type: 'date',
    },
    {
      title: 'Pārbaudīts',
      key: 'verified',
      type: 'checkbox',
    },
    {
      title: 'Komentārs',
      key: 'comment',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Faili', // Pielikumi
      key: 'files',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
  ],
  EditShipVisit_stores: [
    // {
    //   title: "Vizītes kravas datu virziens (kuģim ienākot/izejot)",
    //   key: "direction",
    //   type: "select",

    //   extraFieldProps: {
    //     allowClear: true
    //   },
    //   options: [
    //     { key: "IN", value: "Ienākot" },
    //     { key: "OUT", value: "Izejot" }
    //   ]
    // },
    {
      title: 'Id',
      key: `id`,
      noTable: true,
    },
    {
      title: 'Nrpk',
      key: 'nr',
      number: true,
      integer: true,
    },
    {
      title: 'Krājumu veids',
      key: 'shipsStoreTypeId',
      type: 'select',
      optionSource: 'shipsStoreTypes',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Mērvienība',
      key: 'unitId',
      type: 'select',
      optionSource: 'units',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Daudzums',
      key: 'qty',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Novietojums',
      key: 'location',
      // rules: [Validate.Required],
    },
    {
      title: 'Izmantošanas mērķis',
      key: 'purpose',
      // rules: [Validate.Required],
    },
  ],
  shipsStores: [
    {
      title: 'Vizītes kravas datu virziens (kuģim ienākot/izejot)',
      key: 'direction',
      type: 'select',

      extraFieldProps: {
        allowClear: true,
      },
      options: [
        { key: 'IN', value: 'Ienākot' },
        { key: 'OUT', value: 'Izejot' },
      ],
      noTable: true,
    },
    {
      title: 'Nrpk',
      key: 'nr',
      number: true,
      integer: true,
      rules: [Validate.Number],
    },
    {
      title: 'Krājumu veids',
      key: 'shipsStoreTypeId',
      type: 'select',
      optionSource: 'shipsStoreTypes',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Mērvienība',
      key: 'unitId',
      type: 'select',
      optionSource: 'units',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Daudzums',
      key: 'qty',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Novietojums',
      key: 'location',
      rules: [Validate.Required],
    },
    {
      title: 'Izmantošanas mērķis',
      key: 'purpose',
      rules: [Validate.Required],
    },
  ],
  wasteDisposals: [
    {
      tite: `Id`,
      key: `id`,
      noTable: true,
    },
    {
      title: 'Virziens (kuģim ienākot/izejot)',
      key: 'direction',
      type: 'select',

      extraFieldProps: {
        allowClear: true,
      },
      options: [
        { key: 'IN', value: 'Ienākot' },
        { key: 'OUT', value: 'Izejot' },
      ],
      noTable: true,
    },
    {
      title: 'Kārtas numurs',
      key: 'nr',
    },
    {
      title: 'Atkritumu veids',
      key: 'wasteTypeId',
      type: 'select',
      optionSource: 'wasteTypes',
      localOptionFilterFunc: (option, value, row) => {
        return !option.notActive || option.key === value;
      },
      // optionFilters: {
      //   notActive: '!falsy',
      // },
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      // rules: [Validate.Required],
      renderOption: (option, row) => {
        const title = `${option.value} (${option._t_wasteGroupId}) - ${option.notActive ? 'Neaktīvs' : 'Aktīvs'}`;
        const fields = [
          <span>{option.value}</span>,
          option._t_wasteGroupId ? <strong>&nbsp;({option._t_wasteGroupId})</strong> : undefined,
        ];
        if (row && row.id && option.key === row.id) {
          return null;
        }
        return (
          <div className="flex-space-between" title={title}>
            <div>
              {fields
                .filter((i) => !!i)
                .map((f, i) => (
                  <span
                    key={i}
                  // style={{
                  //   display: 'table-cell',
                  //   width: i === fields.length - 1 ? null : '150px',
                  // }}
                  >
                    {f}
                  </span>
                ))}
            </div>
            <div>&nbsp;[{option.notActive ? 'Neaktīvs' : 'Aktīvs'}]</div>
          </div>
        );
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'title',
      },
    },
    {
      title: 'Apraksts',
      key: 'wasteDescription',
      type: 'textarea',
      // hide: true,
    },
    {
      title: 'Mērvienība',
      key: 'wasteUnitId',
      type: 'select',
      optionSource: 'units',
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Faktiski nodots',
      key: 'disposed',
      number: true,
      rules: [Validate.Decimal],
      // hide: true,
    },
    {
      title: 'Paredzēts nodot',
      key: 'forDisposal',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Maks. ietilpība',
      key: 'maxCapacity',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Atlikums',
      key: 'remainder',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Paredzams radīt',
      key: 'expectedToCreate',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Nodošanas datums',
      key: 'disposedAt',
      type: 'date',
      time: true,
      // defaultValue: moment("2015-01-01", 'YYYY-MM-DD')
      // rules: [Validate.Required],
    },

    //TODO HERE!!
  ],
  // wasteDisposals: [
  //   // {
  //   //   title: "Virziens (kuģim ienākot/izejot)",
  //   //   key: "direction",
  //   //   type: "select",

  //   //   extraFieldProps: {
  //   //     allowClear: true
  //   //   },
  //   //   options: [
  //   //     { key: "IN", value: "Ienākot" },
  //   //     { key: "OUT", value: "Izejot" }
  //   //   ]
  //   // },
  //   {
  //     title: 'Kārtas numurs',
  //     key: 'nr',
  //     number: true,
  //     integer: true,
  //     rules: [Validate.Number],
  //   },
  //   {
  //     title: 'Atkritumu veids',
  //     key: 'wasteTypeId',
  //     type: 'select',
  //     optionSource: 'wasteTypes',
  //     extraFieldProps: {
  //       showSearch: true,
  //       optionFilterProp: 'children',
  //     },
  //   },
  //   {
  //     title: 'Apraksts',
  //     key: 'wasteDescription',
  //     type: 'textarea',
  //     hide: true,
  //   },
  //   {
  //     title: 'Mērvienība',
  //     key: 'wasteUnitId',
  //     type: 'select',
  //     optionSource: 'units',
  //     rules: [Validate.Required],
  //     extraFieldProps: {
  //       showSearch: true,
  //       optionFilterProp: 'children',
  //     },
  //   },
  //   {
  //     title: 'Nodošanai',
  //     key: 'forDisposal',
  //     number: true,
  //     rules: [Validate.Decimal],
  //   },
  //   {
  //     title: 'Maks. ietilpība',
  //     key: 'maxCapacity',
  //     number: true,
  //     rules: [Validate.Decimal],
  //     hide: true,
  //   },
  //   {
  //     title: 'Atlikums',
  //     key: 'remainder',
  //     number: true,
  //     rules: [Validate.Decimal],
  //     hide: true,
  //   },
  //   {
  //     title: 'Paredzams radīt',
  //     key: 'expectedToCreate',
  //     number: true,
  //     rules: [Validate.Decimal],
  //     hide: true,
  //   },
  // ],
  EditShipVisit_movement: [
    {
      title: 'Numurs', // Npk. (possibly readonly)
      key: 'movementNr',
      number: true,
      integer: true,
      rules: [Validate.Number],
    },
    {
      title: 'Sākums',
      key: 'movementStart',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'Beigas',
      key: 'movementEnd',
      type: 'date',
      time: true,
    },
    {
      title: 'No piestātnes',
      key: 'fromBerthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      label: true,
    },
    {
      title: 'Uz piestātni',
      key: 'toBerthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionFilters: {
        isOperating: [true],
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    {
      title: 'Aģents',
      key: 'agentCompanyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Aģenta pārstāvis',
      key: 'agentCompanyRepresentativeId',
      type: 'select',
      optionSource: 'companyRepresentatives',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Aģenta pārstāvja tālrunis',
      key: 'representativePhone',
      hide: true,
    },
    {
      title: 'Borts',
      key: 'board',
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'LEFT', value: 'Kreisais' },
        { key: 'RIGHT', value: 'Labais' },
        { key: 'ANY', value: 'Jebkurš' },
      ],
      hide: true,
    },
    {
      title: 'Korpuss',
      key: 'housing',
      type: 'select',
      number: true,
      integer: true,
      rules: [Validate.Number],
      options: [
        { key: 1, value: '1' },
        { key: 2, value: '2' },
        { key: 3, value: '3' },
        { key: 4, value: '4' },
      ],
    },
    // Parametri
    {
      title: 'Iegrime ūdenī (maks.)',
      key: 'paramWaterMaxDepth',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    // {
    //   title: 'Iegrime ūdenī (fakt.)',
    //   key: 'paramWaterActualDepth',
    //   number: true,
    //   rules: [Validate.Decimal],
    //   hide: true,
    // },
    {
      title: 'Iegrime priekšgalā',
      key: 'paramDraftAtBow',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Iegrime pakaļgalā',
      key: 'paramDraftAtStern',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    // {
    //   title: 'Iegrime gaisā',
    //   key: 'paramAirDepth',
    //   number: true,
    //   rules: [Validate.Decimal],
    //   hide: true,
    // },
    {
      title: 'Sakaru kanāls',
      key: 'radioChannel',
      number: true,
      integer: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Kustības veids',
      key: 'movementType',
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'Berth', value: 'Pārtauvošana' },
        { key: 'TUG', value: 'Pārvilkšana' },
      ],
      hide: true,
    },
    {
      title: 'Pakalpojumi',
      key: 'services',
      type: 'multicheckbox',
      multiple: true,
      optionSource: 'movementServiceTypes',
      hide: true,
    },
    // Locis
    {
      title: 'Loča kvīts Nr.',
      key: 'pilotBillNr',
      // eslint-disable-next-line no-unused-vars
      render: (value, _row) => {
        return value || 'B/L';
      },
      number: true,
      integer: true,
    },
    {
      title: '1.locis',
      key: 'pilot1Id',
      type: 'select',
      optionSource: 'pilots',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: '2.locis',
      key: 'pilot2Id',
      type: 'select',
      optionSource: 'pilots',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Sākuma laiks',
      key: 'startTime',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'Beigu laiks',
      key: 'endTime',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'Locis atteikts', // Locis atcelts
      key: 'pilotCancelled',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Locis aizkavēts - neprecīza info', // Locis atcelts
      key: 'pilotDelayedInfo',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Locis aizkavēts - neprecīza info - laiks',
      key: 'pilotDelayedInfoSeconds',
      type: 'time',
      hide: true,
    },
    // {
    //   title: "Locis aizkavēts - neprecīza info - laiks",
    //   key: "pilotDelayedInfoTime",
    //   type: "date",
    //   time: true,
    //   hide: true
    // },
    {
      title: 'Locis aizkavēts - kuģis', // Locis atcelts
      key: 'pilotDelayedShip',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Locis aizkavēts - kuģis - laiks',
      key: 'pilotDelayedShipSeconds',
      type: 'time',
      hide: true,
    },
    // {
    //   title: "Locis aizkavēts - kuģis - laiks",
    //   key: "pilotDelayedShipTime",
    //   type: "date",
    //   time: true,
    //   hide: true
    // },
    // Velkonis
    {
      title: 'Dokumenta Nr.',
      key: 'tugboatDocumentNr',
      hide: true,
    },
    {
      title: 'Velkonis',
      key: 'tugboatId',
      type: 'select',
      multiple: true,
      optionSource: 'resources',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Sākuma laiks',
      key: 'tugboatStart',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'Beigu laiks',
      key: 'tugboatEnd',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'Tarifs',
      key: 'tugboatTarif',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Velkonis',
      key: 'resourceUses',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Piestātnes apmeklējuma mērķis',
      key: 'callPurposeId',
      type: 'select',
      noTable: true,
      optionSource: 'callPurposes',
      optionValueName: 'description',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  pilotBills: [
    {
      title: 'Nr.', // Npk. (possibly readonly)
      key: 'pilotBillNr',
      // eslint-disable-next-line no-unused-vars
      render: (value, _row) => {
        return value || 'B/L';
      },
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
      defaultSortOrder: 'asc',
    },
    {
      title: 'Vel.',
      key: 'resourceUses',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Vizīte',
      key: 'shipVisitId',
      type: 'select',
      optionSource: 'shipVisits',
      optionValueName: '_t_shipInId',
      render: (v, row) => {
        return row._t_shipInId ?? v;
      },
      optionFilters: {},
      renderOption: shipVisitOptionRenderer,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // Locis
    {
      title: '1.locis',
      key: 'pilot1Id',
      type: 'select',
      optionSource: 'pilots',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: '2.locis',
      key: 'pilot2Id',
      type: 'select',
      optionSource: 'pilots',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Locis uzkāpj',
      key: 'startTime',
      type: 'date',
      time: true,
    },
    {
      title: 'Locis nokāpj',
      key: 'endTime',
      type: 'date',
      time: true,
    },

    {
      title: 'No',
      key: 'fromBerthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },

    {
      title: 'Uz',
      key: 'toBerthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
      optionFilters: {
        isOperating: [true],
      },
    },

    {
      title: 'Aģents',
      key: 'agentCompanyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Sākums',
      key: 'movementStart',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'Beigas',
      key: 'movementEnd',
      type: 'date',
      time: true,
    },

    {
      title: 'Aģenta pārstāvis',
      key: 'agentCompanyRepresentativeId',
      type: 'select',
      optionSource: 'companyRepresentatives',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Aģenta pārstāvja tālrunis',
      key: 'representativePhone',
      hide: true,
    },
    {
      title: 'Borts',
      key: 'board',
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'LEFT', value: 'Kreisais' },
        { key: 'RIGHT', value: 'Labais' },
        { key: 'ANY', value: 'Jebkurš' },
      ],
      hide: true,
    },
    {
      title: 'Korpuss',
      key: 'housing',
      type: 'select',
      number: true,
      integer: true,
      rules: [Validate.Number],
      options: [
        { key: 1, value: '1' },
        { key: 2, value: '2' },
        { key: 3, value: '3' },
        { key: 4, value: '4' },
      ],
      hide: true,
    },
    // Parametri
    {
      title: 'Iegrime ūdenī (maks.)',
      key: 'paramWaterMaxDepth',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    // {
    //   title: 'Iegrime ūdenī (fakt.)',
    //   key: 'paramWaterActualDepth',
    //   number: true,
    //   rules: [Validate.Decimal],
    //   hide: true,
    // },
    {
      title: 'Iegrime gaisā',
      key: 'paramAirDepth',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Sakaru kanāls',
      key: 'radioChannel',
      number: true,
      integer: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Kustības veids',
      key: 'movementType',
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'Berth', value: 'Pārtauvošana' },
        { key: 'TUG', value: 'Pārvilkšana' },
      ],
      hide: true,
    },
    {
      title: 'Pakalpojumi',
      key: 'services',
      type: 'multicheckbox', // multiselect
      multiple: true,
      optionSource: 'movementServiceTypes',
      hide: true,
    },

    {
      title: 'Locis atteikts', // Locis atcelts
      key: 'pilotCancelled',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Locis aizkavēts - neprecīza info', // Locis atcelts
      key: 'pilotDelayedInfo',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Locis aizkavēts - neprecīza info - laiks',
      key: 'pilotDelayedInfoSeconds',
      type: 'time',
      hide: true,
    },
    // {
    //   title: "Locis aizkavēts - neprecīza info - laiks",
    //   key: "pilotDelayedInfoTime",
    //   type: "date",
    //   time: true,
    //   hide: true
    // },
    // {
    //   title: 'Locis aizkavēts - kuģis', // Locis atcelts
    //   key: 'pilotDelayedShip',
    //   type: 'checkbox',
    //   labelRight: true,
    //   hide: true,
    // },
    // {
    //   title: 'Locis aizkavēts - kuģis - laiks',
    //   key: 'pilotDelayedShipSeconds',
    //   type: 'time',
    //   hide: true,
    // },
    // // {
    // //   title: "Locis aizkavēts - kuģis - laiks",
    // //   key: "pilotDelayedShipTime",
    // //   type: "date",
    // //   time: true,
    // //   hide: true
    // // },
    // // Velkonis
    // {
    //   title: 'Dokumenta Nr.',
    //   key: 'tugboatDocumentNr',
    //   hide: true,
    // },
    // {
    //   title: 'Velkonis',
    //   key: 'tugboatId',
    //   type: 'select',
    //   optionSource: 'resources',
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: 'children',
    //   },
    // },
    // {
    //   title: 'Sākuma laiks',
    //   key: 'tugboatStart',
    //   type: 'date',
    //   time: true,
    //   hide: true,
    // },
    // {
    //   title: 'Beigu laiks',
    //   key: 'tugboatEnd',
    //   type: 'date',
    //   time: true,
    //   hide: true,
    // },
    // {
    //   title: 'Tarifs',
    //   key: 'tugboatTarif',
    //   number: true,
    //   rules: [Validate.Decimal],
    //   hide: true,
    // },
  ],
  movementServices: [
    {
      title: 'Pakalpojuma veids',
      key: 'movementServiceTypeId',
      type: 'multicheckbox', // multiselect
      multiple: true,
      optionSource: 'movementServiceTypes',
    },
  ],
  movements: [
    {
      title: 'Numurs', // Npk. (possibly readonly)
      key: 'movementNr',
      // asText: true,
      number: true,
      integer: true,
      rules: [Validate.Number],
    },
    {
      title: 'Kuģa IMO Nr.',
      key: 'imo',
      hide: true,
    },
    {
      title: 'Kuģa GT',
      key: 'grossWeight',
      hide: true,
    },
    /*{
      title: 'Kuģa tips',
      key: 'shipTypeId',
    },*/
    {
      title: 'Kuģa tips',
      key: 'shipTypeId',
      type: 'select',
      optionSource: 'shipTypes',
      rules: [Validate.Required],
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kuģa platums',
      key: 'width',
      hide: true,
    },
    {
      title: 'Piezīmes',
      key: 'note',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Kuģa LOA',
      key: 'length',
      hide: true,
    },
    {
      title: 'Sākums',
      key: 'movementStart',
      type: 'date',
      time: true,
      rules: [Validate.Required],
      defaultSortOrder: 'asc',
    },
    {
      title: 'Beigas',
      key: 'movementEnd',
      type: 'date',
      time: true,
      rules: [Validate.DateNotInFuture]
    },
    {
      title: 'No piestātnes',
      key: 'fromBerthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      optionFilters: {
        isOperating: [true],
      },
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Uz piestātni',
      key: 'toBerthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
        optionStyle: (opt, rec, extra) => {
          const isFat = isShip2Fat(extra.ship, opt, extra.form.getFieldsValue(['paramDraftAtStern']) ?? rec);
          return { background: isFat ? `rgba(255, 77, 79, 0.25)` : undefined, fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
        },
          overridechangeevent: (val, opt, extra) => {
          const errors = [];
          const validationResults = {};
          const {form} = extra;
          const movementStart = form.getFieldValue('movementStart');
          const movementEnd = form.getFieldValue('movementEnd');

          if (val && opt) {
            const isFat = isShip2Fat(extra.ship, opt, extra.form.getFieldsValue(['paramDraftAtStern']) ?? rec);
            //console.log(opt.label ?? opt.name ?? opt, isFat);

            if (isFat) {
              validationResults.help = `Piestātnes tehniskie parametri neatbilst kuģa izmēriem.`;
              validationResults.validateStatus = `warning`;
              validationResults.hasFeedback = true;
            }
          } else {
            errors.push(new Error(Validate.Required.message));
          }

          extra.form.setFields({
            toBerthId: {
              value: val,
              errors: errors.length ? errors.slice() : undefined,
            }
          });

          extra.stashBerthData(opt, false);
          extra.setItemProps(validationResults);
          if (opt && opt.berthType) {
             extra.stashBerthType(opt.berthType);
          }
        }
      },
      optionFilters: {
        berthInUse: [true],
      },
    },
    {
      title: 'Piestātnes apmeklējuma mērķis',
      key: 'callPurposeId',
      type: 'select',
      optionSource: 'callPurposes',
      optionValueName: 'description',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Vizīte',
      key: 'shipVisitId',
      type: 'select',
      hide: true,
      optionSource: 'shipVisits',
      optionKeyName: '_t_shipInId',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Aģents',
      key: 'agentCompanyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Aģenta pārstāvis',
      key: 'agentCompanyRepresentativeId',
      type: 'select',
      optionSource: 'companyRepresentatives',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Aģenta pārstāvja tālrunis',
      key: 'representativePhone',
      hide: true,
    },
    {
      title: 'Borts',
      key: 'board',
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'LEFT', value: 'Kreisais' },
        { key: 'RIGHT', value: 'Labais' },
        { key: 'ANY', value: 'Jebkurš' },
      ],
      hide: true,
    },
    {
      title: 'Korpuss',
      key: 'housing',
      type: 'select',
      options: [
        { key: 1, value: '1' },
        { key: 2, value: '2' },
        { key: 3, value: '3' },
        { key: 4, value: '4' },
      ],
    },
    // Parametri
    {
      title: 'Iegrime ūdenī (maks.)',
      key: 'paramWaterMaxDepth',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    // {
    //   title: 'Iegrime ūdenī (fakt.)',
    //   key: 'paramWaterActualDepth',
    //   number: true,
    //   rules: [Validate.Decimal],
    //   hide: true,
    // },
    {
      title: 'Iegrime priekšgalā',
      key: 'paramDraftAtBow',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
      extraFieldProps: {
          overridechangeevent: (val, extra) => {
          const validationResults = {};

          extra.form.setFields({
            paramDraftAtBow: {
              value: val,
            }
          });
          extra.form.validateFields([`paramDraftAtBow`]);

          if (extra.berth) {
            const isFat = isShip2Fat(extra.ship, extra.berth, extra.form.getFieldsValue(['paramDraftAtBow']));
            // console.log(extra.berth?.name ?? extra.berth, isFat);

            if (isFat) {
              validationResults.help = `Piestātnes tehniskie parametri neatbilst kuģa izmēriem.`;
              validationResults.validateStatus = `warning`;
              validationResults.hasFeedback = true;
            }
          }

          extra.setBerthProps(validationResults);
        }
      }
    },
    {
      title: 'Iegrime pakaļgalā',
      key: 'paramDraftAtStern',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    // {
    //   title: 'Iegrime gaisā',
    //   key: 'paramAirDepth',
    //   number: true,
    //   rules: [Validate.Decimal],
    //   hide: true,
    // },
    // {
    //   title: 'Sakaru kanāls',
    //   key: 'radioChannel',
    //   number: true,
    //   integer: true,
    //   rules: [Validate.Decimal],
    //   hide: true,
    // },
    {
      title: 'Kustības veids',
      key: 'movementType',
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'Berth', value: 'Pārtauvošana' },
        { key: 'TUG', value: 'Pārvilkšana' },
      ],
      hide: true,
    },
    {
      title: 'Pakalpojumi',
      key: 'services',
      type: 'multicheckbox',
      multiple: true,
      optionSource: 'movementServiceTypes',
      hide: true,
    },
    // Locis
    {
      title: 'Bez loča', // Locis atcelts
      key: 'withoutPilot',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: 'Loča pakalpojums tika atcelts', // Locis atcelts
      key: 'pilotCancelled',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    // Locis
    {
      title: 'Loča kvīts Nr.',
      key: 'pilotBillNr',
      // eslint-disable-next-line no-unused-vars
      render: (value, _row) => {
        return value || 'B/L';
      },
      number: true,
      integer: true,
    },
    {
      title: '1.locis',
      key: 'pilot1Id',
      type: 'select',
      optionSource: 'pilots',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: '2.locis',
      key: 'pilot2Id',
      type: 'select',
      optionSource: 'pilots',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Locis uzkāpa',
      key: 'pilotStartTime',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'Locis nokāpa',
      key: 'pilotEndTime',
      type: 'date',
      time: true,
      hide: true,
    },
    // {
    //   title: 'Locis atteikts', // Locis atcelts
    //   key: 'pilotCancelled',
    //   type: 'checkbox',
    //   labelRight: true,
    //   hide: true,
    // },
    // {
    //   title: 'Locis aizkavēts - neprecīza info', // Locis atcelts
    //   key: 'pilotDelayedInfo',
    //   type: 'checkbox',
    //   labelRight: true,
    //   hide: true,
    // },
    {
      title: 'Locis aizkavēts - neprecīza info - laiks',
      key: 'pilotDelayedInfoSeconds',
      type: 'time',
      hide: true,
    },
    {
      title: 'Locis aizkavēts - neprecīza info - laiks',
      key: 'pilotDelayedInfoTime',
      type: 'date',
      time: true,
      hide: true,
    },
    // {
    //   title: 'Locis aizkavēts - kuģis', // Locis atcelts
    //   key: 'pilotDelayedShip',
    //   type: 'checkbox',
    //   labelRight: true,
    //   hide: true,
    // },
    {
      title: 'Locis aizkavēts - kuģis - laiks',
      key: 'pilotDelayedShipSeconds',
      type: 'time',
      hide: true,
    },
    // {
    //   title: "Locis aizkavēts - kuģis - laiks",
    //   key: "pilotDelayedShipTime",
    //   type: "date",
    //   time: true,
    //   hide: true
    // },
    // Velkonis
    {
      title: 'Dokumenta Nr.',
      key: 'tugboatDocumentNr',
      hide: true,
    },
    {
      title: 'Velkonis ',
      key: 'tugboatId',
      type: 'multiselect',
      // multiple:true,
      optionSource: 'resources',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
      render: (value, _row) => {
        return _row._t_tugboatsIds ? _row._t_tugboatsIds : "";
      },
    },
    {
      title: 'Velkonis uzsāk',
      key: 'tugboatStart',
      type: 'date',
      time: true,
      hide: true,
    },
    {
      title: 'Velkonis pabeidz',
      key: 'tugboatEnd',
      type: 'date',
      time: true,
      hide: true,
    },
    // {
    //   title: 'Tarifs',
    //   key: 'tugboatTarif',
    //   number: true,
    //   rules: [Validate.Decimal],
    //   hide: true,
    // },
    {
      title: 'Kuģi velk',
      key: 'tugShipVisitId',
      type: 'select',
      hide: true,
      optionKeyName: 'id',
      optionValueName: '_t_shipInId',
      optionSource: 'tugboatVisits',
      // optionFilters: {
      //   eta: true,
      //   // atd: null,
      //   '_indicators.ships.in.shipTypeId': [3000005, 42], // for production
      //   '[or]': [
      //     {
      //       ata: null,
      //     },
      //     {
      //       atd: null,
      //       ata: {
      //         // gte: moment().subtract(90, 'days').format('YYYY-MM-DD')
      //         gte: 'now-90d',
      //       },
      //     },
      //     {
      //       atd: {
      //         // gte: moment().subtract(30, 'days').format('YYYY-MM-DD')
      //         gte: 'now-30d',
      //       },
      //     },
      //   ],
      // },
      renderOption: shipVisitOptionRenderer,
      // hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
        allowClear: true,
      },
    },
    {
      title: 'Velkonis',
      key: 'resourceUses',
      type: 'table',
      noTable: true,
    },
  ],
  movementServiceTypes: [
    {
      title: 'Pakalpojuma nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
  ],
  berthVisits: [
    {
      title: 'Nr', // Npk. (possibly readonly)
      key: 'nr',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
    {
      title: 'Piestātne',
      key: 'berthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      optionFilters: {
        berthInUse: [true],
      },
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    {
      title: 'Korpuss',
      key: 'housing',
      type: 'select',
      options: [
        { key: 1, value: '1' },
        { key: 2, value: '2' },
        { key: 3, value: '3' },
        { key: 4, value: '4' },
      ],
    },
    {
      title: 'Borts',
      key: 'board',
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'LEFT', value: 'Kreisais' },
        { key: 'RIGHT', value: 'Labais' },
        { key: 'ANY', value: 'Jebkurš' },
      ],
      hide: true,
    },
    {
      title: 'Plānotais sākuma laiks',
      key: 'eta',
      type: 'date',
      time: true,
    },
    {
      title: 'Plānotais beigu laiks',
      key: 'etd',
      type: 'date',
      time: true,
    },
    {
      title: 'Sākuma laiks',
      key: 'startTime',
      type: 'date',
      time: true,
      defaultSortOrder: 'asc',
    },
    {
      title: 'Beigu laiks',
      key: 'endTime',
      type: 'date',
      time: true,
    },
    {
      title: 'Stāvoklis',
      key: 'status',
      type: 'radio',
      extraFieldProps: { vertical: true },
      options: [
        { key: 'PLANNED', value: 'Plānots' },
        { key: 'ONGOING', value: 'Notiekošs' },
        { key: 'COMPLETE', value: 'Pabeigts' },
      ],
    },
    {
      title: 'Veids',
      key: 'berthVisitType',
      type: 'textarea',
    },
    {
      title: 'Piestātnes apmeklējuma mērķis',
      key: 'callPurposeId',
      type: 'select',
      optionSource: 'callPurposes',
      optionValueName: 'description',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  berthVisitRequests: [
    {
      title: 'Piestātne',
      key: 'berthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      optionFilters: { isRequestable: true },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    {
      title: 'Kuģa nosaukums',
      key: 'shipName',
      rules: [
        // Validate.Required
      ],
    },
    {
      title: 'Kuģa IMO numurs',
      key: 'imo',
      rules: [Validate.Required],
    },
    {
      title: 'Rēķina statuss',
      key: 'invoiceStatus',
      asText: true,
      type: 'select',
      options: [
        { key: 'PREPARED', value: 'Rēķins sagatavots' },
        { key: 'SENT', value: 'Rēķins nosūtīts' },
        { key: 'PAID', value: 'Rēķins apmaksāts' },
      ],
    },
    // {
    //   title: "Kuģis",
    //   key: "shipId",
    //   type: "select",
    //   optionSource: "ships",

    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children"
    //   }
    // },
    {
      title: 'Karogs',
      key: 'flagCountryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas ekspeditors',
      key: 'freightForwarderId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas veids',
      key: 'cargoType',
      type: 'select',
      optionSource: 'cargoGoodsTypes',
      time: true,
    },
    {
      title: 'Kuģa garums',
      key: 'shipLength',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Kuģa platums',
      key: 'shipWidth',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Kuģa iegrime',
      key: 'shipDraft',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'GT',
      key: 'gt',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },

    {
      title: 'Paredzamais pienākšanas laiks ostā',
      key: 'etaBerth',
      type: 'date',
      time: true,
    },
    {
      title: 'Paredzamais pienākšanas laiks terminālī',
      key: 'etaTerminal',
      type: 'date',
      time: true,
    },
    {
      title: 'Piestātnes izmantošanas sākuma laiks',
      key: 'startDate',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'Piestātnes izmantošanas beigu laiks',
      key: 'endDate',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },

    // status
    {
      title: 'Statuss',
      key: 'status',
      type: 'select',
      asText: true,
      options: [
        { key: 'SUBMITTED', value: 'Jauns' },
        { key: 'ACCEPTED', value: 'Apstiprināts' },
        { key: 'REJECTED', value: 'Noraidīts' },
        { key: 'CANCELLED', value: 'Atcelts' },
        { key: 'MODIFIED', value: 'Iesniegts ar labojumiem' },
      ],
      render: (value, row) => {
        const opts = fieldSpec.colByKey.berthVisitRequests.status.options;
        const op = opts.find((o) => o.key === `${row['_orig.status']}`) || {};
        const wrapWithColor = (_value) => {
          let color = '#ddd';
          if (_value && _value.key) {
            switch (_value.key) {
              case 'ACCEPTED':
                color = 'green';
                break;
              case 'SUBMITTED':
                color = 'blue';
                break;
              case 'MODIFIED':
                color = 'indigo';
                break;
              case 'REJECTED':
              case 'CANCELLED':
                color = 'red';
                break;
              default:
            }
            _value = _value.value;
          } else {
            return _value;
          }
          return <span style={{ color }}>{_value}</span>;
        };

        return op.value ? wrapWithColor(op) : value;
      },
    },
    // {
    //   title: "Paredzamais atiešanas laiks no ostas",
    //   key: "etdBerth",
    //   type: "date",
    //   time: true
    //
    // },
    // {
    //   title: "Paredzamais atiešanas laiks no termināļa",
    //   key: "etdTerminal",
    //   type: "date",
    //   time: true
    //
    // }
  ],
  callPurposes: [
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
    {
      title: 'Kuģa darbība',
      key: 'shipOperationTypeId',
      type: 'select',
      optionSource: 'shipOperationTypes',
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kuģa vizītes apmeklējuma mērķis',
      key: 'shipVisitPurposeId',
      type: 'select',
      optionSource: 'shipVisitPurposes',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  shipOperations: [
    {
      title: 'Kuģa darbība',
      key: 'shipOperationTypeId',
      type: 'select',
      optionSource: 'shipOperationTypes',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Iemesls',
      key: 'shipStateChangeReasonId',
      type: 'select',
      optionSource: 'shipStateChangeReasons',
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Sākums',
      key: 'startTime',
      type: 'date',
      time: true,
    },
    {
      title: 'Beigas',
      key: 'endTime',
      type: 'date',
      time: true,
    },
    {
      title: 'Komentāri',
      key: 'comment',
      type: 'textarea',
      hide: true,
    },
  ],
  // shipOperations: [
  //   {
  //     title: 'Kuģa darbība',
  //     key: 'shipOperationTypeId',
  //     type: 'select',
  //     optionSource: 'shipOperationTypes',
  //     rules: [Validate.Required],
  //     extraFieldProps: {
  //       showSearch: true,
  //       optionFilterProp: 'children',
  //     },
  //   },
  //   {
  //     title: 'Iemesls',
  //     key: 'shipStateChangeReasonId',
  //     type: 'select',
  //     optionSource: 'shipStateChangeReasons',
  //     rules: [Validate.Required],
  //     extraFieldProps: {
  //       showSearch: true,
  //       optionFilterProp: 'children',
  //     },
  //   },
  //   {
  //     title: 'Sākums',
  //     key: 'startTime',
  //     type: 'date',
  //     time: true,
  //   },
  //   {
  //     title: 'Beigas',
  //     key: 'endTime',
  //     type: 'date',
  //     time: true,
  //   },
  //   {
  //     title: 'Komentāri',
  //     key: 'comment',
  //     type: 'textarea',
  //     hide: true,
  //   },
  // ],
  operationalNotes: [
    // updated_at
    {
      title: 'Datums & laiks',
      key: 'updated_at',
      type: 'date',
      // label: true,
      time: true,
      asText: true,
    },
    // userId
    {
      title: 'Autors',
      key: 'userId',
      type: 'select',
      optionSource: 'users',
      asText: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // objectType
    {
      title: 'Sadaļa',
      key: 'resourceType',
      type: 'select',
      asText: true,
      // label: true,
      options: [
        { key: 'SHIP', value: 'Kuģis' },
        { key: 'SHIPIN', value: 'Kuģis ienākot' },
        { key: 'SHIPOUT', value: 'Kuģis izejot' },
        { key: 'BERTHTO', value: 'Uz piestātni' },
        { key: 'BERTHFROM', value: 'No piestātnes' },
        { key: 'BERTH', value: 'Piestātne' },
        { key: 'MOVEMENT', value: 'Kustība' },
        { key: 'SHIPVISIT', value: 'Kuģa vizīte' },
      ],
      hide: false,
    },
    // objectId
    // {
    //   // title: 'Kuģa vizīte',
    //   title: 'Resurss',
    //   key: 'resourceId',
    //   type: 'select',
    //   asText: true,
    //   // optionSource: 'shipVisits',
    //   // optionValueName: '_t_shipInId',
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: 'children',
    //   },
    //   // renderOption: shipVisitOptionRenderer,
    //   hide: true,
    // },
    // comment
    {
      title: 'Piezīmes',
      key: 'note',
      type: 'textarea',
    },
    // files
    {
      title: 'Faili', // Pielikumi
      key: 'files',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
  ],
  EditShipVisit_operationalNotes: [
    // updated_at
    {
      title: 'Datums & laiks',
      key: 'updated_at',
      type: 'date',
      asText: true,
      time: true,
    },
    // userId
    {
      title: 'Autors',
      key: 'userId',
      type: 'select',
      optionSource: 'users',
      asText: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // objectType
    {
      title: 'Sadaļa',
      key: 'resourceType',
      type: 'select',
      asText: true,
      options: [
        { key: 'SHIP', value: 'Kuģis' },
        { key: 'BERTH', value: 'Piestātne' },
        { key: 'MOVEMENT', value: 'Kustība' },
        { key: 'SHIPVISIT', value: 'Kuģa vizīte' },
      ],
      hide: true,
    },
    // objectId
    {
      title: 'Kuģa vizīte',
      key: 'resourceId',
      type: 'select',
      asText: true,
      optionSource: 'shipVisits',
      optionValueName: '_t_shipInId',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      renderOption: shipVisitOptionRenderer,
      hide: true,
    },
    // comment
    {
      title: 'Piezīmes',
      key: 'note',
      type: 'textarea',
    },
    // files
    {
      title: 'Faili', // Pielikumi
      key: 'files',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
  ],
  files: [
    {
      title: 'Objekta, kuram piesaistīts fails, veids',
      key: 'objectType',
      type: 'select',
      // asText: true,
      label: true,
      options: [
        { key: 'CONTRACT', value: 'Līgums' },
        { key: 'OPERATIONALNOTE', value: 'Operatīvā piezīme' },
      ],
      hide: true,
    },
    {
      title: 'Nosaukums',
      key: 'name',
      asText: true,
      hide: true,
    },
  ],
  EditShip_operationalNotes: [
    // updated_at
    {
      title: 'Datums & laiks',
      key: 'updated_at',
      type: 'date',
      asText: true,
      time: true,
    },
    // userId
    {
      title: 'Autors',
      key: 'userId',
      type: 'select',
      optionSource: 'users',
      asText: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // resourceType
    {
      title: 'Sadaļa',
      key: 'resourceType',
      type: 'select',
      asText: true,
      options: [
        { key: 'SHIP', value: 'Kuģis' },
        { key: 'BERTH', value: 'Piestātne' },
        { key: 'MOVEMENT', value: 'Kustība' },
        { key: 'SHIPVISIT', value: 'Kuģa vizīte' },
      ],
      hide: true,
    },
    // resourceId
    {
      title: 'Kuģis',
      key: 'resourceId',
      type: 'select',
      asText: true,
      optionSource: 'ships',
      hide: true,
    },
    // comment
    {
      title: 'Piezīmes',
      key: 'note',
      type: 'textarea',
    },
    // files
    {
      title: 'Faili', // Pielikumi
      key: 'files',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
  ],
  EditBerth_operationalNotes: [
    // updated_at
    {
      title: 'Datums & laiks',
      key: 'updated_at',
      type: 'date',
      asText: true,
      time: true,
    },
    // userId
    {
      title: 'Autors',
      key: 'userId',
      type: 'select',
      optionSource: 'users',
      asText: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // resourceType
    {
      title: 'Sadaļa',
      key: 'resourceType',
      type: 'select',
      asText: true,
      options: [
        { key: 'BERTH', value: 'Piestātne' },
        { key: 'SHIP', value: 'Kuģis' },
        { key: 'MOVEMENT', value: 'Kustība' },
        { key: 'SHIPVISIT', value: 'Kuģa vizīte' },
      ],
      hide: true,
    },
    // resourceId
    {
      title: 'Piestātne',
      key: 'resourceId',
      type: 'select',
      asText: true,
      optionSource: 'berths',
      // optionKeyName: 'id',
      // optionValueName: 'name',
      hide: true,
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    // comment
    {
      title: 'Piezīmes',
      key: 'note',
      type: 'textarea',
    },
    // files
    {
      title: 'Faili', // Pielikumi
      key: 'files',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
  ],
  shipTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Kuģu grupa',
      key: 'shipTypeGroupId',
      type: 'select',
      optionSource: 'shipTypeGroups',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Iekšējais kods',
      key: 'internalCode',
      rules: [Validate.Required],
    },
    {
      title: 'Krāsa kartē',
      key: 'mapColor',
      type: 'colorpicker',
      columnRenderMethod: 'renderColorColumn',
      hide: true,
      rules: [Validate.Required],
    },
    {
      title: 'SKLOIS kods',
      key: 'skloisCode',
    },
  ],
  shipTypeGroups: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'ICST-COM kods',
      key: 'icstCode',
      rules: [Validate.Required],
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
  ],
  shipDocumentTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
    {
      title: 'Obligāts',
      key: 'mandatory',
      type: 'checkbox',
    },
  ],
  shipCapacityClasses: [
    {
      title: 'Klase',
      key: 'class',
      rules: [Validate.Required],
    },
    {
      title: 'Min. kravnesība (DWT)',
      key: 'minDwt',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
    {
      title: 'Min. bruto tonnāža',
      key: 'minGrossTons',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
    {
      title: 'Max. kravnesība (DWT)',
      key: 'maxDwt',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
    {
      title: 'Max. bruto tonnāža',
      key: 'maxGrossTons',
      number: true,
      integer: true,
      rules: [Validate.Required, Validate.Number],
    },
  ],
  containerTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
  ],
  cargoClassifications: [
    {
      title: 'Kravas klasificēšanas rindkopa',
      key: 'pattern',
      rules: [Validate.Required],
    },
    {
      title: 'Preces (kravas) veids',
      key: 'cargoTypeId',
      type: 'select',
      optionSource: 'cargoTypesSelect',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  cargoTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      hide: true,
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Preču grupa',
      key: 'cargoGroupId',
      type: 'select',
      optionSource: 'cargoGroups',
      rules: [Validate.Required],
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kravas veids',
      key: 'cargoGoodsTypeId',
      type: 'select',
      optionSource: 'cargoGoodsTypes',
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kods (EK direktīva 95/64/EC)',
      key: 'codeEc',
      rules: [Validate.Required],
    },
    {
      title: 'Kods (iekšējai lietošanai)',
      key: 'code',
      rules: [Validate.Required],
    },
    {
      title: 'Atsevišķu vienību uzskaite',
      key: 'separateUnitAccounting',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Papildmērvienība',
      key: 'unitId',
      type: 'select',
      optionSource: 'units',
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Koeficients pārejai uz tonnām',
      key: 'convertToTons',
      number: true,
      rules: [Validate.Number],
      hide: true,
    },
    {
      title: 'Kombinētā nomenklatūra',
      key: 'combinedNomenclature',
      hide: true,
    },
    {
      title: 'NST/R kods',
      key: 'nstrCode',
    },
    {
      title: 'Dzēsts',
      key: 'deleted',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
  ],
  cargoGroups: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Virsgrupa',
      key: 'cargoGroupId',
      type: 'select',
      optionSource: 'cargoGroups',
      hide: true,
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'NST/R kods',
      key: 'nstrCode',
      rules: [Validate.Required],
    },
    {
      title: 'CN kods',
      key: 'cnCode',
      rules: [Validate.Required],
    },
    {
      title: 'Iekšējās lietošanas kods',
      key: 'internalCode',
    },
  ],
  cargoGoodsTypeGroups: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Kods (iekšējai lietošanai)',
      key: 'code',
      rules: [Validate.Required],
    },
  ],
  cargoGoodsTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
    {
      title: 'Kods (EK direktīva 95/64/EC)',
      key: 'codeEc',
      rules: [Validate.Required],
    },
    {
      title: 'Kods (iekšējai lietošanai)',
      key: 'code',
      rules: [Validate.Required],
    },
    {
      title: 'Kravas veida grupa',
      key: 'cargoGoodsTypeGroupId',
      type: 'select',
      optionSource: 'cargoGoodsTypeGroups',
      hide: true,
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Dzēsts',
      key: 'deleted',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
  ],
  cargoStatuses: [
    {
      title: 'Apraksts',
      key: 'description',
      rules: [Validate.Required],
    },
  ],
  shipsStoreTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Mērvienība',
      key: 'unitId',
      type: 'select',
      optionSource: 'units',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Obligāts',
      key: 'required',
      type: 'checkbox',
      hide: true,
    },
  ],
  units: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
      hide: true,
    },
  ],
  shipOperationTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Kods',
      key: 'code',
    },
  ],
  shipStateChangeReasons: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
  ],
  tariffTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
  ],
  wasteGroups: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
    },

    {
      title: 'Kārtas nr.',
      key: 'orderNum',
    },
    {
      title: 'Marpol grupa',
      key: 'marpolGroupId',
      type: 'select',
      optionSource: 'marpolGroups',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
  ],
  wasteTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
    },
    {
      title: 'Atkritumu grupa',
      key: 'wasteGroupId',
      type: 'select',
      optionSource: 'wasteGroups',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Pakārtots zem atkritumu veida',
      key: 'parentWasteTypeId',
      type: 'select',
      optionSource: 'wasteTypes',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      // rules: [Validate.Required],
      renderOption: (option, row) => {
        const title = `${option.value} (${option._t_wasteGroupId}) - ${option.notActive ? 'Neaktīvs' : 'Aktīvs'}`;
        const fields = [
          <span>{option.value}</span>,
          option._t_wasteGroupId ? <strong>&nbsp;({option._t_wasteGroupId})</strong> : undefined,
          ' - ' + (option.notActive ? 'Neaktīvs' : 'Aktīvs'),
        ];
        if (row && row.id && option.key === row.id) {
          return null;
        }
        return (
          <div style={{ display: 'table' }} title={title}>
            {fields
              .filter((i) => !!i)
              .map((f, i) => (
                <div
                  key={i}
                  style={{
                    display: 'table-cell',
                    width: i === fields.length - 1 ? null : '150px',
                  }}
                >
                  {f}
                </div>
              ))}
          </div>
        );
      },
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'title',
      },
    },
    {
      title: 'Kārtas nr.',
      key: 'orderNum',
    },
    {
      title: 'Nav aktīvi',
      key: 'notActive',
      type: 'checkbox',
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
  ],
  portRegions: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Horizontālā koordināte',
      key: 'x1',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Vertikālā koordināte',
      key: 'y1',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Horizontālā koordināte',
      key: 'x2',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Vertikālā koordināte',
      key: 'y2',
      rules: [Validate.Required],
      hide: true,
    },
  ],
  shoreZones: [
    {
      title: 'Kods',
      key: 'code',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Valsts',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  iceClassCertifiers: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Īsais nosaukums',
      key: 'shortName',
      rules: [Validate.Required],
    },
  ],
  ports: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Valsts',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      optionPrefixFields: ['isoCode'],
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'UN/LOCODE',
      key: 'code',
      rules: [Validate.Required, Validate.CodeDuplicate],
    },
    {
      title: 'Jūras piekrastes zona',
      key: 'shoreZoneId',
      type: 'select',
      optionSource: 'shoreZones',
      rules: [Validate.Required],
    },
    {
      title: 'Saite',
      key: 'url',
      hide: true,
    },
    {
      title: 'Bloķēt turpmākai izmantošanai',
      key: 'blocked',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Informācija par dokumentu',
      key: 'ownable',
      type: 'ownable',
      hide: true,
    },
  ],
  currency: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
  ],
  tags: [
    {
      title: 'Atslēgas vārds',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Virsmezgls',
      key: 'tagId',
      type: 'select',
      optionSource: 'tags',
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  typeOfAction: [
    {
      title: 'Type of Action',
      key: 'name',
    },
  ],
  discounts: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Sākums',
      key: 'startDate',
      type: 'date',
    },
    {
      title: 'Beigas',
      key: 'endDate',
      type: 'date',
    },
    {
      title: 'Atlaide',
      key: 'discount',
      tooltip: 'Atlaide procentos',
      render: (v) => (v ? `${v}%` : ''),
      number: true,
      addonAfter: '%',
      rules: [
        {
          messagePrefix: 'Lūdzu ievadiet procentu likmi.',
          min: 0,
          max: 100,
          ...Validate.Number,
        },
        Validate.Required,
      ],
    },
    {
      title: 'Piezīmes',
      key: 'comments',
      type: 'textarea',
      hide: true,
    },
    // tabula
    {
      title: 'Nosacījumi atlaides piemērošanai',
      key: 'discountConditions',
      type: 'table',
      noTable: true,
    },
  ],
  banks: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Bankas kods (SWIFT/BIC)',
      key: 'code',
      rules: [Validate.Required],
    },
    {
      title: 'Valsts',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Pilsēta',
      key: 'city',
    },
    {
      title: 'Iela',
      key: 'street',
      hide: true,
    },
    {
      title: 'Pasta indekss',
      key: 'zip',
      hide: true,
    },
  ],
  currencies: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
  ],
  bankAccounts: [
    {
      title: 'Uzņēmums',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Banka',
      key: 'bankId',
      type: 'select',
      optionSource: 'banks',
      rules: [Validate.Required],
    },
    {
      title: 'Konts',
      key: 'account',
      hide: true,
      tooltip: 'Lūdzu ievadiet konta numuru. IBAN kontiem tiks veikta pārbaude, vai konta numurs ir korekts.',
      rules: [Validate.Required, Validate.IBAN],
    },
    {
      title: 'Valūta',
      key: 'currencyId',
      type: 'select',
      optionSource: 'currencies',
    },
    {
      title: 'Konta nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Mērķis',
      key: 'purpose',
    },
  ],
  territorialRevenueServices: [
    {
      title: 'Ieņēmumu dienesta teritoriālā iestāde',
      key: 'name',
      rules: [Validate.Required],
    },
  ],
  countries: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
    },
    {
      title: 'ISO 3166 (2 burtu) kods',
      key: 'isoCode',
    },
    {
      title: 'Statistisko pārskatu (2 burtu + 2 ciparu) kods',
      key: 'staticReportCode',
    },
    {
      title: 'MID kods (MMSI kodu pirmie 3 cipari)',
      key: 'midCode',
    },
    {
      title: 'Telefona starptautiskais kods',
      key: 'intlCountryCode',
    },
  ],
  cruiseShipVisits: [
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      optionKeyName: 'id',
      optionValueName: 'name',
      rules: [Validate.Required],
      render: (v, row) => {
        return <span>{row._t_shipId ? row._t_shipId : v}</span>
      },
      // optionsSortFunc: (a, b) => a.value ?  a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null,
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      addIfNotFound: true,
    },
    {
      title: 'ETA',
      key: 'eta',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'ETD',
      key: 'etd',
      type: 'date',
      time: true,

    },
    {
      title: 'Aģents',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      optionKeyName: 'id',
      optionValueName: 'name',
      render: (v, row) => {
        return <span>{row._t_companyId ? row._t_companyId : v}</span>
      },
      // optionsSortFunc: (a, b) => a.value ?  a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null,
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Piestātne',
      key: 'berthId',
      type: 'select',
      optionSource: 'berths',
      optionKeyName: 'id',
      optionValueName: 'name',
      render: (v, row) => {
        return <span>{row._t_berthId ? row._t_berthId : v}</span>
      },
      // optionsSortFunc: (a, b) => a.value ?  a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null,
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: '60 % no pasažierietilpības',
      number: true,
      integer: true,
      rules: [Validate.Number],
      key: 'sixtyPercentOfCapacity',
      hide: false,
    },
    {
      title: 'GT',
      number: true,
      integer: true,
      rules: [Validate.Number],
      key: 'GT',
      hide: false,
    },
  ],
  cruiseDiscounts: [
      {
          title: 'Nosaukums',
          key: 'name',
          rules: [Validate.Required],
      },
      {
          title: 'Sākums',
          key: 'startDate',
          type: 'date',
      },
      {
          title: 'Beigas',
          key: 'endDate',
          type: 'date',
      },
      {
          title: 'Atlaide',
          key: 'discount',
          tooltip: 'Atlaide procentos',
          render: (v) => (v ? `${v}%` : ''),
          number: true,
          addonAfter: '%',
          rules: [
              {
                  messagePrefix: 'Lūdzu ievadiet procentu likmi.',
                  min: 0,
                  max: 100,
                  ...Validate.Number,
              },
              Validate.Required,
          ],
      },
      {
          title: 'Piezīmes',
          key: 'comments',
          type: 'textarea',
          hide: true,
      },
      // tabula
      {
          title: 'Nosacījumi kruīza kuģu atlaides piemērošanai',
          key: 'discountConditions',
          type: 'table',
          noTable: true,
      },
  ],
  ships: [
    {
      title: 'Mazais kuģis',
      key: 'isSmall',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Ostas flotes kuģis',
      key: 'isPortFleetShip',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Riska kuģis',
      key: 'isRiskShip',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'MMSI kods',
      key: 'mmsi',
      rules: [Validate.Mmsi, Validate.MmsiDuplicate]
    },
    {
      title: 'IMO kods',
      key: 'imo',
      rules: [Validate.Imo, Validate.IMODuplicate],
    },
    {
      title: 'Izsaukums',
      key: 'callsign',
      hide: false,
    },
    {
      title: 'Garums',
      key: 'length',
      number: true,
      rules: [Validate.Decimal],
      hide: false,
    },
    {
      title: 'Kuģa tips',
      key: 'shipTypeId',
      type: 'select',
      optionSource: 'shipTypes',
      rules: [Validate.Required],
      hide: false,
      extraFieldProps: {
          showSearch: true,
          optionFilterProp: 'children',
      },
    },
    {
      title: 'Ir atļauja kuģošana bez loča',
      key: 'permitRequired',
      type: 'checkbox',
      hide: false,
    },
    {
      title: 'Ieraksts izveidots',
      key: 'created_at',
      type: 'date',
      defaultSortOrder: 'desc',
      time: true,
      hide: false,
    },
    {
            title: 'Ieraksts atjaunots',
            key: 'updated_at',
            type: 'date',
            time: true,
            hide: false,
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Bilde',
      key: 'files',
      type: 'picture',
      tooltip: 'Lūdzu pievienojiet png, jpeg, bmp vai gif formāta datni.',
      noTable: true,
    },
    {
      title: 'Karogs',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Platums',
      key: 'width',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Augstums',
      key: 'height',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Kuģa konstrukcijas maksimālais augstums',
      key: 'maxHeight',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Bruto',
      key: 'grossWeight',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Neto',
      key: 'netWeight',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Segreg.balasts / Reducētais GT(konteinerkuģiem)',
      key: 'seggregatedBallast',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Kravnesība',
      key: 'deadweight',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Iegrime',
      key: 'draft',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Iegrime priekšgalā',
      key: 'draftAtBow',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Iegrime pakaļgalā',
      key: 'draftAtStern',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Dzenskrūves tips',
      key: 'propellerType',
      type: 'select',
      hide: true,
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'FIXED', value: 'Ar fiksētu soli' },
        { key: 'VARIABLE', value: 'Ar maināmu soli' },
      ],
    },
    {
      title: 'Sānskrūves',
      key: 'sideScrews',
      number: true,
      integer: true,
      rules: [Validate.Number],
      hide: true,
    },
    {
      title: 'Korpusa veids',
      key: 'hullType',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Dzinēja jauda (kW)',
      key: 'enginePower',
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Degvielas veids',
      key: 'fuelType',
      type: 'select',
      hide: true,
      optionSource: 'fuelTypes',
      optionKeyName: 'code',
      optionValueName: 'name',
      optionsSortFunc: (a, b) => (a.order < b.order ? -1 : 1),
      render: (v, row) => {
        return <span>{row.fuelType ? row.fuelType : v}</span>
      },
    },
    {
      title: 'Klasifik.sab.',
      key: 'iceClassCertifierId',
      type: 'select',
      optionSource: 'iceClassCertifiers',
      // rules: [Validate.Required],
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Ledus klase',
      key: 'iceClass',
      hide: true,
    },
    {
      title: 'Dati no mērīšanas apliecības',
      key: 'dataFromCertificate',
      type: 'checkbox',
      hide: true,
    },
    {
       title: 'Utilizēts',
       key: 'utilized',
       type: 'checkbox',
       hide: true,
       noTable: true,
    },
    // Piederība
    {
      title: 'Būvgads',
      key: 'yearBuild',
      type: 'date',
      hide: true,
    },
    {
      title: 'Pārbūves gads',
      key: 'yearRebuild',
      type: 'date',
      hide: true,
    },
    {
      title: 'Kuģa īpašnieks/operators',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      hide: true,
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
          "[and]": [
              { "isActive": [true] },
              { "isOwner": [true] }
          ]
      },
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Pieraksta osta',
      key: 'portId',
      type: 'select',
      optionSource: 'ports',
      // rules: [Validate.Required],
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Iepriekšējais vārds',
      key: 'prevShipId',
      type: 'select',
      optionSource: 'ships',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Maiņas datums',
      key: 'changeDate',
      type: 'date',
      hide: true,
    },
    // Papilddati
    {
      title: 'Tilpums',
      key: 'volume',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Klāju skaits',
      key: 'decks',
      number: true,
      integer: true,
      rules: [Validate.Number],
      hide: true,
    },
    {
      title: 'Degvielas veids',
      key: 'fuel',
      hide: true,
    },
    {
      title: 'Degvielas marka',
      key: 'fuelBrand',
      hide: true,
    },
    {
      title: 'Maks. pasažieru skaits',
      key: 'maxPassengers',
      number: true,
      integer: true,
      rules: [Validate.Number],
      hide: true,
    },
    {
      title: 'MMSI piezīmes',
      key: 'mmsiNotes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Informācija par dokumentu',
      key: 'ownable',
      type: 'ownable',
      hide: true,
    },
    {
      title: 'Operatīvās piezīmes',
      key: 'operationalNotes',
      type: 'table',
      noTable: true,
    },
  ],
  // Līnijas
  shippingLines: [
    {
      title: 'Līnija',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Uzņēmums',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Pieļaujamā nobīde (stundās)',
      key: 'allowedDeviation',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Darbojas',
      key: 'isOperating',
      type: 'checkbox',
    },
    {
      title: 'Reģistrēta',
      key: 'registrationDate',
      type: 'date',
    },
    {
      title: 'Darbosies līdz',
      key: 'endDate',
      type: 'date',
    },
    {
      title: 'Grafiks',
      key: 'hasTimetable',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Vizīšu skaits',
      key: 'shippingLineDiscounts',
      type: 'table',
      noTable: true,
    },
  ],
  EditLine_shippingLineDiscounts: [
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'Gads',
      key: 'rebateYear',
      type: 'date',
      rules: [Validate.Required],
      extraFieldProps: {
        mode: 'year',
      },
    },
    {
      title: 'Skaits',
      key: 'rebateCount',
      type: 'number',
      rules: [Validate.Required],
      extraFieldProps: {
        placeholder: 'Norādiet skaitu',
      },
    },
  ],
  shippingLineDiscounts: [
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'Gads',
      key: 'rebateYear',
      type: 'number',
      number: true,
      rules: [Validate.Required],
    },
    {
      title: 'Plānotais vizīšu skaits',
      key: 'rebateCount',
      type: 'number',
      number: true,
      rules: [Validate.Required],
    },
  ],
  shippingLinesSchedule: [
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      renderOption: shipOptionRenderer,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kuģa IMO numurs',
      key: 'ship.imo',
      render: (v, row) => (row?.ship?.imo ? row.ship.imo : ""),
      type: 'text',
    },
    {
      title: 'Līnija',
      key: 'shippingLineId',
      type: 'select',
      optionSource: 'shippingLines',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionFilters: {
        isOperating: [true],
      }
    },
    {
      title: 'Sākums',
      key: 'startDate',
      type: 'date',
    },
    {
      title: 'Beigas',
      key: 'endDate',
      type: 'date',
    },
    {
      title: 'Grafiks',
      key: 'hasTimetable',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Līniju grafika ieraksti',
      key: 'timetableEntries',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Līniju grafika ievērošana',
      key: '_scheduleAdherence',
      type: 'table',
      noTable: true,
    },
  ],
  EditLineSchedule__scheduleAdherence: [
    {
      title: 'Kuģa vizīte',
      key: 'skloisVisitCode',
    },
    {
      title: 'Kuģa ienākšana ostā (faktiskā)',
      key: 'svAta',
      type: 'date',
      time: true,
    },
    {
      title: 'Kuģa iziešana no ostas (faktiskā)',
      key: 'svAtd',
      type: 'date',
      time: true,
    },
    {
      title: 'Statuss',
      key: 'allowedDeviation',
    },
  ],
  shippingLinesPlan: [
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // {
    //   title: "IMO kods",
    //   key: "imo"
    //
    // },
    {
      title: 'Līnija',
      key: 'shippingLineId',
      type: 'select',
      optionSource: 'shippingLines',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionFilters: {
        isOperating: [true],
      }
    },
    // {
    //   title: "Īpašnieks",
    //   key: "companyId",
    //   type: "select",
    //   optionSource: "companies",

    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children"
    //   }
    // },
    {
      title: 'ETA',
      key: 'eta',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'ETD',
      key: 'etd',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'Pienākšana pieteikta savlaicīgi',
      key: 'timelyArrival',
      type: 'checkbox',
      labelRight: true,
    },
    {
      title: 'Aiziešana pieteikta savlaicīgi',
      key: 'timelyDeparture',
      type: 'checkbox',
      labelRight: true,
    },
  ],
  EditLineSchedule_timetableEntries: [
    // {
    //   title: "Kuģis",
    //   key: "shipId",
    //   type: "select",
    //   optionSource: "ships",

    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children"
    //   }
    // },
    // {
    //   title: "IMO kods",
    //   key: "imo"
    //
    // },
    // {
    //   title: "Līnija",
    //   key: "shippingLineId",
    //   type: "select",
    //   optionSource: "shippingLines",

    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children"
    //   }
    // },
    // {
    //   title: "Īpašnieks",
    //   key: "companyId",
    //   type: "select",
    //   optionSource: "companies",

    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children"
    //   }
    // },
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'ETA',
      key: 'eta',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'ETD',
      key: 'etd',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'Pienākšana pieteikta savlaicīgi',
      key: 'timelyArrival',
      type: 'checkbox',
      labelRight: true,
    },
    {
      title: 'Aiziešana pieteikta savlaicīgi',
      key: 'timelyDeparture',
      type: 'checkbox',
      labelRight: true,
    },
  ],
  contractCodes: [
    {
      title: 'Kods',
      key: 'code',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
  ],
  // termināli
  terminals: [
    {
      title: 'Termināla nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    // {
    //   title: "Darbojošies uzņēmumi",
    //   key: "companyList",
    //   type: "multiselect",
    //   hide: true,
    //   optionKeyName: "id",
    //   optionValueName: "name",
    //   optionSource: "companies",
    // },
    // {
    //   title: "Piestātnes",
    //   key: "berthList",
    //   type: "multiselect", // multiselect or multicheckbox
    //   hide: true,
    //   optionKeyName: "id",
    //   optionValueName: "name",
    //   optionSource: "berths",
    //   optionsSortFunc: (a, b) => a.value ?  a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null,
    // },
    // {
    //   title: "Piestātnes",
    //   key: "berths",
    //   type: "table",
    //   computed: (value) => value,
    //   hide: true,
    //   noTable: true
    // },
    {
      title: 'Pievienotie dokumenti',
      key: 'documents',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
    {
      title: 'Uzņēmumi, kuri darbojas terminālī',
      key: 'companies',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Piestātnes',
      key: 'berths',
      type: 'table',
      noTable: true,
    },
  ],
  pilots: [
    {
      title: 'Loča uzvārds un iniciāļi',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Bloķēt turpmākai izmantošanai',
      key: 'blocked',
      type: 'checkbox',
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
    },
  ],
  resourceTypes: [
    {
      title: 'Resursu tipa nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Resursu tipa apraksts',
      key: 'description',
      rules: [Validate.Required],
    },
  ],
  resourceUseTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Apraksts',
      key: 'description',
      rules: [Validate.Required],
    },
  ],
  resources: [
    {
      title: 'Resursa tips',
      key: 'resourceTypeId',
      type: 'select',
      optionSource: 'resourceTypes',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Resursa nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Jauda',
      key: 'power',
      rules: [Validate.Decimal],
    },
    // {
    //   title: 'test',
    //   key: 'treeselect',
    //   type: 'treeselect',
    //   optionValueName: 'title',
    //   optionSource: 'dataset',
    // },
  ],
  resourceUses: [
    {
      title: 'Dokumenta numurs',
      key: 'documentNr',
      rules: [Validate.Required],
    },
    {
      title: 'Tarifs',
      key: 'tarif',
      rules: [Validate.Required],
    },
    {
      title: 'Sākuma laiks',
      key: 'startTime',
      type: 'date',
      time: true,
    },
    {
      title: 'Beigu laiks',
      key: 'endTime',
      type: 'date',
      time: true,
    },
    {
      title: 'Velkonis',
      key: 'resourceId',
      type: 'select',
      optionSource: 'resources',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Pielietojumi',
      key: 'uses',
      type: 'multicheckbox',
      multiple: true,
      optionSource: 'resourceUseTypes',
      hide: true,
    },
  ],
  resourceRequests: [
    {
      title: 'Uzņēmums',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Resurss',
      key: 'resourceId',
      type: 'select',
      optionSource: 'resources',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Vizīte',
      key: 'shipVisitId',
      type: 'select',
      optionSource: 'shipVisits',
      optionValueName: '_t_shipInId',
      optionFilters: {},
      renderOption: shipVisitOptionRenderer,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Piestātne',
      key: 'berthId',
      type: 'select',
      optionSource: 'berths',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    {
      title: 'Vēlamais laiks',
      key: 'desiredTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Plānotais laiks',
      key: 'actualTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Statuss',
      key: 'status',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'NEW', value: 'Jauns' },
        { key: 'ACCEPTED', value: 'Apstiprināts' },
        { key: 'REJECTED', value: 'Noraidīts' },
        { key: 'PROGRESS', value: 'Izpildē' },
        { key: 'COMPLETED', value: 'Pabeigts' },
      ],
    },
    {
      title: 'Pabeigšanas laiks',
      key: 'completionTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Apmaksāts',
      key: 'paid',
      type: 'checkbox',
      labelRight: true,
      hide: true,
    },
    {
      title: (
        <span>
          Papildus informācija{' '}
          <Tooltip title="Papildus informācija, kas var būt svarīga drošai pakalpojuma nodrošināšanai">
            <FontAwesomeIcon icon="info-circle" />
          </Tooltip>
        </span>
      ),
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Kuģa ETA (Irbes meridiāns)',
      key: 'etaIrbeMeridian',
      type: 'date',
      time: true,
    },
    {
      title: 'Kuģa stāvoklis',
      key: 'shipCondition',
      type: 'select',
      // rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'BALLAST', value: 'Balastā' },
        { key: 'PARTLYLOADED', value: 'Daļēji piekrauts (Krava<75%)' },
        { key: 'LOADED', value: 'Piekrauts (Krava>75%)' },
      ],
    },
    {
      title: 'Degvielas krājumi (t)',
      key: 'fuelStock',
      number: true,
    },
  ],
  resourceTasks: [
    {
      title: 'Autors',
      key: 'userId',
      type: 'select',
      optionSource: 'users',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Resursa pieteikums',
      key: 'resourceRequestId',
      type: 'select',
      optionSource: 'resourceRequests',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      renderOption: (option) => {
        const fields = [option._t_companyId && `[ ${option._t_companyId} ] - `, option._t_resourceId];
        return (
          <div style={{ display: 'table' }}>
            {fields
              .filter((i) => i)
              .map((f, i) => (
                <div
                  key={i}
                  style={{
                    display: 'table-cell',
                    width: i === fields.length - 1 ? null : '150px',
                  }}
                >
                  {f}
                </div>
              ))}
          </div>
        );
      },
    },
    {
      title: 'Resurss',
      key: 'resourceId',
      type: 'select',
      optionSource: 'resources',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Vizīte',
      key: 'shipVisitId',
      type: 'select',
      optionSource: 'shipVisits',
      optionValueName: '_t_shipInId',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      renderOption: shipVisitOptionRenderer,
    },
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Piestātne',
      key: 'berthId',
      type: 'select',
      optionSource: 'berths',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    {
      title: 'Vēlamais laiks',
      key: 'desiredTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Darba uzdevums',
      key: 'workDescription',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Pabeigšanas laiks',
      key: 'completionTime',
      hide: true,
      type: 'date',
      time: true,
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Statuss',
      key: 'status',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'NEW', value: 'Jauns' },
        { key: 'ACCEPTED', value: 'Apstiprināts' },
        { key: 'REJECTED', value: 'Noraidīts' },
        { key: 'PROGRESS', value: 'Izpildē' },
        { key: 'COMPLETED', value: 'Pabeigts' },
      ],
    },
  ],
  berths: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Termināls',
      key: 'terminalId',
      type: 'select',
      optionSource: 'terminals',
      // rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Ostas rajons',
      key: 'portRegionId',
      type: 'select',
      optionSource: 'portRegions',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Īpašnieks vai nomnieks',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isCargoLoader: [true],
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Ir rezervējama',
      key: 'isRequestable',
      type: 'checkbox',
      hide: true,
    },
    // Piestātnes tips
    {
      title: 'Tips',
      key: 'berthType',
      type: 'select',
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'BERTH', value: 'Sauszemes' },
        { key: 'RAID', value: 'Reids' },
        { key: 'CANAL', value: 'Iekšējais reids, kanāls' },
        { key: 'DRIFT', value: 'Dreifs' },
        // { key: null, value: "Nav uzdots" }
      ],
    },
    {
      title: 'Piestātnes garums',
      key: 'berthLength',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Piestātnes platums',
      key: 'berthWidth',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Pieļaujamā iegrime',
      key: 'berthDepth',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Pieļaujamā iegrime izmantojot pontonu',
      key: 'berthPontoonDepth',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Piestātne ir darba kārtībā',
      key: 'berthInUse',
      type: 'checkbox',
      // hide: true,
    },
    {
      title: 'Rādīt brīdinājumu',
      key: 'showWarning',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Brīdinājums',
      key: 'warning',
      type: 'textarea',
      tooltip: 'Šis teksts parādīsies, liekot kuģi klāt pie šīs piestātnes!',
      hide: true,
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Sarakste',
      key: 'comments',
      type: 'textarea',
      hide: true,
    },
    // Īpašnieks un lietotājs
    {
      title: 'Īpašnieks',
      key: 'owner',
      hide: true,
    },
    {
      title: 'RPB valdījums',
      key: 'ownerRbp',
      type: 'select',
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'R', value: 'Īpašnieks - pašvaldība' },
        { key: 'V', value: 'Īpašnieks - valsts' },
      ],
      hide: true,
    },
    {
      title: 'Piestātnes nomnieks vai lietotājs',
      key: 'user',
      hide: true,
    },
    {
      title: 'Nomas līguma numurs',
      key: 'leaseContract',
      hide: true,
    },
    {
      title: 'Nomas līguma termiņš',
      key: 'leaseTerm',
      type: 'date',
      hide: true,
    },
    {
      title: 'Kadastra apzīmējums',
      key: 'cadastreNr',
      hide: true,
    },
    {
      title: 'ZG nodalījuma numurs', // ZG - Zemesgāmatas
      key: 'landParcelNr',
      hide: true,
    },
    {
      title: 'Kadastrālā vērtība',
      key: 'cadastreValue',
      hide: true,
    },
    {
      title: 'Inventāra numurs',
      key: 'inventoryNr',
      hide: true,
    },
    {
      title: 'Bilancē no',
      key: 'balanceSheetFrom',
      type: 'date',
      extraFieldProps: { allowClear: true },
    },

    // Piekļaujas pie zemes
    {
      title: 'Nomnieks vai īpašnieks',
      key: 'landOwner',
      hide: true,
    },
    {
      title: 'Kadastra numurs',
      key: 'landCadastreNr',
      hide: true,
    },
    {
      title: 'Apzīmējumi',
      key: 'landCadastreNote',
      hide: true,
    },
    {
      title: 'Adrese',
      key: 'address',
      hide: true,
    },
    {
      title: 'Pasta indekss',
      key: 'zip',
      hide: true,
    },
    {
      title: 'Inžiniertehniskie dati',
      key: 'berthTechnicalData',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Pievienotie dokumenti',
      key: 'documents',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
  ],
  EditBerth_berthTechnicalData: [
    {
      title: 'Tehniskais stāvoklis',
      key: 'technicalState',
      rules: [Validate.Required],
    },
    {
      title: 'Piestātnes garums',
      key: 'length',
      number: true,
      rules: [Validate.Decimal],
    },
    {
      title: 'Maks. kuģu garums',
      key: 'maxShipLength',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Maks. kuģu iegrime',
      key: 'maxShipDraft',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Izbūves/rekonstrukcijas datums',
      key: 'constructionDate',
    },
    {
      title: 'Pases izdošanas gads',
      key: 'passportYear',
    },
    {
      title: 'Gultnes projekta atzīme',
      key: 'bedProjectMark',
      hide: true,
    },
    {
      title: 'Atrodas uz',
      key: 'residesOn',
    },
    {
      title: 'Pamatojums',
      key: 'reason',
      hide: true,
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
  ],
  berthTechnicalData: [
    {
      title: 'Tehniskais stāvoklis',
      key: 'technicalState',
      rules: [Validate.Required],
    },
    {
      title: 'Piestātnes garums',
      key: 'length',
      number: true,
      rules: [Validate.Decimal],
    },
    {
      title: 'Maks. kuģu garums',
      key: 'maxShipLength',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Maks. kuģu iegrime',
      key: 'maxShipDraft',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Izbūves/rekonstrukcijas datums',
      key: 'constructionDate',
    },
    {
      title: 'Pases izdošanas gads',
      key: 'passportYear',
    },
    {
      title: 'Gultnes projekta atzīme',
      key: 'bedProjectMark',
      hide: true,
    },
    {
      title: 'Atrodas uz',
      key: 'residesOn',
    },
    {
      title: 'Pamatojums',
      key: 'reason',
      hide: true,
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
  ],
  shipVisitPurposes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
    },
    {
      title: 'Kuģa darbība ostā',
      key: 'shipOperationTypeId',
      type: 'select',
      optionSource: 'shipOperationTypes',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'SKLOIS kods',
      key: 'skloisCode',
      rules: [Validate.Required],
    },
  ],
  // Pārējie
  contracts: [
    {
      title: 'Indikācijas',
      key: '_indicators',
      type: 'indicator',
      inline: true,
      sorter: false,
      filter: false,
      computed: (_value, _prevValue, row) => parseFloat(row.amountUsed) > parseFloat(row.amount),
      render: (value, row) => {
        if (value && row.amountUsed) {
          const { amountUsed, amount, currency: _curr } = row;
          const { name: _currency = 'EUR' } = _curr || {};
          return (
            <Tooltip
              placement="topLeft"
              title={
                <span>
                  Kalkulāciju summa{' '}
                  <strong>
                    {amountUsed} {_currency}
                  </strong>{' '}
                  pārsniedz līgumā norādīto summu{' '}
                  <strong>
                    {amount} {_currency}
                  </strong>
                  .
                </span>
              }
              arrowPointAtCenter
            >
              <FontAwesomeIcon
                icon="exclamation-triangle"
                type="solid"
                style={{ color: 'red', fontSize: '0.8em', lineHeight: '0.9em' }}
              />
            </Tooltip>
          );
        }
        return '';
      },
    },
    {
      title: 'Kalkulāciju summa par līguma periodu',
      key: 'amountUsed',
      number: true,
      rules: [Validate.Decimal],
      // hide: true,
      noTable: true,
    },
    {
      title: 'Kods',
      key: 'contractCodeId',
      type: 'select',
      optionSource: 'contractCodes',
      renderOption: (option) => {
        return (
          <div style={{ display: 'table' }}>
            <div style={{ display: 'table-cell', minWidth: '50px' }}>{option.code}</div>
            <div style={{ display: 'table-cell' }}>{option.value}</div>
          </div>
        );
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      rules: [Validate.Required],
      optionsSortFunc: (a, b) => a.code.localeCompare(b.code, 'lv', { ignorePunctuation: true }),
    },
    {
      title: 'Līguma nr.',
      key: 'contractNr',
      rules: [Validate.Required],
    },
    {
      title: 'Klients',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    { title: 'Līguma datums', key: 'date', type: 'date', rules: [Validate.Required] },
    { title: 'Termiņš', key: 'period', type: 'date', rules: [Validate.Required] },
    {
      title: 'Pagarināt par mēnešiem',
      key: 'extendByMonths',
      number: true,
      addonAfter: 'mēnešiem',
    },
    {
      title: 'Pagarināt reizes',
      key: 'extendTimes',
      number: true,
      addonAfter: 'reizes',
    },
    {
      title: 'Pamatojums',
      key: 'justification',
      type: 'select',
      rules: [Validate.Required],
      options: [
        { key: 'BOARD', value: 'Valdes lēmums' },
        { key: 'MARKET', value: 'Tirgus izpēte' },
        { key: 'PROCUREMENT', value: 'Iepirkuma procedūra' },
        { key: 'OTHER', value: 'Cits' },
      ],
    },
    {
      title: 'Pamatojuma skaidrojums',
      key: 'explanation',
      type: 'textarea',
      rules: [Validate.Required],
    },
    {
      title: 'Līguma statuss',
      key: 'contractStatus',
      type: 'select',
      // rules: [Validate.Required],
      options: [
        { key: 'ACTIVE', value: 'aktīvs' },
        { key: 'PREPARE', value: 'sagatavošanā' },
        { key: 'CONUFIRMATION', value: 'saskaņošanā' },
        { key: 'DEBT', value: 'palicis parāds' },
        { key: 'HISTORICAL', value: 'vēsturisks līgums' },
        { key: 'NONE', value: 'līguma nav' },
      ],
    },
    {
      title: 'Līguma veids',
      key: 'contractType',
      type: 'select',
      // rules: [Validate.Required],
      options: [
        { key: 'PERMANENT', value: 'pastāvīgs līgums' },
        { key: 'TEMPORARY', value: 'pagaidu līgums' },
        { key: 'SHORTTERM', value: 'īstermiņa līgums' },
      ],
    },
    {
      title: 'Pielikumi',
      key: 'files',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      // validateTrigger: '',
    },
    {
      title: 'Līguma valūta',
      key: 'currencyId',
      type: 'select',
      optionSource: 'currencies',
      hide: true,
    },
    {
      title: 'Summa (bez PVN)',
      key: 'amount',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Summa (ar PVN)',
      key: 'amount_with_vat',
      type: 'label',
      filter: false,
      hide: true,
      sorter: false,
      computed: (value, prevValue, other) => {
        if (!other.vatRate || !other.amount) {
          return '-';
        }
        const cand = currency(other.amount, currencyOpts)
          .multiply(currency(other.vatRate, currencyOpts))
          .divide(100)
          .add(other.amount)
          .format();
        if (!cand) {
          return '';
        }
        return cand;
      },
    },
    {
      title: 'PVN summa',
      key: 'vat_amount',
      type: 'label',
      hide: true,
      sorter: false,
      filter: false,
      computed: (value, prevValue, other) => {
        if (!other.vatRate || !other.amount) {
          return '-';
        }
        const cand = currency(other.amount, currencyOpts)
          .multiply(currency(other.vatRate, currencyOpts))
          .divide(100)
          .format();
        if (!cand) {
          return '';
        }
        return cand;
      },
    },
    {
      title: 'PVN likme',
      key: 'vatRate',
      tooltip: 'PVN procentu likme, piemēram 21',
      render: (v) => (v ? `${v}%` : ''),
      number: true,
      addonAfter: '%',
      rules: [
        {
          messagePrefix: 'Lūdzu ievadiet PVN procentu likmi.',
          min: 0,
          max: 100,
          ...Validate.Decimal,
        },
      ],
      hide: true,
    },
    {
      title: 'Sākotnējā apmaksātā izpilde',
      key: 'paidPart',
      hide: true,
    },
    {
      title: 'Līguma priekšmets',
      key: 'contractSubject',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'PVN kategorija',
      key: 'vatCategory',
      type: 'select',
      hide: true,
    },
    {
      title: 'Papildkods',
      key: 'extraCode',
      hide: true,
    },
    {
      title: 'Saņēmējs',
      key: 'recipient',
      hide: true,
    },
    {
      title: 'Apmaksas termiņš (dienas)',
      key: 'paymentTerm',
      number: true,
      integer: true,
      rules: [
        {
          messagePrefix: 'Nekorekts apmaksas termiņš',
          ...Validate.Number,
          min: 0,
        },
      ],
      hide: true,
    },
    {
      title: 'Dokumentu robeždatums',
      key: 'documentBorderDate',
      number: true,
      integer: true,
      rules: [
        {
          min: 1,
          max: 31,
          ...Validate.Number,
        },
      ],
      hide: true,
    },
    {
      title: 'Kontaktpersona',
      key: 'contactPerson',
      hide: true,
    },
    {
      title: 'E-pasts',
      key: 'email',
      rules: [Validate.Email],
      hide: true,
    },
    {
      title: 'Parakstīšanas datums',
      key: 'signatureDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Reģistrācijas datums',
      key: 'registrationDate',
      type: 'date',
    },
    {
      title: 'Papildus informācija par līgumu',
      key: 'additionalInfo',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Ziņojums sadarbības partnerim',
      key: 'noteToAgent',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Papildus vienošanās',
      key: 'agreements',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Spēkā stāšanās datums',
      key: 'dateOfEntryIntoForce',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Otras puses līguma kods',
      key: 'otherPartyContractCode',
      hide: true,
    },
    {
      title: 'Atbildīgais',
      key: 'personResponsibleId',
      type: 'select',
      optionSource: 'contractusers',
      renderOption: (option) => `${option.value} /${option.name}/`,
      optionValueName: 'fullName',
      // optionsSortFunc: (a, b) => a.fullName.localeCompare(b.fullName, 'lv', { ignorePunctuation: true }),
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      // optionFilters: {
      //   firstName: [true],
      // },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kam nodots',
      key: 'storedUsers',
      type: 'multiselect',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
      optionKeyName: 'id',
      optionValueName: 'fullName',
      renderOption: (option) => `${option.value} /${option.name}/`,
      optionSource: 'contractusers',
      noTable: true,
    },
  ],
  contractusers: [
    {
      title: 'Vārds',
      key: 'fullName',
    },
  ],
  // agreements: [
  //   {
  //     title: 'Numurs',
  //     key: 'number',
  //     number: true,
  //     asText: true,
  //     defaultSortOrder: 'asc',
  //   },
  // ],
  EditContract_agreements: [
    {
      title: 'Numurs',
      key: 'number',
      number: true,
      asText: true,
      defaultSortOrder: 'asc',
    },
    {
      title: 'Papildus vienošanās datums',
      key: 'agreementDate',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Līguma termiņš pagarināts līdz',
      key: 'contractExtendedTo',
      type: 'date',
    },
    {
      title: 'Vienošanās priekšmets',
      key: 'agreementSubject',
      type: 'textarea',
    },
    {
      title: 'Pamatojums',
      key: 'justification',
      type: 'select',
      options: [
        { key: 'BOARD', value: 'Valdes lēmums' },
        { key: 'MARKET', value: 'Tirgus izpēte' },
        { key: 'PROCUREMENT', value: 'Iepirkuma procedūra' },
        { key: 'OTHER', value: 'Cits' },
      ],
    },
    {
      title: 'Pamatojuma skaidrojums',
      key: 'explanation',
      type: 'textarea',
    },
    {
      title: 'Summa (bez PVN)',
      key: 'amount',
      number: true,
      rules: [Validate.Decimal],
      hide: true,
    },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Summa (ar PVN)',
      key: 'amount_with_vat',
      type: 'label',
      filter: false,
      hide: true,
      sorter: false,
      computed: (value, prevValue, other) => {
        if (!other.vatRate || !other.amount) {
          return '-';
        }
        const cand = currency(other.amount, currencyOpts)
          .multiply(currency(other.vatRate, currencyOpts))
          .divide(100)
          .add(other.amount)
          .format();
        if (!cand) {
          return '';
        }
        return cand;
      },
    },
    {
      title: 'PVN summa',
      key: 'vat_amount',
      type: 'label',
      hide: true,
      sorter: false,
      filter: false,
      computed: (value, prevValue, other) => {
        if (!other.vatRate || !other.amount) {
          return '-';
        }
        const cand = currency(other.amount, currencyOpts)
          .multiply(currency(other.vatRate, currencyOpts))
          .divide(100)
          .format();
        if (!cand) {
          return '';
        }
        return cand;
      },
    },
    {
      title: 'PVN likme',
      key: 'vatRate',
      tooltip: 'PVN procentu likme, piemēram 21',
      render: (v) => (v ? `${v}%` : ''),
      number: true,
      addonAfter: '%',
      rules: [
        {
          messagePrefix: 'Lūdzu ievadiet PVN procentu likmi.',
          min: 0,
          max: 100,
          ...Validate.Decimal,
        },
      ],
      hide: true,
    },
    {
      title: 'Pielikumi',
      key: 'files',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
    },
  ],
  changeRecords: [
    {
      title: 'Autors',
      key: 'userId',
      type: 'select',
      optionSource: 'crossUsers',
      label: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      dynamicOptionFilters: (record) => ({
        object: record.userObject,
      }),
      render: (value, row) => {
        if (!value && row.user) {
          value = row.user.name;
        }
        return value;
      },
    },
    {
      title: 'Veids',
      key: 'changeType',
      type: 'select',
      label: true,
      options: [
        {
          key: 'EDITED',
          value: 'Labots',
        },
        {
          key: 'CREATED',
          value: 'Pievienots jauns',
        },
        {
          key: 'DELETED',
          value: 'Dzēsts',
        },
      ],
    },
    {
      title: 'Avots',
      key: 'objectType',
      type: 'select',
      label: true,
      selectSearch: true,
      options: [
        {
          key: `shipVisits`,
          value: `Kuģa vizīte`,
        },
        {
          key: `movements`,
          value: `Kustība`,
        },
        {
          key: `resourceTasks`,
          value: `Resursu norīkojumi`,
        },
        {
          key: `resourceRequests`,
          value: `Resursu pieteikumi`,
        },
        {
          key: `resourceRequests`,
          value: `Resursu pieteikumi`,
        },
        {
          key: `berthVisits`,
          value: `Piestātņu apmeklējumi`,
        },
        {
          key: `shipOperations`,
          value: `Darbības`,
        },
        {
          key: `shipOperations`,
          value: `Darbības`,
        },
        {
          key: `operationalNotes`,
          value: `Operatīvās piezīmes`,
        },
        {
          key: `shipVisitPermits`,
          value: `Atļaujas`,
        },
        {
          key: `crewMembers`,
          value: `Apkalpe`,
        },
        {
          key: `passengers`,
          value: `Pasažieri`,
        },
        {
          key: `cargoConsignments`,
          value: `Kravas sūtījumi`,
        },
        {
          key: `cargos`,
          value: `Krava`,
        },
        {
          key: `shipDocuments`,
          value: `Dokumenti`,
        },
        {
          key: `shipsStores`,
          value: `Kuģu krājumi`,
        },
        {
          key: `wasteDisposals`,
          value: `Kuģu atkritumi`,
        },
        {
          key: `calculations`,
          value: `Kalkulācija`,
        },
        {
          key: `calculationRows`,
          value: `Kalkulācijas ieraksts`,
        },
        {
          key: `calculationRowDetails`,
          value: `Kalkulācijas detaļas`,
        },
        {
          key: `berths`,
          value: `Piestātne`,
        },
        {
          key: `files`,
          value: `Fails`,
        },
        {
          key: `berthDirectories`,
          value: `Mape`,
        },
      ],
    },
    {
      title: 'Identifikators',
      key: 'indicator',
    },
    {
      title: 'Vērtības',
      key: 'diff',
      type: 'json',
      skipIfNoTitle: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Datums & laiks',
      key: 'created_at',
      type: 'date',
      time: true,
      defaultSortOrder: 'desc',
    },
    {
      title: 'Piezīmes',
      key: 'notes',
    },
    // {
    //   title: 'Rīki',
    //   key: 'tools',
    //   type: 'tools',
    //   render: (_value, row, index) => {
    //     console.log(index, this);
    //     // console.log('row: ', row, useHistory());

    //     return (
    //       <Button size="small" onClick={() => true}>
    //         <FontAwesomeIcon icon="eye" />
    //       </Button>
    //     );
    //   },
    // },
  ],
  companies: [
    {
      title: 'Pilns nosaukums',
      key: 'name',
      tooltip: 'Lūdzu ievadiet pilnu uzņēmuma nosaukumu',
      rules: [Validate.Required],
    },
    {
      title: 'Pilns nosaukums(angl.)',
      key: 'nameEn',
      hide: true,
    },
    {
      title: 'Saīsināts nosaukums',
      key: 'shortName',
      rules: [Validate.Required],
    },
    {
      title: 'Reģistrācijas numurs',
      key: 'regNr',
      rules: [Validate.Required, Validate.RegNrDuplicate],
    },
    {
      title: 'PVN numurs',
      key: 'vatNr',
      hide: true,
      tooltip: 'Lūdzu ievdiet derīgu jebkuras ES valsts PVN numuru, piemēram LV90000512408',
      rules: [Validate.VAT],
    },
    {
      title: 'Klienta kods',
      key: 'accountingCode',
      hide: true,
      tooltip: 'Klienta kods grāmatvedības sistēmā',
    },
    {
      title: 'E-pasts',
      key: 'email',
      type: 'email',
      rules: [Validate.Email],
    },
    {
      title: 'Banka',
      key: 'bankId',
      type: 'select',
      optionSource: 'banks',
      hide: true,
    },
    {
      title: 'Konts',
      key: 'bankAccount',
      hide: true,
      tooltip: 'Lūdzu ievadiet konta numuru. IBAN kontiem tiks veikta pārbaude, vai konta numurs ir korekts.',
      rules: [Validate.IBAN],
    },
    {
      title: 'Rekvizīti',
      key: 'bankDetails',
      hide: true,
    },
    {
      title: 'Piesaiste bankas kontam',
      key: 'bankAccountId',
      type: 'select',
      optionSource: 'bankAccounts',
      hide: true,
    },
    //{
    //  title: 'Darbības veidi',
    //  key: 'typeOfActionId',
    //  type: 'select',
    //  optionSource: 'typeOfAction',
    //  hide: true,
    //},
    {
      title: 'Īpašs norēķinu cikls',
      key: 'billingCycle',
      number: true,
      integer: true,
      rules: [Validate.Number],
      tooltip: 'Norādiet norēķinu ciklu dienās, ja tas atšķiras no standarta cikla (10 dienas)',
      hide: true,
      // asText: true,
    },
    // {
    //   key: "taxRegionInstitution",
    //   title: "VID terit.iestāde",
    //   type: "select",
    //
    // },
    {
      title: 'Piezīmes',
      key: 'notes',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Valsts',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Pilsēta',
      key: 'city',
      hide: true,
    },
    {
      title: 'Iela',
      key: 'street',
      hide: true,
    },
    // {
    //   title: "Adrese",
    //   key: "address",
    //   hide: true
    // },
    {
      title: 'Indekss',
      key: 'zip',
      hide: true,
    },
    {
      title: 'Telefons',
      key: 'phone',
      hide: true,
    },
    {
      title: 'Fakss',
      key: 'fax',
      hide: true,
    },
    {
      title: 'WWW',
      key: 'web',
      hide: true,
    },
    {
      title: 'Darbojas',
      key: 'isActive',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Kuģa īpašnieks/operators',
      key: 'isOwner',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Latitude',
      key: 'lat',
      hide: true,
    },
    {
      title: 'Longitude',
      key: 'lng',
      hide: true,
    },
    // {
    //   title: "Aģents",
    //   key: "isAgent",
    //   type: "checkbox",
    //   hide: true,
    //   noComponent: true
    // },
    // {
    //   title: "Ekspeditors",
    //   key: "isExpeditor",
    //   type: "checkbox",
    //   hide: true,
    //   noComponent: true
    // },
    // {
    //   title: "Krāvējs",
    //   key: "isCargoLoader",
    //   type: "checkbox",
    //   hide: true,
    //   noComponent: true
    // },
    // {
    //   title: "Kravas īpašnieks",
    //   key: "isCargoOwner",
    //   type: "checkbox",
    //   hide: true,
    //   noComponent: true
    // },
    // {
    //   title: "Kuģīpašnieks",
    //   key: "isShipOwner",
    //   type: "checkbox",
    //   hide: true,
    //   noComponent: true
    // },
    // {
    //   title: "Dzelzceļa kravas sūtītājs/saņēmējs",
    //   key: "isRailCargoTransporter",
    //   type: "checkbox",
    //   hide: true,
    //   noComponent: true
    // },

    {
      title: 'Uzņēmumam piesaistītās personas',
      key: 'agents',
      type: 'table',
      noTable: true,
    },

    {
      title: 'Līgumi',
      key: 'contracts',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Darbības veidi',
      key: 'typeOfActions',
      type: 'multiselect',
      extraFieldProps: {
        optionGroup: true,
        optionGroupParent: 'typeOfAction',
      },
      optionKeyName: 'id',
      optionValueName: 'name',
      optionSource: 'typeOfAction',
      noTable: true,
    },
    {
      title: 'Darbības veidi',
      key: 'storedTypeOfActions',
      type: 'select',
      optionKeyName: 'id',
      optionValueName: 'name',
      hide: false,
      sorter: true,
      selectSearch: true,
      options: fetchStoredTypeOfActionsList
    },
    // ???
    {
      title: 'Termināli',
      key: 'storedTerminals',
      type: 'multicheckbox',
      grouped: false,
      multiple: true,
      optionKeyName: 'id',
      optionValueName: 'name',
      optionSource: 'terminals',
      noTable: true,
    },
    {
      title: 'Logo',
      key: 'files',
      type: 'picture',
      tooltip: 'Lūdzu pievienojiet png, jpeg, jpg vai gif formāta datni.',
      sorter: false,
    },
    {
      title: 'Apraksts latviski',
      key: 'descriptionLat',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Apraksts angliski',
      key: 'descriptionEng',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Apraksts krieviski',
      key: 'descriptionRus',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Sniegtie pakalpojumi',
      key: 'storedTags',
      type: 'multiselect',
      extraFieldProps: {
        optionGroup: true,
        optionGroupParent: 'tagId',
      },
      optionKeyName: 'id',
      optionValueName: 'name',
      optionSource: 'tags',
      noTable: true,
    },
    {
      title: 'Sniegtie pakalpojumi',
      key: 'storedCompanyTags',
      hide: false,
      sorter: true,
      type: 'select',
      optionKeyName: 'id',
      optionValueName: 'name',
      selectSearch: true,
      options: fetchStoredCompanyTagsList,
    },
  ],
  companyRepresentatives: [
    {
      title: 'Vārds',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Tālrunis',
      key: 'phone',
      rules: [Validate.Required],
    },
    {
      title: 'Uzņēmums',
      key: 'companyId',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      hide: true,
      noColumn: true,
    },
  ],
  EditCompany_agents: [
    {
      title: 'Vārds',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Tālrunis',
      key: 'phone',
      rules: [Validate.Required],
    },
    {
      title: 'Klients',
      key: 'companyId',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      hide: true,
      noColumn: true,
    },
  ],
  calculations: [
    {
      title: 'Numurs',
      key: 'nr',
      render: (value, row) => {
        return (
          <span>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`/api/v1/calculations/${row.id}/invoice/`}
              target="_blank"
            >
              {' '}
              <FontAwesomeIcon icon="file-pdf" style={{ marginRight: '10px' }} />
              <span>{value}</span>{' '}
            </a>
          </span>
        );
      },
    },
    {
      title: 'Statuss',
      key: 'state',
      type: 'select',
      asText: true,
      // options: [
      //   { key: 'null', value: 'Jauns' },
      //   { key: 'ACCEPTED', value: 'Apstiprināts' },
      //   { key: 'REJECTED', value: 'Atgriezts atkārtotai pārbaudei' },
      // ],
      render: (value, row) => {
        // const opts = fieldSpec.colByKey.calculations.state.options;
        // const op = opts.find((o) => o.key === `${value}`) || {};
        // return op.value || value;
        if (row && row.invoiceId) return `Rēķinā`;
        return `-`;
      },
      renderComponent: ({ record_store }) => {
        if (record_store && record_store.item && record_store.item.invoiceId) {
          return `Rēķinā`;
        }
        return `-`;
      },
    },
    {
      title: 'Datums',
      key: 'date',
      type: 'date',
      rules: [Validate.Required],
      defaultSortOrder: 'desc',
    },
    // {
    //   title: 'Apmaksas datums',
    //   key: 'dueDate',
    //   type: 'date',
    //   // rules: [Validate.Required],
    //   // defaultSortOrder: 'desc',
    // },
    {
      title: 'Saņēmējs',
      key: 'recipientId',
      type: 'select',
      optionSource: 'companies',
      renderOption: (option) => option.value ?? option.name ?? '',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Fraktētājs',
      key: 'charterer',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kalkulācijas veids',
      key: 'type',
      type: 'select',
      asText: true,
      options: [
        {
          key: 'PORTDUES',
          value: 'Ostas maksa',
        },
        {
          key: 'RENT',
          value: 'Nomas maksa',
        },
      ],
    },

    // Fraktētājs
    {
      title: 'Konts',
      key: 'bankAccountId',
      type: 'select',
      optionSource: 'bankAccounts',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Apjoms',
      key: 'amount',
      type: 'label',
      number: true,
      filter: false,
      sorter: false,
      validateAfterComuted: true,
      rules: [
        {
          message: 'Apjoma summa nedrīkst pārsniegt 9 999 999 999,99.',
          validator(_rule, value, callback) {
            const val = !Number.isNaN(value) ? currency(value, currencyOpts) : 0;
            if (val > 9999999999.99) {
              return callback(true);
            }
            return callback();
          },
        },
      ],
      computed(_value, _prevValue, other) {
        if (_value) {
          if (_value === 'undefined') {
            return 0;
          }
          return currency(_value, currencyOpts).format();
        }
        return null;
      },
      //   const store = StoreFactory.getStore('EditCalculation_calculationRows');
      //   if (!other || !other.calculationRows || !other.calculationRows.length) {
      //     return _value;
      //   }
      //   const amount = other.calculationRows.reduce((acc, row) => {
      //     row = store.aggregate(row, 'details', (a, b) => {
      //       if (a.discount < b.discount) {
      //         return -1;
      //       }
      //       if (a.discount > b.discount) {
      //         return 1;
      //       }
      //       return 0;
      //     });
      //     return acc + row.sum;
      //   }, 0);
      //   // if (!other.vat || !amount) return " - ";
      //   if (!amount) {
      //     return (
      //       currency(0, currencyOpts)
      //         // .multiply(currency(other.vat, currencyOpts))
      //         // .divide(100)
      //         // .add(amount)
      //         .format()
      //     );
      //   }
      //   const cand = currency(amount, currencyOpts)
      //     // .multiply(currency(other.vat, currencyOpts))
      //     // .divide(100)
      //     // .add(amount)
      //     .format();

      //   if (!cand) {
      //     return '';
      //   }
      //   // console.log(cand);
      //   return cand;
      // },
    },
    {
      title: 'PVN likme',
      key: 'vat',
      tooltip: 'PVN procentu likme, piemēram 21',
      render: (v) => (v ? `${v}%` : ''),
      number: true,
      addonAfter: '%',
      rules: [
        {
          messagePrefix: 'Lūdzu ievadiet PVN procentu likmi.',
          min: 0,
          max: 100,
          ...Validate.Decimal,
        },
      ],
    },
    {
      title: 'Kuģa vizīte',
      key: 'shipVisitId',
      type: 'select',
      optionSource: 'shipVisits',
      optionValueName: '_t_shipInId',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      renderOption: shipVisitOptionRenderer,
      render: (value, row, ...c) => {
        return (
          <span>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`/statistics/visits/${row['_orig.shipVisitId'] ? row['_orig.shipVisitId'] : row['shipVisitId']}`}
              target="_blank"
            >
              {' '}
              <FontAwesomeIcon icon="ship" style={{ marginRight: '10px' }} />
              <span>{value}</span>{' '}
            </a>
          </span>
        );
      },
    },
    // {
    //   title: 'Stāvoklis',
    //   key: 'status',
    //   type: 'select',
    //   optionSource: 'calculationStatuses',
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: 'children',
    //   },
    // },
    {
      title: 'Salīdzināšanas datums',
      key: 'comparisonDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Salīdzināja',
      key: 'comparedId',
      type: 'select',
      optionSource: 'users',
      asText: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Apmaksas datums',
      key: 'dueDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Publicēšanas datums',
      key: 'publishedDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Klients iepazinies ar kalkulāciju',
      key: 'clientAcquaintanceDate',
      type: 'date',
      hide: true,
    },
    {
      title: 'Partnera komentārs',
      key: 'partnerComment',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Glosārijs',
      key: 'glossary',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Kalkulācijas',
      key: 'calculationRows',
      groupDiffDataBy: 'tariff.name',
      type: 'table',
      noTable: true,
    },
    // {
    //   title: 'Kalkulācijas apjoms',
    //   key: 'calculationTotalAmount',
    //   type: 'virtual',
    //   noTable: true,
    // },
  ],
  calculationRows: [
    {
      title: 'Nodeva',
      key: 'calculationTariffId',
      type: 'select',
      optionSource: 'calculationTariffs',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
    {
      title: 'Informācija par dokumentu',
      key: 'ownable',
      type: 'ownable',
      asActualField: true,
      noTable: true,
    },
    {
      title: 'Vienības',
      key: 'amount',
      rules: [Validate.Decimal],
      render: (value, row) => {
        // console.log(value, row);
        // typeof value === 'undefined'
        if (row.details) {
          return '';
        }
        return value;
      },
    },
    {
      title: 'Maksa (par vienību)',
      key: 'cost',
      rules: [Validate.Decimal],
    },
    {
      title: 'Summa bez atlaides',
      key: 'sumWithoutDiscount',
      render: (value, row) => {
        // console.log(value, row);
        // typeof value === 'undefined'

        // console.log('value@sumWithoutDiscount: ', value, row);

        // return value;

        if ('tariff' in row && row.details) {
          // return row.amount ? currency(row.amount).format() : ' - ';
          const sum = row.details.reduce((current, { cost, amount }) => {
            if (cost && amount) {
              current += cost * amount;
            }
            return current;
          }, 0);
          return currency(sum).format();
        } else {
          const { cost, amount } = row;
          const amnt = cost * amount;
          if (!isNaN(amnt)) {
            return amnt > 0 ? currency(cost * amount).format() : ' - ';
          }
          return null;
        }
        return value;
      },
    },
    {
      title: 'Atlaide (%)',
      key: 'discount',
      rules: [Validate.Decimal],
    },
    {
      title: 'GT',
      key: 'gt',
      rules: [Validate.Required, Validate.Decimal],
      hide: true,
    },
    {
      title: 'Summa',
      key: 'sum',
      sorter: false,
      render: (value, row) => {
        // console.log('value: ', row);

        if (row.details) {
          // console.log('row. with tariff: ', row);
          // row.details = row.details.map(d => {
          //   d.
          // });
          return row.amount ? currency(row.amount).format() : ' - ';
        }

        if (row.discount) {
          return ' - ';
          // console.log('row.row: ', row.row);
          // console.log('row: ', row, value);
          // value = parseFloat(row.amount) - parseFloat(row.amount) * (parseFloat(row.discount) / 100);
        }

        if (!row.discount && !row.key) {
          value = parseFloat(row.amount) * parseFloat(row.cost);
        }

        return value ? currency(value).format() : ' - ';
      },
      // makeReducer: () => (acc, row) => {
      //   if (row.discount && parseFloat(row.discount) > 0) {
      //     return (acc * (100 - parseFloat(row.discount))) / 100;
      //   }
      //   if (row.amount && row.cost) {
      //     return acc + row.amount * row.cost;
      //   }
      //   if (row.sum) {
      //     return acc + row.sum;
      //   }
      //   return acc;
      // },
    },
  ],
  EditCalculation_calculationRows: [
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'Nodeva',
      key: 'calculationTariffId',
      type: 'select',
      optionSource: 'calculationTariffs',
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // {
    //   title: "Nodeva",
    //   key: "_t_calculationTariffId",
    // },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
    {
      title: 'Informācija par dokumentu',
      key: 'ownable',
      type: 'ownable',
      asActualField: true,
      noTable: true,
    },
    {
      title: 'Vienības',
      key: 'amount',
      rules: [Validate.Decimal],
      render: (value, row) => {
        // console.log(value, row);
        // typeof value === 'undefined'
        if (row.details) {
          return '';
        }
        return value;
      },
    },
    {
      title: 'Maksa (par vienību)',
      key: 'cost',
      rules: [Validate.Decimal],
    },
    {
      title: 'Summa bez atlaides',
      key: 'sumWithoutDiscount',
      render: (value, row) => {
        // console.log(value, row);
        // typeof value === 'undefined'

        // console.log('value@sumWithoutDiscount: ', value, row);

        // return value;

        if (row.details) {
          // return row.amount ? currency(row.amount).format() : ' - ';
          const sum = row.details.reduce((current, { cost, amount }) => {
            if (cost && amount) {
              current += cost * amount;
            }
            return current;
          }, 0);
          return currency(sum).format();
        } else {
          const { cost, amount } = row;
          const amnt = cost * amount;
          if (!isNaN(amnt)) {
            return amnt > 0 ? currency(cost * amount).format() : ' - ';
          }
          return null;
        }
        return value;
      },
    },
    {
      title: 'Atlaide (%)',
      key: 'discount',
      rules: [Validate.Decimal],
    },
    {
      title: 'GT',
      key: 'gt',
      rules: [Validate.Required, Validate.Decimal],
      hide: true,
    },
    {
      title: 'Summa',
      key: 'sum',
      sorter: false,
      render: (value, row) => {
        // console.log('value: ', row);

        if (row.details) {
          // console.log('row. with tariff: ', row);
          // row.details = row.details.map(d => {
          //   d.
          // });
          return row.amount ? currency(row.amount).format() : ' - ';
        }

        if (row.discount) {
          return ' - ';
          // console.log('row.row: ', row.row);
          // console.log('row: ', row, value);
          // value = parseFloat(row.amount) - parseFloat(row.amount) * (parseFloat(row.discount) / 100);
        }

        if (!row.discount && !row.key) {
          value = parseFloat(row.amount) * parseFloat(row.cost);
        }

        return value ? currency(value).format() : ' - ';
      },
      // makeReducer: () => (acc, row) => {
      //   if (row.discount && parseFloat(row.discount) > 0) {
      //     return (acc * (100 - parseFloat(row.discount))) / 100;
      //   }
      //   if (row.amount && row.cost) {
      //     return acc + row.amount * row.cost;
      //   }
      //   if (row.sum) {
      //     return acc + row.sum;
      //   }
      //   return acc;
      // },
    },
    {
      title: 'Kalkulācijas detaļas',
      key: 'details',
      type: 'table',
      noTable: true,
    },
  ],
  EditCalculationRow_details: [
    {
      title: 'Id',
      key: 'id',
      noTable: true,
    },
    {
      title: 'Apraksts',
      key: 'description',
      rules: [Validate.Required],
    },
    {
      title: 'Vienības',
      key: 'amount',
      number: true,
      rules: [Validate.Number],
    },
    {
      title: 'Maksa (par vienību)',
      key: 'cost',
      number: true,
      rules: [Validate.Number],
    },
    {
      title: 'Atlaide',
      key: 'discount',
      tooltip: 'Atlaide procentos',
      render: (v) => (v ? `${v}%` : ''),
      number: true,
      addonAfter: '%',
      rules: [
        {
          messagePrefix: 'Lūdzu ievadiet procentu likmi.',
          min: 0,
          max: 100,
          ...Validate.Decimal,
        },
      ],
    },
  ],
  calculationRowDetails: [
    {
      title: 'Apraksts',
      key: 'description',
      rules: [Validate.Required],
    },
    {
      title: 'Vienības',
      key: 'amount',
      number: true,
      rules: [Validate.Number],
    },
    {
      title: 'Maksa (par vienību)',
      key: 'cost',
      number: true,
      rules: [Validate.Number],
    },
    {
      title: 'Atlaide',
      key: 'discount',
      tooltip: 'Atlaide procentos',
      render: (v) => (v ? `${v}%` : ''),
      number: true,
      addonAfter: '%',
      rules: [
        {
          messagePrefix: 'Lūdzu ievadiet procentu likmi.',
          min: 0,
          max: 100,
          ...Validate.Decimal,
        },
      ],
    },
  ],
  calculationStatuses: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
    },
    // {
    //   title: "Nr.",
    //   key: "event_id",
    //   type: "label",
    //   hide: true,
    // },
  ],
  calculationTariffs: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angl.',
      key: 'nameEn',
    },
    {
      title: 'Sākums',
      key: 'startDate',
      type: 'date',
    },
    {
      title: 'Beigas',
      key: 'endDate',
      type: 'date',
    },
    {
      title: 'Kods',
      key: 'code',
    },
    {
      title: 'Veids',
      key: 'type',
      type: 'textarea',
    },
    {
      title: 'Ostas maksa',
      key: 'tariffTypeId',
      type: 'select',
      optionSource: 'tariffTypes',
    },
    {
      title: 'Apraksts',
      key: 'description',
      type: 'textarea',
      hide: true,
    },
    {
      title: 'Likme',
      key: 'tariff',
    },
    {
      title: 'Mērvienība',
      key: 'unit',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
      options: [
        { key: 'GT', value: 'EUR/GT' },
        { key: 'GTh', value: 'EUR/GT stundā' },
        { key: 'Passanger', value: 'EUR par pasažieri' },
        { key: 'h', value: 'EUR stundā' },
        { key: 'm3', value: 'EUR par kubikmetru' },
        { key: 't', value: 'EUR par tonnu' },
        { key: 'day', value: 'EUR dienā' },
        { key: 'perDay', value: 'EUR/GT dienā' },
      ],
    },
    // tabula
    {
      title: 'Nosacījumi tarifa piemērošanai',
      key: 'calculation_tariff_conditions',
      type: 'table',
      noTable: true,
    },
  ],
  calculationPeriods: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Rēķina piedēklis',
      key: 'invoiceSuffix',
    },
    {
      title: 'Sākums',
      key: 'startDate',
      type: 'date',
      rules: [Validate.Required],
      defaultSortOrder: 'desc',
    },
    {
      title: 'Beigas',
      key: 'endDate',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Stāvoklis',
      key: 'status',
      type: 'select',
      options: [
        {
          key: 'OPEN',
          value: 'Atvērts',
        },
        {
          key: 'CLOSED',
          value: 'Slēgts',
        },
      ],
      rules: [Validate.Required],
    },
    {
      title: 'Rēķini',
      key: 'invoices',
      type: 'table',
      noTable: true,
    },
  ],
  EditCalculationPeriod_invoices: [
    {
      title: 'Numurs',
      key: 'nr',
      // rules: [Validate.Required],
      asText: true,
      render: (value, row) => {
        return (
          row.horizonId && (
            <span>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                }}
                href={`/api/v1/invoices/${row.id}/pdf`}
                target="_blank"
              >
                {' '}
                <FontAwesomeIcon icon="file-pdf" style={{ marginRight: '10px' }} />
                <span>{value}</span>{' '}
              </a>
            </span>
          )
        );
      },
    },
    {
      title: 'Datums',
      key: 'date',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Uzņēmums',
      key: 'recipientId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // {
    //   title: "Finanšu periods",
    //   key: "calculationPeriodId",
    //   type: "select",
    //   optionSource: "calculationPeriods",
    //
    //   extraFieldProps: {
    //     showSearch: true,
    //     optionFilterProp: "children",
    //   },
    //   hide: true,
    // },
    {
      title: 'Valūta',
      key: 'currencyId',
      type: 'select',
      optionSource: 'currencies',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Summa',
      key: 'amount',
      rules: [Validate.Decimal],
      asText: true,
    },
    {
      title: 'PVN',
      key: 'vat',
      rules: [Validate.Decimal],
      asText: true,
    },
    {
      title: 'Kalkulācijas',
      key: 'calculations',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Nosūtīts uz Horizon',
      key: 'sentToHorizon',
      type: 'checkbox',
    },
  ],
  invoices: [
    {
      title: 'Numurs',
      key: 'nr',
      // rules: [Validate.Required],
      asText: true,
      render: (value, row) => {
        return (
          row.horizonId && (
            <span>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                }}
                href={`/api/v1/invoices/${row.id}/pdf`}
                target="_blank"
              >
                {' '}
                <FontAwesomeIcon icon="file-pdf" style={{ marginRight: '10px' }} />
                <span>{value}</span>{' '}
              </a>
            </span>
          )
        );
      },
    },
    {
      title: 'Datums',
      key: 'date',
      type: 'date',
      rules: [Validate.Required],
      defaultSortOrder: 'desc',
    },
    {
      title: 'Uzņēmums',
      key: 'recipientId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Finanšu periods',
      key: 'calculationPeriodId',
      type: 'select',
      optionSource: 'calculationPeriods',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      hide: true,
    },
    {
      title: 'Valūta',
      key: 'currencyId',
      type: 'select',
      optionSource: 'currencies',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Summa',
      key: 'amount',
      rules: [Validate.Decimal],
      asText: true,
    },
    {
      title: 'PVN',
      key: 'vat',
      rules: [Validate.Decimal],
      asText: true,
    },
    {
      title: 'Kalkulācijas',
      key: 'calculations',
      type: 'table',
      noTable: true,
    },
    {
      title: 'Nosūtīts uz Horizon',
      key: 'sentToHorizon',
      type: 'checkbox',
      asText: true,
    },
    {
      title: 'Apmaksas datums',
      key: 'paymentReceived',
      type: 'date',
      asText: true,
      // hide: true,
    },
    {
      title: 'Apmaksātā summa',
      key: 'paymentReceivedSum',
      // type: 'date',
      asText: true,
      // hide: true,
      render: (_value, row) => {
        if (row.paymentReceivedSum && row.paymentReceivedSum - row.amount != 0) {
          return <span>{_value}
            <span style={row.paymentReceivedSum - row.amount < 0 ? { color: 'red' } : { color: 'green' }}>
              {' '}(
              {row.paymentReceivedSum - row.amount < 0 ? null : '+'}
              {Math.round((row.paymentReceivedSum - row.amount) * 100.) / 100.})
            </span>
          </span>;
        } else return _value;
      }
    },
    {
      title: 'Kalkulācijas',
      key: 'calculations',
      type: 'table',
      noTable: true,
    },
  ],
  smallShipMovements: [
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'smallShips',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'No',
      key: 'fromBerthId',
      type: 'select',
      optionSource: 'berths',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Sākums',
      key: 'movementStart',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'Uz',
      key: 'toBerthId',
      type: 'select',
      optionSource: 'berths',
      // optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      optionFilters: {
        isOperating: [true],
      },
      optionStyle: (opt) => {
        return { fontWeight: opt.berthType !== `BERTH` ? `bold` : `normal` };
      },
    },
    {
      title: 'Beigas',
      key: 'movementEnd',
      type: 'date',
      time: true,
    },
    {
      title: 'Mērķis',
      key: 'purpose',
      type: 'select',
      options: [
        { key: 'SUPPLY', value: 'Apgāde' },
        { key: 'BUNKER', value: 'Bunkurs' },
      ],
    },
    {
      title: 'Ir dota atļauja kustībai',
      key: 'isAuthorized',
      type: 'checkbox',
    },
    {
      title: 'Kustību iekļaut operatīvajā situācijā',
      key: 'addToOperationalSituation',
      type: 'checkbox',
    },
    {
      title: 'Tips',
      key: '_t_type',
    },
    {
      title: 'Īpašnieks',
      key: '_t_name',
    },
  ],
  smallShips: [
    {
      title: 'Nosaukums',
      key: 'name',
    },
  ],
  loggedActions: [
    {
      title: 'Nr.',
      key: 'event_id',
      type: 'label',
      hide: true,
    },
    {
      title: 'Laiks',
      key: 'action_tstamp_tx',
      type: 'date',
      defaultSortOrder: 'desc',
      label: true,
      time: true,
      extraFieldProps: {
        disabled: true,
      },
      renderComponent: (that) => {
        return <span>{moment(that.value).format(`DD.MM.YYYY HH:mm`)}</span>;
      },
    },
    {
      title: 'Lietotājs',
      key: 'fullName',
      type: 'label',
      // type: 'own-name',
      // sorter: false,
      // filter: false,
      // hide: true,
      render: (value) => value === null || value.length < 3 ? <em>Lietotājs nav norādīts</em> : <>{value}</>,
    },
    {
      title: 'Objekts',
      key: 'table_name',
      type: 'select',
      label: true,
      options: [
        { key: 'ShipVisit', value: 'Kuģa vizīte' },
        { key: 'ShipVisitPermit', value: 'Vizītes atļauja' },
        { key: 'ControlOrganization', value: 'Institūcija' },
        { key: 'ShipDocument', value: 'Vizītes kuģa dokuments' },
        { key: 'Cargo', value: 'Krava' },
        { key: 'ShipsStore', value: 'Kuģa krājums' },
        { key: 'Movement', value: 'Kustība' },
        { key: 'BerthVisit', value: 'Piestātnes apmeklējums' },
        { key: 'ShipOperation', value: 'Darbība ostā' },
        { key: 'CrewMember', value: 'Apkalpe' },
        { key: 'CrewTravelDocument', value: 'Ceļošanas dokuments' },
        { key: 'CrewEffect', value: 'Apkalpes manta' },
        { key: 'Passenger', value: 'Pasažieris' },
        {
          key: 'PassengerTravelDocument',
          value: 'Pasažiera ceļošanas dokuments',
        },
        { key: 'WasteDisposal', value: 'Atkritumu nodošana' },
        { key: 'Resource', value: 'Resurss' },
        { key: 'Calculation', value: 'Kalkulācija' },
        { key: 'CalculationRow', value: 'Kalkulācijas rinda' },
        { key: 'CalculationTariff', value: 'Kalkulācijas tarifs' },
        { key: 'CalculationPeriod', value: 'Finanšu periods' },
        { key: 'Invoice', value: 'Rēķins' },
        { key: 'Port', value: 'Osta' },
        { key: 'Discount', value: 'Atlaide' },
        { key: 'Company', value: 'Uzņēmums' },
        {
          key: 'CompanyRepresentative',
          value: 'Uzņēmumam piesaistītā persona',
        },
        { key: 'Ship', value: 'Kuģis' },
        { key: 'Berth', value: 'Piestātne' },
        { key: 'BerthTechnicalData', value: 'Piestātnes tehniskie dati' },
        { key: 'ShippingLine', value: 'Līnija' },
        { key: 'ShippingLineShip', value: 'Līniju reģistrs' },
        { key: 'MovementService', value: 'Kustības pakalpojums' },
        { key: 'MovementServiceType', value: 'Kustības pakalpojuma veids' },
        { key: 'MovementResourceUse', value: 'Kustības resurss' },
        { key: 'MovementDiscount', value: 'Piemērotā atlaide' },
        { key: 'ShipOperationType', value: 'Kuģa darbības ostā' },
        {
          key: 'ShipStateChangeReason',
          value: 'Kuģa stāvokļa maiņas iemesls',
        },
        { key: 'TravelDocumentType', value: 'Ceļošanas dokumenta tips' },
        { key: 'CargoStatus', value: 'Kravas statuss' },
        { key: 'CrewRank', value: 'Dienesta pakāpe' },
        { key: 'CallPurpose', value: 'Piestātnes apmeklējuma mērķis' },
        { key: 'ShipDocumentType', value: 'Kuģa dokumenta veids' },
        { key: 'IceClassCertifier', value: 'Klasifikācijas sabiedrība' },
        { key: 'ShipsStoreType', value: 'Kuģa krājuma veids' },
        { key: 'Unit', value: 'Mērvienība' },
        { key: 'WasteType', value: 'Atkritumu veids' },
        { key: 'CalculationStatus', value: 'Kalkulācijas stāvoklis' },
        { key: 'Bank', value: 'Banka' },
        { key: 'Currency', value: 'Valūta' },
        { key: 'ShipType', value: 'Kuģa tips' },
        { key: 'ShipTypeGroup', value: 'Kuģa tipa grupa' },
        { key: 'ResourceType', value: 'Resursa tips' },
        { key: 'Pilot', value: 'Locis' },
        { key: 'User', value: 'Lietotājs' },
        { key: 'Role', value: 'Lietotāja loma' },
        {
          key: 'RolePermission',
          value: 'Lietotāja lomas piekļuves tiesības',
        },
        { key: 'Session', value: 'Lietotāja sesija' },
        { key: 'ChangeRecord', value: 'Izmaiņu vēstures ieraksts' },
        { key: 'Country', value: 'Valsts' },
        { key: 'ContainerType', value: 'Konteinera tips' },
        { key: 'BankAccount', value: 'Bankas konts' },
        { key: 'AccountType', value: 'Konta tips' },
        { key: 'PortRegion', value: 'Ostas rajons' },
        { key: 'ShoreZone', value: 'Jūras piekrastes zona' },
        { key: 'ResourceUseType', value: 'Resursa pielietojuma apraksts' },
        { key: 'OperationalNote', value: 'Operatīvā piezīme' },
        {
          key: 'File',
          value: 'Fails',
        },
        {
          key: 'Contract',
          value: 'Līgums',
        },
      ],
      selectSearch: true,
      extraFieldProps: {
        disabled: true,
      },
      renderComponent: (that) => {
        for (const i of that.record_store.colByName.table_name.options) {
          if (i.key === that.value) {
            return <span>{i.value}</span>;
          }
        }
        return <em>{that?.value ?? `Nav norādīts`}</em>;
      },
    },
    {
      title: 'Darbība',
      key: 'action',
      type: 'select',
      readonly: true,
      label: true,
      options: [
        {
          key: 'I',
          value: 'Pievienots',
        },
        {
          key: 'U',
          value: 'Rediģēts',
        },
        {
          key: 'D',
          value: 'Dzēsts',
        },
        {
          key: 'T',
          value: 'Iztīrīts',
        },
      ],
      extraFieldProps: {
        disabled: true,
      },
      selectSearch: true,
      renderComponent: (that) => {
        for (const i of that.record_store.colByName.action.options) {
          if (i.key === that.value) {
            return <span>{i.value}</span>;
          }
        }
        return <em>Nav norādīts</em>;
      },
    },
    {
      title: 'Labotā ieraksta dati',
      key: 'changed_fields',
      type: 'map',
      filter: false,
      sorter: false,
      label: true,
      hide: true,
    },
    {
      title: 'Izmantotais SQL vaicājums',
      key: 'client_query',
      type: 'code',
      hide: true,
      extraFieldProps: {
        disabled: true,
      },
      extraItemProps: {
        wrapperCol: { sm: 16, xs: 24 },
      },
      filter: false,
      sorter: false,
    },
    {
      title: 'Oriģinālā ieraksta dati',
      key: 'row_data',
      filter: false,
      sorter: false,
      type: 'map',
      label: true,
      hide: true,
    },
  ],

  oksusers: [
    {
      title: 'Lietotāja vārds',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'E-pasts',
      key: 'email',
      rules: [Validate.Required, Validate.Email],
    },
    {
      title: 'Uzņēmums',
      key: 'companyId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    // {
    //   title: 'Pieslēguma IP no',
    //   key: 'ipFrom',
    //   hide: true,
    // },
    // {
    //   title: 'Pieslēguma IP līdz',
    //   key: 'ipTo',
    //   hide: true,
    // },
    {
      title: 'Aktīvs',
      key: 'status',
      type: 'checkbox',
      labelRight: true,
    },
    {
      title: 'Uzņēmuma konta administrators',
      key: 'isCompanyAdmin',
      type: 'checkbox',
      labelRight: true,
    },
    {
      title: 'Uzņēmuma vadība',
      key: 'isCompanyManagement',
      type: 'checkbox',
      labelRight: true,
    },
    {
      title: 'Vārds',
      key: 'firstName',
      rules: [Validate.Required],
    },
    {
      title: 'Uzvārds',
      key: 'lastName',
      rules: [Validate.Required],
    },
    {
      title: 'Amats',
      key: 'position',
      hide: true,
    },
    {
      title: 'Uzņēmums pieteikumā',
      key: 'companyNameByClient',
      hide: true,
      noTable: true,
    },
    {
      title: 'Adrese',
      key: 'address',
      hide: true,
      noTable: true,
    },
    {
      title: 'Pilsēta',
      key: 'city',
      hide: true,
      noTable: true,
    },
    {
      title: 'Valsts',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      noTable: true,
    },
    {
      title: 'Telefons',
      key: 'phone',
      hide: true,
    },
    {
      title: 'Fakss',
      key: 'fax',
      hide: true,
      noTable: true,
    },
    {
      title: 'WWW',
      key: 'web',
      hide: true,
      noTable: true,
    },
    {
      title: 'Pēdējās autorizācijas laiks',
      key: 'lastLoginTime',
      type: 'date',
      time: true,
      asText: true,
      hide: true,
    },
    {
      title: 'Bloķēšanas laiks',
      key: 'disableTime',
      type: 'date',
      time: true,
      // asText: true,
      hide: true,
    },
    {
      title: 'Neveiksmīgo autorizācijas mēģinājumu skaits',
      tooltip: 'Neveiksmīgo autorizāciju skaits kopš iepriekšējā autorizācijas brīža. (Tiek iestatīts automātiski)',
      key: 'attempts',
      number: true,
      integer: true,
      asText: true,
      hide: true,
      // noTable: true
    },
    // {
    //   key:"loginHash",
    //   title:'Lietotāja vārda hash',
    // },
    {
      title: 'Parole',
      key: 'passwordHash',
      type: 'password',
      tooltip: 'Lai nomainītu lietotāja paroli, ievadiet paroli un saglabājiet ierakstu.',
      rules: [Validate.Password],
      noTable: true,
    },
    {
      title: 'Parole atkārtoti',
      key: 'passwordHash2',
      type: 'password',
      computed: (value) => value,
      rules: [Validate.Password],
      extraFieldProps: { visibilityToggle: false },
      noTable: true,
    },
  ],
  users: [
    {
      title: 'Lietotāja vārds',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'E-pasts',
      key: 'email',
      type: 'email',
      rules: [Validate.Required],
    },

    {
      title: 'Parole',
      key: 'password',
      type: 'password',
      tooltip: 'Lai nomainītu lietotāja paroli, ievadiet paroli un saglabājiet ierakstu.',
      noTable: true,
    },
    {
      title: 'Parole atkārtoti',
      key: 'password2',
      type: 'password',
      computed: (value) => value,
      noTable: true,
      extraFieldProps: { visibilityToggle: false },
    },

    {
      title: 'Vārds',
      key: 'firstName',
      rules: [Validate.Required],
    },
    {
      title: 'Uzvārds',
      key: 'lastName',
      rules: [Validate.Required],
    },
    {
      title: 'Loma',
      key: 'roles_list',
      optionSource: 'roles',
      optionKeyName: 'name',
      type: 'multiselect',
      required: true,
    },
    {
      title: 'Pēdējās autorizācijas laiks',
      key: 'lastLoginTime',
      type: 'date',
      time: true,
      asText: true,
      hide: true,
    },
  ],

  roles: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Atļautās darbības sistēmā',
      key: 'perms',
      type: 'multicheckbox',
      grouped: true,
      optionKeyName: 'name',
      optionValueName: 'title',
      optionSource: 'permissions',
      noTable: true,
    },
  ],
  permissions: [
    {
      title: 'Pieejas apraksts',
      key: 'title',
      rules: [Validate.Required],
    },
    {
      title: 'Pieejas sistēmas kods',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Datu plūsmas interfeiss',
      key: 'api',
      type: 'radio',
      rules: [Validate.Required],
      options: [{ key: 'api', value: 'Standarta' }],
    },
    {
      title: 'Grupa/Kategorija',
      key: 'group',
      type: 'select',
      extraFieldProps: { allowClear: true },
      options: [
        { key: 'Administrēšana', value: 'Administrēšana' },
        { key: 'Finanses', value: 'Finanses' },
        { key: 'Statistika', value: 'Statistika' },
        { key: 'Katalogi', value: 'Katalogi' },
        { key: 'Mazie kuģi', value: 'Mazie kuģi' },
        { key: 'Plānošana', value: 'Plānošana' },
        { key: 'Citas', value: 'Citas' },
        // { key: null, value: "Nav uzdots" }
      ],
    },
  ],
  notifications: [
    {
      title: 'Paziņojums',
      key: 'notification',
    },
  ],
  oksnotifications: [
    {
      title: 'Izveidots',
      key: 'saved',
      type: 'date',
      defaultSortOrder: 'desc',
    },
    {
      title: 'Tēma',
      key: 'subject',
      rules: [Validate.Required],
    },
    {
      title: 'Saturs',
      key: 'body',
      type: 'textarea',
      rules: [Validate.Required],
      noTable: true,
    },
    {
      title: 'Statuss',
      key: 'status',
      type: 'radio',
      options: [
        { key: false, value: 'Sagatavots' },
        { key: true, value: 'Nosūtīts' },
      ],
    },
    {
      title: 'Derīgs līdz',
      key: 'validTill',
      type: 'date',
    },
    {
      title: 'Sūtīt',
      key: 'sendToAdminsOnly',
      type: 'radio',
      extraFieldProps: { vertical: true },
      rules: [Validate.Required],
      options: [
        { key: 2, value: 'tikai uzņēmuma vadībai' },
        { key: 1, value: 'tikai uzņēmuma konta administratoriem' },
        { key: 0, value: 'visiem uzņēmuma OKS lietotājiem' },
      ],
    },
    {
      title: 'Pielikumi',
      key: 'documents',
      type: 'files',
      sorter: false,
      tooltip: 'Lūdzu pievienojiet jebkura formāta failus. Maksimālais faila izmērs 128MB.',
      rules: [Validate.DefaultFileValidator],
      noTable: true,
    },
    {
      title: 'Darbības veidi',
      key: '_companyTypes',
      type: 'select',
      sorter: false,
      noTable: true,
      optionKeyName: 'id',
      optionValueName: 'name',
      optionSource: 'typeOfAction',
      extraFieldProps: {
        noSave: true,
      },
    },
    {
      title: 'Sniegtie pakalpojumi',
      key: '_storedTags',
      type: 'multiselect',
      extraFieldProps: {
        optionGroup: true,
        optionGroupParent: 'tagId',
      },
      optionKeyName: 'id',
      optionValueName: 'name',
      //optionSource: 'tags',
      noTable: true,
    },
    {
      // title: 'Atlasītie uzņēmumi',
      key: 'storedCompanies',
      readonly: true,
      type: 'multicheckbox',
      grouped: false,
      multiple: true,
      optionKeyName: 'id',
      optionValueName: 'name',
      optionSource: 'oksusercompanies',
      noTable: true,
      required: true,
    },
    {
      title: 'Sūtītājs',
      key: 'oksNotificationSenderId',
      type: 'select',
      // optionKeyName: 'code',
      optionSource: 'oksNotificationSenders',

      // optionSource: 'countries',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  oksNotificationSenders: [
    {
      title: 'Vārds / Nosaukums',
      key: 'name',
    },
    {
      title: 'e-pasts',
      key: 'email',
    },
    {
      title: 'Atbildes e-pasts',
      key: 'replyTo',
    },
  ],
  notiftypes: [
    {
      title: 'Kods',
      key: 'code',
    },
    {
      title: 'Veids',
      key: 'type',
    },
  ],
  profile: [
    {
      title: 'Saņemt notifikācijas pārlūkā par šādiem notikumiem',
      key: 'push_notifications',
      type: 'multicheckbox',
      optionKeyName: 'code',
      optionSource: 'notiftypes',
    },
    {
      title: 'Saņemt notifikācijas uz e-pastu par šādiem notikumiem',
      key: 'email_notifications',
      type: 'multicheckbox',
      optionKeyName: 'code',
      optionSource: 'notiftypes',
    },
  ],

  // OKS user registration
  registration: [
    {
      title: 'Uzņēmuma nosaukums',
      key: 'companyNameByClient',
      rules: [Validate.Required],
      hide: true,
    },
    {
      title: 'Reģistrācijas numurs',
      key: 'regNr',
      rules: [Validate.Required, Validate.RegNr],
    },
    {
      title: 'Vārds',
      key: 'firstName',
      rules: [Validate.Required],
    },
    {
      title: 'Uzvārds',
      key: 'lastName',
      rules: [Validate.Required],
    },
    {
      title: 'Amats',
      key: 'position',
      hide: true,
      rules: [Validate.Required],
    },
    {
      title: 'Adrese',
      key: 'address',
      hide: true,
      rules: [Validate.Required],
    },
    {
      title: 'Pilsēta',
      key: 'city',
      hide: true,
      rules: [Validate.Required],
    },
    {
      title: 'Valsts',
      key: 'countryId',
      type: 'select',
      optionSource: 'countries',
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Telefons',
      key: 'phone',
      hide: true,
      rules: [Validate.Required],
    },
    {
      title: 'E-pasts',
      key: 'email',
      type: 'email',
      hide: true,
      rules: [Validate.Required, Validate.Email],
      extraFieldProps: { autoComplete: 'off' },
    },
    {
      title: 'Parole',
      key: 'passwordHash',
      type: 'password',
      hide: true,
      rules: [Validate.Required, Validate.Password],
      extraFieldProps: { autoComplete: 'off' },
    },
    {
      title: 'Parole atkārtoti',
      key: 'passwordHash2',
      type: 'password',
      computed: (value) => value,
      hide: true,
      rules: [Validate.Required],
      extraFieldProps: { visibilityToggle: false },
    },
  ],
  controlOrganizations: [
    {
      title: 'Iestādes nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Iestādes nosaukuma abreviatūra',
      key: 'code',
      rules: [Validate.Required],
    },
    {
      title: 'Virziens',
      key: 'scope',
      type: 'radio',
      extraFieldProps: {
        vertical: true,
        // mode: "button",
        // buttonStyle: "solid"
      },
      options: [
        { key: 'null', value: 'Rādīt abos virzienos' },
        { key: 'IN', value: 'Ienākšana' },
        { key: 'OUT', value: 'Iziešana' },
      ],
      render: (value) => {
        const opts = fieldSpec.colByKey.controlOrganizations.scope.options;
        const op = opts.find((o) => o.key === `${value}`) || {};
        return op.value || value;
      },
    },
  ],
  EditDiscount_discountConditions: [
    {
      title: 'Nosacījums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Objekta tips',
      key: 'object',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
      options: [
        { key: 'wasteOverLimit', value: 'Atkritumi virs robežlieluma' },
        { key: 'wasteGroups', value: 'Atkritumu grupa' },
        { key: 'ballast', value: 'Balastā' },
        { key: 'ioppCertificate', value: 'IOPP sertifikāts' },
        { key: 'cargoOperations', value: 'Kravas operācijas' },
        { key: 'shipOperationTypes', value: 'Kuģa darbības tips ostā' },
        { key: 'shipVisitPurposes', value: 'Vizītes mērķis' },
        { key: 'shipTypes', value: 'Kuģa tips' },
        { key: 'ships', value: 'Kuģis' },
        { key: 'contracts', value: 'Līgums' },
        { key: 'shippingLines', value: 'Līnija' },
        { key: 'ports', value: 'Osta' },
        { key: 'passengerAge', value: 'Pasažiera vecums' },
        { key: 'passengerOperations', value: 'Pasažieru operācijas' },
        { key: 'berths', value: 'Piestātne' },
        { key: 'callPurposes', value: 'Piestātnes apmeklējuma mērķis' },
        { key: 'companies', value: 'Uzņēmums' },
        { key: 'shippingLineVisits', value: 'Apmeklējumu skaits gadā' },
        { key: 'greenAwardCertificate', value: 'Green Award Certificate' },
        { key: 'specialCase', value: 'Īpašais gadījums' },
        { key: 'tariffTypes', value: 'Ostas maksas tips' },
        { key: 'pilotCert', value: 'Pilot certificate' },
        { key: 'withPilot', value: 'Izmantots locis' },
        { key: 'pilotCancelled', value: 'Loča pakalpojums tika atcelts' },
      ],
    },
    {
      title: 'Operators',
      key: 'operator',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
      // options: [
      //   { key: 'equal', value: '=' },
      //   { key: 'notEqual', value: '<>' },
      //   { key: 'bigger', value: '>' },
      //   { key: 'smaller', value: '<' },
      // ],
      noTable: true,
    },
    {
      title: 'Vērtība',
      key: 'value',
      // rules: [Validate.Required],
      noTable: true,
    },
    {
      title: 'Piesaistītā objekta ieraksts',
      key: 'objectId',
      type: 'select',
      // optionSource: null,
      hide: true,
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
        noSave: true,
      },
      // rules: [Validate.Required],
      noTable: true,
    },
  ],
  EditCruiseDiscount_discountConditions: [
      {
          title: 'Nosacījums',
          key: 'name',
          rules: [Validate.Required],
      },
      {
          title: 'Objekta tips',
          key: 'object',
          type: 'select',
          rules: [Validate.Required],
          extraFieldProps: {
              allowClear: true,
              showSearch: true,
              optionFilterProp: 'children',
          },
          options: [
              { key: 'ships', value: 'Kuģis' },
          ],
      },
      {
          title: 'Operators',
          key: 'operator',
          type: 'select',
          rules: [Validate.Required],
          extraFieldProps: {
              allowClear: true,
              showSearch: true,
              optionFilterProp: 'children',
          },
          noTable: true,
      },
      {
          title: 'Vērtība',
          key: 'value',
          // rules: [Validate.Required],
          noTable: true,
      },
      {
          title: 'Piesaistītā objekta ieraksts',
          key: 'objectId',
          type: 'select',
          // optionSource: null,
          hide: true,
          extraFieldProps: {
              showSearch: true,
              allowClear: true,
              optionFilterProp: 'children',
              noSave: true,
          },
          noTable: true,
      },
  ],
  EditClassifier_calculation_tariff_conditions: [
    {
      title: 'Nosacījums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Objekta tips',
      key: 'object',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
      options: [
        { key: 'wasteOverLimit', value: 'Atkritumi virs robežlieluma' },
        { key: 'wasteGroups', value: 'Atkritumu grupa' },
        { key: 'ballast', value: 'Balastā' },
        { key: 'ioppCertificate', value: 'IOPP sertifikāts' },
        { key: 'cargoOperations', value: 'Kravas operācijas' },
        { key: 'shipOperationTypes', value: 'Kuģa darbības tips ostā' },
        { key: 'shipTypeGroups', value: 'Kuģa tipa grupa' },
        { key: 'shipTypes', value: 'Kuģa tips' },
        { key: 'ships', value: 'Kuģis' },
        { key: 'contracts', value: 'Līgums' },
        { key: 'shippingLines', value: 'Līnija' },
        { key: 'smallShip', value: 'Mazais kuģis' },
        { key: 'ports', value: 'Osta' },
        { key: 'passengerAge', value: 'Pasažiera vecums' },
        { key: 'passengerOperations', value: 'Pasažieru operācijas' },
        { key: 'berths', value: 'Piestātne' },
        { key: 'specializedShip', value: 'Specializētais kuģis' },
        { key: 'companies', value: 'Uzņēmums' },
        { key: 'shipInOut', value: 'Maksa par ostas apmeklēšanu (ienākšanu un iziešanu)' },
        { key: 'portVisit', value: 'Maksa par ostas apmeklēšanu' },
        { key: 'shipRemoor', value: 'Maksa par pārtauvošanos' },
        { key: 'shipRoadstead', value: 'Maksa par reidu (ienākšanu un iziešanu)' },
        { key: 'berthVisit', value: 'Maksa par piestātnes apmeklējumu' },
        { key: 'shippingLineVisits', value: 'Apmeklējumu skaits gadā' },
        { key: 'cargoOrPassengerOperations', value: 'Kravas vai pasažieru operācijas' },
        { key: 'wasteToSpecialUnit', value: 'Atkritumu nodošana speciālai vienībai' },
        { key: 'wasteToTreatmentPlants', value: 'Atkritumu nodošana attīrīšanas iekārtās' },
        { key: 'berthReservation', value: 'Maksa par piestātnes rezervāciju' },
        { key: 'shipVisitPurposes', value: 'Vizītes mērķis' },
        { key: 'withPilot', value: 'Izmantots locis' },
        { key: 'movementInOut', value: 'Movement in out' },
        { key: 'shipBerthDuration', value: 'Ilgums piestātnē' },
        { key: 'firstMooring', value: 'Pirmā pārtauvošana' },
        { key: 'targetBerths', value: 'Movement Target Piestātne' },
        { key: 'firstCallPurposes', value: 'First Piestātņu apmeklējumu mērķi' },
        { key: 'berthCallPurposes', value: 'Piestātņu apmeklējumu mērķi' },
      ],
    },
    {
      title: 'Operators',
      key: 'operator',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
      // options: [
      //   { key: 'equal', value: '=' },
      //   { key: 'notEqual', value: '<>' },
      //   { key: 'bigger', value: '>' },
      //   { key: 'smaller', value: '<' },
      // ],
      noTable: true,
    },
    {
      title: 'Vērtība',
      key: 'value',
      // rules: [Validate.Required],
      noTable: true,
    },
    {
      title: 'Piesaistītā objekta ieraksts',
      key: 'objectId',
      type: 'select',
      optionSource: null,
      hide: true,
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
        noSave: true,
      },
      // rules: [Validate.Required],
      noTable: true,
    },
  ],
  shipIn: [
    {
      title: 'Nr',
      type: 'text',
      key: 'nr',
      hide: false,
    },
    {
      title: 'Kuģa nosaukums',
      type: 'text',
      key: 'shipName',
      hide: false,
    },
    {
      title: 'Karogs',
      type: 'text',
      key: 'country',
      hide: false,
    },

    {
      title: 'Ienākšanas datums',
      type: 'date',
      key: 'ata',
      hide: false,
      filter: false,
    },
    {
      title: 'IMO kods',
      type: 'text',
      key: 'imo',
      hide: true,
    },
    {
      title: 'Ienākšanas mērķis',
      type: 'text',
      key: 'shipVisitPurpose',
      hide: true,
    },
    {
      title: 'Kuģa tips',
      type: 'text',
      key: 'shipType',
      hide: true,
    },
    {
      title: 'Kuģa iepriekšējā osta',
      type: 'text',
      key: 'fromPort',
      hide: false,
    },
    {
      title: 'Kuģa iepriekšējā ostas UN/LOCODE',
      type: 'text',
      key: 'fromPortLocode',
      hide: true,
    },
    {
      title: 'Jūras piekrastes rajons',
      type: 'text',
      key: 'shoreZone',
      hide: true,
    },
    {
      title: 'Kravnesība',
      type: 'text',
      key: 'deadweight',
      filter: false,
      hide: true,
    },
    {
      title: 'Kravnesības klase',
      type: 'text',
      key: 'deadweightClass',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Bruto tonnāža',
      type: 'text',
      key: 'gt',
      filter: false,
      hide: true,
    },
    {
      title: 'Bruto tonnāžas klase',
      type: 'text',
      key: 'gtClass',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Kravas iekraušanas osta',
      type: 'text',
      key: 'cargoPort',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Kravas iekraušanas ostas UN/LOCODE',
      type: 'text',
      key: 'cargoPortLocode',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Kravas veids',
      type: 'text',
      key: 'cargoType',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Kravas grupa', // Transporta statistikas standartizētās preču nomenklatūras grupa (NST/R)
      type: 'text',
      key: 'nstr',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Kravas svars',
      type: 'text',
      key: 'cargoWeight',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Tranzīts/Eksports',
      type: 'text',
      key: 'transitCargo',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Konteineri ar kravu',
      type: 'text',
      key: 'filledCont',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Tukšie konteineri',
      type: 'text',
      key: 'emptyCont',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Mobilo kravu vienību skaits',
      type: 'text',
      key: 'mobileCargo',
      filter: false,
      sorter: false,
      hide: true,
    },
  ],
  shipOut: [
    {
      title: 'Nr',
      type: 'text',
      key: 'nr',
      hide: false,
    },
    {
      title: 'Kuģa nosaukums',
      type: 'text',
      key: 'shipName',
      hide: false,
    },
    {
      title: 'Karogs',
      type: 'text',
      key: 'country',
      hide: false,
    },

    {
      title: 'Iziešanas datums',
      type: 'date',
      key: 'atd',
      hide: false,
      filter: false,
    },
    {
      title: 'IMO kods',
      type: 'text',
      key: 'imo',
      hide: true,
    },
    {
      title: 'Iziešanas mērķis',
      type: 'text',
      key: 'shipVisitPurpose',
      hide: true,
    },
    {
      title: 'Kuģa tips',
      type: 'text',
      key: 'shipType',
      hide: true,
    },
    {
      title: 'Kuģa nākošā osta',
      type: 'text',
      key: 'toPort',
      hide: false,
    },
    {
      title: 'Kuģa nākošā ostas UN/LOCODE',
      type: 'text',
      key: 'toPortLocode',
      hide: true,
    },
    {
      title: 'Jūras piekrastes rajons',
      type: 'text',
      key: 'shoreZone',
      hide: true,
    },
    {
      title: 'Kravnesība',
      type: 'text',
      key: 'deadweight',
      filter: false,
      hide: true,
    },
    {
      title: 'Kravnesības klase',
      type: 'text',
      key: 'deadweightClass',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Bruto tonnāža',
      type: 'text',
      key: 'gt',
      filter: false,
      hide: true,
    },
    {
      title: 'Bruto tonnāžas klase',
      type: 'text',
      key: 'gtClass',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Kravas izkraušanas osta',
      type: 'text',
      key: 'cargoPort',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Kravas izkraušanas ostas UN/LOCODE',
      type: 'text',
      key: 'cargoPortLocode',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Kravas veids',
      type: 'text',
      key: 'cargoType',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Kravas grupa', // Transporta statistikas standartizētās preču nomenklatūras grupa
      type: 'text',
      key: 'nstr',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Kravas svars',
      type: 'text',
      key: 'cargoWeight',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Tranzīts/Eksports',
      type: 'text',
      key: 'transitCargo',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Konteineri ar kravu',
      type: 'text',
      key: 'filledCont',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Tukšie konteineri',
      type: 'text',
      key: 'emptyCont',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Mobilo kravu vienību skaits',
      type: 'text',
      key: 'mobileCargo',
      hide: true,
      filter: false,
      sorter: false,
    },
  ],

  shipMove: [
    {
      title: 'Nr',
      type: 'text',
      key: 'nr',
      hide: false,
    },
    {
      title: 'Kuģa nosaukums',
      type: 'text',
      key: 'shipName',
      hide: false,
    },
    {
      title: 'Karogs',
      type: 'text',
      key: 'country',
      hide: false,
    },
    {
      title: 'Datums',
      type: 'date',
      key: 'startTime',
      hide: false,
      filter: false,
    },
    {
      title: 'IMO kods',
      type: 'text',
      key: 'imo',
      hide: true,
    },
    // {
    //   title: 'Iziešanas mērķis',
    //   type: 'text',
    //   key: 'shipVisitPurpose',
    //   hide: true,
    // },
    {
      title: 'Kuģa tips',
      type: 'text',
      key: 'shipType',
      hide: true,
    },

    {
      title: 'No piestātnes',
      type: 'text',
      key: 'fromBerth',
      hide: false,
    },
    {
      title: 'Uz piestātni',
      type: 'text',
      key: 'toBerth',
      hide: false,
    },

    {
      title: 'Uz ostu',
      type: 'text',
      key: 'toPort',
      hide: true,
    },
    {
      title: 'Uz ostu',
      type: 'text',
      key: 'toPortLocode',
      hide: true,
    },
    {
      title: 'Jūras piekrastes rajons',
      type: 'text',
      key: 'shoreZone',
      hide: true,
    },
    {
      title: 'Kravnesība',
      type: 'text',
      key: 'deadweight',
      filter: false,
      hide: true,
    },
    {
      title: 'Kravnesības klase',
      type: 'text',
      key: 'deadweightClass',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Bruto tonnāža',
      type: 'text',
      key: 'gt',
      filter: false,
      hide: true,
    },
    {
      title: 'Bruto tonnāžas klase',
      type: 'text',
      key: 'gtClass',
      filter: false,
      sorter: false,
      hide: true,
    },
    {
      title: 'Kravas izkraušanas osta',
      type: 'text',
      key: 'cargoPort',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Kravas izkraušanas ostas UN/LOCODE',
      type: 'text',
      key: 'cargoPortLocode',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Kravas veids',
      type: 'text',
      key: 'cargoType',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Kravas grupa', // Transporta statistikas standartizētās preču nomenklatūras grupa
      type: 'text',
      key: 'nstr',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Kravas svars',
      type: 'text',
      key: 'cargoWeight',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Tranzīts/Eksports',
      type: 'text',
      key: 'transitCargo',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Konteineri ar kravu',
      type: 'text',
      key: 'filledCont',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Tukšie konteineri',
      type: 'text',
      key: 'emptyCont',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Mobilo kravu vienību skaits',
      type: 'text',
      key: 'mobileCargo',
      hide: true,
      filter: false,
      sorter: false,
    },
  ],

  cruisePassengers: [
    {
      title: 'Tautība',
      type: 'text',
      key: 'nationality',
      hide: false,
    },
    {
      title: 'Pasažieru skaits iebraucot',
      key: 'passengersIn',
      hide: false,
      number: true,
      filter: false,
    },
    {
      title: 'Pasažieru skaits izbraucot',
      key: 'passengersOut',
      hide: false,
      number: true,
      filter: false,
    },
    {
      title: 'Pasažieru plūsma kopā',
      key: 'passengersAll',
      hide: false,
      number: true,
      filter: false,
    },
  ],
  shipWaste: [
    {
      title: 'Kuģis',
      key: 'shipName',
      type: 'text',
      hide: false,
      render: (value, row) => (row.shipName === 'KOPĀ' ? <strong>{value}</strong> : <span>{value}</span>),
    },
    {
      title: 'Vizītes datums',
      key: 'date',
      type: 'date',
      hide: false,
      filter: false,
    },
    {
      title: 'Pēdējā osta',
      key: 'lastPort',
      type: 'text',
      hide: false,
    },
  ],
  portPayments: [
    {
      title: 'Vizītes kods',
      key: 'code',
      type: 'text',
      hide: false,
      filter: false,
    },
    {
      title: 'Vizītes sākums',
      key: 'ata',
      type: 'date',
      hide: false,
      filter: false,
      render: (value, row) => {
        const styles = {};

        if (
          (typeof row.italic !== `undefined` && Array.isArray(row.italic) && row.italic.length >= 1 && row.italic[0]) ||
          row.italic === true
        ) {
          styles.fontStyle = `italic`;
        }

        if (
          (typeof row.bold !== `undefined` && Array.isArray(row.bold) && row.bold.length >= 1 && row.bold[0]) ||
          row.bold === true
        ) {
          styles.fontWeight = `bold`;
        }

        if (
          (typeof row.color !== `undefined` && Array.isArray(row.color) && row.color.length >= 1) ||
          typeof row.color !== `string`
        ) {
          styles.color = Array.isArray(row.color) ? row.color[0] : row.color;
        }

        return <span style={styles}>{value}</span>;
      },
    },
    {
      //example here
      title: 'Vizītes beigas',
      key: 'atd',
      type: 'date',
      hide: false,
      filter: false,
      render: (value, row) => {
        const styles = {};

        if (
          (typeof row.italic !== `undefined` && Array.isArray(row.italic) && row.italic.length >= 2 && row.italic[1]) ||
          row.italic === true
        ) {
          styles.fontStyle = `italic`;
        }

        if (
          (typeof row.bold !== `undefined` && Array.isArray(row.bold) && row.bold.length >= 2 && row.bold[1]) ||
          row.bold === true
        ) {
          styles.fontWeight = `bold`;
        }

        if (
          (typeof row.color !== `undefined` && Array.isArray(row.color) && row.color.length >= 2) ||
          typeof row.color !== `string`
        ) {
          styles.color = Array.isArray(row.color) ? row.color[1] : row.color;
        }

        return <span style={styles}>{value}</span>;
      },
    },
    {
      title: 'Kuģis',
      key: 'shipName',
      type: 'text',
      hide: false,
    },
    {
      title: 'Kuģa tips',
      key: 'shipType',
      type: 'text',
      hide: true,
    },
    {
      title: 'Gross-tonnāža (GT)',
      key: 'gt',
      type: 'text',
      hide: true,
      filter: false,
    },
    {
      title: 'Kravnesība (DW)',
      key: 'dw',
      type: 'text',
      hide: true,
      filter: false,
    },
    {
      title: 'Ievesto kravu veidi',
      key: 'cargoIn',
      type: 'text',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Izvesto kravu veidi',
      key: 'cargoOut',
      type: 'text',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Līnija',
      key: 'line',
      type: 'text',
      hide: true,
    },
  ],
  cargoOperations: [
    {
      title: 'Vizītes Kods',
      key: 'code',
      type: 'text',
      hide: false,
      filter: false,
    },
    {
      title: 'Kuģis',
      key: 'shipName',
      type: 'text',
      hide: false,
    },
    {
      title: 'Vizītes sākums',
      key: 'ata',
      type: 'date',
      hide: false,
      filter: false,
    },
    {
      title: 'Vizītes beigas',
      key: 'atd',
      type: 'date',
      hide: false,
      filter: false,
    },
    {
      title: 'Bruto tonnāža',
      key: 'gt',
      type: 'text',
      hide: true,
      filter: false,
    },
    {
      title: 'Aģents',
      key: 'agent',
      type: 'text',
      hide: false,
    },
    {
      title: 'Kravnesība',
      key: 'dw',
      type: 'text',
      hide: true,
      filter: false,
    },
    {
      title: 'IMO kods',
      key: 'imo',
      type: 'text',
      hide: true,
    },
    {
      title: 'Kuģa tips',
      key: 'shipType',
      type: 'text',
      hide: true,
    },
    {
      title: 'Karogs',
      key: 'country',
      type: 'text',
      hide: true,
    },
    {
      title: 'Vizītes mērķis',
      key: 'shipVisitPurpose',
      type: 'text',
      hide: true,
    },
    {
      title: 'Iepriekšējā osta',
      key: 'fromPort',
      type: 'text',
      hide: true,
    },
    {
      title: 'Nakošā osta',
      key: 'toPort',
      type: 'text',
      hide: true,
    },
    {
      title: 'Piestātnes',
      key: 'berths',
      type: 'text',
      hide: false,
      filter: false,
      sorter: false,
    },
    {
      title: 'Iekrautās kravas veidi',
      key: 'cargoIn',
      type: 'text',
      hide: false,
      filter: false,
      sorter: false,
      render: (value, row) =>
        row.link ? (
          <a href={row.link} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          <span>{value}</span>
        ),
    },
    {
      title: 'Iekrautā krava (tūkst. t.)',
      key: 'cargoInAmmount',
      type: 'text',
      hide: false,
      filter: false,
      sorter: false,
      render: (value, row) =>
        row.link ? (
          <a href={row.link} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          <span>{value}</span>
        ),
    },
    {
      title: 'Izkrautās kravas veidi',
      key: 'cargoOut',
      type: 'text',
      hide: false,
      filter: false,
      sorter: false,
      render: (value, row) =>
        row.link ? (
          <a href={row.link} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          <span>{value}</span>
        ),
    },
    {
      title: 'Izkrautā krava (tūkst.t.)',
      key: 'cargoOutAmmount',
      type: 'text',
      hide: false,
      filter: false,
      sorter: false,
      render: (value, row) =>
        row.link ? (
          <a href={row.link} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          <span>{value}</span>
        ),
    },
  ],
  cargoIn: [
    {
      title: 'Kuģis',
      key: 'shipName',
      type: 'text',
      hide: false,
    },
    {
      title: 'Kuģa aģents',
      key: 'shipAgent',
      type: 'text',
      hide: false,
    },
    {
      title: 'Karogs',
      key: 'country',
      type: 'text',
      hide: false,
    },
    {
      title: 'Kuģa tips',
      key: 'shipType',
      type: 'text',
      hide: false,
    },
    {
      title: 'IMO kods',
      key: 'imo',
      type: 'text',
      hide: true,
    },
    {
      title: 'Kravnesība',
      key: 'dw',
      type: 'text',
      hide: true,
    },
    {
      title: 'Bruto tonnāža',
      key: 'gt',
      type: 'text',
      hide: true,
    },
    {
      title: 'Vizītes mērķis',
      key: 'shipVisitPurpose',
      type: 'text',
      hide: false,
    },
    {
      title: 'Ienākšanas datums',
      key: 'date',
      type: 'date',
      hide: false,
      filter: false,
    },
    {
      title: 'Iepriekšējā osta',
      key: 'fromPort',
      type: 'text',
      hide: false,
    },
    {
      title: 'Nākamā osta',
      key: 'toPort',
      type: 'text',
      hide: false,
    },
    {
      title: 'Kravas daudzums',
      key: 'cargo',
      type: 'text',
      hide: false,
      filter: false,
      sorter: false,
      render: (value, row) =>
        row.link ? (
          <a href={row.link} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          <span>{value}</span>
        ),
    },
  ],
  cargoOut: [
    {
      title: 'Kuģis',
      key: 'shipName',
      type: 'text',
      hide: false,
    },
    {
      title: 'Kuģa aģents',
      key: 'shipAgent',
      type: 'text',
      hide: false,
    },
    {
      title: 'Karogs',
      key: 'country',
      type: 'text',
      hide: false,
    },
    {
      title: 'Kuģa tips',
      key: 'shipType',
      type: 'text',
      hide: false,
    },
    {
      title: 'IMO kods',
      key: 'imo',
      type: 'text',
      hide: true,
    },
    {
      title: 'Kravnesība',
      key: 'dw',
      type: 'text',
      hide: true,
    },
    {
      title: 'Bruto tonnāža',
      key: 'gt',
      type: 'text',
      hide: true,
    },
    {
      title: 'Vizītes mērķis',
      key: 'shipVisitPurpose',
      type: 'text',
      hide: false,
    },
    {
      title: 'Iziešanas datums',
      key: 'date',
      type: 'date',
      hide: false,
      filter: false,
    },
    {
      title: 'Iepriekšējā osta',
      key: 'fromPort',
      type: 'text',
      hide: false,
    },
    {
      title: 'Nākamā osta',
      key: 'toPort',
      type: 'text',
      hide: false,
    },
    {
      title: 'Kravas daudzums',
      key: 'cargo',
      type: 'text',
      hide: false,
      filter: false,
      sorter: false,
      render: (value, row) =>
        row.link ? (
          <a href={row.link} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          <span>{value}</span>
        ),
    },
  ],
  shipAgentTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      hide: false,
      render: (value) => {
        if (value.toUpperCase() === `KOPĀ`) {
          return <span style={{ fontWeight: `bold` }}>{value}</span>;
        }
        return <span>{value}</span>;
      },
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      hide: true,
    },
    {
      title: 'Grupa',
      key: 'group',
      type: 'text',
      hide: true,
    },
    {
      title: 'Iekšējais kods',
      key: 'code',
      hide: true,
    },
    {
      title: 'Krāsa kartē',
      key: 'color',
      type: 'colorpicker',
      columnRenderMethod: 'renderColorColumn',
      hide: true,
      filter: false,
      sorter: false,
    },
    {
      title: 'Vizīšu skaitu periodā',
      key: 'vpy',
      hide: false,
      filter: false,
      sorter: false,
    },
    {
      title: 'Bruto tonnāža periodā',
      key: 'gt',
      hide: false,
      filter: false,
      sorter: false,
    },
    {
      title: 'Vizīšu skaitu periodā pirms gada',
      key: 'vpyPrev',
      hide: false,
      filter: false,
      sorter: false,
    },
    {
      title: 'Bruto tonnāža periodā pirms gada',
      key: 'gtPrev',
      hide: false,
      filter: false,
      sorter: false,
    },
    {
      title: 'Dinamika vizītēm',
      key: 'dvpy',
      hide: false,
      filter: false,
      sorter: false,
    },
    {
      title: 'Dinamika bruto tonnāžai',
      key: 'dgt',
      hide: false,
      filter: false,
      sorter: false,
    },
  ],
  shipAgentCompanies: [
    {
      title: 'Nosaukums',
      key: 'name',
      type: 'text',
      hide: false,
      render: (value) => {
        if (value.toUpperCase() === `KOPĀ`) {
          return <span style={{ fontWeight: `bold` }}>{value}</span>;
        }
        return <span>{value}</span>;
      },
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      type: 'text',
      hide: true,
    },
    {
      title: 'Saīsināts nosaukums',
      key: 'shortName',
      type: 'text',
      hide: true,
    },
    {
      title: 'Reģistrācijas numurs',
      key: 'regNr',
      type: 'text',
      hide: true,
    },
    {
      title: 'Klienta kods',
      key: 'clientCode',
      type: 'text',
      hide: true,
    },
    {
      title: 'E-pasts',
      key: 'email',
      type: 'email',
      hide: true,
    },
    {
      title: 'Banka',
      key: 'bank',
      type: 'text',
      hide: true,
    },
    {
      title: 'Valsts',
      key: 'country',
      type: 'text',
      hide: true,
    },
    {
      title: 'Pilsēta',
      key: 'city',
      type: 'text',
      hide: true,
    },
    {
      title: 'Iela',
      key: 'street',
      type: 'text',
      hide: true,
    },
    {
      title: 'Indekss',
      key: 'zip',
      type: 'text',
      hide: true,
    },
    {
      title: 'Telefons',
      key: 'phone',
      type: 'text',
      hide: true,
    },
    {
      title: 'Fakss',
      key: 'fax',
      type: 'text',
      hide: true,
    },
    {
      title: 'WWW',
      key: 'web',
      type: 'text',
      hide: true,
    },
    {
      title: 'Vizīšu skaitu periodā',
      key: 'vpy',
      type: 'text',
      hide: false,
      sorter: false,
      filter: false,
    },
    {
      title: 'Bruto tonnāža periodā',
      key: 'gt',
      type: 'text',
      hide: false,
      sorter: false,
      filter: false,
    },
    {
      title: 'Vizīšu skaitu periodā pirms gada',
      key: 'vpyPrev',
      type: 'text',
      hide: false,
      sorter: false,
      filter: false,
    },
    {
      title: 'Bruto tonnāža periodā pirms gada',
      key: 'gtPrev',
      type: 'text',
      hide: false,
      sorter: false,
      filter: false,
    },
    {
      title: 'Dinamika vizītēm',
      key: 'dvpy',
      type: 'text',
      hide: false,
      sorter: false,
      filter: false,
    },
    {
      title: 'Dinamika bruto tonnāžai',
      key: 'dgt',
      type: 'text',
      hide: false,
      sorter: false,
      filter: false,
    },
    {
      title: 'Kuģu skaits',
      key: 'shipCount',
      type: 'text',
      hide: false,
      sorter: false,
      filter: false,
    },
    {
      title: 'Palīgflotes kuģi',
      key: 'fleetShips',
      type: 'text',
      hide: false,
      sorter: false,
      filter: false,
    },
  ],
  shipAgents: [
    {
      title: 'Nosaukums',
      key: 'name',
      type: 'text',
      hide: false,
      render: (value) => {
        if (value.toUpperCase() === `KOPĀ`) {
          return <span style={{ fontWeight: `bold` }}>{value}</span>;
        }
        return <span>{value}</span>;
      },
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      type: 'text',
      hide: true,
    },
    {
      title: 'Saīsināts nosaukums',
      key: 'shortName',
      type: 'text',
      hide: true,
    },
    {
      title: 'Reģistrācijas numurs',
      key: 'regNr',
      type: 'text',
      hide: true,
    },
    {
      title: 'Klienta kods',
      key: 'clientCode',
      type: 'text',
      hide: true,
    },
    {
      title: 'E-pasts',
      key: 'email',
      type: 'email',
      hide: true,
    },
    {
      title: 'Banka',
      key: 'bank',
      type: 'text',
      hide: true,
    },
    {
      title: 'Valsts',
      key: 'country',
      type: 'text',
      hide: true,
    },
    {
      title: 'Pilsēta',
      key: 'city',
      type: 'text',
      hide: true,
    },
    {
      title: 'Iela',
      key: 'street',
      type: 'text',
      hide: true,
    },
    {
      title: 'Indekss',
      key: 'zip',
      type: 'text',
      hide: true,
    },
    {
      title: 'Telefons',
      key: 'phone',
      type: 'text',
      hide: true,
    },
    {
      title: 'Fakss',
      key: 'fax',
      type: 'text',
      hide: true,
    },
    {
      title: 'WWW',
      key: 'web',
      type: 'text',
      hide: true,
    },
  ],
  helpGroups: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Sistēmas atslēga',
      tooltip: (
        <>
          <span>Unikāls sistēmas identifikators.</span>
          <div>
            Piemērs: <p>sitemas-atslega-123</p>
          </div>
        </>
      ),
      key: 'key',
      slug: true,
      rules: [
        Validate.Required,
        {
          optionSource: 'helpGroups',
          rule: 'unique',
          ...Validate.AsyncFetch,
        },
      ],
    },
    {
      title: 'Sistēma',
      key: 'sysGroup',
      type: 'select',
      // rules: [Validate.Required],
      // filter: false,
      options: [
        { key: 'ois', value: 'OIS' },
        { key: 'oks', value: 'OKS' },
      ],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
  ],
  helpContents: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Sistēmas atslēga',
      tooltip: (
        <>
          <span>Unikāls pārlūka saites identifikators.</span>
          <div>
            Piemērs: <br /> <span style={{ wordSpace: 'nowrap' }}>{`${getUri()}/help/<atslēga>`}</span>
          </div>
        </>
      ),
      key: 'key',
      slug: true,
      rules: [
        Validate.Required,
        {
          optionSource: 'helpContents',
          rule: 'unique',
          ...Validate.AsyncFetch,
        },
      ],
    },
    {
      title: 'Grupa',
      key: 'helpGroupId',
      type: 'select',
      rules: [Validate.Required],
      // filter: false,
      optionSource: 'helpGroups',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Sistēma',
      key: 'sysGroup',
      type: 'select',
      // rules: [Validate.Required],
      // filter: false,
      options: [
        { key: 'ois', value: 'OIS' },
        { key: 'oks', value: 'OKS' },
      ],
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Piesaistītā sistēmas saite',
      key: 'linkedToPath',
      extraFieldOptions: {
        onChange: (event) => {
          const value = event.target.value || '';
          if (value && !value.startsWith('/')) {
            const regex = /(http[s]?:\/\/)?([^/\s]+)(.*)/g;
            const m = regex.exec(value);
            if (m && m[3]) {
              // eslint-disable-next-line prefer-destructuring
              event.target.value = m[3];
              return event.target.value;
            }
          }

          return value;
        },
      },
    },
    {
      title: 'Saturs',
      key: 'content',
      type: 'richtextarea',
      rules: [Validate.Required],
    },
  ],
  // DPM
  overviewCustomizedGroups: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Lauks',
      key: 'field',
      type: 'select',
      options: [
        { key: 'kkas1', value: 'Kuģis → Kuģa tips' },
        { key: 'kkas2', value: 'Krava → Kravas tips' },
        { key: 'kkas3', value: 'Apkalpe' },
        { key: 'kkas4', value: 'IOPP sertifikāts' },
      ],
    },

    {
      title: 'Grupas',
      key: 'groups',
      type: 'table',
      noTable: true,
    },
  ],
  EditOverviewCustomizedGroup_groups: [
    {
      title: 'Grupa',
      key: 'group',
    },
    {
      title: 'Objekta tips',
      key: 'object',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
      },
      options: [
        { key: 'wasteOverLimit', value: 'Atkritumi virs robežlieluma' },
        { key: 'wasteGroups', value: 'Atkritumu grupa' },
        { key: 'ballast', value: 'Balastā' },
        { key: 'ioppCertificate', value: 'IOPP sertifikāts' },
        { key: 'cargoOperations', value: 'Kravas operācijas' },
        { key: 'shipOperationTypes', value: 'Kuģa darbības tips ostā' },
        { key: 'shipTypes', value: 'Kuģa tips' },
        { key: 'ships', value: 'Kuģis' },
        { key: 'contracts', value: 'Līgums' },
        { key: 'shippingLines', value: 'Līnija' },
        { key: 'ports', value: 'Osta' },
        { key: 'passengerAge', value: 'Pasažiera vecums' },
        { key: 'passengerOperations', value: 'Pasažieru operācijas' },
        { key: 'berths', value: 'Piestātne' },
        { key: 'companies', value: 'Uzņēmums' },
      ],
    },

    {
      title: 'Operators',
      key: 'operator',
      type: 'select',
      rules: [Validate.Required],
      extraFieldProps: {
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'children',
        noSave: true,
      },
      options: [
        { key: 'equal', value: '=' },
        { key: 'notEqual', value: '<>' },
        { key: 'bigger', value: '>' },
        { key: 'smaller', value: '<' },
      ],
      noTable: true,
    },
    {
      title: 'Vērtība',
      key: 'value',
      // rules: [Validate.Required],
      noTable: true,
    },
    {
      title: 'Piesaistītā objekta ieraksts',
      key: 'objectId',
      type: 'select',
      optionSource: null,
      hide: true,
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
      // rules: [Validate.Required],
      noTable: true,
    },
  ],

  dynamicReports: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Datu avoti',
      key: 'objects',
      type: 'table', // fieldtable
    },
    {
      title: 'Atskaites kolonnas',
      key: 'fields',
      type: 'table', // possibly some dynamic table
    },
    {
      title: 'Atlases kritēriji',
      key: 'filters',
      type: 'table', // possibly some dynamic table
    },
  ],
  reportsConfig: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Konfigurācija',
      key: 'config',
      type: 'custom',
      noTable: true,
    },
    {
      title: 'Atlases kritēriji',
      key: 'filters',
      type: 'table', // possibly some dynamic table
    },
    {
      title: 'Vizualizācijas veids',
      key: 'visType',
      type: 'select',
      options: [
        {
          key: 'table',
          value: 'Tabula',
        },
        {
          key: 'chart',
          value: 'Grafiks',
        },
      ],
    },
  ],
  filteredReports: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'config',
      key: 'config',
    },
    // {
    //   title: 'Vizualizācijas veids',
    //   key: 'visType',
    //   type: 'select',
    //   options: [
    //     {
    //       key: 'table',
    //       value: 'Tabula',
    //     },
    //     {
    //       key: 'chart',
    //       value: 'Grafiks',
    //     },
    //   ],
    // },
    // {
    //   title: 'Atskaišu konfigurācija',
    //   key: 'configId',
    //   type: 'select',
    //   optionKeyName: 'id',
    //   optionValueName: 'name',
    //   optionSource: 'reportsConfig',
    // },
    // {
    //   title: 'Konfigurācija',
    //   key: 'config',
    //   type: 'custom',
    //   hide: true,
    // },
  ],

  EditDynamicReport_objects: [
    {
      title: 'Objekts',
      key: 'object',
      type: 'select',
      optionSource: 'dataset',
      optionKeyName: 'dataKey',
      optionValueName: 'title',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      rules: [Validate.Required],
    },
    {
      title: 'Grupēšanas dimensijas',
      key: 'groupBy',
    },
  ],
  EditDynamicReport_fields: [
    {
      title: 'Lauks',
      key: 'expression',
      type: 'treeselect',
      optionSource: 'dataset',
      optionKeyName: 'dataKey',
      optionValueName: 'title',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      columnRenderMethod: 'renderColumn',
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
      rules: [Validate.Required],
    },
    {
      title: 'Virsraksts',
      key: 'title',
      rules: [Validate.Required],
    },
    {
      title: 'Kopsummas (rinda)',
      key: 'aggregateRows',
      type: 'select',
      options: [
        { key: 'SUM', value: 'Summa' },
        { key: 'AVG', value: 'Vidējais' },
        { key: 'COUNT', value: 'Skaits' },
      ],
    },
    {
      title: 'Kopsummas (kolonna)',
      key: 'aggregateCols',
      type: 'select',
      options: [
        { key: 'SUM', value: 'Summa' },
        { key: 'AVG', value: 'Vidējais' },
        { key: 'COUNT', value: 'Skaits' },
      ],
    },
  ],
  EditDynamicReport_filters: [
    {
      title: 'Lauks',
      key: 'field',
      rules: [Validate.Required],
    },
    {
      title: 'Operators',
      key: 'operator',
      rules: [Validate.Required],
    },
    {
      title: 'Vērtība',
      key: 'valueText',
    },
    {
      title: 'Vērtība (Objekts)',
      key: 'valueId',
    },
  ],
  combinedDynamicReports: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
      render: (value, row) => {
        return (
          <span>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`/api/v1/reports/scheduled/${row.id}/previewPdf/`}
              target="_blank"
            >
              {' '}
              <FontAwesomeIcon icon="file-pdf" style={{ marginRight: '10px' }} />
              <span>{value}</span>{' '}
            </a>
          </span>
        );
      },
    },
    {
      title: 'Atskaišu konfigurācijas',
      key: 'reportsConfig',
      // type: 'multicheckbox',
      type: 'multiselect',
      // extraFieldProps: {
      //   optionGroup: true,
      //   optionGroupParent: 'tagId',
      // },
      optionKeyName: 'id',
      optionValueName: 'name',
      optionSource: 'reportsConfig',
    },
    {
      title: 'Sūtīt ģenerēto pārskatu uz e-pastu/-iem',
      key: 'emails',
      hide: true,
    },
    {
      title: 'Datums no',
      key: 'scheduleStart',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'Datums līdz',
      key: 'scheduleEnd',
      type: 'date',
      time: true,
      rules: [Validate.Required],
    },
    {
      title: 'Intervāls',
      key: 'scheduleInterval',
      type: 'select',
      optionsSortFunc: (a, b) => (a.order > b.order ? 1 : -1),
      options: [
        { key: 'd', value: 'Diena', order: 1 },
        { key: 'w', value: 'Nedēļa', order: 2 },
        { key: 'm', value: 'Mēnesis', order: 3 },
        { key: 'q', value: 'Ceturksnis', order: 4 },
        { key: 's', value: 'Pusgads', order: 5 },
        { key: 'y', value: 'Gads', order: 6 },
      ],
      rules: [Validate.Required],
    },
    {
      title: 'Nedēļas dienas',
      key: 'scheduleWeekdays',
      type: 'multicheckbox',
      multiple: true,
      optionsSortFunc: (a, b) => (a.key > b.key ? 1 : -1),
      options: [
        { key: 1, value: 'P.' },
        { key: 2, value: 'O.' },
        { key: 3, value: 'T.' },
        { key: 4, value: 'C.' },
        { key: 5, value: 'Pk.' },
        { key: 6, value: 'S.' },
        { key: 7, value: 'Sv.' },
      ],
    },
    {
      title: 'Konfigurācija',
      key: 'configuration',
      render: (value, row) => {
        return (
          <span>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`/reports/scheduled/${row.id}`}
              target="_blank"
            >
              {' '}
              <FontAwesomeIcon icon="cog" style={{ marginLeft: '50px' }} />
              <span>{value}</span>{' '}
            </a>
          </span>
        );
      },
    },
    {
      title: 'Pievienoja',
      key: 'createdBy',
      hide: true,
    },
  ],
  dynamicReportOverviews: [
    {
      title: 'Izveidošanas datums',
      key: 'created_at',
      type: 'date',
    },
    {
      title: 'Lietotājs',
      key: 'user',
      type: 'date',
    },
    {
      title: 'Pārskata definīcijas',
      key: 'dynamicReports',
      type: 'multiselect',
    },
    {
      title: 'Rīki',
      key: 'actions',
      filter: false,
      sorter: false,
      render: () => {
        return (
          <ButtonGroup>
            <Button type="primary" size="small">
              Apskatīt
            </Button>
            <Button type="secondary" size="small">
              Lejupielādēt
            </Button>
            <Button type="danger" size="small">
              Dzēst
            </Button>
          </ButtonGroup>
        );
      },
    },
  ],
  portDuesReport: [
    {
      title: 'Periods',
      key: 'period',
      type: 'daterange',
    },
    {
      title: 'Aģents',
      key: 'agentId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    // {
    //   title: 'Piestātnes īpašnieks',
    //   key: 'bowner',
    //   type: 'text',
    // },
    {
      title: 'Piestātnes īpašnieks vai nomnieks',
      key: 'bowners',
      type: 'multiselect',
      options: [
        { key: 3000099, value: 'Apvienotais Baltijas Fonds' },
        { key: 2428, value: 'AUDA  A SIA' },
        { key: 3001201, value: 'B PORT, SIA' },
        { key: 3000045, value: 'BALTIC CONTAINER TERMINAL, SIA' },
        { key: 3000214, value: 'Baltmarine Terminal, SIA' },
        { key: 3163, value: 'BLB Baltijas termināls, A/S' },
        { key: 3000043, value: 'BMGS, AS' },
        { key: 3069, value: 'Bolderājas kuģu remonta rūpnīca SIA' },
        { key: 1002885, value: 'EEMS BEHEER VII B.V.' },
        { key: 3000164, value: 'Extron Baltic, SIA' },
        { key: 1001041, value: 'GAMMA-A SIA' },
        { key: 1001876, value: 'I ZUMO BAY INC. PANAMA CITY' },
        { key: 3164, value: 'JM Ostas Co SIA' },
        { key: 3000133, value: 'JP Termināls, SIA' },
        { key: 3001211, value: 'KRIEVU SALA' },
        { key: 2469, value: 'KRS SIA' },
        { key: 3000044, value: 'KS Terminal, SIA' },
        { key: 3227, value: 'LACON  SIA' },
        { key: 2726, value: 'LATVIJAS JŪRAS ADMINISTRĀCIJA' },
        { key: 2408, value: 'LATVIJAS KUĢNIECĪBA' },
        { key: 3000132, value: 'Latvijas propāna gāze, SIA' },
        { key: 3000082, value: 'Man-Tess Tranzīts SIA' },
        { key: 3001527, value: 'MANGALI SHIPYARD,SIA' },
        { key: 3000217, value: 'Mangaļsalas nams, SIA' },
        { key: 2357, value: 'Mangaļu kuģu remonta rūpn., PAS' },
        { key: 3000083, value: 'MT Osta SIA' },
        { key: 3001587, value: 'Noliktava A,SIA' },
        { key: 3000215, value: 'Osta "Lejasvoleri", SIA' },
        { key: 1000740, value: 'OVI SIA' },
        { key: 3000229, value: 'PARS TERMINĀLS, SIA' },
        { key: 3000066, value: 'Port Magnat SIA' },
        { key: 1002361, value: 'Port Milgravis SIA' },
        { key: 3000216, value: 'Riga Bulk Terminal, SIA' },
        { key: 3001694, value: 'RIGA COAL TERMINAL, SIA' },
        { key: 1005972, value: 'RIGA FERTILIZER TERMINAL' },
        { key: 3001212, value: 'RIGA NORDIC TERMINAL,SIA' },
        { key: 2358, value: 'RĪGAS  KUĢU BŪVĒTAVA, AS' },
        { key: 1003084, value: 'Rīgas 1. Saldētava, AS' },
        { key: 3000134, value: 'Rīgas Brīvostas Flote SIA' },
        { key: 3159, value: 'Rīgas brīvostas pārvalde' },
        { key: 3000042, value: 'RĪGAS CENTRĀLAIS TERMINĀLS, SIA' },
        { key: 2392, value: 'RĪGAS JŪRAS OSTA VOLERI , SIA' },
        { key: 2587, value: 'RĪGAS OSTA' },
        { key: 3000029, value: 'RĪGAS OSTAS ELEVATORS, AS' },
        { key: 1001576, value: 'RĪGAS PASAŽIERU TERMINĀLS, SIA' },
        { key: 1002362, value: 'Rīgas universālais termināls, SIA' },
        { key: 1004911, value: 'SIA "Pantera Iks" 3070 "ALPHA OSTA, SIA' },
        { key: 1003808, value: 'SIA BGS' },
        { key: 3000016, value: 'Starts-Rīga, A/S' },
        { key: 3000062, value: 'Statoil, Neste SIA' },
        { key: 2371, value: 'Strek SIA' },
        { key: 1001577, value: 'Termināls Vecmilgrāvis,MPAS' },
        { key: 3000020, value: 'Vega stividors, SIA' },
        { key: 3000017, value: 'VL BUNKERINGS, SIA' },
        { key: 3001568, value: 'VLD Group SIA' },
        { key: 2988, value: 'VVD Jūras un iekšējo ūdeņu pārvaldes' },
        { key: 3000015, value: 'Woodison Terminal SIA' },
        { key: 3000012, value: 'WT Terminal, SIA' },
      ],
      // optionSource: 'companies',
      // optionsSortFunc: (a, b) => a.value ?  a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null,
      // optionFilters: {
      //   isCargoLoader: [true],
      // },
      extraFieldProps: {
        showSearch: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Piestātne',
      key: 'berthId',
      type: 'multiselect',
      optionSource: 'berths',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Līnija',
      key: 'shippingLineId',
      type: 'select',
      hide: true,
      optionSource: 'shippingLines',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Grupēt',
      key: 'groupBy',
      type: 'select',
      hide: true,
      options: [
        {
          key: 'agent',
          value: 'Aģenti',
        },
        {
          key: 'agentsships',
          value: 'Aģenti un kuģi',
        },
        {
          key: 'ship',
          value: 'Kuģi',
        },
        {
          key: 'shiptypes',
          value: 'Kuģu tipi',
        },
        {
          key: 'berth',
          value: 'Piestātnes',
        },
        {
          key: 'berthsships',
          value: 'Piestātnes un kuģi',
        },
        {
          key: 'berthsowners',
          value: 'Piestātņu īpašnieki',
        },
        {
          key: 'ownersberthsships',
          value: 'Piestātņu īpašnieki, piestātnes, kuģi',
        },
      ],
    },
    {
      title: 'Rādīt maksas',
      key: 'dueTypes',
      type: 'multicheckbox',
      tooltip: 'Izvēlieties, kuru ostas maksu kolonnas attēlot atskaitē. Izvēlētās maksas neietekmē kolonnu "Kopā"!',
      hide: true,
      multiple: true,
      options: [
        {
          key: 'Sanitārā maksa',
          value: 'Sanitārā maksa',
        },
        {
          key: 'Piestātņu maksa',
          value: 'Piestātņu maksa',
        },
        {
          key: 'Kanāla maksa',
          value: 'Kanāla maksa',
        },
        {
          key: 'Pasažieru maksa',
          value: 'Pasažieru maksa',
        },
        {
          key: 'Mazo kuģu maksa',
          value: 'Mazo kuģu maksa',
        },
        {
          key: 'Loču maksa',
          value: 'Loču maksa',
        },
      ],
    },
    {
      key: 'showColTotals',
      title: 'Rādīt kopsummas kolonnu',
      tooltip: 'Attēlot tabulā kolonnu, kura apvieno visas maksas kopā - gan redzamās, gan paslēptās.',
      type: 'checkbox',
      hide: true,
    },
    {
      title: 'Atskaites orientācija',
      tooltip: 'Ja vēlaties izmantot atšķirīgu lapas orientāciju no noklusētās',
      key: 'orientation',
      type: 'select',
      hide: true,
      options: [
        {
          key: 'portrait',
          value: 'Portrets',
        },
        {
          key: 'landscape',
          value: 'Ainava',
        },
      ],
    }

  ],

  cargoTypesReport: [
    {
      title: 'Periods no',
      key: 'dateFrom',
      type: 'date',
    },
    {
      title: 'Periods līdz',
      key: 'dateTill',
      type: 'date',
    },
    {
      title: 'Aģents',
      key: 'agentId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Līnija',
      key: 'shippingLineId',
      type: 'select',
      hide: true,
      optionSource: 'shippingLines',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Virziens',
      key: 'direction',
      type: 'select',
      hide: true,
      options: [
        {
          key: 'IN',
          value: 'Ievests',
        },
        {
          key: 'OUT',
          value: 'Izvests',
        },
        {
          key: 'TRANSIT',
          value: 'Tranzīts',
        },
      ],
    },
    {
      title: 'Grupēt',
      key: 'groupBy',
      type: 'select',
      hide: true,
      options: [
        {
          key: 'cargotype',
          value: 'Kravas veidi',
        },
        {
          key: 'cargogroup',
          value: 'Kravas grupas',
        },
        {
          key: 'goodstype',
          value: 'Preču veidi',
        },
        {
          key: 'goodstypegroups',
          value: 'Preču grupas',
        },
        {
          key: 'agent',
          value: 'Aģenti',
        },
        {
          key: 'ship',
          value: 'Kuģi',
        },
        {
          key: 'country',
          value: 'Valstis',
        },
      ],
    },
  ],

  statisticalOfficeReport: [
    {
      title: 'Periods no (ATA/ATD)',
      key: 'dateFrom',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Periods līdz (ATA/ATD)',
      key: 'dateTill',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Virziens',
      key: 'direction',
      type: 'select',
      tooltip: 'Vizītes virziens (ienākot/izejot). *Attiecas tikai uz zemāk attēloto datu atspoguļošanas tabulu.',
      extraFieldProps: {
        allowClear: true,
      },
      options: [
        { key: 'IN', value: 'Ienākot' },
        { key: 'OUT', value: 'Izejot' },
      ],
    },
  ],

  portPaymentsReport: [
    {
      title: 'Periods no (ATD)',
      key: 'dateFrom',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Periods līdz (ATD)',
      key: 'dateTill',
      type: 'date',
      rules: [Validate.Required],
    },
  ],

  wasteDisposalReport: [
    {
      title: 'Periods no',
      key: 'dateFrom',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Periods līdz',
      key: 'dateTill',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Atskaites veids',
      tooltip:
        'Kompaktajā atskaites versijā rādīsies tikai to kuģu atkritumu veidu kolonnas, kurās ir faktiski nodotie atkritumi',
      key: 'version',
      type: 'select',
      options: [
        {
          key: 'COMPACT',
          value: 'Kompakta atskaite',
        },
        {
          key: 'FULL',
          value: 'Izvērsta atskaite',
        },
      ],
    },
  ],

  shipVisitsReport: [
    {
      title: 'Periods no (ATD)',
      key: 'dateFrom',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Periods līdz (ATD)',
      key: 'dateTill',
      type: 'date',
      rules: [Validate.Required],
    },
  ],

  cargoTypesReport2: [
    {
      title: 'Periods no (ATD)',
      key: 'dateFrom',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Periods līdz (ATD)',
      key: 'dateTill',
      type: 'date',
      rules: [Validate.Required],
    },
    {
      title: 'Aģents',
      key: 'agentId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Līnija',
      key: 'shippingLineId',
      type: 'select',
      hide: true,
      optionSource: 'shippingLines',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Virziens',
      key: 'direction',
      type: 'select',
      hide: true,
      options: [
        {
          key: 'IN',
          value: 'Ievests',
        },
        {
          key: 'OUT',
          value: 'Izvests',
        },
        {
          key: 'TRANSIT',
          value: 'Tranzīts',
        },
      ],
    },
    {
      title: 'Grupēt',
      key: 'groupBy',
      type: 'select',
      hide: true,
      options: [
        {
          key: 'cargotype',
          value: 'Kravas veidi',
        },
        {
          key: 'cargogroup',
          value: 'Kravas grupas',
        },
        {
          key: 'goodstype',
          value: 'Preču veidi',
        },
        {
          key: 'goodstypegroups',
          value: 'Preču grupas',
        },
        {
          key: 'agent',
          value: 'Aģenti',
        },
        {
          key: 'ship',
          value: 'Kuģi',
        },
        {
          key: 'country',
          value: 'Valstis',
        },
      ],
    },
  ],

  movementReport: [
    {
      title: 'Kuģis',
      key: 'shipId',
      type: 'select',
      optionSource: 'ships',
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Aģents',
      key: 'agentId',
      type: 'select',
      optionSource: 'companies',
      optionsSortFunc: (a, b) => (a.value ? a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null),
      optionFilters: {
        isActive: [true],
      },
      extraFieldProps: {
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
      },
    },
    {
      title: 'Periods',
      key: 'period',
      type: 'daterange',
      time: true,
    },
  ],

  dataset: [
    {
      title: 'Nosaukums',
      key: 'title',
    },
    {
      title: 'Sistēmas kods',
      key: 'key',
    },
    {
      title: 'Tips',
      key: 'type',
      type: 'select',
      options: [
        { key: 'object', value: 'Vienība' },
        { key: 'relation', value: 'Apakšvienība' },
        { key: 'field', value: 'Vienības lauks' },
      ],
    },
  ],
  // dynacmicReportCustom_group: [
  //   {
  //     title: 'Objekts',
  //     key: 'object',
  //     type: 'text',
  //     rules: [Validate.Required],
  //   },
  //   {
  //     title: 'Grupēšanas dimensijas',
  //     key: 'groupingDimensions',
  //     type: 'text',
  //     rules: [Validate.Required],
  //   },
  // ],
  randomStore: [
    {
      title: 'Nosaukums',
      key: 'name',
      type: 'text',
    },
  ],
  marpolGroups: [
    {
      title: 'Nosaukums',
      key: 'name',
    },
  ],
  berthDirectories: [
    {
      title: 'Nosaukums',
      key: 'name',
    },
  ],
  tugboatVisits: [
    {
      title: 'Nosaukums',
      key: 'name',
    },
  ],
  fuelTypes: [
    {
      title: 'Nosaukums',
      key: 'name',
      rules: [Validate.Required],
    },
    {
      title: 'Nosaukums angliski',
      key: 'nameEn',
      rules: [Validate.Required],
    },
    {
      title: 'Kods',
      key: 'code',
      rules: [Validate.Required, Validate.FuelCodeDuplicate],
    },
    {
      title: 'Secība sarakstā',
      key: 'order',
      rules: [Validate.Required, Validate.FuelOrderDuplicate],
    },

  ],
});

export default columnDefinitions;

