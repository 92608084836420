import Field from './Field';

import './BooleanMultiselectComponent';
import './CheckboxComponent';
import './MultiCheckboxes';
import './RadioboxComponent';
import './CodeComponent';
import './DateComponent';
import './DateRangeComponent';
import './TimeComponent';
import './FilesComponent';
import './LabelComponent';
import './MapComponent';
import './JsonDisplayComponent';
import './MultiSelectComponent';
import './SelectComponent';
import './TableComponent';
import './FieldTableComponent';
import './DataGroupComponent';
import './TextComponent';
import './PasswordComponent';
import './TextareaComponent';
import './ColorPickerComponent';
import './IndicatorComponent';
import './ListComponent';
import './OwnableComponent';
import './RichTextComponent';
import './TreeSelectComponent';
import './PictureComponent';
import './CustomComponent';
import './OwnableNameComponent';
import './NumberComponent';

export default Field;
