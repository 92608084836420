import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Form, Input, Button, message } from 'antd';
import FontAwesomeIcon from '@ois-lib/assets/icon/FontAwesomeIcon';
import Validate from '@ois-lib/validate';
import { Spinner } from '@ois-lib/addons/components/ButtonWrapComponent';

const styleSheet = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    width: '85%',
    height: '80%',
  },
  subButton: {
    display: 'flex',
    marginBottom: '16px',
  },
  heading: {
    fontWeight: '600',
    fontSize: '1.1em',
    marginBottom: '1.1em',
  },
  btn: {
    width: '100%',
  },
};

@inject('authStore')
@withRouter
@observer
class PasswordResetFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      token: null,
      email: null,
    };
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const { authStore, history } = this.props;
    const {
      location: { searchParams },
    } = history;
    if (searchParams && searchParams.token) {
      const result = await authStore.checkPasswordResetToken(searchParams.token);
      if (result && result.ok) {
        this.setState({ loading: false, token: result.token, email: result.email });
        return true;
      }
    }
    message.error('Saite paroles atjaunošanai ir nederīga!');
    history.push('/login');
    return false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { authStore, form, history } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        const { token, email } = this.state;
        this.setState({ loading: true });
        const result = await authStore.resetPassword({ ...values, token, email });
        if (result && result.ok) {
          message.success(`Parole veiksmīgi atjaunota!`);
          await new Promise((r) => setTimeout(r, 3000));
          history.replace(`/login`);
          this.setState({ loading: false });
        } else {
          form.setFields({
            password: {
              value: ``,
              errors: [new Error(result?.error ?? `Paroles atjaunošana neveiksmīga!`)],
            },
            password_reset: ``,
          });
          this.setState({ loading: false });
        }
      }
    });
  };

  render() {
    const { form } = this.props;
    const { loading } = this.state;
    const { getFieldDecorator } = form;

    return loading ? (
      <Spinner />
    ) : (
      <Form onSubmit={this.handleSubmit} style={styleSheet.form}>
        <h1 style={styleSheet.heading}>Lietotāja paroles atjaunošana</h1>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Lūdzu ievadiet paroli!' }, Validate.Password],
          })(
            <Input.Password
              prefix={<FontAwesomeIcon icon="lock" style={{ fill: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Jaunā parole"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password_confirmation', {
            rules: [{ required: true, message: 'Lūdzu ievadiet paroli atkārtoti!' }, Validate.Password],
          })(
            <Input.Password
              prefix={<FontAwesomeIcon icon="lock" style={{ fill: 'rgba(0,0,0,.25)' }} />}
              type="password_confirmation"
              placeholder="Jaunā parole atkārtoti"
              visibilityToggle={false}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={styleSheet.btn}>
            Mainīt paroli
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const PasswordResetForm = Form.create({ name: 'PasswordResetForm' })(PasswordResetFormComponent);
export default PasswordResetForm;
