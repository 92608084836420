/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Select, Input } from 'antd';
import { observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import { Field, Spinner } from './Field';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';
import StoreFactory from '../stores';
import Addon, { AddonComponent } from '../addons';

const SelectLabel = observer(({ value, col, record_store, name }) => {
  const record = record_store ? record_store.item : null;
  if (record && record[`_t_${name}`] !== undefined) {
    return record[`_t_${name}`] || ' - ';
  }
  const res = col.loadRowTransform({ [col.key]: value }, true);
  return <span>{res}</span>;
});

const SelectObserver = observer(({ col, ...props }) => {
  const [isOptionsLoading, setOptionsLoading] = useState(false);
  const [asyncOptions, setAsyncOptions] = useState(null);
  const _this = {
    // asyncOptions: observable.box(null),
    // loading: observable.box(false),
    waitTimeout: 0,
    loadedPrefixes: {},
    withAddonWrapper: (Component) => {
      const addonBefore = [];
      const addonAfter = [];
      const { value, addons } = props;
      if (value) {
        if (col.optionStore) {
          col.optionStore.optionFilterCallback = ({ id }) => id === value;
          const row = col.optionStore.getSelectedOptionRow;
          if (col.optionPrefixFields) {
            if (row) {
              for (const prefixField of col.optionPrefixFields) {
                if (row[prefixField]) {
                  addonBefore.push(
                    <AddonComponent key={prefixField} value={row[prefixField]}>
                      <Input disabled key={prefixField} value={row[prefixField]} />
                    </AddonComponent>
                  );
                }
              }
            } else {
              return Component;
            }
          }
        }

        if (addons && addons.length) {
          let key = 0;
          for (const addon of addons) {
            addonAfter.push(
              <AddonComponent
                key={key++}
                value={value}
                forceRenderFunc={
                  () =>
                    addon.renderComponent({
                      props,
                      rowId: value,
                    })
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            );
          }
        }
      }

      return !addonBefore || !addonAfter ? (
        Component
      ) : (
        <div className="select-with-addonAfter">
          {addonBefore && !!addonBefore.length && <div className="addon-action-block left">{addonBefore}</div>}
          {Component}
          {addonAfter && !!addonAfter.length && <div className="addon-action-block right">{addonAfter}</div>}
        </div>
      );
    },

    handleOpetionStoreSearch: (value) => {
      if (value.length > 2) {
        if (!_this.optionStore) {
          _this.optionStore = StoreFactory.getStore(col.optionSource);
          if (col.optionFilters) {
            _this.optionStore.systemFilters = col.optionFilters;
          }
        }

        setOptionsLoading(true);

        clearTimeout(_this.waitTimeout);
        _this.waitTimeout = setTimeout(async () => {
          if (_this.optionStore) {
            let data = [];
            const searchResult = await _this.optionStore.search(`${value}`.toLocaleLowerCase());
            if (searchResult && searchResult.data) {
              data = searchResult.data;
            }

            const _asyncOptions = data.map((item) => {
              return {
                ...item,
                key: col.optionKeyName && item[col.optionKeyName] ? item[col.optionKeyName] : item.id,
                value:
                  col.optionValueName && item[col.optionValueName]
                    ? item[col.optionValueName]
                    : item.name || item.description || ' - ',
              };
            });
            setAsyncOptions(_asyncOptions);
            col.options = [..._asyncOptions];
            setOptionsLoading(false);
          }
        }, 500);
      }
    },
  };

  const record = props.record_store.item;
  const isNew = record && (!Object.keys(record).length || !record.id);
  const isLazy =
    col.optionSource &&
    ['companies', 'companyRepresentatives', 'ports', 'shipVisits', 'ships', 'tugboatVisits'].includes(col.optionSource);

  let options = [];
  const loading = col.optionStore && col.optionStore.loadingFull;
  if (col.optionSource && props.record_store) {
    if (!props.record_store.loadingOne) {
      if (record && (record[`_t_${props.name}`] !== undefined || (isNew && isLazy))) {
        if (record && record[props.name] && isLazy) {
          options = [{ key: record[props.name], value: record[`_t_${props.name}`] }];
        } else if (!isLazy) {
          options = col.getOptionsFromStore();
        }
      } else {
        options = col.getOptionsFromStore();
      }
    }
  } else {
    options = col.options;
  }

  if (record && isLazy) {
    props.onSearch = _this.handleOpetionStoreSearch;
    props.filterOption = false;
    options =
      asyncOptions && asyncOptions.length
        ? options.reduce((acc, curr) => {
            if (!acc.find((a) => a.key === curr.key)) {
              acc.push(curr);
            }
            return acc;
          }, toJS(asyncOptions, { recurseEverything: true }))
        : options;

    props.notFoundContent = !asyncOptions ? (
      <div className="empty-select-search">
        <FontAwesomeIcon icon="print-search" />
        <p>Ievadiet vismaz 3 simbolus, lai meklētu...</p>
      </div>
    ) : undefined;
  }
  let allOptions = null;
  let lonelyOptions = null;
  if (options && options.length) {
    if (props.optionGroup) {
      options.sort((a, b) => a.value ?  a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) : null);

      const parentKeys = Array.from(new Set(options.map((e) => e[props.optionGroupParent]).filter((e) => !!e)));
      const childNodes = options.filter((n) => !!n[props.optionGroupParent]);
      const parentNodes = options.filter((n) => parentKeys.includes(n.key));
      const lonelyNodes = options.filter((n) => !n[props.optionGroupParent] && !parentKeys.includes(n.key));

      allOptions = parentNodes.map((parent) => (
        <Select.OptGroup key={parent.key} label={parent.value}>
          {childNodes.map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {col.renderOption ? col.renderOption(option) : <span>- {option.value}</span>}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ));
      lonelyOptions = lonelyNodes.length
        ? lonelyNodes.map((option) => (
            <Select.Option key={option.key || 0} value={option.key}>
              {col.renderOption ? col.renderOption(option) : option.value}
            </Select.Option>
          ))
        : null;
    } else {
      allOptions = options.map((option) => (
        <Select.Option key={option.key || 0} value={option.key}>
          {col.renderOption ? col.renderOption(option) : option.value}
        </Select.Option>
      ));
    }
  }
  return loading ? (
    <Spinner />
  ) : (
    _this.withAddonWrapper(
      <Select
        {...props}
        loading={isOptionsLoading}
        removeIcon={<FontAwesomeIcon icon="times" />}
        clearIcon={<FontAwesomeIcon type="regular" icon="times-circle" />}
      >
        {lonelyOptions}
        {allOptions}
      </Select>,
      props.addons
    )
  );
});

@Field.register
class MultiSelectComponent extends Field {
  static code = 'multiselect';

  renderComponent(props) {
    if (this.label || props.asText) {
      return <SelectLabel col={this} {...props} />;
    }
    return <SelectObserver col={this} mode="multiple" {...props} />;
  }
}

export default MultiSelectComponent;
