import React from 'react';
import { observer } from 'mobx-react';
import fieldSpec from '../stores/FieldSpec';
import { Field } from './Field';

const MapLabelComponent = observer(
  ({ isJson, tableName, childrenKey, name, skipIfNoTitle = false, returnAsObject = false, ...props }) => {
    let { value } = props;
    if (!value) {
      return <div />;
    }

    if (isJson) {
      value = value ? JSON.parse(value) : {};
    }

    const originalValue = value;

    value = fieldSpec.getColTitles(tableName, originalValue, isJson, skipIfNoTitle);

    try {
      const obj =
        typeof value === 'string' ? JSON.parse(`{${value.replace(/=>/g, ':').replace(/NULL/g, 'null')}}`) : value;
      let list = [];
      for (const k in obj) {
        if (name === 'row_data' && !obj[k]) {
          continue;
        }
        list.push({ k, v: obj[k] });
      }
      list = list.map(({ k, v }) => {
        let kt = k;
        let tName = childrenKey || tableName;

        if (k && k.includes('#')) {
          const parts = k.split('#');
          const titles = parts.map((part) => {
            const title = fieldSpec.getColTitle(tName, part, skipIfNoTitle);
            if ((part | 0) != part) {
              tName = part;
            } else {
              return `(${(part | 0) + 1})→`;
            }
            return title;
          });
          kt = titles.join('');
        }

        if (v && typeof v === 'object' && !v.hasOwnProperty('$$typeof')) {
          return {
            k: !isNaN(parseInt(kt, 10)) ? `#${kt + 1}` : kt,
            v: (
              <MapLabelComponent
                tableName={childrenKey || tName}
                value={v}
                skipIfNoTitle={skipIfNoTitle}
                returnAsObject={returnAsObject}
                // isJson={isJson}
              />
            ),
            hasChildren: Array.isArray(v),
          };
        }
        return { k: kt, v: v ? <strong>{v}</strong> : '(tukšs lauks)', hasChildren: Array.isArray(v) };
      });

      if (returnAsObject) {
        return list
          .filter(({ k, v }) => k !== 'id' && !k.includes('Id:'))
          .map(({ k, v, hasChildren }) => ({
            k,
            v,
            hasChildren,
          }));
      }

      return (
        <ul>
          {list
            .filter(({ k, v }) => k !== 'id' && !k.includes('Id:'))
            .map(
              ({ k, v, hasChildren }) =>
                k && (
                  <li key={k}>
                    {hasChildren ? <h4>{k}: </h4> : `${k}: `}
                    {v}
                  </li>
                )
            )}
        </ul>
      );
    } catch (e) {
      console.error(e);
      return value;
    }
  }
);

export { MapLabelComponent };

@Field.register
class MapComponent extends Field {
  static code = 'map';

  static MapLabelComponent = MapLabelComponent;

  loadRowTransform(row) {
    const { key, json } = this;
    return (
      <MapLabelComponent
        key={key}
        isJson={json}
        value={row[key]}
        tableName={row.table_name || row.objectType}
        name={this.key}
        skipIfNoTitle={this.skipIfNoTitle}
      />
    );
  }

  renderComponent(props) {
    if (props.skipIfNoTitle || this.skipIfNoTitle) {
      props.skipIfNoTitle = props.skipIfNoTitle || this.skipIfNoTitle;
    }
    return <MapLabelComponent {...props} />;
  }
}

export default MapComponent;
