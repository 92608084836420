import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card, Modal, Table, message } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol, gridCol2, gridRow, FormItem } from '@ois-lib';
// import apiServiceProxy from '@ois-lib/services/api';

@inject('calculationPeriodStore', 'appStore', 'authorize', 'can')
@observer
class EditCalculationPeriod extends EditFormBase {
  title = 'Finanšu perioda kartiņa';
  storeName = 'calculationPeriodStore';
  baseUrl = '/finance/periods';

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedInvoices: [],
    };
    this.sendData = this.sendData.bind(this);
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    this.load(this.props.calculationPeriodStore);
  }

  async sendData() {
    // Event.preventDefault();
    const { selectedInvoices } = this.state;
    if (selectedInvoices.length) {
      message.success('Datu nosūtīšana uzsākta');
      this.toggleModal(false);
      const res = await this.props[this.storeName].makeApiCall('sendtohorizon', { data: this.state.selectedInvoices });
      if (res && res.success) {
        // message.success(res.success);
        this.toggleModal(false);
        // this.props.appStore.history.push(`/finance/periods`);
      }
    } else {
      this.toggleModal(false);
    }
  }

  toggleModal = (modalVisible) => {
    this.setState({ modalVisible });
  };

  selectInvoices = (selectedInvoices) => {
    this.setState({ selectedInvoices });
  };

  canSave() {
    return this.props.can('edit financeperiods');
  }

  canDelete() {
    return this.props.can('delete financeperiods');
  }

  renderForm() {
    // const { authorize, match } = this.props;
    // authorize([match.params.id !== 'new' && 'view financeperiods', 'edit financeperiods']);
    const { item } = this.props[this.storeName];
    const dataSource =
      item && item.invoices
        ? item.invoices
            .filter((i) => !i.sentToHorizon)
            .map((i) => ({
              key: i.id,
              nr: i.nr,
              date: i.date,
              recipient: i.recipientId,
              // recipient: StoreFactory.getStore('invoices').colByName['recipientId'].getSingleOptionFromStore(i.recipientId).reduce((_x,el)=>el.value,''),
              currency: i.currencyId,
              amount: i.amount,
              vat: i.vat,
            }))
        : [];
    const columns = [
      {
        title: 'Numurs',
        dataIndex: 'nr',
      },
      {
        title: 'Datums',
        dataIndex: 'date',
      },
      {
        title: 'Summa',
        dataIndex: 'amount',
      },
      {
        title: 'PVN',
        dataIndex: 'vat',
      },
    ];

    return (
      <>
        <br />
        <Row {...gridRow}>
          <Card title="Finanšu periods" bordered className="mt15">
            <Col {...gridCol2}>
              <FormItem name="name" />
              <FormItem name="invoiceSuffix" />
              <FormItem name="startDate" />
              <FormItem name="endDate" />
              <FormItem name="status" />
              {/*
              <Col span={12}>
              <Button type="info" onClick={async () => {
                 const res = await this.props.calculationPeriodStore.call('generateInvoices')
                 if (res) {
                      console.log('response',res);
                 }
              }}>
                  Izveidot jaunus rēķinus
                </Button>
              </Col>
              <Col span={12}>
                <Button disabled={!(this.props.form.getFieldValue('invoices') && this.props.form.getFieldValue('invoices').length)} type="primary" onClick={() => this.toggleModal(true)}>
                  Sūtīt rēķinus uz HORIZON IS
                </Button>
              </Col>
              */}
            </Col>
          </Card>
        </Row>

        <Row {...gridRow}>
          <Col {...gridCol} className="mt15">
            <FormItem
              disabled
              name="invoices"
              // rowContextMenu={
              //   this.props.form.getFieldValue('status') !== 'CLOSED' && !this.props.form.isFieldsTouched()
              //     ? (record) =>
              //         [
              //           // ['minus', 'Dzēst rēķinu', () => console.log('delete click',record)],
              //           [
              //             'trash',
              //             'Dzēst rēķinu',
              //             () =>
              //               Modal.confirm({
              //                 title: 'Vai tiešām vēlaties dzēst šo rēķinu?',
              //                 content:
              //                   'Apstiprinot šo paziņojumu, ieraksts tiks dzēsts. Jāņem vērā, ka sistēma pēc dzēšanas rēķinu automātiski ģenerēs atkārtoti.',
              //                 onOk: async () => {
              //                   try {
              //                     // const res = await StoreFactory.getStore('invoices').call('DELETE')
              //                     const res = await apiServiceProxy['invoices/' + record.id](null, 'DELETE');
              //                     if (res) {
              //                       // this.store.refreshOne();
              //                       // item.invoices = item.invoices.filter(i => i.id !== record.id);
              //                       this.props.form.setFieldsValue({
              //                         invoices: this.props.form
              //                           .getFieldValue('invoices')
              //                           .filter((i) => i.id !== record.id),
              //                       });
              //                       message.success('Ieraksts veiksmīgi dzēsts');
              //                     }
              //                   } catch (err) {
              //                     console.error(err);
              //                     message.error('Kļūda dzēšot ierakstu' + err.getMessage());
              //                   }
              //                 },
              //                 okType: 'danger',
              //                 okText: 'Dzēst ierakstu',
              //                 cancelText: 'Atcelt',
              //               }),
              //           ],
              //         ].map(([icon, title, cb], key) => (
              //           <Menu style={{ minWidth: 180 }}>
              //             <Menu.Item key={key}>
              //               <a aria-hidden href="#!delete" onClick={cb}>
              //                 <FontAwesomeIcon icon={icon} style={{ float: 'left' }} />
              //                 <span>{title}</span>
              //               </a>
              //             </Menu.Item>
              //           </Menu>
              //         ))
              //     : undefined
              // }
              onRowClick={(row) => {
                this.props.appStore.history.push(`/finance/invoices/${row.id || ''}`);
                window.scrollTo(0, 0);
              }}
            />
          </Col>
        </Row>

        <Modal
          title="Izvēlieties rēķinus sūtīšanai uz HORIZON IS"
          visible={this.state.modalVisible}
          width={'860px'}
          onOk={() => this.sendData()}
          onCancel={() => this.toggleModal(false)}
        >
          <Table
            rowSelection={{
              onChange: (selectedRowKeys) => {
                this.selectInvoices(selectedRowKeys);
              },
            }}
            size="small"
            pagination={{ pageSize: 40 }}
            columns={columns}
            dataSource={dataSource}
          />
          x
        </Modal>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditCalculationPeriod' })(EditCalculationPeriod));
