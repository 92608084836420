import React, { useState } from 'react';
import { Table, Button, Popconfirm, Form, Card, Row, Col, Tag } from 'antd';
import { toJS, reaction } from 'mobx';
import { FormItem, EditRowFormBase } from '@ois-lib';
import StoreFactory from '../../stores';
import FontAwesomeIcon from '../../assets/icon/FontAwesomeIcon';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => {
  console.log('props@EditableRow:', props, form, index);
  const Wrapper = Form.create({ name: 'EditableRow' })(EditRowFormBase);
  return (
    <Wrapper>
      <tr {...props} />
    </Wrapper>
  );
};

// const EditableRow = ({ form, index, ...props }) => (
//   <EditableContext.Provider value={form}>
//     <tr {...props} />
//   </EditableContext.Provider>
// );

// const EditableFormRow = (props) => {
//   // console.log('props@EditableFormRow: ', props);
//   return Form.create({ name: 'EditableRow' })(EditableRow);
// };
const EditableFormRow = EditableRow;

const EditableCell = (props) => {
  const [formInstance, setFormInstance] = useState(null);
  const [input, setInput] = useState(null);
  const [editing, setEditing] = useState(false);

  if (editing && input) {
    input.focus();
  }

  const toggleEdit = () => {
    // console.log('editing@toggleEdit: ', editing);
    setEditing(!editing);
  };

  const save = (e) => {
    const { record, handleSave } = props;
    formInstance.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  const renderCell = (form) => {
    // console.log('props@renderCell: ', props);

    if (!formInstance && form) {
      setTimeout(() => setFormInstance(form));
    }
    const { children, dataIndex, record, title } = props;
    // console.log('dataIndex: ', dataIndex);

    console.log('props: ', props);

    console.log('AAA: ', dataIndex);

    return (
      <div aria-hidden onClick={toggleEdit}>
        <FormItem name={dataIndex} asText={!editing} />
      </div>
    );
  };

  const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = props;
  return <td {...restProps}>{editable ? renderCell() : children}</td>;
};

const WrapperFragment = ({ children }) => <React.Fragment key="wrapper">{children}</React.Fragment>;

class EditableTable extends React.Component {
  constructor(props) {
    super(props);

    // const { formKind: FormKind } = props;
    // if (props.formKind) {
    //   props.formKind.isLocal = true;
    // }

    // this.EditableFormRow = FormKind;
    // console.log('props@EditableTable->constructor: ', props.formKind);
  }

  componentDidMount() {
    const { value, columnRenderMethods } = this.props;
    const store = this.getStore();

    this._is_mounted = true;
    store.loadingOne = false;
    store._data = value || [];

    if (columnRenderMethods && store) {
      store.columns.map((column) => {
        if (columnRenderMethods[column.key]) {
          column.render = columnRenderMethods[column.key];
        }
        return column;
      });
    }

    // if (forcedFiltersOptions && forcedFiltersOptions.options) {
    //   const { columnKey, defaultOptionValue, options } = forcedFiltersOptions;
    //   this.filterOptions[columnKey] = defaultOptionValue;
    //   store.sysFilter({ [columnKey]: [defaultOptionValue] });
    //   this.actions.push(
    //     <Radio.Group
    //       key="filter"
    //       className="table-ffradio-actions"
    //       defaultValue={this.filterOptions[columnKey]}
    //       onChange={(e) => {
    //         this.filterOptions[columnKey] = e.target.value;
    //         store.sysFilter({ [columnKey]: [e.target.value] });
    //       }}
    //     >
    //       {options.map((option) => (
    //         <Radio key={option.key} value={option.key}>
    //           {option.value}
    //         </Radio>
    //       ))}
    //     </Radio.Group>
    //   );
    // }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ value: newValue }) {
    const { value: oldValue } = this.props;
    if (newValue !== oldValue && newValue) {
      this.store.updateCause = 'fromProps';
      this.store._data = newValue.map((row) => ({
        ...row,
        key: row.id ? row.id : Math.random(),
      }));
    }
  }

  componentWillUnmount() {
    this._is_mounted = false;
    if (typeof this.disposer === 'function') {
      this.disposer();
    }
  }

  getStore = () => {
    if (this.store) {
      return this.store;
    }

    const { id, name, onChange, formInstance } = this.props;
    const store = StoreFactory.getStore(id);
    store.isLocal = true;
    if (typeof this.disposer === 'function') {
      this.disposer();
    }
    this.disposer = reaction(
      () => toJS(store._data, { recurseEverything: true }),
      () => {
        if (!this._is_mounted) {
          return;
        }
        const newData = toJS(store._data, { recurseEverything: true });
        const newValue = newData.map((row) => ({ ...row, key: undefined }));
        if (store.updateCause !== 'value') {
          if (!store.updateCause && !this.changed) {
            formInstance.setFieldsValue({ [name]: newValue });
          } else if (this.changed) {
            onChange(newValue);
          }
        }
        this.changed = false;
        store.updateCause = undefined;
      }
    );
    this.store = store;
    return store;
  };

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter((item) => item.key !== key) });
  };

  handleAdd = () => {
    // const { count, dataSource } = this.state;
    const store = this.getStore();
    const { colByName } = store;
    const item = {
      key: Math.random(),
    };
    for (const colKey in colByName) {
      item[colKey] = null;
    }
    store._data.push(item);
    // console.log('store: ', store);
    // const newData = {
    //   key: count,
    //   name: `Edward King ${count}`,
    //   age: 32,
    //   address: `London, Park Lane no. ${count}`,
    // };
    // this.setState({
    //   dataSource: [...dataSource, newData],
    //   count: count + 1,
    // });
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  render() {
    const { props } = this;
    const store = this.getStore();
    // console.log('store: ', store);
    let { columns } = store;
    const { data } = store;
    const components = {
      //   header: { wrapper: tableHeaderWrapper(store) },
      body: {
        row: this.EditableFormRow || EditableFormRow,
        cell: EditableCell,
      },
    };
    columns = columns.map((col) => {
      return {
        ...col,
        sorter: false,
        fieldLabel: undefined,
        filterDropdown: undefined,
        filterIcon: undefined,
        onCell: (record) => ({
          record,
          editable: !(col.asText && col.label),
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    columns.push({
      sorter: false,
      fieldLabel: undefined,
      filterDropdown: undefined,
      filterIcon: undefined,
      onCell: (record) => ({
        record,
        title: 'Rīki',
      }),
    });

    const Wrapper = props.noCard ? WrapperFragment : Card;

    return (
      <div
        style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}
        className="cabinet_table_group local-table editable-table"
      >
        <Wrapper
          style={{
            width: '100%',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          size="small"
          title={
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={{ span: 24 }} className="table_header_title">
                <span className="table_header_title_item">{props.title}</span>
                <Tag>{store.total}</Tag>
                <Button
                  size="small"
                  onClick={this.handleAdd}
                  type="primary"
                  style={{ marginLeft: 'auto', paddingBottom: 25 }}
                >
                  <FontAwesomeIcon icon="plus" className="btn-icon" /> Pievienot jaunu
                </Button>
              </Col>
            </Row>
          }
        >
          <Col
            className="no-row-table"
            style={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="ant-table-title" style={{ flex: '0 0 auto' }} />
            <Table
              style={{ overflow: 'auto', flex: '1 1 auto' }}
              size="small"
              components={components}
              rowClassName={() => 'pointer editable-row'}
              bordered={false}
              dataSource={data}
              columns={columns}
            />
          </Col>
        </Wrapper>
      </div>
    );
  }
}

export default EditableTable;

/*
class DataFieldTableComponent extends React.Component {
  actions = [];
  filterOptions = {};

  constructor(props) {
    super(props);
    if (props.formKind) {
      props.formKind.isLocal = true;
    }
    // this.state = { modal: false };
  }

  componentDidMount() {
    const { value, columnRenderMethods, forcedFiltersOptions } = this.props;
    const store = this.getStore();

    this._is_mounted = true;
    store.loadingOne = false;
    store._data = value || [];

    if (columnRenderMethods && store) {
      store.columns.map((column) => {
        if (columnRenderMethods[column.key]) {
          column.render = columnRenderMethods[column.key];
        }
        return column;
      });
    }

    if (forcedFiltersOptions && forcedFiltersOptions.options) {
      const { columnKey, defaultOptionValue, options } = forcedFiltersOptions;
      this.filterOptions[columnKey] = defaultOptionValue;
      store.sysFilter({ [columnKey]: [defaultOptionValue] });
      this.actions.push(
        <Radio.Group
          key="filter"
          className="table-ffradio-actions"
          defaultValue={this.filterOptions[columnKey]}
          onChange={(e) => {
            this.filterOptions[columnKey] = e.target.value;
            store.sysFilter({ [columnKey]: [e.target.value] });
          }}
        >
          {options.map((option) => (
            <Radio key={option.key} value={option.key}>
              {option.value}
            </Radio>
          ))}
        </Radio.Group>
      );
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ value: newValue }) {
    const { value: oldValue } = this.props;
    if (newValue !== oldValue && newValue) {
      this.store.updateCause = 'fromProps';
      this.store._data = newValue.map((row) => ({
        ...row,
        key: row.id ? row.id : Math.random(),
      }));
    }
  }

  componentWillUnmount() {
    this._is_mounted = false;
    if (typeof this.disposer === 'function') {
      this.disposer();
    }
  }

  getStore = () => {
    if (this.store) {
      return this.store;
    }

    const { id, name, onChange, formInstance } = this.props;
    const store = StoreFactory.getStore(id);
    store.isLocal = true;
    if (typeof this.disposer === 'function') {
      this.disposer();
    }
    this.disposer = reaction(
      () => toJS(store._data, { recurseEverything: true }),
      () => {
        if (!this._is_mounted) {
          return;
        }
        const newData = toJS(store._data, { recurseEverything: true });
        const newValue = newData.map((row) => ({ ...row, key: undefined }));
        if (store.updateCause !== 'value') {
          if (!store.updateCause && !this.changed) {
            formInstance.setFieldsValue({ [name]: newValue });
          } else if (this.changed) {
            onChange(newValue);
          }
        }
        this.changed = false;
        store.updateCause = undefined;
      }
    );
    this.store = store;
    return store;
  };

  handleRowClick = (_row) => {
    const { props, store } = this;
    const { formKind: FormKind } = props;

    let row;
    if (_row.key) {
      row = toJS(
        store._data.find((el) => el.key === _row.key),
        { recurseEverything: true }
      );
    } else {
      row = _row;
    }
    if (FormKind) {
      this.setState({ modal: true, data: row });
      runInAction(() => {
        store.item = row;
      });
    }
  };

  render() {
    const { props, store, ref } = this;
    const { formKind: FormKind, modalProps, extraItemProps } = props;
    const { modal, data, loading } = this.state;
    const rowOnClick = props.rowOnClick
      ? (_row) => {
          props.rowOnClick(_row, this.handleRowClick);
        }
      : this.handleRowClick;

    return store ? (
      <div>
        <DataTable
          actions={this.actions}
          store={store}
          onRowClick={rowOnClick}
          onAdd={
            props.disabled
              ? undefined
              : (row, b) => {
                  if (FormKind) {
                    store.item = {};
                    this.setState({ modal: true, data: {} });
                  }
                }
          }
          {...props}
          {...extraItemProps}
        />
        <Modal
          {...modalProps}
          centered
          closable={false}
          maskClosable={false}
          visible={modal}
          onOk={() => this.setState({ modal: false })}
          onCancel={() => this.setState({ modal: false })}
          footer={[
            !!data &&
              !!data.key &&
              ref &&
              !ref.forceDisableEdit &&
              (!ref.canDelete || (ref.canDelete && ref.canDelete())) && (
                <Button
                  key="delete"
                  type="danger"
                  onClick={() => {
                    this.setState({ modal: false });
                    this.changed = true;
                    store._data = store._data.filter((row) => row.key && row.key !== data.key);
                  }}
                >
                  Dzēst
                </Button>
              ),
            <Button key="back" onClick={() => this.setState({ modal: false })}>
              Aizvērt
            </Button>,
            ref && !ref.forceDisableEdit && (!ref.canSave || (ref.canSave && ref.canSave())) && (
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={async (e) => {
                  if (ref) {
                    this.changed = true;
                    try {
                      store.item = await this.ref.handleLeanSubmit(e);
                    } catch (err) {
                      // console.error(err);
                      return null;
                    }
                    if (data.key) {
                      store._data = store._data.map((row) =>
                        row.key === data.key
                          ? { ...toJS(store.item, { recurseEverything: true }), ...this.filterOptions }
                          : row
                      );
                    } else {
                      const item = {
                        key: Math.random(),
                        ...toJS(store.item, { recurseEverything: true }),
                        ...this.filterOptions,
                      };
                      store._data.push(item);
                    }
                    this.setState({ modal: false });
                  }
                  return true;
                }}
              >
                Saglabāt
              </Button>
            ),
          ]}
        >
          {FormKind && (
            <FormKind
              local
              wrappedComponentRef={(_ref) => {
                this.ref = _ref;
                if (_ref && _ref.props && _ref.props.form) {
                  this.formRef = _ref.props.form;
                  this.formRef.resetFields();
                  if (_ref) {
                    const item = { ...toJS(store.item, { recurseEverything: true }) };
                    if (!Object.keys(item).length) {
                      for (const col of store.definedColumns) {
                        item[col.key] = col.type && col.type === 'table' ? [] : null;
                      }
                    }
                    this.ref.setFields(item, store);
                  }
                  if (_ref && !this.refTimeout) {
                    this.refTimeout = setTimeout(() => {
                      clearTimeout(this.refTimeout);
                      this.forceUpdate();
                    });
                  }
                } else {
                  this.formRef = undefined;
                }
              }}
              parentProps={{ ...props }}
              store={store}
            />
          )}
        </Modal>
      </div>
    ) : (
      <div />
    );
  }
}

export default DataFieldTableComponent;
*/
