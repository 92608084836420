import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authStore from '../stores/AuthStore';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!authStore.loggedIn ? <Component {...rest} {...props} /> : <Redirect to="/" />)}
  />
);

export default PublicRoute;
