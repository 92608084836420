import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button, Spin, Popover } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarToolbar, { CalendarView } from './CalendarToolbar';

const localizer = momentLocalizer(moment);
const withProps = (props) => (Component) => (calendarProps) => <Component {...props} {...calendarProps} />;

@observer
class CalendarComponent extends React.Component {
  events = [];

  formats = {
    dateFormat: 'D',
    weekdayFormat: 'ddd',
    dayFormat: 'dddd (L)',
    monthHeaderFormat: 'YYYY.[gada] MMMM',
    dayHeaderFormat: 'YYYY.[gada] D.MMMM (dddd)',
    dayRangeHeaderFormat: (date, _culture, _localizer) =>
      [_localizer.format(date.start, 'D.MMMM'), _localizer.format(date.end, 'D.MMMM')].join(' - '),
  };

  render() {
    const {
      loading,
      columns,
      filters,
      onFilter,
      onEvent,
      dataSource,
      titleCol,
      itemTitle,
      startTimeCol,
      endTimeCol,
      eventProps,
    } = this.props;

    const events = dataSource.map((i) => ({
      title: i[titleCol] ? i[titleCol] : itemTitle,
      start: (i[startTimeCol] && moment(i[startTimeCol], 'L LT').toDate()) || undefined,
      end: (i[startTimeCol] && moment(i[endTimeCol], 'L LT').toDate()) || undefined,
      resource: i,
    }));

    return (
      <div className="calendarWrapper">
        {(!loading && (
          <Calendar
            className="big-calendar"
            localizer={localizer}
            onSelectEvent={({ resource }) => (onEvent ? onEvent(resource) : false)}
            eventPropGetter={eventProps}
            components={{
              event: ({ event, title }) => {
                const { resource: item } = event;
                const intoText = columns
                  .map((column) => {
                    if (column.optionStore && column.optionStore.loadingFull) {
                      return 'Notiek datu ielāde...';
                    }
                    return column.render ? column.render(item[column.key], item) : item[column.key];
                  })
                  .join(' // ');
                const popoverContent = (
                  <>
                    <ul className="calendar-popover-list">
                      {columns.map((column) => (
                        <li key={column.key}>
                          <strong>{`${column.getFormLabel(column)}: `}</strong>
                          {column.render ? column.render(item[column.key], item) : item[column.key]}
                        </li>
                      ))}
                    </ul>
                    {!!onEvent && <Button onClick={() => onEvent(item)}>Skatīt vairāk</Button>}
                  </>
                );
                return (
                  <>
                    {intoText}
                    <Popover
                      className="calendar-popover-wrap"
                      title={title ? <strong>{title}</strong> : null}
                      content={popoverContent}
                    />
                  </>
                );
              },
              toolbar: withProps({ columns, filters, onFilter })(CalendarToolbar),
            }}
            view={CalendarView.type}
            onView={(type) => {
              CalendarView.type = type;
            }}
            messages={{
              date: 'datums',
              time: 'laiks',
              event: 'pasākums',
              allDay: 'visa diena',
              week: 'nedēļa',
              day: 'diena',
              month: 'mēnesis',
              previous: 'iepriekšējā',
              next: 'nākamā',
              yesterday: 'vakar',
              tomorrow: 'rīt',
              today: 'šodien',
              agenda: 'darba kārtība',
              showMore: (total) => `skatīt vairāk (+${total})`,
            }}
            formats={this.formats}
            events={events}
            startAccessor="start"
            endAccessor="end"
          />
        )) || (
          <div className="loader-container">
            <Spin />
          </div>
        )}
      </div>
    );
  }
}

export default CalendarComponent;
