import React from 'react';
import { Row, Col, Form, Card, Alert } from 'antd';
import { inject, observer } from 'mobx-react';
import { gridCol, gridCol2, gridColNarrow, gridRow, FormItem, EditFormBase } from '@ois-lib';
import EditAgreement from './finance/Forms/EditAgreement';

@inject('appStore', 'contractStore')
@observer
class EditContract extends EditFormBase {
  title = 'Līgums';
  storeName = 'contractStore';
  readonly = true;
  baseUrl = '/contracts';

  async componentDidMount() {
    this.load(this.props.contractStore);
  }
  canSave() {
    return false;
  }

  canDelete() {
    return false;
  }
  handleSubmit() {
    return false;
  }
  renderForm() {
    const { contractStore } = this.props;
    const { contractNr, _indicators: showFlashMessage, amountUsed, amount, currency: _currency } = contractStore.item;
    const { name: currency = 'EUR' } = _currency || {};

    gridColNarrow.xl = 12;
    gridColNarrow.lg = 16;

    return (
      <>
        <Row {...gridRow}>
          <Col {...gridCol}>
            <h1 className="cabinet_title">
              <div>
                {`${this.title}: ${contractNr || ''}`}
                <FormItem
                  name="_indicators"
                  formItemProps={{ className: 'inline-indicator', style: { height: 30, margin: '-3px 10px 0' } }}
                />
              </div>
            </h1>
          </Col>
        </Row>
        {showFlashMessage && (
          <Row {...gridRow}>
            <Col {...gridColNarrow}>
              <Alert
                message="Brīdinājums!"
                description={
                  <span>
                    Kalkulāciju summa{' '}
                    <strong>
                      {amountUsed} {currency}
                    </strong>{' '}
                    pārsniedz līgumā norādīto summu{' '}
                    <strong>
                      {amount} {currency}
                    </strong>
                    .
                  </span>
                }
                type="warning"
                showIcon
              />
              <br />
            </Col>
          </Row>
        )}
        <Row {...gridRow}>
          <Col {...gridCol2}>
            <Card title="Pamatinformācija par līgumu" bordered={false}>
              <FormItem asText name="contractCodeId" />
              <FormItem asText name="contractNr" />
              <FormItem asText name="date" />
              <FormItem asText name="registrationDate" />
              <FormItem asText name="period" />
              <FormItem asText name="contractStatus" />
              <FormItem asText name="contractType" />
              <FormItem asText name="amount" />
              <FormItem asText name="vatRate" />
              <FormItem asText name="vat_amount" />
              <FormItem asText name="amount_with_vat" />
              <FormItem asText name="noteToAgent" title="ROP ziņojums" />
              <FormItem asText name="files" />
              <FormItem name="agreements" disabled formKind={EditAgreement} formItemProps={{ className: 'mt15' }} />
            </Card>
          </Col>
        </Row>
        {/* <Row {...gridRow}>
          <Col {...gridCol2}>
            {files && (
              <Card title="Pievienotie faili" bordered={false}>
                <FormItem disabled name="files" />
              </Card>
            )}
          </Col>
        </Row> */}
      </>
    );
  }
}

export default Form.create({ name: 'EditContract' })(EditContract);
