import React from 'react';
import { Form, Modal, message, Layout, Spin, Button, notification, Tooltip, Alert, Row, Col } from 'antd';
import moment from 'moment-timezone';
import { formItemLayout, gridCol2, gridRow } from '.';
import Prompt from './components/UnloadPrompt';
import FontAwesomeIcon from './assets/icon/FontAwesomeIcon';
import StoreFactory from './stores';

const EditFormContext = React.createContext({});

class EditFormBase extends React.Component {
  _title = '';
  _storeName = 'store';
  submitting = false;
  submitButtonName = 'Saglabāt';
  successMessage = 'Ieraksts veiksmīgi saglabāts!';
  fieldDiff = null;
  lastSetFieldProps = {};
  // _frozenStack = [];
  // _frozenStackAwaits = 0;

  actions = {};
  resolversInAction = {};
  defaultActions = [];
  registeredActionMethods = {};
  actionButtonsOrder = ['back', 'showCurrentDocument', 'delete', 'save'];

  constructor(props, context) {
    super(props, context);
    // this.getStoreName();
    this.store = (props && props[this.storeName]) || null;
    this.id = this.generatedId;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { props } = this;
    if (props.modal) {
      if (!this.storeName) {
        throw Error('Please provide store name "storeName" property in your Form');
      }
      if (!this.store && this.storeName) {
        this.store = StoreFactory.getStore(this.storeName);
      }
      if (this.storeName && this.storeName !== 'store' && this.store) {
        this.load(this.store);
      }
    }
  }

  async componentDidMount() {
    const { store } = this;
    if (store && store.item) {
      this.setFields({ ...this.addDefaultValues(), ...store.item }, store);
    }

  }
  addDefaultValues() {
    return {};
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const { form, match, history, ...props } = this.props;
    if (!this.store) {
      this.store = props[this.storeName];
    }

    const canSave = !this.forceDisableEdit && !(this.canSave && !this.canSave());
    const canSavePartial = this.canSavePartial && this.canSavePartial();
    const canDelete =
      !this.forceDisableEdit && !(this.canDelete && !this.canDelete()) && this.store.item && this.store.item.id;

    let showOriginalButton = false;
    if (!canSave) {
      if (this.store && this.store.item && this.store.item.__isOldVersion) {
        showOriginalButton = true;
      }
    }

    this.defaultActions = this.store
      ? [
        !this.noBack && {
          title: 'Atgriešanās uz saraksta sadaļu',
          key: 'back',
          button: {
            title: props.modal ? 'Aizvērt' : 'Atpakaļ',
            icon: props.modal ? 'times-circle' : 'angle-left',
            props: { type: 'secondary' },
          },
          resolver: () => (props.modal ? this.handleCloseModal() : history.push(this.baseUrl)),
        },
        canDelete && {
          title: 'Ieraksta dzēšana',
          key: 'delete',
          button: {
            title: 'Dzēst',
            icon: 'trash-alt',
            props: {
              type: 'danger',
              disabled: () => this.submitting || this.store.savingOne,
            },
          },
          resolver: (e) => this.handleDelete(e),
        },
        (canSave || canSavePartial) && {
          title: 'Ieraksta saglabāšana',
          key: 'save',
          button: {
            title: this.submitButtonName,
            icon: 'file-check',
            props: {
              type: 'primary',
              htmlType: 'submit',
              loading: () => this.submitting || this.store.savingOne,
              disabled: () =>
                !form.isFieldsTouched() ||
                this.store.savingOne ||
                this.hasErrors ||
                (this.validateFields && this.validateFields(form)),
            },
          },
          resolver: (e) => this.handleSubmit(e),
        },
        showOriginalButton && {
          title: 'Aktuālais dokuments',
          key: 'showCurrentDocument',
          button: {
            title: 'Rādīt aktuālo dokumentu',
            icon: 'eye',
            props: {
              type: 'primary',
            },
          },
          resolver: () => this.setVersion(),
        },
      ]
      : [];
  }

  componentWillUnmount() {
    this.submitting = false;
  }

  onActionComplete(stayOnPage = false) {
    const { history, modal, closeModal, afterSubmitted } = this.props;
    this.submitting = false;
    if (modal && closeModal) {
      if (afterSubmitted) {
        // try {
        //   afterSubmitted();
        // } catch (e) {
        //   // graceful fail
        // }
        afterSubmitted(this);
      }
      closeModal();
    } else if (this.baseUrl && history) {
      setTimeout(() => {
        history.push(this.baseUrl);
        if (stayOnPage) {
          history.push(this.props.match.url);
        }
      }, 400);
    }
  }

  set storeName(value) {
    this._storeName = value;
  }

  get storeName() {
    return this.constructor.storeName || this._storeName;
  }

  get hasErrors() {
    const { form, ...props } = this.props;
    if (!this.store) {
      this.store = props[this.storeName];
    }

    const errors = form.getFieldsError();
    let numErrors = 0;
    for (const fk in errors) {
      if (typeof errors[fk] !== 'undefined' && errors[fk].validateStatus !== "warning") {
        if (this.warningFields && this.warningFields.includes(fk)) continue;
        numErrors++;
      }
    }
    return !!numErrors;
  }

  get generatedId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  get untouchedFields() {
    const { form } = this.props;
    const dirty = this.dirtyFields;
    const clean = form.getFieldsValue();
    for (let key in clean) {
      if (key in dirty) {
        delete clean[key];
      }
    }
    return clean;
  }

  get dirtyFields() {
    const { form } = this.props;
    const transform = (obj, arr = [], parent = '') => {
      if (!obj) return [];
      if (parent) parent = `${parent}.`;
      Object.keys(obj).forEach((key) => {
        const fullKey = `${parent}${key}`;
        if (obj[key] && typeof obj[key] === 'object') {
          transform(obj[key], arr, fullKey);
        } else {
          arr.push(fullKey);
        }
      });
      return arr;
    };
    const arr = transform(form.getFieldsValue()).filter((x) => form.isFieldTouched(x));
    return form.getFieldsValue(arr);
  }

  get isDirty() {
    const { store: _store, form, [this.storeName]: namedStore } = this.props;
    const store = this.store || namedStore || _store;
    return form.isFieldsTouched() && !this.submitting && !store.savingOne;
  }

  get title() {
    return this.setTitle && this.setTitle(this._title);
  }

  set title(title) {
    this._title = title;
  }

  setTitle(title) {
    if (title) {
      setTimeout(() => window.setTitle(title), 1);
    }
    return title;
  }

  setFields(_item, store, all = false) {
    const item = {};
    const { form } = this.props;
    if (all) {
      for (const col in _item) {
        item[col] = _item[col];
      }
    }
    for (const col in store.colByName) {
      if (!col.includes('.')) {
        item[col] = store.colByName[col].loadFormTransform(_item);
        const fieldProps = form.getFieldProps(col);
        this.lastSetFieldProps[col] = fieldProps;
      }
    }
    form.setFieldsValue(item);
  }

  setFieldsChangeDiff(diff = null) {
    this.fieldDiff = diff;
  }

  handleDelete = () => {
    const { store: _store, ...props } = this.props;
    const store = this.store || props[this.storeName] || _store;
    return Modal.confirm({
      title: 'Vai tiešām vēlaties dzēst šo ierakstu?',
      content: 'Apstiprinot šo paziņojumu, ieraksts tiks dzēsts.',
      onOk: async () => {
        try {
          const res = await store.deleteOne();
          if (res) {
            message.success('Ieraksts veiksmīgi dzēsts');
            if (typeof this.onActionComplete === 'function') {
              this.onActionComplete();
            }
          }
        } catch (err) {
          console.log('err: ', err);
          message.error('Kļūda dzēšot ierakstu');
        }
      },
      okType: 'danger',
      okText: 'Dzēst ierakstu',
      cancelText: 'Atcelt',
    });
  };

  beforeSubmit = async () => {
    return true;
  };

  handleSubmit = async (e) => {
    if (window.cancelSubmittion) {
      e.preventDefault();
      return false;
    }

    this.submitting = true;
    const { store: _store, match, history, fixedFormValues, ...props } = this.props;
    let out = null;
    try {
      out = await this.handleLeanSubmit(e);
    } catch (err) {
      this.submitting = false;
      return null;
    }
    try {
      const store = this.store || props[this.storeName] || _store;

      if (!(await this.beforeSubmit({ ...out, ...this._extra_data, ...fixedFormValues }))) {
        this.submitting = false;
        this.forceUpdate();
        return false;
      }

      const res = await store.saveOne({ ...out, ...this._extra_data, ...fixedFormValues }, store.item && store.item.id);
      if (res && res.data) {
        if (match && match.params && match.params.id === 'new' && match.url && history) {
          if (store.redirectTo) {
            history.push(store.redirectTo(res.data));
          } else {
            history.push(match.url.replace('/new', `/${store.item.id}`));
          }
        }
        message.success(res.message ? res.message : this.successMessage);
        if (typeof this.onActionComplete === 'function') {
          this.onActionComplete(store.stayOnPageAfterSave, store);
        }
      } else if (res.message) {
        this.submitting = false;
        throw Error(res.message);
      } else {
        setTimeout(() => {
          this.submitting = false;
          this.forceUpdate();
        }, 1000);
      }
    } catch (err) {
      this.submitting = false;
      notification.error({
        message: 'Kļūda saglabājot ierakstu',
        description: err.message || 'Tīkla kļūda',
      });
    }
    this.submitting = false;
    return out;
  };

  handleQuietSubmit = async (e, baseStore) => {
    if (window.cancelSubmittion) {
      e.preventDefault();
      return false;
    }
    const { store: _store, form, ...props } = this.props;
    let out = null;
    try {
      out = baseStore.item;
      out = await new Promise((resolve, reject) => {
        form.validateFieldsAndScroll(async (err, values) => {
          if (!err) {
            const store = this.store || props[this.storeName] || _store;
            let _out = {};
            for (const col in store.colByName) {
              _out = store.colByName[col].saveFormTransform(values, out);
            }
            resolve(_out);
          }
          reject(err);
        });
      });
    } catch (err) {
      return null;
    }

    try {
      const res = await baseStore.saveOne(out, baseStore.item && baseStore.item.id);
      if (res && res.data) {
        message.success('Ieraksts veiksmīgi saglabāts');
        return res.data;
      }
      if (res.message) {
        throw Error(res.message);
      }
    } catch (err) {
      notification.error({
        message: 'Kļūda saglabājot ierakstu',
        description: e.message || 'Tīkla kļūda',
      });
    }
    return out;
  };

  handleLeanSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { store: _store, form, ...props } = this.props;
    try {
      return new Promise((resolve, reject) => {
        form.validateFieldsAndScroll(async (err, values) => {
          if (this.warningFields){
            this.warningFields.forEach((f) => {
              if (err != null && err[f] !== undefined) delete err[f];
            })
          }
          if (!err || Object.keys(err).length === 0) {
            const store = this.store || props[this.storeName] || _store;
            let out = {};
            for (const col in store.colByName) {
              out = store.colByName[col].saveFormTransform({ ...values, ...store.forceOverrideValues }, out);
            }
            resolve(this.transformValuesBeforeSubmit ? this.transformValuesBeforeSubmit(out) : out);
          } else {
            this.submitting = false;
            reject(err);
          }
        });
      });
    } catch (err) {
      this.submitting = false;
      return null;
    }
  };

  handleCloseModal = () => {
    const { closeModal } = this.props;

    if (this.isDirty) {
      // eslint-disable-next-line no-alert
      const r = window.confirm('Jums ir nesaglabātas izmaiņas! Vai tiešām vēlaties turpināt?');
      if (r == true) {
        closeModal();
        return true;
      }
      return false;
    }
    closeModal();
    return true;
  };

  proccessFieldChangeValue(value) {
    // Possibly need different more simple implementation like checking if it's a SyntheticEvent or smth.
    if (typeof value === 'object' && value.target) {
      if ('checked' in value.target) {
        value = value.target.checked;
      } else {
        value = fieldValue.target;
      }
    }

    // TODO: proccess dependencies | validation errors

    return value;
  }

  // handleFieldChange = (fieldName, fieldValue) => {
  //   const value = this.proccessFieldChangeValue(fieldValue);
  //   return value;
  // };

  async load(store, setFields = true, props = this.props, callback = null) {
    const { form, match } = props;
    if (match.params && match.params.id) {
      const item = await store.loadOne(match.params.id);
      if (match.params.id !== 'new') {
        form.resetFields();
        if (setFields && item) {
          if (item.changesDiff) {
            this.setFieldsChangeDiff(item.changesDiff);
          } else if (this.fieldDiff) {
            this.setFieldsChangeDiff(null);
          }
          this.setFields(item, store);
          if (this.warningFields) form.validateFields();
        }
      }
      if (callback) callback(item);
      return item;
    }
    return null;
  }

  async loadFresh(store, setFields = true) {
    const { form } = this.props;
    if (store.item && store.item.id) {
      form.resetFields();
      const item = await store.reloadOne();
      if (setFields && item) {
        this.setFields(item, store);
      }
      return item;
    }
    return null;
  }

  loadLocal(item) {
    const { store: _store, ...props } = this.props;
    const store = this.store || props[this.storeName] || _store;
    this.resetFields();
    this.setFields(item, store);
    // setTimeout(() => this.setFields(item, store), 100);
  }

  actionButtons(_store, defaultActions) {
    return defaultActions;
  }

  renderActionButtons(store) {
    const { store: _store, form, ...props } = this.props;
    if (!store) {
      store = this.store || props[this.storeName] || _store;
    }
    this.actions = this.actionButtons(store, this.defaultActions);
    const actions =
      this.actions &&
      this.actions
        .sort((a, b) => {
          const aIndex = this.actionButtonsOrder.indexOf(a.key);
          const bIndex = this.actionButtonsOrder.indexOf(b.key);
          if (aIndex < bIndex) {
            return -1;
          }
          if (aIndex > bIndex) {
            return 1;
          }
          return 0;
        })
        .filter((a) => a);
    this.resolversInAction = [];
    return actions.map(({ key, button, resolver }) => {
      const { title, icon, props: buttonProps, onClick } = button;
      if (onClick) {
        console.warn(`Action's "${key}" are overridden. Please, use resolver.`);
      }
      this.registeredActionMethods[key] = () =>
        resolver(null, {
          form,
          actions: this.registeredActionMethods,
        });

      const ButtonWrapper = ({ staticContext, ..._props }) =>
        button.tooltip ? (
          <Tooltip title={button.tooltip}>
            <Button {..._props} />
          </Tooltip>
        ) : (
          <Button {..._props} />
        );

      const { disabled, loading, ...otherBtnProps } = buttonProps;
      const isLoading = loading ? loading() : false;

      return (
        <ButtonWrapper
          type="secondary"
          key={key}
          loading={isLoading || undefined}
          disabled={(disabled && typeof disabled === 'function' && disabled()) || this.resolversInAction[key]}
          {...otherBtnProps}
          onClick={async (event) => {
            if (this.resolversInAction[key]) {
              event.preventDefault();
              return false;
            }
            this.resolversInAction[key] = true;
            const result = await Promise.all([
              resolver(event, {
                form,
                actions: this.registeredActionMethods,
              }),
            ]);
            if (resolver.length) {
              delete this.resolversInAction[key];
            }
            return result || null;
          }}
        >
          {icon && !isLoading && <FontAwesomeIcon icon={icon} />}
          {title}
        </ButtonWrapper>
      );
    });
  }

  renderDefault() {
    const { store: _store, form, ...props } = this.props;
    const store = this.store || props[this.storeName] || _store;

    const { getFieldProps, getFieldValue } = form;
    const itemProps = {
      editForm: this,
      formId: this.id,
      formInstance: form,
      fstore: store,
      getFieldProps,
      getFieldValue,
      fieldDiff: this.fieldDiff || {},
      record_store: store,
      traceFieldChange: this.handleFieldChange,
      canEdit: this.forceDisableEdit ? false : !this.canSave || (this.canSave && this.canSave()),
    };

    const showLoader = this.submitting || (store && store.loadingOne) || this.showLoader;

    let oldVersion;
    if (store && store.item && store.item.__isOldVersion) {
      oldVersion = store.item.__isOldVersion;
    }

    if (oldVersion) {
      if (!('initialForceDisableEdit' in this)) {
        this.initialForceDisableEdit = this.forceDisableEdit;
      }
      this.forceDisableEdit = true;
      itemProps.canEdit = false;
      itemProps.disabled = true;
    } else if ('initialForceDisableEdit' in this) {
      this.forceDisableEdit = this.initialForceDisableEdit;
    }

    return (
      <div>
        {this.storeName !== 'store' && <Prompt when={this.isDirty} />}
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Layout>
            <Layout.Content>
              <Spin
                spinning={!!showLoader}
                tip={this.submitting ? 'Notiek datu saglabāšana...' : 'Notiek datu ielāde...'}
              >
                {oldVersion && (
                  <Row {...gridRow} style={{ marginTop: 20 }}>
                    <Col {...gridCol2}>
                      <Alert
                        message={
                          <>
                            Vēsturiska versija <strong>{oldVersion.id}</strong>, izveidota{' '}
                            <strong>
                              {oldVersion.created_at
                                ? moment(oldVersion.created_at).format('DD.MM.YYYY. HH:mm')
                                : ' - '}
                            </strong>
                            {oldVersion.notes && (
                              <div>
                                <br />
                                <br />
                                <strong>Piezīmes: </strong>
                                <br />
                                <p>{oldVersion.notes}</p>
                              </div>
                            )}
                          </>
                        }
                        type="warning"
                        showIcon
                      />
                    </Col>
                  </Row>
                )}
                <EditFormContext.Provider value={itemProps}>{this.renderForm()}</EditFormContext.Provider>
              </Spin>
            </Layout.Content>
            {'location' in this.props && (
              <Layout.Footer className="editor-button-footer">
                <div style={{ float: 'right' }}>{this.renderActionButtons(store)}</div>
              </Layout.Footer>
            )}
          </Layout>
        </Form>
      </div>
    );
  }

  renderModal() {
    const { store: _store, form, ...props } = this.props;
    const store = this.store || props[this.storeName] || _store;

    const { getFieldProps, getFieldValue } = form;
    const itemProps = {
      editForm: this,
      formId: this.id,
      formInstance: form,
      fstore: store,
      getFieldProps,
      getFieldValue,
      fieldDiff: this.fieldDiff || {},
      record_store: store,
      traceFieldChange: this.handleFieldChange,
      canEdit: this.forceDisableEdit ? false : !this.canSave || (this.canSave && this.canSave()),
    };

    const showLoader = this.submitting || (store && store.loadingOne) || this.showLoader;

    // let oldVersion;
    // if (store && store.item && store.item.__isOldVersion) {
    //   oldVersion = store.item.__isOldVersion;
    // }

    // if (oldVersion) {
    //   if (!('initialForceDisableEdit' in this)) {
    //     this.initialForceDisableEdit = this.forceDisableEdit;
    //   }
    //   this.forceDisableEdit = true;
    //   itemProps.canEdit = false;
    //   itemProps.disabled = true;
    // } else if ('initialForceDisableEdit' in this) {
    //   this.forceDisableEdit = this.initialForceDisableEdit;
    // }

    return (
      <Modal
        {...props.modalProps}
        closeable
        onCancel={this.handleCloseModal}
        visible={props.visible}
        footer={<>{this.renderActionButtons(store)}</>}
      >
        {this.storeName !== 'store' && <Prompt when={this.isDirty} />}
        <Form {...formItemLayout} onSubmit={this.handleSubmit} name={this.id}>
          <Spin spinning={!!showLoader} tip={this.submitting ? 'Notiek datu saglabāšana...' : 'Notiek datu ielāde...'}>
            <EditFormContext.Provider value={itemProps}>{this.renderForm()}</EditFormContext.Provider>
          </Spin>
        </Form>
      </Modal>
    );
  }

  render() {
    const { modal } = this.props;
    return modal ? this.renderModal() : this.renderDefault();
  }
}

export default EditFormBase;
export { EditFormContext };
