import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, FormItem, gridCol2, gridRow } from '@ois-lib';
import Validate from '@ois-lib/validate';

@inject('resourceRequestStore', 'appStore', 'authStore', 'authorize', 'can')
@observer
class EditResourceRequest extends EditFormBase {
  title = 'Resursu pieteikuma kartiņa';
  storeName = 'resourceRequestStore';
  baseUrl = '/planning/resource_requests';

  constructor(props) {
    super(props);

    this.agent = props.authStore.user.company;

    this.state = {
      isIcebreaker: false,
      shipFlag: false,
      shipVisit: null,
      shipOwner: null,
      shipAgent: null,
    };
  }
  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const store = this.props[this.storeName];
    store.colByName.companyId.optionSource = undefined;
    await this.load(store).then(() => {
      this.setState({
        isIcebreaker: store && store.item && store.item.resourceType === 'Ledlauzis',
        shipVisit: store.item ? store.item.ship_visit : null,
      });
      this.checkShipFlag();
    });
    if (!store.item.companyId) {
      store.item.companyId = this.agent.id;
      store.item.company = this.agent;
      store.item._t_companyId = this.agent.name;
    }
    if (!store.item.status) {
      store.item.status = 'NEW';
    }
  }

  componentDidMount() {
    this.checkShipFlag();
  }

  checkForIcebreaker = () => {
    const { form } = this.props;
    this.store.loadRecord(form.getFieldValue('resourceId'), 'resources').then(() => {
      if (this.store._loadedRecord) {
        this.store.loadRecord(this.store._loadedRecord.resourceTypeId, 'resourceTypes').then(() => {
          if (this.store._loadedRecord) {
            this.setState({
              isIcebreaker: this.store._loadedRecord.name === 'Ledlauzis',
            });
            this.resetFieldIfEmpty('etaIrbeMeridian');
            this.resetFieldIfEmpty('shipCondition');
            this.resetFieldIfEmpty('fuelStock');
          }
        });
      }
    });
  };

  resetFieldIfEmpty = (field) => {
    if (!this.props.form.getFieldValue(field)) {
      this.props.form.resetFields(field);
    }
  };

  checkShipFlag = () => {
    const { shipVisit } = this.state;
    if (shipVisit) {
      const { shipIn } = shipVisit;

      if (shipIn.companyId) {
        this.store.loadRecord(shipIn.companyId, 'company').then(() => {
          if (this.store._loadedRecord) {
            this.setState({
              shipOwner: this.store._loadedRecord.name,
            });
          }
        });
      } else {
        this.setState({
          shipOwner: false,
        });
      }

      if (shipIn.countryId) {
        this.store.loadRecord(shipIn.countryId, 'countries').then(() => {
          if (this.store._loadedRecord) {
            this.setState({
              shipFlag: this.store._loadedRecord.name,
            });
          }
        });
      } else {
        this.setState({
          shipFlag: false,
        });
      }

      if (shipVisit.shipAgentId) {
        this.store.loadRecord(shipVisit.shipAgentId, 'company', '_loadedAgent').then(() => {
          if (this.store._loadedAgent) {
            this.setState({
              shipAgent: this.store._loadedAgent,
            });
          }
        });
      } else {
        this.setState({
          shipAgent: false,
        });
      }
    }
  };

  reloadShipVisit = () => {
    const shipVisitId = this.props.form.getFieldValue('shipVisitId');
    if (shipVisitId) {
      this.store.loadRecord(shipVisitId, 'shipVisits', '_loadedShipVisit').then(() => {
        if (this.store._loadedShipVisit) {
          this.setState({
            shipVisit: this.store._loadedShipVisit,
          });
        }
        this.checkShipFlag();
      });
    }
  };

  renderForm() {
    const { isIcebreaker, shipVisit, shipFlag, shipOwner, shipAgent } = this.state;
    // const shipVisit = this.props.resourceRequestStore ? this.props.resourceRequestStore.item.ship_visit : null;
    let infoBlock = '';
    if (shipVisit) {
      const shipIn = shipVisit ? shipVisit.shipIn : null;
      let propellerType = shipIn ? shipIn.propellerType : null;
      switch (propellerType) {
        case 'FIXED':
          propellerType = 'Ar fiksētu soli';
          break;
        case 'VARIABLE':
          propellerType = 'Ar maināmu soli';
          break;
        default:
      }
      infoBlock = shipIn ? (
        <Card title="Informācija">
          <p>Kuģa nosaukums: {shipIn.name ? <strong>{shipIn.name}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa karogs: {shipFlag ? <strong>{shipFlag}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa BT: {shipIn.grossWeight ? <strong>{shipIn.grossWeight}</strong> : '-nav norādīts-'}</p>
          <p>Izsaukuma signāls: {shipIn.mmsi ? <strong>{shipIn.mmsi}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa klasif.sab./ledus klase: {shipIn.iceClass ? <strong>{shipIn.iceClass}</strong> : '-nav norādīts-'}</p>
          <p>Dzinēja jauda: {shipIn.enginePower ? <strong>{shipIn.enginePower}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa īpašnieks: {this.store && (shipOwner ? <strong>{shipOwner}</strong> : '-nav norādīts-')}</p>
          <p>Aģents: {shipAgent && shipAgent.name ? <strong>{shipAgent.name}</strong> : '-nav norādīts-'}</p>
          <p>Aģenta e-pasts: {shipAgent && shipAgent.email ? <strong>{shipAgent.email}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa garums(m): {shipIn.length ? <strong>{shipIn.length}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa platums(m): {shipIn.width ? <strong>{shipIn.width}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa dzenskrūves tips: {propellerType ? <strong>{propellerType}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa iegrime: {shipIn.draft ? <strong>{shipIn.draft}</strong> : '-nav norādīts-'}</p>
          <p>Kuģa iegrime priekšgalā: {shipIn.draftAtBow ? <strong>{shipIn.draftAtBow}</strong> : '-nav norādīts-'}</p>
          <p>
            Kuģa iegrime pakaļgalā: {shipIn.draftAtStern ? <strong>{shipIn.draftAtStern}</strong> : '-nav norādīts-'}
          </p>
          <p>
            Kravas apraksts ienākot:
            {shipVisit.cargoDescriptionIn ? <strong>{shipVisit.cargoDescriptionIn}</strong> : '-nav norādīts-'}
          </p>
          <p>
            Kravas apraksts izejot:
            {shipVisit.cargoDescriptionOut ? <strong>{shipVisit.cargoDescriptionOut}</strong> : '-nav norādīts-'}
          </p>
        </Card>
      ) : (
        ''
      );
    }
    return (
      <>
        <br />
        <Row {...gridRow}>
          <Card title="Parametri" bordered className="mt15">
            <Col {...gridCol2}>
              <FormItem name="companyId" asText />
              <FormItem name="resourceId" onSelect={() => this.checkForIcebreaker()} />
              <FormItem name="shipVisitId" onSelect={() => this.reloadShipVisit()} />
              <FormItem name="berthId" />
              <FormItem name="desiredTime" />
              <FormItem name="actualTime" />
              <FormItem asText name="status" />
              <FormItem asText name="completionTime" />
              <FormItem asText disabled name="paid" />
              <FormItem name="notes" />
              <FormItem name="etaIrbeMeridian" rules={isIcebreaker ? [Validate.Required] : undefined} />
              <FormItem name="shipCondition" rules={isIcebreaker ? [Validate.Required] : undefined} />
              <FormItem name="fuelStock" rules={isIcebreaker ? [Validate.Required] : undefined} />
            </Col>
            <Col {...gridCol2}>{infoBlock}</Col>
          </Card>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditResourceRequest' })(EditResourceRequest));
