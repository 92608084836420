import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol, gridRow, FormItem } from '@ois-lib';

@inject('appStore', 'authStore', 'authorize', 'can')
@observer
class EditCalculationRowDetail extends EditFormBase {
  title = 'Kalkulācijas vienības detaļas';
  storeName = 'store';

  transformValuesBeforeSubmit = (values) => {
    const { authStore } = this.props;
    if (!this.store.item.id && !this.store.item.created_by && authStore.user) {
      this.store.item.created_by = authStore.user.id;
      values.created_by = authStore.user.id;
    }
    return values;
  };

  get forceDisableEdit() {
    return this.props.parentProps && !!this.props.parentProps.disabled;
  }

  canSave() {
    return false;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    const { /*authorize,*/ form } = this.props;
    // authorize(['view calculations', 'edit calculations']);

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Kalkulācijas vienības detaļas" bordered={false}>
            <FormItem name="description" />
            <FormItem name="amount" disabled={!!form.getFieldValue('discount')} />
            <FormItem name="cost" disabled={!!form.getFieldValue('discount')} />
            <FormItem name="discount" disabled={!!form.getFieldValue('amount') || !!form.getFieldValue('cost')} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditCalculationRowDetail' })(EditCalculationRowDetail);
