import React from 'react';
import { Popover } from 'antd';

const OperationalNotesPopover = ({ trigger, title, placement, content, children }) => {
  return (
    <div aria-hidden onClick={(e) => e.stopPropagation()}>
      <Popover
        className="files-popover-wrapper"
        placement={placement || 'bottomRight'}
        title={title}
        content={
          <div
            aria-hidden
            className="addons files-popover-content"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {content}
          </div>
        }
        trigger={trigger || 'hover'}
      >
        {children}
      </Popover>
    </div>
  );
};

export default OperationalNotesPopover;
