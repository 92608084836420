import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Dropdown, Menu } from 'antd';
import { inject, observer } from 'mobx-react';

import FontAwesomeIcon from '../../assets/icon/FontAwesomeIcon';

@inject('authStore')
@observer
class UserInfo extends React.Component {
  get fullName() {
    const { authStore } = this.props;
    if (authStore.user) {
      const { name, fullName } = authStore.user;
      return fullName || name;
    }
    return ' - ';
  }

  render() {
    const { authStore, userMenu, showCollectorDialog } = this.props;
    const hasUserMenu = userMenu && userMenu.length;
    if (!authStore.loggedIn) {
      return '';
    }
    const userName = (
      <span className="site_header_right_profile pointer">
        <FontAwesomeIcon icon="user-circle" className="icon-check" />
        <span className="site_header_profile_name">
          <Tooltip placement="bottom" title="Lietotāja profils">
            {this.fullName}
          </Tooltip>
        </span>
        {/* {hasUserMenu && <FontAwesomeIcon icon="angle-down" className="icon-check" />} */}
        <FontAwesomeIcon icon="angle-down" className="icon-check" />
      </span>
    );
    return (
      <Dropdown
        overlay={
          <Menu>
            {userMenu &&
              userMenu.map((i) => (
                <Menu.Item key={i.to}>
                  <Link to={i.to}>{i.title}</Link>
                </Menu.Item>
              ))}
            {this.props.oks ? (
              <Menu.Item key="jira2" onClick={()=>location='mailto:it@rop.lv'}>
                <span className="pointer">
                  <a href={`mailto:it@rop.lv`}>Pieteikt problēmu RBP</a>
                </span>
              </Menu.Item>
            ) : (
              <Menu.Item key="jira2" disabled>
                <a href={`mailto:it@rop.lv`}>Pieteikt problēmu RBP</a>
              </Menu.Item>
            )}
            {true ||  this.props.oks ? null : (
              <Menu.Item key="jira">
                <span
                  className="pointer"
                  aria-hidden="true"
                  onClick={() => {
                    if (showCollectorDialog && typeof showCollectorDialog === 'function') {
                      showCollectorDialog();
                    }
                  }}
                >
                  Pieteikt problēmu izstrādātājam
                </span>
              </Menu.Item>
            )}
          </Menu>
        }
      >
        {userName}
      </Dropdown>
    );
  }
}

export default UserInfo;
