import React from 'react';
import { Prompt } from 'react-router';

class UnloadPrompt extends React.Component {
  componentDidUpdate() {
    const { when } = this.props;
    if (when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  render() {
    const { when, message } = this.props;

    return (
      <Prompt
        key="unload-prompt"
        when={when}
        message={message || 'Jums ir nesaglabātas izmaiņas! Vai tiešām vēlaties turpināt?'}
      />
    );
  }
}

export default UnloadPrompt;
