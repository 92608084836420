import React from 'react';
import { observer } from 'mobx-react';
import fieldSpec from '../stores/FieldSpec';
import { Field } from './Field';

const TryParse = (string) => {
    try {
        return JSON.parse(string);
    } catch (e) {
        return false;
    }
};

const JsonDisplayLabelComponent = observer(
    ({ tableName, name, value, skipIfNoTitle = false, ...props }) => {
        const record = TryParse(value);
        if (record === false) {
            return <em>Nav datu</em>;
        }

        const display = [];
        const stack = [];

        let i = 0;
        let indent = 0;
        let obj = { ...record };
        let objIsFromArray = false;
        let keys = Object.keys(obj);
        let x=0;
        for (; ;) {
            if (keys.length <= i) {
                if (stack.length) {
                    const prev = stack.pop();
                    objIsFromArray = prev.isArr;
                    keys = prev.keys.slice();
                    obj = { ...prev.obj };
                    i = prev.i;
                    --indent;
                    continue;
                }

                break;
            } else {
                let label = null;
                if (obj[keys[i]] === null) {
                    label = fieldSpec.getColTitle(tableName, keys[i], skipIfNoTitle, true);

                    if (label !== null || !skipIfNoTitle) {
                        display.push({
                            indent,
                            value: null,
                            title: label,
                        });
                    }

                    ++i;
                    continue;
                }

                switch (typeof obj[keys[i]]) {
                    case `object`:
                        const _t_keys = Object.keys(obj[keys[i]]);
                        if (_t_keys.length === 2 && _t_keys.includes(`new`) && _t_keys.includes(`prev`)) {
                            label = objIsFromArray ?
                                `${keys[i]}` :
                                fieldSpec.getColTitle(tableName, keys[i], skipIfNoTitle, true);

                            if (label !== null || !skipIfNoTitle) {
                                display.push({
                                    indent,
                                    value: {
                                        new: obj[keys[i]].new !== null ?
                                            fieldSpec?.getCol(tableName, keys[i])?.loadRowTransform({ [keys[i]]: obj[keys[i]].new })
                                            : null,
                                        prev: obj[keys[i]].prev !== null ?
                                            fieldSpec?.getCol(tableName, keys[i])?.loadRowTransform({ [keys[i]]: obj[keys[i]].prev })
                                            : null,
                                    },
                                    title: label,
                                });
                            }

                            ++i;
                        } else {
                            label = objIsFromArray ?
                                `${keys[i]}` :
                                fieldSpec.getColTitle(tableName, keys[i], skipIfNoTitle, true);

                            display.push({
                                indent,
                                value: undefined,
                                title: label,
                            });

                            stack.push({
                                i: i + 1,
                                obj: { ...obj },
                                keys: keys.slice(),
                                isArr: objIsFromArray,
                            });

                            obj = { ...obj[keys[i]] };
                            keys = Object.keys(obj);
                            objIsFromArray = false;

                            i = 0;
                            ++indent;
                        }

                        break;

                    case `array`:
                        label = objIsFromArray ?
                            `${keys[i]}` :
                            fieldSpec.getColTitle(tableName, keys[i], skipIfNoTitle, true);

                        display.push({
                            indent,
                            value: undefined,
                            title: label,
                        });

                        stack.push({
                            i: i + 1,
                            obj: { ...obj },
                            keys: keys.slice(),
                            isArr: objIsFromArray,
                        });

                        const _t_obj = {};
                        for (const j in obj[keys[i]]) {
                            switch (typeof sub) {
                                case `object`:
                                    _t_obj[`${obj[keys[i]][j].id ?? j}`] = { ...sub };
                                    break;

                                case `array`:
                                    _t_obj[`${obj[keys[i]][j].id ?? j}`] = sub.slice();
                                    break;

                                default:
                                    _t_obj[`${obj[keys[i]][j].id ?? j}`] = sub;
                                    break;
                            }
                        }

                        obj = { ..._t_obj };
                        keys = Object.keys(obj);
                        objIsFromArray = true;

                        i = 0;
                        ++indent;

                        break;

                    default:
                        label = fieldSpec.getColTitle(tableName, keys[i], skipIfNoTitle, true);
                        if (label !== null || !skipIfNoTitle) {
                            display.push({
                                indent,
                                value: obj[keys[i]] !== null ?
                                    fieldSpec?.getCol(tableName, keys[i])?.loadRowTransform({ [keys[i]]: obj[keys[i]] })
                                    : null,
                                title: label,
                            });
                        }

                        ++i;
                        break;
                }
            }
        }

        // console.log(display.slice());

        return (
            <React.Fragment key={x++}>
                {
                    display.map((inst) => {
                        const diff = inst.value;

                        if (diff === undefined) {
                            return (
                                <span
                                    key={x++}
                                    style={{
                                        display: `block`,
                                        paddingLeft: inst.indent * 20,
                                    }}
                                >
                                    <strong>{inst.title}</strong>
                                </span>
                            );
                        } else if (typeof diff === `object` && diff?.prev !== undefined && diff?.new !== undefined) {
                            if (diff.new === null || diff.new === `null`) {
                                return (
                                    <span
                                        key={x++}
                                        style={{
                                            display: `block`,
                                            color: `#d4380d`,
                                            paddingLeft: inst.indent * 20,
                                            textDecoration: `line-through`,
                                        }}
                                    >
                                        <strong>{inst.title}</strong>: {diff.prev ?? <em>(tukšs lauks)</em>}
                                    </span>
                                );
                            } else if (diff.prev === null || diff.prev === `null`) {
                                return (
                                    <span
                                        key={x++}
                                        style={{
                                            display: `block`,
                                            color: `#389e0d`,
                                            paddingLeft: inst.indent * 20,
                                        }}
                                    >
                                        <strong>{inst.title}</strong>: {diff.new ?? <em>(tukšs lauks)</em>}
                                    </span>
                                );
                            } else {
                                return (
                                    <React.Fragment key={x++}>
                                        <span
                                            key={x++}
                                            style={{
                                                display: `block`,
                                                color: `#d4380d`,
                                                paddingLeft: inst.indent * 20,
                                                textDecoration: `line-through`,
                                            }}
                                        >
                                            <strong>{inst.title}</strong>: {diff.prev ?? <em>(tukšs lauks)</em>}
                                        </span>
                                        <span
                                            key={x++}
                                            style={{
                                                display: `block`,
                                                color: `#389e0d`,
                                                paddingLeft: inst.indent * 20,
                                            }}
                                        >
                                            <strong>{inst.title}</strong>: {diff.new ?? <em>(tukšs lauks)</em>}
                                        </span>
                                    </React.Fragment>
                                );
                            }
                        } else {
                            return (
                                <span
                                    key={x++}
                                    style={{
                                        display: `block`,
                                        paddingLeft: inst.indent * 20,
                                    }}
                                >
                                    <strong>{inst.title}</strong>: {diff ?? <em>(tukšs lauks)</em>}
                                </span>
                            )
                        }
                    })
                }
            </React.Fragment>
        );
    }
);

export { JsonDisplayLabelComponent };

@Field.register
class JsonDisplayComponent extends Field {
    static code = `json`;

    static JsonDisplayLabelComponent = JsonDisplayLabelComponent;

    loadRowTransform(row) {
        const { key } = this;
        return (
            <JsonDisplayLabelComponent
                key={key}
                value={row[key]}
                tableName={row.table_name || row.objectType}
                name={this.key}
                skipIfNoTitle={this.skipIfNoTitle}
            />
        );
    }

    renderComponent(props) {
        if (prop.skipIfNoTitle || this.skipIfNoTitle) {
            props.skipIfNoTitle = prop.skipIfNoTitle || this.skipIfNoTitle;
        }
        return <JsonDisplayLabelComponent {...props} />;
    }
}

export default JsonDisplayComponent;
