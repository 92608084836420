import React from 'react';
import { toJS, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Button, List, Tag, Spin, Icon } from 'antd';
import FontAwesomeIcon from '../../assets/icon/FontAwesomeIcon';
import OperationalNotesPopover from '../../components/OperationalNotesPopover';
import FilesRenderer from '../../components/FilesRenderer';
import ModalEditorComponent from './ModalEditorComponent';
import EditOperationalNote from '../../routes/EditOperationalNote';

export const Spinner = () => <Spin indicator={<Icon type="loading" spin />} size="small" />;

@inject('can')
@observer
class ButtonWrapComponent extends React.Component {
  render() {
    const { options, store, formStore, config, rowId, can } = this.props;

    if (!can(['view operational_notes', 'edit operational_notes'])) {
      return null;
    }

    return store.loading ? (
      <Spinner />
    ) : (
      <div key="aaa">
        <OperationalNotesPopover
          title={`${options.title} (${store.total})`}
          content={
            <div>
              {config.fieldProps && !config.fieldProps.inTable && can('edit operational_notes') && (
                <Button
                  type="primary"
                  onClick={() => {
                    formStore.item = {
                      id: null,
                      resourceType: 'SHIP',
                      resourceId: rowId,
                    };
                    formStore.isOpen.set(true);
                  }}
                >
                  <FontAwesomeIcon icon="plus" className="btn-icon" />
                  <span>Pievienot</span>
                </Button>
              )}
              {!!store.total && (
                <List
                  style={{ marginTop: '15px' }}
                  bordered
                  dataSource={store.data}
                  renderItem={(item) => {
                    return (
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        key={item.id}
                        onClick={() => {
                          if (item.key) {
                            item = toJS(
                              store._data.find((el) => el.key === item.key),
                              { recurseEverything: true }
                            );
                          }
                          if (EditOperationalNote) {
                            runInAction(async () => {
                              const loadOne = await store.loadOne(item.id);
                              formStore.item = loadOne;
                              if (loadOne) {
                                formStore.isOpen.set(true);
                              }
                            });
                          }
                        }}
                      >
                        <div className="spread-flexbox">
                          <Tag color="#2db7f5">
                            {(item && (item.updated_at || item.created_at)) || ' - '}
                            &nbsp;
                          </Tag>
                          {FilesRenderer(item.files)}
                        </div>
                        <span className="notes-text">{item.note}</span>
                      </List.Item>
                    );
                  }}
                />
              )}
            </div>
          }
          key="onpo"
        >
          <FontAwesomeIcon icon="comment-alt-plus" type="solid" style={{ cursor: 'pointer' }} />
        </OperationalNotesPopover>
        <ModalEditorComponent rowId={rowId} store={formStore} baseStore={store} formKind={EditOperationalNote} />
      </div>
    );
  }
}

export default ButtonWrapComponent;
