import React from 'react';
import { Select } from 'antd';
import { Field } from './Field';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';

@Field.register
class BooleanMultiselectComponent extends Field {
  static code = 'boolean_multiselect';
  loadRowTransform(row) {
    const col = this;
    return col.options
      .filter((option) => row[option.key])
      .map((option) => option.value)
      .join(', ');
  }

  loadFormTransform(item, _value = item[this.key]) {
    return super.loadFormTransform(
      item,
      this.options.filter((option) => item[option.key]).map((option) => option.key)
    );
  }
  saveFormTransform(values, out = {}) {
    this.options.forEach((option) => {
      if (typeof values[this.key] === 'undefined') {
        return;
      }
      out[option.key] = !!values[this.key].includes(option.key);
    });
    return super.saveFormTransform(out, out);
  }

  renderComponent(props) {
    return (
      <Select
        mode="multiple"
        {...props}
        removeIcon={<FontAwesomeIcon icon="times" />}
        clearIcon={<FontAwesomeIcon type="regular" icon="times-circle" />}
      >
        {this.options.map((option, i) => (
          <Select.Option key={option.key} value={option.key}>
            {option.value}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default BooleanMultiselectComponent;
