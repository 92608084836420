import React from 'react';
import { Row, Col, Card, Button, Select, DatePicker } from 'antd';
import { observer, inject } from 'mobx-react';
import moment from 'moment-timezone';
import { DataTable, gridCol, gridRow, FontAwesomeIcon } from '@ois-lib';
import storeFactory from '@ois-lib/stores';
import api from '@ois-lib/services/api';

import './styles.less';

@inject(`authorize`, `can`)
@observer
class Section extends React.Component {
  constructor(props) {
    super(props);

    this.shipIn = storeFactory.getStore(`shipIn`);
    this.shipOut = storeFactory.getStore(`shipOut`);

    this.tablesRef = React.createRef();
    this.mutex = [null, null];

    this.state = {
      options: {
        ship: [],
      },
      loading: {
        ship: false,
      },
      values: {
        period: [moment().subtract(1, `month`).startOf(`month`), moment().subtract(1, `month`).endOf(`month`)],
        ship: undefined,
      },
      input: {
        ship: null,
      },
    };
  }

  componentDidMount() {
    const { values } = this.state;

    this.load(`ship`, null);
    this.refresh(values.period, values.ship);
  }

  refresh = (period, ship) => {
    this.shipIn.additionalParams = {
      dateFrom: period[0].format(`YYYY-MM-DD HH:mm:ss`),
      dateTo: period[1].format(`YYYY-MM-DD HH:mm:ss`),
    };

    this.shipOut.additionalParams = {
      dateFrom: period[0].format(`YYYY-MM-DD HH:mm:ss`),
      dateTo: period[1].format(`YYYY-MM-DD HH:mm:ss`),
    };

    const queries = {};

    if (ship && ship.length) {
      queries.ship = ship.substring(5);
    }

    if (Object.keys(queries).length) {
      this.shipIn.additionalParams.queries = JSON.stringify(queries);
      this.shipOut.additionalParams.queries = JSON.stringify(queries);
    }

    this.shipIn.refresh();
    this.shipOut.refresh();
  };

  load = (id, _input) => {
    const { loading, input, options } = this.state;

    const path = {
      ship: `ships`,
    };

    loading[id] = true;
    input[id] = _input;

    this.setState({ input, loading });

    if (this.mutex[id]) {
      this.mutex[id].abort();
    }
    this.mutex[id] = new AbortController();

    const q = _input && _input.length ? `&q=${encodeURIComponent(_input)}` : ``;
    fetch(`${api.base}${path[id]}?page=1&size=10${q}`, {
      headers: {
        Authorization: api.headers.Authorization,
      },
      signal: this.mutex[id].signal,
    })
      .then(async (response) => {
        const { data } = await response.json();

        options[id] = data.slice();
        loading[id] = false;

        this.setState({ options, loading });
      })
      .catch(() => {
        options[id] = [];
        loading[id] = false;

        this.setState({ options, loading });
      });
  };

  change = (id, _value) => {
    const { values } = this.state;

    values[id] = _value;

    this.refresh(values.period, values.ship);
    this.setState({ values });
  };

  render() {
    const { values, options, input, loading } = this.state;

    return (
      <>
        <br />
        <span ref={this.tablesRef} />
        <Card title="Kuģu kustības" size="small">
          <Row {...gridRow} style={{ marginBottom: 10 }}>
            <Col {...gridCol}>
              <Button
                type="default"
                style={{ minWidth: 235 }}
                onClick={() => {
                  this.change(`period`, [
                    moment().subtract(1, `month`).startOf(`month`),
                    moment().subtract(1, `month`).endOf(`month`),
                  ]);
                  this.tablesRef.current.scrollIntoView({ behavior: `smooth` });
                }}
              >
                Pēdējā mēneša atskaite
              </Button>
              <Button
                type="default"
                style={{ marginLeft: 10, minWidth: 235 }}
                onClick={() => {
                  const _period = [moment().startOf(`quarter`), moment().endOf(`quarter`)];

                  if (_period[1] > moment()) {
                    _period[0] = moment().startOf(`quarter`).subtract(1, `quarter`);
                    _period[1] = moment().endOf(`quarter`).subtract(1, `quarter`);
                  }

                  this.change(`period`, _period);
                  this.tablesRef.current.scrollIntoView({ behavior: `smooth` });
                }}
              >
                Pēdējā ceturkšņa atskaite
              </Button>
            </Col>
          </Row>
          <Row {...gridRow} style={{ marginBottom: 10 }}>
            <Col style={{ display: `inline-block`, minWidth: 80 }}>Kuģis:</Col>
            <Col style={{ display: `inline-block` }}>
              <Select
                showSearch
                allowClear
                loading={loading.ship}
                removeIcon={<FontAwesomeIcon icon="times-circle" />}
                clearIcon={<FontAwesomeIcon type="regular" icon="times-circle" />}
                notFoundContent={
                  !values.ship && (!input.ship || (input.ship && input.ship)) ? (
                    <div className="empty-select-search">
                      <FontAwesomeIcon icon="print-search" />
                      <p>Ievadiet meklējamo frāzi...</p>
                    </div>
                  ) : undefined
                }
                optionFilterProp="children"
                onChange={(value) => this.change(`ship`, value)}
                onSearch={(_input) => this.load(`ship`, _input)}
                value={values.ship}
                style={{ minWidth: 399 }}
                placeholder="Izvēlaties kuģi.."
              >
                {options.ship.map((opt) => (
                  <Select.Option value={`ship_${opt.id}`} key={`ship_${opt.id}`}>
                    {opt.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row {...gridRow} style={{ marginBottom: 20 }}>
            <Col style={{ display: `inline-block`, minWidth: 80 }}>Periods:</Col>
            <Col style={{ display: `inline-block` }}>
              <DatePicker.RangePicker
                showTime
                allowClear={false}
                format="DD.MM.YYYY HH:mm"
                value={values.period}
                separator="-"
                onChange={(value) => this.change(`period`, value)}
              />
            </Col>
          </Row>
          <Row {...gridRow}>
            <Col {...gridCol}>{this.shipIn && <DataTable printable title="Ienākošie kuģi" store={this.shipIn} />}</Col>
            <Col {...gridCol}>{this.shipOut && <DataTable printable title="Izejošie kuģi" store={this.shipOut} />}</Col>
          </Row>
        </Card>
      </>
    );
  }
}

export default Section;
