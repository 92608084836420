/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol, gridCol2 } from '@ois-lib';
import Validate from '@ois-lib/validate';
import { observable } from 'mobx';
// import EditResourceUse from './EditResourceUse';

@inject('appStore', 'authorize', 'can')
@observer
class EditMovement extends EditFormBase {
  title = 'Kustība';
  static storeName = 'movements';

  @observable expanded = parseInt(localStorage.getItem('movement.expanded')) || false;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    const { store } = this;
    // eslint-disable-next-line eqeqeq
    if (props && props.match && this.props && this.props.match && props.match.params.id != this.props.match.params.id) {
      this.load(store, true, props);
    } else if (props && props.match && props.match.params.id === 'new') {
      if (
        props.fixedFormValues &&
        props.fixedFormValues.shipVisitId &&
        (!this.props.fixedFormValues || props.fixedFormValues.shipVisitId !== this.props.fixedFormValues.shipVisitId)
      ) {
        this.autofill();
      }
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.autofill();
  }

  async autofill() {
    const { props, store } = this;
    if (
      props &&
      props.match &&
      props.match.params.id === 'new' &&
      props.fixedFormValues &&
      props.fixedFormValues.shipVisitId
    ) {
      const data = await store.callGet('autofill', null, props.fixedFormValues.shipVisitId);
      const values = data.data || undefined;
      if (values && store) {
        store.item = values;
      }
      this.setFields({ ...values }, store, true);
    }
  }

  handleFieldChange = (fieldName, fieldValue) => {
    if (fieldName === 'withoutPilot') {
      const value = this.proccessFieldChangeValue(fieldValue);
      if (value) {
        const { form } = this.props;
        setTimeout(() => {
          form.setFieldsValue({
            pilot1Id: null,
            pilot2Id: null,
          });
        }, 100);
      }
      return value;
    } else if (fieldName === 'toBerthId') {
      const value = this.proccessFieldChangeValue(fieldValue);
      if (value && value === -1) {
        const { form } = this.props;
        setTimeout(() => {
          form.setFieldsValue({
            movementType: null,
          });
        }, 100);
      }
    } else if (fieldName === 'agentCompanyId') {
      this.props.form.setFieldsValue({
        agentCompanyRepresentativeId: null,
        representativePhone: null,
      });
    } else if (fieldName === 'agentCompanyRepresentativeId') {
      const colStore = this.store.colByName[fieldName].optionStore;
      let itemValue = null;
      if (colStore._dataFull.length) {
        itemValue = colStore._dataFull.find((i) => i.id === fieldValue);
        if (itemValue) {
          itemValue = itemValue.phone;
        }
      }
      this.props.form.setFieldsValue({
        representativePhone: itemValue,
      });
    }
  };

  toggleCollapse() {
    const expanded = !this.expanded;
    localStorage.setItem('movement.expanded', expanded ? 1 : 0);
    this.expanded = expanded;
  }

  canSave() {
    return false;
    // return this.props.can('edit ship_visits.movement');
  }

  canDelete() {
    return false;
    // return this.props.can('delete ship_visits.movement');
  }

  renderForm() {
    const {  /* authorize, */ form, match, additionalFormProps } = this.props;
    const { store } = this;
    //do i need this?
    // authorize(['view ship_visits.movement', 'edit ship_visits.movement']);
    const withoutPilot = form.getFieldValue('withoutPilot', false);
    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title={<>Kustība - <strong>{additionalFormProps.ship?.name ?? additionalFormProps.ship?.imo}</strong></>} bordered={false} size="small">
            <Col {...gridCol2}>
              <a
                onClick={() => {
                  this.toggleCollapse();
                }}
              >
                {this.expanded ? 'Rādīt kompaktu ievadformu' : 'Rādīt pilnu ievadformu'}
              </a>
              <FormItem name="movementNr" asText />
              <FormItem name="movementStart" />
              <FormItem name="movementEnd" />
              <FormItem name="fromBerthId" asText />
              <FormItem name="toBerthId" />
              <FormItem name="callPurposeId" />
              {this.expanded && <FormItem name="agentCompanyId" />}
              {this.expanded && (
                <FormItem
                  name="agentCompanyRepresentativeId"
                  optionFilters={
                    form.getFieldValue('agentCompanyId', false)
                      ? { companyId: form.getFieldValue('agentCompanyId', false) }
                      : null
                  }
                  disabled={!form.getFieldValue('agentCompanyId', false)}
                  allowClear
                />
              )}
              {this.expanded && <FormItem name="representativePhone" allowClear />}
              {this.expanded && <FormItem name="board" />}
              {this.expanded && <FormItem name="housing" />}
              <FormItem name="paramWaterMaxDepth" />
              {/* <FormItem name="paramWaterActualDepth" /> */}
              <FormItem name="paramDraftAtBow" />
              <FormItem name="paramDraftAtStern" />
              {/* <FormItem name="paramAirDepth" /> */}
              {/* <FormItem name="radioChannel" /> */}
              <FormItem name="movementType" />
              {this.expanded && <FormItem name="services" />}
            </Col>
            <Col {...gridCol2}>
              <Card title="Locis" bordered={false}>
                <FormItem name="withoutPilot" />
                {!withoutPilot && store.item.pilotBillNr && (
                  <FormItem name="pilotBillNr" hidden={match.params.id === 'new'} asText />
                )}
                <FormItem
                  name="pilot1Id"
                  rules={!withoutPilot ? [Validate.Required] : []}
                  // dependancyRules={[{ field: 'withoutPilot' }]}
                  asText={withoutPilot || undefined}
                />

                {<FormItem name="pilot2Id" asText={withoutPilot || undefined} />}
                {/* <FormItem name="startTime" /> */}
                {/* <FormItem name="endTime" /> */}
                {/* <FormItem name="pilotCancelled" /> */}
                {/* <FormItem name="pilotDelayedInfo" /> */}
                {this.expanded && <FormItem name="pilotDelayedInfoSeconds" />}
                {/* <FormItem name="pilotDelayedShip" /> */}
                {this.expanded && <FormItem name="pilotDelayedShipSeconds" />}
              </Card>
              {this.props.can([
                'view ship_visits.movement.resource_uses',
                'edit ship_visits.movement.resource_uses',
              ]) && (
                <Card title="Velkonis" bordered={false}>
                  {this.expanded && <FormItem name="tugboatDocumentNr" />}
                  <FormItem name="tugboatId" />
                  <FormItem name="tugboatStart" />
                  <FormItem name="tugboatEnd" />
                  <FormItem name="tugShipVisitId" />
                  {/* <FormItem name="tugboatTarif" /> */}
                  {/* <FormItem
                formKind={EditResourceUse}
                disabled={!this.props.can('edit ship_visits.movement.resource_uses')}
                name="resourceUses"
                formItemProps={{ className: 'mt15' }}
                modalProps={{ width: '620px' }}
              /> */}
                </Card>
              )}
            </Col>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditMovement' })(EditMovement);
