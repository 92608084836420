import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card, Modal, message, Input, Alert } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, FormItem, gridCol2, gridRow } from '@ois-lib';

@inject('berthVisitRequestStore', 'appStore', 'authorize', 'can', 'authStore')
@observer
class EditBerthVisitRequest extends EditFormBase {
  title = 'Piestātnes rezervēšanas kartiņa';
  storeName = 'berthVisitRequestStore';
  baseUrl = '/planning/berth_visit_requests';

  submitButtonName = 'Iesniegt rezervācijas pieteikumu';
  actionButtonsOrder = ['accept', 'reject', 'modify'];

  actionButtons(store, defaultActions) {
    let actions = defaultActions;

    const errors = this.props.form.getFieldsError();
    let numErrors = 0;
    for (const fk in errors) {
      if (typeof errors[fk] !== 'undefined') {
        numErrors++;
      }
    }

    if (this.props.match.params.id !== 'new') {
      if (store.item.status) {
        actions = actions.filter((a) => a.key !== 'save');
      }
      switch (store.item.status) {
        case 'SUBMITTED':
        case 'MODIFIED':
        case 'REJECTED':
        case 'CANCELLED':
          if (this.canApply && this.canApply()) {
            actions.push({
              title: 'Rezervācijas pieteikuma labojumu iesniegšana',
              key: 'modify',
              button: {
                title: 'Iesniegt rezervācijas pieteikuma labojumus',
                icon: 'file-edit',
                props: {
                  type: 'primary',
                  disabled: () => !this.props.form.isFieldsTouched() || store.savingOne || numErrors,
                },
              },
              resolver: () => {
                return Modal.confirm({
                  title: 'Vai tiešām vēlaties iesniegt rezervācijas labojumus?',
                  content: (
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-label">
                        <h5 title="Piezīmes">Piezīmes</h5>
                      </div>
                      <div className="ant-col ant-form-item-control-wrapper" style={{ marginBottom: 15 }}>
                        <div className="ant-form-item-control">
                          <span className="ant-form-item-children">
                            <Input.TextArea
                              rows={4}
                              onChange={(v) => {
                                this.comment = v.target.value;
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Alert
                        message="Apstiprinot šo paziņojumu, rezervācijas pieteikuma labojumi tiks nosūtīti pārbaudei."
                        type="warning"
                        showIcon
                      />
                    </div>
                  ),
                  onOk: async (e) => {
                    try {
                      let out;
                      try {
                        out = await this.handleLeanSubmit(e);
                      } catch (err) {
                        console.error(err);
                        return;
                      }

                      const res = await store.call('modify', {
                        changes: out,
                        comment: this.comment,
                      });
                      if (res.ok) {
                        message.success('Rezervācijas pieteikuma labojumi iesniegti!');
                      } else {
                        message.error(`Kļūda: ${res.error}`);
                      }
                    } catch (err) {
                      message.error('Notikusi sistēmas kļūda iesniedzot rezervācijas pieteikuma labojumus.');
                      console.error(err);
                    }
                    this.load(store, true);
                  },
                  okType: 'primary',
                  okText: 'Iesniegt labojumus',
                  cancelText: 'Aizvērt',
                });
              },
            });
          }
          if (this.canCancel && this.canCancel() && store.item.status !== 'CANCELLED') {
            actions.push({
              title: 'Rezervācijas pieteikuma atcelšana',
              key: 'cancel',
              button: {
                title: 'Atcel rezervācijas pieteikumu',
                icon: 'ban',
                props: {
                  type: 'danger',
                  disabled: () => store.savingOne || numErrors,
                },
              },
              resolver: () => {
                return Modal.confirm({
                  title: 'Vai tiešām vēlaties atcelt rezervāciju?',
                  content: (
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-label">
                        <h5 title="Piezīmes">Piezīmes</h5>
                      </div>
                      <div className="ant-col ant-form-item-control-wrapper" style={{ marginBottom: 15 }}>
                        <div className="ant-form-item-control">
                          <span className="ant-form-item-children">
                            <Input.TextArea
                              rows={4}
                              onChange={(v) => {
                                this.comment = v.target.value;
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Alert message="Apstiprinot šo paziņojumu, rezervācija tiks atcelta." type="warning" showIcon />
                    </div>
                  ),
                  onOk: async (e) => {
                    try {
                      let out;
                      try {
                        out = await this.handleLeanSubmit(e);
                      } catch (err) {
                        console.error(err);
                        return;
                      }

                      const res = await store.call('cancel', {
                        changes: out,
                        comment: this.comment,
                      });
                      if (res.ok) {
                        message.success('Rezervācijas pieteikums atcelts!');
                      } else {
                        message.error(`Kļūda: ${res.error}`);
                      }
                    } catch (err) {
                      message.error('Notikusi sistēmas kļūda atceļot rezervācijas pieteikumu.');
                      console.error(err);
                    }
                    this.load(store, true);
                  },
                  okType: 'danger',
                  okText: 'Atcelt rezervācijas pieteikumu',
                  cancelText: 'Aizvērt',
                });
              },
            });
          }
          break;
        default:
      }
    }

    return actions;
  }

  async componentDidMount() {
    this.load(this.props[this.storeName]);
    const store = this.props[this.storeName];
    if (!store.item.userId && this.props.authStore.user && this.props.authStore.user.id) {
      store.item.userId = this.props.authStore.user.id;
    }
    if (!store.item.status) {
      store.item.status = 'NEW';
    }
  }

  canSave() {
    return true;
  }

  canCancel() {
    return true;
  }

  canApply() {
    return true;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    const { [this.storeName]: store } = this.props;

    return (
      <>
        <br />
        <Row {...gridRow}>
          <Card title="Informācija par kuģi" bordered className="mt15">
            <Col {...gridCol2}>
              {store.item && store.item.comment && (
                <Alert
                  style={{ marginBottom: 15 }}
                  message="Informācija"
                  description={store.item.comment}
                  type="info"
                  showIcon
                />
              )}
              <FormItem hidden={this.props.form.getFieldValue('status') === 'NEW'} name="status" />
              <FormItem hidden={this.props.form.getFieldValue('status') === 'NEW'} name="invoiceStatus" />
              <FormItem name="berthId" />
              <FormItem name="shipName" />
              <FormItem name="imo" />
              <FormItem name="flagCountryId" />
              <FormItem name="shipLength" />
              <FormItem name="shipWidth" />
              <FormItem name="shipDraft" />
              <FormItem name="gt" />
              <FormItem name="etaBerth" />
              <FormItem name="etaTerminal" />
              <FormItem name="startDate" />
              <FormItem name="endDate" />
            </Col>
          </Card>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditBerthVisitRequest' })(EditBerthVisitRequest));
