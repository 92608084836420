import React from 'react';
// import { formItemLayout } from '@ois-lib';
import EditFormBase, { EditFormContext } from './EditFormBase';
// import { Form } from 'antd';

class EditRowFormBase extends EditFormBase {
  render() {
    const { store: _store, form, children, ...props } = this.props;
    const store = this.store || props[this.storeName] || _store;

    const { getFieldProps } = form;
    const itemProps = {
      formInstance: form,
      fstore: store,
      getFieldProps,
      record_store: store,
      canEdit: this.forceDisableEdit ? false : !this.canSave || (this.canSave && this.canSave()),
    };

    return (
      <>
        {store && store.loadingOne && 'loading'}
        <EditFormContext.Provider value={itemProps}>{children}</EditFormContext.Provider>
        {/* <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        </Form> */}
      </>
    );
  }
}

export default EditRowFormBase;
// export { EditRowFormBase };
