import React from 'react';
import { EditFormContext } from './EditFormBase';

const FormItem = ({ fstore, ...props }) => {
  const col = fstore && fstore.colByName[props.name];
  return !fstore || !props.name || !col ? 'Invalid component' : col.renderFormItem(props);
};

export default (props) => (
  <EditFormContext.Consumer>{(ctx) => <FormItem {...ctx} {...props} />}</EditFormContext.Consumer>
);
