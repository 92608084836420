import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol, gridCol2, gridRow, FormItem } from '@ois-lib';
import EditDiscountCondition from './Forms/EditDiscountCondition';

@inject('discountStore', 'appStore', 'authorize', 'can')
@observer
class EditDiscount extends EditFormBase {
  title = 'Atlaides kartiņa';
  storeName = 'discountStore';
  baseUrl = '/finance/discounts';

  async componentDidMount() {
    this.load(this.props.discountStore);
  }

  canSave() {
    return false; // this.props.can('edit discounts');
  }

  canDelete() {
    return false; // this.props.can('delete discounts');
  }

  renderForm() {
    // const { authorize, match } = this.props;
    // authorize([match.params.id !== 'new' && 'view discounts', 'edit discounts']);

    return (
      <>
        <br />
        <Row {...gridRow}>
          <Card title="Atlaide" bordered className="mt15">
            <Col {...gridCol2}>
              <FormItem name="name" />
              <FormItem name="startDate" />
              <FormItem name="endDate" />
              <FormItem name="discount" />
              <FormItem name="comments" />
            </Col>
          </Card>
        </Row>
        <Row {...gridRow}>
          <Col {...gridCol} className="mt15">
            <FormItem formKind={EditDiscountCondition} name="discountConditions" hidePagination />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditDiscount' })(EditDiscount));
