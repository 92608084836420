import React from 'react';
import { Input } from 'antd';
import { Field } from './Field';

@Field.register
class PasswordComponent extends Field {
  static code = 'password';
  extraItemProps = {
    hasFeedback: !this.addonAfter,
  };

  renderComponent({ asText, ...props }) {
    const extra = {
      addonAfter: this.addonAfter,
      addonBefore: this.addonBefore,
      ref: this.fieldRef,
    };
    if (this.label || asText) {
      return '-';
    }

    return <Input.Password {...props} {...extra} />;
  }
}

export default PasswordComponent;
