/* eslint-disable no-async-promise-executor */
/* eslint-disable consistent-return */
import jwtDecode from 'jwt-decode';
import { notification } from 'antd';
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
import upload from 'rc-upload/lib/request';
import appStore from '../stores/AppStore';
import authStore from '../stores/AuthStore';
import socket from './socket';
import RequestQueue from './RequestQueue';

const hashCode = function (s) {
  var h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
};

class ApiService {
  tokenExp = 0;
  base = `/api/v1/`;
  defaultHeaders = {
    'Content-Type': `application/json`,
    Accept: `application/json`,
  };
  echo = null;
  onQueue = {};

  constructor() {
    this.socket = socket;
  }

  async login(data) {
    const req = await fetch(`${this.base}login`, {
      method: `POST`,
      headers: this.headers,
      body: JSON.stringify(data),
    });
    const res = await req.json();
    if (res) {
      if (res.error) {
        console.error(res.error);
        return { error: res.error };
      }
      return res;
    }
    return null;
  }

  get headers() {
    return window && window.localStorage.getItem(`token`)
      ? {
        ...this.defaultHeaders,
        Authorization: `Bearer ${window.localStorage.getItem(`token`)}`,
      }
      : this.defaultHeaders;
  }

  async refreshToken(force = false) {
    if (authStore.loggedIn) {
      const tokenExp = this.getExp();
      const expTime = Math.floor((tokenExp - Date.now()) / 1000 / 60);
      if (expTime < 0) {
        setTimeout(() => {
          authStore.logout();
        }, 100);
      }

      if (!this.refreshing && (force || (expTime > 0 && expTime < 30))) {
        this.refreshing = true;
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
          const req = await fetch(`${this.base}refresh`, {
            method: `POST`,
            headers: this.headers,
          });
          const res = await req.json();
          if (res && res.access_token) {
            this.setToken(res.access_token);
          }
          if (res) {
            this.refreshing = false;
          }
          return setTimeout(() => resolve(!this.refreshing), 200);
        });
      }
      this.refreshing = false;
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => resolve(!this.refreshing));
  }

  getExp() {
    if (typeof window !== `undefined` && window.localStorage && window.localStorage.getItem(`token`)) {
      const tokenDecoded = jwtDecode(window.localStorage.getItem(`token`));
      if (tokenDecoded) {
        return tokenDecoded.exp * 1000;
      }
    }
    return 0;
  }

  setToken(token = null) {
    this.tokenExp = 0;
    this.token = token;
    if (token) {
      const tokenDecoded = jwtDecode(token);
      if (tokenDecoded) {
        this.tokenExp = tokenDecoded.exp * 1000;
      }
      window.localStorage.setItem(`token`, token);
      // if (window.echo) {
      //   window.echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${token}`;
      // }
    }
    this.socket.updateToken(token);
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async call(_method, data, httpMethod, callCounter = 0) {
    if (typeof _method !== 'string') {
      return;
    }
    const refreshed = await this.refreshToken();
    if (refreshed) {
      // console.log('this.base: ', this.base);
      // console.log('_method: ', _method);
      // console.log('typeof _method: ', typeof _method);
      // if (typeof _method === 'symbol') {
      //   console.trace();
      // }

      const queueKey = hashCode(JSON.stringify(arguments));
      const req = await new Promise((resolve, reject) => {
        if (!this.onQueue[queueKey] || this.onQueue[queueKey].isResolved()) {
          this.onQueue[queueKey] = new RequestQueue({
            key: queueKey,
            request: data
              ? fetch(this.base + _method, {
                method: httpMethod || `POST`,
                headers: this.headers,
                body: JSON.stringify(data),
              })
              : fetch(this.base + _method, {
                method: httpMethod || `GET`,
                headers: this.headers,
              }),
            queue: this.onQueue,
            // resolve,
            reject,
            cacheLifetime: 2500,
          });
        }

        this.onQueue[queueKey].resolver = resolve;
        return this.onQueue[queueKey];
      });

      if (req && req.status === 401 && _method !== `logout`) {
        // if (window.localStorage && !authStore.logging) {
        //   window.localStorage.setItem('backLink', window.location.pathname);
        // }
        setTimeout(() => {
          authStore.logout();
        }, 100);
        return;
      }

      try {
        const res = (await req.json()) || (await req.body());
        res.responseCode = req.status;
        if (res) {
          if (res.error) {
            if (req && req.status !== 404) {
              if (req && req.status !== 403) {
                notification.error({
                  message: `Sistēmas kļūda`,
                  description: res.error,
                });
              }
              if (req && req.status !== 403) {
                throw new Error(res.error);
              }
            }
            appStore.responseCode = 404;
            // eslint-disable-next-line consistent-return
            return res;
          }
          if (res.warn) {
            notification.warn({
              message: `Paziņojums!`,
              description: res.warn,
            });
          }
          // eslint-disable-next-line consistent-return
          return res;
        }
      } catch (e) {
        console.error(e);
      }
    } else if (callCounter < 10) {
      // eslint-disable-next-line consistent-return
      return this.call(_method, data, httpMethod, callCounter++);
    }
  }

    syncRequest(method, url, data = null) {
        const xhr = new XMLHttpRequest();
        xhr.open(method, this.base + url, false); // false for synchronous
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');

        if (window.localStorage.getItem('token')) {
            xhr.setRequestHeader('Authorization', `Bearer ${window.localStorage.getItem('token')}`);
        }

        if (data) {
            xhr.send(JSON.stringify(data));
        } else {
            xhr.send();
        }

        if (xhr.status === 200) {
            return JSON.parse(xhr.responseText);
        } else {
            // Handle non-200 responses if needed
            console.error(`Request failed: ${xhr.status} ${xhr.statusText}`);
            return null;
        }
    }

  async fileUpload(blob) {
    return new Promise(async (resolve, reject) => {
      const now = new Date();
      let fileExt = '';
      let fileType = 'File';
      switch (blob.type) {
        case 'image/png':
          fileExt = '.png';
          fileType = 'Image';
          break;
        case 'image/jpeg':
        case 'image/jpg':
          fileExt = '.jpg';
          fileType = 'Image';
          break;
        default:
      }
      const data = { fileName: `${fileType}-${now.toISOString().slice(0, 10)}-${Math.random() * 2}${fileExt}` };
      try {
        upload({
          action: `${this.base}blobs`,
          method: 'POST',
          headers: { Authorization: this.headers.Authorization },
          file: blob,
          filename: 'file',
          onSuccess: resolve,
          onError: reject,
          data,
        });
      } catch (e) {
        return reject(e);
      }
    });
  }
}

const apiService = new ApiService();
const apiServiceProxy = new Proxy(apiService, {
  get: (target, prop) =>
    target[prop] === undefined ? (data, httpMethod) => target.call(prop, data, httpMethod) : target[prop],
});

export default apiServiceProxy;
export const syncRequest = apiService.syncRequest.bind(apiService);
