import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol } from '@ois-lib';
import Validate from '@ois-lib/validate';

@inject('appStore', 'authorize', 'can')
@observer
class EditDiscountCondition extends EditFormBase {
  storeName = 'store';

  constructor(props, context) {
    super(props, context);
    this.state = {
      hideValue: true,
      hideObjectId: true,
      optionSource: null,
      optionValueName: null,
    };
  }

  componentDidMount() {
    this.loadOptionSource();
  }

  componentDidUpdate() {
    const { store, form } = this.props;
    if (store.colByName.objectId.optionSource === null) {
      if (form.getFieldValue('objectId')) {
        this.loadOptionSource();
      } else if (this.state.hideValue && form.getFieldValue('value')) {
        this.loadOptionSource();
      }
    } else if (store.colByName.objectId.optionSource !== form.getFieldValue('object')) {
      this.clearStoreProps();
      this.loadOptionSource();
    }
  }

  clearObjectId = () => {
    this.props.form.setFieldsValue({
      value: null,
      objectId: null,
      operator: null,
    });
    this.clearStoreProps();
    this.loadOptionSource();
  };

  clearStoreProps = () => {
    const { store } = this.props;
    store.colByName.objectId.optionSource = null;
    store.colByName.objectId.optionStore = null;
    store.colByName.objectId.optionValueName = null;
    store.colByName.value.optionSource = null;
    store.colByName.value.optionStore = null;
    store.colByName.value.optionValueName = null;
  };

  loadOptionSource = () => {
    const { form } = this.props;
    const objectType = form.getFieldValue('object') ? form.getFieldValue('object') : null;

    // this.setState({
    //   hideValue: true,
    //   hideObjectId: true,
    // });

    // this.clearStoreProps();

    let optionSource = null;
    let optionValueName = null;
    let options = [
      { key: 'equal', value: '=' },
      { key: 'notEqual', value: '<>' },
    ];

    switch (objectType) {
      case 'wasteGroups':
      case 'shipOperationTypes':
      case 'shipTypes':
      case 'ships':
      case 'shippingLines':
      case 'ports':
      case 'berths':
      case 'companies':
        optionSource = objectType;
        optionValueName = 'name';
        break;
      case 'contracts':
        optionSource = objectType;
        optionValueName = 'contractNr';
        break;
      default:
    }

    options = !optionSource ? [...options, { key: 'bigger', value: '>' }, { key: 'smaller', value: '<' }] : options;

    this.props.store.colByName.operator.options = options;
    if (objectType) {
      this.props.store.colByName.objectId.optionSource = objectType;
    }
    this.setState({
      hideValue: !!optionSource || objectType === null,
      hideObjectId: !optionSource,
      optionSource,
      optionValueName,
    });
  };

  renderForm() {
    const { state } = this;
    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title={this.props.parentProps.title} bordered={false}>
            <FormItem name="name" />
            <FormItem name="object" onSelect={this.clearObjectId} />
            <FormItem name="operator" hidden={state.hideValue && state.hideObjectId} />
            <FormItem
              name="value"
              hidden={state.hideValue}
              rules={!state.hideValue ? [Validate.Required] : undefined}
            />
            <FormItem
              name="objectId"
              altOptionProps={{
                optionSource: state.optionSource,
                optionValueName: state.optionValueName,
              }}
              hidden={state.hideObjectId}
              rules={!state.hideObjectId ? [Validate.Required] : undefined}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditDiscountCondition' })(EditDiscountCondition);
