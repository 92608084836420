import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import StoreFactory from '@ois-lib/stores';
import EditShipVisit from '../live/EditShipVisit';

@inject('authorize', 'can', 'appStore')
@observer
class Visits extends React.Component {
  static Edit = EditShipVisit;

  title = 'Kuģu vizītes';

  constructor(props) {
    super(props);
    if (props.title) {
      this.title = props.title;
    }
    this.store = StoreFactory.getStore('shipVisits');
  }

  componentDidMount() {
    this.store.load();
  }

  render() {
    const { isWidget, settingsOptions, settingsButtons, history, appStore } = this.props;

    appStore.shipVisitLink = '/statistics/visits/';

    const DataTableWidget = (props) => (
      <DataTable
        {...props}
        store={this.store}
        title={this.title}
        onRowClick={(row) => history && history.push(`/statistics/visits/${row.id || ''}`)}
        // onAdd={can('edit ship_visits') && (() => history && history.push('/statistics/visits/new'))}
      />
    );

    return isWidget ? (
      <DataTableWidget additionalSettingsOptions={settingsOptions} additionalSettingsButtons={settingsButtons} />
    ) : (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>
            <DataTableWidget />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Visits);
