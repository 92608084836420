import { toJS } from 'mobx';
import Help from '@ois-lib/routes/Help';
import EditHelpContent from '@ois-lib/routes/EditHelpContent';
import EditHelpGroup from '@ois-lib/routes/EditHelpGroup';
import OKSLive /* , { otherComponents as OKSLiveComponents } */ from './routes/live';
import OKSMyContracts from './routes/Contracts';
import OKSMyContractsView from './routes/EditContract';
import OKSPlanning from './routes/planning';
import Notifications from './routes/Notifications';
// import TerminalSecurity from './routes/TerminalSecurity';
// import BerthSecurity from './routes/BerthSecurity';
import BusinessPlan from './routes/BusinessPlan';
import OKSFinance from './routes/finance';
// import Reports from './routes/reports';
import Statistics from './routes/statistics';
import Dashboard from './routes/dashboard/Dashboard';
import OKSProfile from './routes/EditCompany';
import OKSUserSettings from './routes/OKSUserSettings';
import Misc from './routes/misc';

export default [
  {
    title: 'Infopanelis',
    icon: 'home',
    path: '/',
    key: 'root',
    component: Dashboard,
    root: true,
    checkIfLogged: true,
    exact: true,
    filter: 'IN_PORT',
    showIf: ({ authStore: { user } }) => {
      const { company } = toJS(user);
      return company; // && (company.isAgent || company.isCargoLoader);
    },
  },
  {
    title: 'Meklēšana',
    path: '/search',
    key: 'search',
    breadcrumbsTitle: 'Meklēšana',
    component: Misc.Search,
    exact: true,
    hidden: true,
    includeInBreadcrumbs: true,
    // perms: ['view statistics.visits'],
  },
  {
    title: 'Paziņojumi',
    icon: 'bell',
    path: '/notifications',
    key: 'notifications',
    filter: 'IN_PORT',
    checkIfLogged: true,
    exact: true,
    component: Notifications,
    showIf: ({ authStore: { user } }) => {
      const { company } = toJS(user);
      return company;
    },
    children: [
      {
        title: 'Paziņojums',
        path: '/notifications/:id',
        component: Notifications.Edit,
        hidden: true,
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company;
        },
        filter: 'IN_PORT',
      },
    ],
  },
  {
    title: 'Kuģi ostā',
    icon: 'exchange-alt',
    path: '/ships',
    key: 'ships',
    component: OKSLive,
    filter: 'IN_PORT',
    checkIfLogged: true,
    exact: true,
    showIf: ({ authStore: { user } }) => {
      const { company } = toJS(user);
      return company && (company.isAgent || company.isCargoLoader);
    },
    children: [
      {
        title: 'Vizītes kartiņa',
        path: '/ships/:id',
        component: OKSLive.Edit,
        filter: 'IN_PORT',
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && (company.isAgent || company.isCargoLoader);
        },
        hidden: true,
      },
    ],
  },
  // {
  //   title: "Kravas",
  //   icon: "window-restore",
  //   path: "/live",
  //   children: [
  //     {
  //       title: "Ievestās/izvestās kravas",
  //       path: "/live/moving",
  //       component: OKSLive,
  //       filter: "Moving",
  //     },
  //   ],
  //   showIf: ({ authStore: { user } }) => {
  //     const { company } = toJS(user);
  //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
  //   },
  // },
  {
    title: 'Operatīvā situācija',
    icon: 'exchange-alt',
    path: '/live',
    showIf: ({ authStore: { user } }) => {
      const { company } = toJS(user);
      return company && company.isAgent;
    },
    key: 'live',
    children: [
      {
        title: 'Kustībā',
        path: '/live/moving',
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent;
        },
        key: 'moving',
        hasWidget: true,
        component: OKSLive,
        filter: 'Moving',
        children: [
          {
            title: 'Vizītes kartiņa',
            path: '/live/moving/:id',
            showIf: ({ authStore: { user } }) => {
              const { company } = toJS(user);
              return company && company.isAgent;
            },
            component: OKSLive.Edit,
            filter: 'Moving',
            hidden: true,
          },
        ],
      },
      {
        title: 'Piestātnēs',
        path: '/live/berthed',
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent;
        },
        key: 'berthed',
        hasWidget: true,
        component: OKSLive,
        filter: 'Berthed',
        children: [
          {
            title: 'Vizītes kartiņa',
            path: '/live/berthed/:id',
            showIf: ({ authStore: { user } }) => {
              const { company } = toJS(user);
              return company && company.isAgent;
            },
            component: OKSLive.Edit,
            filter: 'Berthed',
            hidden: true,
          },
        ],
      },
      {
        title: 'Reidā',
        path: '/live/raid',
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent;
        },
        key: 'raid',
        hasWidget: true,
        component: OKSLive,
        filter: 'Raid',
        children: [
          {
            title: 'Vizītes kartiņa',
            path: '/live/raid/:id',
            showIf: ({ authStore: { user } }) => {
              const { company } = toJS(user);
              return company && company.isAgent;
            },
            component: OKSLive.Edit,
            filter: 'Raid',
            hidden: true,
          },
        ],
      },
      {
        title: 'Gaidāmie',
        path: '/live/expected',
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent;
        },
        key: 'expected',
        hasWidget: true,
        component: OKSLive,
        filter: 'Expected',
        children: [
          {
            title: 'Vizītes kartiņa',
            path: '/live/expected/:id',
            showIf: ({ authStore: { user } }) => {
              const { company } = toJS(user);
              return company && company.isAgent;
            },
            component: OKSLive.Edit,
            filter: 'Expected',
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Plānošana',
    icon: 'compass',
    path: '/planning',
    showIf: ({ authStore: { user } }) => {
      const { company } = toJS(user);
      return company && company.isAgent;
    },
    children: [
      // {
      //   title: 'Kuģu vizīšu pieteikumi',
      //   path: '/planning/visits',
      //   component: OKSLive,
      //   filter: 'VISIT_REQUEST',
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent;
      //   },
      //   children: [
      //     {
      //       title: 'Vizītes kartiņa',
      //       path: '/planning/visits/:id',
      //       component: OKSLive.Edit,
      //       filter: 'VISIT_REQUEST',
      //       showIf: ({ authStore: { user } }) => {
      //         const { company } = toJS(user);
      //         return company && company.isAgent;
      //       },
      //       hidden: true,
      //     },
      //   ],
      // },
      {
        title: 'Resursu pieteikumi',
        path: '/planning/resource_requests',
        key: 'resource_requests',
        hasWidget: true,
        component: OKSPlanning.ResourceRequests,
        // showIf: ({ authStore: { user } }) => {
        //   const { company } = toJS(user);
        //   return company && company.isAgent;
        // },
        children: [
          {
            title: 'Resursu pieteikuma kartiņa',
            path: '/planning/resource_requests/:id',
            component: OKSPlanning.ResourceRequests.Edit,
            hidden: true,
          },
        ],
      },
      {
        title: 'Piestātņu rezervācijas',
        path: '/planning/berth_visit_requests',
        key: 'berth_visit_requests',
        hasWidget: true,
        component: OKSPlanning.BerthVisitRequests,
        // showIf: ({ authStore: { user } }) => {
        //   const { company } = toJS(user);
        //   return company && (company.isAgent || company.isCargoLoader);
        // },
        children: [
          {
            title: 'Piestātnes rezervēšanas kartiņa',
            path: '/planning/berth_visit_requests/:id',
            component: OKSPlanning.BerthVisitRequests.Edit,
            hidden: true,
          },
        ],
      },
      {
        title: 'Līnijas',
        path: '/planning/lines',
        key: 'lines',
        component: OKSPlanning.Lines,
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent;
        },
        children: [
          {
            title: 'Līnijas kartiņa',
            path: '/planning/lines/:id',
            component: OKSPlanning.Lines.Edit,
            hidden: true,
          },
        ],
      },
      {
        title: 'Līniju kuģi',
        path: '/planning/line_schedule',
        key: 'line_schedule',
        component: OKSPlanning.LineSchedule,
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent;
        },
        children: [
          {
            title: 'Līniju grafika kartiņa',
            path: '/planning/line_schedule/:id',
            component: OKSPlanning.LineSchedule.Edit,
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Statistika',
    icon: 'analytics',
    key: 'statistics',
    path: '/statistics',
    children: [
      {
        title: 'Pabeigtās vizītes',
        path: '/statistics/visits',
        component: OKSLive,
        filter: 'HISTORY',
        hasWidget: true,
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent;
        },
        children: [
          {
            title: 'Pabeigtās vizītes kartiņa',
            path: '/statistics/visits/:id',
            component: OKSLive.Edit,
            filter: 'HISTORY',
            showIf: ({ authStore: { user } }) => {
              const { company } = toJS(user);
              return company && company.isAgent;
            },
            hidden: true,
          },
        ],
      },
      // {
      //   title: 'Kustības',
      //   path: '/statistics/movements',
      //   component: Statistics.Movements,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Piestātņu apmeklējumi',
      //   path: '/statistics/berch_visits',
      //   perms: ['view statistics.berch_visits'],
      //   hasWidget: true,
      //   component: Statistics.BerchVisits,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Dokumenti',
      //   path: '/statistics/documents',
      //   perms: ['view statistics.documents'],
      //   hasWidget: true,
      //   component: Statistics.Documents,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Kravas',
      //   path: '/statistics/cargo',
      //   perms: ['view statistics.cargo'],
      //   hasWidget: true,
      //   component: Statistics.Cargo,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Darbības',
      //   path: '/statistics/operations',
      //   perms: ['view statistics.operations'],
      //   hasWidget: true,
      //   component: Statistics.Operations,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Pārskats par kuģiem ostā',
      //   path: '/statistics/overview',
      //   perms: ['view statistics.overview'],
      //   hasWidget: true,
      //   component: Statistics.Overview,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Atskaites',
      //   perms: ['view reports'],
      //   path: '/statistics/reports',
      //   hasWidget: true,
      //   component: Reports,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Kuģu aģenti',
      //   path: '/statistics/agents',
      //   component: Statistics.ShipAgents,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Kruīza kuģu pasažieri',
      //   path: '/statistics/passengers',
      //   component: Statistics.CruisePassengers,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Kuģu atkritumi',
      //   path: '/statistics/waste',
      //   component: Statistics.ShipWaste,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Ostas maksas',
      //   path: '/statistics/payments',
      //   component: Statistics.PortPayments,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Kravu operāciju pārskats',
      //   path: '/statistics/cargo_operations',
      //   component: Statistics.CargoOperations,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      // {
      //   title: 'Kravu galamērķu pārskats',
      //   path: '/statistics/cargo_destinations',
      //   component: Statistics.CargoDestinations,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      {
        title: 'Mana statistika',
        path: '/statistics/mystatistics',
        component: Statistics.MyStatistics,
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent && !window.location.href.includes('oks.rop.lv');
        },
      },
      
      // {
      //   title: 'Pārskats par ostu darbību',
      //   path: '/statistics/portsreport',
      //   component: Statistics.PortsReport,
      //   hasWidget: true,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
      {
        title: 'Ikmēneša statistika',
        path: '/statistics/shippinglinereport',
        component: Statistics.ShippingLineReport,
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && company.isAgent && !window.location.href.includes('oks.rop.lv');
        },
      },
      // {
      //   title: 'Darbības rādītāju aptauja',
      //   path: '/statistics/stividorreport',
      //   component: Statistics.StividorReport,
      //   hasWidget: true,
      //   showIf: ({ authStore: { user } }) => {
      //     const { company } = toJS(user);
      //     return company && company.isAgent && !window.location.href.indexOf('/oks') !== -1;
      //   },
      // },
    ],
  },
  // {
  //   title: 'Terminālu drošibas pāraudes',
  //   icon: 'shield-check',
  //   path: '/terminalsecurity',
  //   key: 'terminalsecurity',
  //   component: TerminalSecurity,
  //   showIf: ({ authStore: { user } }) => {
  //     const { company } = toJS(user);
  //     return company && company.isCargoLoader || ( company && company.isAgent && !window.location.href.indexOf('/oks') !== -1);
  //   },
  // },
  // {
  //   title: 'Piestātņu pārbaudes',
  //   icon: 'clipboard-check',
  //   path: '/berthsecurity',
  //   key: 'berthsecurity',
  //   component: BerthSecurity,
  //   showIf: ({ authStore: { user } }) => {
  //     const { company } = toJS(user);
  //     return company && company.isCargoLoader || (company && company.isAgent && !window.location.href.indexOf('/oks') !== -1);
  //   },
  // },
  {
    title: 'Par uzņēmumu',
    icon: 'building',
    path: '/company/edit',
    component: OKSProfile,
    hasWidget: true,
    showIf: ({ authStore: { user } }) => {
      const { company } = toJS(user);
      return company; // && (company.isAgent || company.isCargoLoader);
    },
  },
  {
    title: 'Norēķini',
    icon: 'euro-sign',
    path: '/finance',
    showIf: ({ authStore: { user } }) => {
      const { company } = toJS(user);
      return company && company.isAgent;
    },
    children: [
      {
        title: 'Līgumi',
        // icon: 'handshake',
        path: '/contracts',
        component: OKSMyContracts,
        showIf: ({ authStore: { user } }) => {
          const { company } = toJS(user);
          return company && (company.isAgent || company.isCargoLoader) && !window.location.href.includes('oks.rop.lv');
        },
        children: [
          {
            title: 'Līguma kartiņa',
            path: '/contracts/:id',
            component: OKSMyContractsView,
            hidden: true,
          },
        ],
      },
      {
        title: 'Kalkulācijas',
        component: OKSFinance.Calculations,
        path: '/finance/calculations',
        children: [
          {
            title: 'Kalkulācijas kartiņa',
            path: '/finance/calculations/:id',
            component: OKSFinance.Calculations.Edit,
            hidden: true,
          },
        ],
      },
    ],
  },

  {
    title: 'Biznesa plāns',
    icon: 'sack',
    path: '/businessplan',
    key: 'businessplan',
    component: BusinessPlan,
    showIf: ({ authStore: { user } }) => {
      const { company } = toJS(user);
      return company && (company.isAgent || company.isCargoLoader) && !window.location.href.includes('oks.rop.lv');
    }
  },
  // {
  //   title: 'Pārskati',
  //   path: '/reports',
  //   hasWidget: true,
  //   // component: OKSReports.Reports,
  //   key: 'reports',
  //   icon: 'book-open',
  //   children: [],
  //   showIf: ({ authStore: { user } }) => {
  //     const { company } = toJS(user);
  //     return company && (company.isAgent && !window.location.href.indexOf('/oks') !== -1)
  //   }
  // },
  {
    title: 'Iestatījumi',
    path: '/profile/edit',
    key: 'profile',
    component: OKSUserSettings,
    icon: 'cog',
    children: [],
    hasWidget: true,
  },
  {
    title: 'Palīdzība',
    path: '/help',
    component: Help,
    showIf: ({ authStore: { user } }) => {
      // const { company } = toJS(user);
      return true;
    },
    icon: 'question-circle',
    children: [
      {
        title: 'Palīdzība',
        path: '/help/:key',
        exact: true,
        component: Help,
        hidden: false,
      },
      {
        title: 'Palīga satura rādītāja kartiņa',
        path: '/helpGroup/:id',
        exact: true,
        component: EditHelpGroup,
        hidden: true,
      },
      {
        title: 'Palīga satura kartiņa',
        path: '/helpContent/:id',
        exact: true,
        component: EditHelpContent,
        hidden: true,
      },
    ],
  },
];
