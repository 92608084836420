import React, { useState } from 'react';
import { TreeSelect, Spin, Icon } from 'antd';
import { observer } from 'mobx-react';
import { Field } from './Field';

const TreeSelectObserver = observer(({ col, ...props }) => {
  let options = col.getOptionsFromStore();
  if (col.optionsSortFunc && options) {
    options = options.sort(col.optionsSortFunc);
  }
  const [treeData, setTreeData] = useState([]);

  if (!treeData.length && options.length) {
    setTreeData(
      options.map((o, _k) => {
        return {
          id: o.key,
          pId: null,
          title: o.value,
          value: o.key,
          isLeaf: o.type !== 'object',
        };
      })
    );
  }

  const handler = {
    onChange: (value) => {
      props.onChange(value);
    },
    genTreeNode: (parentId, isLeaf = false) => {
      const random = Math.random().toString(36).substring(2, 6);
      return {
        id: random,
        pId: parentId,
        value: random,
        title: isLeaf ? 'Tree Node' : 'Expand to load',
        isLeaf,
      };
    },
    onLoadData: (treeNode) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        const { id } = treeNode.props;
        const data = await col.optionStore.loadOne(id);
        setTreeData([
          ...treeData,
          ...data.map((item) => {
            return {
              id: item.dataKey,
              pId: id,
              title: item.title,
              value: item.dataKey,
              isLeaf: item.type !== 'object',
            };
          }),
        ]);

        resolve();
      }),
  };

  return (
    <TreeSelect
      allowClear
      // showSearch
      treeDataSimpleMode
      style={{ width: '100%' }}
      value={props.value}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      // placeholder="Please select"
      onChange={handler.onChange}
      loadData={handler.onLoadData}
      treeData={treeData}
    />
  );
});

const Spinner = () => <Spin indicator={<Icon type="loading" spin />} size="small" />;

const TreeSelectLabel = observer(({ col, value }) => {

  
  if (!value) {
    // console.log('value: ', value);
    return ' - ';
  }

  if (typeof value !== 'string') {
    return value;
  }

  const options = col.getOptionsFromStore();
  if (
    options.length &&
    options.find((i) => {
      return i.value === value;
    })
  ) {
    return value;
  }

  const [initialisedValue, setInitialisedValue] = useState(null);

  if (!initialisedValue) {
    // console.log('value: ', value);
    const parentValue = value.split('.');
    parentValue.pop();
    col.optionStore.loadOne(parentValue.length ? parentValue.join('.') : value).then((data) => {
      setTimeout(() => {
        const merged = [...options, ...data];
        const selected = merged.find((i) => i.dataKey === value);
        setInitialisedValue(selected ? selected.title : true);
      });
    });
  }
  return initialisedValue || <Spinner />;
});

@Field.register
class TreeSelectComponent extends Field {
  static code = 'treeselect';

  renderColumn(value) {
    // console.log('value@renderColumn: ', value);
    return this.renderComponent({ value, asText: true });
  };

  renderComponent({ asText, ...props }) {
    if (this.label || asText) {
      return <TreeSelectLabel col={this} {...props} />;
    }
    return <TreeSelectObserver col={this} {...props} />;
  }
}

export default TreeSelectComponent;
