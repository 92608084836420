import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout, Spin, Icon } from 'antd';
import { inject } from 'mobx-react';
import authStore from '../stores/AuthStore';

let timeOutCheck = null;

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const PrivateRoute = inject('appStore')(({ component: Component, filter, componentProps: routeProps, ...rest }) => {
  if (rest.computedMatch && rest.appStore) {
    rest.appStore.setRouterMatch(rest.computedMatch);
  }

  clearTimeout(timeOutCheck);
  timeOutCheck = setTimeout(() => {
    if (window.backLink && rest.appStore) {
      let { backLink } = window;
      delete window.backLink;
      if (backLink) {
        backLink = backLink.replace(window.location.origin, '');
        rest.appStore.history.push(backLink);
      }
    }
  }, 1000);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authStore.loading) {
          return (
            <Layout.Content style={{ margin: '10% 0', textAlign: 'center' }}>
              <Spin indicator={antIcon} size="large" />
            </Layout.Content>
          );
        }

        return authStore.loggedIn ? (
          <Component {...props} {...{ filter, title: rest.title, routeProps }} />
        ) : (
          <Redirect to={`/login${(authStore.backUrl && `?backUrl=${authStore.backUrl}`) || ''}`} />
        );
      }}
    />
  );
});

export default PrivateRoute;
