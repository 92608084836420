import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb } from 'antd';
import { inject, observer } from 'mobx-react';
import HelpLink from '../HelpLinkWrapper';

const itemRender = () => (route, _params, _routes) => {
  const isLast = route.path && _routes[_routes.length - 1].path === route.path;
  return !route.path || isLast ? (
    <span>
      {route.breadcrumbName} {!route.hideHelpLink && isLast && <HelpLink />}
    </span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
};

export default inject('appStore')(
  observer((props) => {
    return (
      <Row>
        <Col style={{ paddingTop: '20px' }}>
          <Breadcrumb itemRender={itemRender(props)} params={props} routes={props.appStore.breadcrumbs} />
        </Col>
      </Row>
    );
  })
);
