import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import EditResourceRequest from './EditResourceRequest';

@inject('resourceRequestStore', 'authorize', 'can')
@observer
class ResourceRequests extends React.Component {
  static Edit = EditResourceRequest;

  title = 'Resursu pieteikumi';

  componentDidMount() {
    const { resourceRequestStore } = this.props;
    resourceRequestStore.load();
  }

  render() {
    const { isWidget, resourceRequestStore, history, settingsOptions, settingsButtons } = this.props;

    const DataTableWidget = (props) => (
      <DataTable
        {...props}
        store={resourceRequestStore}
        title={this.title}
        onRowClick={(row) => history.push(`/planning/resource_requests/${row.id || ''}`)}
        onAdd={() => history.push('/planning/resource_requests/new')}
      />
    );

    return isWidget ? (
      <DataTableWidget additionalSettingsOptions={settingsOptions} additionalSettingsButtons={settingsButtons} />
    ) : (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>
            <DataTableWidget />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(ResourceRequests);
