import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, Card, Avatar, Form, Table, Button, message } from 'antd';
import { inject, observer } from 'mobx-react';
import { gridRow, EditFormBase, FontAwesomeIcon, gridCol2 } from '@ois-lib';
import StoreFactory from '@ois-lib/stores';
import './Dashboard.css';

const DashLink = (props) => {
  const { handleClick, icon, title } = props;
  return (
    <Col span={12} onClick={handleClick}>
      <Card className="dashLink">
        <FontAwesomeIcon icon={icon} className="dashLinkIcon" type="solid" />
        <div>{title}</div>
      </Card>
    </Col>
  );
};

@inject('authorize', 'can', 'appStore', 'authStore', 'companyStore', 'history', 'oksNotificationStore')
@observer
class Dashboard extends EditFormBase {
  // title = 'Uzņēmuma profils';
  // storeName = 'companyStore';
  // baseUrl = '/';

  constructor(props) {
    super(props);
    const {
      // match: {
      //   params: { id },
      // },
      // history,
      companyStore,
      authStore: { user },
    } = props;
    const id = user.companyId;
    // if ([user.id, 'edit'].includes(id)) {
    //   companyStore.item.id = user.id;
    // } else {
    companyStore.item.id = id;
    //   history.push('/profile/edit');
    // }

    this.store = StoreFactory.getStore('shipVisits', props.filter ? props.filter.toUpperCase() : undefined);
    if (props.routeProps && props.routeProps.mode) {
      const { mode } = props.routeProps;
      if (mode === 'visitRequests') {
        this.store.systemFilters = { atd: false };
      }
    } else {
      this.store.systemFilters = props.filter ? { status: props.filter.toUpperCase(), shipAgentId: id } : {};
    }
  }

  async componentDidMount() {
    this.store.load().then(() => {
      const { companyStore, oksNotificationStore } = this.props;
      const { item } = companyStore;
      this.loadFresh(companyStore);
      if (this.props.appStore.incompleteCompanyInfo !== 2) {
        this.store.loadRecord(item.id, 'companies').then(() => {
          const res = this.store._loadedRecord;
          const requiredFieldList = ['descriptionLat', 'email', 'city', 'street', 'zip', 'phone', 'storedTags'];
          if (this.props.authStore.user.isCompanyAdmin) {
            for (let i = 0; i < requiredFieldList.length; i++) {
              const check = Array.isArray(res[requiredFieldList[i]]) && res[requiredFieldList[i]].length === 0;
              if (!res[requiredFieldList[i]] || check) {
                message.info('Lūdzu, ievadiet datus par uzņēmumu!');
                this.props.appStore.setIncompleteCompanyInfo(0);
                this.props.history.push('/company/edit');
                return;
              }
            }
          }
          this.props.appStore.setIncompleteCompanyInfo(2);
        });
      }
      oksNotificationStore.load();
    });
  }

  render() {
    const { user } = this.props.authStore;
    const { company } = user;
    const { oksNotificationStore, history } = this.props;
    const notificationsDataSource =
      oksNotificationStore && oksNotificationStore._data
        ? oksNotificationStore._data
            //.filter((e) => !e.validTill || moment(e.validTill).isAfter(moment()))
            .sort((a, b) => {
              return moment(a.saved).isAfter(moment(b.saved), 'second') ? -1 : 1;
            })
            .slice(0, 5)
            .map((e) => ({
              title: e.subject,
              date: moment(e.saved).format('DD.MM.YYYY.'),
              key: e.id,
              isNew: !e.pivot.readAt,
            }))
        : [];

    // const notificationsDataSource = [
    //   {
    //     title: `Sveicam Jūs jaunajā Ostas Informācijas sistēmā! Šobrīd vēl veicam sistēmas pielāgošanu, tādēļ dažas sadaļas vēl nav pieejamas, vai arī var nedarboties korekti. Informēsim Jūs par atjauninājumiem!\n
    //     Lūdzam ar jautājumiem par jaunās sistēmas lietošanu sazināties ar Denisu Bičkovu, e-pasts deniss.bickovs@rop.lv, tel. +371 27896336`,
    //     date: '15.02.2021.',
    //     key: 0,
    //     source: '#',
    //   },

    const notificationsColumns = [
      {
        title: 'Datums',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Temats',
        dataIndex: 'title',
        key: 'title',
      },
    ];

    const liveDataSource = this.store
      ? this.store._data.map((e, index) => {
          let shipName = e.shipIn ? e.shipIn.name : e.shipNameIn;
          if (!shipName) {
            shipName = e.shipOut ? e.shipOut.name : e.shipNameOut;
          }
          return {
            key: index,
            ship: shipName,
            eta: e.eta ? moment(e.eta).format('L LTS') : null,
            ata: e.ata ? moment(e.ata).format('L LTS') : null,
            etd: e.etd ? moment(e.etd).format('L LTS') : null,
          };
        })
      : [];
    const liveColumns = [
      {
        title: 'Kuģa nosaukums',
        dataIndex: 'ship',
        key: 'ship',
        className: 'centered',
      },
      {
        title: 'ATA',
        dataIndex: 'ata',
        key: 'ata',
        className: 'centered',
      },
      {
        title: 'ETD',
        dataIndex: 'etd',
        key: 'etd',
        className: 'centered',
      },
    ];
    const initials = user && user.firstName && user.lastName ? user.firstName.charAt(0) + user.lastName.charAt(0) : '';
    return (
      <span>
        <br />
        <Row {...gridRow} gutter={[16, 16]} type="flex">
          <Col {...gridCol2}>
            <Card title={`Sveicināti, ${user.firstName} ${user.lastName}`}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar size={128} className="avatar" onClick={() => this.props.history.push('profile/edit')}>
                  <span className="avatarText">{initials}</span>
                </Avatar>
                <div>{`${user.firstName} ${user.lastName}`}</div>
                <div>
                  Uzņēmums: <span className="company">{(company && company.name) || ' - '}</span>
                </div>
                <div>Kontakttelefons: {company && company.phone}</div>
              </div>
            </Card>
          </Col>
          <Col {...gridCol2}>
            <Card title="Paziņojumi">
              <Table
                dataSource={notificationsDataSource}
                columns={notificationsColumns}
                showHeader={false}
                pagination={false}
                onRow={(row) => {
                  return {
                    onClick: (event) => history.push(`/notifications/${row.key || ''}`),
                  };
                }}
                rowClassName={(record, index) => (record.isNew ? 'pointer bold' : 'pointer')}
                className="NotificationsTable"
              />
              <Button onClick={() => this.props.history.push('/notifications')} className="styledButton nextButton">
                <span>Visi paziņojumi</span>
              </Button>
            </Card>
          </Col>

          <Col {...gridCol2}>
            <Card title="Kuģi ostā">
              <Table
                dataSource={liveDataSource}
                columns={liveColumns}
                pagination={false}
                style={{ textAlign: 'center' }}
              />
            </Card>
          </Col>
          <Col {...gridCol2}>
            <Row gutter={[8, 8]} type="flex">
              <DashLink title="Kuģu vizītes" icon="ship" handleClick={() => this.props.history.push('/ships')} />
              <DashLink
                title="Resursu pieteikumi"
                icon="calendar-check"
                handleClick={() => this.props.history.push('/planning/resource_requests')}
              />
            </Row>
            <Row gutter={[8, 8]} type="flex">
              <DashLink
                title="Piestātņu rezervācijas"
                icon="anchor"
                handleClick={() => this.props.history.push('/planning/berth_visit_requests')}
              />
              {/* <DashLink
                title="Mana statistika"
                icon="analytics"
                handleClick={() => this.props.history.push('/statistics/mystatistics')}
              /> */}
            </Row>
            {/* <Row gutter={[8, 8]} type="flex">
              <DashLink
                title="Terminālu drošības pārbaudes"
                icon="shield-check"
                handleClick={() => this.props.history.push('/terminalsecurity')}
              />
              <DashLink
                title="Piestātņu pārbaudes"
                icon="clipboard-check"
                handleClick={() => this.props.history.push('/berthsecurity')}
              />
            </Row> */}
          </Col>
        </Row>
      </span>
    );
  }
}

// export default withRouter(Dashboard);
export default withRouter(Form.create({ name: 'Dashboard' })(Dashboard));
