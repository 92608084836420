/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import ColorPicker from 'rc-color-picker';
import { Field } from './Field';
import 'rc-color-picker/assets/index.css';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';

@Field.register
class ColorPickerComponent extends Field {
  static code = 'colorpicker';

  renderColorColumn(value = '#fff') {
    return <FontAwesomeIcon type="solid" icon="map" style={{ color: value }} />;
  }

  renderComponent({ value, onChange, ...props }) {
    return (
      <>
        <ColorPicker
          className={!value ? 'empty-box' : ''}
          color={value}
          defaultColor={'#fff'}
          onClose={({ color }) => onChange(color)}
          placement="topLeft"
          enableAlpha={false}
          {...props}
        />
        {this.labelRight && (
          <>
            &nbsp;
            <label
              onClick={() => {
                const elm = document.getElementsByClassName('rc-color-picker-trigger');
                if (elm && elm[0].click) elm[0].click();
              }}
              className={this.isRequired && 'ant-form-item-required'}
            >
              {this.getFormLabel(this, props)}
            </label>
          </>
        )}
      </>
    );
  }
}

export default ColorPickerComponent;
