import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { gridCol2, gridRow } from '@ois-lib';
import '../dashboard/Dashboard.css';
import turnover from './img/turnover.png';
import passengers from './img/passengers.png';
import structure from './img/structure.png';
import traffic from './img/traffic.png';

@inject('appStore', 'contractStore')
@observer
class Notifications extends React.Component {
  render() {
    // const { contractStore, history } = this.props;

    return (
      <div>
        <br />
        <Row {...gridRow} gutter={[16, 16]} type="flex">
          <Col {...gridCol2}>
            <Card title="Mana statistika">
              <img src={turnover} alt="Kopējais kravu apgrozījums" style={{ maxHeight: '100%', maxWidth: '100%' }} />
              <img src={structure} alt="Kravu struktūra" style={{ maxHeight: '100%', maxWidth: '100%' }} />
              <img src={traffic} alt="Kuģu satiksme" style={{ maxHeight: '100%', maxWidth: '100%' }} />
              <img src={passengers} alt="Pasažieru satiksme" style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Notifications);
