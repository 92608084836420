import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol } from '@ois-lib';

@inject('appStore', 'authorize', 'can')
@observer
class EditBerthVisit extends EditFormBase {
  title = 'Piestātnes apmeklējums';
  static storeName = 'berthVisits';

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    const { store } = this;
    // eslint-disable-next-line eqeqeq
    if (props && props.match && this.props && this.props.match && props.match.params.id != this.props.match.params.id) {
      this.load(store, true, props);
    } else if (props && props.match && props.match.params.id === 'new') {
      if (
        props.fixedFormValues &&
        props.fixedFormValues.shipVisitId &&
        (!this.props.fixedFormValues || props.fixedFormValues.shipVisitId !== this.props.fixedFormValues.shipVisitId)
      ) {
        this.autofill();
      }
    }
  }

  canSave() {
    return true;
  }

  canDelete() {
    return true;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.berth_visits', 'edit ship_visits.berth_visits']);

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Piestātnes apmeklējums" bordered={false}>
            <FormItem name="nr" />
            <FormItem name="berthId" />
            <FormItem name="housing" />
            <FormItem name="board" />
            <FormItem name="eta" />
            <FormItem name="etd" />
            <FormItem name="startTime" />
            <FormItem name="endTime" />
            <FormItem name="status" />
            <FormItem name="berthVisitType" />
            <FormItem name="callPurposeId" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditBerthVisit' })(EditBerthVisit);
