import moment from 'moment-timezone';
import { toJS } from 'mobx';
import { DatePicker, Button, Icon, Tooltip } from 'antd';
import { FontAwesomeIcon } from '..';
import { Field } from './Field';
import React, { useState } from 'react';

const DateLabelComponent = ({ value, format }) => {
  if (!value) {
    return <div />;
  }
  const val = moment(value).tz('Europe/Riga').format(format);
  return <div>{val}</div>;
};


const YearComponent = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      format="Y"
      open={open}
      showTime={false}
      placeholder="Izvēlieties gadu"
      onPanelChange={(val) => { props.onChange(val); setOpen(false) }}
      onOpenChange={(state) => setOpen(state)}
      {...props}
    />
  );
};

@Field.register
class DateComponent extends Field {
  static code = 'date';

  constructor(col, dataSet) {
    super(col, dataSet);

    this.format = this.time ? 'DD.MM.YYYY. HH:mm' : 'DD.MM.YYYY.';

    //	Filters
    if (this.filter === undefined) {
      this.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, showButtons = true }) => {
        if (Array.isArray(selectedKeys)) {
          selectedKeys = selectedKeys.map((date) => (date ? moment(date) : null));
        }
        return (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={toJS(selectedKeys)}
              onChange={(v) => setSelectedKeys(v)}
              style={{ marginBottom: 8, display: 'block' }}
              format="L"
              showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }}
              allowClear={!showButtons}
            />
            {showButtons && (
              <>
                <Button
                  type="primary"
                  onClick={() => confirm(toJS(selectedKeys))}
                  icon="search"
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  Atlasīt
                </Button>
                <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                  Noņemt
                </Button>
              </>
            )}
          </div>
        );
      };

      this.filterIcon = (filtered) => {
        return <Icon type="calendar" style={{ color: filtered ? '#1890ff' : undefined }} />;
      };
    }
  }

  loadRowTransform(row) {
    const { key, extraFieldProps } = this;
    return row && row[key] ? moment(row[key]).tz('Europe/Riga').format(extraFieldProps?.mode === `year` ? `Y` : this.format) : null;
  }

  loadFormTransform(item, value = item[this.key]) {
    return value ? moment(item[this.key]).tz('Europe/Riga') : null;
  }

  saveFormTransform(values, out = {}) {
    out[this.key] = values[this.key] ? values[this.key].format('YYYY-MM-DD HH:mm:ss') : null;
    return super.saveFormTransform(out, out);
  }

  compareValues(left, right) {
    if (left) {
      left = left.format(this.format);
    }
    if (right) {
      if (typeof right === 'string') {
        right = moment(right);
      }
      right = right.format(this.format);
    }
    return left !== right;
  }

  renderWarningIcon({ previousValue }) {
    if (typeof previousValue === 'string') {
      previousValue = moment(previousValue).format(this.format);
    }
    return (
      <Tooltip
        title={
          <>
            <strong>Iepriekšējā vērtība: </strong>
            <span style={{ textDecoration: 'line-through' }}>{previousValue || '(tukšs lauks)'}</span>
          </>
        }
      >
        &nbsp;
        <FontAwesomeIcon icon="engine-warning" type="solid" style={{ color: 'orange', cursor: 'pointer' }} />
      </Tooltip>
    );
  }

  renderComponent(_props) {
    // eslint-disable-next-line prefer-const
    let { value, ...props } = _props;
    if (typeof value === 'string') {
      value = null; // graceful fail
      console.error('Invalid string value supplied for DateComponent, please pass a Moment object');
    }

    return this.label || this.asText || props.asText ? (
      <DateLabelComponent {...props} value={value} format={props?.mode === `year` ? `Y` : this.format} />
    ) : props?.mode === `year` ? (<YearComponent {..._props} />) : (
      <span style={{ display: 'flex', alignItems:"center" }}>
        <DatePicker
          showTime={this.time ? { format: 'HH:mm' } : false}
          placeholder={this.time ? 'Izvēlieties laiku' : 'Izvēlieties datumu'}
          format={this.format}
          value={value}
          {...props}
        />
        {_props.addonAfter ?? _props.addonAfter}
      </span>
    );
  }
}

export default DateComponent;
