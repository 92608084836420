import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Checkbox } from 'antd';
import { inject, observer } from 'mobx-react';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import StoreFactory from '@ois-lib/stores';
import EditShipVisit from '../live/EditShipVisit';
import { toJS } from 'mobx';

@inject('authorize', 'can', 'appStore', 'authStore', 'companyStore')
@observer
class Search extends React.Component {
    static Edit = EditShipVisit;

    title = 'Meklēšana';

    constructor(props) {
        super(props);
        if (props.title) {
            this.title = props.title;
        }
        this.state = {
            showOnlyAgentVisits: localStorage.getItem('showOnlyAgentVisits') === 'false' ? false : true,
        };
        const { filter } = props;
        this.shipVisitStore = StoreFactory.getStore('shipVisits', 'SEARCH');
        this.shipVisitStore.keepOriginalValues = ['shipAgentId'];
        this.shipVisitStore.systemFilters = {};
        const additionalFilters = {};
        if (this.state.showOnlyAgentVisits) {
            const { user } = props.authStore;
            const { company } = toJS(user);
            additionalFilters.shipAgentId = company.id;
        }

        this.shipVisitStore.systemFilters = filter ? { status: filter.toUpperCase(), ...additionalFilters } : {};
        this.shipVisitStore.sorter = { eta: 'desc' };
        this.shipVisitStore.load();

    }

    componentDidMount() {
        this.checkSearchValue(this.props, true);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.checkSearchValue(props);
    }

    checkSearchValue(props, force = false) {
        const { history, appStore } = props;
        const {
            location: { searchParams },
        } = history;

        appStore.searchValue = searchParams.q;
        this.shipVisitStore.systemFilters = {};
        if (appStore.searchValue) {
            this.shipVisitStore.search(appStore.searchValue);
        } else {
            this.shipVisitStore.search('');
        }
    }

    render() {
        const { history, authStore } = this.props;
        const { user } = authStore;
        const { company } = toJS(user);

        return (
            <>
                <br />
                <Row {...gridRow}>
                    <Col {...gridCol}>
                        <>
                            {/* {can(['view ship_visits', 'edit ship_visits']) && ( */}
                            <DataTable
                                title="Atrastās vizītes"
                                store={this.shipVisitStore}
                                actions={
                                    company &&
                                    company.id
                                    && [
                                        <Checkbox
                                            key="shoOnlyAgentVisits"
                                            style={{ marginRight: -15 }}
                                            checked={this.state.showOnlyAgentVisits}
                                            onChange={(e) => {
                                                const { checked } = e.target;
                                                const filters = {
                                                    ...this.shipVisitStore.filters,
                                                    ...this.shipVisitStore.systemFilters,
                                                    shipAgentId: checked && company ? company.id : undefined,
                                                };
                                                this.shipVisitStore.sysFilter(filters, this.shipVisitStore.sorter);
                                                this.setState({ showOnlyAgentVisits: checked }, () => {
                                                    localStorage.setItem('showOnlyAgentVisits', checked);
                                                });
                                            }}
                                        >
                                            Rādīt tikai <strong>{company.name}</strong> kuģus
                                        </Checkbox>,
                                    ]
                                }
                                rowClassName={(row, groq) => {
                                    let colorClass = 'visit-color ';
                                    if (company && company.name === row.shipAgentId) {
                                        {
                                            const rowClass = row._indicators.alertPermits.in.reduce((acc, indicator) => {
                                                if (indicator.status === 'ACCEPTED' && acc === '') {
                                                    return 'visit-accepted ';
                                                }
                                                if (indicator.status === 'REJECTED') {
                                                    return 'visit-rejected ';
                                                }
                                                return '';
                                            }, '');
                                            colorClass += rowClass;
                                        }
                                        if (row.status) {
                                            switch (row.status) {
                                                case 'BERTHED':
                                                    colorClass += 'blue';
                                                    break;
                                                case 'RAID':
                                                case 'EXPECTED':
                                                    colorClass += 'black';
                                                    break;
                                                case 'COMPLETED':
                                                    colorClass += 'green';
                                                    break;
                                                case 'MOVING':
                                                    colorClass += 'red';
                                                    break;
                                                default:
                                            }
                                        }
                                    } else {
                                        colorClass += 'black bg-white cursor-default ';
                                    }
                                    return colorClass;
                                }}
                                onRowClick={(row) => {
                                    let shipAgentId = row.shipAgentId;
                                    if (typeof shipAgentId !== 'number' && row['_orig.shipAgentId']) {
                                        shipAgentId = row['_orig.shipAgentId'];
                                    }
                                    if (company && company.id === shipAgentId && history) {
                                        let link;
                                        switch (row.status) {
                                            case 'IN_PORT':
                                                link = '/ships';
                                                this.store.sorter = { ata: 'desc' };
                                                break;
                                            case 'VISIT_REQUEST':
                                                link = '/planning/visits';
                                                break;
                                            case 'HISTORY':
                                                link = '/statistics/visits';
                                                break;
                                            case 'MOVING':
                                                link = '/live/moving';
                                                break;
                                            case 'BERTHED':
                                                link = '/live/berthed';
                                                break;
                                            case 'RAID':
                                                link = '/live/raid';
                                                break;
                                            case 'EXPECTED':
                                                link = '/live/expected';
                                                break;
                                            default:
                                                link = '/ships';
                                        }
                                        history.push(`${link}/${row.id || ''}`);
                                    }
                                }}
                                addButtonLabel="Pieteikt vizīti"
                                onAdd={
                                    undefined
                                    // shipVisitLink && filter === 'VISIT_REQUEST'
                                    //   ? () => history && history.push(`${shipVisitLink}/new`)
                                    //   : undefined
                                }
                            />
                            {/* )} */}
                        </>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withRouter(Search);
