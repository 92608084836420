import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider, observer } from 'mobx-react';
import '@ois-lib/assets/style/index.css';
import '@ois-lib/assets/style/font.css';
import App from '@ois-lib/components/AppOKS';
import storeFactory from '@ois-lib/stores';
import authStore from '@ois-lib/stores/AuthStore';
import AppStore from '@ois-lib/stores/AppStore';
// import PublicRoute from '@ois-lib/components/PublicRoute';
// import { LanguageProvider } from '@ois-lib/containers/Language';
// import LanguageSelector from '@ois-lib/components/LanguageSelector';
import * as serviceWorker from './serviceWorker';
// import Register from './routes/RegisterOKSUser';
import routes from './routes';
import ReactGA from 'react-ga';

const appVersion = '3.3.1';
if (localStorage.appVersion !== appVersion) {
  localStorage.clear();
  sessionStorage.clear();
  localStorage.appVersion = appVersion;
}

const mainTitle = document.title;
window.setTitle = (title = null, template = `[title] :: ${mainTitle}`) => {
  if (title) {
    title = template.replace('[title]', title);
  } else {
    title = mainTitle;
  }
  document.title = title;
};

const appStore = new AppStore(routes, 'oks');
storeFactory.appStore = appStore;
const stores = {
  authStore,
  appStore,
  contractStore: storeFactory.getStore('contracts'),
  companyStore: storeFactory.getStore('companies'),
  auditStore: storeFactory.getStore('loggedActions'),
  shipStore: storeFactory.getStore('ships'),
  resourceStore: storeFactory.getStore('resources'),
  resourceRequestStore: storeFactory.getStore('resourceRequests'),
  resourceTaskStore: storeFactory.getStore('resourceTasks'),
  berthStore: storeFactory.getStore('berths'),
  berthVisitRequestStore: storeFactory.getStore('berthVisitRequests'),
  pilotStore: storeFactory.getStore('pilots'),
  lineStore: storeFactory.getStore('shippingLines'),
  lineScheduleStore: storeFactory.getStore('shippingLinesSchedule'),
  shippingLinesPlanStore: storeFactory.getStore('shippingLinesPlan'),
  changeRecordsStore: storeFactory.getStore('changeRecords'),
  discountStore: storeFactory.getStore('discounts'),
  notificationStore: storeFactory.getStore('notifications'),
  oksNotificationStore: storeFactory.getStore('oksnotifications'),
  calculationStore: storeFactory.getStore('calculations'),
  calculationPeriodStore: storeFactory.getStore('calculationPeriods'),
  invoiceStore: storeFactory.getStore('invoices'),
  helpGroupStore: storeFactory.getStore('helpGroups'),
  helpContentStore: storeFactory.getStore('helpContents'),
  oksUserStore: storeFactory.getStore('oksusers'),
  can: authStore.can,
  authorize: authStore.authorize,
};

const history = stores.appStore.useBrowserHistory();

// This one is probably unnecessary
if (!window.localStorage.helpSysGroup) {
  window.localStorage.setItem('helpSysGroup', 'oks');
}

// Debug
window._____APP_STATE_____ = stores;
// keydown || onkeypress
window.addEventListener('keydown', (e) => {
  e = e || window.event;

  if (e && e.altKey) {
    // e.ctrlKey &&
    e.stopPropagation();
    e.preventDefault();

    switch (`${e.key}`.toUpperCase()) {
      case 'K':
        if (e.ctrlKey) {
          history.push('/small/moving');
        } else {
          history.push('/live/moving');
        }
        break;
      case 'P':
        if (e.ctrlKey) {
          history.push('/small/berthed');
        } else {
          history.push('/live/berthed');
        }
        break;
      case 'R':
        history.push('/live/raid');
        break;
      case 'G':
        if (e.ctrlKey) {
          history.push('/small/atsea');
        } else {
          history.push('/live/expected');
        }
        break;
      case 'N':
        history.push('planning/passenger_ships');
        break;
      case 'M':
        history.push('/planning/visits');
        break;
      case 'L':
      case 'B':
        history.push('/logout');
        break;
      default:
    }
  }
});
// const regex = /(register)(\/?)([\Ss]+)/g;
const AppOverlay = observer(() => (
  <App
    stores={stores}
    userMenu={
      [
        // {
        //   title: 'Profils',
        //   to: '/profile/edit',
        // },
        // {
        //   title: 'Līgumi',
        //   to: '/contracts',
        // },
      ]
    }
    oks
    // allowRegister
    // extraRoutes={[
    //   !authStore ||
    //     (!authStore.loggedIn && (
    //       <Route
    //         key="redirect-check"
    //         path="/register"
    //         render={({ history: { location } }) => {
    //           const { pathname: url } = location;
    //           const m = regex.exec(url);
    //           if (m) {
    //             if (!m[3] || (m[3] && m[3] !== 'new')) {
    //               return <Redirect to="/register/new" />;
    //             }
    //           }
    //           return <PublicRoute key="/register/:id" path="/register/:id" component={Register} />;
    //         }}
    //       />
    //     )),
    // ]}
    // langMenuComponent={
    //   !authStore || !authStore.loggedIn ? <LanguageSelector className="site_header_right_lang" /> : undefined
    // }
  />
));

ReactGA.initialize('UA-15805731-3');

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      {/* <LanguageProvider> */}
      <AppOverlay />
      {/* </LanguageProvider> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
