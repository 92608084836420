import React from 'react';
import { Input } from 'antd';
import { Field } from './Field';

@Field.register
class CodeComponent extends Field {
  static code = 'code';

  extraItemProps = {
    wrapperCol: { sm: 24, xs: 24, lg: 24, md: 24 },
    style: { width: '100%', marginRight: 0 },
    ...this.extraItemProps,
  };

  renderComponent(props) {
    return <Input.TextArea readOnly rows={6} className="code" {...props} />;
  }
}

export default CodeComponent;
