import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol, gridRow, FormItem } from '@ois-lib';

@inject('appStore', 'authorize', 'can')
@observer
class EditCrewMember extends EditFormBase {
  title = 'Apkalpes loceklis';
  storeName = 'store';

  canSave() {
    return true;
  }

  canDelete() {
    return true;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.crew_members', 'edit ship_visits.crew_members']);

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Apkalpes loceklis" bordered={false}>
            <FormItem name="nr" />
            <FormItem name="crewRankId" />
            <FormItem name="firstName" />
            <FormItem name="lastName" />
            <FormItem name="countryId" />
            <FormItem name="birthDate" />
            <FormItem name="birthPlace" />
            {/* <FormItem name="birthPlaceId" /> */}
            <FormItem name="gender" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditCrewMember' })(EditCrewMember);
