import moment from 'moment-timezone';
import { toJS, runInAction } from 'mobx';
import columnDefinitions from './columnDefinitions';

let Field;
if (typeof window === 'undefined') {
  Field = { make: () => ({}) };
} else {
  // eslint-disable-next-line global-require
  Field = require('../fields').default;
}

class FieldSpec {
  static classifiers = [
    {
      title: 'Kuģa dokumentu veidi',
      link: 'shipDocumentTypes',
    },
    {
      title: 'Kuģu darbības ostā',
      link: 'shipOperationTypes',
    },
    {
      title: 'Kuģu stāvokļu maiņas iemesli',
      link: 'shipStateChangeReasons',
    },
    {
      title: 'Kuģu vizītes mērķi',
      link: 'shipVisitPurposes',
    },
    {
      title: 'Ostas',
      link: 'ports',
    },
    {
      title: 'Ostu rajoni',
      link: 'portRegions',
    },
    {
      title: 'Jūras piekrastes zonas',
      link: 'shoreZones',
    },
    {
      title: 'Kalkulāciju statusi',
      link: 'calculationStatuses',
    },
    {
      title: 'Kalkulāciju tarifi',
      link: 'calculationTariffs',
    },
    {
      title: 'Klasifikācijas sabiedrības',
      link: 'iceClassCertifiers',
    },
    {
      title: 'Kravas statusi (SKLOIS)',
      link: 'cargoStatuses',
    },
    {
      title: 'Preču (kravu) veidi',
      link: 'cargoTypes',
    },
    {
      title: 'Preču grupas/virsgrupas',
      link: 'cargoGroups',
    },
    {
      title: 'Kravu veidu grupas',
      link: 'cargoGoodsTypeGroups',
    },
    {
      title: 'Kravu veidi',
      link: 'cargoGoodsTypes',
    },
    {
      title: 'Mērvienības',
      link: 'units',
    },
    {
      title: 'Pasažieru kategorijas',
      link: '',
    },
    {
      title: 'Kuģu kravnesības klases',
      link: 'shipCapacityClasses',
    },
    {
      title: 'Konteineru tipi (SKLOIS)',
      link: 'containerTypes',
    },
    {
      title: 'Kontroles organizācijas',
      link: 'controlOrganizations',
    },
    {
      title: 'Bankas',
      link: 'banks',
    },
    {
      title: 'Banku konti',
      link: 'bankAccounts',
    },
    {
      title: 'Rēķinu veidi',
      link: '',
    },
    {
      title: 'Notikumi',
      link: '',
    },
    {
      title: 'Dienestu maiņas',
      link: '',
    },
    {
      title: 'Maiņas žurnāls',
      link: '',
    },
    {
      title: 'Resursu tipi',
      link: 'resourceTypes',
    },
    {
      title: 'Resursu pielietojuma tipi',
      link: 'resourceUseTypes',
    },
    {
      title: 'Resursu pielietojuma apraksts',
      link: 'movementResourceUses',
    },
    {
      title: 'Kuģu tipi',
      link: 'shipTypes',
    },
    {
      title: 'Valstis',
      link: 'countries',
    },
    {
      title: 'Krājumu veidi (SKLOIS)',
      link: 'shipsStoreTypes',
    },
    {
      title: 'Atkritumu grupas',
      link: 'wasteGroups',
    },
    {
      title: 'Atkritumu veidi',
      link: 'wasteTypes',
    },
    {
      title: 'Kuģu tipu grupas',
      link: 'shipTypeGroups',
    },
    {
      title: 'Rangi/amati',
      link: 'crewRanks',
    },
    {
      title: 'Valūtas',
      link: 'currencies',
    },
    {
      title: 'Kustības pakalpojumu veidi',
      link: 'movementServiceTypes',
    },
    {
      title: 'Piestātņu apmeklējumu mērķi',
      link: 'callPurposes',
    },
    {
      title: 'Pārskatu pielāgotās grupas',
      link: 'overviewCustomizedGroups',
    },
    {
      title: 'Atslēgas vārdi',
      link: 'tags',
    },
    {
      title: 'Līgumu kodi',
      link: 'contractCodes',
    },
    {
      title: 'OKS ziņojumu sūtītāji',
      link: 'oksNotificationSenders',
    },
    {
      title: 'MARPOL grupas',
      link: 'marpolGroups',
    },
    {
      title: 'Degvielas tipi',
      link: 'fuelTypes',
    },
  ];

  _columns = columnDefinitions(this);
  colByStore = {};
  colByKey = {};
  tableMap = {
    CargoStatus: 'cargoStatuses',
    Company: 'companies',
    CompanyRepresentative: 'EditCompany_agents',
    Contract: 'contracts',
    Country: 'countries',
    Currency: 'currencies',
    ShippingLineShip: 'shippingLinesSchedule',
  };

  disableFilter = [
    'EditBerth_berthTechnicalData',
    'EditCompany_agents',
    'EditCompany_contracts',
    'EditLineSchedule_timetableEntries',
    'EditShip_operationalNotes',
    'EditShipVisit_operationalNotes',
    'EditShipVisit_crewMembers',
    'EditShipVisit_passengers',
    'EditShipVisit_cargo',
    'EditShipVisit_documents',
    'EditShipVisit_stores',
    'EditShipVisit_waste',
    'EditShipVisit_movement',
    'EditShipVisit_berthVisits',
    'EditShipVisit_shipOperations',
    'EditCalculation_calculationRows',
  ];

  get classifiers() {
    return FieldSpec.classifiers;
  }

  getColTitles(store, object, withoutMap = false, skipIfNoTitle = false) {
    const newObj = {};
    const obj =
      typeof object === 'string' ? JSON.parse(`{${object.replace(/=>/g, ':').replace(/NULL/g, 'null')}}`) : object;

    // eslint-disable-next-line guard-for-in
    for (const kk in obj) {
      const k = kk.replace(/\//g, '#');
      const col = this.getCol(withoutMap ? store : this.tableMap[store], k);
      let value = obj[kk];
      if (col) {
        value = col.loadRowTransform({ [k]: value });
      } else if (value && (k === 'updated_at' || k === 'created_at')) {
        value = moment(value).tz('Europe/Riga').format();
      }
      const newKey = this.getColTitle(
        withoutMap ? store : this.tableMap[store] ? this.tableMap[store] : store,
        k,
        skipIfNoTitle
      );
      if (newKey === null) {
        continue;
      }
      newObj[newKey] = value;
    }

    return newObj;
  }

  getColTitle(store, key, skipIfNoTitle = false, includeParentheses = false) {
    const { colByKey } = this;
    if (key === 'updated_at') {
      return 'Rediģēšanas laiks';
    }
    if (key === 'created_at') {
      return 'Izveidošanas laiks';
    }
    if (!colByKey[store] || !colByKey[store][key] || !colByKey[store][key].title) {
      if (isNaN(parseInt(key, 10)) && skipIfNoTitle && !key.includes(':')) {
        return null;
      }
      if (key.includes(':')) {
        key = key.split(':')[0];
      }
      return key;
    }

    let add = ``;
    if (includeParentheses && colByKey[store][key].parentheses) {
      add = ` (${colByKey[store][key].parentheses})`;
    }

    return colByKey[store][key].title + add;
  }

  getCol(store, key) {
    const { colByKey } = this;
    return colByKey[store] && colByKey[store][key] ? colByKey[store][key] : null;
  }

  getClassifierTitle(key) {
    return key && this.classifierTitles[key] ? this.classifierTitles[key] : null;
  }

  constructor() {
    const { _columns } = this;

    const dupliceMap = {
      EditCompany_contracts: 'contracts',
      EditTerminal_companies: 'companies',
      EditTerminal_berths: 'berths',
      EditDiscount_discountConditions: 'discountConditions',
      EditMovement_resourceUses: 'resourceUses',
      EditCargoConsignment_cargo: 'cargos',
      EditDangerousCargoConsignment_cargo: 'cargos',
      EditShipVisit_dangerousCargoConsignments: 'EditShipVisit_cargoConsignments',
      EditCalculationRow_details: 'EditCalculationRow_details',
      EditInvoice_calculations: 'calculations',
    };

    runInAction(() => {
      // Set column definition duplicates with names
      for (const newColumnKey of Object.keys(dupliceMap)) {
        const sourceColumnKey = dupliceMap[newColumnKey];
        if (_columns[sourceColumnKey]) {
          _columns[newColumnKey] = toJS(_columns[sourceColumnKey], {
            recurseEverything: true,
          });
        }
      }

      // Disable filters
      for (const key of Object.keys(_columns)) {
        let cols = _columns[key];
        if (!this.disableFilter.includes(key)) {
          // eslint-disable-next-line no-continue
          continue;
        }
        cols = cols.map((c) => {
          c.filter = false;
          return c;
        });
        _columns[key] = cols;
      }
    });

    const makeName = (name) => {
      return `${name.charAt(0).toLowerCase() + name.substring(1)}s`;
    };

    // Audit options automation script
    const newTableMap = this._columns.loggedActions
      .find((col) => col.key === 'table_name')
      .options.reduce((curr, acc) => {
        const name = makeName(acc.key);
        curr[acc.key] = this.tableMap[acc.key] || name;
        return curr;
      }, {});

    this.tableMap = newTableMap;

    this.classifierTitles = FieldSpec.classifiers
      .filter((c) => c.link)
      .reduce(
        (c, a) => ({
          ...c,
          [a.link]: a.title,
        }),
        {}
      );

    for (const store in _columns) {
      if (store) {
        this.colByKey[store] = {};
        this.colByStore[store] = [];
        for (const col of _columns[store]) {
          const field = Field.make(col, store);
          this.colByStore[store].push(field);
          this.colByKey[store][col.key] = field;
        }
      }
    }
  }

  add = (store, data) => {
    this.colByKey[store] = {};
    this.colByStore[store] = [];
    for (const col of data) {
      const field = Field.make(col, store);
      this.colByStore[store].push(field);
      this.colByKey[store][col.key] = field;
    }
  };
}

const { classifiers } = FieldSpec;
export { classifiers };

export default new FieldSpec();
