import React from 'react';
import { Card } from 'antd';
import moment from 'moment';
import { Field } from './Field';

const OwnableCard = ({ title, created, updated, source, status, renderer, className, bordered, noCard }) => {
  if ((created && created.at) || (updated && updated.at) || source) {
    const Wrapper = noCard ? ({ children }) => <>{children}</> : Card;
    return (
      <Wrapper title={title} bordered={bordered} className={className}>
        {renderer({ created, updated, source, status })}
      </Wrapper>
    );
  }
  return '';
};

@Field.register
class OwnableComponent extends Field {
  static code = 'ownable';
  getFormLabel(options) {
    return !options.asActualField ? undefined : super.getFormLabel(options);
  }

  renderValue(value) {
    const { created, updated, source, status } = value || {};
    if (created || updated || source) {
      const params = [];
      if (created.at) {
        params.push(
          <>
            <strong>Pievienots: </strong>
            &nbsp;
            {moment(created.at).format('L LTS')}
          </>
        );
      }
      if (created.by) {
        let name =
          created.by.firstName && created.by.lastName
            ? `${created.by.firstName} ${created.by.lastName}`
            : created.by.name;

        if (created.object && created.object === 'oks') {
          name += ' (OKS)';
        }

        params.push(
          <>
            <strong>Pievienoja: </strong>
            &nbsp;
            {name}
          </>
        );
      }

      if (updated.at) {
        params.push(
          <>
            <strong>Labots: </strong>
            &nbsp;
            {moment(updated.at).format('L LTS')}
          </>
        );
      }
      if (updated.by) {
        let name =
          updated.by.firstName && updated.by.lastName
            ? `${updated.by.firstName} ${updated.by.lastName}`
            : updated.by.name;

        if (updated.object && updated.object === 'oks') {
          name += ' (OKS)';
        }

        params.push(
          <>
            <strong>Laboja: </strong>
            &nbsp;
            {name}
          </>
        );
      }

      if (source) {
        params.push(
          <>
            <strong>Pirmavots: </strong>
            &nbsp;
            {source}
          </>
        );
      }

      if (status) {
        params.push(
          <>
            <strong>Statuss: </strong>
            &nbsp;
            {status}
          </>
        );
      }

      return (
        !!params.length && (
          <ul className="clean-list">
            {params.map((p, k) => (
              <li key={k}>{p}</li>
            ))}
          </ul>
        )
      );
    }
    return '';
  }

  render = (value) => {
    return this.renderValue(value);
  };

  renderComponent(props) {
    if (this.asActualField && !props.value) {
      return ' - ';
    }
    return (
      <OwnableCard
        {...props.value}
        noCard={props.noCard}
        className={props.className}
        bordered={props.bordered}
        title={!this.asActualField ? this.title : undefined}
        renderer={this.renderValue}
      />
    );
  }
}

export default OwnableComponent;
