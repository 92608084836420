import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Form, Input, Button, Checkbox } from 'antd';
import FontAwesomeIcon from '@ois-lib/assets/icon/FontAwesomeIcon';
import { LanguageContext } from '../../containers/Language';

const styleSheet = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    width: '85%',
    height: '80%',
  },
  subButton: {
    display: 'flex',
    marginBottom: '16px',
  },
  heading: {
    fontWeight: '600',
    fontSize: '1.1em',
    marginBottom: '1.1em',
  },
  btn: {
    width: '100%',
  },
};

@inject('authStore')
@observer
class LoginFormComponent extends React.Component {
  static contextType = LanguageContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
    };
  }
  componentWillUnmount() {
    this.setState({ loading: false });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { dictionary } = this.context;
    this.setState({ loading: true });
    const { authStore, form, history } = this.props;
    const {
      location: { pathname, searchParams },
    } = history;
    form.validateFields(async (err, values) => {
      if (!err) {
        const result = await authStore.login(values);
        if (result && result.ok) {
          if (searchParams.backUrl) {
            history.push(searchParams.backUrl);
          } else if (pathname !== '/') {
            history.replace('/', true);
          }
        } else {
          this.setState({ loading: false });
          form.setFields({
            password: {
              value: '',
              errors: [new Error(dictionary.BadUserNameOrPassword)],
            },
          });
        }
      }
    });
  };

  render() {
    const { form, allowRegister } = this.props;
    const { getFieldDecorator } = form;
    const { loading } = this.state;
    const { dictionary } = this.context;
    return (
      <Form onSubmit={this.handleSubmit} style={styleSheet.form}>
        <h1 style={styleSheet.heading}>{dictionary.UserAuth}</h1>
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: dictionary.PleaseEnterUserName }],
          })(
            <Input
              disabled={loading}
              prefix={<FontAwesomeIcon icon="user" style={{ fill: 'rgba(0,0,0,.25)' }} />}
              placeholder={dictionary.UserName}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: dictionary.PleaseEnterPassword }],
          })(
            <Input.Password
              disabled={loading}
              prefix={<FontAwesomeIcon icon="lock" style={{ fill: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={dictionary.Password}
              visibilityToggle
            />
          )}
        </Form.Item>
        <Form.Item disabled={loading} className="form_sub_buttons">
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true,
          })(<Checkbox>{dictionary.RememberMe}</Checkbox>)}
          <Link to="/forgot">{dictionary.ForgotPassword}</Link>
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit" style={styleSheet.btn}>
            {dictionary.Enter}
          </Button>
          {!!allowRegister && (
            <span>
              {dictionary.or}&nbsp;
              <Link to="/register/new">{dictionary.register}</Link>
            </span>
          )}
        </Form.Item>
      </Form>
    );
  }
}

const LoginForm = withRouter(Form.create({ name: 'LoginForm' })(LoginFormComponent));
export default LoginForm;
