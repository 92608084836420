import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Card, Input, Tabs, Button, Form, Radio, DatePicker, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, FontAwesomeIcon, gridCol, gridCol2, gridRow } from '@ois-lib';
import './dashboard/Dashboard.css';

@inject('appStore', 'authStore', 'companyStore')
@observer
class BusinessPlan extends EditFormBase {
  storeName = 'companyStore';
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
      history,
      companyStore,
      authStore: { user },
    } = props;

    if ([user.id, 'edit'].includes(id)) {
      companyStore.item.id = user.id;
    } else {
      companyStore.item.id = user.id;
      history.push('/businessplan');
    }
    this.state = {
      activeKey: 'base',
      // colSize: { lg: 18, xl: 14, xxl: 10 },
    };
  }

  async componentDidMount() {
    const { companyStore } = this.props;
    if (companyStore.item && companyStore.item.id) {
      this.loadFresh(companyStore);
    }
  }

  changeActiveKey = (activeKey) => {
    this.setState({
      activeKey,
      // colSize: this.tabSizes[activeKey] || { lg: 18, xl: 14, xxl: 10 }
    });
  };
  renderForm() {
    const { TabPane } = Tabs;
    const { Option } = Select;
    const dataSource = [
      {
        title: 'Pamatdati',
        key: 'base',
        content: (
          <Row {...gridRow}>
            <Col {...gridCol2}>
              <Card title={false} bordered={false}>
                <Col {...gridCol2}>
                  <Form.Item label="Kods">
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...gridCol2}>
                  <Form.Item label="Līguma numurs">
                    <Input />
                  </Form.Item>
                </Col>
                <Form.Item label="Līguma priekšmets">
                  <Input.TextArea />
                </Form.Item>
                <Form.Item label="Otras puses līguma kods">
                  <Input />
                </Form.Item>
                <Form.Item label="Kam nodots">
                  <Input.TextArea />
                </Form.Item>
                <Form.Item label="Ar ko noslēgts">
                  <Input />
                </Form.Item>
                <Form.Item label="Pamatojums">
                  <Input />
                </Form.Item>
                <Form.Item label="Pamatojuma skaidrojums">
                  <Input.TextArea />
                </Form.Item>
                <Card title="Finanses">
                  <Form.Item label="Summa">
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item label="Valūta">
                    <Input />
                  </Form.Item>
                  <Form.Item label="PVN, %">
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item label="PVN papildus">
                    <Input type="checkbox" />
                  </Form.Item>
                  <Form.Item label="Summa bez PVN">
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item label="Summa ar PVN">
                    <Input type="number" />
                  </Form.Item>
                </Card>
              </Card>
            </Col>
            <Col {...gridCol2}>
              <Card title="Datumi un termiņi">
                <Form.Item label="Līguma datums">
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Līguma termiņš">
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Pagarināt par">
                  <Input type="number" addonAfter="mēnešiem" />
                </Form.Item>
                <Form.Item label="Pagarināšanas reižu skaits">
                  <Input type="number" addonAfter="reizes" />
                </Form.Item>
                <Form.Item label={false}>
                  <Button disabled className="moveRight">
                    Pagarināt
                  </Button>
                </Form.Item>
                <Form.Item label="Atbildīgais">
                  <Input />
                </Form.Item>
                <Form.Item label="Par termiņa beigām informēt">
                  <Input type="number" addonAfter="dienas iepriekš" />
                </Form.Item>

                <Form.Item label="Reģistrācijas datums">
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Līguma spēkā stāšanās datums">
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Termiņš ar vārdiem">
                  <Input.TextArea />
                </Form.Item>

                <Form.Item label="Pabeigšanas datums">
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Līgums pabeigts">
                  <Input type="checkbox" />
                </Form.Item>
              </Card>

              <br />
              <Form.Item label="Piezīmes">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        ),
      },
      {
        title: 'Biznesa plāns',
        key: 'businessPlan',
        content: (
          <Row {...gridRow}>
            <Col {...gridCol}>
              <Form.Item label="Nosaukums">
                <Input.TextArea />
              </Form.Item>
              <Row>
                <Col {...gridCol2}>
                  <Form.Item label="Līguma numurs">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Vienošanās numurs">
                    <Input />
                  </Form.Item>
                  <Form.Item label="BP spēkā stāšanās datums">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item label="Biznesa plāna termiņš no">
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col {...gridCol2}>
                  <Form.Item label="Līguma datums">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item label="Vienošanās datums">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item label="BP numurs">
                    <Input />
                  </Form.Item>
                  <Form.Item label="līdz">
                    <DatePicker />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Pamatdarbības virziens">
                <Input.TextArea />
              </Form.Item>
              <Form.Item label="Piezīmes">
                <Input.TextArea />
              </Form.Item>
              <Form.Item label="statuss">
                <Select defaultValue="ACTUAL">
                  <Option value="ACTUAL">aktuāls</Option>
                  <Option value="NOTACTUAL">neaktuāls</Option>
                </Select>
              </Form.Item>
              <Card title="Informācija">
                <Form.Item label="Pievienoja">
                  <Input />
                </Form.Item>
              </Card>
              {/* <b>r
                s
                d
                s
                d
                ds
                dsf
              </b> */}
            </Col>
          </Row>
        ),
      },
      {
        title: 'Investīcijas aktivitāte',
        key: 'activity',
        content: (
          <Row {...gridRow}>
            <Col {...gridCol}>
              <Form.Item label="Summa">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Valūta">
                <Select defaultValue="EUR">
                  <Option value="EUR">EUR</Option>
                  <Option value="USD">USD</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Termiņš">
                <DatePicker />
              </Form.Item>
              <Form.Item label="Aktivitātes veids">
                <Select defaultValue="BERTH">
                  <Option value="BERTH">Piestātnes</Option>
                  <Option value="ADMINISTRATIVE">Administratīvās ēkas un būves</Option>
                  <Option value="MACHINERY">Terminālu tehnika, iekārtas un mašīnas</Option>
                  <Option value="ENG_STRUCT_COMUNICATIONS">Inženierbūves un komunikācijas</Option>
                  <Option value="TERR_IMPROVEMENT">Teritorijas labiekārtošana</Option>
                  <Option value="LICENCES_PROGRAMMS">Licences un programmas</Option>
                  <Option value="IND_BUILDINGS">Ražošanas ēkas un būves</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Neaktuāls">
                <Input type="checkbox" />
              </Form.Item>
              <Card style={{ backgroundColor: '#ccc' }}>
                <Tabs type="card" className="content-tabs">
                  <TabPane tab="Kontrole" key="controll">
                    <Card title="Kontroles statuss">
                      <Radio.Group defaultValue="a">
                        <Radio.Button value="a">Nav saņemta atskaite</Radio.Button>
                        <Radio.Button value="b">Ir atskaitē - investēts daļēji</Radio.Button>
                        <Radio.Button value="c">Ir atskaitē - investēts pilnā apmērā</Radio.Button>
                      </Radio.Group>
                    </Card>
                    <Form.Item label="Investīcijas apjoms">
                      <Input type="number" value={10} />
                    </Form.Item>
                    <Form.Item label="Valūta">
                      <Select defaultValue="EUR">
                        <Option value="EUR">EUR</Option>
                        <Option value="USD">USD</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Ir akts">
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item label="Kontroles piezīmes">
                      <Input.TextArea />
                    </Form.Item>
                    <Card title="Kontroles vēsture">
                      <p>...</p>
                      <p>...</p>
                    </Card>
                  </TabPane>
                  <TabPane tab="Apsekošana" key="survey">
                    <Card title="Apsekošanas statuss">
                      <Radio.Group defaultValue="a">
                        <Radio.Button value="a">Nav apsekots</Radio.Button>
                        <Radio.Button value="b">Apsekots - investēts daļēji</Radio.Button>
                        <Radio.Button value="c">Apsekots - investēts pilnā apmērā</Radio.Button>
                      </Radio.Group>
                    </Card>
                    <Form.Item label="Finanšu kontroles summa">
                      <Input type="number" value={10} />
                    </Form.Item>
                    <Form.Item label="Valūta">
                      <Select defaultValue="EUR">
                        <Option value="EUR">EUR</Option>
                        <Option value="USD">USD</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Apsekošanas piezīmes">
                      <Input.TextArea />
                    </Form.Item>
                    <Card title="Apsekošanas vēsture">
                      <p>...</p>
                      <p>...</p>
                    </Card>
                  </TabPane>
                  <TabPane tab="Informācija" key="Information">
                    <Form.Item label="Piezīmes">
                      <Input.TextArea autoSize={{ minRows: 9, maxRows: 9 }} />
                    </Form.Item>
                    <Card title="Informācija">
                      <Form.Item label="Pievienoja">
                        <Input />
                      </Form.Item>
                      <Form.Item label="Datums">
                        <DatePicker />
                      </Form.Item>
                      <Form.Item label="Numurs">
                        <Input />
                      </Form.Item>
                    </Card>
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        ),
      },
      {
        title: 'Uzstādījumi (dok.)',
        key: 'settings',
        content: (
          <>
            <Row {...gridRow}>
              <Col {...gridCol2}>
                <Card title="Izveidotājs">
                  <Form.Item label="Vārds">
                    <Input />
                  </Form.Item>
                  <Form.Item label="no">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item label="līdz">
                    <DatePicker />
                  </Form.Item>
                </Card>
              </Col>
              <Col {...gridCol2}>
                <Card title="Modifikators">
                  <Form.Item label="Vārds">
                    <Input />
                  </Form.Item>
                  <Form.Item label="no">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item label="līdz">
                    <DatePicker />
                  </Form.Item>
                </Card>
              </Col>
            </Row>
            <br />
            <Row {...gridRow}>
              <Col {...gridCol2}>
                <Card title="Īpašnieks">
                  <Form.Item label="Vārds">
                    <Input />
                  </Form.Item>
                </Card>
              </Col>
              <Col {...gridCol2}>
                <Card title="uz medija">
                  <Form.Item label="Vārds">
                    <Input />
                  </Form.Item>
                </Card>
              </Col>
            </Row>
            <br />
            <Row {...gridRow}>
              <Col {...gridCol2}>
                <Card title="Uzstādījumi">
                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="" /> arhivēts
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="plus" /> arhivējams
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="lock" /> rezervēts (mans)
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="lock-open" /> ārējs
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="cabinet-filing" /> reģistrā
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="asterisk" /> atsauces kopija
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="circle" type="solid" /> nearhivējams
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="file" /> bez lapām
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="user-lock" /> rezervēts (citu lietotāju)
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="link" /> saistīts dokuments
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="times-circle" /> nav reģistrā
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <FontAwesomeIcon icon="copy" /> ir versijas
                        </>
                      }
                    >
                      <Input type="checkbox" />
                    </Form.Item>
                  </Col>
                </Card>
              </Col>
            </Row>
          </>
        ),
      },
    ];
    const tabPanes = dataSource.map((e, i) => {
      const last = dataSource.length === i + 1;
      return (
        <TabPane tab={e.title} key={e.key} forceRender>
          <h2>{e.title}</h2>
          {e.content}
          <Button.Group className="moveRight">
            <Button
              className="styledButton previousButton"
              disabled={i === 0}
              onClick={() => this.changeActiveKey(dataSource[i - 1].key)}
            >
              Atpakaļ
            </Button>
            <Button
              className="styledButton nextButton"
              disabled={last}
              onClick={() => this.changeActiveKey(dataSource[i + 1].key)}
            >
              Uz priekšu
            </Button>
          </Button.Group>
        </TabPane>
      );
    });
    return (
      <>
        <br />
        <Tabs
          type="card"
          className="content-tabs"
          activeKey={this.state.activeKey}
          onChange={(key) => this.changeActiveKey(key)}
        >
          {tabPanes}
        </Tabs>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'BusinessPlan' })(BusinessPlan));
