import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Modal, Radio } from 'antd';
import { toJS, reaction, runInAction } from 'mobx';
import StoreFactory from '../../stores';
import DataTable from '../../DataTable';

class DataTableComponent extends React.Component {
  actions = [];
  filterOptions = {};
  parentId = null;

  constructor(props) {
    super(props);

    const { formKind, match, async } = props;

    if (formKind && !async) {
      formKind.isLocal = true;
    }

    if (match && match.params) {
      const { id } = match.params;
      const parsedId = parseInt(id, 10);
      if (Number.isFinite(parsedId)) {
        this.parentId = parsedId;
      }
    }
    this.state = { modal: false };
  }

  componentDidMount() {
    const { value, async, optionValueKey, columnRenderMethods, forcedFiltersOptions, forcedFilters } = this.props;
    const store = this.getStore();

    this._is_mounted = true;
    if (!async) {
      store.loadingOne = false;
      store._data = value || [];
    } else {
      store.loading = false;
    }

    if (columnRenderMethods && store) {
      store.columns.map((column) => {
        if (columnRenderMethods[column.key]) {
          column.render = columnRenderMethods[column.key];
        }
        return column;
      });
    }

    if (forcedFiltersOptions && forcedFiltersOptions.options) {
      const { columnKey, defaultOptionValue, options } = forcedFiltersOptions;
      this.filterOptions[columnKey] = defaultOptionValue;
      if (!async) {
        store.sysFilter({ [columnKey]: [defaultOptionValue] });
      }
      this.actions.push(
        <Radio.Group
          key="filter"
          className="table-ffradio-actions"
          defaultValue={this.filterOptions[columnKey]}
          onChange={(e) => {
            this.filterOptions[columnKey] = e.target.value;
            if (!async || (async && this.parentId)) {
              store.sysFilter({ ...store.systemFilters, ...{ [columnKey]: [e.target.value] } });
            }
          }}
        >
          {options.map((option) => (
            <Radio key={option.key} value={option.key}>
              {option.value}
            </Radio>
          ))}
        </Radio.Group>
      );
    }

    if (forcedFilters) {
      for (const fKey of Object.keys(forcedFilters)) {
        this.filterOptions[fKey] = forcedFilters[fKey];
      }
    }

    if (async && optionValueKey && this.parentId) {
      store.systemFilters = { ...this.filterOptions, ...{ [optionValueKey]: [this.parentId], fMode: 'strict' } };
      store.paginate(1);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ value: newValue }) {
    const { value: oldValue, async } = this.props;
    if (!async) {
      if (newValue !== oldValue) {
        this.store.updateCause = 'fromProps';
        if (newValue) {
          this.store._data = newValue.map((row) => ({
            ...row,
            key: row.id ? row.id : Math.random(),
          }));
        } else {
          this.store._data = [];
        }
      }
    }
  }

  componentWillUnmount() {
    this._is_mounted = false;
    if (typeof this.disposer === 'function') {
      this.disposer();
    }
  }

  getStore = () => {
    if (this.store) {
      return this.store;
    }

    const { id, async, optionSource, name, onChange, formInstance, customize } = this.props;
    let store = null;
    if (async) {
      store = StoreFactory.getStore(optionSource, id, false, id);
      store.isAsyncLocal = true;
    } else {
      store = StoreFactory.getStore(id);
      store.isLocal = true;
    }

    if (customize) {
      customize(store);
    }

    if (typeof this.disposer === 'function') {
      this.disposer();
    }
    this.disposer = reaction(
      () => toJS(store._data, { recurseEverything: true }),
      () => {
        if (!this._is_mounted) {
          return;
        }

        // async skip?
        const newData = toJS(store._data, { recurseEverything: true });
        const newValue = newData.map((row) => ({ ...row, key: undefined }));
        if (store.updateCause !== 'value') {
          if (!store.updateCause && !this.changed) {
            formInstance.setFieldsValue({ [name]: newValue });
          } else if (this.changed) {
            onChange(newValue);
          }
        }
        this.changed = false;
        store.updateCause = undefined;
      }
    );
    this.store = store;
    return store;
  };

  handleRowClick = (_row) => {
    const { props, store } = this;
    const { formKind: FormKind } = props;

    let row;
    if (_row.key) {
      row = toJS(
        store._data.find((el) => el.key === _row.key),
        { recurseEverything: true }
      );
    } else {
      row = _row;
    }
    if (FormKind) {
      this.setState({ modal: true, data: row });
      runInAction(() => {
        store.item = row;
      });
    }
  };

  render() {
    const { props, store, ref } = this;
    const { formKind: FormKind, modalProps, extraItemProps, async } = props;
    const { modal, data, loading, dataId } = this.state;
    let rowOnClick = props.rowOnClick
      ? (_row) => {
          props.rowOnClick(_row, this.handleRowClick);
        }
      : this.handleRowClick;

    const extraFormProps = {};
    if (async && ref) {
      ref.forceDisableEdit = true;
    }

    if (async) {
      extraFormProps.match = { params: { id: dataId } };
      rowOnClick = (_row) => {
        this.setState({ modal: true, dataId: _row.id }, () => {
          if (ref) {
            ref.load(ref.store);
          } else {
            this.loadScheduled = true;
          }
        });
      };
    }

    return store ? (
      <div>
        <DataTable
          actions={this.actions}
          store={store}
          onRowClick={rowOnClick}
          onAdd={
            async || props.disabled
              ? undefined
              : (row, b) => {
                  if (FormKind) {
                    store.item = {};
                    this.setState({ modal: true, data: {} });
                  }
                }
          }
          {...props}
          {...extraItemProps}
        />
        <Modal
          {...modalProps}
          centered
          closable={false}
          maskClosable={false}
          visible={modal}
          onOk={() => this.setState({ modal: false })}
          onCancel={() => this.setState({ modal: false })}
          footer={[
            !!data &&
              !!data.key &&
              ref &&
              !ref.forceDisableEdit &&
              (!ref.canDelete || (ref.canDelete && ref.canDelete())) && (
                <Button
                  key="delete"
                  type="danger"
                  onClick={() => {
                    this.setState({ modal: false });
                    this.changed = true;
                    store._data = store._data.filter((row) => row.key && row.key !== data.key);
                  }}
                >
                  Dzēst
                </Button>
              ),
            <Button key="back" onClick={() => this.setState({ modal: false })}>
              Aizvērt
            </Button>,
            ref && !ref.forceDisableEdit && (!ref.canSave || (ref.canSave && ref.canSave())) && (
              <Button
                key="submit"
                type="primary"
                disabled={loading}
                loading={loading}
                onDoubleClick={(e) => e.stopPropagation()}
                onClick={async (e) => {
                  if (ref && !this.submitting) {
                    this.submitting = true;
                    this.changed = true;
                    try {
                      store.item = await this.ref.handleLeanSubmit(e);
                    } catch (err) {
                      // console.error(err);
                      setTimeout(() => {
                        this.submitting = false;
                      }, 1000);
                      return null;
                    }
                    if (data.key) {
                      store._data = store._data.map((row) =>
                        row.key === data.key
                          ? { ...toJS(store.item, { recurseEverything: true }), ...this.filterOptions }
                          : row
                      );
                    } else {
                      const item = {
                        key: Math.random(),
                        ...toJS(store.item, { recurseEverything: true }),
                        ...this.filterOptions,
                      };
                      store._data.push(item);
                    }
                    this.setState({ modal: false }, () => {
                      setTimeout(() => {
                        this.submitting = false;
                      }, 1000);
                    });
                  }
                  return true;
                }}
              >
                Saglabāt
              </Button>
            ),
          ]}
        >
          {FormKind &&
            (async ? (
              <FormKind
                store={store}
                wrappedComponentRef={(_ref) => {
                  if (this.loadScheduled) {
                    _ref.load(_ref.store);
                    this.loadScheduled = false;
                  }
                  this.ref = _ref;
                }}
                {...extraFormProps}
              />
            ) : (
              <FormKind
                local
                // disabled={async}
                wrappedComponentRef={(_ref) => {
                  this.ref = _ref;
                  if (_ref && _ref.props && _ref.props.form) {
                    this.formRef = _ref.props.form;
                    this.formRef.resetFields();
                    if (_ref) {
                      const item = { ...toJS(store.item, { recurseEverything: true }) };
                      if (!Object.keys(item).length) {
                        for (const col of store.definedColumns) {
                          item[col.key] = col.type && col.type === 'table' ? [] : null;
                        }
                      }
                      setTimeout(() => this.ref.setFields(item, store));
                    }
                    if (_ref && !this.refTimeout) {
                      this.refTimeout = setTimeout(() => {
                        clearTimeout(this.refTimeout);
                        this.forceUpdate();
                      });
                    }
                  } else {
                    this.formRef = undefined;
                  }
                }}
                parentProps={{ ...props }}
                store={store}
                {...extraFormProps}
              />
            ))}
        </Modal>
      </div>
    ) : (
      <div />
    );
  }
}

export default withRouter(DataTableComponent);
