import React, {useState,useEffect} from 'react';
import { Spin, Icon, Form, Tooltip, Button, Input, Tag, Select } from 'antd';
import { toJS } from 'mobx';
import { FontAwesomeIcon } from '@ois-lib';
import StoreFactory from '../stores';
import Addon from '../addons';
import { options } from 'less';
const { Option } = Select;

const formItemLayouts = {
  inline: {
    labelCol: { order: 1 },
    wrapperCol: { order: 2 },
  },
  horizontal: {
    labelCol: { span: 24 },
    wrapperCol: {
      lg: { span: 20 },
      xs: { span: 24 },
      sm: { span: 24 },
    },
  },
  'horizontal-wide': {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  },
  vertical: {},
};

const Spinner = () => <Spin indicator={<Icon type="loading" spin />} size="small" />;

const findValue = (key, obj, defaultValue = null) => {
  let currentItem = obj;
  for (const currentKey of key.split('.')) {
    if (typeof currentItem === 'object' && currentItem && currentKey in currentItem) {
      currentItem = currentItem[currentKey];
    } else {
      return defaultValue;
    }
  }
  return currentItem;
};


const defaultFilters = {
  filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters, showButtons = true }) {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(null);

    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await (typeof this.options === 'function' ? this.options() : this.options);
        const arrayResult = Array.isArray(result) ? result : [];

        if (arrayResult.length > 0) {
          setOptions(arrayResult);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (this.options) {
        fetchData();
      } else {
        setOptions(null);
      }
    }, [this.options]);

    const renderDropdownContent = () => {
      if (loading) {
        return <span>Loading options...</span>;
      } else if (options && options.length > 0) {
        return (
            <Select
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                showSearch
                value={selectedKeys[0]}
                onChange={(value) => setSelectedKeys(value ? [value] : [])}
            >
              {options.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.value}
                  </Select.Option>
              ))}
            </Select>
        );
      } else {
        return (
            <Input
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm(selectedKeys)}
                placeholder="Filtrēt"
                allowClear={!showButtons}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
        );
      }
    };

    const renderButtons = () => (
        <>
          <Button
              type="primary"
              onClick={() => confirm(selectedKeys)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
          >
            Atlasīt
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Noņemt
          </Button>
        </>
    );

    return (
        <div style={{ padding: 8 }}>
          {renderDropdownContent()}
          {showButtons && renderButtons()}
        </div>
    );
  },

  filterIcon(filtered) {
    return <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />;
  },
};

class Field {
  static components = {};
  static get(code) {
    const component = Field.components[code];
    return component || Field.components.text;
  }
  static make(col, ...rest) {
    if (col && !col.type) {
      col.type = 'text';
    }
    return col ? new (Field.get(col.type))(col, ...rest) : {};
  }
  static register(target) {
    Field.components[target.code] = target;
  }

  getCode() {
    return this.code;
  }

  valueKey = 'value';
  sorter = true;
  isRequired = false;
  pushingTempIds = [];
  refreshOptions = null;

  constructor(col, dataSet) {
    this.dataSet = dataSet;
    this.baseExtraFieldOptions = this.getExtraFieldOptions(col);
    this.fieldLabel = this.getFormLabel(col);
    this.cellRender = this.getCellRenderer(col);
    this.dataIndex = col.key;
    this.sortDirections = ['descend', 'ascend'];
    this.render = this.cellRender;

    if (col.rules) {
      this.isRequired = col.rules.reduce((curr, acc) => {
        if (!curr && acc.required) {
          return acc.required;
        }
        return curr;
      }, false);
    }

    Object.assign(this, col);

    if (col.optionSource && StoreFactory) {
      this.optionStore = StoreFactory.getStore(this.optionSource, 'options');
      this.optionStore.chachedRecordsById = StoreFactory.getCache(this.optionSource);
      this.optionStore.loadIfEmpty();
    }
    if (col.filter === undefined) {
      if (Array.isArray(col.options)) {
        this.filters = col.options
            .sort((a, b) => {
              if (typeof a.value === 'string') {
                return a.value.localeCompare(b.value, 'lv', { ignorePunctuation: true }) * 1;
              }
              return (a.value > b.value ? 1 : -1) * 1;
            })
            .map((o) => ({ text: o.value, value: o.key }));
        if (col.selectSearch) {
          // Object.assign(this, col);
          // defaultFilters.options = col.options;
          Object.assign(defaultFilters, col.selectSearch);
          Object.assign(this, defaultFilters);

        }
      } else if (!this.filterDropdown && this.filter !== false) {
        Object.assign(this, defaultFilters);
      }
    }

    if (typeof col.computed === 'function') {
      this.render = (value, record, index, useComputed = col.computed) => {
        const computedValue = useComputed ? useComputed(value, undefined, record) : value;
        if (typeof col.render === 'function') {
          return col.render(computedValue, record, index);
        }
        return computedValue;
      };
    }

    if (col.columnRenderMethod && typeof this[col.columnRenderMethod] === 'function') {
      const comutedFunc = typeof col.computed === 'function' ? col.computed : null;
      this.render = (value, record, index, useComputed = comutedFunc) => {
        const computedValue = useComputed ? useComputed(value, undefined, record) : value;
        return this[col.columnRenderMethod](computedValue, record, index);
      };
    }

    this.fieldContext = React.createContext(`${col}.${dataSet}`);
  }

  refreshWithParams(params) {
    this.refreshOptions = params;
  }

  getSingleOptionFromStore(id, instanceName = 'options', row = {}) {
    if (!StoreFactory) {
      return [];
    }

    if (!this.optionStore || (this.optionStore && `${instanceName}` !== this.optionStore._instanceName)) {
      this.optionStore = StoreFactory.getStore(this.optionSource, instanceName);
      if (this.optionFilters) {
        this.optionStore.systemFilters = this.optionFilters;
      }
    }

    if (this.optionKeyName) {
      this.optionStore.optionKeyName = this.optionKeyName;
    }
    if (this.optionValueName) {
      this.optionStore.optionValueName = this.optionValueName;
    }

    let additionalFilters = {};
    if (this.dynamicOptionFilters) {
      additionalFilters = { ...this.dynamicOptionFilters(row) };
    }

    if (id && !this.pushingTempIds.includes(id)) {
      this.pushingTempIds.push(id);
      this.optionStore.pushToOptions(id, additionalFilters);
      // console.log('AAA: ', this.optionStore.getAsOptions, toJS(this.optionStore._dataFull));
    }


    return this.optionStore.getAsOptions;
  }

  // Bulked options
  /**
   *
   * setTimeout(); <-- clearTimeout while loading same options then request with colelcted Id's
   *
   */
  getOptionsFromStore(params = null, instanceName = 'options') {
    if (!StoreFactory) {
      return [];
    }

    if (!this.lrtCachedStore) {
      this.lrtCachedStore = StoreFactory.getStore(this.dataSet, instanceName);
    }
    if (this.optionFilters) {
      this.lrtCachedStore.systemFilters = this.optionFilters;
    }
    if (!this.lastReqTime) {
      this.lastReqTime = Date.now();
    }
    if (!this.optionStore || (this.optionStore && instanceName !== this.optionStore._instanceName)) {
      this.optionStore = StoreFactory.getStore(this.optionSource, instanceName);
      if (this.optionFilters) {
        this.optionStore.systemFilters = this.optionFilters;
      }
      this.optionStore.loadIfEmpty(params);
    }

    if (this.refreshOptions) {
      if (this.optionStore) {
        this.optionStore.refreshFull(this.refreshOptions);
      }
      this.refreshOptions = undefined;
    }

    if (this.optionKeyName) {
      this.optionStore.optionKeyName = this.optionKeyName;
    }
    if (this.optionValueName) {
      this.optionStore.optionValueName = this.optionValueName;
    }

    return this.optionStore.getAsOptions;
  }

  // loadMissingOptions() {
  //   if (!this.optionStore) {
  //     return;
  //   }
  //   this.getSingleOptionFromStore(this.activeOptionValue, '_options_normalizer');
  //   // console.log('aaa');
  // }

  getSelectedOption() {
    if (!this.formInstance) {
      return null;
    }
    const tmpOptions = this.getOptionsFromStore();
    const currentValue = this.formInstance.getFieldValue(this.key);
    const selectedOption = tmpOptions.filter((option) => option.key === currentValue);
    return selectedOption.length ? selectedOption[0] : null;
  }

  async s_getSelectedOption(id, callback = null) {
    const option = await this.optionStore?.loadOne(id);
    if (callback) callback({ ...option });

    return { ...option };
  }

  getExtraFieldOptions(col) {
    const extraFieldOptions = col.extraFieldOptions ? col.extraFieldOptions : {};
    if (col.number) {
      const { customNormalize } = extraFieldOptions;
      extraFieldOptions.normalize = (value, prevValue, other) => {
        if (typeof value === 'string') {
          value = value
              .replace(/\.|,/g, ',')
              .replace(/[,]+/g, ',')
              .replace(/[^\d,]/g, '')
              .replace(/^,/g, '');
          if (col.integer) {
            value = value.replace(/\.|,/g, '');
          }
        }
        if (typeof customNormalize === 'function') {
          return customNormalize(value, prevValue, other);
        }
        return value;
      };
    }

    if (typeof col.computed === 'function') {
      extraFieldOptions.normalize = (value, prevValue, other) => {
        const val = col.computed.bind(this)(value, prevValue, other);
        if (this.validateAfterComuted && this.editForm.store) {
          if (this.validateAfterComutedTimer) {
            clearTimeout(this.validateAfterComutedTimer);
          }
          const formVal = this.editForm.props.form.getFieldValue(col.key);

          // if (formVal) {
          //   formVal = this.editForm.props.form.getFieldValue(col.key).replace(/,|,,/g, '.').replace(/\s/g, '');
          // }
          // let xVal = val;
          // if (val) {
          //   xVal = val.replace(/,|,,/g, '.').replace(/\s/g, '');
          // }
          if (val !== formVal) {
            this.validateAfterComutedTimer = setTimeout(() => {
              this.editForm.props.form.validateFields([col.key]);
            });
          }
        }

        return val;
      };
    }
    return extraFieldOptions;
  }

  getFormLabel(col) {
    return col.tooltip ? (
        <span>
        {col.title}
          &nbsp;
          <Tooltip title={col.tooltip}>
          <Icon type="question-circle-o" />
        </Tooltip>
      </span>
    ) : (
        col.title
    );
  }

  getCellRenderer() {
    return this.render ? this.render : (v) => v;
  }

  compareValues(left, right) {
    if (this.number) {
      right = parseFloat(right);
      left = parseFloat(left);
    }
    if (this.type === 'table') {
      if (Array.isArray(left) && Array.isArray(right)) {
        const isNotQueal = JSON.stringify(left) !== JSON.stringify(right);
        return isNotQueal;
      }
    }

    return left !== right;
  }

  loadRowTransform(row, displayAsTags = false) {
    const col = this;
    const { number, key } = col;
    const textValue = row && row[`_t_${key}`];
    let value = null;
    if (key.includes('.')) {
      value = row && findValue(key, row);
    } else {
      value = row && row[key];
    }

    const isLazy = ['companies', 'companyRepresentatives', 'ports', 'shipVisits', 'ships', 'crossUsers', 'tugboatVisits'].includes(col.optionSource);
    if (textValue !== undefined && textValue !== null) {
      return textValue;
    }

    // TODO:
    // ! bug when opening existing ship visit and then opening new card (select fields loading infinitely)
    // console.log('col.key: ', col.key);
    // if (col.key === 'shipAgentId') {
    //   console.log('value: ', value);
    // }

    let options = [];
    if (Array.isArray(col.options)|| col.optionSource) {
      if (isLazy && value) {
        options = col.optionSource ? this.getSingleOptionFromStore(value, '_options_normalizer', row) : col.options;
      } else {
        if (!isLazy) {
          let additionalFilters = {};
          if (col.dynamicOptionFilters) {
            additionalFilters = { ...col.dynamicOptionFilters(row) };
          }

          const combinedOptionFilters = { ...col.optionFilters, ...additionalFilters };
          options = col.optionSource ? this.getOptionsFromStore(combinedOptionFilters, '_options_normalizer') : col.options;
          if (
              col.optionSource &&
              this.optionStore &&
              !this.optionStore.loading &&
              value && !options.find(o => o.key === value)
          ) {
            options = col.optionSource ? this.getSingleOptionFromStore(value, '_options_normalizer', row) : col.options;
          }
          // if (col.key === 'shipTypeId') {
          //   console.log('value: ', value, col.optionSource, options);
          // }
        } else {
          options = col.options;
        }
      }

      if (value && Array.isArray(value)) {
        const out = [];
        if (options) {
          for (const akey of value) {
            const cand = options.find((option) => option.key == akey);
            const candValue = cand && (cand[this.valueKey] || cand['name']);
            if (candValue) {
              out.push(candValue);
            }
          }
        }

        if (displayAsTags) {
          value = out.map((t, k) => <Tag key={k}>{t}</Tag>);
        } else {
          value = out.join(', ');
        }
      } else {


        const cand = options && options.find((option) => option.key == value);
        const candValue = cand && (cand[this.valueKey] || cand['name']);
        if (candValue) {
          value = candValue;
        }
      }
    }

    if (isLazy) {
      if (col.optionSource && (!this.optionStore || (this.optionStore && this.optionStore.loading))) {
        return value ? <Spinner /> : '';
      }
    } else if (col.optionSource && (!this.optionStore || (this.optionStore && this.optionStore.loadingFull))) {

      return value ? <Spinner /> : '';
    }

    // if (col.optionSource && (!this.optionStore || (this.optionStore && this.optionStore.loadingFull))) {
    //   return value ? <Spinner /> : '';
    // }

    if (number) {
      if (value) {
        if (typeof value !== 'string') {
          value += '';
        }
        value = value.replace(/\./g, ',');
      }
    }

    // if (col.key.endsWith('shipTypeId')) {
    //   console.log('value: ', value, col.optionSource);
    // }

    return value;
  }

  loadFormTransform(item, value = item[this.key]) {
    if (this.noComponent) {
      return undefined;
    }
    if (this.number) {
      if (typeof value !== 'string') {
        value += '';
      }
      value = value.replace(/\./g, ',');
    }
    return value;
  }

  saveFormTransform(values, out = {}) {
    const col = this.key;
    if (this.computed || typeof values[col] === 'undefined') {
      return out;
    }

    if (this.number && typeof values[col] === 'string') {
      out[col] = values[col].replace(/,/g, '.').replace(/\s/g, '');
    } else if (typeof super.saveFormTransform !== 'function') {
      out[col] = values[col];
    }

    if (values[col] && this.optionSource && this.options && this.options.length) {
      const options = toJS(this.options, { recurseEverything: true });
      const option = options.find((o) => o.key == values[col]);
      if (option && option.value) {
        out[`_t_${col}`] = option.value;
      }
    }
    return out;
  }

  renderWarningIcon(props) {
    return (
        <Tooltip
            title={
              <>
                <strong>Iepriekšējā vērtība: </strong>
                <span style={{ textDecoration: 'line-through' }}>{props.previousValue || '(tukšs lauks)'}</span>
              </>
            }
        >
          &nbsp;
          <FontAwesomeIcon icon="engine-warning" type="solid" style={{ color: 'orange', cursor: 'pointer' }} />
        </Tooltip>
    );
  }

  renderFormItem(allProps) {
    const col = this;
    const {
      getFieldProps,
      getFieldValue,
      altOptionProps,
      usePlaceholderAsLabel,
      canEdit,
      layout,
      formId,
      formItemProps,
      formInstance,
      fieldDiff,
      editForm,
      traceFieldChange,
      ...props
    } = allProps;
    let _formItemProps = formItemProps;

    if (editForm) {
      this.editForm = editForm;
    }

    if (altOptionProps) {
      if (altOptionProps.optionSource) {
        this.optionSource = altOptionProps.optionSource;
      }
      if (altOptionProps.optionKeyName) {
        this.optionKeyName = altOptionProps.optionKeyName;
      }
      if (altOptionProps.optionValueName) {
        this.optionValueName = altOptionProps.optionValueName;
      }
    }

    col.label = !canEdit;
    if (!col.label) {
      col.label = col.asText || props.asText;
    }

    let rules = !col.label && col.rules ? col.rules : [];

    if (!col.label) {
      if (props.rules) {
        rules = rules.concat(props.rules);
      }

      // Possibly need to limit this for specific validation types
      const fieldValue = getFieldValue(col.key);
      rules = rules.map((r) => {
        if (!r.setTouched) {
          r.setTouched = (value = true) => {
            r.touched = value;
          };
        }

        r.previousValue = fieldValue;
        if (!r.formId || r.formId !== formId) {
          r.formId = formId;
          r.touched = undefined;
          r.initialValue = undefined;
        }

        if (!r.touched) {
          if (typeof r.initialValue === 'undefined') {
            r.initialValue = fieldValue;
          }
        }
        return r;
      });
    }

    if (!col.traceFieldChange && traceFieldChange) {
      col.traceFieldChange = traceFieldChange;
      if (!col.extraFieldOptions) {
        col.extraFieldOptions = {};
      }
      if (!col.extraFieldOptions.onChange) {
        col.extraFieldOptions.onChange = function () {
          const [value, abc] = arguments;
          col.traceFieldChange(col.key, value, formInstance);
          return arguments[0];
        };
      }
    } else {
      col.traceFieldChange = traceFieldChange;
    }

    const fieldProps = getFieldProps(col.key, {
      rules,
      validateTrigger: typeof col.validateTrigger !== 'undefined' ? col.validateTrigger : undefined,
      ...this.baseExtraFieldOptions,
      ...col.extraFieldOptions,
      normalize: (value, previousValue, allValues) => {
        if (col.extraFieldOptions && 'normilize' in col.extraFieldOptions) {
          value = col.extraFieldOptions.normalize(value, previousValue, allValues);
        } else if (this.baseExtraFieldOptions && 'normalize' in this.baseExtraFieldOptions) {
          value = this.baseExtraFieldOptions.normalize(value, previousValue, allValues);
        }

        if (col.slug && col.type === 'text' && value !== previousValue) {
          value = value
              // .toLowerCase()
              .replace(/ /g, '-')
              .replace(/[^\w-]+/g, '');
        }

        return value;
      },
    });

    col.formInstance = formInstance;
    if (fieldProps.ref) {
      this.fieldRef = fieldProps.ref;
      delete fieldProps.ref;
    }

    if (!this.addons && col.fieldAddons && Array.isArray(col.fieldAddons)) {
      this.addons = col.fieldAddons.map((addon, key) => {
        if (addon.type) {
          return Addon.make(addon.type, {
            key,
            fieldProps,
            addonFormStore: addon.formStore
                ? StoreFactory.getStore(addon.formStore, 'addons')
                : StoreFactory.getStore(`${this.optionSource}_${addon.optionSource}`, 'addons'),
            addonStore: StoreFactory.getStore(addon.optionSource),
            addonSource: addon.optionSource,
            fieldSource: this.optionSource,
            options: addon,
          });
        }
        return 'Invalid addon type';
      });
    }

    switch (layout) {
      case 'inline':
      case 'horizontal':
      case 'horizontal-wide':
      case 'vertical':
        _formItemProps = {
          ..._formItemProps,
          ...formItemLayouts[layout],
          className: `layout-${layout} ${(_formItemProps && _formItemProps.className) || ''}`,
        };
        break;
      default:
    }

    if (_formItemProps && _formItemProps.className) {
      _formItemProps.className += ` item-type-${col.type || 'text'}`;
    } else {
      _formItemProps = {
        ..._formItemProps,
        className: ` item-type-${col.type || 'text'}`,
      };
    }

    if (col.type === 'richtextarea') {
      _formItemProps.className += ' clear-styles';
    }

    let tailFormItemLayout = {};
    if (col.labelRight && ['checkbox', 'colorpicker'].includes(col.type)) {
      tailFormItemLayout =
          layout !== 'horizontal-wide'
              ? {
                wrapperCol: {
                  xs: {
                    span: 24,
                    offset: 0,
                  },
                  sm: {
                    span: 16,
                    offset: 8,
                  },
                },
              }
              : {
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              };
    }

    if (allProps.hidden) {
      return '';
    }

    if (usePlaceholderAsLabel) {
      fieldProps.placeholder = fieldProps.placeholder || col.title;
    }

    const formItemLabel =
        !usePlaceholderAsLabel && !Object.keys(tailFormItemLayout).length && this.getFormLabel({ ...col, ...props });

    col.warningIconProps = {
      ...props,
      ...fieldProps,
      ...col.extraFieldProps,
      previousValue: fieldDiff[col.key],
    };
    const showWarningIcon = col.key in fieldDiff && col.compareValues(fieldProps.value, fieldDiff[col.key]);
    const warningIcon = showWarningIcon && col.renderWarningIcon(col.warningIconProps);

    col.showWarningIcon = !!showWarningIcon;

    const FieldContext = this.fieldContext;

    return (
        <FieldContext.Provider
            value={{
              col,
              fieldProps: {
                ...props,
                ...fieldProps,
                ...col.extraFieldProps,
              },
            }}
        >
          <Form.Item
              // eslint-disable-next-line react/jsx-curly-newline
              label={
                !formItemLabel ? undefined : (
                    <>
                      {formItemLabel}
                      {warningIcon}
                    </>
                )
                // eslint-disable-next-line react/jsx-curly-newline
              }
              // validateStatus="warning"
              {...col.extraItemProps}
              {..._formItemProps}
              {...tailFormItemLayout}
              {...allProps.customItemProps}
          >
            {col.renderComponent({
              ...props,
              ...fieldProps,
              ...col.extraFieldProps,
            })}
          </Form.Item>
        </FieldContext.Provider>
    );
  }
}

export { Field, Spinner };
export default Field;
