import React from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import { Field } from './Field';

const TextareaObserver = observer(({initialValue, ...props }) => {
    const textareaProps = initialValue !== undefined && initialValue !== ''
        ? { value: initialValue }
        : {};

    return (
        <Input.TextArea {...props} {...textareaProps} />
    );
});

@Field.register
class TextareaComponent extends Field {
  static code = 'textarea';
  renderComponent({ asText, ...props }) {
    if (this.label || props.asText) {
      return <span style={{ whiteSpace: 'pre-line' }}>{props.value}</span>;
    }
      return <TextareaObserver {...props} />;
  }
}

export default TextareaComponent;
