import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol, gridRow, FormItem } from '@ois-lib';

@inject('appStore', 'authorize', 'can')
@observer
class EditPassenger extends EditFormBase {
  title = 'Pasažieris';
  static storeName = 'passengers';

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    super.UNSAFE_componentWillReceiveProps(props);
    const { store } = this;
    // eslint-disable-next-line eqeqeq
    if (props && props.match && this.props && this.props.match && props.match.params.id != this.props.match.params.id) {
      this.load(store, true, props);
    }
  }

  canSave() {
    return false;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.passengers', 'edit ship_visits.passengers']);

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Pasažieris" bordered={false}>
            <FormItem name="nr" />
            <FormItem name="firstName" />
            <FormItem name="lastName" />
            <FormItem name="countryId" />
            <FormItem name="birthDate" />
            <FormItem name="birthPlace" />
            {/* <FormItem name="birthCountryId" /> */}
            <FormItem name="gender" />
            <FormItem name="transit" />
            <FormItem name="originPortId" />
            <FormItem name="destinationPortId" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditPassenger' })(EditPassenger);
