import React, { useState } from 'react';
import { Popover } from 'antd';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';

const FilesPopover = ({ title, placement, content, children }) => {
  const [visible, setVisible] = useState(0);
  let timeoutCounter;
  return (
    <div
      aria-hidden
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={() => clearTimeout(timeoutCounter)}
      onMouseLeave={() => {
        timeoutCounter = setTimeout(() => setVisible(false), 600);
      }}
    >
      <Popover
        title={null}
        className="files-popover-wrapper"
        placement={placement || 'bottomRight'}
        onClick={() => setVisible(!visible)}
        visible={!!visible || false}
        content={
          <div aria-hidden className="files-popover-content" onClick={(e) => e.stopPropagation()}>
            <div className="popover-content-header">
              <span>{title || ''}</span>
              &nbsp;
              <FontAwesomeIcon icon="times" onClick={() => setVisible(false)} />
            </div>
            <div className="files-popover-content-wrapper">{content}</div>
          </div>
        }
        trigger="click"
      >
        {children}
      </Popover>
    </div>
  );
};

export default FilesPopover;
