/* eslint-disable no-async-promise-executor */
/* eslint-disable curly */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import { checkVAT, countries } from 'jsvat';
import * as moment from 'moment';
import { ibanLen } from './static';
import StoreFactory from './stores';
import {getIdFromUri, getFullUri} from './helpers/url';
const passwordRegex = {
  lc: new RegExp('(?=.*[a-z])'),
  uc: new RegExp('(?=.*[A-Z])'),
  nc: new RegExp('(?=.*[0-9])'),
  sc: new RegExp('(?=.*[!@#$%^&*])'),
};

const intMax = 2147483647;
const floatMax = 10 ** (12 - 2) - 10 ** -2;

/**
 *
 * Documentation & examples can be found here:
 *  - https://github.com/yiminghe/async-validator
 *
 */
const Validate = {
  Required: {
    required: true,
    // whitespace: true,
    message: 'Šis lauks ir obligāti aizpildāms!',
    validator(rule, value, callback) {
      if (value) {
        value = !!`${value}`.trim();
      }
      return callback(!value && value !== 0 ? rule.message : undefined);
    },
  },
  Checked: {
    required: true,
    validator(_rule, value, callback) {
      return value === true ? callback() : callback('Šis lauks ir obliģats!');
    },
  },
  Email: { type: 'email', message: 'Lūdzu, ievadiet derīgu e-pasta adresi' },
  Password: {
    validator(rule, value, callback) {
      value = value ? `${value}` : '';
      if (value) {
        if (value.length < 8) {
          return callback('Parolei jāsastāv no vismaz 8 simboliem.');
        }

        for (const key in passwordRegex) {
          if (!passwordRegex[key].test(value)) {
            let message = '';
            switch (key) {
              case 'lc':
                message = 'mazo latīņu burtu';
                break;
              case 'uc':
                message = 'lielo latīņu burtu';
                break;
              case 'nc':
                message = 'ciparu';
                break;
              case 'sc':
                message = 'speciālo simbolu'; // special symbols - (!@#$%^&*)
                break;
              default:
            }
            return callback(`Parolē jāiekļauj vismaz 1 ${message}.`);
          }
          continue;
        }
      }

      return callback();
    },
  },
  VAT: {
    message: 'Nederīgs PVN numurs.',
    validator(_rule, value, callback) {
      if (!value) {
        return callback();
      }
      return callback(checkVAT(value, countries).isValid ? undefined : true);
    },
  },
  RegNr: {
    meesage: 'Nederīgs reģistrācijas numurs.',
    validator(_rule, value, callback) {
      if (!value) {
        return callback();
      }
      return callback(value.length === 11 ? undefined : true);
    },
  },
  RegNrDuplicate: {
    message: 'Reģistrācijas numurs jau eksistē datu bāzē.',
    validator(_rule, value, callback) {
      const id = getIdFromUri(getFullUri());

      fetch(`/api/v1/checkDuplicateRegNr?regNr=${value}&id=${id}`)
        .then(response => response.json())
        .then(data => {
            if (data.exists) {
                return callback('Reģistrācijas numurs jau eksistē datu bāzē.');
            } else {
                return callback();
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            return callback('Error fetching data');
        });
    }
  },
  MmsiDuplicate: {
    message: 'MMSI jau eksistē datu bāzē.',
    validator(_rule, value, callback) {
        const id = getIdFromUri(getFullUri());

        fetch(`/api/v1/checkDuplicateMMSI?mmsi=${value}&id=${id}`)
            .then(response => response.json())
            .then(data => {
                if (data.exists) {
                    return callback('MMSI jau eksistē datu bāzē.');
                } else {
                    return callback();
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                return callback('Error fetching data');
            });
    },
  },
  IMODuplicate: {
    message: 'IMO jau eksistē datu bāzē.',
    validator(_rule, value, callback) {
        const id = getIdFromUri(getFullUri());

        fetch(`/api/v1/checkDuplicateIMO?imo=${value}&id=${id}`)
            .then(response => response.json())
            .then(data => {
                if (data.exists) {
                    return callback('IMO jau eksistē datu bāzē.');
                } else {
                    return callback();
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                return callback('Error fetching data');
            });
    },
  },
  CodeDuplicate: {
      message: 'Kods jau eksistē datu bāzē.',
      validator(_rule, value, callback) {
          const id = getIdFromUri(getFullUri());

          fetch(`/api/v1/checkDuplicateCode?code=${value}&id=${id}`)
              .then(response => response.json())
              .then(data => {
                  if (data.exists) {
                      return callback('Kods jau eksistē datu bāzē.');
                  } else {
                      return callback();
                  }
              })
              .catch(error => {
                  console.error('Error fetching data:', error);
                  return callback('Error fetching data');
              });
      },
  },
  DateNotInFuture: {
    meesage: 'Datums un laiks nevar būt nākotnē!',
    validator(_rule, value, callback) {
      if (value > moment().add(1, 'hours'))
        return callback("Datums un laiks nevar būt nākotnē!");
      else return callback();
    },
  },
  DefaultFileValidator: {
    message:
      'Ierakstu nav iespējams saglabāt, jo visi faili nav augšupielādēti vai arī augšupielāde notikusi kļūdaini.',
    validator(_rule, value, callback, valueKey, imutable) {
      if (!value || !value.length) {
        return callback();
      }
      return new Promise(async (resolve, reject) => {
        if (imutable.timeoutClock) {
          clearTimeout(imutable.timeoutClock);
        }

        imutable.timeoutClock = setTimeout(() => {
          const filesWithError = value.find((file) => ['error', 'uploading'].includes(file.status));
          if (filesWithError) {
            return reject(callback(true));
          }
          return resolve(callback());
        }, 100);
      });
    },
  },
  IBAN: {
    validator(_rule, iban, callback) {
      if (!iban || !iban.length) {
        return callback();
      }
      iban = iban.replace(/\s/g, '');
      const countryCode = iban.substr(0, 2);
      const requiredLength = ibanLen[countryCode];
      if (!requiredLength) {
        return callback();
      } // Not an IBAN
      if (!iban.match(/^[\dA-Z]+$/)) {
        return callback('IBAN konta numurā var būt tikai burti un cipari');
      }
      const len = iban.length;
      if (len !== ibanLen[iban.substr(0, 2)]) {
        return callback(
          `IBAN konta numurā jābūt ${requiredLength} ${requiredLength % 10 === 1 ? 'simbolam' : 'simboliem'}`
        );
      }
      iban = iban.substr(4) + iban.substr(0, 4);
      const checksum = iban.substr(-2);
      for (var s = '', i = 0; i < len; i += 1) {
        s += parseInt(iban.charAt(i), 36);
      }
      s = `${s.substr(0, s.length - 2)}00`;
      // eslint-disable-next-line no-redeclare
      for (var m = s.substr(0, 15) % 97, s = s.substr(15); s; s = s.substr(13)) m = (m + s.substr(0, 13)) % 97;
      let correctChecksum = 98 - m;
      correctChecksum = correctChecksum < 10 ? `0${correctChecksum}` : `${correctChecksum}`;
      return callback(correctChecksum === checksum ? undefined : 'Kļūdains IBAN');
    },
  },
  Number: {
    validator(rule, value, callback) {
      if (typeof value === 'number') {
        return callback();
      }
      if (!value || !value.length) {
        return callback();
      }
      const prefix = rule.messagePrefix ? `${rule.messagePrefix} ` : '';
      if (value.includes(',,')) {
        value = value.replace(',,', ',');
      }
      if (typeof value === 'string') {
        value = value.replace(/,|,,/g, '.').replace(/\s/g, '');
      }
      const cand = Number(value);
      const isNan = Number.isNaN(cand);
      if (isNan) {
        return callback(`${prefix}Lūdzu ievadiet skaitli`);
      }

      if (!rule.max || rule.max > intMax) {
        rule.max = intMax;
      }

      if (
        (typeof rule.min !== 'undefined' && cand < rule.min) ||
        (typeof rule.max !== 'undefined' && cand > rule.max)
      ) {
        return callback(
          `${prefix}Lūdzu, ievadiet skaitli ${[
            typeof rule.min !== 'undefined' ? `ne mazāku par ${rule.min}` : '',
            typeof rule.max !== 'undefined' ? `ne lielāku par ${rule.max}` : '',
          ]
            .filter((f) => f)
            .join(' un ')}`
        );
      }
      return callback();
    },
  },
  Imo: {
    validator: (rule = {}, value, callback) => {
      if (!value || !value.length) {
        return callback();
      }
      if (value.length === 7 && parseInt(value)) {
        var chars = value.split("");
        let checkSum = 0;
        let index = 7;
        let checkChar = chars.pop();
        chars.forEach((e) => {
          checkSum += index * parseInt(e);
          index--;
        });
        let checkSumString = checkSum.toString();
        let checkSymbol = checkSumString.slice(-1);
        console.log(3);
        if (checkSymbol == checkChar) {
          return callback();
        }
        return callback("Nederīgs IMO numurs");
      }

      return callback("Nederīgs IMO numurs");

      //   if (strlen($imo) == 7 && is_numeric($imo)) {
      //     $chars = str_split($imo);
      //     $checkSum = 0;
      //     $index = 7;
      //     $checkChar = array_pop($chars);
      //     foreach ($chars as $char) {
      //         $checkSum += $index * (int)$char;
      //         $index--;
      //     }
      //     $checkSymbol = substr((string)$checkSum, -1);
      //     if ($checkSymbol == $checkChar) {
      //         return true;
      //     }
      // }
      // return false;
    },
    validateStatus: 'warning',
    hasFeedback: true
  },
  Mmsi: {
    validator: (rule = {}, value, callback) => {
      if (!value || !value.length) {
        return callback();
      }
      if (value.length === 9 && parseInt(value)) {
        return callback();
      }

      return callback("Nederīgs MMSI numurs");

      //   if (strlen($imo) == 7 && is_numeric($imo)) {
      //     $chars = str_split($imo);
      //     $checkSum = 0;
      //     $index = 7;
      //     $checkChar = array_pop($chars);
      //     foreach ($chars as $char) {
      //         $checkSum += $index * (int)$char;
      //         $index--;
      //     }
      //     $checkSymbol = substr((string)$checkSum, -1);
      //     if ($checkSymbol == $checkChar) {
      //         return true;
      //     }
      // }
      // return false;
    },
    validateStatus: 'warning',
    hasFeedback: true
  },
  Decimal: {
    type: 'float',
    // transform: value => value,
    validator: (rule = {}, value, callback) => {
      if (!value || !value.length) {
        return callback();
      }

      if (!rule.max || rule.max > floatMax) {
        rule.max = floatMax;
      }

      if (typeof value === 'number') {
        if (
          (typeof rule.min !== 'undefined' && parseFloat(Number(value)) < rule.min) ||
          (typeof rule.max !== 'undefined' && parseFloat(Number(value)) > rule.max)
        ) {
          return callback(
            `Lūdzu, ievadiet skaitli ${[
              typeof rule.min !== 'undefined' ? `ne mazāku par ${rule.min}` : '',
              typeof rule.max !== 'undefined' ? `ne lielāku par ${rule.max}` : '',
            ]
              .filter((f) => f)
              .join(' un ')}`
          );
        }
        return callback();
      }

      if (value.includes(',,')) {
        value = value.replace(',,', ',');
      }
      if (typeof value === 'string') {
        value = value.replace(/,|,,/g, '.').replace(/\s/g, '');
      }

      if (
        (typeof rule.min !== 'undefined' && parseFloat(Number(value)) < rule.min) ||
        (typeof rule.max !== 'undefined' && parseFloat(Number(value)) > rule.max)
      ) {
        return callback(
          `Lūdzu, ievadiet skaitli ${[
            typeof rule.min !== 'undefined' ? `ne mazāku par ${rule.min}` : '',
            typeof rule.max !== 'undefined' ? `ne lielāku par ${rule.max}` : '',
          ]
            .filter((f) => f)
            .join(' un ')}`
        );
      }

      if (typeof value === 'string') {
        const regexp = /^\d{0,10}(.\d{1,5})?$/;
        if (!regexp.test(value)) {
          return callback('Lūdzu, ievadiet derīgu skaitlisko vienību');
        }
      }

      return callback();
    },
    validateStatus: 'warn',
  },
  AsyncFetch: {
    rules: {
      unique: (data) => {
        return !data.length;
      },
    },
    store: null,
    type: 'api',
    async validator(_rules, value, callback, valueKey, imutable) {
      return new Promise(async (resolve, reject) => {
        // if(prevVal !== newVal) clearTimeout/setTimeout & call.this.validator(..params); // ???
        if (!this.touched) {
          this.setTouched(true);
        }
        // clearTimeout --> setTimeout || with search options
        if (value !== this.initialValue) {
          let store = null;
          if (this.optionSource && !imutable.store) {
            if (StoreFactory) {
              store = StoreFactory.getStore(this.optionSource);
            }
            imutable.store = store;
          } else if (imutable.store) {
            store = imutable.store;
          }

          if (this.rule && store) {
            switch (this.rule) {
              case 'unique':
                const res = await store.pullAsync('checkExists', { f: JSON.stringify({ [this.field]: [value] }) });
                if (res) {
                  if (res.statusMessage) {
                    reject(callback(res.statusMessage));
                  }
                  if (res.data) {
                    reject(callback('Ieraksts ar šādu vērtību jau eksistē'));
                  }
                }
                break;
              default:
            }
          }
        }

        resolve(callback());
      });
    },
  },
  FuelCodeDuplicate: {
    message: 'Kods jau eksistē datu bāzē.',
    validator(_rule, value, callback) {
      const id = getIdFromUri(getFullUri());

      fetch(`/api/v1/fuelCheckDuplicateCode?code=${value}&id=${id}`)
        .then(response => response.json())
        .then(data => {
          if (data.exists) {
            return callback('Kods jau eksistē datu bāzē.');
          } else {
            return callback();
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          return callback('Error fetching data');
        });
    },
  },
  FuelOrderDuplicate: {
    message: 'Secība sarakstā jau eksistē datu bāzē.',
    validator(_rule, value, callback) {
      const id = getIdFromUri(getFullUri());

      fetch(`/api/v1/fuelCheckDuplicateOrder?order=${value}&id=${id}`)
        .then(response => response.json())
        .then(data => {
          if (data.exists) {
            return callback('Secība sarakstā jau eksistē datu bāzē.');
          } else {
            return callback();
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          return callback('Error fetching data');
        });
    },
  },
};
export default Validate;
