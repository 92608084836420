import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import EditBerthVisitRequest from './EditBerthVisitRequest';

@inject('berthVisitRequestStore', 'authorize', 'can')
@observer
class BerthVisitRequests extends React.Component {
  static Edit = EditBerthVisitRequest;

  title = 'Piestātņu rezervācijas';

  @observable view = 'table';

  componentDidMount() {
    const { berthVisitRequestStore } = this.props;
    berthVisitRequestStore.keepOriginalValues = ['status'];
    berthVisitRequestStore.load();
  }

  render() {
    const { isWidget, berthVisitRequestStore, history, settingsOptions, settingsButtons } = this.props;

    const DataTableWidget = (props) => (
      <DataTable
        {...props}
        title={this.title}
        calendar={!isWidget}
        calendarProps={{
          itemTitle: 'Piestātnes rezervācija',
          startTimeCol: 'etaBerth',
          endTimeCol: 'etaTerminal',
        }}
        store={berthVisitRequestStore}
        onRowClick={(row) => history.push(`/planning/berth_visit_requests/${row.id || ''}`)}
        onAdd={() => history.push('/planning/berth_visit_requests/new')}
      />
    );

    return isWidget ? (
      <DataTableWidget additionalSettingsOptions={settingsOptions} additionalSettingsButtons={settingsButtons} />
    ) : (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>
            <DataTableWidget />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(BerthVisitRequests);
