import React from 'react';
import { Input, Button, Table, Row, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import moment from 'moment';
import { gridRow, gridCol, FontAwesomeIcon } from '@ois-lib';

@inject('authStore')
@observer
class OverviewSettingsList extends React.Component {
  @observable savedSettings = [];
  @observable settingsLoading = false;
  @observable settingsName = '';

  constructor(props) {
    super(props);
    if (props.authStore && props.authStore.api) {
      this.api = props.authStore.api;
    }
  }

  componentDidMount() {
    this.loadSettings();
  }

  @action.bound setSettingsName(name) {
    this.settingsName = name;
  }

  @action.bound async createSettings(kind = 'private') {
    const {
      store: { activeSection, flexSize },
    } = this.props;
    const setting = {
      name: toJS(this.settingsName, { recurseEverything: true }),
      object: 'overview',
      kind,
      settings: {
        ...toJS({ activeSection, flexSize }, { recurseEverything: true }),
      },
    };
    this.settingsName = '';
    const saveResult = await this.api.ui_settings(setting);
    this.loadSettings();
    return saveResult;
  }

  @action.bound async loadSettings() {
    const res = await this.api['ui_settings?object=overview']();
    this.savedSettings =
      res &&
      res.map &&
      res.map((setting) => ({
        ...setting,
        settings: JSON.parse(setting.settings),
      }));
  }

  @action.bound async activateSettings(settingsRow) {
    if (settingsRow.settings) {
      const { store } = this.props;
      if (store) {
        if (settingsRow.settings.flexSize) {
          store.flexSize = toJS(settingsRow.settings.flexSize, {
            recurseEverything: true,
          });
        }

        if (settingsRow.settings.activeSection) {
          store.activeSection = toJS(settingsRow.settings.activeSection, { recurseEverything: true });
        }
      }
      return true;
    }
    return false;
  }

  @action.bound async deleteSettings(settings) {
    const res = await this.api[`ui_settings/${settings.id}`](undefined, 'DELETE');
    this.loadSettings();
    return res;
  }

  render() {
    let id = null;
    const { authStore, store } = this.props;
    if (authStore && authStore.user && authStore.user.id) {
      id = authStore.user.id;
    }

    if (id) {
      const settingsColumns = [
        {
          key: 'name',
          dataIndex: 'name',
          title: 'Nosaukums',
          render: (val, row) => {
            return (
              <span
                aria-hidden
                className="a"
                onClick={
                  () =>
                    this.activateSettings(row).then((res) => {
                      if (res && store) {
                        store.handleSubmit();
                      }
                    })
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                {val}
              </span>
            );
          },
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Izveidots',
          render: (val) => moment(val).format('L LT'),
        },
        {
          key: 'delete',
          title: '',
          render: (val, row) => {
            return (
              <span aria-hidden className="a" onClick={() => this.deleteSettings(row)}>
                Dzēst
              </span>
            );
          },
        },
      ];
      const settingsData = this.savedSettings
        ? toJS(this.savedSettings, { recurseEverything: true }).filter((setting) => setting.userId === id)
        : [];

      return (
        <div className="table-settings-list">
          <h3 style={{ fontWeight: 600 }}>Bloku iestatījumi</h3>
          <Row {...gridRow} style={{ paddingTop: '1em' }}>
            <Col {...gridCol}>
              <Table
                style={{ overflow: 'auto' }}
                dataSource={settingsData}
                columns={settingsColumns}
                rowKey="id"
                size="small"
                pagination={false}
                rowClassName={(el) => (el.className ? el.className : null)}
              />
            </Col>
          </Row>
          <Row {...gridRow} style={{ paddingTop: '1em' }}>
            <Col {...gridCol}>
              <Row type="flex" justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: 10 }}>
                  <Input
                    value={this.settingsName}
                    onKeyUp={(e) => {
                      if (e.which === 13) {
                        this.createSettings();
                      }
                    }}
                    onChange={(e) => this.setSettingsName(e.target.value)}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'center' }}>
                  <Button type="primary" disabled={!this.settingsName} onClick={() => this.createSettings()}>
                    <FontAwesomeIcon icon="file-medical" />
                    <span>Saglabāt tabulas skatu</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
    return '';
  }
}

export default OverviewSettingsList;
