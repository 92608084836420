import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol, gridRow, FormItem, FontAwesomeIcon } from '@ois-lib';
import EditCalculationRowDetail from './EditCalculationRowDetail';

@inject('appStore', 'authorize', 'can')
@observer
class EditCalculationRow extends EditFormBase {
  title = 'Kalkulācijas vienība';
  storeName = 'store';

  get forceDisableEdit() {
    return this.props.parentProps && !!this.props.parentProps.disabled;
  }

  canSave() {
    return false;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view calculations', 'edit calculations']);

    const disableField = this.store && 'created_by' in this.store.item ? !this.store.item.created_by : false;

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Kalkulācija" bordered={false}>
            <FormItem name="calculationTariffId" disabled={disableField} />
            <FormItem name="description" />
            <FormItem name="ownable" />
            <FormItem
              name="details"
              disabled={this.forceDisableEdit}
              formKind={EditCalculationRowDetail}
              addButtonLabel="Pievienot korekciju"
              addButtonIcon={<FontAwesomeIcon icon="file-edit" className="btn-icon" />}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditCalculationRow' })(EditCalculationRow);
