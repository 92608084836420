import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol } from '@ois-lib';

@inject('appStore', 'authorize', 'can')
@observer
class EditShipDocument extends EditFormBase {
  title = 'Dokuments';
  storeName = 'store';

  canSave() {
    return true;
    // return this.props.can('edit ship_visits.documents');
  }

  canDelete() {
    return true;
    // return this.props.can('delete ship_visits.documents');
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.documents', 'edit ship_visits.documents']);

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Dokuments" bordered={false}>
            <FormItem name="shipDocumentTypeId" />
            <FormItem name="issueDate" />
            <FormItem name="issueLocation" />
            <FormItem name="validUntil" />
            <FormItem asText readonly disabled name="verifiedDate" />
            <FormItem asText readonly disabled name="verified" />
            <FormItem asText name="verifiedByName" />
            <FormItem name="comment" />
            <FormItem name="files" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditShipDocument' })(EditShipDocument);
