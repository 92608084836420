import React from 'react';
import { Checkbox } from 'antd';
import moment from 'moment-timezone';
import { Field } from './Field';

@Field.register
class CheckboxComponent extends Field {
  static code = 'checkbox';

  extraFieldOptions = {
    valuePropName: 'checked',
  };
  filterDropdown = this.filter !== false ? this.filterDropdown : undefined;
  filterIcon = this.filter !== false ? this.filterIcon : undefined;
  optionsMap = [
    {
      text: 'Jā',
      value: true,
    },
    {
      text: 'Nē',
      value: false,
    },
  ];

  get filters() {
    if (this.filter !== false) {
      return this.optionsMap;
    }
    return undefined;
  }

  loadRowTransform(row) {
    const { key } = this;
    return row[key] === true || row[key] === 't' ? 'Jā' : 'Nē';
  }

  loadFormTransform(item) {
    return super.loadFormTransform(item, !!item[this.key]);
  }

  saveFormTransform(values, out = {}) {
    const col = this.key;
    if (this.type === 'date' && values[col]) {
      out[col] = moment(values[col]).format();
    } else if (this.type === 'boolean_multiselect') {
      this.options.forEach((option) => {
        out[option.key] = !!values[col].includes(option.key);
      });
    } else if (this.computed) {
      delete out[col];
    } else if (this.number && typeof values[col] === 'string') {
      out[col] = values[col].replace(/,/g, '.').replace(/\s/g, '');
    } else {
      out[col] = values[col];
    }
    return out;
  }

  renderComponent(props) {
    if (this.label || props.asText) {
      const value = this.optionsMap.find((o) => !!o.value === !!props.checked).text;
      if (this.labelRight) {
        return `[${value}] ${this.getFormLabel(this, props)}`;
      }
      return value;
    }

    return (
      <Checkbox {...props}>
        {this.labelRight && (
          <span className={this.isRequired ? 'ant-form-item-required' : ''}>{this.getFormLabel(this, props)}</span>
        )}
      </Checkbox>
    );
  }
}

export default CheckboxComponent;
