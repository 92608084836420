import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, FormItem, gridRow } from '@ois-lib';

@inject('appStore', 'authStore', 'oksUserStore')
@observer
class EditUser extends EditFormBase {
  title = 'Lietotāja profils';
  storeName = 'oksUserStore';
  baseUrl = '/';
  colSize = { lg: 24, xl: 18, xxl: 12 };

  constructor(props) {
    super(props);
    const {
      oksUserStore,
      authStore: { user },
    } = props;

    oksUserStore.item.id = user.id;
  }

  async componentDidMount() {
    const { oksUserStore } = this.props;
    this.loadFresh(oksUserStore);
  }

  canSave() {
    const { authStore } = this.props;
    return authStore && authStore.user && authStore.user.id;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    const item = this.props.form.getFieldsValue();
    const { form } = this.props;

    return (
      <>
        <Row {...gridRow}>
          <Col {...this.colSize}>
            <h1 className="cabinet_title">{`${this.title}: ${(item && item.shortName) || item.name || ''}`}</h1>
          </Col>
        </Row>

        <Row {...gridRow}>
          <Col {...this.colSize}>
            <Card title="Pamatinformācija par lietotāju " bordered={false}>
              <FormItem name="firstName" />
              <FormItem name="lastName" />
              <FormItem name="email" />
              <FormItem name="phone" />
              <FormItem name="passwordHash" autoComplete="new-password" />
              <FormItem
                name="passwordHash2"
                rules={[
                  {
                    message: 'Paroles nesakrīt!',
                    validator(_rule, value, callback) {
                      const value2 = form.getFieldValue('passwordHash');
                      if ((value2 || value) && value !== value2) {
                        callback(true);
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              />
              <FormItem name="position" />
              <FormItem name="status" disabled />
              <FormItem name="isCompanyAdmin" disabled />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditUser' })(EditUser));
