import React from 'react';
import { Modal, Button } from 'antd';
import { inject, observer } from 'mobx-react';

@inject('authStore')
@observer
class SessionExpirationWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      okPressed: false,
    };
  }

  onCancel = () => {
    const { authStore } = this.props;
    authStore.logout();
  };

  onOk = () => {
    this.setState({ okPressed: true });
    const {
      authStore: { api, setSessionRemaining },
    } = this.props;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const rt = await api.refreshToken(true);
      if (rt) {
        const sessionRemaining = Math.floor((api.getExp() - Date.now()) / 1000);
        setSessionRemaining(sessionRemaining);
      }
      this.setState({ okPressed: false });
      return resolve(true);
    }).catch((error) => {
      this.setState({ okPressed: false });
      console.error(error);
    });
  };

  render() {
    const {
      authStore: { sessionWarningVisible, sessionRemainingTxt },
    } = this.props;
    const { okPressed } = this.state;

    return (
      <Modal
        centered
        closable={false}
        maskClosable={false}
        visible={!!sessionWarningVisible}
        onOk={this.onOk}
        onCancel={this.onCancel}
        okText="Turpināt"
        cancelText="Beigt darbu"
        confirmLoading={okPressed}
      >
        <div className="center">
          <h3 style={{ fontSize: '23px', textAlign: 'center' }}>Jūsu sesija beigsies pēc</h3>
          <h1 style={{ fontSize: '36px', textAlign: 'center' }}>{sessionRemainingTxt}</h1>
        </div>
      </Modal>
    );
  }
}

export default SessionExpirationWarning;
