import React from 'react';
import { Upload, Button, Icon, message } from 'antd';
import api from '../services/api';
import { Field } from './Field';
import FilesRenderer from '../components/FilesRenderer';

const FilesListComponent = ({ value = [], asText, onChange, ...props }) => {
  const url = value.length ? value[0].url : '';
  const { preview } = props;
  return (
    <>
      {!asText && (
        <Upload
          action={`${api.base}blobs`}
          headers={{ Authorization: api.headers.Authorization }}
          onChange={({ file, fileList }) => {
            const validImage = ['image/png', 'image/jpeg', 'image/bmp', 'image/gif'];
            const isImage = validImage.includes(file.type);
            if (!isImage) {
              message.error(`${file.name} nav png, jpeg, bmp vai gif datne`);
              return;
            }

            if (file.status === 'done' && file.response) {
              fileList
                .filter((f) => file.uid === f.uid)
                .forEach((f) => {
                  if (f.response && f.response.data && f.response.data.id) {
                    f.blobId = f.response.data.id;
                    f.guid = f.response.data.guid;
                    f.url = `/api/v1/download/${f.guid || ''}`;
                  }
                });
              fileList = fileList.filter((f) => f.url);
              if (fileList.length > 1) {
                fileList.shift();
              }
            } else if (fileList.length > 1) {
              fileList.shift();
            }
            if (onChange) {
              onChange([...new Set([...fileList])]);
            }
          }}
          showUploadList={false}
        >
          <Button>
            <Icon type="upload" />
            Izvēlieties {value.length ? 'citu' : ''} bildi
          </Button>
        </Upload>
      )}
      <br />
      {!!url && !!preview ? <img src={url} alt="bilde" style={preview} /> : ''}
    </>
  );
};

@Field.register
class PictureComponent extends Field {
  static code = 'picture';
  constructor(col) {
    super(col);
    this.render = FilesRenderer;
  }
  renderComponent(props) {
    if (props.ref) {
      delete props.ref;
    }
    if (this.label || props.asText) {
      props.asText = true;
    }
    return <FilesListComponent {...props} />;
  }
}

export default PictureComponent;
