import React from 'react';

export default function FontAwesomeIcon({ icon, type, className, ...props }) {
  // PRO // regular - far, solid - fas, light - fal
  switch (type) {
    case 'static':
      type = 'fa';
      break;
    case 'regular':
      type = 'far';
      break;
    case 'solid':
      type = 'fas';
      break;
    case 'duo': // Duotone
      type = 'fad';
      break;
    default:
      type = 'fal';
  }
  return <i className={`${type} fa-${icon} ${className || ''}`} {...props} />;
}
