import React from 'react';
import { Row, Col, Modal, Tabs, Table, Button, Input, Checkbox } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { gridCol, gridRow, FontAwesomeIcon,  } from '.';

const TableSettingsList = observer(({ store, kind, onCancel }) => {
  const settingsColumns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nosaukums',
      render: (val, row) => {
        return (
          <span
            aria-hidden
            className="a"
            onClick={
              () =>
                store.activateSettings(row).then((res) => {
                  if (res && onCancel) {
                    onCancel();
                  }
                })
              // eslint-disable-next-line react/jsx-curly-newline
            }
          >
            {val}
          </span>
        );
      },
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Izveidots',
      render: (val) => moment(val).format('L LT'),
    },
    {
      key: 'delete',
      title: '',
      render: (_val, row) => {
        return (
          <span aria-hidden className="a" onClick={() => store.deleteSettings(row)}>
            Dzēst
          </span>
        );
      },
    },
  ];
  const settingsData = toJS(store.savedSettings, { recurseEverything: true }).filter(
    // eslint-disable-next-line no-bitwise
    (setting) => (kind === 'private') ^ !setting.userId
  );

  return (
    <div className="table-settings-list">
      <Row {...gridRow} style={{ paddingTop: '1em' }}>
        <Col {...gridCol}>
          <Row type="flex" justify="space-between">
            <Col xs={24} sm={13} md={13} lg={13}>
              <Input
                value={store.settingsName}
                onKeyUp={(e) => {
                  if (e.which === 13) {
                    store.createSettings(kind);
                  }
                }}
                onChange={(e) => store.setSettingsName(e.target.value)}
              />
            </Col>
            <Col xs={24} sm={11} md={11} lg={11} style={{ textAlign: 'right' }}>
              <Button type="primary" disabled={!store.settingsName} onClick={() => store.createSettings(kind)}>
                <FontAwesomeIcon icon="file-medical" />
                <span>Saglabāt tabulas skatu</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row {...gridRow} style={{ paddingTop: '1em' }}>
        <Col {...gridCol}>
          <Table
            style={{ overflow: 'auto' }}
            dataSource={settingsData}
            columns={settingsColumns}
            rowKey="id"
            size="small"
            pagination={false}
            rowClassName={(el) => (el.className ? el.className : null)}
            onRow={(record) => {
              const _that = this;
              return {
                onClick: () => {
                  if (_that && _that.props.onRowClick) {
                    _that.props.onRowClick(record);
                  }
                },
              };
            }}
          />
        </Col>
      </Row>
    </div>
  );
});

@observer
class TableSettings extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { store } = this.props;
    store.loadSettings();
  }

  getSortedColumns() {
    const { store } = this.props;
    return [...store.columns].sort((a, b) => {
        const orderA = store.columnOrders[a.key] || 0;
        const orderB = store.columnOrders[b.key] || 0;
        return orderA - orderB;
    });
  }
  render() {
    const { store, ...props } = this.props;

    if (!store.columnOrders || Object.keys(store.columnOrders).length === 0) {
        store.columns.forEach((col, index) => {
            const order = col.hasOwnProperty('columnOrder') ? col.columnOrder : index;
            store.setColumnOrder(col.key, order);
        });

        store.writeColState();
    }

    const sortedColumns = this.getSortedColumns();

    return (
      <Modal closable closeIcon={<FontAwesomeIcon icon="times" />} centered footer={null} {...props}>
        <Row>
          <h3 className="editable_title">Tabulas iestatījumi</h3>
        </Row>
        <Row>
          <Tabs type="card">
            <Tabs.TabPane tab="Tabulas kolonnas" key="1">
              <Button type="secondary" onClick={() => {
                store.resetColumnState();
                this.props?.onReset();
              }}>
                Atgriezt noklusētos kolonnu iestatījumus
              </Button>
              <Row {...gridRow} style={{ paddingTop: '1em' }}>
                <Col {...gridCol}>
                  {sortedColumns.map((col, i) => (
                      <div key={col.key}
                           draggable
                           onDragStart={(e) => this.handleDragStart(e, col)}
                           onDragOver={this.handleDragOver}
                           onDrop={(e) => this.handleDrop(e, col)}>
                          <Checkbox
                              checked={store.columnState[col.key] === undefined ? false : !!store.columnState[col.key]}
                              onChange={(e) => store.setColState(col.key, e.target.checked)}
                          >
                              {col.title}
                          </Checkbox>
                          <FontAwesomeIcon icon="grip-vertical" type="solid" style={{ opacity: 0.5 }}/>
                      </div>
                  ))}
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Mani skati" key="2">
              <TableSettingsList kind="private" store={store} onCancel={props.onCancel} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Sistēmas skati" key="3">
              <TableSettingsList kind="public" store={store} onCancel={props.onCancel} />
            </Tabs.TabPane>
          </Tabs>
        </Row>
      </Modal>
    );
  }
  handleDragStart = (e, col) => {
      e.dataTransfer.setData("text/plain", col.key);
  };
  handleDragOver = (e) => {
      e.preventDefault();
  };
  handleDrop = (e, targetCol) => {
      e.preventDefault();
      const draggedColKey = e.dataTransfer.getData("text/plain");

      if (draggedColKey !== targetCol.key) {
          const { store } = this.props;

          const draggedOrder = store.columnOrders[draggedColKey];
          const targetOrder = store.columnOrders[targetCol.key];

          store.setColumnOrder(draggedColKey, targetOrder);
          store.setColumnOrder(targetCol.key, draggedOrder);
      }
  };
}


export default TableSettings;
