import React from 'react';
import { inject, observer } from 'mobx-react';
import { Router, Switch, Route, Link } from 'react-router-dom';
import { Layout, Result, Spin, Icon, Button, ConfigProvider, Modal } from 'antd';
import 'moment/locale/lv';
import moment from 'moment-timezone';
import lvLV from 'antd/es/locale-provider/lv_LV';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Login, { ForgotPassword, ResetPassword } from './user/Login';
import Header from './layout/HeaderOKS';
import AppToolbar from './layout/AppToolbarOKS';
import Breadcrumb from './layout/Breadcrumb';
import CabinetSidebar from './layout/CabinetSidebar';
import ErrorBoundary from './ErrorBoundary';
import SessionExpirationWarning from './SessionExpirationWarning';
import '../assets/style/App.less';
import UserInfo from './user/UserInfo';
import StoreFactory from '@ois-lib/stores';
import { toJS } from 'mobx';
import ReactGA from 'react-ga';
import versionString from '../../version.json';
import '../assets/style/sidebar.css';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const NotFoundComponent = () => (
  <div className="notFound-block">
    <Result
      status="404"
      title="404"
      subTitle="Diemžēl, neviens ieraksts šajā saitē netika atrasts."
      extra={
        <Link to="/">
          <Button type="primary">Uz sākumu</Button>
        </Link>
      }
    />
  </div>
);

moment.defaultFormat = 'DD.MM.YYYY. HH:mm';

@inject('authStore', 'appStore')
@observer
class App extends React.Component {
  constructor(props) {
    super(props);
    props.appStore.authStoreReference = props.authStore;

    this.state = {
      modal: {
        resourceId: null,
        visible: false,
        open: (resourceId) => {
          const { modal } = this.state;
          modal.resourceId = resourceId || null;
          this.setState({ modal: { ...modal, visible: true } });
        },
        close: () => {
          const { modal, resourceId } = this.state;
          if (!['new', null].includes(resourceId)) {
            this.modalStore.refresh();
          }
          this.setState({ modal: { ...modal, resourceId: null, visible: false } });
        },
      },
      collapsed: false
    };
  }

  modalComponent = ({ id, modalForm: ModalForm, /* defaultFilters,*/ ...formProps }) => {
    if (ModalForm) {
      // if (ModalForm.storeName) {
      //   this.modalStore = StoreFactory.getStore(ModalForm.storeName, ModalForm.storeName + '_global');
      //   this._modalDefaultFilters = defaultFilters || { id: [-99999] };
      //   // this.modalStore.systemFilters = this._modalDefaultFilters;
      //   this.modalStore.load();
      // }
      // console.log('formProps: ', formProps);
      const newMatch = { params: { id: id || 'new' } };
      return <ModalForm {...formProps} match={newMatch} modal />;
    }
    return null;
  };

  renderRoutes(routes) {
    const { authStore, stores } = this.props;
    return routes.reduce((routeContainer, routeItem) => {
      const { title, path, component, componentProps, filter, children, exact, checkIfLogged, showIf } = routeItem;
      const additionalProps = {};

      if (path !== '/' && !path.startsWith('/notifications') && showIf && typeof showIf === 'function' && !showIf(stores)) {
        return routeContainer || [];
      }

      if (children && children.length) {
        for (const child of this.renderRoutes(children)) {
          routeContainer.push(child);
        }
      }

      if (path && component) {
        if (checkIfLogged) {
          additionalProps.loggedIn = authStore.loggedIn;
        }
        routeContainer.push(
          <PrivateRoute
            key={path || title}
            {...{ path, component, filter, title, exact, componentProps }}
            {...additionalProps}
          />
        );
      }
      return routeContainer || [];
    }, []);
  }

  formatVersionNumber = () => {
    let version = versionString;
    if (version !== "dev") {
      let versionArray = version.split(" ");
      versionArray[1] = moment(versionArray[1]).format('DD.MM.YYYY.HH.mm');
      version = (versionArray[0] + '.') + versionArray[1];
    }
    return version;
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return JSON.stringify(this.state) !== JSON.stringify(nextState);
  }

  render() {
    const { modalComponent: ModalFormComponent } = this;
    const { authStore, appStore, allowRegister, userMenu, langMenuComponent, extraRoutes, stores, oks } = this.props;
    let siderAdditionalProps = {};
    // this.setState({ collapsed: appStore.sidebarCollapsed}); // don't do that
    if (!appStore.windowWidth.desktop) {
      siderAdditionalProps = { collapsedWidth: '0' };
    }
    if (window) ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <ConfigProvider locale={lvLV}>
        <Router history={appStore.history}>
          <ErrorBoundary isRoot>
            <Layout className="app">
              <Header
                showCollectorDialog={appStore.showCollectorDialog}
                userMenu={userMenu}
                oks={oks}
                langMenuComponent={langMenuComponent}
              />
              {authStore.loading ? (
                <Layout.Content style={{ margin: '10% 0', textAlign: 'center' }}>
                  <Spin indicator={antIcon} size="large" />
                </Layout.Content>
              ) : (
                <Layout>
                  {ModalFormComponent && appStore.activeModal && (
                    <ModalFormComponent
                      id={appStore.modal.resourceId}
                      // closeModal={appStore._modals[appStore.activeModal].close}
                      modalForm={appStore.modal.form}
                      closeModal={appStore.modal.close}
                      visible={appStore.modal.visible}
                      {...toJS(appStore.modal.options)}
                      // fixedFormValues={fixedFormValues}
                    />
                  )}
                  {authStore.loggedIn && (
                    <>
                      <Layout.Sider
                        width="250"
                        breakpoint="lg"
                        {...siderAdditionalProps}
                        trigger={
                          <Icon
                            style={{ transform: appStore.sidebarCollapsed ? 'rotate(180deg)' : 'rotate(0deg)' }}
                            type="double-left"
                          />
                        }
                        collapsible
                        collapsed={appStore.sidebarCollapsed}
                        onCollapse={(collapsed) => {
                          appStore.setCollapse(collapsed);
                          this.setState({ collapsed: collapsed });
                        }}
                        style={!appStore.windowWidth.desktop ? {
                          top: 0,
                          left: 0,
                          bottom: 0,
                          zIndex: 999,
                          overflowY: `scroll`,
                          position: `fixed`,
                          margin: 0,
                        } : {}}
                      >
                        {
                          authStore.loggedIn && !appStore.windowWidth.desktop && (
                            <>
                              <UserInfo userMenu={userMenu} showCollectorDialog={appStore.showCollectorDialog} />
                              <AppToolbar showCollectorDialog={appStore.showCollectorDialog} />
                            </>
                          )
                        }
                        <CabinetSidebar showCollectorDialog={appStore.showCollectorDialog} stores={stores} />
                        <div
                          style={{
                            bottom: 0,
                            width: `100%`,
                            color: `white`,
                            fontWeight: `bolder`,
                            justifyContent: `center`,
                            margin: appStore.windowWidth.desktop ? undefined : `10px 0`,
                            display: appStore.sidebarCollapsed ? `none` : `inline-flex`,
                            position: appStore.windowWidth.desktop ? `absolute` : `relative`,
                          }}
                        >
                          {this.formatVersionNumber()}
                        </div>
                      </Layout.Sider>
                      <SessionExpirationWarning />
                    </>
                  )}
                  {
                    !appStore.sidebarCollapsed && !appStore.windowWidth.desktop && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 998,
                          opacity: 0.2,
                          position: `fixed`,
                          background: `gray`,
                        }}
                        onClick={() => {
                          appStore.setCollapse(true);
                          this.setState({ collapsed: true })
                        }}
                      />
                    )
                  }
                  <Layout.Content className="block-content">
                    <ErrorBoundary>
                      {/** TODO: Must hide breadcrumbs if section does not exist */}
                      {authStore.loggedIn && <Breadcrumb />}
                      {!authStore.loading && (
                        <>
                          {appStore.responseCode === 404 ? (
                            <NotFoundComponent />
                          ) : (
                            <>
                              <Switch>
                                <PublicRoute allowRegister={allowRegister} path="/login" component={Login} />
                                <PublicRoute path="/forgot" component={ForgotPassword} />
                                <PublicRoute path="/reset" component={ResetPassword} />
                                {extraRoutes}
                                {this.renderRoutes(appStore.routes)}
                                <Route path="*" exact component={NotFoundComponent} />
                              </Switch>
                            </>
                          )}
                        </>
                      )}
                    </ErrorBoundary>
                  </Layout.Content>
                </Layout>
              )}
            </Layout>
          </ErrorBoundary>
        </Router>
      </ConfigProvider>
    );
  }
}

export default App;
