import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridCol, gridRow, FormItem } from '@ois-lib';
import StoreFactory from '@ois-lib/stores';

@inject('helpContentStore', 'helpGroupStore', 'can', 'appStore')
@observer
class EditHelpContent extends EditFormBase {
  title = 'Palīga satura kartiņa';
  storeName = 'helpContentStore';
  baseUrl = '/help';

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const {
      [this.storeName]: store,
      form,
      match,
      history: { location },
    } = this.props;

    const { searchParams } = location;
    if (match.params.id) {
      const { sysGroup, group: helpGroupId } = searchParams;

      store.redirectTo = (item) => `/helpContent/${item.key}`;
      store.additionalData = { sysGroup };
      setTimeout(() => {
        store.colByName.helpGroupId.refreshWithParams({ f: JSON.stringify({ sysGroup }) });
      });
      this.load(store);

      if (helpGroupId) {
        const helpGroupStore = StoreFactory.getStore(`helpGroups`);
        const helpGroup = await helpGroupStore.loadOne(helpGroupId);
        setTimeout(() =>
          form.setFieldsValue({
            helpGroupId: match.params.id === 'new' ? helpGroup.id || null : store.item.helpGroupId,
            sysGroup: helpGroup.sysGroup || window.localStorage.helpSysGroup,
          })
        );
      } else {
        setTimeout(() =>
          form.setFieldsValue({
            sysGroup: sysGroup || window.localStorage.helpSysGroup,
          })
        );
      }
    }
  }

  canSave() {
    return this.props.can('edit help.helpContent');
  }

  canDelete() {
    return this.props.can('delete help.helpContent');
  }

  handleSysGroupChange = (value) => {
    const { [this.storeName]: store, form } = this.props;
    const field = 'helpGroupId';
    form.setFieldsValue({ [field]: null });
    switch (value) {
      case 'oks':
      case 'ois':
        store.colByName[field].refreshWithParams({ f: JSON.stringify({ sysGroup: value }) });
        break;
      default:
    }
  };

  renderForm() {
    return (
      <>
        <Row {...gridRow}>
          <Col {...gridCol}>
            <Card title="Palīga saturs" bordered className="mt15">
              <FormItem name="name" />
              <FormItem name="key" />
              <FormItem
                hidden={!this.props.can('edit help.helpContent')}
                onSelect={this.handleSysGroupChange}
                asText={!!window.localStorage.helpSysGroup}
                name="sysGroup"
              />
              <FormItem name="helpGroupId" />
              <FormItem asText={!this.props.can('edit help.helpContent')} name="linkedToPath" />
              <FormItem asText={!this.props.can('edit help.helpContent') || undefined} name="content" />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Form.create({ name: 'EditHelpContent' })(EditHelpContent));
