import { observable, action, computed, runInAction } from 'mobx';
import { createBrowserHistory } from 'history';

const menuTree = {
  '/finance': ['/companies', '/contracts'],
  '/catalog': ['/classifiers'],
  // '/statistics': ['/reports'],
};

class AppStore {
  @observable loading = false;
  @observable searchValue = '';

  sysKey = 'ois';
  tree = {};
  routes = [];
  routerMatch = {};

  authStoreReference = null;
  collectorFunc = false;

  _modals = {};

  jiraFieldValues = () => {
    const values = {};
    values.fullname = this.authStoreReference.user ? this.authStoreReference.user.name : '';
    values.email = this.authStoreReference.user ? this.authStoreReference.user.email : '';
    return values;
  };

  @action.bound async showCollectorDialog(options = {}) {
    delete window.ATL_JQ_PAGE_PROPS;
    const { scriptLoader } = await import('..');

    scriptLoader.remove(['jiraScript1', 'jiraScript2']).then(() => {
      if (document.querySelector('#atlwdg-container')) {
        document.querySelector('#atlwdg-container').remove();
      }
      if (document.querySelector('#atlwdg-blanket')) {
        document.querySelector('#atlwdg-blanket').remove();
      }
    });

    if (!('Error stack' in options)) {
      try {
        const errorStack = JSON.parse(window.localStorage.getItem('errorStack'));
        if (errorStack) {
          options.Crashed = errorStack.time;
          options.Path = errorStack.path;
          options['Error stack'] = errorStack.info ? JSON.stringify(errorStack.info) : undefined;
          this.lastError = errorStack.message ? errorStack.message : this.lastError;
        }
      } catch (err) {
        console.error(err);
      }
    }

    const environment = {
      ...options,
      URI: window.location.href,
      'Last error': this.lastError,
    };

    if (window && !window.ATL_JQ_PAGE_PROPS) {
      window.ATL_JQ_PAGE_PROPS = {
        ...window.ATL_JQ_PAGE_PROPS,
        environment,
        fieldValues: this.jiraFieldValues,
        triggerFunction: (showCollectorDialog) => {
          this.collectorFunc = showCollectorDialog;
        },
      };

      scriptLoader
        .load(
          // eslint-disable-next-line max-len
          'https://jira.esynergy.lv/s/d41d8cd98f00b204e9800998ecf8427e-CDN/ot9ghr/802001/b6b48b2829824b869586ac216d119363/2.2.4.6/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=aef12709',
          'jiraScript1'
        )
        .then(() =>
          scriptLoader.load(
            // eslint-disable-next-line max-len
            'https://jira.esynergy.lv/s/4578d892cfbca29df97ce0a37bc6948a-T/ot9ghr/802001/b6b48b2829824b869586ac216d119363/3.0.7/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-UK&collectorId=aef12709',
            'jiraScript2'
          )
        )
        .then(() => setTimeout(() => this.collectorFunc && this.collectorFunc(), 100));
    }
  }

  @observable responseCode = 200;
  @observable sidebarCollapsed = false;
  @observable windowWidth = {
    mobile: false,
    tablet: false,
    desktop: false,
  };

  @observable menuOpen = [];
  @observable menuSelected = [];
  @observable breadcrumbs = [];
  routerMenuOpen = [];

  @observable activeModal = null;
  @observable modal = {
    resourceId: null,
    visible: false,
    form: null,
    open: (resourceId) => {
      this.modal.resourceId = resourceId || this.modal.resourceId || null;
      this.modal.visible = true;
    },
    close: () => {
      // if (!['new', null].includes(this.modal.resourceId)) {
      //   this.modal.store.refresh();
      // }
      this.modal.resourceId = null;
      this.modal.form = null;
      this.modal.options = {};
      this.modal.visible = false;
      this.activeModal = false;
    },
    // store: null,
    options: {},
  };

  @observable incompleteCompanyInfo = 1;
  @action.bound setIncompleteCompanyInfo(check) {
    this.incompleteCompanyInfo = check;
  }

  @action.bound setMenu(open, selected) {
    this.routerMenuOpen = open;
    if (!this.sidebarCollapsed) {
      this.menuOpen = open;
    }
    const selectedMenuItems = new Set(selected);
    this.menuSelected = [...selectedMenuItems];
  }

  @action.bound setMenuOpen(open) {
    this.menuOpen = open;
  }

  @action.bound toggleCollapse() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  @action.bound setCollapse(collapse) {
    this.sidebarCollapsed = collapse;
    if (collapse) {
      this.menuOpen = [];
      document.body.style.overflowY = `auto`;
    } else {
      this.menuOpen = this.routerMenuOpen;
      document.body.style.overflowY = `hidden`;
    }
  }

  @action.bound setRouterMatch(match) {
    this.routerMatch = match;
    if (match) {
      setTimeout(() => this.watchHistory(this.history.location));
    }
  }

  // @action.bound _toggleModal(form, options) {
  //   const { open, close } = this.modals[name] || {};
  // }

  getModal(name) {
    return this._modals[name] ? this._modals[name] : null;
  }

  @action.bound createModal(name, form, options) {
    if (!this._modals[name]) {
      this._modals[name] = () => ({
        form,
        options,
        open: (id, _options) => {
          runInAction(() => {
            this.modal.resourceId = id;
            this.modal.form = form;
            this.modal.visible = true;
            this.modal.options = _options;
            this.activeModal = name;
          });
        },
        // close: () => {
        //   this.modal.resourceId = null;
        //   this.modal.form = null;
        //   this.modal.visible = false;
        //   this.activeModal = false;
        // },
        // visible: false,
      });
    }

    return this._modals[name]();
  }

  watchHistory({ pathname: eventPathname, search }) {
    this.routerMatch.path = eventPathname;

    this.loading = true;
    const usp = new URLSearchParams(search);
    const searchParams = {};
    for (const key of usp.keys()) {
      const item = usp.getAll(key);
      searchParams[key] = item.join();
    }
    this.history.location.searchParams = searchParams;

    const open = [];
    const selected = [];
    for (const key in this.tree) {
      if (eventPathname === key || eventPathname.startsWith(`${key}/`.replace('//', '/'))) {
        open.push(this.tree[key]);
        selected.push(key);
        // break;
      }
    }

    const pathname = eventPathname.split('/');
    if (pathname !== null) {
      open.push(`/${pathname[1]}`);
      selected.push(eventPathname);
    }

    this.setMenu(open, selected);

    const breadcrumbs = [
      {
        breadcrumbName: 'Sākums',
        path: '/',
      },
    ];

    let pathName = eventPathname;
    for (const key in this.tree) {
      if (
        this.tree[key] !== key &&
        (eventPathname === key || (eventPathname.startsWith(`${key}/`) && pathName !== key))
      ) {
        pathName = this.tree[key] + pathName;
      }
    }

    const { path: matchPath } = this.routerMatch || {};
    const findRoute = (tree, _parent = undefined) => {
      for (const item of tree) {
        if (item.path === '/profile') {
          item.path = '/profile/edit';
        }
        if (item.path === '/') {
          if (matchPath === '/') {
            breadcrumbs[0] = {
              pageTitle: item.title || item.bcTitle,
              breadcrumbName: item.bcTitle || item.title,
              hideHelpLink: item.hideHelpLink,
              path: item.component && item.path ? item.path : undefined,
            };
          }
          continue;
        }

        if (
          pathName === item.path ||
          eventPathname === item.path ||
          pathName.startsWith(`${item.path}/`.replace('//', '/')) ||
          eventPathname.startsWith(`${item.path}/`.replace('//', '/'))
        ) {
          breadcrumbs.push({
            pageTitle: item.title || item.bcTitle,
            breadcrumbName: item.bcTitle || item.title,
            hideHelpLink: item.hideHelpLink,
            path: item.component && item.path ? item.path : undefined,
          });
          if (item.children) {
            findRoute(item.children, item || _parent);
          }
          break;
        }
      }
      if (matchPath && matchPath !== '/') {
        for (const item of tree) {
          if (item.path.includes(':id') && matchPath === item.path) {
            breadcrumbs.push({
              pageTitle: item.title || item.bcTitle,
              breadcrumbName: item.bcTitle || item.title,
              hideHelpLink: item.hideHelpLink,
              path: item.component && item.path ? item.path : undefined,
            });
          }
        }
      }
    };

    findRoute([...this.flatRoutesObjects(this.routes)].reverse());
    this.setBreadcrumbs(breadcrumbs);
  }

  @action.bound setBreadcrumbs(bc) {
    window.setTitle(bc[bc.length - 1].pageTitle);
    this.breadcrumbs = bc;
  }

  flatRoutes(routes) {
    return routes.reduce((routeContainer, routeItem) => {
      const { path, children, hidden } = routeItem;

      if (children && children.length) {
        for (const child of this.flatRoutes(children)) {
          routeContainer.push(child);
        }
      }

      if (path && !hidden) {
        routeContainer.push(path);
      }
      return routeContainer || [];
    }, []);
  }

  flatRoutesObjects(routes) {
    return routes.reduce((routeContainer, routeItem) => {
      // const { path, children, hidden } = routeItem;
      if (routeItem.children && routeItem.children.length) {
        for (const child of this.flatRoutesObjects(routeItem.children)) {
          routeContainer.push(child);
        }
      }

      if (routeItem.path && (!routeItem.hidden || routeItem.includeInBreadcrumbs)) {
        routeContainer.push(routeItem);
      }
      return routeContainer || [];
    }, []);
  }

  constructor(routes = [], sysKey = 'ois', classifiers = []) {
    for (const r of classifiers) {
      if (!r.link) {
        continue;
      }
      const link = `/catalog/classifiers/${r.link}`;
      this.tree[link] = link;
    }

    routes = routes.map((route) => {
      if (route.key === 'catalog') {
        if (route.children) {
          route.children = route.children.map((child) => {
            if (child.key === 'classifiers') {
              for (const r of classifiers) {
                if (!r.link) {
                  continue;
                }
                const link = `${child.path}/${r.link}`;
                child.children.push({ title: r.title, path: link, key: link, hidden: true, component: true });
              }
            }
            return child;
          });
        }
      }
      return route;
    });

    this.routes = routes;
    this.sysKey = sysKey;

    for (const r of this.flatRoutes(routes)) {
      if (r !== '/') {
        this.tree[r] = r;
      }
    }

    for (const group in menuTree) {
      if (menuTree[group]) {
        for (const item of menuTree[group]) {
          if (group) {
            this.tree[item] = group;
          }
        }
      }
    }

    this.history = createBrowserHistory();
    this.history.listen((location) => {
      this.watchHistory(location);
    });
    this.watchHistory(this.history.location);
  }

  useBrowserHistory() {
    return this.history;
  }
}

export default AppStore;
