import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol } from '@ois-lib';

@inject('appStore', 'authStore', 'authorize', 'can')
@observer
class EditOperationalNote extends EditFormBase {
  title = 'Papildus vienošanās';
  storeName = 'store';

  componentDidUpdate() {
    const { form, store } = this.props;
    if (form && !form.getFieldValue('number')) {
      form.setFieldsValue({ number: store._data.length + 1 });
    }
  }

  canSave() {
    return false;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view contracts', 'edit contracts']);

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Vienošanās" bordered={false}>
            <FormItem asText name="number" />
            <FormItem asText name="agreementDate" />
            <FormItem asText name="contractExtendedTo" />
            <FormItem asText name="agreementSubject" />
            <FormItem asText name="justification" />
            <FormItem asText name="explanation" />
            <FormItem asText name="amount" />
            <FormItem asText name="vatRate" />
            <FormItem asText name="vat_amount" />
            <FormItem asText name="amount_with_vat" />
            <FormItem asText name="notes" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'editOperationalNote' })(EditOperationalNote);
