import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { PageHeader, Input, Divider, Col, Row } from 'antd';
import logo from '@ois-lib/assets/img/logo.png';
import logoText from '@ois-lib/assets/img/logoText.png';
import { FontAwesomeIcon } from '@ois-lib';
import UserInfo from '../user/UserInfo';
import AppToolbar from './AppToolbarOKS';

const { Search } = Input;

@inject('appStore', 'authStore')
@observer
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    const { history, appStore } = this.props;
    const {
      location: { searchParams },
    } = history;

    if (searchParams.q !== appStore.searchValue) {
      appStore.searchValue = searchParams.q;
    }

    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    const { appStore } = this.props;
    const resetAll = (obj) => {
      Object.keys(obj).forEach((item) => {
        obj[item] = false;
      });
    };
    if (window.innerWidth < 425) {
      resetAll(appStore.windowWidth);
      appStore.windowWidth.mobile = true;
    } else if (window.innerWidth < 768) {
      resetAll(appStore.windowWidth);
      appStore.windowWidth.tablet = true;
    } else {
      resetAll(appStore.windowWidth);
      appStore.windowWidth.desktop = true;
    }
  }

  render() {
    const { authStore, appStore, history, userMenu, showCollectorDialog, langMenuComponent, oks } = this.props;
    const col = {
      l: {
        xxl: 18,
        xl: 18,
        lg: 16,
        md: 15,
        sm: 10,
        xs: 8,
      },
      r: {
        xxl: 6,
        xl: 6,
        lg: 8,
        md: 9,
        sm: 14,
        xs: 16,
      },
    };

    return (
      <>
        <PageHeader
          style={!appStore.windowWidth.desktop ? { position: `fixed`, width: `100%` } : {}}
          className="site_header"
          ghost={false}
          title={[
            <Row key="header" className="site_header_row">
              <Col {...col.l}>
                <div className="site_header_left">
                  <Link to="/" className="site_header_logo">
                    <span>
                      <img src={logo} alt="logo" />
                      {appStore.sidebarCollapsed ||
                        (appStore.windowWidth.desktop && <img src={logoText} alt="logoText" />)}
                    </span>
                  </Link>
                  {window.location.href.includes("ois-test2.rbp.lv") ?
                  <span style={{"color":"red","fontSize":"60px"}}>
                    TEST2
                  </span> : ""}
				  {window.location.href.includes("ois-test.rbp.lv") ?
                  <span style={{"color":"red","fontSize":"60px"}}>
                    TEST2
                  </span> : ""}
                  {authStore.loggedIn && appStore.windowWidth.desktop && (
                    <span
                      style={{
                        marginLeft: !appStore.sidebarCollapsed ? '0' : '-6em',
                        width: '100%',
                      }}
                    >
                      <Divider type="vertical" style={{ height: '2em' }} />
                      <Search
                        prefix={
                          <FontAwesomeIcon
                            icon="search"
                            className="icon-check"
                            style={{
                              marginLeft: '-0.1em',
                              marginTop: '-0.3em',
                              width: '16px',
                            }}
                          />
                        }
                        size="large"
                        className="header_profile_info_search"
                        value={appStore.searchValue}
                        placeholder="Meklēt..."
                        onChange={(value) => {
                          appStore.searchValue = value.target.value;
                        }}
                        onSearch={(value) => {
                          appStore.searchValue = value;
                          history.push(`/search?q=${value}`);
                        }}
                        onKeyUp={(e) => {
                          switch (e.which) {
                            case 27:
                              appStore.searchValue = '';
                              history.push(`/search?q=`);
                              e.target.blur();
                              break;
                            // case 13:
                            //   console.log("ENTER PRESSED!");
                            //   break;
                            default:
                              // store.setSearchQuery(e.target.value);
                              break;
                          }
                        }}
                        allowClear
                        style={{ width: '85%', color: 'rgb(152, 152, 152)' }}
                      />
                    </span>
                  )}
                </div>
              </Col>
              {authStore.loggedIn && appStore.windowWidth.desktop ? (
                <Col {...col.r}>
                  <div className="site_header_right">
                    <UserInfo userMenu={userMenu} showCollectorDialog={showCollectorDialog} oks={oks} />
                    <AppToolbar showCollectorDialog={showCollectorDialog} />
                  </div>
                </Col>
              ) : (
                <Col {...col.r}>{!!langMenuComponent && langMenuComponent}</Col>
              )}
            </Row>,
          ]}
        />
        {
          !appStore.windowWidth.desktop && (
            <div style={{ height: 64, width: `100%` }} />
          )
        }
      </>
    );
  }
}
export default withRouter(Header);
