import React from 'react';
import { Field } from './Field';
import DataFieldTableComponent from './components/DataFieldTableComponent';
import { Form } from 'antd';
import { withRouter } from 'react-router-dom';

@Field.register
class DataGroupComponent extends Field {
  static code = 'datagroup';

  extraItemProps = {
    wrapperCol: { sm: 24, xs: 24, lg: 24, md: 24 },
    style: { width: '100%', marginRight: 0 },
    label: undefined,
  };

  getFormLabel() {
    return undefined;
  }

  renderComponent(props) {
    console.log('props@DataGp: ', props);
    const Base = props.formKind;
    return <Base parentProps={{ disabled: false }} />;
    // return <DataFieldTableComponent title={this.title} formInstance={this.formInstance} {...props} />;
  }
}

export default DataGroupComponent;
