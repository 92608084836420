import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol } from '@ois-lib';

@inject('appStore', 'authorize', 'can')
@observer
class EditShipOperation extends EditFormBase {
  title = 'Kuģa darbība';
  storeName = 'store';

  canSave() {
    return false;
  }

  canDelete() {
    return false;
  }

  renderForm() {
    // const { authorize } = this.props;
    // authorize(['view ship_visits.ship_operations', 'edit ship_visits.ship_operations']);

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Kuģa darbība" bordered={false}>
            <FormItem name="shipOperationTypeId" />
            <FormItem name="shipStateChangeReasonId" />
            <FormItem name="startTime" />
            <FormItem name="endTime" />
            <FormItem name="comment" />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditShipOperation' })(EditShipOperation);
