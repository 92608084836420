import React from 'react';

export default class AddonComponent extends React.Component {
  value = null;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { value } = this.props;
    if (value) {
      this.value = value;
    }
  }

  shouldComponentUpdate({ value = null }) {
    if (this.value !== value) {
      this.value = value;
      return true;
    }
    return false;
  }

  render() {
    const { forceRenderFunc, children } = this.props;
    return forceRenderFunc ? forceRenderFunc() : children;
  }
}
