/* eslint-disable no-await-in-loop */
import React, { Component } from 'react';
import { Row, Col, Card, DatePicker } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import { DataTable, gridCol, gridRow } from '@ois-lib';
import storeFactory from '@ois-lib/stores';
import columnDefinitions from '@ois-lib/stores/columnDefinitions';
import fieldSpec from '@ois-lib/stores/FieldSpec';
import api from '@ois-lib/services/api';

import './styles.less';

@inject(`authorize`, `can`)
@observer
class ShipAgents extends Component {
  constructor(props) {
    super(props);

    this.stores = {
      shipAgentTypes: storeFactory.getStore(`shipAgentTypes`),
      shipAgentCompanies: storeFactory.getStore(`shipAgentCompanies`),
      shipAgentGross: null,
      shipAgentMonths: null,
    };

    this.state = {
      values: {
        period: [moment().startOf(`year`), moment().subtract(1, `month`).endOf(`month`)],
      },
    };

    this.mutex = null;
  }

  componentDidMount() {
    const { values } = this.state;

    this.init();
    this.refresh(values.period);
  }

  init = async (gross = true, month = true) => {
    const { values } = this.state;

    const store = columnDefinitions().shipAgents;

    if (gross) {
      let response = null;

      await fetch(`${api.base}shipTypeColumns`, {
        headers: {
          Authorization: api.headers.Authorization,
        },
      })
        .then((resp) => {
          response = resp;
        })
        .catch((err) => {
          console.error(err);
        });

      const data = await response.json();

      fieldSpec.add(`shipAgentGross`, store.concat(data));
      this.stores.shipAgentGross = storeFactory.getStore(`shipAgentGross`, null, true);

      this.stores.shipAgentGross.additionalParams = {
        dateFrom: values.period[0].format(`YYYY-MM-DD HH:mm:ss`),
        dateTo: values.period[1].format(`YYYY-MM-DD HH:mm:ss`),
      };

      // this.stores.shipAgentGross.refresh();
      this.stores.shipAgentGross.resetColumnState();
    }

    if (month) {
      let response = null;

      const params = `?dateFrom=${encodeURIComponent(values.period[0].format(`YYYY-MM-DD HH:mm:ss`))}
        &dateTo=${encodeURIComponent(values.period[1].format(`YYYY-MM-DD HH:mm:ss`))}`;

      await fetch(`${api.base}shipMonthColumns${params}`, {
        headers: {
          Authorization: api.headers.Authorization,
        },
      })
        .then((resp) => {
          response = resp;
        })
        .catch((err) => {
          console.error(err);
        });

      const data = await response.json();

      fieldSpec.add(`shipAgentMonths`, store.concat(data));
      this.stores.shipAgentMonths = storeFactory.getStore(`shipAgentMonths`, null, true);

      this.stores.shipAgentMonths.additionalParams = {
        dateFrom: values.period[0].format(`YYYY-MM-DD HH:mm:ss`),
        dateTo: values.period[1].format(`YYYY-MM-DD HH:mm:ss`),
      };

      // this.stores.shipAgentMonths.refresh();
      this.stores.shipAgentMonths.resetColumnState();
    }

    // this.refresh(values.period, values.agent);

    this.forceUpdate();
  };

  refresh = (period) => {
    for (const i in this.stores) {
      if (this.stores[i]) {
        this.stores[i].additionalParams = {
          dateFrom: period[0].format(`YYYY-MM-DD HH:mm:ss`),
          dateTo: period[1].format(`YYYY-MM-DD HH:mm:ss`),
        };

        this.stores[i].refresh();
      }
    }
  };

  change = (id, _value) => {
    const { values } = this.state;

    values[id] = _value;

    this.init();
    this.refresh(values.period);
    this.setState({ values });
  };

  render() {
    const { values } = this.state;

    return (
      <>
        <br />
        <Row {...gridRow}>
          <Col {...gridCol}>
            <Card title="Kuģu aģenti" size="small" bordered={false}>
              <Row {...gridRow} style={{ marginTop: 10 }}>
                <Col style={{ display: `inline-block`, minWidth: 120 }}>
                  <div>Periods: </div>
                </Col>
                <Col style={{ display: `inline-block` }}>
                  <DatePicker.RangePicker
                    showTime
                    allowClear={false}
                    format="DD.MM.YYYY HH:mm"
                    value={values.period}
                    separator="-"
                    onChange={(value) => this.change(`period`, value)}
                  />
                </Col>
              </Row>
              <Row {...gridRow} style={{ marginTop: 30 }}>
                <Col {...gridCol}>
                  {this.stores.shipAgentTypes && (
                    <DataTable printable title="Kuģu tipi" store={this.stores.shipAgentTypes} />
                  )}
                </Col>
              </Row>
              <Row {...gridRow} style={{ marginTop: 30 }}>
                <Col {...gridCol}>
                  {this.stores.shipAgentCompanies && (
                    <DataTable printable title="Kuģu aģenti" store={this.stores.shipAgentCompanies} />
                  )}
                </Col>
              </Row>
              <Row {...gridRow} style={{ marginTop: 30 }}>
                <Col {...gridCol}>
                  {this.stores.shipAgentGross && (
                    <DataTable
                      printable
                      title="Bruto tonāža pa aģentiem"
                      overloadRefresh={() => this.init(true, false)}
                      store={this.stores.shipAgentGross}
                    />
                  )}
                </Col>
              </Row>
              <Row {...gridRow} style={{ marginTop: 30 }}>
                <Col {...gridCol}>
                  {this.stores.shipAgentMonths && (
                    <DataTable
                      printable
                      title="Vizītes pa mēnešiem"
                      overloadRefresh={() => this.init(false, true)}
                      store={this.stores.shipAgentMonths}
                    />
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default ShipAgents;
