import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Card, Button } from 'antd';
import { inject } from 'mobx-react';
import moment from 'moment';
import { gridRow } from '..';

const WrappedWith = ({ root, children }) => {
  return root ? (
    <Layout style={{ minHeight: '100vh', paddingTop: '60px' }}>
      <Layout.Content>
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={14} xxl={10}>
            {children}
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  ) : (
    <>{children}</>
  );
};

@withRouter
@inject('appStore')
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
      component: null,
    };
    props.history.listen(() => {
      this.setState({ hasError: false, component: null });
    });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    switch (error.constructor.name) {
      case 'ErrorForbidden':
      case 'ErrorNotFound':
        return { hasError: true, error, component: error.constructor.component };
      default:
    }
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    const { appStore, location } = this.props;
    appStore.loading = false;
    // logErrorToMyService(error, info);
    const errorStack = {
      hasError: true,
      error,
      info,
      message: error.message ? error.message : null,
      path: location.pathname,
      time: moment().format('L LT'),
    };
    if (window.localStorage) {
      window.localStorage.setItem('errorStack', JSON.stringify(errorStack));
    }
    this.setState(errorStack);
  }

  render() {
    const { hasError, message, error, info, time, path, component: Component } = this.state;
    const { isRoot, appStore, children } = this.props;
    if (hasError) {
      return Component ? (
        <Component message={message} />
      ) : (
        <WrappedWith root={isRoot}>
          <h1 className="cabinet_title">Kļūda</h1>
          <Row className="help-grid" type="flex" {...gridRow}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card title="Sistēmā notikusi kļūda.">
                <h3>Kļūdas tehniskā informācija:</h3>
                <p>{error.message}</p>
                <Button
                  type="info"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    this.setState({ hasError: false });
                    window.location.reload();
                  }}
                >
                  Pārlādēt lapu
                </Button>
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    if (message) {
                      appStore.lastError = message;
                      appStore.showCollectorDialog({
                        Crashed: time,
                        Path: path,
                        'Error stack': info ? JSON.stringify(info) : undefined,
                      });
                    }
                  }}
                >
                  Pieteikt kļūdu sistēmas izstrādātājam
                </Button>
              </Card>
            </Col>
          </Row>
        </WrappedWith>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
