/* eslint-disable max-classes-per-file */
import React from 'react';
import { Radio, Button, Timeline, Modal, Spin } from 'antd';
import { observable } from 'mobx';
import FontAwesomeIcon from '../assets/icon/FontAwesomeIcon';

class CalendarViewInterface {
  @observable type = 'month';
  // Event(event) {
  //   return (
  //     <span>
  //       <span>{JSON.stringify(event)}</span>
  //     </span>
  //   );
  // }
}

const CalendarView = new CalendarViewInterface();

const CalendarFilterModal = React.memo(({ columns, filterModal = true, filterChanged, filterValues, parent }) => (
  <Modal
    title="Filtri"
    closable={false}
    maskClosable={false}
    visible={filterModal}
    footer={[
      <Button key="back" onClick={parent.toggleFilterModal}>
        Aizvērt
      </Button>,
      <Button key="clearAll" onClick={parent.clearAllFilters}>
        Notīrīt visus
      </Button>,
      <Button key="filter" onClick={parent.confirm} disabled={!filterChanged} type="primary">
        Filtrēt
      </Button>,
    ]}
  >
    <Timeline>
      {columns
        .filter(({ filter, filterDropdown, filterIcon }) => filter === undefined && filterDropdown && filterIcon)
        .map((c) => {
          return (
            <Timeline.Item key={c.key}>
              <h3>
                {c.filterIcon && c.filterIcon(false)}
                &nbsp;
                {c.title}
              </h3>
              <div>
                {c.filterDropdown({
                  setSelectedKeys: parent.setFilter(c.key),
                  selectedKeys: filterValues[c.key] || [],
                  confirm: parent.confirm, // onFilter
                  clearFilters: parent.clear(c.key),
                  showButtons: false,
                })}
              </div>
            </Timeline.Item>
          );
        })}
    </Timeline>
  </Modal>
));

class Toolbar extends React.Component {
  nav = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',

    MONTH: 'month',
    WEEK: 'week',
    WORK_WEEK: 'work_week',
    DAY: 'day',
    AGENDA: 'agenda',
  };

  constructor(props) {
    super(props);

    this.state = {
      filterModal: false,
      filterValues: {},
      filterChanged: false,
    };
    if (props.filters) {
      this.state.filterValues = props.filters;
    }
  }

  navigate = (action) => {
    const { onNavigate } = this.props;
    onNavigate(action);
  };

  toggleFilterModal = () => {
    const { filters } = this.props;
    const { filterModal } = this.state;
    this.setState({
      filterValues: filters,
      filterModal: !filterModal,
      filterChanged: false,
    });
  };

  clearAllFilters = () => {
    this.setState(
      {
        filterValues: {},
      },
      this.confirm
    );
  };

  setFilter = (key) => (value) => {
    const { filterValues } = this.state;
    this.setState({
      filterValues: { ...filterValues, [key]: value },
      filterChanged: true,
    });
    return true;
  };

  confirm = () => {
    const { onFilter } = this.props;
    const { filterValues } = this.state;
    onFilter(filterValues);
    return true;
  };

  clear = () => () => {
    return true;
  };

  render() {
    const { label, columns, filters } = this.props;
    const { filterModal, filterValues, filterChanged } = this.state;
    const dataLoading = !columns.filter((column) => column.optionStore && column.optionStore.loadingFull).length;

    return (
      <div className="rbc-toolbar-flex">
        <span className="">
          <Button.Group>
            <Button type="primary" onClick={() => this.navigate(this.nav.TODAY)}>
              <FontAwesomeIcon icon="calendar-day" />
              Šodien
            </Button>
            <Button type="secondary" className="pagintaion-button" onClick={() => this.navigate(this.nav.PREVIOUS)}>
              <FontAwesomeIcon icon="angle-left" />
            </Button>
            <Button type="secondary" className="pagintaion-button" onClick={() => this.navigate(this.nav.NEXT)}>
              <FontAwesomeIcon icon="angle-right" />
            </Button>
          </Button.Group>
          <Button
            type={Object.entries(filters).filter(([, v]) => !!v && !!v.length).length ? 'primary' : 'secondary'}
            style={{ marginLeft: 10 }}
            disabled={!dataLoading}
            onClick={this.toggleFilterModal}
          >
            <FontAwesomeIcon icon="filter" style={{ marginRight: 0 }} />
          </Button>
          <CalendarFilterModal {...{ columns, filterModal, filterValues, filterChanged, parent: this }} />
        </span>
        <span className="rbc-toolbar-label">{label}</span>
        <span className="rbc-btn-group">
          <Radio.Group
            value={CalendarView.type}
            onChange={(e) => {
              CalendarView.type = e.target.value;
            }}
          >
            <Radio.Button value={this.nav.MONTH}>Mēnesis</Radio.Button>
            <Radio.Button value={this.nav.WEEK}>Nedēļa</Radio.Button>
            <Radio.Button value={this.nav.DAY}>Diena</Radio.Button>
          </Radio.Group>
        </span>
      </div>
    );
  }
}

export { CalendarView };
export default Toolbar;
