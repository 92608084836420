import React from 'react';
import { Row, Col, Form, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { EditFormBase, gridRow, FormItem, gridCol, gridCol2 } from '@ois-lib';
import Validate from '@ois-lib/validate';

@inject('appStore', 'authorize', 'can')
@observer
class EditResourceRequest extends EditFormBase {
  title = 'Resursa pieprasījums';
  storeName = 'store';

  constructor(props) {
    super(props);
    this.state = {
      isIcebreaker: false,
      resourceId: null,
      shipFlag: false,
    };
  }

  componentDidMount() {
    this.checkShipFlag();
  }

  componentDidUpdate() {
    const resourceId = this.props.form.getFieldValue('resourceId');

    if (resourceId && resourceId !== this.state.resourceId) {
      this.checkForIcebreaker();
    } else if (!resourceId && this.state.isIcebreaker) {
      this.notIcebreaker();
    }
    const companyId = this.props.form.getFieldValue('companyId');
    const { shipAgentId } = this.props.parentProps.record_store.item;
    if (shipAgentId && companyId !== shipAgentId) {
      this.props.form.setFieldsValue({ companyId: shipAgentId });
    }
  }

  notIcebreaker() {
    this.setState({ isIcebreaker: false });
  }

  canSave() {
    return this.props.can('edit ship_visits.resource_requests');
  }

  canDelete() {
    return this.props.can('delete ship_visits.resource_requests');
  }

  checkForIcebreaker = () => {
    const { form, store } = this.props;
    this.setState({
      resourceId: form.getFieldValue('resourceId'),
    });
    store.loadRecord(form.getFieldValue('resourceId'), 'resources').then(() => {
      if (store._loadedRecord) {
        store.loadRecord(store._loadedRecord.resourceTypeId, 'resourceTypes').then(() => {
          if (store._loadedRecord) {
            this.setState({
              isIcebreaker: store._loadedRecord.name === 'Ledlauzis',
            });
          }
        });
      }
    });
  };

  checkShipFlag = () => {
    const recordStore = this.props.parentProps.record_store;
    const shipVisit = recordStore ? recordStore.item : null;
    if (shipVisit) {
      const { shipIn } = shipVisit;

      if (shipIn.countryId) {
        this.props.store.loadRecord(shipIn.countryId, 'countries').then(() => {
          if (this.props.store._loadedRecord) {
            this.setState({
              shipFlag: this.props.store._loadedRecord.name,
            });
          }
        });
      }
    }
  };

  renderForm() {
    // const { authorize } = this.props;
    const recordStore = this.props.parentProps.record_store;
    const { isIcebreaker } = this.state;
    // authorize(['view ship_visits.resource_requests', 'edit ship_visits.resource_requests']);
    const shipVisit = recordStore ? recordStore.item : null;
    const agent = shipVisit ? shipVisit.shipAgent : null;
    let infoBlock = '';
    if (shipVisit && agent) {
      const { shipIn } = shipVisit;
      let propellerType = shipIn ? shipIn.propellerType : null;
      switch (propellerType) {
        case 'FIXED':
          propellerType = 'Ar fiksētu soli';
          break;
        case 'VARIABLE':
          propellerType = 'Ar maināmu soli';
          break;
        default:
      }
      infoBlock = (
        <Col {...gridCol2}>
          <Card title="Informācija">
            <p>Kuģa nosaukums: {shipIn.name ? <strong>{shipIn.name}</strong> : '-nav norādīts-'}</p>
            <p>Kuģa karogs: {this.state.shipFlag ? <strong>{this.state.shipFlag}</strong> : '-nav norādīts-'}</p>
            <p>Kuģa BT: {shipIn.grossWeight ? <strong>{shipIn.grossWeight}</strong> : '-nav norādīts-'}</p>
            <p>Izsaukuma signāls: {shipIn.mmsi ? <strong>{shipIn.mmsi}</strong> : '-nav norādīts-'}</p>
            <p>
              Kuģa klasif.sab./ledus klase: {shipIn.iceClass ? <strong>{shipIn.iceClass}</strong> : '-nav norādīts-'}
            </p>
            <p>Dzinēja jauda: {shipIn.enginePower ? <strong>{shipIn.enginePower}</strong> : '-nav norādīts-'}</p>
            {/* <p>
              Kuģa īpašnieks:{' '}
              {this.store.item.shipOwner ? <strong>{this.store.item.shipOwner}</strong> : '-nav norādīts-'}
            </p> */}
            <p>Aģents: {agent.name ? <strong>{agent.name}</strong> : '-nav norādīts-'}</p>
            <p>Aģenta e-pasts: {agent.email ? <strong>{agent.email}</strong> : '-nav norādīts-'}</p>
            <p>Kuģa garums(m): {shipIn.length ? <strong>{shipIn.length}</strong> : '-nav norādīts-'}</p>
            <p>Kuģa platums(m): {shipIn.width ? <strong>{shipIn.width}</strong> : '-nav norādīts-'}</p>
            <p>Kuģa dzenskrūves tips: {propellerType ? <strong>{propellerType}</strong> : '-nav norādīts-'}</p>
            <p>Kuģa iegrime: {shipIn.draft ? <strong>{shipIn.draft}</strong> : '-nav norādīts-'}</p>
            <p>
              Kuģa iegrime priekšgalā: {shipIn.draftAtBow ? <strong>{shipIn.draftAtBow}</strong> : '-nav norādīts-'}
            </p>
            <p>
              Kuģa iegrime pakaļgalā: {shipIn.draftAtStarn ? <strong>{shipIn.draftAtStarn}</strong> : '-nav norādīts-'}
            </p>
            <p>
              Kravas apraksts ienākot:
              {shipVisit.cargoDescriptionIn ? <strong>{shipVisit.cargoDescriptionIn}</strong> : '-nav norādīts-'}
            </p>
            <p>
              Kravas apraksts izejot:
              {shipVisit.cargoDescriptionOut ? <strong>{shipVisit.cargoDescriptionOut}</strong> : '-nav norādīts-'}
            </p>
          </Card>
        </Col>
      );
    }

    return (
      <Row {...gridRow}>
        <Col {...gridCol}>
          <Card title="Resursa pieprasījums" bordered={false}>
            <Col {...gridCol2}>
              <FormItem name="companyId" hidden />
              <FormItem name="resourceId" onSelect={() => this.checkForIcebreaker()} />
              <FormItem name="berthId" />
              <FormItem name="desiredTime" />
              <FormItem name="actualTime" />
              <FormItem name="status" />
              <FormItem name="completionTime" />
              <FormItem name="paid" />
              <FormItem name="notes" />

              <FormItem name="etaIrbeMeridian" rules={isIcebreaker ? [Validate.Required] : undefined} />
              <FormItem name="shipCondition" rules={isIcebreaker ? [Validate.Required] : undefined} />
              <FormItem name="fuelStock" rules={isIcebreaker ? [Validate.Required] : undefined} />
            </Col>

            {infoBlock}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'EditResourceRequest' })(EditResourceRequest);
